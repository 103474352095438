import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { UserService } from 'src/app/auth/service/user.service';
import { Symptoms } from 'src/app/models/symptoms.model';
import { UserSymptomsService } from 'src/app/services/user-symptoms.service';
import Swal from 'sweetalert2/src/sweetalert2.js';

@Component({
  selector: 'app-health-status-view',
  templateUrl: './health-status-view.component.html',
  styleUrls: ['./health-status-view.component.scss'],
})
export class HealthStatusViewComponent implements OnInit {
  public symptom!: Symptoms;
  public previousSymptom: any;
  isFetching = false;
  error = null;
  userSymptomId: any;
  private errorSub!: Subscription;
  public userData: any;
  public diagnosisMode: any;
  currentSymptom: any;
  symptomsBaseline: any;

  constructor(
    public userSymptomsService: UserSymptomsService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userSymptomId = this.route.snapshot.params.id;
    this.currentSymptom = this.route.snapshot.params.symptom;
    this.errorSub = this.userSymptomsService.error.subscribe((errorMessage) => {
      // this.error = errorMessage;
    });

    this.isFetching = true;

    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;

        if (this.userData.primary_diagnosis) {
          this.diagnosisMode = this.userService.getDiagnosisMode(
            this.userData.primary_diagnosis
          );
        }
      }
    });

    forkJoin({
      symptoms: this.userSymptomsService.fetchAll(),
      symptom: this.userSymptomsService.fetch(this.userSymptomId),
    }).subscribe(
      (responseData) => {
        this.isFetching = false;
        this.symptom = responseData.symptom;
        responseData.symptoms.forEach((symptom, index) => {
          if (symptom.user_symptom_id == this.userSymptomId) {
            if (responseData.symptoms[index + 1]) {
              this.previousSymptom = responseData.symptoms[index + 1];
              //console.log(this.previousSymptom);
            }
          }
        });

        responseData.symptoms.forEach((symptoms: any) => {
          if (symptoms.baseline == 1 && !this.symptomsBaseline) {
            this.symptomsBaseline = symptoms;
          }
        });
      },
      (error) => {
        // console.log(error);
      }
    );
  }

  onDelete() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to undo this",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it',
    }).then((result) => {
      if (result.value) {
        this.userSymptomsService.delete(this.userSymptomId).subscribe(
          (responseData) => {
            Swal.fire('Deleted', 'Your record has been deleted.', 'success');
            this.router.navigate(['/trackers', 'health-status']);
          },
          (error) => {
            this.isFetching = false;
            this.error = error.message;
            Swal.fire('Deleted!', 'There was an error.', 'error');
          }
        );
      }
    });
  }

  checkSymptomForDiagnosis(symptomId: any) {
    let allowSymptom = true;
    /*
    ischaemic_pain
  cough
  phlegm
  chest_tightness
  sob
  ankle_swelling
  activity
  confidence
  sleep
  energy
  mood
  appetite
  surgery_pain
*/

    if (
      this.diagnosisMode == 'cardiac' &&
      (symptomId == 'cough' ||
        symptomId == 'phlegm' ||
        symptomId == 'chest_tightness')
    ) {
      allowSymptom = false;
    }

    if (
      this.diagnosisMode == 'respiratory' &&
      (symptomId == 'ischaemic_pain' ||
        symptomId == 'ankle_swelling' ||
        symptomId == 'surgery_pain')
    ) {
      allowSymptom = false;
    }

    return allowSymptom;
  }
}
