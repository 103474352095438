<div *ngIf="isLoading" class="text-center">
  <app-loading-spinner></app-loading-spinner>
</div>
<div *ngIf="!isLoading" class="login">
    <div class="container container-register">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">

        <div>
          <button
          class="btn btn-back btn-lg"
          [routerLink]="['/login']"
        >
          <span class="icon-wrap"
          ><fa-icon
            [icon]="['fal', 'arrow-left']"
            [fixedWidth]="true"
          ></fa-icon><span class="sr-only">Back</span></span
      >
        </button>
        </div>
          <div class="card rounded-3 text-black">
            <div class="row g-0">
              <div class="col-lg-12">
                <div class="card-body login-form">
                  <div class="text-center">
                    <img
                      src="/assets/img/logo-iimpact-colour.svg"
                      style="width: 185px"
                      alt="logo"
                    />
                    <h4 class="login-title">Forgotten your password</h4>
                  </div>

                  <form
                    #authForm="ngForm"
                    (ngSubmit)="onSubmit(authForm)"
                    *ngIf="!isLoading && !sent"
                  >
                    <div class="form-group form-floating mb-4">
                      <input
                        type="email"
                        id="email"
                        class="form-control"
                        ngModel
                        name="email"
                        placeholder="Email"
                        required
                        email
                      />
                      <label for="email text-center"
                        >Please enter your email</label
                      >
                      <!--label for="email">Email address</label-->
                    </div>

                    <button
                      type="submit"
                      [disabled]="!authForm.valid"
                      class="btn btn-primary w-100 btn-lg w-100 mb-3"
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8 offset-lg-2">
        <div class="signin-details">
          <div class="signin-details-flex">
            <div class="signin-left">
              Website last reviewed April 2024<br>
            </div>
            <div class="signin-right">
              <a [routerLink]="['/terms-and-conditions']"
                >Terms &amp; Conditions</a
              >&nbsp;|&nbsp; 
              <a [routerLink]="['/privacy-policy']">Privacy Policy</a>
            </div>
          </div>
          <div class="signin-details-flex">
            <div class="signin-left">
              © {{ currentYear }} University Hospitals of Leicester
            </div>
            <div class="signin-right">
              Built by <a href="https://www.hark2.com">Hark 2</a>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col footer-logos">
            <div class="programme-logo-strap">Incorporating the programmes</div>
            <div class="programme-logos">
              <img
                src="./assets/img/logo-ayh-colour.svg"
                alt="Activate Your Heart Logo"
                width="93"
              />
              <img
                src="./assets/img/logo-space-colour.svg"
                alt="SPACE for COPD"
                width="69"
              />
              <img
                src="./assets/img/logo-ycr-colour.svg"
                alt="Your COVID Recovery"
                width="93"
              />
            </div>
          </div>
        </div>
      </div>
    </div>  
</div>
