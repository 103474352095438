<div class="main-content-area">
  <div class="bg-light">
    <div class="row">
      <div class="col">

        <!-- START OF FORM-->
        <form class="ii-form" (ngSubmit)="onSubmit()" #form="ngForm">

          <!-- START OF DATE-->
          <div class="form-group">
            <!-- Title: Date -->
            <label for="date">
              <span class="num">1.</span> Date of workout
            </label>
            <div class="input-group">
              <span class="input-group-icon">
                <fa-icon
                  [icon]="['far', 'calendar-alt']"
                  [fixedWidth]="true">
                </fa-icon>
              </span>
              <input
                type="text"
                id="date"
                class="form-control form-control-date"
                [ngModel]="bsDate"
                #date="ngModel"
                name="date"
                placeholder="DD/MM/YYYY"
                required
                date
                bsDatepicker
                autocomplete="off"
                placement="bottom"
                [maxDate]="maxDate"
                [bsConfig]="{
                  dateInputFormat: 'DD/MM/YYYY',
                  containerClass: 'theme-impact',
                  showWeekNumbers: false
                }"
              />
            </div>
            <!-- Alert for date -->
            <div class="alert alert-danger" *ngIf="!date.valid && submitted">
              Please enter a date above
            </div>
          </div>
          <!-- END OF DATE-->

          <!-- START OF EXERCISE TYPE-->
          <div class="form-group" *ngIf="exercises">
            <!-- Title: Exercise type -->
            <label for="exercise">
              <span class="num">2.</span> What exercise did you do?
            </label>
            <div class="input-group">
              <select
                id="exercise"
                class="form-select"
                ngModel
                name="exercise"
                required
                #exercise="ngModel"
                (change)="onCheckForOther($event)">
                <!-- Light exercise sorted list -->
                <optgroup label="Light">
                  <option
                    *ngFor="let exercise of filterExercise(exercises, 'l')"
                    [value]="exercise.exercise_id">
                    {{ exercise.name }}
                  </option>
                </optgroup>
                 <!-- Moderate exercise sorted list -->
                <optgroup label="Moderate">
                  <option
                    *ngFor="let exercise of filterExercise(exercises, 'm')"
                    [value]="exercise.exercise_id">
                    {{ exercise.name }}
                  </option>
                </optgroup>
                 <!-- Vigorous exercise sorted list -->
                <optgroup label="Vigorous">
                  <option
                    *ngFor="let exercise of filterExercise(exercises, 'v')"
                    [value]="exercise.exercise_id">
                    {{ exercise.name }}
                  </option>
                </optgroup>
              </select>
            </div>
            <!-- Alert for exercise typee -->
            <div class="alert alert-danger" *ngIf="!exercise.valid && submitted">
              Please select an activity
            </div>
          </div>
          <!-- END OF EXERCISE TYPE-->

          <!-- START OF OTHER EXERCISE -->
          <div class="form-group" [hidden]="!otherSelected">
            <label for="exercise_other text-center"><span class="num">2b.</span> Please state your exercise</label>
            <input
              id="exercise_other"
              type="text"
              class="form-control"
              ngModel
              name="exercise_other"
              #exercise_other="ngModel"
              placeholder="Please enter an activity"
            >
            <!-- Alert for other exercise -->
            <div class="alert alert-danger" *ngIf="!exercise_other.valid && submitted">
              Please state what activity you did
            </div>
          </div>
          <!-- END OF OTHER EXERCISE -->

          <!-- START OF DURATION-->
          <div class="form-group">
            <!-- Title: Duration -->
            <label for="duration">
              <span class="num">3.</span> Workout Duration
            </label>
            <div class="col-sm-6">
              <div class="row mt-0">
                <!-- Minutes column -->
                <div class="col">
                  <div class="input-group">
                    <input
                      type="number"
                      min="0"
                      id="minutes"
                      class="form-control"
                      ngModel
                      #minutes="ngModel"
                      name="minutes"
                      placeholder="0"
                      minutes
                    />
                    <span class="input-group-text">MIN</span>
                  </div>
                   <label for="minutes" class="sub-label visually-hidden">
                    Minutes
                  </label>
                  <!-- Alert for minutes -->
                  <div
                    class="alert alert-danger"
                    *ngIf="!minutes.valid && submitted">
                    Please enter a number of minutes exercised
                  </div>
                </div>
                <!-- Seconds column -->
                <div class="col">
                  <div class="input-group">
                    <input
                      type="number"
                      min="0"
                      id="seconds"
                      class="form-control"
                      ngModel
                      #seconds="ngModel"
                      name="seconds"
                      placeholder="0"
                      min="0"
                      max="59"
                      seconds
                    />
                    <span class="input-group-text">SEC</span>
                  </div>
                  <label for="seconds" class="sub-label visually-hidden">
                    Seconds
                  </label>
                  <!-- Alert for seconds -->
                  <div
                    class="alert alert-danger"
                    *ngIf="!seconds.valid && seconds.touched">
                      Please enter a number of seconds exercised (0-59)
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END OF DURATION-->

          <!-- START OF DIFFICULTY-->
          <div class="form-group">
            <div class="col-sm-6 input-difficulty">
              <!-- Title: Difficulty -->
              <label for="difficulty">
                <span class="num">4.</span> Difficulty of workout
              </label>
              <div
                class="radio-group"
                [ngClass]="{ chosen: difficulty.dirty || difficulty.touched }">
                <!-- Difficulty button - 1 Very Easy -->
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #difficulty="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="difficulty"
                    id="difficulty1"
                    value="1"
                  />
                  <label class="form-check-label" for="difficulty1">
                    <span class="sr-only">1</span>
                    <span class="icon-wrap">
                      <fa-icon [icon]="['fas', 'grin-stars']"></fa-icon>
                    </span>
                  </label>
                </div>
                <!-- Difficulty button - 2 Easy -->
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #difficulty="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="difficulty"
                    id="difficulty2"
                    value="2"
                  />
                  <label class="form-check-label" for="difficulty2">
                    <span class="sr-only">2</span>
                    <span class="icon-wrap">
                      <fa-icon [icon]="['fas', 'smile']"></fa-icon>
                    </span>
                  </label>
                </div>
                <!-- Difficulty button - 3 Average -->
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #difficulty="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="difficulty"
                    id="difficulty3"
                    value="3"
                  />
                  <label class="form-check-label" for="difficulty3">
                    <span class="sr-only">3</span>
                    <span class="icon-wrap">
                      <fa-icon [icon]="['fas', 'meh']"></fa-icon>
                    </span>
                  </label>
                </div>
                <!-- Difficulty button - 4 Hard -->
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #difficulty="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="difficulty"
                    id="difficulty4"
                    value="4"
                  />
                  <label class="form-check-label" for="difficulty4">
                    <span class="sr-only">4</span>
                    <span class="icon-wrap">
                      <fa-icon [icon]="['fas', 'frown']"></fa-icon>
                    </span>
                  </label>
                </div>
                <!-- Difficulty button - 5 Very Hard -->
                <div class="form-check form-check-inline">
                  <input
                    ngModel
                    #difficulty="ngModel"
                    class="form-check-input"
                    type="radio"
                    required
                    name="difficulty"
                    id="difficulty5"
                    value="5"
                  />
                  <label class="form-check-label" for="difficulty5">
                    <span class="sr-only">5</span>
                    <span class="icon-wrap">
                      <fa-icon [icon]="['fas', 'tired']"></fa-icon>
                    </span>
                  </label>
                </div>
              </div>
              <!-- Alert for difficulty -->
              <div
                class="alert alert-danger"
                *ngIf="!difficulty.valid && submitted">
                Please select a difficulty above
              </div>
            </div>
          </div>
          <!-- END OF DIFFICULTY-->

          <!-- START OF SAVE BUTTON-->
          <button class="btn btn-primary  w-100">
            Save Workout
          </button>
          <!-- END OF SAVE BUTTON-->

        </form>
        <!-- END OF FORM-->

      </div>
    </div>
  </div>
</div>
