import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-prioritising-tool',
  templateUrl: './prioritising-tool.component.html',
  styleUrls: ['./prioritising-tool.component.scss']
})
export class PrioritisingToolComponent implements OnInit {

  environment = environment;
  step:any = 1;
  constructor() { }

  ngOnInit(): void {
  }

  onNextStep(){
    this.step++;
  }

}
