export class User {
    constructor(
      public email: string,
      public id: string,
      public first_name: string,
      public last_name: string,
      private app_token: string,
      private api_token: string,
      private _tokenExpirationDate: Date,
      public avatar: string,
      public language_id: string,
      public week_start: string,
      public current_week: number,
      public username: string
    ) {}

    get token() {
      if (!this._tokenExpirationDate || new Date() > this._tokenExpirationDate) {
        return null;
      }
      return this.api_token;
    }

    set setToken(token:any){
      this.api_token = token;
    }
  }
