<div class="login">
    <div class="main-content-area sub-page pt-4">
      <div class="row">
        <div class="col">
          <app-privacy-content></app-privacy-content>
        </div>
      </div>
    </div>
  </div>
  
  