<div class="dashboard-masthead-container" *ngIf="show">
  <div class="dashboard-masthead dashboard-masthead-goals">
    <div class="dashboard-masthead-content">
      <div class="dashboard-masthead-content-inner">
        <h2>Goals to review</h2>
        <div class="dashboard-masthead-subtitle">
          You have {{ openGoals }} ongoing goals with {{ goalsToReview }} past
          their review date
        </div>

        <div class="d-flex dashboard-masthead-goals-icons">
          <div>
            <span class="icon-wrap text-danger">
              <fa-icon [icon]="['fas', 'circle-exclamation']"></fa-icon>
            </span>
            <span>{{goalsToReview}}</span>
          </div>
          <div>
            <span class="icon-wrap text-warning">
              <fa-icon [icon]="['fas', 'clock']"></fa-icon>
            </span>
            <span>{{openGoals}}</span>
          </div>
          <div>
            <span class="icon-wrap text-success">
              <fa-icon [icon]="['fas', 'check-circle']"></fa-icon>
            </span>
            <span>{{goalsComplete}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
