import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { UserService } from '../auth/service/user.service';
import { Goal } from '../goals/goal.model';
import { GoalsService } from '../goals/goals.service';
import { CardioWorkout } from '../models/cardio-workout.model';
import { StrengthWorkout } from '../models/strength-workout.model';
import { Symptoms } from '../models/symptoms.model';
import { AchievementService } from '../services/achievement.service';
import { CardioWorkoutService } from '../services/cardio-workout.service';
import { DateHelperService } from '../services/date-helper.service';
import { StrengthWorkoutService } from '../services/strength-workout.service';
import { UserSymptomsService } from '../services/user-symptoms.service';
import { UserTaskStatusService } from '../services/user-task-status.service';
import { BitesizeSessionService } from '../services/bitesize-session.service';
import { BitesizeSessionViewService } from '../services/bitesize-session-view.service';
import { Eq5dService } from '../services/eq5d.service';
import { FeedbackService } from '../feedback/feedback.service';
import { KeyValue } from '@angular/common';


import SwiperCore, { SwiperOptions, Pagination } from "swiper";
// install Swiper modules
SwiperCore.use([Pagination]);

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})


export class DashboardComponent implements OnInit {
  isLoading = false;
  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public cardioData: {
    currentWeekData: [];
    daysHit: number;
    weeklyTarget: { days: number; threshold: number };
    workouts: CardioWorkout[];
  } = {
    currentWeekData: [],
    daysHit: 0,
    weeklyTarget: { days: 5, threshold: 10 },
    workouts: [],
  };
  public CardioCurrentWeekData!: any[];
  public today = this.dateHelperService.getToday();
  public cardioDaysHit = 0;
  public cardioWeeklyTarget = { days: 5, threshold: 10 };
  public goals!: Goal[];
  public goalsToReview = 0;
  public goalsReviewed = 0;
  public openGoals = 0;
  public goalsComplete = 0;
  public userData: any;
  currentWeekNumber!: number;
  actualWeekNumber!: number;
  public gettingStartedTaskIds = [
    'welcomeVideo',
    'eq5dPre',
    'readGoals',
    //'setGoal',
    'healthStatusBaseline',
    //'readingList',
    'readActivity',
    'exerciseSafetyQuiz',
  ];

  public strengthData: {
    currentWeekData: [];
    daysHit: number;
    weeklyTarget: { days: number; threshold: number };
    workouts: StrengthWorkout[];
  } = {
    currentWeekData: [],
    daysHit: 0,
    weeklyTarget: { days: 5, threshold: 10 },
    workouts: [],
  };
  public StrengthCurrentWeekData!: any[];
  public strengthDaysHit = 0;
  public strengthWeeklyTarget = this.strengthWorkoutService.weeklyTarget;
  public userSymptoms: Symptoms[] = [];
  public userSymptomsWeek: Symptoms[] = [];
  public symptomsHigh = 0;
  public symptomsUsual = 0;
  public symptomsTotal = 0;
  public symptomsBaseline: any;

  // to do list
  public tasksTotal = 6;
  public tasksComplete = 0;
  public recentAchievements = 0;
  public bitesizeSessions: any;
  public currentBitesizeSession: any;
  public bitesizeSessionViews: any;
  readingListReviewed = false;

  reviewWeekNo = 8;
  reviewWeekNoEarly = 4;
  eq5dResponses: any;
  eq5dResponses4:any;
  feedbackResponses: any;

  latestSymptomScore: any = {};
  baselineScores: any = {};

  public diagnosisMode: any;


  public config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: false,
    pagination: { clickable: true },
    scrollbar: true,
  };

  showMasthead = true;


  eq5dCompletedThisWeek: any = false;
  feedbackCompletedThisWeek:any = false;
  

  constructor(
    private cardioWorkoutService: CardioWorkoutService,
    private dateHelperService: DateHelperService,
    private goalService: GoalsService,
    private userService: UserService,
    private strengthWorkoutService: StrengthWorkoutService,
    private userTaskStatusService: UserTaskStatusService,
    private router: Router,
    private userSymptomsService: UserSymptomsService,
    private achievementService: AchievementService,
    private bitesizeSessionService: BitesizeSessionService,
    private bitesizeSessionViewService: BitesizeSessionViewService,
    private eq5dService: Eq5dService,
    private feedbackService: FeedbackService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    const startDate = this.currentWeek[0];
    const endDate = this.currentWeek[6];
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
        this.currentWeekNumber = +userData.current_week;
        this.actualWeekNumber = +userData.current_week;
        if (
          this.userData.primary_diagnosis &&
          this.gettingStartedTaskIds.indexOf('createActionPlan') == -1
        ) {
          let diagnosisMode = this.userService.getDiagnosisMode(
            this.userData.primary_diagnosis
          );
          this.diagnosisMode = diagnosisMode;
          if (
            diagnosisMode == 'respiratory' ||
            this.userData.primary_diagnosis == 6 //HF
          ) {
            this.gettingStartedTaskIds.push('createActionPlan');
          }
        }
      }
    });

    this.achievementService.refreshNotifications();

    forkJoin({
      cardioWorkouts: this.cardioWorkoutService.fetchBetween(
        startDate,
        endDate
      ),
      strengthWorkouts: this.strengthWorkoutService.fetchBetween(
        startDate,
        endDate
      ),
      goals: this.goalService.fetchGoals(),
      userTaskStatuses: this.userTaskStatusService.fetchAll(),
      userSymptoms: this.userSymptomsService.fetchAll(),
      achievements: this.achievementService.fetchAll(),
      bitesizeSessions: this.bitesizeSessionService.fetchAll(),
      bitesizeSessionViews: this.bitesizeSessionViewService.fetchAll(),
      // targets: this.userTargetService.fetchAll(),
      eq5dResponses: this.eq5dService.fetchResponse(8),
      eq5dResponses4: this.eq5dService.fetchResponse(4),
      feedbackResponses: this.feedbackService.fetchResponse(),
    }).subscribe((responseData) => {
      this.isLoading = false;
      this.cardioData.workouts = responseData.cardioWorkouts;
      this.cardioData.currentWeekData =
        this.cardioWorkoutService.generateWeekArray(
          this.currentWeek,
          this.cardioData.workouts
        );
      this.cardioDaysHit = this.cardioWorkoutService.getDaysHit(
        this.cardioData.currentWeekData
      );

      if (this.cardioDaysHit >= this.cardioWeeklyTarget.days) {
        this.tasksComplete++;
      }

      this.strengthData.workouts = responseData.strengthWorkouts;
      this.strengthData.currentWeekData =
        this.strengthWorkoutService.generateWeekArray(
          this.currentWeek,
          this.strengthData.workouts
        );
      this.strengthDaysHit = this.strengthWorkoutService.getDaysHit(
        this.strengthData.currentWeekData
      );

      if (this.strengthDaysHit >= this.strengthWeeklyTarget.days) {
        this.tasksComplete++;
      }

      this.goals = responseData.goals;
      this.openGoals = 0;
      this.goalsToReview = 0;
      let reviewedGoals = false;

      this.goalsComplete = 0;
      this.goals.forEach((goal) => {
        if(goal.status == 2){
          this.goalsComplete++;
        }
        if (
          moment(goal.modified) > moment(startDate) ||
          moment(goal.created) > moment(startDate)
        ) {
          this.goalsReviewed = 1;
        }
        if (moment(goal.start) <= moment() && goal.status != 2) {
          this.goalsToReview++;
        }
        if (goal.status != 2) {
          this.openGoals++;
        }
      });

      if (this.goalsReviewed == 1 && this.goalsToReview ==0) {
        this.tasksComplete++;
      }

      /*
      this.goalsToReview = this.goals.reduce(
        (acc, cur) => (cur.status !== 1 ? ++acc : acc),
        0
      );
      this.goalsReviewed = this.goals.reduce(
        (acc, cur) =>
          cur.status !== 1 && moment(cur.modified) > moment(startDate)
            ? ++acc
            : acc,
        0
      );*/
      if (reviewedGoals) {
        this.tasksComplete++;
      }

      
      this.userSymptoms = responseData.userSymptoms;

      this.userSymptomsWeek = this.userSymptomsService.getBewteen(
        responseData.userSymptoms,
        startDate,
        endDate
      );


      this.userSymptoms.forEach((symptoms: any) => {
        if (symptoms.baseline == 1 && !this.symptomsBaseline) {
          this.symptomsBaseline = symptoms;
        }
      });
      if (this.userSymptomsWeek[0] && this.symptomsBaseline) {
        this.tasksComplete++;
        this.userSymptomsService.symptomsArray.forEach((symptomInf) => {
          if (
            this.userSymptomsService.checkSymptomForDiagnosis(
              symptomInf.id,
              this.diagnosisMode
            )
          ) {
            this.symptomsTotal++;
            let symptomScore = null;
            symptomScore = this.userSymptomsService.getLatestSymptomScore(
              this.userSymptoms,
              symptomInf.id
            );

            this.latestSymptomScore[symptomInf.id] = symptomScore;

            let baselineScore = null;
            baselineScore = this.userSymptomsService.getSymptomScore(
              this.symptomsBaseline,
              symptomInf.id
            );

            this.baselineScores[symptomInf.id] = baselineScore;
            if (symptomScore || symptomScore == 0) {
              //this.symptomsTotal++;

              if (symptomScore >= 3 && symptomScore > baselineScore) {
                this.symptomsHigh++;
              } else {
                this.symptomsUsual++;
              }
            }
          }
        });
      }

      responseData.achievements.forEach((achievement) => {
        if (moment(achievement.created) > moment(startDate)) {
          this.recentAchievements++;
        }
      });

      // do we need to be on the getting started?
      let gettingStartedTasks = true;
      let tasksComplete: any = [];

      responseData.userTaskStatuses.forEach((taskStatus) => {
        if (taskStatus.status == 1) {
          tasksComplete.push(taskStatus.task_id);
        }
      });
      this.gettingStartedTaskIds.forEach((task) => {
        if (tasksComplete.indexOf(task) == -1) {
          gettingStartedTasks = false;
        }
      });

      if (!gettingStartedTasks) {
        this.router.navigate(['/getting-started']);
      }

      // bitesize sessions
      this.bitesizeSessions = responseData.bitesizeSessions;

      let bitesizeWeek = +this.currentWeekNumber;
      if (this.userData.smoker != 1) {
        bitesizeWeek++;
      }

      if (this.bitesizeSessions[bitesizeWeek]) {
        this.currentBitesizeSession = this.bitesizeSessions[bitesizeWeek - 1];
      }

      this.bitesizeSessionViews = responseData.bitesizeSessionViews;
      // is this task complete?
      if (
        this.currentBitesizeSession &&
        this.bitesizeSessionViewService.checkViewed(
          this.bitesizeSessionViews,
          this.currentBitesizeSession.bitesize_session_id
        )
      ) {
        this.tasksComplete++;
      }

      if (!this.currentBitesizeSession) {
        this.tasksTotal--;
      }

      this.eq5dResponses = responseData.eq5dResponses;
      //this.eq5dResponses4 = responseData.eq5dResponses4;
      this.feedbackResponses = responseData.feedbackResponses;
      // are we on week 8?
      if (this.currentWeekNumber == this.reviewWeekNo) {
        // add the tasks
        this.tasksTotal += 2;

        if (this.eq5dResponses) {
          this.tasksComplete++;
        }

        if (this.feedbackResponses) {
          this.tasksComplete++;
        }
      }
      if (this.currentWeekNumber > this.reviewWeekNo) {
        if (!this.eq5dResponses) {
          this.tasksTotal++;
        } else {
          if (this.eq5dResponses?.created) {
            if (
              moment(this.eq5dResponses.created).format('YYYY-MM-DD') >=
                moment(this.currentWeek[0]).format('YYYY-MM-DD') &&
              moment(this.eq5dResponses.created).format('YYYY-MM-DD') <=
                moment(this.currentWeek[6]).format('YYYY-MM-DD')
            ) {
              this.eq5dCompletedThisWeek = true;
              this.tasksTotal++;
              this.tasksComplete++;
            }
          }
        }
        if (!this.feedbackResponses) {
          this.tasksTotal++;
        } else {
          if (this.feedbackResponses?.created) {
            if (
              moment(this.feedbackResponses.created).format('YYYY-MM-DD') >=
                moment(this.currentWeek[0]).format('YYYY-MM-DD') &&
              moment(this.feedbackResponses.created).format('YYYY-MM-DD') <=
                moment(this.currentWeek[6]).format('YYYY-MM-DD')
            ) {
              this.feedbackCompletedThisWeek = true;
              this.tasksTotal++;
              this.tasksComplete++;
            }
          }
        }
      }



      // are we on week 4?
      /*if (this.currentWeekNumber == this.reviewWeekNoEarly) {
        // add the tasks
        this.tasksTotal += 1;

        if (this.eq5dResponses4) {
          this.tasksComplete++;
        }
      }*/

      // reading list
      responseData.userTaskStatuses.forEach((taskStatus) => {
        if (taskStatus.task_id == 'readingList' + this.currentWeekNumber) {
          this.readingListReviewed = true;
        }
      });

      if (this.readingListReviewed == true) {
        this.tasksComplete++;
      }
    });
  }

  onPrevWeek() {
    this.currentWeek = this.dateHelperService.getWeekFor(
      moment(this.currentWeek[0]).subtract(7, 'days')
    );
    this.currentWeekNumber--;
  }

  onNextWeek() {
    this.currentWeek = this.dateHelperService.getWeekFor(
      moment(this.currentWeek[0]).add(7, 'days')
    );
    this.currentWeekNumber++;
  }
  onResetWeek() {
    this.currentWeek = this.dateHelperService.getCurrentWeek();
    this.currentWeekNumber = this.actualWeekNumber;
  }

  getTotalBitesize() {
    if (this.userData?.smoker != 1) {
      return this.bitesizeSessions.length - 1;
    } else {
      return this.bitesizeSessions.length;
    }
  }

  getSymptomClass(id: any, score: any) {
    let bgClass = 'bg-field';
    if (this.baselineScores) {
      if (this.baselineScores[id] != undefined) {
        if (score > this.baselineScores[id] && score >= 3) {
          bgClass = 'bg-symptom-' + score;
        }
      }
    }
    return bgClass;
  }

  // Preserve original property order
  originalOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return 0;
  };

  // Order by ascending property value
  valueAscOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return a.value?.toString()?.localeCompare(b.value);
  };

  // Order by descending property key
  keyDescOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return a.key > b.key ? -1 : b.key > a.key ? 1 : 0;
  };


  onSwiper(swiper: any) {

  }
  onSlideChange() {}


  onCloseMasthead(event:any){
    this.showMasthead = false;
  }


}
