<ng-container [ngSwitch]="difficulty">
    <span class="difficulty-circle bg-difficulty-1" *ngSwitchCase="1">
      
    </span>
    <span class="difficulty-circle bg-difficulty-2" *ngSwitchCase="2">
      
    </span>
    <span class="difficulty-circle bg-difficulty-3" *ngSwitchCase="3">
      
    </span>
    <span class="difficulty-circle bg-difficulty-4" *ngSwitchCase="4">
      
    </span>
    <span class="difficulty-circle bg-difficulty-5" *ngSwitchCase="5">
      
    </span>
  </ng-container>
  