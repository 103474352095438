<div class="login">
  <div class="main-content-area sub-page pt-4">
    <div class="row">
      <div class="col">
        <app-terms-content></app-terms-content>
      </div>
    </div>
  </div>
</div>

