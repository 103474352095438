import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { map, catchError, tap, publishReplay, refCount } from "rxjs/operators";
import { Observable, Subject, throwError } from "rxjs";

import { environment } from "./../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class SiteContentService {
  cachedResource: any;
  cacheTime = new Date();
  cacheLifetime = 1 * 60 * 1000;

  constructor(private http: HttpClient) {}

  fetchPage(pageId: number): Observable<any> {
    if (!this.cachedResource) {
      this.cachedResource = {};
    }
    const now = new Date();
    if (
      !this.cachedResource[pageId] ||
      Math.abs(now.getTime() - this.cacheTime.getTime()) > this.cacheLifetime
    ) {
      this.cacheTime = new Date();
      this.cachedResource[pageId] = this.http
        .get<any>(environment.apiUrl + "/site_content/" + pageId, {
          responseType: "json",
        })
        .pipe(
          map((responseData) => {
            return responseData;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.cachedResource[pageId];
  }

  parseContent(content: string) {
    // disable video download button
    content = content
      .split("<video ")
      .join('<video controlsList="nodownload" ');
    // update asset refs
    return content.split("/assets/").join(environment.staticUrl + "/");
    //    return content.replace('/\/assets\//g', environment.staticUrl + '/');
  }

  clearCache() {
    this.cachedResource = null;
  }
}
