import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { NgForm } from '@angular/forms';
import { CatQuestionnaireService } from 'src/app/services/cat-questionnaire.service';
import { AuthService } from 'src/app/auth/service/auth.service';
import { UserTaskStatusService } from 'src/app/services/user-task-status.service';

@Component({
  selector: 'app-cat',
  templateUrl: './cat.component.html',
  styleUrls: ['./cat.component.scss'],
})
export class CatComponent implements OnInit {
  @ViewChild('form', { static: false }) catForm!: NgForm;

  submitted = false;
  error!: string;
  isLoading = false;
  stage: any = 1;
  taskStatus = false;
  completed = false;
  responses: any;
  currentScores = {
    cough: 0,
    phlegm: 0,
    chest_tightness: 0,
    breathlessness: 0,
    activity: 0,
    confidence: 0,
    sleep: 0,
    energy: 0,
    total: 0,
  };

  constructor(
    private catQuestionnaireService: CatQuestionnaireService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private userTaskStatusService: UserTaskStatusService
  ) {}

  ngOnInit(): void {
    /*
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userStage = user.stage;
      }
    });
    */
    this.isLoading = true;
    forkJoin({
      responses: this.catQuestionnaireService.fetchResponse(this.stage),
    
    }).subscribe(
      (responseData) => {
        this.isLoading = false;
        this.responses = responseData.responses;

        // have they answered it?
        if (this.responses) {
          this.currentScores = {
            cough: this.responses.cough,
            phlegm: this.responses.phlegm,
            chest_tightness: this.responses.chest_tightness,
            breathlessness: this.responses.breathlessness,
            activity: this.responses.activity,
            confidence: this.responses.confidence,
            sleep: this.responses.sleep,
            energy: this.responses.energy,
            total: this.responses.total,
          };
          this.completed = true;
        }
      },
      (error) => {
        // console.log(error);
      }
    );
  }

  onSubmit() {
    this.catQuestionnaireService
      .saveResponse(
        this.stage,
        this.catForm.value.cough,
        this.catForm.value.phlegm,
        this.catForm.value.chestTightness,
        this.catForm.value.breathlessness,
        this.catForm.value.activity,
        this.catForm.value.confidence,
        this.catForm.value.sleep,
        this.catForm.value.energy
      )
      .subscribe((responseData) => {
        this.userTaskStatusService.create('catPre', 1).subscribe((responseData)=>{});
        this.completed = true;
        window.scroll(0, 0);

        this.currentScores.total =
          +this.catForm.value.cough +
          +this.catForm.value.phlegm +
          +this.catForm.value.chestTightness +
          +this.catForm.value.breathlessness +
          +this.catForm.value.activity +
          +this.catForm.value.confidence +
          +this.catForm.value.sleep +
          +this.catForm.value.energy;
      });
  }
}
