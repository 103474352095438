import { ActivatedRoute } from '@angular/router';
import { ResourcesService } from '../../services/resources.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-resource-index',
  templateUrl: './resource-index.component.html',
  styleUrls: ['./resource-index.component.scss'],
})
export class ResourceIndexComponent implements OnInit, OnDestroy {
  pages:any;
  resourceId!:number;
  resource:any;
  isLoading = false;
  constructor(
    public resourcesService: ResourcesService,
    private route: ActivatedRoute,
    private titleService: Title,
    private navbarService: NavbarService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.resourceId = params.id;
      this.isLoading = true;
      this.resourcesService
        .fetchResource(this.resourceId)
        .subscribe((responseData) => {
          this.isLoading = false;
          this.pages = responseData.pages;
          this.resource = responseData.resource;
          // set custom HTML title
          this.titleService.setTitle(environment.title + ' | Resources | ' + this.resource.title);
          this.navbarService.customTitle.next(this.resource.title);
        });
    });
  }
  ngOnDestroy(){
    this.navbarService.customTitle.next('');
  }
}
