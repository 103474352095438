import { Component, Input, OnInit } from '@angular/core';
import { DateHelperService } from 'src/app/services/date-helper.service';

@Component({
  selector: 'app-dashboard-masthead-strength',
  templateUrl: './dashboard-masthead-strength.component.html',
  styleUrls: ['./dashboard-masthead-strength.component.scss']
})
export class DashboardMastheadStrengthComponent implements OnInit {
  show = true;
  @Input() strengthWeeklyTarget: any;
  @Input() currentWeekData: any;
  @Input() strengthDaysHit: any;

  public today = this.dateHelperService.getToday();

  constructor(private dateHelperService: DateHelperService) {}

  ngOnInit(): void {
  }
  onClose(event:any){
    this.show = false;
  }
}
