import { environment } from './../../../environments/environment';
import { DatePipe } from '@angular/common';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

import { AuthService, AuthResponseData } from '../service/auth.service';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  isLoading = false;
  error: string | null = null;
  step = 0;
  userData = { email: '', code: '' };
  regData?: any;
  userDetails?: any;
  user?: any;
  currentYear: number = new Date().getFullYear();


  password1 = '';
  password2 = '';
  environment = environment;

  constructor(
    private authService: AuthService,
    private router: Router,
    private datePipe: DatePipe,
    private modalService: BsModalService,
    public userService: UserService
  ) {}

  ngOnInit(): void {
    // are we logged in?
    const user = this.authService.user.getValue();
    if (user) {
      if (this.authService.redirect) {
        this.router.navigate([this.authService.redirect]);
      } else {
        this.router.navigate(['/dashboard']);
      }
    }
  }

  onNext(event: any) {
    this.step++;
    window.scroll(0, 0);
  }
  onPrev(event: any) {
    this.step--;
    window.scroll(0, 0);
  }

  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }
    const code = form.value.code;
    const email = form.value.email;
    let authObs: Observable<AuthResponseData>;

    this.isLoading = true;

    authObs = this.authService.checkCode(code, email);

    authObs.subscribe(
      (resData) => {
        // console.log(resData);
        this.error = null;
        this.isLoading = false;
        this.step = 1;
        this.populateRegForm(resData);
        this.user.code = code;
        this.user.email = email;
        window.scroll(0, 0);
      },
      (errorMessage) => {
        this.error = 'Your code or email address were not recognised.';
        this.isLoading = false;
      }
    );
  }

  private populateRegForm(userData: any) {
    //console.log(userData);
    this.regData = userData.regData;
    this.userDetails = userData.userDetails;
    /*this.weight = this.userDetails.weight;
    this.height = this.userDetails.height;
    if (this.userDetails.dob) {
      const bits = this.userDetails.dob.split('/');
      this.bsValue = new Date(bits[2] + '/' + bits[1] + '/' + bits[0]);
    }
    if (this.userDetails.hospital_admission_date) {
      const bits = this.userDetails.hospital_admission_date.split('/');
      // this.bsValueB = new Date(bits[2] + '/' + bits[1] + '/' + bits[0]);
    }
    this.userMedications = userData.userMedications;
    this.userConditions = userData.userConditions;*/
    this.user = userData.user;
  }

  onSubmitRegistration(form: NgForm) {
    this.isLoading = true;
    if (!form.valid) {
      this.isLoading = false;
      return;
    }
    let conditions = [];
    this.authService
      .register(
        this.user.code,
        this.user.email,
        form.value.accountSetup.passwords.password
      )
      .subscribe(
        (resData) => {
          // console.log(resData);
          this.isLoading = false;
          this.router.navigate(['/dashboard']);
          // this.router.navigate(['/questionnaires/cat']);
        },
        (errorMessage) => {
          this.error = errorMessage;
          this.isLoading = false;
          window.scroll(0, 0);
        }
      );
  }

  checkPassword(password: string, check: string) {
    switch (check) {
      case 'upper':
        return /[A-Z]/.test(password);
        break;
      case 'lower':
        return /[a-z]/.test(password);
        break;
      case 'num':
        return /[0-9]/.test(password);
        break;
      case 'special':
        return /[$@$!%*?&\\{}\[\]\(\)`]/.test(password);
        break;
    }
    return;
  }

  trackByIndex = (index: number): number => {
    return index;
  };
}
