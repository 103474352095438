import { environment } from 'src/environments/environment';
import { UserService } from '../service/user.service';
import { Observable } from 'rxjs';
import { NgForm } from '@angular/forms';
import { AuthService } from './../service/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { UserDataInterface } from '../service/userData.interface';

@Component({
  selector: 'app-update-details',
  templateUrl: './update-details.component.html',
  styleUrls: ['./update-details.component.scss'],
})
export class UpdateDetailsComponent implements OnInit {
  isLoading = false;
  error: string = '';
  updated = false;
  userData!: UserDataInterface;
  environment = environment;

  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
      }
    });
  }

  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }
    const email = form.value.email;
    const firstName = form.value.firstName;
    const lastName = form.value.lastName;
    const language_id = form.value.language;
    const week_start = form.value.week_start;
    //const screenName = form.value.screenName;
    let authObs: Observable<any>;

    this.isLoading = true;

    authObs = this.authService.updateDetails(
      email,
      firstName,
      lastName,
      language_id,
      week_start
      
    );

    authObs.subscribe(
      (resData) => {
        this.isLoading = false;
        this.updated = true;
        this.router.navigate(['/dashboard']);
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Details updated',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          toast: true,
        });
      },
      (errorMessage) => {
        this.error = errorMessage;
        this.isLoading = false;
      }
    );
  }
}
