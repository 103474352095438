import { Injectable } from '@angular/core';
import { ResourcesService } from './resources.service';
import { SiteContentService } from './site-content.service';
import { BitesizeSessionService } from './bitesize-session.service';
import { CardioWorkoutService } from './cardio-workout.service';
import { UserTaskStatusService } from './user-task-status.service';
import { AchievementService } from './achievement.service';
import { StrengthWorkoutService } from './strength-workout.service';
import { UserSymptomsService } from './user-symptoms.service';
import { ActionPlanService } from './action-plan.service';
import { MessagesService } from './messages.service';
import { NotificationService } from './notification.service';
import { PageViewService } from './page-view.service';
import { SettingsService } from './settings.service';
import { UserTargetService } from './user-target.service';
import { WeightService } from './weight.service';

@Injectable({
  providedIn: 'root',
})
export class CacheManagerService {
  constructor(
    private resoucesService: ResourcesService,
    private siteContentService: SiteContentService,
    private bitesizeSessionService: BitesizeSessionService,
    private cardioWorkoutService: CardioWorkoutService,
    private userTaskStatusService: UserTaskStatusService,
    private achievementService: AchievementService,
    private strengthWokrourService: StrengthWorkoutService,
    private userSymptomService: UserSymptomsService,
    private actionPlanService: ActionPlanService,
    private messageService:MessagesService,
    private notificationService:NotificationService,
    private pageViewService:PageViewService,
    private userTargetService:UserTargetService,
    private weightService:WeightService
  ) {}

  clearAllCache() {
    this.resoucesService.clearCache();
    this.siteContentService.clearCache();
    this.bitesizeSessionService.clearCache();
    this.cardioWorkoutService.clearCache();
    this.userTaskStatusService.clearCache();
    this.achievementService.clearCache();
    this.strengthWokrourService.clearCache();
    this.userSymptomService.clearCache();
    this.actionPlanService.clearCache();
    this.messageService.clearCache();
    this.notificationService.clearCache();
    this.pageViewService.clearCache();
    this.userTargetService.clearCache();
    this.weightService.clearCache();
  }
}
