import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UserTaskStatus } from '../models/user-task-status.model';

@Injectable({
  providedIn: 'root'
})
export class UserTaskStatusService {
  error = new Subject<string>();
  slug = "/user_task_statuses";
  resourceName = "user_task_statuses";
  allTasks?: Observable<UserTaskStatus[]> | null = null;

  constructor(
    private http: HttpClient
  ) {}

  
  fetchAll(): Observable<UserTaskStatus[]> {
    if (!this.allTasks) {
      this.allTasks = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: "json",
        })
        .pipe(
          map((responseData) => {
            const returnArray: UserTaskStatus[] = [];
            responseData["_embedded"][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allTasks;
  }

  clearCache() {
    this.allTasks = null;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + "/" + id, {
        responseType: "json",
      })
      .pipe(
        map((responseData) => {
          const item = new UserTaskStatus(
            +responseData.user_task_status_id,
            +responseData.user_id,
            responseData.task_id,
            +responseData.status,
            responseData.created,
            responseData.modified
          );
          return item;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(
    task_id: string,
    status: number,
  ) {
    this.clearCache();
    const payload = {
      task_id,
      status,
    };
    return this.http.post<UserTaskStatus>(
      environment.apiUrl + this.slug,
      payload,
      {
        observe: "response",
      }
    );
  }

  update(
    id: number,
    task_id: string,
    status: number,
  ) {
    this.clearCache();
    const payload = {
      task_id,
      status,
    };
    return this.http.patch<UserTaskStatus>(
      environment.apiUrl + this.slug + "/" + id,
      payload,
      {
        observe: "response",
      }
    );
  }

  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + "/" + id
    );
  }
}