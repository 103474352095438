<div class="main-content-area exercise-mastheads">
  <div class="row">
    <div class="col-sm-6 mb-3">
      <div class="card card-cardio">
        <a [routerLink]="['/exercise', 'cardio']" class="stretched-link">
          <span class="visually-hidden">Go to Cardio</span>
        </a>
        <div class="card-title">
          <h3>Cardio</h3>
          <span class="icon-wrap float-end icon-more"
            ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
          ></span>
        </div>
        <div class="card-bg-image">
          <span class="tracker-info">
            <span class="tracker-title">Weekly Target</span>
            <span class="progress progress-dividers">
              <span
                *ngFor="
                  let num of [].constructor(cardioWeeklyTarget.days);
                  let index = index
                "
                class="progress-bar"
                [ngClass]="{
                  'bg-success': index < cardioDaysHit,
                  'bg-white': index >= cardioDaysHit
                }"
                role="progressbar"
                style="width: {{ 100 / cardioWeeklyTarget.days }}%"
                [attr.aria-valuenow]="100 / cardioWeeklyTarget.days"
                aria-valuemin="0"
                aria-valuemax="100"
                [attr.aria-label]=" 100 / cardioWeeklyTarget.days + '%'"
              ></span>
            </span>

            <span class="details"
              >{{ cardioDaysHit }} of {{ cardioWeeklyTarget.days }} days
              completed</span
            >
          </span>
        </div>
      </div>
    </div>
    <div class="col-sm-6 mb-3">
      <div class="card card-strength">
        <a [routerLink]="['/exercise', 'strength']" class="stretched-link">
          <span class="visually-hidden">Go to strength</span>
        </a>
        <div class="card-title">
          <h3>Strength</h3>
          <span class="icon-wrap float-end icon-more"
            ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
          ></span>
        </div>
        <div class="card-bg-image">
          <span class="tracker-info">
            <span class="tracker-title">Weekly Target</span>

            <span class="progress progress-dividers">
              <span
                *ngFor="
                  let num of [].constructor(strengthWeeklyTarget.days);
                  let index = index
                "
                class="progress-bar"
                [ngClass]="{
                  'bg-success': index < strengthDaysHit,
                  'bg-white': index >= strengthDaysHit
                }"
                role="progressbar"
                style="width: {{ 100 / strengthWeeklyTarget.days }}%"
                [attr.aria-valuenow]="100 / strengthWeeklyTarget.days"
                aria-valuemin="0"
                aria-valuemax="100"
                [attr.aria-label]=" 100 / strengthWeeklyTarget.days + '%'"
              ></span>
            </span>

            <span class="details"
              >{{ strengthDaysHit }} of {{ strengthWeeklyTarget.days }} days
              completed</span
            >
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="more-resources p-0">
        <ul class="tracker-list">
          <li>
            <a class="task" [routerLink]="['/exercise', 'about']">
              <span class="tracker-info">
                <span class="tracker-title">Read about your Exercise Programme</span>
              </span>
              <span class="icon-wrap float-end icon-more"
                ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon>
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
