import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { StrengthWorkout } from 'src/app/models/strength-workout.model';
import { AchievementService } from 'src/app/services/achievement.service';
import { StrengthWorkoutService } from 'src/app/services/strength-workout.service';

@Component({
  selector: 'app-view-strength-workout',
  templateUrl: './view-strength-workout.component.html',
  styleUrls: ['./view-strength-workout.component.scss'],
})
export class ViewStrengthWorkoutComponent
  implements OnInit
{
  submitted = false;
  error?: string;
  public errors: any = [];
  isLoading = false;
  public entryId!: number;
  public workout?:StrengthWorkout;
  maxDate = new Date();
  workoutDate?: Date;
  exercisesPicked: any = [];
  exercisesSelected = false;
  submittedStep = [false, false, false, false];
  hasDeleted = false;
  totalReps = 0;

  prevWorkout:any;
  nextWorkout:any;

  // biceps curls, Pull ups/pull ups, sit to stand and step ups
  exercises = [
    { id: 'bicepCurl', label: 'Bicep Curls' },
    { id: 'pullUps', label: 'Pull ups' },
    { id: 'sitToStand', label: 'Sit to Stand' },
    { id: 'stepUps', label: 'Step ups' },
  ];
  step = 0;
  exerciseData: any;

  constructor(
    private strengthWorkoutService: StrengthWorkoutService,
    private router: Router,
    private route: ActivatedRoute,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.prevWorkout = null;
      this.nextWorkout = null;
      this.totalReps = 0;
      this.entryId = params.id;
      this.isLoading = true;
      forkJoin({
        workout: this.strengthWorkoutService.fetch(this.entryId),
        allWorkouts: this.strengthWorkoutService.fetchAll(),
      }).subscribe(
        (responseData) => {
          this.isLoading = false;
          this.workout = responseData.workout;

          responseData.allWorkouts.forEach((_workout, index)=>{
            if(+_workout.strength_workout_id == +this.entryId){
              if(responseData.allWorkouts[index-1]){
                this.nextWorkout = responseData.allWorkouts[index-1];
              }
              if(responseData.allWorkouts[index+1]){
                this.prevWorkout = responseData.allWorkouts[index+1];
              }
            }
          });


          this.exerciseData = JSON.parse(this.workout.exercises);
          if (!this.exerciseData) {
            this.exerciseData = {
              bicepCurl: {
                weight: null,
                set1: '',
                set2: '',
                set3: '',
                difficulty: 0,
              },
              pullUps: {
                weight: null,
                set1: '',
                set2: '',
                set3: '',
                difficulty: 0,
              },
              sitToStand: {
                weight: null,
                set1: '',
                set2: '',
                set3: '',
                difficulty: 0,
              },
              stepUps: {
                weight: null,
                set1: '',
                set2: '',
                set3: '',
                difficulty: 0,
              },
            };
          }
          // fill in blank data
          this.exercises.forEach((exercise) => {
            if (!this.exerciseData[exercise.id]) {
              this.exerciseData[exercise.id] = {
                weight: null,
                set1: '',
                set2: '',
                set3: '',
                difficulty: 0,
              };
            } else {
              this.exercisesPicked.push(exercise);
            }

            this.totalReps += + this.exerciseData[exercise.id].set1 + +this.exerciseData[exercise.id].set2 + +this.exerciseData[exercise.id].set3;
          });
        },
        (error) => {
          this.errors.push(error.message);
        }
      );
    });
  }

}
