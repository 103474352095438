
<div class="bg-white">
<div class="container nmh relative pt-2">
  <app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
  <div *ngIf="messages">
    <div *ngFor="let message of messages">
      <div
        class="speech-bubble"
        [ngClass]="{
          'speech-bubble-left': message.is_admin,
          'speech-bubble-right': !message.is_admin
        }"
      >
        {{ message.message }}
      </div>
      <div class="msg-details" [ngClass]="{
        'text-end': !message.is_admin
      }">
        <strong
          ><span *ngIf="message.is_admin">Technical Support</span
          ><span *ngIf="!message.is_admin">You</span></strong
        >
        | {{ message.created | customDate : "ddd Do MMM yyyy, h.mm a" }}
      </div>
    </div>
  </div>

  <div *ngIf="messages.length < 1">
    <p class="text-center pt-3 pb-3">You haven't asked any questions yet.</p>
  </div>
</div>
<div class="chat-input-container hfn">
  <div class="inner-addon right-addon">
    <form class="message-form" (ngSubmit)="onSubmit()" #form="ngForm">
      <button
        class="btn btn-primary addon  btn-icon-only d-lg-none d-xl-none"
        [disabled]="!message.valid"
      >
        <span class="icon-wrap"
          ><fa-icon
            [icon]="['far', 'paper-plane-top']"
            [fixedWidth]="true"
          ></fa-icon
        ></span>
      </button>
      <textarea
        type="text"
        class="chat-input form-control form-control-lg"
        placeholder="Write a message..."
        name="message"
        id="message"
        rows="1"
        ngModel
        #message="ngModel"
        required
        maxlength="65535"
        minlength="3"
        autocomplete="off"
        [readonly]="submitted"
        (keyup)="autoGrow()"
      ></textarea>

      <button
        class="btn btn-primary w-100 mt-2  d-none d-sm-none d-md-none d-xl-block d-lg-block"
        [disabled]="!message.valid || submitted"
      >
      Send Message
      <span class="icon-wrap" *ngIf="submitted"
      ><fa-icon
        [icon]="['fal', 'spinner']" [spin]="true"
        [fixedWidth]="true"
      ></fa-icon
    ></span>
      <span class="icon-wrap" *ngIf="!submitted"
        ><fa-icon
          [icon]="['far', 'paper-plane-top']"
          [fixedWidth]="true"
        ></fa-icon
      ></span>
      </button>
    </form>
  </div>
</div>
</div>