<div class="main-content-area">
  <div class="mt-n3">
    <div class="row">
      <div class="col">
        <form
          class="ii-form mt-3"
          (ngSubmit)="onSubmit()"
          #form="ngForm"
          *ngIf="goal"
        >
          <fieldset ngModelGroup="goalInputs" #goalInputs="ngModelGroup">
            <div class="form-group">
              <label for="what"
                ><span class="num">1.</span> Write out your goal</label
              >
              <textarea
                name="what"
                id="what"
                class="form-control"
                placeholder="Enter text..."
                rows="8"
                [ngModel]="goal.what"
                #what3="ngModel"
                required
                maxlength="65535"
                minlength=""
              ></textarea>
            </div>

            <div class="form-group">
              <label for="start"
                ><span class="num">2.</span> Date to review goal
              </label>

              <div class="input-group">
                <span class="input-group-icon">
                  <fa-icon
                    [icon]="['far', 'calendar-alt']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                <input
                  class="form-control form-control-date"
                  name="start"
                  #start="bsDatepicker"
                  bsDatepicker
                  placeholder="DD/MM/YYYY"
                  autocomplete="off"
                  placement="bottom"
                  [bsConfig]="{
                    dateInputFormat: 'DD/MM/YYYY',
                    containerClass: 'theme-impact',
                    showWeekNumbers: false
                  }"
                  [minDate]="today"
                  [(ngModel)]="bsValue"
                  required
                />
              </div>
            </div>
            <div class="form-group">
              <label for="progress"
                ><span class="num">3.</span>  How far are you to achieving your goal?</label
              >
              <div class="slider-container">
                <span class="slider-value mb-2">
                  <input
                  name="progress"
                    type="text"
                    class="form-control visually-hidden"
                    value="{{ goal.progress }}%"
                  />
                </span>
                <span class="slider-wrap">
                  <ngx-slider
                    [(value)]="goal.progress"
                    [options]="options"
                  ></ngx-slider>
                </span>
              </div>
            </div>
          </fieldset>

          <!--fieldset ngModelGroup="goalInputs" #goalInputs="ngModelGroup">
      <div class="form-group "><label for="what"
          ><span class="num">1</span> What do I want to be able to dos?</label
        >
        <textarea
          name="what"
          id="what"
          class="form-control"
          placeholder="Enter text..."
          rows=""
          [ngModel]="goal.what"
          #what3="ngModel"
          required
          maxlength="65535"
          minlength=""
        ></textarea
        >
      </div>
      <div class="form-group "><label for="why"
          ><span class="num">2</span> Why is it important to me?​</label
        >
        <textarea
          name="why"
          id="why"
          class="form-control"
          placeholder="Enter text..."
          rows=""
          [ngModel]="goal.why"
          #why3="ngModel"
          required
          maxlength="65535"
          minlength=""
        ></textarea
        >
      </div>
      <div class="form-group "><label for="how"
          ><span class="num">3</span> How am I going to achieve this?​​</label
        >
        <textarea
          name="how"
          id="how"
          class="form-control"
          placeholder="Enter text..."
          rows=""
          [ngModel]="goal.how"
          #how3="ngModel"
          required
          maxlength="65535"
          minlength=""
        ></textarea
        >
      </div>
      <div class="form-group "><label for="barriers"
          ><span class="num">4</span> What might stop me?
        </label>
        <textarea
          name="barriers"
          id="barriers"
          class="form-control"
          placeholder="Enter text..."
          rows=""
          [ngModel]="goal.barriers"
          #barriers3="ngModel"
          required
          maxlength="65535"
          minlength=""
        ></textarea>
        
      </div>
      <div class="form-group "> <label for="start"
          ><span class="num">5</span> When should I review this goal?​
        </label>
        <input
          class="form-control"
          name="start"
          #start="bsDatepicker"
          bsDatepicker
          placeholder="DD/MM/YYYY"
          autocomplete="off"
          placement="top"
          [bsConfig]="{
            dateInputFormat: 'DD/MM/YYYY',
            containerClass: 'theme-impact',
            showWeekNumbers: false
          }"
          [(ngModel)]="bsValue"
          required
        />
       
      </div>
    </fieldset-->

          <button class="btn btn-primary w-100" [disabled]="!goalInputs.valid">
            Submit
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
