<div class="main-content-area sub-page container-resource-page">
  <app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
  <div class="row">
    <div class="col">
      <h3 *ngIf="gettingStarted" class="mt-3 mb-3 text-center">The Exercise Programme</h3>
        <div
          *ngIf="resource"
          [innerHTML]="resource?.pages[0].content | safeHtml"
          (click)="onContentClick($event)"
        ></div>
        <div class="text-center" *ngIf="gettingStarted">
          <button class="btn btn-primary" [routerLink]="['/getting-started']">Continue</button>
        </div>
    </div>
  </div>
</div>
<div *ngIf="gettingStarted">
  <div class="signin-details">
    <div class="signin-details-flex">
      <div class="signin-left">
        © {{ currentYear }} University Hospitals of Leicester
      </div>
      <div class="signin-right">
        <a [routerLink]="['/getting-started/terms']"
        >Terms &amp; Conditions</a
      >&nbsp;|&nbsp; 
      <a [routerLink]="['/getting-started/privacy']">Privacy Policy</a>
      </div>
    </div>
  </div>
  <div class="">
    <div class="col footer-logos">
      <div class="programme-logo-strap">Incorporating the programmes</div>
      <div class="programme-logos">
        <img
          src="./assets/img/logo-ayh-colour.svg"
          alt="Activate Your Heart Logo"
          width="93"
        />
        <img
          src="./assets/img/logo-space-colour.svg"
          alt="SPACE for COPD"
          width="69"
        />
        <img
          src="./assets/img/logo-ycr-colour.svg"
          alt="Your COVID Recovery"
          width="93"
        />
      </div>
    </div>
  </div>
</div> 