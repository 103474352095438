export class Symptoms {
  public user_symptom_id: number;
  public user_id: number;
  public cough: number;
  public phlegm: number;
  public chest_tightness: number;
  public energy: number;
  public sob: number;
  public activity: number;
  public sleep: number;
  public confidence: number;
  public mood: number;
  public appetite: number;
  public ischaemic_pain: number;
  public ankle_swelling: number;
  public surgery_pain: number;
  public anxiety:number;
  public fatigue:number;
  public memory:number;
  public total: number;
  public baseline: number;
  public created: string;
  public modified?: string;

  constructor(
    user_symptom_id: number,
    user_id: number,
    cough: number,
    phlegm: number,
    chest_tightness: number,
    energy: number,
    sob: number,
    activity: number,
    sleep: number,
    confidence: number,
    mood: number,
    appetite: number,
    ischaemic_pain: number,
    ankle_swelling: number,
    surgery_pain: number,
    anxiety: number,
    fatigue: number,
    memory: number,
    total: number,
    baseline:number,
    created: string,
    modified?: string
  ) {
    this.user_symptom_id = user_symptom_id;
    this.user_id = user_id;
    this.cough = cough;
    this.phlegm = phlegm;
    this.chest_tightness = chest_tightness;
    this.energy = energy;
    this.sob = sob;
    this.activity = activity;
    this.sleep = sleep;
    this.confidence = confidence;
    this.mood = mood;
    this.appetite = appetite;
    this.ischaemic_pain = ischaemic_pain;
    this.ankle_swelling = ankle_swelling;
    this.surgery_pain = surgery_pain;
    this.anxiety = anxiety;
    this.fatigue = fatigue;
    this.memory = memory;
    this.total = total;
    this.baseline = baseline;
    this.created = created;
    this.modified = modified;
  }
}
