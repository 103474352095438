import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-masthead-bitesize',
  templateUrl: './dashboard-masthead-bitesize.component.html',
  styleUrls: ['./dashboard-masthead-bitesize.component.scss']
})
export class DashboardMastheadBitesizeComponent implements OnInit {
  show = true;
  @Input()currentBitesizeSession:any;
  @Input()currentBitesizeNumber:any;
  @Input()totalBitesize:any;

  constructor() { }

  ngOnInit(): void {
  }
  onClose(event:any){
    this.show = false;
  }

}
