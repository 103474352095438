<div class="dashboard-masthead-container" *ngIf="show">
  <div class="dashboard-masthead dashboard-masthead-health">
    <div class="dashboard-masthead-content">
      <div class="dashboard-masthead-content-inner">
        <h2>How is your health today?</h2>
        <div class="dashboard-masthead-subtitle">

          <ng-container *ngIf="symptomsHigh!=0">You last reported {{symptomsHigh}} high symptom<span *ngIf="symptomsHigh!=1">s</span></ng-container>
          <ng-container *ngIf="symptomsHigh==0">No high symptoms</ng-container>
          

        </div>

        <span class="progress progress-dividers" *ngIf="symptomsHigh > 0">
          <span
            *ngFor="
              let latestScore of latestSymptomScore | keyvalue : valueAscOrder;
              let index = index
            "
            class="progress-bar {{
              getSymptomClass(latestScore.key, latestScore.value)
            }}"
            role="progressbar"
            style="width: 14.285714%"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
            aria-label="symptom block"
          ></span>
        </span>

        <span class="progress progress-dividers" *ngIf="symptomsHigh == 0">
          <span
            *ngFor="
              let latestScore of latestSymptomScore | keyvalue : valueAscOrder;
              let index = index
            "
            class="progress-bar bg-symptom-{{ latestScore.value }}"
            role="progressbar"
            style="width: 14.285714%"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
            aria-label="symptom block"
          ></span>
        </span>

        <div class="dashboard-masthead-subtext">
          Keep track of your health with with our Trackers.
        </div>
      </div>
    </div>
  </div>
</div>
