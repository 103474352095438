import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/service/auth.service';
import { UserService } from 'src/app/auth/service/user.service';
import { UserSymptomsService } from 'src/app/services/user-symptoms.service';

@Component({
  selector: 'app-health-status-feedback',
  templateUrl: './health-status-feedback.component.html',
  styleUrls: ['./health-status-feedback.component.scss'],
})
export class HealthStatusFeedbackComponent implements OnInit {
  @Input() currentScore!: number;
  @Input() previousScore!: number;
  @Input() symptom!: string;



  userData!:any;
  public diagnosisMode: any;

  exacerbationsResourceId:any = {
    1: 52, // copd
    2: 76, // 'asthma',
    3: 73, // 'Bronchiectasis',
    4: 70 //'ILD'
  }

  constructor(
    private datePipe: DatePipe,
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    public userSymptomsService: UserSymptomsService
  ) {}

  ngOnInit(): void {
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;

        if (this.userData.primary_diagnosis) {
          this.diagnosisMode = this.userService.getDiagnosisMode(
            this.userData.primary_diagnosis
          );
        }
      }
    });
  }
}
