import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ChartConfiguration, ChartData, ChartEvent, ChartType } from 'chart.js';
import { Subscription } from 'rxjs';
import { Symptoms } from 'src/app/models/symptoms.model';
import { UserSymptomsService } from 'src/app/services/user-symptoms.service';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import { NavbarService } from 'src/app/services/navbar.service';

@Component({
  selector: 'app-health-status-symptom',
  templateUrl: './health-status-symptom.component.html',
  styleUrls: ['./health-status-symptom.component.scss'],
})
export class HealthStatusSymptomComponent implements OnInit {
  public barChartOptions: ChartConfiguration['options'] = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      x: {
        title: {
          display: true,
          text: 'Date',
          color: '#222529',
        },
        grid: {
          display: false,
        },
      },
      y: {
        min: 0,
        max: 5,
        ticks: { stepSize: 1 },
        title: {
          display: true,
          text: 'Symptom Score',
          color: '#222529',
        },
        grid: {
          display: false,
        },
      },
    },

    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };

  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  public barChartData: ChartData<'bar'> = {
    labels: [],
    datasets: [{ data: [], label: '', backgroundColor: [] }],
  };

  public barChartPlugins = [DataLabelsPlugin];
  public currentSymptom: any;

  public symptoms: Symptoms[] = [];
  isFetching = false;
  error = null;
  private errorSub!: Subscription;

  colors: any = [];
  symptomsBaseline:any;

  constructor(
    public userSymptomsService: UserSymptomsService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private navbarService: NavbarService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.currentSymptom = params.symptom;
      let symptomTitle = this.currentSymptom;
      this.userSymptomsService.symptomsArray.forEach((__symptom) => {
        if (__symptom.id == this.currentSymptom) {
          symptomTitle = __symptom.title;
        }
      });

      this.navbarService.customTitle.next(symptomTitle);

      this.barChartData.datasets[0].label = this.currentSymptom;
      this.errorSub = this.userSymptomsService.error.subscribe(
        (errorMessage) => {
          // this.error = errorMessage;
        }
      );

      this.isFetching = true;
      this.symptoms = [];
      this.userSymptomsService.fetchAll().subscribe(
        (symptoms) => {
          this.isFetching = false;
          symptoms.forEach((_symptom: any) => {
            if (_symptom[this.currentSymptom] != undefined) {
              this.symptoms.push(_symptom);
            }
          });

          symptoms.forEach((symptoms: any) => {
            if (symptoms.baseline == 1 && !this.symptomsBaseline) {
              this.symptomsBaseline = symptoms;
            }
          });

          this.setChartData(this.symptoms);
        },
        (error) => {
          this.isFetching = false;
          this.error = error.message;
        }
      );
    });
  }

  setChartData(symptoms: any) {
    this.barChartData.datasets[0].data = [];
    this.barChartData.labels = [];
    this.barChartData.datasets[0].backgroundColor = [];
    this.barChartData.datasets[0].borderRadius = { topLeft: 5, topRight: 5 };
    symptoms.forEach((symptom: any, index: any) => {
      this.barChartData.datasets[0].data.push(symptom[this.currentSymptom]);
      this.barChartData.labels?.push(
        this.datePipe.transform(symptom.created.replace(/-/g, '/'), 'MMM dd')
      );

      const percent = (symptom[this.currentSymptom] / 5) * 100;
      let bgColour = '';
      if (percent <= 0) {
        bgColour = '#7AE571';
      } else if (percent > 0 && percent <= 20) {
        bgColour = '#FFD800';
      } else if (percent > 20 && percent <= 40) {
        bgColour = '#FFD800';
      } else if (percent > 40 && percent <= 60) {
        bgColour = '#FFB100';
      } else if (percent > 60 && percent <= 80) {
        bgColour = '#F26E24';
      } else {
        bgColour = '#DE3535';
      }
      this.colors.push(bgColour);
    });
    // reverse them
    this.barChartData.datasets[0].data.reverse();
    this.barChartData.datasets[0].backgroundColor = this.colors.reverse();
    this.barChartData.datasets[0].hoverBackgroundColor = this.colors.reverse();
    //this.barChartData.datasets[0].backgroundColor?.reverse();
    this.colors.reverse();
    this.barChartData.labels.reverse();
  }

  /*
  getDate(value: any): Date {
    // https://github.com/angular/angular/issues/12334
    return value ? new Date(value.replace(/\s/g, 'T')) : undefined;
  }*/

  getSymptomDiff(symptomA: any, symptomB: any, symptomId: any) {
    let diff: any = 0;

    diff = symptomA[symptomId] - symptomB[symptomId];

    if (diff > 0) {
      diff = '+' + diff;
    }
    if (diff == 0) {
      diff = '-';
    }
    return diff;
  }
}
