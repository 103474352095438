<div class="">
  <app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
  <div class="">
    <div class="col">
      <div *ngIf="!completed"
            class="card text-center card-light mb-3 border-radius-bottom-only"
          >
            <div class="card-body text-center">
      <h3 class="sm-title text-center">Exercise Safety Quiz</h3>
      <p>
        Before you start getting more active please take this Exercise Safety
        Quiz.</p>
        <p>Please select <strong>all the statements you think are true</strong>.
      </p>
      </div>
      </div>

      <div *ngIf="quiz" class="quiz">

        <div *ngIf="completed">
            <div
            class="card text-center card-light mb-3 border-radius-bottom-only"
          >
            <div class="card-body text-center">
              <h3 class="sm-title text-center">Exercise Safety Quiz</h3>
                <h3 class="badge badge-xl"  [ngClass]="{'bg-danger': score<80, 'bg-success':score>=80}"><span class="sr-only">You scored</span> {{score | number}}%</h3>
        
                <div *ngIf="score>=80">
                    <p class="quiz-result">Well done, you passed the quiz</p>
                  <button class="btn btn-primary" [routerLink]="['/getting-started']">Continue <span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span></button>
                </div>
                <div *ngIf="score<80">
                    <p class="quiz-result">I'm afraid you haven't passed this time.</p>
                    <button class="btn btn-primary" (click)="onRetake()">Retake</button>
                </div>

              
          </div>
          </div>
        </div>
        <div *ngFor="let question of quiz.questions; let index = index" class="quiz-question">
          <h4>{{index+1}}. {{ question.question }}</h4>
          <ul class="pick-list">
            <li
              *ngFor="let answer of quiz.answers[question.assessment_question_id]"
              [ngClass]="{
                active: (answersPicked.indexOf(answer) != -1 && !completed),
                correct: completed && answer.correct ==1,
                incorrect:  completed && answer.correct != 1,
                picked: (answersPicked.indexOf(answer) != -1 && completed)
              }"
              (click)="onPickAnswer(answer)"
            >
              <span class="pick-icon">
                <span class="icon-wrap">
                  <fa-icon
                    [icon]="['far', 'circle']"
                    *ngIf="answersPicked.indexOf(answer) === -1 && !completed"
                  ></fa-icon>
                  <fa-icon
                    [icon]="['fas', 'circle-dot']"
                    *ngIf="answersPicked.indexOf(answer) !== -1 && !completed"
                  ></fa-icon>
                  
                  <fa-icon
                    [icon]="['fas', 'circle']"
                    *ngIf="answersPicked.indexOf(answer) !== -1 && completed && answer.correct == 1"
                  ></fa-icon>
                  <fa-icon
                    [icon]="['fas', 'circle']"
                    *ngIf="answersPicked.indexOf(answer) !== -1 && completed && answer.correct != 1"
                  ></fa-icon>
                  <fa-icon
                    [icon]="['fas', 'circle']"
                    *ngIf="answersPicked.indexOf(answer) === -1 && completed && answer.correct == 1"
                  ></fa-icon>
                  <fa-icon
                    [icon]="['fas', 'circle']"
                    *ngIf="answersPicked.indexOf(answer) === -1 && completed && answer.correct != 1"
                  ></fa-icon> 
                </span>
              </span>
              
              <span class="pick-item">{{ answer.answer }} <span class="your-answer" *ngIf="answersPicked.indexOf(answer) != -1 && completed">&nbsp;&nbsp;&nbsp;(Your Answer)</span></span>
            </li>
          </ul>
          <p *ngIf="completed">You selected <strong>{{countCorrectPicked(question.assessment_question_id)}} of {{countCorrectAnswers(question.assessment_question_id)}}</strong> correct answers</p>
        </div>

        <div class="text-center">
          <button class="btn btn-primary btn-block mb-3 w-100" (click)="onSubmitAnswers()" *ngIf="!completed">Submit Answers</button>
        </div>
        

      </div>
    </div>
  </div>
</div>
<div *ngIf="gettingStarted">
  <div class="signin-details">
    <div class="signin-details-flex">
      <div class="signin-left">
        © {{ currentYear }} University Hospitals of Leicester
      </div>
      <div class="signin-right">
        <a [routerLink]="['/getting-started/terms']"
        >Terms &amp; Conditions</a
      >&nbsp;|&nbsp; 
      <a [routerLink]="['/getting-started/privacy']">Privacy Policy</a>
      </div>
    </div>
  </div>
  <div class="">
    <div class="col footer-logos">
      <div class="programme-logo-strap">Incorporating the programmes</div>
      <div class="programme-logos">
        <img
          src="./assets/img/logo-ayh-colour.svg"
          alt="Activate Your Heart Logo"
          width="93"
        />
        <img
          src="./assets/img/logo-space-colour.svg"
          alt="SPACE for COPD"
          width="69"
        />
        <img
          src="./assets/img/logo-ycr-colour.svg"
          alt="Your COVID Recovery"
          width="93"
        />
      </div>
    </div>
  </div>
</div> 