import { Goal } from './../goal.model';
import { GoalsService } from './../goals.service';
import { Component, OnInit } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-goal-view',
  templateUrl: './goal-view.component.html',
  styleUrls: ['./goal-view.component.scss'],
})
export class GoalViewComponent implements OnInit {
  value = 0;
  options: Options = {
    floor: 0,
    ceil: 100,
    showSelectionBar: true,
  };

  public goal!: Goal;
  isLoading = false;
  error:any;
  goalId:any;
  private errorSub!: Subscription;

  constructor(
    private goalsService: GoalsService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.goalId = this.route.snapshot.params.id;
    this.errorSub = this.goalsService.error.subscribe((errorMessage) => {
      this.error = errorMessage;
    });

    this.isLoading = true;
    this.goalsService.fetch(this.goalId).subscribe(
      (goal) => {
        this.isLoading = false;
        this.goal = goal;
        if(goal.progress){this.value = goal.progress;}
      },
      (error) => {
        this.isLoading = false;
        this.error = error.message;
      }
    );
  }

  onUpdate() {
    if (this.value === 100) {
      this.goal.status = 2;
    } else {
      this.goal.status = 1;
    }

    this.goalsService
      .updateGoal(
        this.goalId,
        this.goal.goal_category_id,
        this.goal.what,
        this.goal.why,
        this.goal.how,
        this.goal.barriers,
        this.goal.start,
        this.goal.custom,
        this.goal.status,
        this.value
      )
      .subscribe(
        (responseData) => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Progress updated',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            toast: true,
          });
          // this.router.navigate(['/trackers', 'symptom-thermometer']);
        },
        (error) => {
          this.error = error.message;
        }
      );
  }

  onDelete() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to undo this",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it',
    }).then((result) => {
      if (result.value) {
        this.goalsService.delete(this.goalId).subscribe(
          (responseData) => {
            Swal.fire('Deleted', 'Your goal has been deleted.', 'success');
            this.router.navigate(['/goals']);
          },
          (error) => {
            this.isLoading = false;
            this.error = error.message;
            Swal.fire('Deleted!', 'There was an error.', 'error');
          }
        );
      }
    });
  }

  /*
   getDate(value: any): Date {
    // https://github.com/angular/angular/issues/12334
    return value ? new Date(value.replace(/\s/g, 'T')) : undefined;
  }*/
}
