import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Notification } from '../models/notification.model';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
})
export class NotificationsComponent implements OnInit {
  public notifications: Notification[] = [];
  constructor(
    private notificationService: NotificationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.notificationService.fetchAll().subscribe((responseData) => {
      this.notifications = responseData;
    });
  }

  onPressNotification(notification:Notification) {
    if(notification.link){
      this.router.navigateByUrl(notification.link);
    }
    // set to read
    this.notificationService.update(notification.user_id, 1).subscribe((responseData)=>{
      //set to read
      this.notificationService.refreshNotifications();
    });
  }
}
