import { UserResourceService } from './../services/user-resource.service';
import { Goal } from './goal.model';
import { GoalsService } from './goals.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2/src/sweetalert2.js';
import * as moment from 'moment';
import { UserDataInterface } from '../auth/service/userData.interface';
import { AuthService } from '../auth/service/auth.service';
import { UserService } from '../auth/service/user.service';


@Component({
  selector: 'app-goals',
  templateUrl: './goals.component.html',
  styleUrls: ['./goals.component.scss'],
})
export class GoalsComponent implements OnInit {
  public allGoals: Goal[] = [];
  public openGoals: Goal[] = [];
  public completeGoals: Goal[] = [];
  isLoading = false;
  error: any;
  private errorSub!: Subscription;
  userResourceData: any;
  userStage: any;
  viewCustom = false;
  taskStatus = false;
  taskId: any;
  userData!: UserDataInterface;

  public quickestGoal: any;
  public goalStreak = 0;
  now = moment().format('YYYY-MM-DD');



  constructor(
    private goalsService: GoalsService,
    private userResourceService: UserResourceService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (+params['custom'] == 1) {
        this.viewCustom = true;
      } else {
        this.viewCustom = false;
      }
    });

    this.errorSub = this.goalsService.error.subscribe((errorMessage) => {
      this.error = errorMessage;
    });
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
      }
    });

    this.isLoading = true;
    forkJoin({
      goals: this.goalsService.fetchGoals(),
      userResourceData: this.userResourceService.fetchAll(),
    }).subscribe(
      (responseData) => {
        this.allGoals = responseData.goals;
        this.openGoals = this.allGoals.filter((goal) => +goal.status <2);
        this.completeGoals = this.allGoals.filter((goal) => +goal.status == 2);
        this.userResourceData = responseData.userResourceData;
        this.isLoading = false;
        // work out the stats
        this.quickestGoal = this.goalsService.getQuickestGoal(this.allGoals);
      },
      (error) => {}
    );
  }



  onCompleteGoal(goal: Goal) {
    this.goalsService.updateGoal(goal.goal_id, 0,goal.what, goal.why, goal.how, goal.barriers, goal.start, goal.custom, 2,100).subscribe(
      (responseData) => {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: 'Goal achieved',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          toast: true,
        });
        this.allGoals.forEach((aGoal, index) => {
          if (aGoal.goal_id == goal.goal_id) {
            this.allGoals[index].status = 2;
          }
        });

        this.openGoals = this.allGoals.filter((goal) => +goal.status == 1);
        this.completeGoals = this.allGoals.filter((goal) => +goal.status == 2);
      },
      (error) => {
        console.log(error);
      }
    );
  }
  
}
