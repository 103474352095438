import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UserTaskStatusService } from '../services/user-task-status.service';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-welcome-video',
  templateUrl: './welcome-video.component.html',
  styleUrls: ['./welcome-video.component.scss']
})
export class WelcomeVideoComponent implements OnInit {

  public taskCompleted = false;
  environment = environment;

  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  constructor(private userTaskStatusService:UserTaskStatusService) { }

  ngOnInit(): void {
    this.userTaskStatusService.fetchAll().subscribe((responseData) => {
      responseData.forEach((userTaskStatus) => {
        if(userTaskStatus.task_id == 'welcomeVideo'){
          this.taskCompleted = true;
        }
      });
    });
  }


  onVideoStart(){
    //console.log('started!');
  }

  onVideoEnd(){
    if(!this.taskCompleted){
      this.userTaskStatusService.create('welcomeVideo', 1).subscribe((responseData) => {
        Swal.fire(
          'Task Complete',
          "You completed the 'Watch the Welcome Video' task, well done!",
          'success'
        );
        this.closeModal.emit(true);
      });
    }

  }

}
