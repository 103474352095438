import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ResourcesService } from 'src/app/services/resources.service';

@Component({
  selector: 'app-more-page',
  templateUrl: './more-page.component.html',
  styleUrls: ['./more-page.component.scss']
})
export class MorePageComponent implements OnInit {
  area = 'about';
  resourceId:any;
  isLoading = false;
  pages:any;
  resource:any;
  page:any;

  constructor(
    private resourcesService: ResourcesService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.area = this.route.snapshot.params.area;

      switch (this.area) {
        case 'about':
          this.resourceId = 24;
          break;
        case 'contributors':
          this.resourceId = 25;
          break;
        case 'organisations':
          this.resourceId = 26;
          break;
        case 'app':
          this.resourceId = 30;
        break;
        case 'privacy':
          this.resourceId = 27;
          break;
        case 'terms':
          this.resourceId = 28;
          break;
      }

      this.isLoading = true;
      this.resourcesService.fetchResource(this.resourceId).subscribe(
        (responseData) => {
          this.isLoading = false;
          this.pages = responseData.pages;
          this.pages.forEach((page:any, pIndex:any) => {
            if (pIndex === 0) {
              this.page = page;
              /* if (pIndex > 0) {
                this.prevPage = this.pages[pIndex - 1];
              } else {
                this.prevPage = null;
              }
              if (pIndex + 1 <= this.pages.length) {
                this.nextPage = this.pages[pIndex + 1];
              } else {
                this.nextPage = null;
              }*/
            }
          });
          this.resource = responseData.resource;
        },
        (error) => {}
      );
    });
  }
}