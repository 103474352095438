import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-symptom-icon',
  templateUrl: './symptom-icon.component.html',
  styleUrls: ['./symptom-icon.component.scss'],
})
export class SymptomIconComponent implements OnInit {
  @Input() symptomId: any;
  constructor() {}

  ngOnInit(): void {}
}
