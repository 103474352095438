import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Notification } from '../models/notification.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  error = new Subject<string>();
  slug = '/notifications';
  resourceName = 'notifications';
  weeklyTarget = {
    threshold: 10,
    days: 5,
  };
  allNotifications?: Observable<Notification[]> | null;
  cacheTime = new Date();
  cacheLifetime = 1 * 60 * 1000;
  updateNotifications = new BehaviorSubject(false);

  constructor(private http: HttpClient) {}

  fetchAll(): Observable<Notification[]> {
    const now = new Date();
    if (
      !this.allNotifications ||
      Math.abs(now.getTime() - this.cacheTime.getTime()) > this.cacheLifetime
    ) {
      this.cacheTime = new Date();
      this.allNotifications = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: Notification[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.allNotifications;
  }

  update(id: number, status: number) {
    const payload = {
      status,
    };
    return this.http.patch<Notification>(
      environment.apiUrl + this.slug + '/' + id,
      payload,
      {
        observe: 'response',
      }
    );
  }

  refreshNotifications() {
    this.clearCache();
    this.updateNotifications.next(true);
  }

  getNotifications(): Observable<Notification[]> {
    let searchParams = new HttpParams();

    searchParams = searchParams.append('status', '0');
    return this.http
      .get<any>(environment.apiUrl + this.slug, {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData['_embedded'][this.resourceName];
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  clearCache() {
    this.allNotifications = null;
  }
}
