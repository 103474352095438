<div class="container relative">
  <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>
  <div class="card text-center card-light mt-3">
    <div class="card-body text-center">
      <div >
        <h3 class="mt-1 mb-3 sm-title">Set Target</h3>
        <form class="weight-form" (ngSubmit)="onSubmit()" #form="ngForm">
       

            <div class="form-group mb-3">
              <input
                type="text"
                class="form-control from-control-xl-micro"
                name="weightInput"
                id="weightInput"
                placeholder="0"
                rows=""
                ngModel
                #weightInput="ngModel"
                required
                maxlength="65535"
                minlength=""
              /> <span class="xl-micro-addon">Kg</span>
            </div> 

           
          <button class="btn btn-primary">Set Target</button>
        </form>
      </div>
    </div>
  </div>
</div>
