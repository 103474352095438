<div class="achievement-notification" (click)="onCloseAward($event)">
  <div class="achievement-wrap">
    <div class="card bg-white">
      <div class="card-body">
        <h1>Congratulations!</h1>
        <p>You earned a new achievement badge</p>
        <div class="d-flex justify-content-center mb-3">
          <app-achievement-badge
            [achievement]="activeAchievement"
          ></app-achievement-badge>
        </div>

        <p *ngIf="activeAchievement.area == 'cardio' && activeAchievement.type =='badge-workouts'">Awarded for completing {{activeAchievement.total}} Cardio Workouts</p>
        <p *ngIf="activeAchievement.area == 'cardio' && activeAchievement.type =='badge-workout-steak'">Awarded for completing a Cardio Workouts streak</p>
        <p *ngIf="activeAchievement.area == 'strength' && activeAchievement.type =='badge-workouts'">Awarded for completing {{activeAchievement.total}} Strength Workouts</p>
        <p *ngIf="activeAchievement.area == 'strength' && activeAchievement.type =='badge-workout-steak'">Awarded for completing a Strength Workouts streak</p>
        <p *ngIf="activeAchievement.area == 'goals' && activeAchievement.type =='badge-milestone'">Awarded for completing {{activeAchievement.total}} Goals</p>


        <div class="more-resources bg-light">
          <ul class="tracker-list">

            <li *ngIf="activeAchievement.area == 'cardio'">
              <a class="task" [routerLink]="['/exercise', 'cardio']">
                <span class="tracker-info">
                  <span class="tracker-title">View Cardio Workouts</span>
                </span>
                <span class="icon-wrap float-end icon-more"
                  ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon>
                </span>
              </a>
            </li>

            <li *ngIf="activeAchievement.area == 'strength'">
              <a class="task" [routerLink]="['/exercise', 'strength']">
                <span class="tracker-info">
                  <span class="tracker-title">View Strength Workouts</span>
                </span>
                <span class="icon-wrap float-end icon-more"
                  ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon>
                </span>
              </a>
            </li>
            <li *ngIf="activeAchievement.area == 'goals'">
              <a class="task" [routerLink]="['/goals']">
                <span class="tracker-info">
                  <span class="tracker-title">View Goals</span>
                </span>
                <span class="icon-wrap float-end icon-more"
                  ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon>
                </span>
              </a>
            </li>

            <li>
              <a class="task" [routerLink]="['/achievements']">
                <span class="tracker-info">
                  <span class="tracker-title">View Achievements</span>
                </span>
                <span class="icon-wrap float-end icon-more"
                  ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
