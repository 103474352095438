import { UserAchievement } from "./../models/user-achievement.model";

import { map, catchError, publishReplay, refCount } from "rxjs/operators";
import { environment } from "./../../environments/environment";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject, throwError } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AchievementService {
  error = new Subject<string>();
  slug = "/achievements";
  resourceName = "achievements";
  weeklyTarget = {
    threshold: 10,
    days: 5,
  };
  allAchievements?: Observable<UserAchievement[]> | null;
  areaAchievements?: any;
  cacheTime = new Date();
  cacheLifetime = 1 * 60 * 1000;
  updateNotifications = new BehaviorSubject(false);

  public streakMilestones = [
    "3 day",
    "7 day",
    "2 week",
    "1 month",
    "3 month",
    "6 month",
    "1 year",
  ];
  public goalMilestones = ["1", "5", "10", "25", "50", "100"];
  public workoutMilestones = [
    "10",
    "25",
    "50",
    "75",
    "100",
    "250",
    "500",
    "1000",
  ];
  public weekStreakMilestones = [
    "3",
    "5",
    "10",
    "25",
    "50",
    "100",
    "250",
    "500",
    "1000",
  ];

  constructor(private http: HttpClient) {}

  fetchAll(): Observable<UserAchievement[]> {
    const now = new Date();
    if (
      !this.allAchievements ||
      Math.abs(now.getTime() - this.cacheTime.getTime()) > this.cacheLifetime
    ) {
      this.cacheTime = new Date();
      this.allAchievements = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: "json",
        })
        .pipe(
          map((responseData) => {
            const returnArray: UserAchievement[] = [];
            responseData["_embedded"][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.allAchievements;
  }

  update(id: number, status: number) {
    const payload = {
      status,
    };
    return this.http.patch<UserAchievement>(
      environment.apiUrl + this.slug + "/" + id,
      payload,
      {
        observe: "response",
      }
    );
  }

  fetchArea(area: string): Observable<UserAchievement[]> {
    const now = new Date();
    if (!this.areaAchievements) {
      this.areaAchievements = {};
    }
    if (
      !this.areaAchievements[area] ||
      Math.abs(now.getTime() - this.cacheTime.getTime()) > this.cacheLifetime
    ) {
      let searchParams = new HttpParams();

      searchParams = searchParams.append("area", area.toString());

      this.areaAchievements[area] = this.http
        .get<any>(environment.apiUrl + this.slug, {
          params: searchParams,
          responseType: "json",
        })
        .pipe(
          map((responseData) => {
            const returnArray: UserAchievement[] = [];
            responseData["_embedded"][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.areaAchievements[area];
  }

  getUnique(achievements: UserAchievement[]): UserAchievement[] {
    let uniqueAchievements: UserAchievement[] = [];
    achievements.forEach((achievement) => {
      let found = false;
      uniqueAchievements.forEach((badge) => {
        if (
          badge.total == achievement.total &&
          badge.area == achievement.area &&
          badge.type == achievement.type
        ) {
          found = true;
        }
      });
      if (!found) {
        uniqueAchievements.push(achievement);
      }
    });
    return uniqueAchievements;
  }

  refreshNotifications() {
    this.clearCache();
    this.updateNotifications.next(true);
  }

  getNotifications(): Observable<UserAchievement[]> {
    let searchParams = new HttpParams();

    searchParams = searchParams.append("status", "0");
    return this.http
      .get<any>(environment.apiUrl + this.slug, {
        params: searchParams,
        responseType: "json",
      })
      .pipe(
        map((responseData) => {
          return responseData["_embedded"][this.resourceName];
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  clearCache() {
    this.allAchievements = null;
  }

  getNextMilestone(
    area: string,
    type: string,
    achievements: UserAchievement[]
  ): string {
    let next = "";

    let grabbed: any = [];
    achievements.forEach((achievement: UserAchievement) => {
      if (achievement.area == area && achievement.type == type) {
        let key = achievement.total.toString();
        if (achievement.unit) {
          key = key + " " + achievement.unit;
        }
        grabbed.push(key);
      }
    });

    let nextSet = false;

    let milestones: any = [];
    switch (type) {
      case "badge-workouts":
        milestones = this.workoutMilestones;
        break;
      case "badge-workout-streak":
        milestones = this.streakMilestones;
        break;
      case "badge-goals":
        milestones = this.goalMilestones;
        break;
      case "week-streak":
        milestones = this.weekStreakMilestones;
        break;
    }
    
    next = milestones[0];
    milestones.forEach((milestone: any) => {
      if (!nextSet && grabbed.indexOf(milestone) === -1) {
        next = milestone;
        nextSet = true;
      }
    });
    return next;
  }


  getProgrammeAchievementTitle(achievement:any){
    let title = 'Programme Achievement';
    switch(achievement.type){
      case 'getting-started-1':
        title = 'Getting started part 1';
        break;
      case 'getting-started-2':
        title = 'Getting started part 2';
        break;
      case 'safety-quiz':
        title = 'Activity Safety Quiz';
        break;
      case 'bitesize-session':
        title = 'Completed Bitesize Session';
        break;
    }
    return title;
  }
}
