export class Goal {
    public goal_id: number;
    public goal_category_id: number;
    public goal_category: string;
    public what: string;
    public why: string;
    public how: string;
    public barriers: string;
    public start: string;
    public custom: string;
    public status: number;
    public progress: number;
    public created: string;
    public modified: string;
    public category: string;
  
    constructor(
      goal_id: number,
      goal_category_id: number,
      goal_category: string,
      what: string,
      why: string,
      how: string,
      barriers: string,
      start: string,
      custom: string,
      status: number,
      progress: number,
      created: string,
      modified: string,
      category: string
    ) {
      this.goal_id = goal_id;
      this.goal_category_id = goal_category_id;
      this.goal_category = goal_category;
      this.what = what;
      this.why = why;
      this.how = how;
      this.barriers = barriers;
      this.start = start;
      this.custom = custom;
      this.status = status;
      this.progress = progress;
      this.created = created;
      this.modified = modified;
      this.category = category;
    }
  }
