<div class="main-content-area sub-page">
  <tabset [justified]="true" class="mt-2">
    <tab>
      <ng-template tabHeading> Weekly Target </ng-template>
      <nav class="page-header">
        <div>
          <button
            (click)="onPreviousWeek()"
            *ngIf="currentWeekNumber > 1"
            class="btn"
          >
          <span class="sr-only">Previous Week</span>
            <span class="icon-wrap"
              ><fa-icon [icon]="['fal', 'chevron-left']"></fa-icon
            ></span>
          </button>
        </div>
        <div>
          <h2 *ngIf="userData">Week {{ currentWeekNumber }}</h2>
          <div class="subtitle">
            {{ currentWeek[0] | customDate : "ddd Do MMM" }} -
            {{ currentWeek[6] | customDate : "ddd Do MMM" }}
          </div>
        </div>
        <div>
          <button
            (click)="onNextWeek()"
            *ngIf="userData && currentWeekNumber < userData.current_week"
            class="btn"
          >
          <span class="sr-only">Next Week</span>
            <span class="icon-wrap"
              ><fa-icon [icon]="['fal', 'chevron-right']"></fa-icon
            ></span>
          </button>
        </div>
      </nav>

      <div class="">
        <div class="row">
          <div class="col">
            <div class="card bg-secondary text-white card-strength">
              <div class="card-body">
                <div *ngIf="isLoading" class="text-center">
                  <app-loading-spinner [inline]="true"></app-loading-spinner>
                </div>
                <div *ngIf="!isLoading">
                  <p class="text-center" *ngIf="currentWeekNumber == currentWeekNumberGlobal">
                    Workout for
                    {{ weeklyTarget.days }} days of this week
                  </p>
                  <p class="text-center" *ngIf="currentWeekNumber != currentWeekNumberGlobal">
                    Workout for
                    {{ weeklyTarget.days }} days in the week
                  </p>

                  <div class="week-bar">
                    <div class="entry-days">
                      <div
                        class="entry-day"
                        *ngFor="let day of currentWeekData"
                        [ngClass]="{
                          complete: day.exercises
                        }"
                      >
                        <div class="entry-day-label">
                          {{ day.date | date : "EE" | slice : 0 : 1 }}
                        </div>
                        <div class="na" *ngIf="day.date > today">&nbsp;</div>
                        <div
                          class=""
                          *ngIf="day.date <= today"
                          [ngClass]="{
                            complete: day.exercises
                          }"
                        >
                          <span class="icon-wrap" *ngIf="day.exercises">
                            <fa-icon [icon]="['fas', 'check']"></fa-icon>
                          </span>
                          <span
                            class="icon-wrap icon-wrap-dot text-success"
                            [routerLink]="[
                              '/exercise',
                              'strength',
                              'add-workout'
                            ]"
                            [queryParams]="{ d: day.date }"
                            *ngIf="
                              day.minutes &&
                              +day.minutes < +weeklyTarget.threshold
                            "
                          >
                            <fa-icon [icon]="['fas', 'circle']"></fa-icon>
                          </span>

                          <div
                            class="entry-day-data-value"
                            *ngIf="!day.exercises && day.date !== today"
                            [routerLink]="[
                              '/exercise',
                              'strength',
                              'add-workout'
                            ]"
                            [queryParams]="{ d: day.date }"
                          >
                            -
                          </div>

                          <div
                            class="entry-day-data-value relative"
                            *ngIf="!day.exercises && day.date === today"
                          >
                            <span
                              class="icon-wrap icon-wrap-dot text-success"
                              [routerLink]="[
                                '/exercise',
                                'strength',
                                'add-workout'
                              ]"
                            >
                              <fa-icon [icon]="['fas', 'circle']"></fa-icon>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--p class="subtext" *ngIf="+daysHit < weeklyTarget.days">
                    You have completed
                    <strong
                      >{{ daysHit }} day<ng-container *ngIf="daysHit != 1"
                        >s</ng-container
                      ></strong
                    >
                    towards your target this week.
                  </p-->
                  <p
                    class="subtext text-center"
                    *ngIf="+daysHit >= weeklyTarget.days && currentWeekNumber == currentWeekNumberGlobal"
                  >
                    You have hit your target this week!
                  </p>
                  <p
                    class="subtext text-center"
                    *ngIf="+daysHit >= weeklyTarget.days && currentWeekNumber != currentWeekNumberGlobal"
                  >
                    You have hit your target for week {{currentWeekNumber}}!
                  </p>

                  <div class="text-center mt-4"
                  *ngIf="bicepCurlsWeekReport?.globalWorkouts > 0">
                    <!--a
                      [routerLink]="['/exercise', 'strength', 'add-workout']"
                      class="btn btn-white btn-sm"
                      ><span class="icon-wrap">
                        <fa-icon [icon]="['fal', 'circle-plus']"></fa-icon>
                      </span>
                      Add Workout</a
                    -->

                    <button
                      class="btn btn-white btn-sm ms-2"
                      *ngIf="bicepCurlsWeekReport?.globalWorkouts > 0"
                      (click)="toggleFeedback()"
                    >
                      <span *ngIf="!showFeedback" class="icon-wrap">
                        <fa-icon [icon]="['fal', 'circle-plus']"></fa-icon
                      ></span>
                      <span *ngIf="showFeedback" class="icon-wrap">
                        <fa-icon [icon]="['fal', 'circle-minus']"></fa-icon
                      ></span>
                      Show weekly feedback
                    </button>
                  </div>
                </div>
              </div>

              <!-- feedback -->

              <div *ngIf="bicepCurlsWeekReport?.globalWorkouts > 0">
                <div *ngIf="showFeedback" class="feedback-container p-3 small">
                  <p class="p-0 mb-2">
                    {{
                      "Last week you worked out on " +
                        bicepCurlsWeekReport.globalWorkouts +
                        " days."
                    }}
                  </p>

                  <div
                    *ngIf="
                      bicepCurlsWeekReport.feedback ||
                      bicepCurlsWeekReport.progress ||
                      bicepCurlsWeekReport.reduce
                    "
                  >
                    <p class="p-0 m-0 mb-0">
                      <strong>{{ "Bicep Curls" | translate }}</strong>
                    </p>
                    <p *ngIf="bicepCurlsWeekReport.feedback" class="pb-1 m-0">
                      {{ bicepCurlsWeekReport.feedback }}
                    </p>
                    <p *ngIf="bicepCurlsWeekReport.progress" class="pb-1">
                      {{
                        "We recommend increasing your weight by 0.5kg this week."
                          | translate
                      }}
                    </p>
                    <p *ngIf="bicepCurlsWeekReport.reduce" class="pb-1">
                      {{
                        "We recommend reducing your weight by 0.5kg this week."
                          | translate
                      }}
                    </p>
                  </div>

                  <div
                    *ngIf="
                      pullUpsWeekReport.feedback ||
                      pullUpsWeekReport.progress ||
                      pullUpsWeekReport.reduce
                    "
                  >
                    <p class="p-0 m-0 mb-0">
                      <strong>{{ "Pull ups" | translate }}</strong>
                    </p>
                    <p *ngIf="pullUpsWeekReport.feedback" class="pb-1 m-0">
                      {{ pullUpsWeekReport.feedback }}
                    </p>
                    <p *ngIf="pullUpsWeekReport.progress" class="pb-1">
                      {{
                        "We recommend increasing your weight by 0.5kg this week."
                          | translate
                      }}
                    </p>
                    <p *ngIf="pullUpsWeekReport.reduce" class="pb-1">
                      {{
                        "We recommend reducing your weight by 0.5kg this week."
                          | translate
                      }}
                    </p>
                  </div>

                  <div
                    *ngIf="
                      sitToStandWeekReport.feedback ||
                      sitToStandWeekReport.progress ||
                      sitToStandWeekReport.reduce
                    "
                  >
                    <p class="p-0 m-0 mb-0">
                      <strong>{{ "Sit to stand" | translate }}</strong>
                    </p>
                    <p *ngIf="sitToStandWeekReport.feedback" class="pb-1 m-0">
                      {{ sitToStandWeekReport.feedback }}
                    </p>
                    <p *ngIf="sitToStandWeekReport.progress" class="pb-1">
                      {{
                        "We recommend increasing your weight by 0.5kg this week."
                          | translate
                      }}
                    </p>
                    <p *ngIf="sitToStandWeekReport.reduce" class="pb-1">
                      {{
                        "We recommend reducing your weight by 0.5kg this week."
                          | translate
                      }}
                    </p>
                  </div>

                  <div
                    *ngIf="
                      stepUpsWeekReport.feedback ||
                      stepUpsWeekReport.progress ||
                      stepUpsWeekReport.reduce
                    "
                  >
                    <p class="p-0 m-0">
                      <strong>{{ "Step ups" | translate }}</strong>
                    </p>
                    <p *ngIf="stepUpsWeekReport.feedback" class="pb-1 m-0">
                      {{ stepUpsWeekReport.feedback }}
                    </p>
                    <p *ngIf="stepUpsWeekReport.progress" class="pb-1">
                      {{
                        "We recommend increasing your weight by 0.5kg this week."
                          | translate
                      }}
                    </p>
                    <p *ngIf="stepUpsWeekReport.reduce" class="pb-1">
                      {{
                        "We recommend reducing your weight by 0.5kg this week."
                          | translate
                      }}
                    </p>
                  </div>
                </div>
              </div>
              <!-- end feedback -->
            </div>

            <table class="table-workouts my-4">
              <thead>
                <tr>
                  <th></th>
                  <th>Exercises completed</th>
                  <th>Total Reps</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let day of currentWeekData"
                  [ngClass]="{ disabled: day.date > today }"
                >
                  <td class="td-strength-date">{{ day.date | date : "EEEE" }}</td>
                  <td class="td-strength-exercises">
                    <span *ngIf="day.date <= today && day.exercises"
                      ><app-format-strength-exercises
                        [exerciseData]="day.exercises"
                      ></app-format-strength-exercises
                    ></span>
                    <span *ngIf="day.date <= today && !day.exercises"></span>
                  </td>
                  <td class="td-strength-reps">
                    <span *ngIf="day.reps > 0">{{ day.reps }}</span>
                  </td>

                  <td class="td-strength-check">
                    <span class="icon-wrap text-success" *ngIf="day.reps > 0"
                      ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
                    ></span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- key -->

      <div class="d-flex strength-exercise-key">
        <div>
          <app-strength-icon [exerciseId]="'bicepCurl'"></app-strength-icon> =
          Bicep curls
        </div>
        <div>
          <app-strength-icon [exerciseId]="'pullUps'"></app-strength-icon> =
          Upright rows
        </div>
        <div>
          <app-strength-icon [exerciseId]="'sitToStand'"></app-strength-icon> =
          Sit-to-stand
        </div>
        <div>
          <app-strength-icon [exerciseId]="'bicepCurl'"></app-strength-icon> =
          Step ups
        </div>
      </div>


    </tab>
    <tab>
      <ng-template tabHeading> All Workouts </ng-template>

      <div class="">
        <div class="row">
          <div class="col">
            <div *ngIf="isLoading" class="text-center">
              <app-loading-spinner [inline]="true"></app-loading-spinner>
            </div>
            <div *ngIf="!isLoading && allWorkouts">
              <div class="text-center pt-4">
                {{ allWorkouts.length }} workouts
              </div>

              <div
                *ngFor="let week of programmeWeeks.slice().reverse(); let index = index"
              >
                
                <ng-container *ngIf="week.data.length > 0">
                  <div class="all-weeks-header">
                    <div class="week-title">
                      <h3 class="all-data-header">
                        Week {{ week.number }}
                      </h3>
                      <h4 class="all-data-subheader">
                        {{ week.start | customDate : "ddd Do MMM" }} -
                        {{ week.end | customDate : "ddd Do MMM" }}
                      </h4>
                    </div>
                    <div class="week-metrics">
                      <span class="metric-data"><br>{{ week.data.length }} workout<span *ngIf="week.data.length != 1">s</span></span>
                    </div>
                  </div>

                  <table class="table-workouts-flex mt-2 mb-4">
                    <tbody>
                      <ng-container *ngFor="let workout of week.data">
                        <tr *ngIf="workout.workout_date | date : 'MMMMYY'">
                          <td class="strength-workout-table">
                            <div class="flex-workout-date">
                              {{ workout.workout_date | customDate : "ddd Do" }}
                            </div>
                            <div class="flex-strength-entry">
                              <app-format-strength-exercises-detailed
                                [exerciseData]="workout.exercises"
                              ></app-format-strength-exercises-detailed>
                            </div>
                            <a class="flex-workout-link stretched-link"
                                [routerLink]="[
                                  '/exercise',
                                  'strength',
                                  'view-workout',
                                  workout.strength_workout_id
                                ]">
                                <span>
                                  <span class="sr-only">View Workout</span>
                                  <fa-icon
                                    [icon]="['fal', 'arrow-right']">
                                  </fa-icon>
                                </span>
                            </a>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </tab>
    <tab>
      <ng-template tabHeading> Month </ng-template>
      <nav class="page-header">
        <div>
          <span class="icon-wrap" (click)="onPrevMonth()"
            ><fa-icon [icon]="['fal', 'chevron-left']"></fa-icon
          ></span>
        </div>
        <div>
          <h2 *ngIf="userData">{{ monthSelected | date : "MMMM YYYY" }}</h2>
        </div>
        <div>
          <span
            class="icon-wrap"
            *ngIf="monthSelected < currentMonth"
            (click)="onNextMonth()"
            ><fa-icon [icon]="['fal', 'chevron-right']"></fa-icon
          ></span>
        </div>
      </nav>
      <div class="status-calendar" *ngIf="monthData">
        <div class="status-calendar-row status-calendar-header">
          <div class="status-calendar-col">
            {{ monthData[1].days[0]?.date | date : "EE" | slice : 0 : 1 }}
          </div>
          <div class="status-calendar-col">
            {{ monthData[1].days[1]?.date | date : "EE" | slice : 0 : 1 }}
          </div>
          <div class="status-calendar-col">
            {{ monthData[1].days[2]?.date | date : "EE" | slice : 0 : 1 }}
          </div>
          <div class="status-calendar-col">
            {{ monthData[1].days[3]?.date | date : "EE" | slice : 0 : 1 }}
          </div>
          <div class="status-calendar-col">
            {{ monthData[1].days[4]?.date | date : "EE" | slice : 0 : 1 }}
          </div>
          <div class="status-calendar-col">
            {{ monthData[1].days[5]?.date | date : "EE" | slice : 0 : 1 }}
          </div>
          <div class="status-calendar-col">
            {{ monthData[1].days[6]?.date | date : "EE" | slice : 0 : 1 }}
          </div>
          <div class="status-calendar-col">
            <span class="icon-wrap text-primary">
              <!--fa-icon [icon]="['fas', 'bullseye-arrow']"></fa-icon-->
            </span>
          </div>
        </div>

        <div
          class="status-calendar-row"
          *ngFor="let weekNum of [1, 2, 3, 4, 5, 6]"
        >
          <ng-container *ngIf="monthData[weekNum]">
            <div
              class="status-calendar-col"
              *ngFor="let day of monthData[weekNum].days"
            >
              <span
                class="status-calendar-day"
                [ngClass]="{
                  complete: day.exercises,
                  incomplete:
                    !day.exercises && day.date < today,
                  'outside-month':
                    day.date < firstDayMonth || day.date > lastDayMonth
                }"
                >{{ day.date | date : "d" }}</span
              >
            </div>
            <div class="status-calendar-col">
              <span
                class="icon-wrap text-success cal-check"
                *ngIf="+monthData[weekNum].daysHit >= +weeklyTarget.days"
                ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
              ></span>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="bg-light stats-section pt-3" *ngIf="allWorkouts">
        <!-- stats -->
        <div class="row">
          <div class="col-6 mb-3">
            <div class="status-badge">
              <div class="status-badge-text">
                <div class="status-badge-title">TOTAL WORKOUTS</div>
                <div class="status-badge-status">
                  {{ stats?.total }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-6 mb-3">
            <div class="status-badge">
              <div class="status-badge-text">
                <div class="status-badge-title">TOTAL REPS</div>
                <div class="status-badge-status">
                  {{ stats?.totalReps }}
                 
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 mb-3">
            <div class="status-badge">
              <div class="status-badge-icon">
                <span class="icon-wrap">
                  <!--fa-icon [icon]="['fas', 'bullseye-arrow']"></fa-icon-->
                </span>
              </div>
              <div class="status-badge-text">
                <div class="status-badge-title">TARGET HIT</div>
                <div class="status-badge-status">
                  {{ stats?.targetHit }}<span>x</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 mb-3">
            <div class="status-badge">
              <div class="status-badge-icon">
                <span class="icon-wrap">
                  <!--fa-icon [icon]="['fas', 'arrows-h']"></fa-icon-->
                </span>
              </div>
              <div class="status-badge-text">
                <div class="status-badge-title">LONGEST STREAK</div>
                <div class="status-badge-status">
                  {{ stats?.streak }}<span> days</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </tab>
  </tabset>
  <div class="row d-md-block d-none mt-4">
    <div class="col text-center">
      <button class="btn btn-primary" [routerLink]="['/exercise','strength', 'add-workout']">
        <span class="icon-wrap"
          ><fa-icon [icon]="['fal', 'circle-plus']"></fa-icon
        ></span>
        Add a workout
      </button>
    </div>
  </div>

      <button class="btn btn-primary d-md-none sticky-button" [routerLink]="['/exercise','strength', 'add-workout']">
        <span class="icon-wrap"
          ><fa-icon [icon]="['fal', 'circle-plus']"></fa-icon
        ></span>
        Add a workout
      </button>
</div>
