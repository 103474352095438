
<div class="main-content-area">
  <div class="bg-light">
    <div class="row">
      <div class="col">
        <app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
      
        <form class="symptom-thermometer-form ii-form" (ngSubmit)="onSubmit()" #form="ngForm">


          <div class="form-group" *ngIf="diagnosisMode == 'cardiac' && currentSymptom =='ischaemic_pain'">
            <fieldset>
              <legend>
                <span class="icon-wrap symptom-icon"><app-symptom-icon [symptomId]="'ischaemic_pain'"></app-symptom-icon></span>
                Chest Pain
              </legend>
              <p *ngIf="symptomsBaseline" class="sympton-desc">Your baseline level for this tracker is set at <b>{{symptomsBaseline.ischaemic_pain}}</b></p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.ischaemic_pain<3" class="sympton-desc">A high level for this tracker is <b>3</b> and above.</p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.ischaemic_pain>=3 && symptomsBaseline.ischaemic_pain!=5" class="sympton-desc">A high level for this tracker is <b>{{symptomsBaseline.ischaemic_pain+1}}</b> and above.</p>
              <p class="sympton-desc">Please select a number below that currently represents the pain level.</p>

              <!--app-health-status-baseline-indicator [symptomsBaseline]="symptomsBaseline" [symptom]="'ischaemic_pain'"></app-health-status-baseline-indicator-->

              <div class="radio-group">
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.ischaemic_pain"
                    class="form-check-input"
                    type="radio"
                    required
                    name="ischaemic_pain"
                    id="ischaemic_pain0"
                    [value]="0"
                  />
                  <label class="form-check-label" for="ischaemic_pain0">0</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.ischaemic_pain"
                    class="form-check-input"
                    type="radio"
                    required
                    name="ischaemic_pain"
                    id="ischaemic_pain1"
                    [value]="1"
                  />
                  <label class="form-check-label" for="ischaemic_pain1">1</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.ischaemic_pain"
                    class="form-check-input"
                    type="radio"
                    required
                    name="ischaemic_pain"
                    id="ischaemic_pain2"
                    [value]="2"
                  />
                  <label class="form-check-label" for="ischaemic_pain2">2</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.ischaemic_pain"
                    class="form-check-input"
                    type="radio"
                    required
                    name="ischaemic_pain"
                    id="ischaemic_pain3"
                    [value]="3"
                  />
                  <label class="form-check-label" for="ischaemic_pain3">3</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.ischaemic_pain"
                    class="form-check-input"
                    type="radio"
                    required
                    name="ischaemic_pain"
                    id="ischaemic_pain4"
                    [value]="4"
                  />
                  <label class="form-check-label" for="ischaemic_pain4">4</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.ischaemic_pain"
                    class="form-check-input"
                    type="radio"
                    required
                    name="ischaemic_pain"
                    id="ischaemic_pain5"
                    [value]="5"
                  />
                  <label class="form-check-label" for="ischaemic_pain5">5</label>
                </div>
              </div>
            </fieldset>
            <div class="row form-instructions">
              <div class="col">
                <span class="arrow-left"></span>
                <span class="symptom-desc-low">No pain</span>
              </div>
              <div class="col text-end">
                <span class="arrow-right"></span>
                <span class="symptom-desc-high">Pain all the time</span>
              </div>
            </div>
            
          </div>

          

          <!-- COUGH -->
          <div class="form-group" *ngIf="currentSymptom =='cough'">
            <fieldset>
              <legend>
                <span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'cough'"></app-symptom-icon></span>
                Cough
              </legend>
              <p *ngIf="symptomsBaseline" class="sympton-desc">Your baseline level for this tracker is set at <b>{{symptomsBaseline.cough}}</b></p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.cough<3" class="sympton-desc">A high level for this tracker is <b>3</b> and above.</p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.cough>=3 && symptomsBaseline.cough!=5" class="sympton-desc">A high level for this tracker is <b>{{symptomsBaseline.cough+1}}</b> and above.</p>
              <p class="sympton-desc">Please select a number below that currently represents how your cough is.</p>

              <!--app-health-status-baseline-indicator [symptomsBaseline]="symptomsBaseline" [symptom]="'cough'"></app-health-status-baseline-indicator-->
              <div class="radio-group">
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.cough"
                    class="form-check-input"
                    type="radio"
                    required
                    name="cough"
                    id="cough0"
                    [value]="0"
                  />
                  <label class="form-check-label" for="cough0">0</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.cough"
                    class="form-check-input"
                    type="radio"
                    required
                    name="cough"
                    id="cough1"
                    [value]="1"
                  />
                  <label class="form-check-label" for="cough1">1</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.cough"
                    class="form-check-input"
                    type="radio"
                    required
                    name="cough"
                    id="cough2"
                    [value]="2"
                  />
                  <label class="form-check-label" for="cough2">2</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.cough"
                    class="form-check-input"
                    type="radio"
                    required
                    name="cough"
                    id="cough3"
                    [value]="3"
                  />
                  <label class="form-check-label" for="cough3">3</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.cough"
                    class="form-check-input"
                    type="radio"
                    required
                    name="cough"
                    id="cough4"
                    [value]="4"
                  />
                  <label class="form-check-label" for="cough4">4</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.cough"
                    class="form-check-input"
                    type="radio"
                    required
                    name="cough"
                    id="cough5"
                    [value]="5"
                  />
                  <label class="form-check-label" for="cough5">5</label>
                </div>
              </div>
            </fieldset>
            <div class="row form-instructions">
              <div class="col">
                <span class="arrow-left"></span>
                <span class="symptom-desc-low">I NEVER cough</span>
              </div>
              <div class="col text-end">
                <span class="arrow-right"></span>
                <span class="symptom-desc-high">I cough ALL the time</span>
              </div>
            </div>
          </div>

          <!-- PHLEGM -->
          <div class="form-group" *ngIf="diagnosisMode == 'respiratory' && currentSymptom =='phlegm'">
            <fieldset>
              <legend>
                <span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'phlegm'"></app-symptom-icon></span>
                Phlegm
              </legend>
              <p *ngIf="symptomsBaseline" class="sympton-desc">Your baseline level for this tracker is set at <b>{{symptomsBaseline.phlegm}}</b></p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.phlegm<3" class="sympton-desc">A high level for this tracker is <b>3</b> and above.</p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.phlegm>=3 && symptomsBaseline.phlegm!=5" class="sympton-desc">A high level for this tracker is <b>{{symptomsBaseline.phlegm+1}}</b> and above.</p>
              <p class="sympton-desc">Please select a number below that currently represents how much phlegm you have.</p>

              <!--app-health-status-baseline-indicator [symptomsBaseline]="symptomsBaseline" [symptom]="'phlegm'"></app-health-status-baseline-indicator-->
              <div class="radio-group">
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.phlegm"
                    class="form-check-input"
                    type="radio"
                    required
                    name="phlegm"
                    id="phlegm0"
                    [value]="0"
                  />
                  <label class="form-check-label" for="phlegm0">0</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.phlegm"
                    class="form-check-input"
                    type="radio"
                    required
                    name="phlegm"
                    id="phlegm1"
                    [value]="1"
                  />
                  <label class="form-check-label" for="phlegm1">1</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.phlegm"
                    class="form-check-input"
                    type="radio"
                    required
                    name="phlegm"
                    id="phlegm2"
                    [value]="2"
                  />
                  <label class="form-check-label" for="phlegm2">2</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.phlegm"
                    class="form-check-input"
                    type="radio"
                    required
                    name="phlegm"
                    id="phlegm3"
                    [value]="3"
                  />
                  <label class="form-check-label" for="phlegm3">3</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.phlegm"
                    class="form-check-input"
                    type="radio"
                    required
                    name="phlegm"
                    id="phlegm4"
                    [value]="4"
                  />
                  <label class="form-check-label" for="phlegm4">4</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.phlegm"
                    class="form-check-input"
                    type="radio"
                    required
                    name="phlegm"
                    id="phlegm5"
                    [value]="5"
                  />
                  <label class="form-check-label" for="phlegm5">5</label>
                </div>
              </div>
            </fieldset>
            <div class="row form-instructions">
              <div class="col">
                <span class="arrow-left"></span>
                <span class="symptom-desc-low">I have no phlegm on my chest</span>
              </div>
              <div class="col text-end">
                <span class="arrow-right"></span>
                <span class="symptom-desc-high">My chest is full of phlegm</span>
              </div>
            </div>


            
          </div>

          <!-- CHEST TIGHTNESS -->
          <div class="form-group" *ngIf="currentSymptom =='chest_tightness'">
            <fieldset>
              <legend>
                <span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'chest_tightness'"></app-symptom-icon></span>
                Chest Tightness
              </legend>
              <p *ngIf="symptomsBaseline" class="sympton-desc">Your baseline level for this tracker is set at <b>{{symptomsBaseline.chest_tightness}}</b></p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.chest_tightness<3" class="sympton-desc">A high level for this tracker is <b>3</b> and above.</p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.chest_tightness>=3 && symptomsBaseline.chest_tightness!=5" class="sympton-desc">A high level for this tracker is <b>{{symptomsBaseline.chest_tightness+1}}</b> and above.</p>
              <p class="sympton-desc">Please select a number below that currently represents how tight your chest is feeling.</p>

              <!--app-health-status-baseline-indicator [symptomsBaseline]="symptomsBaseline" [symptom]="'chest_tightness'"></app-health-status-baseline-indicator-->
              <div class="radio-group">
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.chest_tightness"
                    class="form-check-input"
                    type="radio"
                    required
                    name="chest_tightness"
                    id="chest_tightness0"
                    [value]="0"
                  />
                  <label class="form-check-label" for="chest_tightness0">0</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.chest_tightness"
                    class="form-check-input"
                    type="radio"
                    required
                    name="chest_tightness"
                    id="chest_tightness1"
                    [value]="1"
                  />
                  <label class="form-check-label" for="chest_tightness1">1</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.chest_tightness"
                    class="form-check-input"
                    type="radio"
                    required
                    name="chest_tightness"
                    id="chest_tightness2"
                    [value]="2"
                  />
                  <label class="form-check-label" for="chest_tightness2">2</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.chest_tightness"
                    class="form-check-input"
                    type="radio"
                    required
                    name="chest_tightness"
                    id="chest_tightness3"
                    [value]="3"
                  />
                  <label class="form-check-label" for="chest_tightness3">3</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.chest_tightness"
                    class="form-check-input"
                    type="radio"
                    required
                    name="chest_tightness"
                    id="chest_tightness4"
                    [value]="4"
                  />
                  <label class="form-check-label" for="chest_tightness4">4</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.chest_tightness"
                    class="form-check-input"
                    type="radio"
                    required
                    name="chest_tightness"
                    id="chest_tightness5"
                    [value]="5"
                  />
                  <label class="form-check-label" for="chest_tightness5">5</label>
                </div>
              </div>
            </fieldset>
            <div class="row form-instructions">
              <div class="col">
                <span class="arrow-left"></span>
                <span class="symptom-desc-low">No tightness</span>
              </div>
              <div class="col text-end">
                <span class="arrow-right"></span>
                <span class="symptom-desc-high">My chest is very tight</span>
              </div>
            </div>
          </div>

          <!-- SHORTNESS OF BREATH -->
          <div class="form-group" *ngIf="currentSymptom =='sob'">
            <fieldset>
              <legend>
                <span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'sob'"></app-symptom-icon></span>
                Shortness of Breath
              </legend>
              <p *ngIf="symptomsBaseline" class="sympton-desc">Your baseline level for this tracker is set at <b>{{symptomsBaseline.sob}}</b></p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.sob<3" class="sympton-desc">A high level for this tracker is <b>3</b> and above.</p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.sob>=3 && symptomsBaseline.sob!=5" class="sympton-desc">A high level for this tracker is <b>{{symptomsBaseline.sob+1}}</b> and above.</p>
              <p class="sympton-desc">Please select a number below that currently represents how breathless you may be feeling.</p>
              <!--app-health-status-baseline-indicator [symptomsBaseline]="symptomsBaseline" [symptom]="'sob'"></app-health-status-baseline-indicator-->
              <div class="radio-group">
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.sob"
                    class="form-check-input"
                    type="radio"
                    required
                    name="sob"
                    id="sob0"
                    [value]="0"
                  />
                  <label class="form-check-label" for="sob0">0</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.sob"
                    class="form-check-input"
                    type="radio"
                    required
                    name="sob"
                    id="sob1"
                    [value]="1"
                  />
                  <label class="form-check-label" for="sob1">1</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.sob"
                    class="form-check-input"
                    type="radio"
                    required
                    name="sob"
                    id="sob2"
                    [value]="2"
                  />
                  <label class="form-check-label" for="sob2">2</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.sob"
                    class="form-check-input"
                    type="radio"
                    required
                    name="sob"
                    id="sob3"
                    [value]="3"
                  />
                  <label class="form-check-label" for="sob3">3</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.sob"
                    class="form-check-input"
                    type="radio"
                    required
                    name="sob"
                    id="sob4"
                    [value]="4"
                  />
                  <label class="form-check-label" for="sob4">4</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.sob"
                    class="form-check-input"
                    type="radio"
                    required
                    name="sob"
                    id="sob5"
                    [value]="5"
                  />
                  <label class="form-check-label" for="sob5">5</label>
                </div>
              </div>
            </fieldset>
            <div class="row form-instructions">
              <div class="col">
                <span class="arrow-left"></span>
                <span class="symptom-desc-low">No breathlessness</span>
              </div>
              <div class="col text-end">
                <span class="arrow-right"></span>
                <span class="symptom-desc-high">I am very breathless</span>
              </div>
            </div>
          </div>

          <!-- ANKLE SWELLING -->
          <div class="form-group" *ngIf="diagnosisMode == 'cardiac' && currentSymptom =='ankle_swelling'">
            <fieldset>
              <legend>
                <span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'ankle_swelling'"></app-symptom-icon></span>
                Ankle Swelling
              </legend>
              <p *ngIf="symptomsBaseline" class="sympton-desc">Your baseline level for this tracker is set at <b>{{symptomsBaseline.ankle_swelling}}</b></p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.ankle_swelling<3" class="sympton-desc">A high level for this tracker is <b>3</b> and above.</p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.ankle_swelling>=3 && symptomsBaseline.ankle_swelling!=5" class="sympton-desc">A high level for this tracker is <b>{{symptomsBaseline.ankle_swelling+1}}</b> and above.</p>
              <p class="sympton-desc">Please select a number below that currently represents the level on ankle swelling you may be experiencing.</p>
              <!--app-health-status-baseline-indicator [symptomsBaseline]="symptomsBaseline" [symptom]="'ankle_swelling'"></app-health-status-baseline-indicator-->
              <div class="radio-group">
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.ankle_swelling"
                    class="form-check-input"
                    type="radio"
                    required
                    name="ankle_swelling"
                    id="ankle_swelling0"
                    [value]="0"
                  />
                  <label class="form-check-label" for="ankle_swelling0">0</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.ankle_swelling"
                    class="form-check-input"
                    type="radio"
                    required
                    name="ankle_swelling"
                    id="ankle_swelling1"
                    [value]="1"
                  />
                  <label class="form-check-label" for="ankle_swelling1">1</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.ankle_swelling"
                    class="form-check-input"
                    type="radio"
                    required
                    name="ankle_swelling"
                    id="ankle_swelling2"
                    [value]="2"
                  />
                  <label class="form-check-label" for="ankle_swelling2">2</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.ankle_swelling"
                    class="form-check-input"
                    type="radio"
                    required
                    name="ankle_swelling"
                    id="ankle_swelling3"
                    [value]="3"
                  />
                  <label class="form-check-label" for="ankle_swelling3">3</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.ankle_swelling"
                    class="form-check-input"
                    type="radio"
                    required
                    name="ankle_swelling"
                    id="ankle_swelling4"
                    [value]="4"
                  />
                  <label class="form-check-label" for="ankle_swelling4">4</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.ankle_swelling"
                    class="form-check-input"
                    type="radio"
                    required
                    name="ankle_swelling"
                    id="ankle_swelling5"
                    [value]="5"
                  />
                  <label class="form-check-label" for="ankle_swelling5">5</label>
                </div>
              </div>
            </fieldset>
            <div class="row form-instructions">
              <div class="col">
                <span class="arrow-left"></span>
                <span class="symptom-desc-low">No swelling at all</span>
              </div>
              <div class="col text-end">
                <span class="arrow-right"></span>
                <span class="symptom-desc-high">Swollen all the time</span>
              </div>
            </div>
          </div>


          <!-- EVERYDAY ACTIVITY -->
          <div class="form-group" *ngIf="currentSymptom =='activity'">
            <fieldset>
              <legend>
                <span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'activity'"></app-symptom-icon></span>
                Everyday Activity
              </legend>
              <p *ngIf="symptomsBaseline" class="sympton-desc">Your baseline level for this tracker is set at <b>{{symptomsBaseline.activity}}</b></p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.activity<3" class="sympton-desc">A high level for this tracker is <b>3</b> and above.</p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.activity>=3 && symptomsBaseline.activity!=5" class="sympton-desc">A high level for this tracker is <b>{{symptomsBaseline.activity+1}}</b> and above.</p>
              <p class="sympton-desc">Please select a number below that currently represents how you are finding doing your usual everyday activities.</p>
              <!--app-health-status-baseline-indicator [symptomsBaseline]="symptomsBaseline" [symptom]="'activity'"></app-health-status-baseline-indicator-->
              <div class="radio-group">
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.activity"
                    class="form-check-input"
                    type="radio"
                    required
                    name="activity"
                    id="activity0"
                    [value]="0"
                  />
                  <label class="form-check-label" for="activity0">0</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.activity"
                    class="form-check-input"
                    type="radio"
                    required
                    name="activity"
                    id="activity1"
                    [value]="1"
                  />
                  <label class="form-check-label" for="activity1">1</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.activity"
                    class="form-check-input"
                    type="radio"
                    required
                    name="activity"
                    id="activity2"
                    [value]="2"
                  />
                  <label class="form-check-label" for="activity2">2</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.activity"
                    class="form-check-input"
                    type="radio"
                    required
                    name="activity"
                    id="activity3"
                    [value]="3"
                  />
                  <label class="form-check-label" for="activity3">3</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.activity"
                    class="form-check-input"
                    type="radio"
                    required
                    name="activity"
                    id="activity4"
                    [value]="4"
                  />
                  <label class="form-check-label" for="activity4">4</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.activity"
                    class="form-check-input"
                    type="radio"
                    required
                    name="activity"
                    id="activity5"
                    [value]="5"
                  />
                  <label class="form-check-label" for="activity5">5</label>
                </div>
              </div>
            </fieldset>
            <div class="row form-instructions">
              <div class="col">
                <span class="arrow-left"></span>
                <span class="symptom-desc-low">No problems</span>
              </div>
              <div class="col text-end">
                <span class="arrow-right"></span>
                <span class="symptom-desc-high">Very difficult</span>
              </div>
            </div>
          </div>

          

          <!-- CONFIDENCE -->
          <div class="form-group" *ngIf="currentSymptom =='confidence'">
            <fieldset>
              <legend>
                <span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'confidence'"></app-symptom-icon></span>
                Confidence
              </legend>
              <p *ngIf="symptomsBaseline" class="sympton-desc">Your baseline level for this tracker is set at <b>{{symptomsBaseline.confidence}}</b></p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.confidence<3" class="sympton-desc">A high level for this tracker is <b>3</b> and above.</p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.confidence>=3 && symptomsBaseline.confidence!=5" class="sympton-desc">A high level for this tracker is <b>{{symptomsBaseline.confidence+1}}</b> and above.</p>
              <p class="sympton-desc">Please select a number below that currently represents how confident you are feeling.</p>
              <!--app-health-status-baseline-indicator [symptomsBaseline]="symptomsBaseline" [symptom]="'confidence'"></app-health-status-baseline-indicator-->
              <div class="radio-group">
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.confidence"
                    class="form-check-input"
                    type="radio"
                    required
                    name="confidence"
                    id="confidence0"
                    [value]="0"
                  />
                  <label class="form-check-label" for="confidence0">0</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.confidence"
                    class="form-check-input"
                    type="radio"
                    required
                    name="confidence"
                    id="confidence1"
                    [value]="1"
                  />
                  <label class="form-check-label" for="confidence1">1</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.confidence"
                    class="form-check-input"
                    type="radio"
                    required
                    name="confidence"
                    id="confidence2"
                    [value]="2"
                  />
                  <label class="form-check-label" for="confidence2">2</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.confidence"
                    class="form-check-input"
                    type="radio"
                    required
                    name="confidence"
                    id="confidence3"
                    [value]="3"
                  />
                  <label class="form-check-label" for="confidence3">3</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.confidence"
                    class="form-check-input"
                    type="radio"
                    required
                    name="confidence"
                    id="confidence4"
                    [value]="4"
                  />
                  <label class="form-check-label" for="confidence4">4</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.confidence"
                    class="form-check-input"
                    type="radio"
                    required
                    name="confidence"
                    id="confidence5"
                    [value]="5"
                  />
                  <label class="form-check-label" for="confidence5">5</label>
                </div>
              </div>
            </fieldset>
            <div class="row form-instructions">
              <div class="col">
                <span class="arrow-left"></span>
                <span class="symptom-desc-low">I am confident ALL the time</span>
              </div>
              <div class="col text-end">
                <span class="arrow-right"></span>
                <span class="symptom-desc-high">I am NOT confident at all</span>
              </div>
            </div>
          </div>

          <!-- ANXIETY -->
          <div class="form-group" *ngIf="currentSymptom =='anxiety' && diagnosisMode == 'covid'">
            <fieldset>
              <legend>
                <span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'anxiety'"></app-symptom-icon></span>
                Anxiety
              </legend>
              <p *ngIf="symptomsBaseline" class="sympton-desc">Your baseline level for this tracker is set at <b>{{symptomsBaseline.anxiety}}</b></p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.anxiety<3" class="sympton-desc">A high level for this tracker is <b>3</b> and above.</p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.anxiety>=3 && symptomsBaseline.anxiety!=5" class="sympton-desc">A high level for this tracker is <b>{{symptomsBaseline.anxiety+1}}</b> and above.</p>
              <p class="sympton-desc">Please select a number below that currently represents how anxious you are feeling.</p>
              <!--app-health-status-baseline-indicator [symptomsBaseline]="symptomsBaseline" [symptom]="'anxiety'"></app-health-status-baseline-indicator-->
              <div class="radio-group">
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.anxiety"
                    class="form-check-input"
                    type="radio"
                    required
                    name="anxiety"
                    id="anxiety0"
                    [value]="0"
                  />
                  <label class="form-check-label" for="anxiety0">0</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.anxiety"
                    class="form-check-input"
                    type="radio"
                    required
                    name="anxiety"
                    id="anxiety1"
                    [value]="1"
                  />
                  <label class="form-check-label" for="anxiety1">1</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.anxiety"
                    class="form-check-input"
                    type="radio"
                    required
                    name="anxiety"
                    id="anxiety2"
                    [value]="2"
                  />
                  <label class="form-check-label" for="anxiety2">2</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.anxiety"
                    class="form-check-input"
                    type="radio"
                    required
                    name="anxiety"
                    id="anxiety3"
                    [value]="3"
                  />
                  <label class="form-check-label" for="anxiety3">3</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.anxiety"
                    class="form-check-input"
                    type="radio"
                    required
                    name="anxiety"
                    id="anxiety4"
                    [value]="4"
                  />
                  <label class="form-check-label" for="anxiety4">4</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.anxiety"
                    class="form-check-input"
                    type="radio"
                    required
                    name="anxiety"
                    id="anxiety5"
                    [value]="5"
                  />
                  <label class="form-check-label" for="anxiety5">5</label>
                </div>
              </div>
            </fieldset>
            <div class="row form-instructions">
              <div class="col">
                <span class="arrow-left"></span>
                <span class="symptom-desc-low">I am NOT anxious at all</span>
              </div>
              <div class="col text-end">
                <span class="arrow-right"></span>
                <span class="symptom-desc-high">I am anxious ALL the time</span>
              </div>
            </div>
          </div>

          <!-- SHORT TERM MEMORY LOSS -->
          <div class="form-group" *ngIf="currentSymptom =='memory'">
            <fieldset>
              <legend>
                <span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'memory'"></app-symptom-icon></span>
                Short Term Memory Loss
              </legend>
              <p *ngIf="symptomsBaseline" class="sympton-desc">Your baseline level for this tracker is set at <b>{{symptomsBaseline.memory}}</b></p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.memory<3" class="sympton-desc">A high level for this tracker is <b>3</b> and above.</p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.memory>=3 && symptomsBaseline.memory!=5" class="sympton-desc">A high level for this tracker is <b>{{symptomsBaseline.memory+1}}</b> and above.</p>
              <p class="sympton-desc">Please select a number below that  represents how forgetful you've been recently.</p>
              <div class="radio-group">
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.memory"
                    class="form-check-input"
                    type="radio"
                    required
                    name="memory"
                    id="memory0"
                    [value]="0"
                  />
                  <label class="form-check-label" for="memory0">0</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.memory"
                    class="form-check-input"
                    type="radio"
                    required
                    name="memory"
                    id="memory1"
                    [value]="1"
                  />
                  <label class="form-check-label" for="memory1">1</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.memory"
                    class="form-check-input"
                    type="radio"
                    required
                    name="memory"
                    id="memory2"
                    [value]="2"
                  />
                  <label class="form-check-label" for="memory2">2</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.memory"
                    class="form-check-input"
                    type="radio"
                    required
                    name="memory"
                    id="memory3"
                    [value]="3"
                  />
                  <label class="form-check-label" for="memory3">3</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.memory"
                    class="form-check-input"
                    type="radio"
                    required
                    name="memory"
                    id="memory4"
                    [value]="4"
                  />
                  <label class="form-check-label" for="memory4">4</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.memory"
                    class="form-check-input"
                    type="radio"
                    required
                    name="memory"
                    id="memory5"
                    [value]="5"
                  />
                  <label class="form-check-label" for="memory5">5</label>
                </div>
              </div>
            </fieldset>
            <div class="row form-instructions">
              <div class="col">
                <span class="arrow-left"></span>
                <span class="symptom-desc-low">I have no problem remembering things</span>
              </div>
              <div class="col text-end">
                <span class="arrow-right"></span>
                <span class="symptom-desc-high">I have trouble remembering anything</span>
              </div>
            </div>
          </div>

          
          
          <!-- SLEEP -->
          <div class="form-group" *ngIf="currentSymptom =='sleep'">
            <fieldset>
              <legend>
                <span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'sleep'"></app-symptom-icon></span>
                Sleep
              </legend>
              <p *ngIf="symptomsBaseline" class="sympton-desc">Your baseline level for this tracker is set at <b>{{symptomsBaseline.sleep}}</b></p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.sleep<3" class="sympton-desc">A high level for this tracker is <b>3</b> and above.</p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.sleep>=3 && symptomsBaseline.sleep!=5" class="sympton-desc">A high level for this tracker is <b>{{symptomsBaseline.sleep+1}}</b> and above.</p>
              <p class="sympton-desc">Please select a number below that currently represents the quality of sleep you've been getting.</p>
              <div class="radio-group">
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.sleep"
                    class="form-check-input"
                    type="radio"
                    required
                    name="sleep"
                    id="sleep0"
                    [value]="0"
                  />
                  <label class="form-check-label" for="sleep0">0</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.sleep"
                    class="form-check-input"
                    type="radio"
                    required
                    name="sleep"
                    id="sleep1"
                    [value]="1"
                  />
                  <label class="form-check-label" for="sleep1">1</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.sleep"
                    class="form-check-input"
                    type="radio"
                    required
                    name="sleep"
                    id="sleep2"
                    [value]="2"
                  />
                  <label class="form-check-label" for="sleep2">2</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.sleep"
                    class="form-check-input"
                    type="radio"
                    required
                    name="sleep"
                    id="sleep3"
                    [value]="3"
                  />
                  <label class="form-check-label" for="sleep3">3</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.sleep"
                    class="form-check-input"
                    type="radio"
                    required
                    name="sleep"
                    id="sleep4"
                    [value]="4"
                  />
                  <label class="form-check-label" for="sleep4">4</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.sleep"
                    class="form-check-input"
                    type="radio"
                    required
                    name="sleep"
                    id="sleep5"
                    [value]="5"
                  />
                  <label class="form-check-label" for="sleep5">5</label>
                </div>
              </div>
            </fieldset>
            <div class="row form-instructions">
              <div class="col">
                <span class="arrow-left"></span>
                <span class="symptom-desc-low">I sleep soundly</span>
              </div>
              <div class="col text-end">
                <span class="arrow-right"></span>
                <span class="symptom-desc-high">I DON'T sleep soundly because of my condition</span>
              </div>
            </div>
          </div>

          <!--ENERGY -->
          <div class="form-group" *ngIf="currentSymptom =='energy'">
            <fieldset>
              <legend>
                <span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'energy'"></app-symptom-icon></span>
                Energy
              </legend>
              <p *ngIf="symptomsBaseline" class="sympton-desc">Your baseline level for this tracker is set at <b>{{symptomsBaseline.energy}}</b></p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.energy<3" class="sympton-desc">A high level for this tracker is <b>3</b> and above.</p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.energy>=3 && symptomsBaseline.energy!=5" class="sympton-desc">A high level for this tracker is <b>{{symptomsBaseline.energy+1}}</b> and above.</p>
              <p class="sympton-desc">Please select a number below that represents how energetic you've been feeling recently.</p>
              <div class="radio-group">
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.energy"
                    class="form-check-input"
                    type="radio"
                    required
                    name="energy"
                    id="energy0"
                    [value]="0"
                  />
                  <label class="form-check-label" for="energy0">0</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.energy"
                    class="form-check-input"
                    type="radio"
                    required
                    name="energy"
                    id="energy1"
                    [value]="1"
                  />
                  <label class="form-check-label" for="energy1">1</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.energy"
                    class="form-check-input"
                    type="radio"
                    required
                    name="energy"
                    id="energy2"
                    [value]="2"
                  />
                  <label class="form-check-label" for="energy2">2</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.energy"
                    class="form-check-input"
                    type="radio"
                    required
                    name="energy"
                    id="energy3"
                    [value]="3"
                  />
                  <label class="form-check-label" for="energy3">3</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.energy"
                    class="form-check-input"
                    type="radio"
                    required
                    name="energy"
                    id="energy4"
                    [value]="4"
                  />
                  <label class="form-check-label" for="energy4">4</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.energy"
                    class="form-check-input"
                    type="radio"
                    required
                    name="energy"
                    id="energy5"
                    [value]="5"
                  />
                  <label class="form-check-label" for="energy5">5</label>
                </div>
              </div>
            </fieldset>
            <div class="row form-instructions">
              <div class="col">
                <span class="arrow-left"></span>
                <span class="symptom-desc-low">LOTS of energy</span>
              </div>
              <div class="col text-end">
                <span class="arrow-right"></span>
                <span class="symptom-desc-high">No energy at all</span>
              </div>
            </div>
          </div>

          <!-- FATIGUE -->
          <div class="form-group" *ngIf="currentSymptom =='fatigue'">
            <fieldset>
              <legend>
                <span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'fatigue'"></app-symptom-icon></span>
                Fatigue
              </legend>
              <p *ngIf="symptomsBaseline" class="sympton-desc">Your baseline level for this tracker is set at <b>{{symptomsBaseline.fatigue}}</b></p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.fatigue<3" class="sympton-desc">A high level for this tracker is <b>3</b> and above.</p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.fatigue>=3 && symptomsBaseline.fatigue!=5" class="sympton-desc">A high level for this tracker is <b>{{symptomsBaseline.fatigue+1}}</b> and above.</p>
              <p class="sympton-desc">Please select a number below that represents how energetic you've been feeling recently.</p>
              <div class="radio-group">
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.fatigue"
                    class="form-check-input"
                    type="radio"
                    required
                    name="fatigue"
                    id="fatigue0"
                    [value]="0"
                  />
                  <label class="form-check-label" for="fatigue0">0</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.fatigue"
                    class="form-check-input"
                    type="radio"
                    required
                    name="fatigue"
                    id="fatigue1"
                    [value]="1"
                  />
                  <label class="form-check-label" for="fatigue1">1</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.fatigue"
                    class="form-check-input"
                    type="radio"
                    required
                    name="fatigue"
                    id="fatigue2"
                    [value]="2"
                  />
                  <label class="form-check-label" for="fatigue2">2</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.fatigue"
                    class="form-check-input"
                    type="radio"
                    required
                    name="fatigue"
                    id="fatigue3"
                    [value]="3"
                  />
                  <label class="form-check-label" for="fatigue3">3</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.fatigue"
                    class="form-check-input"
                    type="radio"
                    required
                    name="fatigue"
                    id="fatigue4"
                    [value]="4"
                  />
                  <label class="form-check-label" for="fatigue4">4</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.fatigue"
                    class="form-check-input"
                    type="radio"
                    required
                    name="fatigue"
                    id="fatigue5"
                    [value]="5"
                  />
                  <label class="form-check-label" for="fatigue5">5</label>
                </div>
              </div>
            </fieldset>
            <div class="row form-instructions">
              <div class="col">
                <span class="arrow-left"></span>
                <span class="symptom-desc-low">I have lots of energy</span>
              </div>
              <div class="col text-end">
                <span class="arrow-right"></span>
                <span class="symptom-desc-high">I have no energy at all</span>
              </div>
            </div>
          </div>

          
          <!-- MOOD -->
          <div class="form-group" *ngIf="currentSymptom =='mood'">
            <fieldset>
              <legend>
                <span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'mood'"></app-symptom-icon></span>
                Mood
              </legend>
              <p *ngIf="symptomsBaseline" class="sympton-desc">Your baseline level for this tracker is set at <b>{{symptomsBaseline.mood}}</b></p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.mood<3" class="sympton-desc">A high level for this tracker is <b>3</b> and above.</p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.mood>=3 && symptomsBaseline.mood!=5" class="sympton-desc">A high level for this tracker is <b>{{symptomsBaseline.mood+1}}</b> and above.</p>
              <p class="sympton-desc">Please select a number below that represents how you've been feeling.</p>
              <div class="radio-group">
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.mood"
                    class="form-check-input"
                    type="radio"
                    required
                    name="mood"
                    id="mood0"
                    [value]="0"
                  />
                  <label class="form-check-label" for="mood0">0</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.mood"
                    class="form-check-input"
                    type="radio"
                    required
                    name="mood"
                    id="mood1"
                    [value]="1"
                  />
                  <label class="form-check-label" for="mood1">1</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.mood"
                    class="form-check-input"
                    type="radio"
                    required
                    name="mood"
                    id="mood2"
                    [value]="2"
                  />
                  <label class="form-check-label" for="mood2">2</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.mood"
                    class="form-check-input"
                    type="radio"
                    required
                    name="mood"
                    id="mood3"
                    [value]="3"
                  />
                  <label class="form-check-label" for="mood3">3</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.mood"
                    class="form-check-input"
                    type="radio"
                    required
                    name="mood"
                    id="mood4"
                    [value]="4"
                  />
                  <label class="form-check-label" for="mood4">4</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.mood"
                    class="form-check-input"
                    type="radio"
                    required
                    name="mood"
                    id="mood5"
                    [value]="5"
                  />
                  <label class="form-check-label" for="mood5">5</label>
                </div>
              </div>
            </fieldset>
            <div class="row form-instructions">
              <div class="col">
                <span class="arrow-left"></span>
                <span class="symptom-desc-low">My mood is NOT affected</span>
              </div>
              <div class="col text-end">
                <span class="arrow-right"></span>
                <span class="symptom-desc-high">I am LOW in mood all the time</span>
              </div>
            </div>
          </div>

          <!-- APPETITE -->
          <div class="form-group" *ngIf="currentSymptom =='appetite'">
            <fieldset>
              <legend>
                <span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'appetite'"></app-symptom-icon></span>
                Appetite
              </legend>
              <p *ngIf="symptomsBaseline" class="sympton-desc">Your baseline level for this tracker is set at <b>{{symptomsBaseline.appetite}}</b></p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.appetite<3" class="sympton-desc">A high level for this tracker is <b>3</b> and above.</p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.appetite>=3 && symptomsBaseline.appetite!=5" class="sympton-desc">A high level for this tracker is <b>{{symptomsBaseline.appetite+1}}</b> and above.</p>
              <p class="sympton-desc">Please select a number below that currently represents your appetite level.</p>
              <div class="radio-group">
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.appetite"
                    class="form-check-input"
                    type="radio"
                    required
                    name="appetite"
                    id="appetite0"
                    [value]="0"
                  />
                  <label class="form-check-label" for="appetite0">0</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.appetite"
                    class="form-check-input"
                    type="radio"
                    required
                    name="appetite"
                    id="appetite1"
                    [value]="1"
                  />
                  <label class="form-check-label" for="appetite1">1</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.appetite"
                    class="form-check-input"
                    type="radio"
                    required
                    name="appetite"
                    id="appetite2"
                    [value]="2"
                  />
                  <label class="form-check-label" for="appetite2">2</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.appetite"
                    class="form-check-input"
                    type="radio"
                    required
                    name="appetite"
                    id="appetite3"
                    [value]="3"
                  />
                  <label class="form-check-label" for="appetite3">3</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.appetite"
                    class="form-check-input"
                    type="radio"
                    required
                    name="appetite"
                    id="appetite4"
                    [value]="4"
                  />
                  <label class="form-check-label" for="appetite4">4</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.appetite"
                    class="form-check-input"
                    type="radio"
                    required
                    name="appetite"
                    id="appetite5"
                    [value]="5"
                  />
                  <label class="form-check-label" for="appetite5">5</label>
                </div>
              </div>
            </fieldset>
            <div class="row form-instructions">
              <div class="col">
                <span class="arrow-left"></span>
                <span class="symptom-desc-low">I have an appetite</span>
              </div>
              <div class="col text-end">
                <span class="arrow-right"></span>
                <span class="symptom-desc-high">I have NO appetite at all​</span>
              </div>
            </div>
          </div>


          <!-- POST SURGERY PAIN -->
          <div class="form-group" *ngIf="diagnosisMode == 'cardiac' && currentSymptom =='surgery_pain'">
            <fieldset>
              <legend>
                <span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'surgery_pain'"></app-symptom-icon></span>
                Post Surgery Pain
              </legend>
              <p *ngIf="symptomsBaseline" class="sympton-desc">Your baseline level for this tracker is set at <b>{{symptomsBaseline.surgery_pain}}</b></p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.surgery_pain<3" class="sympton-desc">A high level for this tracker is <b>3</b> and above.</p>
              <p *ngIf="symptomsBaseline && symptomsBaseline.surgery_pain>=3 && symptomsBaseline.surgery_pain!=5" class="sympton-desc">A high level for this tracker is <b>{{symptomsBaseline.surgery_pain+1}}</b> and above.</p>
              <p class="sympton-desc">Please select a number below that represents your current pain level after your surgery.</p>
              <div class="radio-group">
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.surgery_pain"
                    class="form-check-input"
                    type="radio"
                    required
                    name="surgery_pain"
                    id="surgery_pain0"
                    [value]="0"
                  />
                  <label class="form-check-label" for="surgery_pain0">0</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.surgery_pain"
                    class="form-check-input"
                    type="radio"
                    required
                    name="surgery_pain"
                    id="surgery_pain1"
                    [value]="1"
                  />
                  <label class="form-check-label" for="surgery_pain1">1</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.surgery_pain"
                    class="form-check-input"
                    type="radio"
                    required
                    name="surgery_pain"
                    id="surgery_pain2"
                    [value]="2"
                  />
                  <label class="form-check-label" for="surgery_pain2">2</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.surgery_pain"
                    class="form-check-input"
                    type="radio"
                    required
                    name="surgery_pain"
                    id="surgery_pain3"
                    [value]="3"
                  />
                  <label class="form-check-label" for="surgery_pain3">3</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.surgery_pain"
                    class="form-check-input"
                    type="radio"
                    required
                    name="surgery_pain"
                    id="surgery_pain4"
                    [value]="4"
                  />
                  <label class="form-check-label" for="surgery_pain4">4</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    [(ngModel)]="symptomData.surgery_pain"
                    class="form-check-input"
                    type="radio"
                    required
                    name="surgery_pain"
                    id="surgery_pain5"
                    [value]="5"
                  />
                  <label class="form-check-label" for="surgery_pain5">5</label>
                </div>
              </div>
            </fieldset>
            <div class="row form-instructions">
              <div class="col">
                <span class="arrow-left"></span>
                <span class="symptom-desc-low">I have no pain</span>
              </div>
              <div class="col text-end">
                <span class="arrow-right"></span>
                <span class="symptom-desc-high">I have  pain all the time / sharp / dull ache</span>

              </div>
            </div>
          </div>
          <!-- BUTTON - Save update -->
          <div class="mt-3 mb-3 text-center">
            <button class="btn btn-primary btn-block w-100" [disabled]="!form.valid">Save update</button>
            
          </div>
        </form>
        <!-- BUTTON - Delete -->
        <div class="text-center mt-5" *ngIf="userSymptomId">
          <button class="btn btn-white btn-sm" (click)="onDelete()">
            Delete
            <span class="icon-wrap text-danger"
              ><fa-icon [icon]="['fal', 'trash']"></fa-icon
            ></span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
