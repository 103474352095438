import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class Eq5dService {
  error = new Subject<string>();

  constructor(private http: HttpClient) {}

  fetchResponse(stage: number) {
    let params = new HttpParams();
    params = params.append('stage', stage.toString());

    return this.http
      .get<any>(environment.apiUrl + '/eq5d_responses', {
        responseType: 'json',
        params,
      })
      .pipe(
        map((responseData) => {
          if (responseData['_embedded'].eq5d_responses[0]) {
            return responseData['_embedded'].eq5d_responses[0];
          } else {
            return null;
          }
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
  saveResponse(stage:any, mobility:any, selfcare:any, activities:any, pain:any, anxiety:any, health_rating:any) {
    const postData = {
      stage,
      mobility,
      selfcare,
      activities,
      pain,
      anxiety,
      health_rating,
    };
    return this.http.post<any>(
      environment.apiUrl + '/eq5d_responses',
      postData,
      {
        observe: 'response',
      }
    );
  }
}
