import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ContactService {
  constructor(private http: HttpClient) {}

  send(name: string, email: string, message: string) {
    const postData = {
      name,
      email,
      message,
    };
    return this.http.post<any>(environment.apiUrl + '/contact', postData, {
      observe: 'response',
    });
  }
}
