import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';

import { map, catchError } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserResourceService {
  error = new Subject<string>();

  constructor(private http: HttpClient) {}

  fetchAll() {
    return this.http
      .get<any>(environment.apiUrl + '/user_resources', {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          return responseData['_embedded'].user_resources;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }


  add(resourceIds:any) {
    return this.http.post<{ name: string }>(
      environment.apiUrl + '/user_resources',
      { resource_ids: resourceIds },
      {
        observe: 'response',
      }
    );
  }
}
