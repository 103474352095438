import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { map, catchError, tap, publishReplay, refCount } from 'rxjs/operators';
import { Observable, Subject, throwError } from 'rxjs';

import { Goal } from './goal.model';
import { environment } from './../../environments/environment';
import * as moment from 'moment';
import { DateHelperService } from '../services/date-helper.service';

@Injectable({
  providedIn: 'root',
})
export class GoalsService {
  error = new Subject<string>();
  cachedCategories: any;
  cacheTime = new Date();
  cacheLifetime = 1 * 60 * 1000;

  constructor(private http: HttpClient, private datePipe: DatePipe, private dateHelperService:DateHelperService) {}

  fetchCategories(): Observable<any[]> {
    const now = new Date();

    if (
      !this.cachedCategories ||
      Math.abs(now.getTime() - this.cacheTime.getTime()) > this.cacheLifetime
    ) {
      this.cachedCategories = this.http
        .get<any>(environment.apiUrl + '/goal_categories', {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const goalsCategoryArray: any = [];
            responseData['_embedded'].goal_categories.forEach(
              (goalCategory: any) => {
                goalsCategoryArray.push(goalCategory);
              }
            );
            return goalsCategoryArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.cachedCategories;
  }

  fetchGoals(status?: number, custom?: number) {
    let searchParams = new HttpParams();
    if (status) {
      searchParams = searchParams.append('status', status.toString());
    }
    if (custom) {
      searchParams = searchParams.append('custom', custom.toString());
    }
    return this.http
      .get<any>(environment.apiUrl + '/goals', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const goalsArray: Goal[] = [];
          responseData['_embedded'].goals.forEach((goal: any) => {
            goalsArray.push(goal);
          });
          return goalsArray;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + '/goals/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const goal = new Goal(
            responseData.goal_id,
            responseData.goal_category_id,
            responseData.goal_category,
            responseData.what,
            responseData.why,
            responseData.how,
            responseData.barriers,
            responseData.start,
            responseData.custom,
            responseData.status,
            responseData.progress,
            responseData.created,
            responseData.modified,
            responseData.category
          );
          return goal;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  createGoal(
    goal_category: any,
    what: any,
    why: any,
    how: any,
    barriers: any,
    start: any,
    custom: any,
    status: any,
    progress: any
  ) {
    const goalData = {
      goal_category_id: goal_category,
      what,
      why,
      how,
      barriers,
      start: this.datePipe.transform(start, 'yyyy-MM-dd'),
      custom,
      status: 1,
      progress: 0,
    };
    return this.http.post<{ name: string }>(
      environment.apiUrl + '/goals',
      goalData,
      {
        observe: 'response',
      }
    );
  }

  updateGoal(
    id: any,
    goal_category_id: any,
    what: any,
    why: any,
    how: any,
    barriers: any,
    start: any,
    custom: any,
    status: any,
    progress: any
  ) {
    const goalData = {
      goal_category_id,
      what,
      why,
      how,
      barriers,
      start,
      custom,
      status,
      progress,
    };
    return this.http.patch<{ name: string }>(
      environment.apiUrl + '/goals/' + id,
      goalData,
      {
        observe: 'response',
      }
    );
  }

  delete(id: any) {
    return this.http.delete<{ name: string }>(
      environment.apiUrl + '/goals/' + id
    );
  }

  getWeeklyStatus(goals: Goal[]) {/*
    let status: any = {
      walking: 0,
      strength: 0,
      yoga: 0,
    };
    let currentWeek = this.dateHelperService.getCurrentWeek();

    goals.forEach((goal: Goal) => {
      if (
        (moment(goal.created) >= moment(currentWeek[0]) &&
          moment(goal.created) <= moment(currentWeek[6])) ||
        +goal.status === 0
      ) {
        if (status[goal.area] < 1) {
          status[goal.area] = 1;
        }
      }
      if (
        moment(goal.modified) >= moment(currentWeek[0]) &&
        moment(goal.modified) <= moment(currentWeek[6])
      ) {
        if (status[goal.area] < 2) {
          status[goal.area] = 2;
        }
      }
    });

    return status;*/
  }

  getQuickestGoal(goals: Goal[]) {
    let quickest = -1;
    goals.forEach((goal) => {
      // compare days
      if (goal.status == 1) {
        let dayDiff = moment(goal.modified).diff(moment(goal.created), "days");
        if (quickest === -1 || dayDiff < quickest) {
          quickest = dayDiff;
        }
      }
    });
    return quickest;
  }
}
