import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpParams,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import {
  take,
  exhaustMap,
  tap,
  catchError,
  filter,
  switchMap,
} from 'rxjs/operators';

import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { AuthService } from '../service/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthRefreshInterceptorService implements HttpInterceptor {
  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  constructor(private authService: AuthService, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // We don't want to refresh token for some requests like login or refresh token itself
        // So we verify url and we throw an error if it's the case
        if (
          request.url.includes('refreshtoken') ||
          request.url.includes('login') ||
          request.url.includes('refresh')
        ) {
          // We do another check to see if refresh token failed
          // In this case we want to logout user and to redirect it to login page

          if (request.url.includes('refreshtoken')) {
            this.authService.logout();
          }

          return throwError(error);
        }

        // If error status is different than 401 we want to skip refresh token
        // So we check that and throw the error if it's the case
        if (error.status !== 401) {
          return throwError(error);
        }

        if (this.refreshTokenInProgress) {
          // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
          // – which means the new token is ready and we can retry the request again
          return this.refreshTokenSubject.pipe(
            filter((result) => result !== null),
            take(1),
            switchMap(() => next.handle(this.addAuthenticationToken(request)))
          );
        } else {
          this.refreshTokenInProgress = true;

          // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
          this.refreshTokenSubject.next(null);
          // console.log('got here first');
          // Call auth.refreshAccessToken(this is an Observable that will be returned)
          return this.authService.refreshAccessToken().pipe(
            switchMap((token: any) => {
              // console.log('got a token!');
              // console.log(token);
              //When the call to refreshToken completes we reset the refreshTokenInProgress to false
              // for the next time the token needs to be refreshed
              this.refreshTokenInProgress = false;
              this.refreshTokenSubject.next(token);

              return next.handle(this.addAuthenticationToken(request));
            }),
            catchError((err: any) => {
              // we didn't get a new token..
              this.refreshTokenInProgress = false;
              // how about restoring?
              const autoLoginObs = this.authService.autoLogin();
              if (autoLoginObs) {
                autoLoginObs.subscribe(
                  (resData) => {
                    console.log(resData);
                    this.router.navigate(['/dashboard']);
                  },
                  (errorMessage) => {
                    // else
                    this.authService.logout();
                    this.authService.user.next(null);
                    this.router.navigate(['/login']);
                  }
                );
              } else {
                if (this.router.url != '/login' && this.router.url != '/register') {
                  this.authService.logout();
                  this.authService.user.next(null);
                  this.router.navigate(['/login']);
                }
              }

              return throwError(error);
            })
          );
        }
      })
    );

    /*
    return this.authService.user.pipe(
      take(1),
      exhaustMap((user) => {
        if (!user) {
          return next.handle(req);
        }
        const modifiedReq = req.clone({
           headers: new HttpHeaders().set('Authorization', 'Bearer ' + user.token),
        });
        return next.handle(modifiedReq).pipe( tap(() => {},
        (err: any) => {
        if (err instanceof HttpErrorResponse) {
         if (err.status !== 401) {
           return;
          }
          // get a new token

          if (this.refreshTokenInProgress) {
            // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
            // – which means the new token is ready and we can retry the request again
            return this.refreshTokenSubject
                .filter(result => result !== null)
                .take(1)
                .switchMap(() => next.handle(this.addAuthenticationToken(request)));
        } else {
            this.refreshTokenInProgress = true;

            // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
            this.refreshTokenSubject.next(null);

            // Call auth.refreshAccessToken(this is an Observable that will be returned)
            return this.auth
                .refreshAccessToken()
                .switchMap((token: any) => {
                    //When the call to refreshToken completes we reset the refreshTokenInProgress to false
                    // for the next time the token needs to be refreshed
                    this.refreshTokenInProgress = false;
                    this.refreshTokenSubject.next(token);

                    return next.handle(this.addAuthenticationToken(request));
                })
                .catch((err: any) => {
                    this.refreshTokenInProgress = false;

                    this.authService.logout();
                    return Observable.throw(error);
                });
        }

          // end get a new token


          //this.router.navigate(['login']);
        }
      }));
      })
    );*/
  }

  addAuthenticationToken(request: any) {
    // Get access token from Local Storage
    const accessToken = this.authService.getAccessToken();

    // If access token is null this means that user is not logged in
    // And we return the original request
    if (!accessToken) {
      return request;
    }

    // We clone the request, because the original request is immutable
    return request.clone({
      setHeaders: {
        Authorization: 'Bearer ' + this.authService.getAccessToken(),
      },
    });
  }
}
