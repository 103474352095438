import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard-masthead-goals',
  templateUrl: './dashboard-masthead-goals.component.html',
  styleUrls: ['./dashboard-masthead-goals.component.scss'],
})
export class DashboardMastheadGoalsComponent implements OnInit {
  show = true;

  @Input() openGoals: any;
  @Input() goalsToReview: any;
  @Input() goalsComplete: any;

  constructor() {}

  ngOnInit(): void {}
  onClose(event: any) {
    this.show = false;
  }
}
