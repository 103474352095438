
<div class="main-content-area bg-light">

  <div class="row" *ngIf="results">
    <div class="col">
      <p class="pt-3 ps-3">
        Your search for <strong>{{ query }}</strong> returned {{ results.total }} result<span
          *ngIf="+results.total != 1"
          >s</span
        >
      </p>

      <div *ngIf="results.resources.length > 0" class="more-resources pt-0">
        <h4>Resources</h4>
        <ul class="tracker-list">
          <li *ngFor="let result of results.resources">
            <a
              [routerLink]="[
                '/resources',
                result.content_section_id,
                result.content_page_id
              ]"
              class="task"
            >
              <span class="tracker-info">
                <span class="tracker-title"
                  >{{ result.content_section_title }} > {{ result.title }}</span
                >
              </span>
              <span class="icon-wrap float-end icon-more"
                ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon>
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div *ngIf="results.readingList.length > 0" class="more-resources">
      <h4>Reading List</h4>
      <ul class="tracker-list">
        <li *ngFor="let result of results.readingList" >
          <a
            [routerLink]="[
              '/resources',
              result.content_section_id,
              result.content_page_id
            ]"
            class="task"
          >
            <span class="tracker-info">
              <span class="tracker-title"
                >{{ result.content_section_title }} > {{ result.title }}</span
              >
            </span>
            <span class="icon-wrap float-end icon-more"
              ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon>
            </span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
