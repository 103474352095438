<div *ngIf="!completed">
  <div class="internal-app card card-light mt-3 mb-4">
    <div class="card-body">
      <h4>Risk Factors</h4>
      <form
        (ngSubmit)="onSubmit()"
        #form="ngForm"
        class="sleep-score-form eq5d-form"
      >
        <table cellspacing="0" cellpadding="0" class="table table-border">
          
          <tbody>
            <tr
              [ngClass]="{
                'bg-danger text-white': riskFactorsScores.smoking == 'red',
                'bg-warning': riskFactorsScores.smoking == 'amber',
                'bg-success': riskFactorsScores.smoking == 'green'
              }"
            >
              <td valign="top">
                <p>
                  <strong>Smoking</strong>
                </p>
              </td>
              <td valign="top">
                <div class="form-group radio-group">
                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      name="smoking"
                      value="1"
                      id="smokinga"
                      ngModel
                      #smoking="ngModel"
                    />
                    <label class="form-check-label" for="smokinga">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Yes
                    </label>
                  </div>

                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      name="smoking"
                      value="2"
                      id="smokingb"
                      ngModel
                      #smoking="ngModel"
                    />
                    <label class="form-check-label" for="smokingb">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      No
                    </label>
                  </div>

                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      name="smoking"
                      value="3"
                      id="smokingc"
                      ngModel
                      #smoking="ngModel"
                    />
                    <label class="form-check-label" for="smokingc">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Quit over 6 months
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr
              [ngClass]="{
                'bg-danger text-white': riskFactorsScores.diabetes == 'red',
                'bg-warning': riskFactorsScores.diabetes == 'amber',
                'bg-success': riskFactorsScores.diabetes == 'green'
              }"
            >
              <td valign="top">
                <p>
                  <strong>Diabetes</strong>
                </p>
              </td>
              <td valign="top">
                <p>
                  HbA1c (%):
                  <input
                    type="text"
                    class="form-control"
                    name="diabetes"
                    ngModel
                    #diabetes="ngModel"
                  />
                </p>
              </td>
            </tr>
            <tr
              [ngClass]="{
                'bg-danger text-white': riskFactorsScores.bmi == 'red',
                'bg-warning': riskFactorsScores.bmi == 'amber',
                'bg-success': riskFactorsScores.bmi == 'green'
              }"
            >
              <td valign="top">
                <p>
                  <strong>Obesity</strong>
                </p>
              </td>
              <td valign="top">
                <p>
                  BMI:
                  <input
                    type="text"
                    class="form-control"
                    name="bmi"
                    ngModel
                    #bmi="ngModel"
                  />
                </p>
              </td>
            </tr>
            <tr
              [ngClass]="{
                'bg-danger text-white':
                  riskFactorsScores.bloodPressure == 'red',
                'bg-warning': riskFactorsScores.bloodPressure == 'amber',
                'bg-success': riskFactorsScores.bloodPressure == 'green'
              }"
            >
              <td valign="top">
                <p>
                  <strong>High blood</strong>
                </p>
                <p>
                  <strong>pressure</strong>
                </p>
              </td>
              <td valign="top">
                <p>
                  Systolic: mmhg
                  <input
                    type="text"
                    class="form-control"
                    name="bloodPressureSys"
                    ngModel
                    #bloodPressureSys="ngModel"
                  />
                </p>
                <p>
                  Diastolic: mmhg
                  <input
                    type="text"
                    class="form-control"
                    name="bloodPressureDia"
                    ngModel
                    #bloodPressureDia="ngModel"
                  />
                </p>
              </td>
            </tr>
            <tr
              [ngClass]="{
                'bg-danger text-white': riskFactorsScores.activity == 'red',
                'bg-warning': riskFactorsScores.activity == 'amber',
                'bg-success': riskFactorsScores.activity == 'green'
              }"
            >
              <td valign="top">
                <p>
                  <strong>Activity levels</strong>
                </p>
              </td>
              <td valign="top">
                <div class="form-group radio-group">
                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      name="activity"
                      value="1"
                      id="activitya"
                      ngModel
                      #activity="ngModel"
                    />
                    <label class="form-check-label" for="activitya">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Inactive
                    </label>
                  </div>

                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      name="activity"
                      value="2"
                      id="activityb"
                      ngModel
                      #activity="ngModel"
                    />
                    <label class="form-check-label" for="activityb">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Light
                    </label>
                  </div>

                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      name="activity"
                      value="3"
                      id="activityc"
                      ngModel
                      #activity="ngModel"
                    />
                    <label class="form-check-label" for="activityc">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Moderate
                    </label>
                  </div>

                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      name="activity"
                      value="4"
                      id="activityd"
                      ngModel
                      #activity="ngModel"
                    />
                    <label class="form-check-label" for="activityd">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Very active
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr
              [ngClass]="{
                'bg-danger text-white': riskFactorsScores.depression == 'red',
                'bg-warning': riskFactorsScores.depression == 'amber',
                'bg-success': riskFactorsScores.depression == 'green'
              }"
            >
              <td valign="top">
                <p>
                  <strong>Depression</strong>
                </p>
              </td>
              <td valign="top">
                <div class="form-group radio-group">
                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      name="depression"
                      value="1"
                      id="depressiona"
                      ngModel
                      #depression="ngModel"
                    />
                    <label class="form-check-label" for="depressiona">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Not clinically depressed
                    </label>
                  </div>

                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      name="depression"
                      value="2"
                      id="depressionb"
                      ngModel
                      #depression="ngModel"
                    />
                    <label class="form-check-label" for="depressionb">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Mild
                    </label>
                  </div>

                  <div class="form-check">
                    <input
                      type="radio"
                      class="form-check-input"
                      name="depression"
                      value="3"
                      id="depressionc"
                      ngModel
                      #depression="ngModel"
                    />
                    <label class="form-check-label" for="depressionc">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Clinically depressed
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr
              [ngClass]="{
                'bg-danger text-white': riskFactorsScores.cholesterol == 'red',
                'bg-warning': riskFactorsScores.cholesterol == 'amber',
                'bg-success': riskFactorsScores.cholesterol == 'green'
              }"
            >
              <td valign="top">
                <p>
                  <strong>Cholesterol</strong>
                </p>
              </td>
              <td valign="top">
                <p>
                  LDL: mmol/L
                  <input
                    type="text"
                    class="form-control"
                    name="ldl"
                    ngModel
                    #ldl="ngModel"
                  />
                </p>
                <p>
                  Cholesterol / HDL ratio:
                  <input
                    type="text"
                    class="form-control"
                    name="ratio"
                    ngModel
                    #ratio="ngModel"
                  />
                </p>
                <p>
                  Triglycerides: mmol/L
                  <input
                    type="text"
                    class="form-control"
                    name="trig"
                    ngModel
                    #trig="ngModel"
                  />
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <button class="btn btn-primary w-100 mb-4" [disabled]="!form.valid">
          Submit
        </button>
      </form>
    </div>
  </div>
</div>
