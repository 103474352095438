
<div class="container relative">
  <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>
  <div class="card text-center card-light mt-3">
    <div class="card-body text-center">
      <div *ngIf="weight">
        <h3 class="mt-1 mb-3 sm-title">Update Weight</h3>
        <form class="weight-form" (ngSubmit)="onSubmit()" #form="ngForm">
          <div class="form-group text-center mb-3">
            <input
              type="text"
              class="form-control from-control-xl-micro"
              name="weightInput"
              id="weightInput"
              placeholder="00"
              rows=""
              [ngModel]="weight.weight"
              #weightInput="ngModel"
              required
            />
            <span class="xl-micro-addon">Kg</span>
          </div>

          <button class="btn btn-primary ">Update <span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span></button>
        </form>
      </div>
    </div>
  </div>

  <div class="text-center mt-5"><button class="btn btn-white btn-sm" (click)="onDelete();">Delete <span class="icon-wrap text-danger"><fa-icon [icon]="['fal', 'trash']"></fa-icon></span></button></div>
  
</div>
