import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-footer-nav',
  templateUrl: './footer-nav.component.html',
  styleUrls: ['./footer-nav.component.scss'],
})
export class FooterNavComponent implements OnInit, OnDestroy {
  hasFooterNav = true;
  currentUrl:any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe((val) => {
      // see also
      if(val instanceof NavigationEnd){
        this.currentUrl = val.url;
      }
    });
    this.renderer.addClass(this.document.body, 'has-footer-nav');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'has-footer-nav');
  }

  checkUrl(){
    let navItem = '';
    if(this.currentUrl){
      if(this.currentUrl.includes('/goals')){
        navItem = 'trackers';
      }
      else if(this.currentUrl.includes('/task-list')){
        navItem = 'more';
      }
      else if(this.currentUrl.includes('/bitesize')){
        navItem = 'resources';
      }
      else if(this.currentUrl.includes('/chat')){
        navItem = 'more';
      }
      else if(this.currentUrl.includes('/action-plan')){
        navItem = 'more';
      }
      else if(this.currentUrl.includes('/notifications')){
        navItem = 'more';
      }
      else if(this.currentUrl.includes('/achievements')){
        navItem = 'more';
      }
      else if(this.currentUrl.includes('/notifications')){
        navItem = 'more';
      }
      else if(this.currentUrl.includes('/update-details')){
        navItem = 'more';
      }
      else if(this.currentUrl.includes('/contact')){
        navItem = 'more';
      }
      else if(this.currentUrl.includes('/terms')){
        navItem = 'more';
      }
      else if(this.currentUrl.includes('/privacy')){
        navItem = 'more';
      }
      else if(this.currentUrl.includes('/getting-started')){
        navItem = 'dashboard';
      }
      else if(this.currentUrl.includes('/questionnaires/eq5d')){
        navItem = 'dashboard';
      }
      else if(this.currentUrl.includes('/feedback')){
        navItem = 'dashboard';
      }
    }
    return navItem;
  }
}
