<app-header-bar [title]="'Getting around - in and outdoors'"></app-header-bar>
<div class="container" *ngIf="goal">
  <div class="row">
    <div class="col">
      <h3 class="sm-title sm-title-lineless mt-3 text-center">
        <span class="icon-wrap text-secondary">
          <fa-icon [icon]="['far', 'calendar-alt']"></fa-icon>
        </span>&nbsp;
        Review by: {{goal.start | date: "EEE d MMM"}}
      </h3>
      <form class="goal-form">
        
        <fieldset>
          <div class="form-group text-center ml-2 mr-2">
            <label for="Wwhathat"
              >How far are you to achieving your goal?</label
            >
            <hr class="mt-0">
            <div class="slider-container">
              <span class="slider-value mb-2">
                <input type="text" class="form-control visually-hidden"
                value="{{value}}%">
              </span>
              <span class="slider-wrap">
                <ngx-slider [(value)]="value" [options]="options"></ngx-slider>
              </span>
            </div>
            
            
            <div class="text-center">
              <button class="btn btn-primary btn-wide" (click)="onUpdate()">
                Update
                <span class="icon-wrap">
                  <fa-icon
                    [icon]="['far', 'arrow-circle-right']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </button>
            </div>
            <hr />
            <p class="small text-center" *ngIf="goal.modified">Last updated: {{goal.modified | customDate :"ddd Do MMM"}}</p>
          </div>
        </fieldset>
      </form>
      <ul class="task-list task-list-goals task-list-goal-info mt-3">
        <li>
          <span class="flex-wrap">
          <span class="num">
            1
          </span>
          <span class="task">
            <span class="title">
               What do you want to be able to do?
               <p>{{goal.what}}</p>
            </span>
            
            
          </span>
        </span>
        </li>
        <li>
          <span class="flex-wrap">
          <span class="num">
            2
          </span>
          <span class="task">
            <span class="title">
              Why is it important to me?​
               <p>{{goal.why}}</p>
            </span>
            
            
          </span>
          </span>
        </li>
        <li>
          <span class="flex-wrap">
          <span class="num">
            3
          </span>
          <span class="task">
            <span class="title">
              How am I going to achieve this?​
               <p>{{goal.how}}</p>
            </span>
            
            
          </span>
          </span>
        </li>
        <li>

          <span class="flex-wrap">
          <span class="num">
            4
          </span>
          <span class="task">
            <span class="title">
              What might stop me?   
               <p>{{goal.barriers}}</p>
            </span>
            
            
          </span>
          </span>
        </li>
        <li>

          <span class="flex-wrap">
          <span class="num">
            5
          </span>
          <span class="task">
            <span class="title">
              When should I review this goal?
               <p>{{goal.start}}</p>
            </span>
            
            
          </span>
          </span>
        </li>

        
      </ul>


      <div class="text-center mt-5">
          <button class="btn btn-primary w-100" [routerLink]="['/goals', 'update', goalId]">
        Edit Goal
        <span class="icon-wrap">
          <fa-icon
            [icon]="['far', 'arrow-circle-right']"
            [fixedWidth]="true"
          ></fa-icon>
        </span>
      </button>
 </div>

 <div class="text-center mt-5">
        

      <button class="btn btn-white w-100" (click)="onDelete()">
        Delete Goal
        <span class="icon-wrap text-danger">
          <fa-icon
            [icon]="['fal', 'trash']"
            [fixedWidth]="true"
          ></fa-icon>
        </span>
      </button>
      </div>


     
      
    </div>
  </div>
</div>
