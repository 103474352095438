<div class="">
  <app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
  <div class="card text-center card-light mb-3 border-radius-bottom-only">
    <div class="card-body text-center">
      <h3 *ngIf="gettingStarted" class="mb-3 text-center">Create your Action Plan</h3>
      <p class="text-center">
        To create your personalised action plan tell us what is normal for you.
      </p>
      <p class="text-center">
        If you already have an action plan you can
        <a class="pointer" (click)="onSkip()">skip this step</a>
      </p>
    </div>
  </div>

  <form class="ii-form symptom-thermometer-form" (ngSubmit)="onSubmit()" #form="ngForm">
    <div class="form-group mb-3 mb-3">
      <fieldset>
        <legend>
          Breathlessness at rest
        </legend>

        <div class="radio-group">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.breathlessness_rest"
              class="form-check-input"
              type="radio"
              required
              name="breathlessness_rest"
              id="breathlessness_rest0"
              value="0"
            />
            <label class="form-check-label" for="breathlessness_rest0">0</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.breathlessness_rest"
              class="form-check-input"
              type="radio"
              required
              name="breathlessness_rest"
              id="breathlessness_rest1"
              value="1"
            />
            <label class="form-check-label" for="breathlessness_rest1">1</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.breathlessness_rest"
              class="form-check-input"
              type="radio"
              required
              name="breathlessness_rest"
              id="breathlessness_rest2"
              value="2"
            />
            <label class="form-check-label" for="breathlessness_rest2">2</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.breathlessness_rest"
              class="form-check-input"
              type="radio"
              required
              name="breathlessness_rest"
              id="breathlessness_rest3"
              value="3"
            />
            <label class="form-check-label" for="breathlessness_rest3">3</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.breathlessness_rest"
              class="form-check-input"
              type="radio"
              required
              name="breathlessness_rest"
              id="breathlessness_rest4"
              value="4"
            />
            <label class="form-check-label" for="breathlessness_rest4">4</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.breathlessness_rest"
              class="form-check-input"
              type="radio"
              required
              name="breathlessness_rest"
              id="breathlessness_rest5"
              value="5"
            />
            <label class="form-check-label" for="breathlessness_rest5">5</label>
          </div>
        </div>
      </fieldset>
      <div class="row form-instructions">
        <div class="col">
          <span class="arrow-left"></span>
          I am <strong><u>NOT</u><br /></strong>breathless
        </div>
        <div class="col text-end">
          <span class="arrow-right"></span>
          I am <strong><u>VERY</u><br /></strong>breathless
        </div>
      </div>
    </div>

    <div class="form-group mb-3">
      <fieldset>
        <legend>
          Breathlessness during everyday activities
        </legend>

        <div class="radio-group">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.breathlessness"
              class="form-check-input"
              type="radio"
              required
              name="breathlessness"
              id="breathlessness0"
              value="0"
            />
            <label class="form-check-label" for="breathlessness0">0</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.breathlessness"
              class="form-check-input"
              type="radio"
              required
              name="breathlessness"
              id="breathlessness1"
              value="1"
            />
            <label class="form-check-label" for="breathlessness1">1</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.breathlessness"
              class="form-check-input"
              type="radio"
              required
              name="breathlessness"
              id="breathlessness2"
              value="2"
            />
            <label class="form-check-label" for="breathlessness2">2</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.breathlessness"
              class="form-check-input"
              type="radio"
              required
              name="breathlessness"
              id="breathlessness3"
              value="3"
            />
            <label class="form-check-label" for="breathlessness3">3</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.breathlessness"
              class="form-check-input"
              type="radio"
              required
              name="breathlessness"
              id="breathlessness4"
              value="4"
            />
            <label class="form-check-label" for="breathlessness4">4</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.breathlessness"
              class="form-check-input"
              type="radio"
              required
              name="breathlessness"
              id="breathlessness5"
              value="5"
            />
            <label class="form-check-label" for="breathlessness5">5</label>
          </div>
        </div>
      </fieldset>
      <div class="row form-instructions">
        <div class="col">
          <span class="arrow-left"></span>
          When I walk up a hill or one flight of stairs I am NOT breathless
        </div>
        <div class="col text-end">
          <span class="arrow-right"></span>
          When I walk up a hill or one flight of stairs I am VERY breathless
        </div>
      </div>
    </div>

    <div class="form-group mb-3" *ngIf="diagnosisMode == 'respiratory'">
      <fieldset>
        <legend>
          Cough
        </legend>
        <div class="radio-group">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.cough"
              class="form-check-input"
              type="radio"
              required
              name="cough"
              id="cough0"
              value="0"
            />
            <label class="form-check-label" for="cough0">0</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.cough"
              class="form-check-input"
              type="radio"
              required
              name="cough"
              id="cough1"
              value="1"
            />
            <label class="form-check-label" for="cough1">1</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.cough"
              class="form-check-input"
              type="radio"
              required
              name="cough"
              id="cough2"
              value="2"
            />
            <label class="form-check-label" for="cough2">2</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.cough"
              class="form-check-input"
              type="radio"
              required
              name="cough"
              id="cough3"
              value="3"
            />
            <label class="form-check-label" for="cough3">3</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.cough"
              class="form-check-input"
              type="radio"
              required
              name="cough"
              id="cough4"
              value="4"
            />
            <label class="form-check-label" for="cough4">4</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.cough"
              class="form-check-input"
              type="radio"
              required
              name="cough"
              id="cough5"
              value="5"
            />
            <label class="form-check-label" for="cough5">5</label>
          </div>
        </div>
      </fieldset>
      <div class="row form-instructions">
        <div class="col">
          <span class="arrow-left"></span>
          I NEVER cough
        </div>
        <div class="col text-end">
          <span class="arrow-right"></span>
          I cough ALL the time
        </div>
      </div>
    </div>
    <div class="form-group mb-3" *ngIf="diagnosisMode == 'respiratory'">
      <fieldset>
        <legend>
          I normally have a productive cough
        </legend>
        <div class="radio-group radio-group-yn">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.cough_productive"
              class="form-check-input"
              type="radio"
              required
              name="cough_productive"
              id="cough_productive0"
              value="1"
            />
            <label class="form-check-label" for="cough_productive0">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.cough_productive"
              class="form-check-input"
              type="radio"
              required
              name="cough_productive"
              id="cough_productive1"
              value="0"
            />
            <label class="form-check-label" for="cough_productive1">No</label>
          </div>
        </div>
      </fieldset>
    </div>

    <div class="form-group mb-3" *ngIf="diagnosisMode == 'respiratory'">
      <fieldset>
        <legend>
          The colour of my sputum is
        </legend>

        <div class="col-12 mb-2">
          <div
            class="radio-group radio-group-sputum"
            [ngClass]="{ chosen: sputum_colour.dirty || sputum_colour.touched }"
          >
            <div class="form-check form-check-inline">
              <input
                [(ngModel)]="symptomData.sputum_colour"
                #sputum_colour="ngModel"
                class="form-check-input"
                type="radio"
                required
                name="sputum_colour"
                id="sputum_colour1"
                value="0"
              />
              <label class="form-check-label" for="sputum_colour1">
                <span class="icon-wrap text-white sputum-icon"
                  ><fa-icon [icon]="['far', 'tint']"></fa-icon
                ></span>
                <span class="visually-hidden">0</span></label
              >
            </div>

            <div class="form-check form-check-inline">
              <input
                [(ngModel)]="symptomData.sputum_colour"
                #sputum_colour="ngModel"
                class="form-check-input"
                type="radio"
                required
                name="sputum_colour"
                id="sputum_colour2"
                value="1"
              />
              <label class="form-check-label" for="sputum_colour2">
                <span class="icon-wrap text-white sputum-icon"
                  ><fa-icon [icon]="['far', 'tint']"></fa-icon
                ></span>
                <span class="visually-hidden">1</span></label
              >
            </div>

            <div class="form-check form-check-inline">
              <input
                [(ngModel)]="symptomData.sputum_colour"
                #sputum_colour="ngModel"
                class="form-check-input"
                type="radio"
                required
                name="sputum_colour"
                id="sputum_colour3"
                value="2"
              />
              <label class="form-check-label" for="sputum_colour3">
                <span class="icon-wrap text-white sputum-icon"
                  ><fa-icon [icon]="['far', 'tint']"></fa-icon
                ></span>
                <span class="visually-hidden">2</span></label
              >
            </div>

            <div class="form-check form-check-inline">
              <input
                [(ngModel)]="symptomData.sputum_colour"
                #sputum_colour="ngModel"
                class="form-check-input"
                type="radio"
                required
                name="sputum_colour"
                id="sputum_colour4"
                value="3"
              />
              <label class="form-check-label" for="sputum_colour4">
                <span class="icon-wrap text-white sputum-icon"
                  ><fa-icon [icon]="['far', 'tint']"></fa-icon
                ></span>
                <span class="visually-hidden">3</span></label
              >
            </div>
            <div class="form-check form-check-inline">
              <input
                [(ngModel)]="symptomData.sputum_colour"
                #sputum_colour="ngModel"
                class="form-check-input"
                type="radio"
                required
                name="sputum_colour"
                id="sputum_colour5"
                value="4"
              />
              <label class="form-check-label" for="sputum_colour5">
                <span class="icon-wrap text-white sputum-icon"
                  ><fa-icon [icon]="['far', 'tint']"></fa-icon
                ></span>
                <span class="visually-hidden">4</span></label
              >
            </div>
          </div>
        </div>
      </fieldset>
    </div>

    <div class="form-group mb-3" *ngIf="diagnosisMode=='respiratory'">
      <fieldset>
        <legend>
          How much sputum do I clear on a normal day?
        </legend>
        <div class="radio-group">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sputum_clear_total"
              class="form-check-input"
              type="radio"
              required
              name="sputum_clear_total"
              id="sputum_clear_total0"
              value="0"
            />
            <label class="form-check-label" for="sputum_clear_total0">0</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sputum_clear_total"
              class="form-check-input"
              type="radio"
              required
              name="sputum_clear_total"
              id="sputum_clear_total1"
              value="1"
            />
            <label class="form-check-label" for="sputum_clear_total1">1</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sputum_clear_total"
              class="form-check-input"
              type="radio"
              required
              name="sputum_clear_total"
              id="sputum_clear_total2"
              value="2"
            />
            <label class="form-check-label" for="sputum_clear_total2">2</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sputum_clear_total"
              class="form-check-input"
              type="radio"
              required
              name="sputum_clear_total"
              id="sputum_clear_total3"
              value="3"
            />
            <label class="form-check-label" for="sputum_clear_total3">3</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sputum_clear_total"
              class="form-check-input"
              type="radio"
              required
              name="sputum_clear_total"
              id="sputum_clear_total4"
              value="4"
            />
            <label class="form-check-label" for="sputum_clear_total4">4</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sputum_clear_total"
              class="form-check-input"
              type="radio"
              required
              name="sputum_clear_total"
              id="sputum_clear_total5"
              value="5"
            />
            <label class="form-check-label" for="sputum_clear_total5">5</label>
          </div>
        </div>
      </fieldset>
      <div class="row form-instructions">
        <div class="col">
          <span class="arrow-left"></span>
        </div>
        <div class="col text-end">
          <span class="arrow-right"></span>
        </div>
      </div>
      <div class="text-center">
        Hint: Spoonfuls are a helpful measure
      </div>
    </div>


    <div class="form-group mb-3" *ngIf="diagnosisMode=='respiratory'">
      <fieldset>
        <legend>
          How thick is my sputum?
        </legend>
        <div class="radio-group">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sputum_thickness"
              class="form-check-input"
              type="radio"
              required
              name="sputum_thickness"
              id="sputum_thickness0"
              value="0"
            />
            <label class="form-check-label" for="sputum_thickness0">0</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sputum_thickness"
              class="form-check-input"
              type="radio"
              required
              name="sputum_thickness"
              id="sputum_thickness1"
              value="1"
            />
            <label class="form-check-label" for="sputum_thickness1">1</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sputum_thickness"
              class="form-check-input"
              type="radio"
              required
              name="sputum_thickness"
              id="sputum_thickness2"
              value="2"
            />
            <label class="form-check-label" for="sputum_thickness2">2</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sputum_thickness"
              class="form-check-input"
              type="radio"
              required
              name="sputum_thickness"
              id="sputum_thickness3"
              value="3"
            />
            <label class="form-check-label" for="sputum_thickness3">3</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sputum_thickness"
              class="form-check-input"
              type="radio"
              required
              name="sputum_thickness"
              id="sputum_thickness4"
              value="4"
            />
            <label class="form-check-label" for="sputum_thickness4">4</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sputum_thickness"
              class="form-check-input"
              type="radio"
              required
              name="sputum_thickness"
              id="sputum_thickness5"
              value="5"
            />
            <label class="form-check-label" for="sputum_thickness5">5</label>
          </div>
        </div>
      </fieldset>
      <div class="row form-instructions">
        <div class="col">
          <span class="arrow-left"></span>
          Not thick at all
        </div>
        <div class="col text-end">
          <span class="arrow-right"></span>
          Very thick
        </div>
      </div>
    </div>


    <div class="form-group mb-3" *ngIf="diagnosisMode=='respiratory'">
      <fieldset>
        <legend>
          How easy is it to clear my sputum?
        </legend>
        <div class="radio-group">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sputum_clear_difficulty"
              class="form-check-input"
              type="radio"
              required
              name="sputum_clear_difficulty"
              id="sputum_clear_difficulty0"
              value="0"
            />
            <label class="form-check-label" for="sputum_clear_difficulty0">0</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sputum_clear_difficulty"
              class="form-check-input"
              type="radio"
              required
              name="sputum_clear_difficulty"
              id="sputum_clear_difficulty1"
              value="1"
            />
            <label class="form-check-label" for="sputum_clear_difficulty1">1</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sputum_clear_difficulty"
              class="form-check-input"
              type="radio"
              required
              name="sputum_clear_difficulty"
              id="sputum_clear_difficulty2"
              value="2"
            />
            <label class="form-check-label" for="sputum_clear_difficulty2">2</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sputum_clear_difficulty"
              class="form-check-input"
              type="radio"
              required
              name="sputum_clear_difficulty"
              id="sputum_clear_difficulty3"
              value="3"
            />
            <label class="form-check-label" for="sputum_clear_difficulty3">3</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sputum_clear_difficulty"
              class="form-check-input"
              type="radio"
              required
              name="sputum_clear_difficulty"
              id="sputum_clear_difficulty4"
              value="4"
            />
            <label class="form-check-label" for="sputum_clear_difficulty4">4</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sputum_clear_difficulty"
              class="form-check-input"
              type="radio"
              required
              name="sputum_clear_difficulty"
              id="sputum_clear_difficulty5"
              value="5"
            />
            <label class="form-check-label" for="sputum_clear_difficulty5">5</label>
          </div>
        </div>
      </fieldset>
      <div class="row form-instructions">
        <div class="col">
          <span class="arrow-left"></span>
          Very easy
        </div>
        <div class="col text-end">
          <span class="arrow-right"></span>
          Not easy at all
        </div>
      </div>
    </div>

    <div class="form-group mb-3" *ngIf="diagnosisMode == 'respiratory'">
      <fieldset>
        <legend>
          I normally have a wheeze
        </legend>
        <div class="radio-group radio-group-yn">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.wheeze"
              class="form-check-input"
              type="radio"
              required
              name="wheeze"
              id="wheeze0"
              value="1"
            />
            <label class="form-check-label" for="wheeze0">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.wheeze"
              class="form-check-input"
              type="radio"
              required
              name="wheeze"
              id="wheeze1"
              value="0"
            />
            <label class="form-check-label" for="wheeze1">No</label>
          </div>
        </div>
      </fieldset>
    </div>

    <div class="form-group mb-3" *ngIf="diagnosisMode == 'respiratory'">
      <fieldset>
        <legend>
          I normally have a runny nose
        </legend>
        <div class="radio-group radio-group-yn">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.nasal_discharge"
              class="form-check-input"
              type="radio"
              required
              name="nasal_discharge"
              id="nasal_discharge0"
              value="1"
            />
            <label class="form-check-label" for="nasal_discharge0">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.nasal_discharge"
              class="form-check-input"
              type="radio"
              required
              name="nasal_discharge"
              id="nasal_discharge1"
              value="0"
            />
            <label class="form-check-label" for="nasal_discharge1">No</label>
          </div>
        </div>
      </fieldset>
    </div>

    <div class="form-group mb-3" *ngIf="diagnosisMode == 'respiratory'">
      <fieldset>
        <legend>
          I normally have a sore throat
        </legend>
        <div class="radio-group radio-group-yn">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sore_throat"
              class="form-check-input"
              type="radio"
              required
              name="sore_throat"
              id="sore_throat0"
              value="1"
            />
            <label class="form-check-label" for="sore_throat0">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sore_throat"
              class="form-check-input"
              type="radio"
              required
              name="sore_throat"
              id="sore_throat1"
              value="0"
            />
            <label class="form-check-label" for="sore_throat1">No</label>
          </div>
        </div>
      </fieldset>
    </div>

   

    <div class="form-group mb-3">
      <fieldset>
        <legend>
          I normally have swollen ankles
        </legend>
        <div class="radio-group radio-group-yn">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.swollen_ankles"
              class="form-check-input"
              type="radio"
              required
              name="swollen_ankles"
              id="swollen_ankles0"
              value="1"
            />
            <label class="form-check-label" for="swollen_ankles0">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.swollen_ankles"
              class="form-check-input"
              type="radio"
              required
              name="swollen_ankles"
              id="swollen_ankles1"
              value="0"
            />
            <label class="form-check-label" for="swollen_ankles1">No</label>
          </div>
        </div>
      </fieldset>
    </div>

    <div class="form-group mb-3" *ngIf="diagnosisMode == 'cardiac'">
      <fieldset>
        <legend>
          My weight has changed
        </legend>
        <div class="radio-group radio-group-yn">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.weight_change"
              class="form-check-input"
              type="radio"
              required
              name="weight_change"
              id="weight_change0"
              value="1"
            />
            <label class="form-check-label" for="weight_change0">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.weight_change"
              class="form-check-input"
              type="radio"
              required
              name="weight_change"
              id="weight_change1"
              value="0"
            />
            <label class="form-check-label" for="weight_change1">No</label>
          </div>
        </div>
      </fieldset>
    </div>

    <div class="form-group mb-3" *ngIf="diagnosisMode == 'cardiac'">
      <fieldset>
        <legend>
          I have chest pain / spasm
        </legend>
        <div class="radio-group">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.chest_pain"
              class="form-check-input"
              type="radio"
              required
              name="chest_pain"
              id="chest_pain0"
              value="0"
            />
            <label class="form-check-label" for="chest_pain0">0</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.chest_pain"
              class="form-check-input"
              type="radio"
              required
              name="chest_pain"
              id="chest_pain1"
              value="1"
            />
            <label class="form-check-label" for="chest_pain1">1</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.chest_pain"
              class="form-check-input"
              type="radio"
              required
              name="chest_pain"
              id="chest_pain2"
              value="2"
            />
            <label class="form-check-label" for="chest_pain2">2</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.chest_pain"
              class="form-check-input"
              type="radio"
              required
              name="chest_pain"
              id="chest_pain3"
              value="3"
            />
            <label class="form-check-label" for="chest_pain3">3</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.chest_pain"
              class="form-check-input"
              type="radio"
              required
              name="chest_pain"
              id="chest_pain4"
              value="4"
            />
            <label class="form-check-label" for="chest_pain4">4</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.chest_pain"
              class="form-check-input"
              type="radio"
              required
              name="chest_pain"
              id="chest_pain5"
              value="5"
            />
            <label class="form-check-label" for="chest_pain5">5</label>
          </div>
        </div>
      </fieldset>
      <div class="row form-instructions">
        <div class="col">
          <span class="arrow-left"></span>
          No chest pain at all
        </div>
        <div class="col text-end">
          <span class="arrow-right"></span>
          LOTS of chest pain
        </div>
      </div>
    </div>

    <div class="form-group mb-3" *ngIf="diagnosisMode == 'cardiac'">
      <fieldset>
        <legend>
          I have palpitations
        </legend>
        <div class="radio-group radio-group-yn">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.palpitations"
              class="form-check-input"
              type="radio"
              required
              name="palpitations"
              id="palpitations0"
              value="1"
            />
            <label class="form-check-label" for="palpitations0">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.palpitations"
              class="form-check-input"
              type="radio"
              required
              name="palpitations"
              id="palpitations1"
              value="0"
            />
            <label class="form-check-label" for="palpitations1">No</label>
          </div>
        </div>
      </fieldset>
    </div>

    <div class="form-group mb-3" *ngIf="diagnosisMode == 'cardiac'">
      <fieldset>
        <legend>
          I have dizziness
        </legend>
        <div class="radio-group radio-group-yn">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.dizziness"
              class="form-check-input"
              type="radio"
              required
              name="dizziness"
              id="dizziness0"
              value="1"
            />
            <label class="form-check-label" for="dizziness0">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.dizziness"
              class="form-check-input"
              type="radio"
              required
              name="dizziness"
              id="dizziness1"
              value="0"
            />
            <label class="form-check-label" for="dizziness1">No</label>
          </div>
        </div>
      </fieldset>
    </div>

    <div class="form-group mb-3" *ngIf="diagnosisMode == 'cardiac'">
      <fieldset>
        <legend>
          I have nausea
        </legend>
        <div class="radio-group radio-group-yn">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.nausea"
              class="form-check-input"
              type="radio"
              required
              name="nausea"
              id="nausea0"
              value="1"
            />
            <label class="form-check-label" for="nausea0">Yes</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.nausea"
              class="form-check-input"
              type="radio"
              required
              name="nausea"
              id="nausea1"
              value="0"
            />
            <label class="form-check-label" for="nausea1">No</label>
          </div>
        </div>
      </fieldset>
    </div>

    <div class="mt-3 mb-3 text-center">
      <button class="btn btn-primary btn-block w-100" [disabled]="!form.valid">
        Create Action Plan
      </button>
    </div>
  </form>
</div>
<div *ngIf="gettingStarted">
  <div class="signin-details">
    <div class="signin-details-flex">
      <div class="signin-left">
        © {{ currentYear }} University Hospitals of Leicester
      </div>
      <div class="signin-right">
        <a [routerLink]="['/getting-started/terms']"
        >Terms &amp; Conditions</a
      >&nbsp;|&nbsp; 
      <a [routerLink]="['/getting-started/privacy']">Privacy Policy</a>
      </div>
    </div>
  </div>
  <div class="">
    <div class="col footer-logos">
      <div class="programme-logo-strap">Incorporating the programmes</div>
      <div class="programme-logos">
        <img
          src="./assets/img/logo-ayh-colour.svg"
          alt="Activate Your Heart Logo"
          width="93"
        />
        <img
          src="./assets/img/logo-space-colour.svg"
          alt="SPACE for COPD"
          width="69"
        />
        <img
          src="./assets/img/logo-ycr-colour.svg"
          alt="Your COVID Recovery"
          width="93"
        />
      </div>
    </div>
  </div>
</div> 
