import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { AuthResponseData } from '../auth/service/auth.service';
import { ContactService } from '../services/contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  isLoading = false;
  constructor(private contactService:ContactService) {}

  ngOnInit(): void {}
  onSubmit(form: NgForm) {
    if (!form.valid) {
      return;
    }
    const email = form.value.email;
    const name = form.value.name;
    const message = form.value.message;
    

    this.isLoading = true;

    this.contactService.send(name, email, message).subscribe((responseData) => {
    form.reset();
    this.isLoading = false;
    Swal.fire({
      icon: 'success',
      title: 'Message sent',
      text: 'Thank you for your message',
      showConfirmButton: true
    });
    },
    (error)=>{
      Swal.fire({
        icon: 'error',
        title: 'Unable to send to message',
        text: 'There was an error sending the message',
        showConfirmButton: true
      });
      this.isLoading = false;
    });
    
  }
}
