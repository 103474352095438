import { ComponentCanDeactivate } from '../../../guards/pending-changes.guard';
import { StrengthWorkoutService } from "../../../services/strength-workout.service";
import { ActivatedRoute, Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
import Swal from "sweetalert2/src/sweetalert2.js";
import { AchievementService } from "src/app/services/achievement.service";
import * as moment from "moment";
import { Observable } from "rxjs";
import { UserService } from 'src/app/auth/service/user.service';

@Component({
  selector: "app-add-strength-workout",
  templateUrl: "./add-strength-workout.component.html",
  styleUrls: ["./add-strength-workout.component.scss"],
})
export class AddStrengthWorkoutComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild("form", { static: false }) entryForm?: NgForm;
  submitted = false;
  error?: string;
  maxDate = new Date();
  bsDate = new Date();
  exercisesPicked: any = [];
  exercisesSelected = false;
  submittedStep = [false, false, false, false];

  // biceps curls, Pull ups/pull ups, sit to stand and step ups
  exercises = [{id:'bicepCurl', label:'Bicep Curls'},{id:'pullUps', label:'Pull ups'},{id:'sitToStand', label:'Sit to Stand'},{id:'stepUps', label:'Step ups'}];
  step = 0;
  exerciseData  : { [key: string]: any }  = {
    bicepCurl : {weight:null, set1:'', set2:'', set3:'', difficulty:''},
    pullUps : {weight:null, set1:'', set2:'', set3:'', difficulty:''},
    sitToStand : {weight:null, set1:'', set2:'', set3:'', difficulty:''},
    stepUps : {weight:null, set1:'', set2:'', set3:'', difficulty:''},
  };
  userData:any;

  constructor(
    private strengthWorkoutService: StrengthWorkoutService,
    private router: Router,
    private achievementService: AchievementService,
    private route: ActivatedRoute,
    private el: ElementRef,
    private userService:UserService
  ) {}

  ngOnInit(): void {
    // get the date if set
    this.route.queryParams.subscribe((params: any) => {
      if (params.d) {
        this.bsDate = moment(params.d).toDate();
      }
    });
    if (!this.userService.userData.getValue()) {
      this.userService.userData.subscribe((userData) => {
        if (userData) {
          this.userData = userData;
        }
      });
    } else {
      this.userData = this.userService.userData.getValue();
    }
  }

  onSubmit() {
    this.submitted = true;
    if (!this.entryForm?.valid) {
      Swal.fire({
        icon: "error",
        title: "The form is invalid",
        text: "Please check the form for errors",
        showConfirmButton: true,
        didClose: () => this.scrollToFirstInvalidControl(),
      });
    } else {
      // create entry
      const resourceIds = [];
      this.strengthWorkoutService
        .create(
          this.entryForm?.value.workout_date,
          this.exerciseData
        )
        .subscribe(
          (responseData) => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Entry created",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              toast: true,
            });
            this.router.navigate(["/exercise", "strength"]);
            this.entryForm?.reset();
            this.achievementService.refreshNotifications();
          },
          (error) => {
            this.error = error.message;
          }
        );
    }
  }

  onNext(){
    if(!this.exerciseData[this.exercisesPicked[this.step].id].weight || !this.exerciseData[this.exercisesPicked[this.step].id].set1 || !this.exerciseData[this.exercisesPicked[this.step].id].set2 || !this.exerciseData[this.exercisesPicked[this.step].id].set3 || !this.exerciseData[this.exercisesPicked[this.step].id].difficulty){
      Swal.fire({
        icon: "error",
        title: "The form is invalid",
        text: "Please check the form for errors",
        showConfirmButton: true,
        didClose: () => this.scrollToFirstInvalidControl(),
      });

    this.submittedStep[this.step] = true;
    }
    else{
    this.step++;
    }
  }
  onPrev(){
    this.step--;
  }


  onPickExercise(exercise:any) {
    if (this.exercisesPicked.indexOf(exercise) !== -1) {
      this.exercisesPicked.splice(this.exercisesPicked.indexOf(exercise), 1);
    } else {
      this.exercisesPicked.push(exercise);
    }
  }
  onPickAll(){
    this.exercisesPicked = [];
    this.exercises.forEach((exercise) => {
      this.exercisesPicked.push(exercise);
    });
  }
  onConfirmExercises(){
    this.exercisesSelected = true;
    this.exerciseData.bicepCurl.weight = +this.userData.strength_upper;
    this.exerciseData.pullUps.weight = +this.userData.strength_upper;
    this.exerciseData.sitToStand.weight = +this.userData.strength_lower;
    this.exerciseData.stepUps.weight = +this.userData.strength_lower;
 
  }


  public scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement =
      this.el.nativeElement.querySelector("form .ng-invalid");

    if (firstInvalidControl) {
      firstInvalidControl.focus();
      window.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
        behavior: "smooth",
      });
    }
  }

  public getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 150;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }


  @HostListener('window:beforeunload')
  canDeactivate(): Observable<any> | any {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    if(this.submitted){
      return true;
    }
    else if(this.exercisesPicked.length == 0){
      return true;
    }
  }
}
