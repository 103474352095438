<div class="main-content-area" *ngIf="actionPlan">
  <ng-container *ngIf="diagnosisMode == 'respiratory'">
    <h3 class="mt-3 mb-3 text-center">My usual symptoms are:</h3>
    <div class="container bg-light pt-3">
      <div class="row">
        <div class="col-md-3 mb-3">
          <div class="status-badge">
            <div class="status-badge-text">
              <div class="status-badge-title">
                Breathlessness<br />
                at rest
              </div>
              <div class="status-badge-status">
                {{ actionPlan.breathlessness_rest }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="status-badge">
            <div class="status-badge-text">
              <div class="status-badge-title">
                Breathlessness during<br />
                everyday activities
              </div>
              <div class="status-badge-status">
                {{ actionPlan.breathlessness }}
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3 mb-3">
          <div class="status-badge">
            <div class="status-badge-text">
              <div class="status-badge-title">
                <span *ngIf="actionPlan.cough == 1">
                  I normally have <br />
                  a cough</span
                >
                <span *ngIf="actionPlan.cough != 1">
                  I don't normally have <br />
                  a cough</span
                >
              </div>
              <div class="status-badge-status">&nbsp;</div>
            </div>
          </div>
        </div>

        <div class="col-md-3 mb-3">
          <div class="status-badge">
            <div class="status-badge-text">
              <div class="status-badge-title">
                <span *ngIf="actionPlan.cough_productive == 1"
                  >My cough <br />
                  is productive
                </span>
                <span *ngIf="actionPlan.cough_productive != 1">
                  My cough <br />
                  is not productive</span
                >
              </div>
              <div class="status-badge-status">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-3 mb-3">
          <div class="status-badge">
            <div class="status-badge-text">
              <div class="status-badge-title">
                The colour of my phlegm<br />
                is normally
              </div>
              <div class="status-badge-status">
                <div
                  class="bg-sputum-{{ actionPlan.sputum_colour }}"
                  style="
                    width: 22px;
                    height: 22px;
                    display: inline-block;
                    border: 1px solid #999;
                  "
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="status-badge">
            <div class="status-badge-text">
              <div class="status-badge-title">
                <span *ngIf="actionPlan.wheeze == 1">
                  I normally have<br />
                  a wheeze
                </span>
                <span *ngIf="actionPlan.wheeze != 1">
                  I don't normally<br />
                  have a wheeze</span
                >
              </div>
              <div class="status-badge-status">&nbsp;</div>
            </div>
          </div>
        </div>

        <div class="col-md-3 mb-3">
          <div class="status-badge">
            <div class="status-badge-text">
              <div class="status-badge-title">
                <span *ngIf="actionPlan.nasal_discharge == 1">
                  I normally have <br />a runny nose
                </span>
                <span *ngIf="actionPlan.nasal_discharge != 1">
                  I don't normally have <br />a runny nose</span
                >
              </div>
              <div class="status-badge-status">&nbsp;</div>
            </div>
          </div>
        </div>

        <div class="col-md-3 mb-3">
          <div class="status-badge">
            <div class="status-badge-text">
              <div class="status-badge-title">
                <span *ngIf="actionPlan.swollen_ankles == 1">
                  I normally have<br />
                  swollen ankles</span
                >
                <span *ngIf="actionPlan.swollen_ankles != 1">
                  I don't normally have<br />
                  swollen ankles</span
                >
              </div>
              <div class="status-badge-status">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h3 class="mt-3 mb-3 text-center">Am I having an exacerbation?</h3>
    <p class="text-center">
      In the last 2 days have you noticed any of the following symptoms?
    </p>
    <div class="container bg-light pt-3">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Important Symptoms:</h5>
              <ul>
                <li>Increased breathlessness at rest or during activities</li>
                <li>Increased amount of sputum (phlegm)</li>
                <li>A change in colour of your phlegm</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Minor Symptoms:</h5>
              <ul>
                <li>Increased wheeze</li>
                <li>Runny nose</li>
                <li>Sore throat</li>
                <li>Fever</li>
                <li>Swollen ankles</li>
                <li>Cough</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card mt-2">
            <div class="card-body">
              <h5 class="card-title">
                You may be having a flare up (exacerbation) if you have:
              </h5>
              <ul>
                <li>
                  <strong>Two</strong> or <strong>more</strong> of the
                  <strong>important</strong> symptoms; or
                </li>
                <li>
                  <strong>One important</strong> symptom together with any
                  <strong>one or more</strong> of the
                  <strong>minor</strong> symptoms.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container bg0light pt-3">
      <div class="row">
        <div class="col">
          <div class="alert alert-success">
            <h4 class="alert-heading">Manage</h4>
            If your symptoms do not suggest a flare up (exacerbation): Please
            continue to monitor your symptoms. If your symptoms change or worsen
            then take a look at the important and minor symptoms checklist again
            and take action if needed.
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="alert alert-warning">
            <h4 class="alert-heading">Be Aware</h4>
            If your symptoms suggest that you are having an exacerbation, you
            can help yourself by starting your home standby medication (also
            sometimes known as a rescue pack) as soon as possible. If you do not
            have home standby medication please see your GP as soon as possible.
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="alert alert-danger">
            <h4 class="alert-heading">Seek Help</h4>
            If your symptoms GET WORSE or do not improve within 48 to 72 hours,
            seek medical attention and see your GP or specialist community nurse
            as a matter of urgency. If you feel severely unwell dial 999 for an
            ambulance.
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="diagnosisMode == 'cardiac'">
    <h3 class="mt-3 mb-3 text-center">My usual symptoms are:</h3>
    <div class="container bg-light pt-3">
      <div class="row">
        <div class="col-md-3 mb-3">
          <div class="status-badge">
            <div class="status-badge-text">
              <div class="status-badge-title">
                Breathlessness<br />
                at rest
              </div>
              <div class="status-badge-status">
                {{ actionPlan.breathlessness_rest }}
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 mb-3">
          <div class="status-badge">
            <div class="status-badge-text">
              <div class="status-badge-title">
                Breathlessness during<br />
                everyday activities
              </div>
              <div class="status-badge-status">
                {{ actionPlan.breathlessness }}
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3 mb-3">
          <div class="status-badge">
            <div class="status-badge-text">
              <div class="status-badge-title">
                <span *ngIf="actionPlan.swollen_ankles == 1">
                  I normally have<br />
                  swollen ankles</span
                >
                <span *ngIf="actionPlan.swollen_ankles != 1">
                  I don't normally have<br />
                  swollen ankles</span
                >
              </div>
              <div class="status-badge-status">&nbsp;</div>
            </div>
          </div>
        </div>

        <div class="col-md-3 mb-3">
          <div class="status-badge">
            <div class="status-badge-text">
              <div class="status-badge-title">
                <span *ngIf="actionPlan.weight_change == 1">
                  My weight<br />
                  is stable</span
                >
                <span *ngIf="actionPlan.weight_change != 1">
                  My weight<br />
                  is not stable</span
                >
              </div>
              <div class="status-badge-status">&nbsp;</div>
            </div>
          </div>
        </div>

        <div class="col-md-3 mb-3">
          <div class="status-badge">
            <div class="status-badge-text">
              <div class="status-badge-title">
                <span *ngIf="actionPlan.palpitation == 1">
                  I have<br />
                  palpitation</span
                >
                <span *ngIf="actionPlan.palpitation != 1">
                  I do not have<br />
                  palpitation</span
                >
              </div>
              <div class="status-badge-status">&nbsp;</div>
            </div>
          </div>
        </div>

        <div class="col-md-3 mb-3">
          <div class="status-badge">
            <div class="status-badge-text">
              <div class="status-badge-title">
                <span *ngIf="actionPlan.dizziness == 1">
                  I have<br />
                  dizziness</span
                >
                <span *ngIf="actionPlan.dizziness != 1">
                  I do not have<br />
                  dizziness</span
                >
              </div>
              <div class="status-badge-status">&nbsp;</div>
            </div>
          </div>
        </div>

        <div class="col-md-3 mb-3">
          <div class="status-badge">
            <div class="status-badge-text">
              <div class="status-badge-title">
                <span *ngIf="actionPlan.nausea == 1">
                  I am<br />
                  nauseous</span
                >
                <span *ngIf="actionPlan.nausea != 1">
                  I am not<br />
                  nauseous</span
                >
              </div>
              <div class="status-badge-status">&nbsp;</div>
            </div>
          </div>
        </div>

        <div class="col-md-3 mb-3">
          <div class="status-badge">
            <div class="status-badge-text">
              <div class="status-badge-title">
                <div class="status-badge-title">Chest Pain</div>
                <div class="status-badge-status">
                  {{ actionPlan.chest_pain }}
                </div>
              </div>
              <div class="status-badge-status">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h3 class="mt-3 mb-3 text-center">Am I having a change in symptoms?</h3>
    <p class="text-center">
      In the last 3 days have you noticed a change in any of the following?
    </p>
    <div class="container bg-light pt-3">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <h5 class="card-title">Symptoms:</h5>
                  <ul>
                    <li>
                      Increased breathlessness at rest or during usual
                      activities
                    </li>
                    <li>Increased swelling of ankles/ legs/abdomen</li>
                    <li>
                      Breathless at night or need more pillows to sleep on
                    </li>
                    <li>Chest pain</li>
                    <li>Increase in weight (2-3lbs over 2-3 days)</li>
                    <li>Increased fatigue/overall tiredness</li>
                    <li>Increased dizziness</li>
                  </ul>
                </div>
                <div class="col-md-6">
                  <h5 class="card-title">Consider:</h5>
                  <ul>
                    <li>Nausea</li>
                    <li>Other conditions worsening</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container bg-light pt-3">
      <div class="row">
        <div class="col">
          <h4 class="mt-3 mb-3 text-center">If you feel you have two or more symptoms and they have not improved,
          please see below:</h4>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="alert alert-success">
            <h4 class="alert-heading">Manage</h4>
            If your symptoms are stable: please continue to monitor. If your
            symptoms change or worsen then take a look at the symptoms checklist
            again and take action if needed.
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="alert alert-warning">
            <h4 class="alert-heading">Be Aware</h4>
            Your symptoms suggest a change that may mean you are feeling worse,
            please monitor and if they continue for 2- 3 days with no
            improvement seek help
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="alert alert-danger">
            <h4 class="alert-heading">Seek Help</h4>
            If your symptoms GET WORSE or do not improve within 48 to 72 hours,
            seek medical attention and see your GP or specialist community nurse
            urgently. If you feel severely unwell dial 999 for an ambulance.
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div class="text-center p-4">
  <button class="btn btn-primary" [routerLink]="['/dashboard']">
    Go to Dashboard
  </button>
</div>
