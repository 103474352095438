import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { GoalsService } from 'src/app/goals/goals.service';
import { UserAchievement } from 'src/app/models/user-achievement.model';
import { AchievementService } from 'src/app/services/achievement.service';
import { CardioWorkoutService } from 'src/app/services/cardio-workout.service';
import { StrengthWorkoutService } from 'src/app/services/strength-workout.service';

@Component({
  selector: 'app-achievements-index',
  templateUrl: './achievements-index.component.html',
  styleUrls: ['./achievements-index.component.scss']
})
export class AchievementsIndexComponent implements OnInit {
  area = "global";
  isLoading = false;
  weeklyParticipationBadges: UserAchievement[] = [];
  nextParticipationStreak = 3;

  // programme
  programmeBadges: UserAchievement[] = [];


  // cardio
  cardioWorkoutMilestones: UserAchievement[] = [];
  nextCardioWorkoutMilestone = "";
  cardioGoals: UserAchievement[] = [];
  nextCardioGoalAward = "";
  cardioStreaks: UserAchievement[] = [];
  nextCardioStreak = "";


  // strength
  strengthWorkoutMilestones: UserAchievement[] = [];
  nextStrengthWorkoutMilestone = "";
  strengthGoals: UserAchievement[] = [];
  nextStrengthGoalAward = "";
  strengthStreaks: UserAchievement[] = [];
  nextStrengthStreak = "";


  allAchievements: UserAchievement[] = [];
  uniqueAchievements: UserAchievement[] = [];
  current = {
    strengthWorkouts: 0,
    strengthStreak: 0,
    cardioWorkouts: 0,
    cardioStreak: 0,
  };

  milestones = [1,5,10,25,50,75,100,150,200];
  goalMilestones = [1,5,10,25,50];
  streaks = [3,5,7,14, 30, 50, 75, 100, 150, 200];
  weeks  = [1,2,3,4,5,6,7,8,9,10,11,12];


  constructor(
    public achievementService: AchievementService,
    private route: ActivatedRoute,
    private strengthWorkoutService: StrengthWorkoutService,
    private cardioWorkoutService: CardioWorkoutService,
    private goalsService: GoalsService
  ) {
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.achievementService.fetchAll().subscribe((responseData) => {
      this.isLoading = false;

      this.allAchievements = responseData;
      this.uniqueAchievements = this.achievementService.getUnique(responseData);

      this.uniqueAchievements.forEach((achievement) => {
        
        if (
          achievement.area === "programme"
        ) {
          this.programmeBadges.push(achievement);
        }

        if (
          achievement.area === "cardio" &&
          achievement.type === "badge-workouts"
        ) {
          this.cardioWorkoutMilestones.push(achievement);
        }
        if (
          achievement.area === "cardio" &&
          achievement.type === "badge-goals"
        ) {
          this.cardioGoals.push(achievement);
        }
        if (
          achievement.area === "cardio" &&
          achievement.type === "badge-workout-streak"
        ) {
          this.cardioStreaks.push(achievement);
        }


        if (
          achievement.area === "strength" &&
          achievement.type === "badge-workouts"
        ) {
          this.strengthWorkoutMilestones.push(achievement);
        }
        if (
          achievement.area === "strength" &&
          achievement.type === "badge-goals"
        ) {
          this.strengthGoals.push(achievement);
        }
        if (
          achievement.area === "strength" &&
          achievement.type === "badge-workout-streak"
        ) {
          this.strengthStreaks.push(achievement);
        }

      
      });



      this.nextCardioWorkoutMilestone =
        this.achievementService.getNextMilestone(
          "cardio",
          "badge-workouts",
          this.allAchievements
        );
     
      this.nextCardioGoalAward = this.achievementService.getNextMilestone(
        "cardio",
        "badge-goals",
        this.allAchievements
      );
     
      this.nextCardioStreak = this.achievementService.getNextMilestone(
        "cardio",
        "badge-workout-streak",
        this.allAchievements
      );

      this.nextStrengthWorkoutMilestone =
        this.achievementService.getNextMilestone(
          "strength",
          "badge-workouts",
          this.allAchievements
        );
     
      this.nextStrengthGoalAward = this.achievementService.getNextMilestone(
        "strength",
        "badge-goals",
        this.allAchievements
      );
     
      this.nextStrengthStreak = this.achievementService.getNextMilestone(
        "strength",
        "badge-workout-streak",
        this.allAchievements
      );
     

      // get currents
      forkJoin({
        allGoals: this.goalsService.fetchGoals(),
        allStrengthWorkouts: this.strengthWorkoutService.fetchAll(),
        allCardioWorkouts: this.cardioWorkoutService.fetchAll()
      }).subscribe(
        (responseData) => {

          this.current.cardioWorkouts =
            responseData.allCardioWorkouts.length;
        

         
          this.current.cardioStreak =
            this.cardioWorkoutService.getCurrentStreak(
              responseData.allCardioWorkouts
            );


          this.current.strengthWorkouts =
            responseData.allStrengthWorkouts.length;
        

         
          this.current.strengthStreak =
            this.strengthWorkoutService.getCurrentStreak(
              responseData.allStrengthWorkouts
            );
         
        },
        (error) => {}
      );
    });
  }
  achievementCount(area: string, type: string, total: number) {
    let count = 0;
    this.allAchievements.forEach((achievement) => {
      if (
        achievement.type == type &&
        achievement.area == area &&
        achievement.total == total
      ) {
        count++;
      }
    });
    return count;
  }

  getPopoverContent(achievement: UserAchievement) {
    // Awarded for working out {{achievement.total}} days in a row. Awarded {{achievementCount(achievement.area, achievement.type, achievement.total)}} times.

    let html = "";
    if (achievement.type == "week-streak") {
      html =
        html +
        "Awarded for logging in  " +
        achievement.total.toString() +
        " weeks in a row.";
    } 
    else if (achievement.area == "programme") {
        html  =  html + this.achievementService.getProgrammeAchievementTitle(achievement);
    } 
    else {
      html =
        html +
        "Awarded for working out " +
        achievement.total.toString() +
        " days in a row.";
    }
    const totalawarded = this.achievementCount(
      achievement.area,
      achievement.type,
      achievement.total
    );
    html = html + " Awarded " + totalawarded.toString() + " time";
    if (totalawarded == 1) {
      html = html + ".";
    } else {
      html = html + "s.";
    }


    return html;
  }

  hasAward(area:string, type:string, milestone:any){
    let hasAward = false;
    this.allAchievements.forEach((achievement:any)=>{
      if(achievement.area == area && achievement.type == type &&  ((milestone && +achievement.total == +milestone) || !milestone)){
        hasAward = true;
      } 
    });

    return hasAward;
  }

}

