import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentCanDeactivate } from 'src/app/guards/pending-changes.guard';
import { CardioWorkoutService } from 'src/app/services/cardio-workout.service';

import Swal from 'sweetalert2/src/sweetalert2.js';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { AchievementService } from 'src/app/services/achievement.service';

@Component({
  selector: 'app-add-cardio-workout',
  templateUrl: './add-cardio-workout.component.html',
  styleUrls: ['./add-cardio-workout.component.scss'],
})
export class AddCardioWorkoutComponent
  implements OnInit, ComponentCanDeactivate
{
  @ViewChild('form', { static: false }) entryForm?: NgForm;
  submitted = false;
  error?: string;
  maxDate = new Date();
  bsDate = new Date();
  exercises: any;
  otherSelected = false;

  constructor(
    private cardioWorkoutService: CardioWorkoutService,
    private router: Router,
    private achievementService: AchievementService,
    private route: ActivatedRoute,
    private el: ElementRef
  ) {}

  ngOnInit(): void {
    // get the date if set
    this.route.queryParams.subscribe((params: any) => {
      if (params.d) {
        this.bsDate = moment(params.d).toDate();
      }
    });
    this.cardioWorkoutService.fetchExercises().subscribe((exercises) => {
      this.exercises = exercises;
    });
  }

  onSubmit() {
    this.submitted = true;
    if (!this.entryForm?.valid) {
      Swal.fire({
        icon: 'error',
        title: 'The form is invalid',
        text: 'Please check the form for errors',
        showConfirmButton: true,
        didClose: () => this.scrollToFirstInvalidControl(),
      });
    } else {
      // create entry
      const resourceIds = [];
      this.cardioWorkoutService
        .create(
          this.entryForm?.value.date,
          +this.entryForm?.value.exercise,
          this.entryForm?.value.exercise_other,
          +this.entryForm?.value.minutes,
          +this.entryForm?.value.seconds,
          //this.entryForm?.value.intensity,
          +this.entryForm?.value.difficulty
        )
        .subscribe(
          (responseData) => {
            if (+this.entryForm?.value.difficulty < 3) {
              Swal.fire({
                icon: 'success',
                title: 'Entry created',
                text: 'Well done! You have completed your exercise, keep going and try to increase your time over the next 2 weeks',
                showConfirmButton: true,
                timerProgressBar: true,
              });
            } else if (+this.entryForm?.value.difficulty > 3) {
              Swal.fire({
                icon: 'success',
                title: 'Entry created',
                text: 'You’re not quite managing your exercise, try reducing your pace and remember to do a good warm up first. Remember to contact your HCP via the support tab if you have concerns.',
                showConfirmButton: true,
                timerProgressBar: true,
              });
            } else {
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Entry created',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                toast: true,
              });
            }

            this.router.navigate(['/exercise', 'cardio']);
            this.entryForm?.reset();

            this.achievementService.refreshNotifications();
          },
          (error) => {
            this.error = error.message;
          }
        );
    }
  }

  onCheckForOther(event: any) {
    let selectElementText =
      event.target['options'][event.target['options'].selectedIndex].text;
    if (selectElementText == 'Other') {
      this.otherSelected = true;
    } else {
      this.otherSelected = false;
    }
  }

  filterExercise(exercises: any, intensity: any) {
    return this.cardioWorkoutService.filterExercises(exercises, intensity);
  }

  public scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement =
      this.el.nativeElement.querySelector('form .ng-invalid');

    if (firstInvalidControl) {
      firstInvalidControl.focus();
      window.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  public getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 150;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<any> | any {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away

    if (this.submitted) {
      return true;
    } else if (
      !this.entryForm?.value.exercise &&
      !this.entryForm?.value.minutes &&
      !this.entryForm?.value.seconds &&
      !this.entryForm?.value.difficulty
    ) {
      return true;
    } else {
      return false;
    }
  }
}
