<div class="dashboard-masthead-container" *ngIf="show">
  <div class="dashboard-masthead dashboard-masthead-cardio">
    <div class="dashboard-masthead-content">
      <div class="dashboard-masthead-content-inner">
        <h2>Hit your Cardio Exercise Target</h2>
        <div class="dashboard-masthead-subtitle">{{cardioWeeklyTarget.days - cardioDaysHit}} more days needed</div>
        <div class="week-bar">
          <div class="entry-days">
            <div
              class="entry-day"
              *ngFor="let day of currentWeekData"
              [ngClass]="{
                complete: +day.minutes >= +cardioWeeklyTarget.threshold
              }"
            >
              <div
                class="entry-day-label"
                *ngIf="+day.minutes < +cardioWeeklyTarget.threshold"
              >
                {{ day.date | date : "EE" | slice : 0 : 1 }}
              </div>

              <div
                class=""
                *ngIf="day.date <= today"
                [ngClass]="{
                  complete: +day.minutes >= +cardioWeeklyTarget.threshold
                }"
              >
                <span
                  class="icon-wrap"
                  *ngIf="+day.minutes >= +cardioWeeklyTarget.threshold"
                >
                  <fa-icon [icon]="['fas', 'check']"></fa-icon>
                </span>
                <span
                  class="icon-wrap icon-wrap-dot text-success"
                  [routerLink]="['/exercise', 'cardio', 'add-workout']"
                  [queryParams]="{ d: day.date }"
                  *ngIf="
                    day.minutes && +day.minutes < +cardioWeeklyTarget.threshold
                  "
                >
                  <fa-icon [icon]="['fas', 'circle']"></fa-icon>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="dashboard-masthead-subtext">
          Workout for {{cardioWeeklyTarget.threshold}} minutes on {{cardioWeeklyTarget.days}} days this week.
        </div>
      </div>
    </div>
  </div>
</div>
