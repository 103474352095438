<div class="main-content-area sub-page">
  <tabset [justified]="true" class="mt-2">
    <tab [attr.role]="'tab'">
      <ng-template tabHeading> Weekly Target </ng-template>
      <nav class="page-header">
        <div>
          <button
            (click)="onPreviousWeek()"
            *ngIf="currentWeekNumber > 1"
            class="btn btn-left"
          >
          <span class="sr-only">Previous Week</span>
            <span class="icon-wrap"
              ><fa-icon [icon]="['fal', 'chevron-left']"></fa-icon
            ></span>
          </button>
        </div>
        <div>
          <h2 *ngIf="userData">Week {{ currentWeekNumber }}</h2>
          <div class="subtitle">
            {{ currentWeek[0] | customDate : "ddd Do MMM" }} -
            {{ currentWeek[6] | customDate : "ddd Do MMM" }}
          </div>
        </div>
        <div>
          <button
            (click)="onNextWeek()"
            *ngIf="userData && currentWeekNumber < userData.current_week"
            class="btn btn-right"
          >
          <span class="sr-only">Next Week</span>
            <span class="icon-wrap"
              ><fa-icon [icon]="['fal', 'chevron-right']"></fa-icon
            ></span>
          </button>
        </div>
      </nav>

      <div class="">
        <div class="row">
          <div class="col">
            <div
              class="card bg-primary mb-3 text-white"
              *ngIf="userData && canProgress"
            >
              <div class="card-body">
                <h3 class="card-title card-title-lg text-center">
                  <span class="icon-wrap"
                    ><fa-icon [icon]="['fal', 'lock-keyhole-open']"></fa-icon
                  ></span>
                  Next Level Unlocked!
                </h3>
                <p class="card-text text-center">
                  In the last two weeks you have met the criteria for level
                  {{ userData.exercise_level }}
                </p>
                <p class="card-text text-center">
                  <strong>
                    {{
                      cardioWorkoutService.levelTargets[userData.exercise_level]
                        .long 
                    }}
                  </strong>
                </p>
                <p class="card-text text-center">
                  Would you like to progress on to level
                  {{ +userData.exercise_level + 1 }}?
                </p>
              </div>
              <div
                class="card-footer text-center relative text-dark bg-primary"
              >
                <a
                  (click)="onProgress(+userData.exercise_level + 1)"
                  class="stretched-link btn btn-white"
                  >Proceed &nbsp;
                  <span class="icon-wrap more-link"
                    ><fa-icon [icon]="['fal', 'chevron-right']"></fa-icon></span
                ></a>
              </div>
            </div>

            <div class="card bg-secondary text-white card-cardio">
              <div class="card-body">
                <div *ngIf="isLoading" class="text-center">
                  <app-loading-spinner [inline]="true"></app-loading-spinner>
                </div>
                <div *ngIf="!isLoading">
                  <p class="text-center" *ngIf="userData.exercise_level">
                    Level {{ userData.exercise_level }}:
                    {{
                      cardioWorkoutService.levelTargets[userData.exercise_level]
                        .simple
                    }}
                  </p>

                  <div class="week-bar">
                    <div class="entry-days">
                      <div
                        class="entry-day"
                        *ngFor="let day of currentWeekData"
                        [ngClass]="{
                          complete: +day.minutes >= +weeklyTarget.threshold
                        }"
                      >
                        <div class="entry-day-label">
                          {{ day.date | date : "EE" | slice : 0 : 1 }}
                        </div>
                        <div class="na" *ngIf="day.date > today">&nbsp;</div>
                        <div
                          class=""
                          *ngIf="day.date <= today"
                          [ngClass]="{
                            complete: +day.minutes >= +weeklyTarget.threshold
                          }"
                        >
                          <span
                            class="icon-wrap"
                            *ngIf="+day.minutes >= +weeklyTarget.threshold"
                          >
                            <fa-icon [icon]="['fas', 'check']"></fa-icon>
                          </span>
                          <span
                            class="icon-wrap icon-wrap-dot text-success"
                            [routerLink]="[
                              '/exercise',
                              'cardio',
                              'add-workout'
                            ]"
                            [queryParams]="{ d: day.date }"
                            *ngIf="
                              day.minutes &&
                              +day.minutes < +weeklyTarget.threshold
                            "
                          >
                            <fa-icon [icon]="['fas', 'circle']"></fa-icon>
                          </span>

                          <div
                            class="entry-day-data-value"
                            *ngIf="day.minutes == 0 && day.date !== today"
                            [routerLink]="[
                              '/exercise',
                              'cardio',
                              'add-workout'
                            ]"
                            [queryParams]="{ d: day.date }"
                          >
                            -
                          </div>

                          <div
                            class="entry-day-data-value relative"
                            *ngIf="day.minutes == 0 && day.date === today"
                          >
                            <span
                              class="icon-wrap icon-wrap-dot text-success"
                              [routerLink]="[
                                '/exercise',
                                'cardio',
                                'add-workout'
                              ]"
                            >
                              <fa-icon [icon]="['fas', 'circle']"></fa-icon>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--p class="subtext" *ngIf="+daysHit < weeklyTarget.days">
                  You have completed
                  <strong
                    >{{ daysHit }} day<ng-container *ngIf="daysHit != 1"
                      >s</ng-container
                    ></strong
                  >
                  towards your target this week.
                </p-->
                  <p
                    class="subtext text-center m-2"
                    *ngIf="+daysHit >= weeklyTarget.days"
                  >
                    You have hit your target this week!
                  </p>
                </div>
              </div>
            </div>
            <table class="table-workouts my-4">
              <thead>
                <tr>
                  <th></th>
                  <th>No. of workouts</th>
                  <th>Total Duration</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let day of currentWeekData"
                  [ngClass]="{ disabled: day.date > today }"
                >
                  <td>{{ day.date | date : "EEEE" }}</td>
                  <td>
                    <span *ngIf="day.date <= today">{{ day.workouts }}</span>
                  </td>
                  <td>
                    <span *ngIf="day.date <= today"
                      >{{ day.minutes }}m {{ day.seconds }}s</span
                    >
                  </td>
                  <td>
                    <span
                      class="icon-wrap text-success"
                      *ngIf="+day.minutes >= +weeklyTarget.threshold"
                      ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
                    ></span>
                  </td>
                </tr>
              </tbody>
            </table>

            <p class="note">
              Please note that <strong>Light</strong>,
              <strong>Moderate</strong> and <strong>Vigorous</strong> cardio
              workouts all count towards this week's target.
            </p>
          </div>
        </div>
      </div>
    </tab>
    <tab>
      <ng-template tabHeading> All Workouts </ng-template>

      <div class="">
        <div class="row">
          <div class="col">
            <div *ngIf="isLoading" class="text-center">
              <app-loading-spinner [inline]="true"></app-loading-spinner>
            </div>
            <div *ngIf="!isLoading && allWorkouts">
              <div class="text-center pt-4 mb-3">
                {{ allWorkouts.length }} workouts |
                {{ getTotalWorkoutDuration() }}
              </div>

              <div
                *ngFor="
                  let week of programmeWeeks.slice().reverse();
                  let index = index
                "
              >
                <ng-container *ngIf="week.data.length > 0">
                  <div class="all-weeks-header">
                    <div class="week-title">
                      <h3 class="all-data-header">Week {{ week.number }}</h3>
                      <h4 class="all-data-subheader">
                        {{ week.start | customDate : "ddd Do MMM" }} -
                        {{ week.end | customDate : "ddd Do MMM" }}
                      </h4>
                    </div>
                    <div class="week-metrics">
                      <span class="metric-data"
                        >{{ week.data.length }} workout<span
                          *ngIf="week.data.length != 1"
                          >s</span
                        ></span
                      >
                      <span class="metric-data"
                        ><span *ngIf="week.totalHours > 0"
                          >{{ week.totalHours }}h </span
                        >{{ week.totalMinutes }}m {{ week.totalSeconds }}s</span
                      >
                    </div>
                  </div>

                  <table class="table-workouts-flex mt-2 mb-5">
                    <tbody>
                      <ng-container *ngFor="let workout of week.data">
                        <tr *ngIf="workout.workout_date | date : 'MMMMYY'">
                          <td>
                            <div class="flex-workout-date">
                              {{ workout.workout_date | customDate : "ddd Do" }}
                            </div>
                            <div class="text-start flex-workout-type">
                              {{ workout.exercise }}
                            </div>
                            <div class="flex-workout-intensity">
                              <span
                                class="circle-intensity circle-intensity-{{
                                  workout.intensity
                                }}"
                                >{{ workout.intensity }}</span
                              >
                            </div>
                            <div class="flex-workout-time">
                              {{ workout.minutes }}m {{ workout.seconds }}s
                            </div>
                            <a
                              class="flex-workout-link stretched-link"
                              [routerLink]="[
                                '/exercise',
                                'cardio',
                                'edit-workout',
                                workout.cardio_workout_id
                              ]"
                              ><span>
                                <span class="sr-only">Edit Workout</span>
                                <fa-icon
                                  [icon]="['fal', 'arrow-right']"
                                ></fa-icon></span
                            ></a>
                          </td>
                        </tr>
                      </ng-container>
                    </tbody>
                  </table>

                  <!--ul class="task-list task-list-todo task-list-bt mb-2">
                <ng-container *ngFor="let workout of allWorkouts">
                  <li *ngIf="(workout.workout_date | date: 'MMMMYY') == month">
                    <span class="task">
                      <span class="date me-4">
                        {{ workout.workout_date | date: "EEE d" }}
                      </span>
                      <span class="title"
                        >{{ workout.minutes }}m
                        <span *ngIf="workout.seconds">
                          {{ workout.seconds }}s
                        </span>
                      </span>
                      <span class="title">
                        {{ workout.difficulty }}
                      </span>
                      <span class="status">
                        <span class="icon-wrap text-secondary-accent" dropdown>
                          <button
                            dropdownToggle
                            type="button"
                            class="btn"
                            aria-controls="dropdown-basic"
                          >
                            <fa-icon [icon]="['fal', 'ellipsis-v']"></fa-icon>
                          </button>
                          <ul
                            *dropdownMenu
                            class="dropdown-menu dropdown-menu-right"
                            role="menu"
                            aria-labelledby="button-basic"
                          >
                            <li role="menuitem">
                              <a
                                class="dropdown-item"
                                [routerLink]="[
                                  '/exercise',
                                  'cardio',
                                  'edit-workout',
                                  workout.cardio_workout_id
                                ]"
                                >Edit</a
                              >
                            </li>
                            <li role="menuitem">
                              <a
                                class="dropdown-item"
                                (click)="
                                  onDeleteEntry(workout.cardio_workout_id)
                                "
                                >Delete</a
                              >
                            </li>
                          </ul>
                        </span>
                      </span>
                    </span>
                  </li>
                </ng-container>
              </ul-->
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </tab>
    <tab>
      <ng-template tabHeading> Month </ng-template>
      <nav class="page-header">
        <div>
          <span class="icon-wrap" (click)="onPrevMonth()"
            ><fa-icon [icon]="['fal', 'chevron-left']"></fa-icon
          ></span>
        </div>
        <div>
          <h2 *ngIf="userData">{{ monthSelected | date : "MMMM YYYY" }}</h2>
        </div>
        <div>
          <span
            class="icon-wrap"
            *ngIf="monthSelected < currentMonth"
            (click)="onNextMonth()"
            ><fa-icon [icon]="['fal', 'chevron-right']"></fa-icon
          ></span>
        </div>
      </nav>
      <div class="status-calendar" *ngIf="monthData">
        <div class="status-calendar-row status-calendar-header">
          <div class="status-calendar-col">
            {{ monthData[1].days[0]?.date | date : "EE" | slice : 0 : 1 }}
          </div>
          <div class="status-calendar-col">
            {{ monthData[1].days[1]?.date | date : "EE" | slice : 0 : 1 }}
          </div>
          <div class="status-calendar-col">
            {{ monthData[1].days[2]?.date | date : "EE" | slice : 0 : 1 }}
          </div>
          <div class="status-calendar-col">
            {{ monthData[1].days[3]?.date | date : "EE" | slice : 0 : 1 }}
          </div>
          <div class="status-calendar-col">
            {{ monthData[1].days[4]?.date | date : "EE" | slice : 0 : 1 }}
          </div>
          <div class="status-calendar-col">
            {{ monthData[1].days[5]?.date | date : "EE" | slice : 0 : 1 }}
          </div>
          <div class="status-calendar-col">
            {{ monthData[1].days[6]?.date | date : "EE" | slice : 0 : 1 }}
          </div>
          <div class="status-calendar-col">
            <span class="icon-wrap text-primary">
              <!--fa-icon [icon]="['fas', 'bullseye-arrow']"></fa-icon-->
            </span>
          </div>
        </div>

        <div
          class="status-calendar-row"
          *ngFor="let weekNum of [1, 2, 3, 4, 5, 6]"
        >
          <ng-container *ngIf="monthData[weekNum]">
            <div
              class="status-calendar-col"
              *ngFor="let day of monthData[weekNum].days"
            >
              <span
                class="status-calendar-day"
                [ngClass]="{
                  complete: +day.mins >= +weeklyTarget.threshold,
                  incomplete:
                    +day.mins < +weeklyTarget.threshold && day.date < today,
                  'outside-month':
                    day.date < firstDayMonth || day.date > lastDayMonth
                }"
                >{{ day.date | date : "d" }}</span
              >
            </div>
            <div class="status-calendar-col">
              <span
                class="icon-wrap text-success cal-check"
                *ngIf="+monthData[weekNum].daysHit >= +weeklyTarget.days"
                ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
              ></span>
            </div>
          </ng-container>
        </div>
      </div>

      <div class="bg-light stats-section pt-3" *ngIf="allWorkouts">
        <!-- stats -->
        <div class="row">
          <div class="col-6 mb-3">
            <div class="status-badge">
              <div class="status-badge-text">
                <div class="status-badge-title">TOTAL WORKOUTS</div>
                <div class="status-badge-status">
                  {{ stats?.total }}
                </div>
              </div>
            </div>
          </div>

          <div class="col-6 mb-3">
            <div class="status-badge">
              <div class="status-badge-text">
                <div class="status-badge-title">TOTAL DURATION</div>
                <div class="status-badge-status">
                  {{ stats?.timeSpentFormatted.hours }}<span> hr</span>
                  {{ stats?.timeSpentFormatted.minutes }}<span> min</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 mb-3">
            <div class="status-badge">
              <div class="status-badge-icon">
                <span class="icon-wrap">
                  <!--fa-icon [icon]="['fas', 'bullseye-arrow']"></fa-icon-->
                </span>
              </div>
              <div class="status-badge-text">
                <div class="status-badge-title">TARGET HIT</div>
                <div class="status-badge-status">
                  {{ stats?.targetHit }}<span>x</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6 mb-3">
            <div class="status-badge">
              <div class="status-badge-icon">
                <span class="icon-wrap">
                  <!--fa-icon [icon]="['fas', 'arrows-h']"></fa-icon-->
                </span>
              </div>
              <div class="status-badge-text">
                <div class="status-badge-title">LONGEST STREAK</div>
                <div class="status-badge-status">
                  {{ stats?.streak }}<span> days</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <div class="status-badge">
              <div class="status-badge-text">
                <div class="status-badge-title">WORKOUT INTENSITY</div>
                <div class="status-badge-status">
                  <div class="row my-2">
                    <div class="col text-center">
                      <span
                        class="circle-intensity-lg circle-intensity-l mx-auto"
                        >L</span
                      >
                      <span class="small"
                        >{{ stats.lightWorkouts }} workouts</span
                      >
                    </div>
                    <div class="col text-center">
                      <span
                        class="circle-intensity-lg circle-intensity-m mx-auto"
                        >M</span
                      >
                      <span class="small"
                        >{{ stats.moderateWorkouts }} workouts</span
                      >
                    </div>
                    <div class="col text-center">
                      <span
                        class="circle-intensity-lg circle-intensity-v mx-auto"
                        >V</span
                      >
                      <span class="small"
                        >{{ stats.vigorousWorkouts }} workouts</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="status-badge">
              <div class="status-badge-text">
                <div class="status-badge-title">TOP ACTIVITIES</div>
                <div class="status-badge-status">
                  <div class="row my-2">
                    <ng-container
                      *ngFor="
                        let activity of stats.workoutsByActivity;
                        index as index
                      "
                    >
                      <ng-container *ngIf="index < 3">
                        <div class="col text-center mb-2">
                          <span
                            class="circle-intensity-lg circle-intensity-p mx-auto"
                            >{{ activity.total }}x</span
                          >
                          <span class="small">{{ activity.exercise }}</span>
                        </div></ng-container
                      >
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </tab>
  </tabset>
  <div class="row d-md-block d-none mt-4">
    <div class="col text-center">
      <button
        class="btn btn-primary"
        [routerLink]="['/exercise', 'cardio', 'add-workout']"
      >
        <span class="icon-wrap"
          ><fa-icon [icon]="['fal', 'circle-plus']"></fa-icon
        ></span>
        Add a workout
      </button>
    </div>
  </div>

  <button
    class="btn btn-primary d-md-none sticky-button"
    [routerLink]="['/exercise', 'cardio', 'add-workout']"
  >
    <span class="icon-wrap"
      ><fa-icon [icon]="['fal', 'circle-plus']"></fa-icon
    ></span>
    Add a workout
  </button>
</div>
