<span class="strength-icon">
  <ng-container [ngSwitch]="exerciseId">
    <ng-container *ngSwitchCase="'pullUps'">
      <svg
        width="18"
        height="28"
        viewBox="0 0 18 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1253_3807)">
          <path
            d="M4.34934 26.5757C4.55117 24.1395 4.71486 21.8502 4.93392 19.5657C5.2896 15.8799 5.50497 12.1893 5.36836 8.4853C5.33883 7.6967 5.15422 6.9627 4.58686 6.36458C4.51917 6.293 4.40964 6.23234 4.50317 6.10253C4.58194 5.99334 4.66932 6.00183 4.78255 6.03944C4.97577 6.10374 5.18745 6.04914 5.28591 5.9072C5.51359 5.57599 5.74988 5.71065 5.9911 5.8429C6.3234 6.02367 6.67415 6.07219 7.05075 6.06613C8.00086 6.05157 8.95096 6.05157 9.90107 6.06613C10.4721 6.07462 11.0333 6.07462 11.5416 5.76282C11.6585 5.69124 11.7853 5.63786 11.9096 5.77981C12.1016 5.99819 12.3293 6.13286 12.6382 6.0176C12.7231 5.98606 12.7822 6.02852 12.8339 6.09161C12.9126 6.18745 12.8597 6.25539 12.792 6.32455C12.0696 7.07189 11.9785 8.00486 11.9527 8.97423C11.8505 12.7862 12.1237 16.5787 12.4831 20.37C12.6739 22.384 12.8302 24.4016 12.9717 26.4204C13.0283 27.2223 12.5077 27.8217 11.725 27.9685C11.0407 28.0971 10.3171 27.6882 10.0881 27.0173C10.0069 26.7771 9.97861 26.5162 9.95769 26.2615C9.70662 23.2672 9.45802 20.273 9.21926 17.2775C9.2008 17.0397 9.15403 16.9306 8.88081 16.9378C7.96886 16.9621 8.15716 16.859 8.09439 17.633C7.84948 20.6272 7.60703 23.6227 7.34981 26.6169C7.27843 27.4444 6.54369 28.0595 5.75727 27.9952C4.94008 27.9284 4.32226 27.2393 4.34564 26.5769L4.34934 26.5757Z"
            fill="#3C5A64"
          />
          <path
            d="M8.6322 5.36371C7.1332 5.35036 5.9431 4.14442 5.95787 2.65458C5.97387 1.17566 7.19596 -0.00844717 8.69497 4.53977e-05C10.178 0.00853797 11.4062 1.22904 11.405 2.69219C11.4025 4.17596 10.1558 5.37705 8.6322 5.3625V5.36371Z"
            fill="#3C5A64"
          />
          <path
            d="M3.45337 7.90771C3.158 7.91985 2.92047 7.79974 2.71002 7.60562C1.93836 6.89225 1.16179 6.18494 0.392591 5.46792C-0.0406192 5.06392 -0.115693 4.58833 0.168602 4.16856C0.447973 3.75485 0.92672 3.63959 1.46823 3.86889C2.05897 4.11882 2.64356 4.3833 3.2343 4.6308C3.40291 4.70116 3.51367 4.79094 3.54075 4.9802C3.55306 5.06392 3.64044 5.14763 3.55921 5.23013C3.46814 5.32233 3.39553 5.23983 3.32661 5.18281C3.24292 5.11366 3.17031 5.0991 3.10016 5.20465C3.02508 5.31991 3.12231 5.36722 3.18384 5.42424C3.49521 5.71542 3.82258 5.99082 4.12042 6.29534C4.40963 6.59137 4.49578 6.95533 4.31856 7.34114C4.15364 7.70147 3.86197 7.89801 3.45337 7.90893V7.90771Z"
            fill="#3C5A64"
          />
          <path
            d="M17.3321 4.74732C17.3432 4.98511 17.2152 5.23018 16.99 5.43522C16.1949 6.16437 15.4085 6.90079 14.6061 7.62266C14.1692 8.01575 13.5784 8.0024 13.2092 7.61781C12.8425 7.23443 12.8671 6.65087 13.2818 6.24201C13.5514 5.9751 13.8393 5.72639 14.1126 5.46312C14.1901 5.38912 14.3464 5.32603 14.2209 5.17559C14.1052 5.03607 14.0436 5.17923 13.9661 5.23018C13.7963 5.34301 13.7163 5.2411 13.7593 5.1028C13.8135 4.93052 13.8356 4.73398 14.0707 4.64056C14.7218 4.38214 15.3507 4.07155 16.0029 3.81799C16.6614 3.562 17.3173 4.00361 17.3296 4.74611L17.3321 4.74732Z"
            fill="#3C5A64"
          />
          <path
            d="M13.6203 4.79579C13.3926 5.04329 13.5009 5.26773 13.6597 5.54799C13.7249 5.66446 13.3864 5.81854 13.2375 5.9617C13.0948 6.10001 13.0271 5.94957 12.9594 5.86221C12.8535 5.72512 12.76 5.68144 12.5803 5.77486C12.3477 5.89497 12.1779 5.74696 12.0474 5.55891C11.9096 5.35873 11.9699 5.19009 12.1397 5.0445C12.381 4.83825 12.6259 4.63686 12.8683 4.43061C13.0111 4.3105 13.1735 4.27896 13.3323 4.36995C13.48 4.45366 13.5784 4.59075 13.6203 4.79458V4.79579Z"
            fill="#3C5A64"
          />
          <path
            d="M2.64972 7.8545C2.58203 7.94549 2.5365 8.02435 2.47496 8.08744C2.36543 8.20148 2.34697 8.28641 2.46142 8.42714C2.62757 8.62975 2.52419 8.82387 2.35189 8.98037C2.1599 9.15508 1.96545 9.11989 1.77961 8.9646C1.547 8.77049 1.31686 8.57273 1.08426 8.37861C0.909496 8.23303 0.856576 8.05954 0.977185 7.86057C1.09656 7.66281 1.27625 7.52572 1.50762 7.63733C1.68607 7.72347 1.77715 7.68708 1.88791 7.54998C1.95068 7.47355 2.01098 7.30855 2.16728 7.43837C2.32728 7.57061 2.48235 7.70891 2.64849 7.85329L2.64972 7.8545Z"
            fill="#3C5A64"
          />
          <path
            d="M16.4386 8.06187C16.4226 8.18562 16.3734 8.28995 16.2725 8.37367C16.0374 8.56657 15.806 8.76554 15.5722 8.95965C15.3876 9.11373 15.1907 9.15862 14.9962 8.98392C14.8251 8.82863 14.7267 8.63815 14.8855 8.42584C14.9679 8.31543 15.0294 8.22565 14.9015 8.12374C14.6209 7.90294 14.787 7.76706 14.9753 7.60084C15.171 7.42857 15.331 7.30846 15.5107 7.60812C15.5943 7.74886 15.699 7.67728 15.8233 7.64209C16.1297 7.55474 16.4386 7.77919 16.4398 8.06187H16.4386Z"
            fill="#3C5A64"
          />
          <path
            d="M4.20041 4.32154C4.41332 4.32154 5.3179 5.07738 5.35482 5.28362C5.40897 5.58693 5.10252 5.86355 4.78008 5.7859C4.66808 5.75921 4.55978 5.64031 4.46378 5.78954C4.30133 6.04189 4.15487 6.03825 3.93335 5.83079C3.73643 5.64638 3.56536 5.50928 3.8472 5.27028C3.98257 5.15624 3.85827 5.07374 3.80781 4.96819C3.66628 4.66852 3.88658 4.3179 4.19918 4.32033L4.20041 4.32154Z"
            fill="#3C5A64"
          />
        </g>
        <defs>
          <clipPath id="clip0_1253_3807">
            <rect width="17.3333" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </ng-container>
    <ng-container *ngSwitchCase="'bicepCurl'">
      <svg
        width="18"
        height="28"
        viewBox="0 0 18 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1256_4023)">
          <path
            d="M8.71262 5.98667C9.46973 5.98667 10.2281 5.98034 10.9852 5.9892C11.6776 5.99679 12.9071 6.85095 13.1582 7.46974C13.2165 7.61147 13.1543 7.67854 13.0573 7.74054C12.713 7.95946 12.326 7.77598 12.2976 7.38369C12.2523 6.74845 12.0232 6.54599 11.38 6.58395C11.2195 6.59407 11.059 6.61179 10.8985 6.63203C10.3951 6.6991 10.2178 6.88891 10.2411 7.38243C10.2773 8.12397 10.3239 8.8655 10.3821 9.60451C10.4378 10.3043 10.6669 10.4852 11.3774 10.4334C11.9999 10.3891 12.018 10.3891 11.9973 11.0117C11.8925 14.1474 12.2924 17.2553 12.5474 20.3707C12.7104 22.3536 12.8903 24.3353 13.0482 26.3195C13.1181 27.204 12.5461 27.8911 11.7009 27.9898C10.8714 28.086 10.122 27.5204 10.0301 26.6409C9.85282 24.9288 9.72598 23.2116 9.57844 21.4969C9.46197 20.1442 9.32996 18.7915 9.23807 17.4375C9.19795 16.8491 8.77733 17.1224 8.50166 17.0667C8.15223 16.9958 8.13022 17.2135 8.10822 17.4792C7.85844 20.5011 7.60089 23.5229 7.34076 26.5434C7.26181 27.4558 6.51505 28.0911 5.65052 27.9911C4.75622 27.8886 4.22301 27.1635 4.30454 26.2347C4.55562 23.3875 4.76917 20.5365 5.03707 17.6906C5.25449 15.3761 5.39686 13.0604 5.32697 10.7358C5.31791 10.427 5.41886 10.3385 5.71653 10.4068C5.8563 10.4397 6.00513 10.441 6.14879 10.4435C6.64577 10.4536 6.90849 10.2372 6.94732 9.75763C7.01332 8.95535 7.07286 8.15181 7.11686 7.347C7.13886 6.94206 6.93438 6.68771 6.50729 6.63963C6.31574 6.61811 6.12291 6.59407 5.93007 6.58268C5.32438 6.54978 5.07848 6.76743 5.03448 7.36724C5.00342 7.7823 4.74587 7.93542 4.34337 7.76965C4.16218 7.69499 4.09488 7.5811 4.23595 7.40394C4.87141 6.60166 5.55863 5.90948 6.7273 5.97908C7.38605 6.0183 8.04869 5.98667 8.71003 5.98667H8.71262Z"
            fill="#3C5A64"
          />
          <path
            d="M8.69191 3.6768e-05C10.223 0.0076293 11.4369 1.18447 11.433 2.65742C11.4292 4.11646 10.1919 5.30975 8.67638 5.31481C7.15698 5.31861 5.88865 4.08609 5.90289 2.6182C5.91713 1.16169 7.16215 -0.00755577 8.69191 3.6768e-05Z"
            fill="#3C5A64"
          />
          <path
            d="M12.4399 11.5786C12.4037 11.2179 12.3558 10.8585 12.3325 10.4979C12.3027 10.0499 12.5655 9.63235 12.4619 9.16541C12.4283 9.0123 12.6522 9.05279 12.7712 9.04014C12.9188 9.02495 12.9822 9.1148 12.968 9.24134C12.9369 9.50708 12.8955 9.77155 12.8502 10.0348C12.8295 10.1537 12.7376 10.3018 12.933 10.3397C13.1349 10.3777 13.1077 10.1702 13.1233 10.0778C13.1906 9.6817 13.3485 9.29702 13.1673 8.87563C12.955 8.38085 13.1634 7.89366 13.6164 7.67728C14.09 7.45076 14.6258 7.60262 14.9209 8.05437C15.2652 8.57952 15.2535 9.16921 15.1836 9.75004C15.0594 10.775 14.7035 11.7405 14.2803 12.677C14.0732 13.1338 13.5931 13.3236 13.1103 13.2008C12.6871 13.092 12.4063 12.7162 12.3985 12.2442C12.3946 12.024 12.3985 11.8026 12.3985 11.5824L12.4425 11.5798L12.4399 11.5786Z"
            fill="#3C5A64"
          />
          <path
            d="M2.12639 9.23258C2.10956 8.83524 2.1898 8.42018 2.42794 8.04814C2.71396 7.60145 3.26917 7.4496 3.73509 7.67864C4.18806 7.90009 4.38349 8.38095 4.17641 8.88332C3.99005 9.33634 4.17124 9.74887 4.24889 10.1753C4.26571 10.2702 4.28771 10.374 4.42361 10.3411C4.54656 10.3107 4.54656 10.212 4.52585 10.1146C4.47408 9.86782 4.41455 9.62233 4.37184 9.37431C4.33819 9.18196 4.36925 9.03391 4.63456 9.03644C4.87658 9.0377 4.89082 9.1478 4.91929 9.35532C5.04612 10.2892 4.89729 11.2218 4.93352 12.1544C4.95553 12.7289 4.66821 13.1149 4.18418 13.2111C3.66649 13.3123 3.25235 13.0921 3.01939 12.5897C2.51853 11.5154 2.13156 10.0741 2.12639 9.23384V9.23258Z"
            fill="#3C5A64"
          />
          <path
            d="M5.97535 10.1601C5.16906 10.1234 5.11729 10.0741 5.20401 9.25282C5.23636 8.95292 5.20142 8.76184 4.83516 8.77829C4.37442 8.79853 4.56596 8.43536 4.5362 8.23669C4.49349 7.94944 4.7588 8.07471 4.86363 8.09116C5.26483 8.1557 5.30754 7.94564 5.3179 7.63435C5.34249 6.88901 5.92617 6.58784 6.62246 6.94089C6.85283 7.05731 6.85283 7.25472 6.836 7.46858C6.78035 8.16076 6.72341 8.85168 6.67811 9.54387C6.64058 10.1234 6.57587 10.1867 5.97535 10.1589V10.1601Z"
            fill="#3C5A64"
          />
          <path
            d="M11.4317 6.85608C11.8705 6.77636 12.0219 7.01426 12.0219 7.44577C12.0219 7.82286 12.0219 8.23666 12.6302 8.08101C12.7272 8.0557 12.902 8.0595 12.8217 8.2316C12.7402 8.40876 12.9524 8.75928 12.6017 8.75675C12.0556 8.75422 12.1384 9.06045 12.1694 9.40085C12.1914 9.63748 12.3015 9.95257 11.9805 10.0639C11.6207 10.1879 11.226 10.2474 10.8545 10.0956C10.6733 10.0209 10.6798 9.80831 10.6669 9.63622C10.6229 9.00857 10.5776 8.38092 10.5439 7.752C10.4999 6.91429 10.5336 6.88266 11.4317 6.85355V6.85608Z"
            fill="#3C5A64"
          />
          <path
            d="M15.1798 7.8899C15.6755 7.96835 15.722 7.73931 15.6923 7.3774C15.6315 6.64219 16.1103 6.30432 16.8622 6.51438C17.0603 6.57006 17.1431 6.70293 17.1573 6.89654C17.2143 7.71274 17.2764 8.52894 17.3333 9.34513C17.354 9.64377 17.1871 9.77538 16.8985 9.78803C16.8027 9.79183 16.7056 9.79562 16.6099 9.79942C15.8916 9.83232 15.8955 9.83232 15.8126 9.14014C15.7829 8.88705 15.8748 8.58968 15.4554 8.49983C15.3403 8.47452 15.2924 8.15184 15.1824 7.89116L15.1798 7.8899Z"
            fill="#3C5A64"
          />
          <path
            d="M2.22086 7.87847C1.96719 8.18344 2.05779 8.55041 1.62552 8.61241C1.47669 8.63393 1.56469 8.92244 1.53881 9.08821C1.50128 9.32991 1.58928 9.66398 1.30585 9.76015C0.944767 9.8829 0.543563 9.84367 0.177302 9.7108C0.0310564 9.65765 -0.0103583 9.50454 0.00128959 9.35142C0.0634116 8.51877 0.125534 7.68612 0.18895 6.85348C0.207069 6.62317 0.359785 6.50802 0.572035 6.50675C0.843819 6.50549 1.1169 6.53712 1.38739 6.57129C1.57116 6.59533 1.66564 6.7244 1.66952 6.90156C1.67341 7.05848 1.67341 7.21918 1.64752 7.3723C1.58799 7.72662 1.65011 7.96958 2.22215 7.8772L2.22086 7.87847Z"
            fill="#3C5A64"
          />
        </g>
        <defs>
          <clipPath id="clip0_1256_4023">
            <rect width="17.3333" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </ng-container>
    <ng-container *ngSwitchCase="'sitToStand'">
      <svg
        width="18"
        height="28"
        viewBox="0 0 18 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1253_3816)">
          <path
            d="M7.33173 12.1626C7.21238 12.083 7.07274 12.064 6.95339 12.1234C6.83403 12.1828 6.75407 12.3016 6.75407 12.4406V13.0952H5.79688C4.78001 13.0952 3.96245 13.9077 3.96245 14.9186V16.9999C3.96245 17.2576 4.16177 17.456 4.42076 17.456C4.67975 17.456 4.87907 17.2576 4.87907 16.9999V14.9388C4.87907 14.4233 5.29799 14.0075 5.81597 14.0075H6.77317V14.6621C6.77317 14.8011 6.85313 14.9199 6.97248 14.9793C7.09183 15.0387 7.23147 15.0184 7.35082 14.9401L8.86539 13.8306C8.96564 13.7712 9.00503 13.6524 9.00503 13.5337C9.00503 13.4149 8.94535 13.315 8.86539 13.2367L7.33053 12.1664L7.33173 12.1626Z"
            fill="#3C5A64"
          />
          <path
            d="M9.00622 19.8837C9.00622 19.3567 8.67204 18.9473 8.24118 18.9473H2.62333L1.52411 10.9623C1.4525 10.4644 1.06938 10.1144 0.63972 10.2016C0.448758 10.2306 0.257797 10.3772 0.137252 10.5819C0.0179014 10.7866 -0.029839 11.0204 0.0179014 11.2845L1.2126 20.0303C1.28421 20.4687 1.59453 20.82 1.97764 20.82H2.35957L1.16486 26.7289C1.06938 27.2268 1.30808 27.7234 1.71507 27.87C1.78668 27.899 1.83442 27.899 1.90603 27.899C2.26409 27.899 2.55172 27.6071 2.6472 27.1964L3.14967 24.6805H6.85432L7.35679 27.1964C7.45227 27.6059 7.73871 27.899 8.09796 27.899C8.16957 27.899 8.21731 27.899 8.28892 27.87C8.69471 27.7524 8.95848 27.2268 8.83913 26.7289L7.64443 20.82H8.2185C8.67323 20.82 9.00741 20.4106 9.00741 19.8837H9.00622ZM3.45998 23.2475L3.93858 20.8491H6.09048L6.56907 23.2475H3.46118H3.45998Z"
            fill="#3C5A64"
          />
          <path
            d="M11.7966 16.3567C11.9482 16.4805 12.144 16.5753 12.2442 16.7345C12.5486 17.2185 12.8135 17.7303 13.0988 18.2282C13.4461 18.8347 13.6287 18.8815 14.2123 18.5062C14.3854 18.395 14.5465 18.2597 14.7184 18.1309C15 19.0066 15.0681 19.8899 14.975 20.8161C14.8282 22.2693 14.7601 23.7326 14.6611 25.1921C14.6265 25.6938 14.6121 26.198 14.5608 26.6984C14.4749 27.5286 13.8614 28.0568 13.0844 27.9949C12.3803 27.9393 11.8706 27.3088 11.9232 26.4849C12.0568 24.3821 12.2215 22.282 12.3564 20.1792C12.3755 19.8734 12.3194 19.555 12.2573 19.2517C12.0676 18.3343 11.8587 17.4207 11.657 16.5058C11.7036 16.4565 11.7501 16.406 11.7955 16.3567H11.7966Z"
            fill="#3C5A64"
          />
          <path
            d="M14.0094 11.5195C13.7194 10.4707 13.643 9.45467 13.8364 8.41595C13.9808 7.64007 13.5893 7.02088 12.8863 6.87935C12.2669 6.75551 11.6809 7.20537 11.5341 7.9345C11.1163 10.0044 11.5305 11.958 12.3039 13.8522C12.5318 14.4107 12.8135 14.944 13.0701 15.4861C12.5593 15.9132 12.064 16.2405 11.3777 15.8968C10.6938 15.5543 10.3012 14.9844 10.1806 14.2439C9.79513 11.8847 10.0338 9.57978 10.8741 7.35575C11.1235 6.6936 11.5532 6.17676 12.1714 5.85958C13.458 5.19996 14.6563 5.93161 14.6408 7.44547C14.6324 8.28201 14.4152 9.11602 14.2839 9.95003C14.204 10.4593 14.1073 10.9647 14.0094 11.5195Z"
            fill="#3C5A64"
          />
          <path
            d="M11.3718 2.56018C11.3706 1.13099 12.4185 0.00634395 13.7612 2.56886e-05C15.111 -0.00629257 16.2353 1.15374 16.2317 2.55134C16.2281 3.94136 15.08 5.14814 13.7719 5.13677C12.459 5.12666 11.3753 3.96157 11.373 2.55892L11.3718 2.56018Z"
            fill="#3C5A64"
          />
          <path
            d="M13.5093 8.23786C13.2205 9.78457 13.4294 11.33 14.0667 12.7895C14.451 13.6703 14.9571 14.4917 15.4285 15.3282C15.6302 15.6858 15.6851 16.027 15.4738 16.3859C15.2757 16.7233 14.9618 16.8269 14.6217 16.7359C14.4343 16.6866 14.2207 16.5615 14.1121 16.4011C12.582 14.1518 11.5675 11.7142 11.762 8.86085C11.7823 8.56389 11.8157 8.26566 11.873 7.97376C11.9757 7.46072 12.3874 7.17134 12.866 7.25222C13.267 7.32045 13.5272 7.69449 13.5105 8.2366L13.5093 8.23786Z"
            fill="#3C5A64"
          />
          <path
            d="M13.1453 15.8411C13.2408 15.9181 13.3172 15.9649 13.3769 16.0281C13.5977 16.2644 13.8137 16.5057 14.0285 16.7484C14.0751 16.8014 14.1502 16.9101 14.1359 16.9278C13.9175 17.2172 14.1646 17.4042 14.2815 17.59C14.4928 17.9236 14.3197 18.0651 14.0762 18.2167C13.834 18.3671 13.6144 18.5301 13.4139 18.1472C13.168 17.6809 12.8911 17.2323 12.6452 16.7661C12.5724 16.6283 12.4817 16.3794 12.539 16.3124C12.7001 16.1216 12.9329 15.9978 13.1453 15.8411Z"
            fill="#3C5A64"
          />
          <path
            d="M16.0921 13.8699C16.2234 14 16.3379 14.0733 16.4012 14.1807C16.6924 14.6736 16.961 15.1815 17.2546 15.6731C17.4491 15.9979 17.2498 16.1268 17.0302 16.2556C16.8189 16.3795 16.6184 16.6019 16.3964 16.2354C16.2711 16.0282 16.0193 15.898 15.9178 15.6832C15.7245 15.2763 15.5419 14.8416 15.4786 14.3993C15.4595 14.2654 15.8522 14.067 16.0909 13.8699H16.0921Z"
            fill="#3C5A64"
          />
        </g>
        <defs>
          <clipPath id="clip0_1253_3816">
            <rect width="17.3333" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </ng-container>
    <ng-container *ngSwitchCase="'stepUps'"
      ><svg
        width="14"
        height="28"
        viewBox="0 0 14 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1253_3825)">
          <path
            d="M1.86424 16.219C2.02155 16.3437 2.2247 16.4392 2.32875 16.5995C2.64462 17.087 2.91961 17.6024 3.21566 18.1038C3.57612 18.7147 3.76564 18.7618 4.37136 18.3838C4.55097 18.2718 4.7182 18.1356 4.89657 18.0058C5.1889 18.8878 5.25951 19.7774 5.16289 20.7102C5.01053 22.1738 4.93992 23.6476 4.83711 25.1175C4.80119 25.6227 4.78632 26.1305 4.73306 26.6345C4.64387 27.4707 4.00718 28.0026 3.20079 27.9403C2.46996 27.8843 1.94104 27.2492 1.99554 26.4194C2.13427 24.3017 2.30521 22.1865 2.44519 20.0688C2.46501 19.7608 2.40679 19.4401 2.34238 19.1347C2.14542 18.2107 1.92865 17.2906 1.71931 16.3692C1.76762 16.3195 1.81593 16.2686 1.863 16.219H1.86424Z"
            fill="#3C5A64"
          />
          <path
            d="M7.24514 24.6006H13.3333V27.9986H7.24514V24.6006Z"
            fill="#3C5A64"
          />
          <path
            d="M4.16078 11.347C3.85978 10.2907 3.7805 9.26747 3.98117 8.22133C4.13105 7.43991 3.72476 6.81631 2.99517 6.67377C2.35229 6.54904 1.74409 7.00212 1.59173 7.73645C1.15818 9.82108 1.58801 11.7886 2.39069 13.6964C2.62728 14.2589 2.91961 14.7959 3.18593 15.3419C2.65577 15.7721 2.14171 16.1017 1.42946 15.7555C0.719684 15.4106 0.312153 14.8367 0.187044 14.0909C-0.213055 11.7148 0.0346847 9.39346 0.906727 7.15356C1.16561 6.48668 1.61155 5.96616 2.25319 5.64672C3.58851 4.98239 4.83216 5.71926 4.81605 7.24392C4.80738 8.08643 4.58194 8.92639 4.44568 9.76635C4.36269 10.2792 4.26236 10.7883 4.16078 11.347Z"
            fill="#3C5A64"
          />
          <path
            d="M5.89248 16.6744C6.02006 16.4059 6.09315 16.2519 6.16375 16.1017C6.20339 16.1056 6.24427 16.0979 6.25542 16.1132C6.72984 16.7699 7.34795 16.7941 7.8075 16.1183C7.92023 15.9516 7.89421 15.5978 7.80255 15.3903C7.60064 14.9347 7.31698 14.5185 7.05314 14.0655C8.22247 14.0489 8.99913 14.4218 9.15149 15.5736C9.40914 17.5157 9.73987 19.4463 10.0186 21.3859C10.1412 22.2373 9.63706 22.9512 8.88145 23.0747C8.09984 23.202 7.43218 22.67 7.29592 21.7893C7.0742 20.3525 6.8599 18.9156 6.65056 17.4775C6.58739 17.0461 6.56385 16.5981 5.89248 16.677V16.6744Z"
            fill="#3C5A64"
          />
          <path
            d="M3.03357 2.57845C3.03109 1.13907 4.11991 0.00638922 5.5122 2.58719e-05C6.91317 -0.00633748 8.08002 1.16197 8.0763 2.56955C8.07259 3.96948 6.88096 5.18488 5.52335 5.17343C4.16078 5.16325 3.03605 3.98984 3.03357 2.57718V2.57845Z"
            fill="#3C5A64"
          />
          <path
            d="M3.64177 8.04211C3.342 9.59986 3.55878 11.1563 4.22024 12.6263C4.6191 13.5133 5.14431 14.3406 5.63359 15.1831C5.84293 15.5432 5.89991 15.8868 5.68066 16.2483C5.47504 16.5881 5.14926 16.6924 4.79623 16.6008C4.60176 16.5512 4.38003 16.4252 4.26731 16.2636C2.6793 13.9982 1.62641 11.5432 1.82832 8.66954C1.84937 8.37046 1.88406 8.07011 1.94352 7.77613C2.05004 7.25942 2.47739 6.96798 2.97411 7.04943C3.39031 7.11816 3.66035 7.49487 3.64301 8.04084L3.64177 8.04211Z"
            fill="#3C5A64"
          />
          <path
            d="M3.26397 15.6997C3.36306 15.7773 3.44234 15.8244 3.50427 15.8881C3.73343 16.1261 3.95764 16.3691 4.1806 16.6135C4.22891 16.6669 4.30695 16.7764 4.29209 16.7942C4.0654 17.0856 4.32181 17.274 4.44321 17.4611C4.66246 17.7971 4.48285 17.9396 4.23015 18.0923C3.9787 18.2438 3.75078 18.4079 3.54267 18.0223C3.2875 17.5527 3.00012 17.1009 2.74495 16.6313C2.66939 16.4926 2.57525 16.2419 2.63471 16.1744C2.80193 15.9822 3.04348 15.8575 3.26397 15.6997Z"
            fill="#3C5A64"
          />
          <path
            d="M6.32107 13.7144C6.45733 13.8454 6.57624 13.9193 6.64189 14.0274C6.94414 14.5238 7.22284 15.0354 7.52756 15.5305C7.72947 15.8575 7.52261 15.9873 7.29469 16.1172C7.07544 16.2419 6.86734 16.4659 6.63694 16.0968C6.50688 15.8881 6.24551 15.757 6.14022 15.5406C5.93955 15.1308 5.75003 14.693 5.68438 14.2476C5.66456 14.1127 6.07209 13.9129 6.31983 13.7144H6.32107Z"
            fill="#3C5A64"
          />
        </g>
        <defs>
          <clipPath id="clip0_1253_3825">
            <rect width="13.3333" height="28" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </ng-container>
  </ng-container>
</span>
