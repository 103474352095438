<div class="main-content-area sub-listing">
<div class="todo-progress">
  <span class="progress progress-dividers">
    <span
      *ngFor="let _ of [].constructor(tasksTotal); let index = index"
      class="progress-bar"
      [ngClass]="{ 'bg-success': index < tasksComplete, 'bg-white': index >= tasksComplete  }"
      role="progressbar"
      style="width: {{100/tasksTotal}}%"
      aria-valuenow="25"
      aria-valuemin="0"
      aria-valuemax="100"
      [attr.aria-label]="100/tasksTotal + '%'"
    >
      <span *ngIf="index < tasksComplete" class="icon-wrap text-white"
        ><fa-icon [icon]="['far', 'check']"></fa-icon
      ></span>
    </span>
  </span>
  <div class="progress-strap">
    <strong>{{ tasksComplete }} of {{ tasksTotal }}</strong> tasks completed
  </div>
</div>
<ul class="tracker-list">

  <li *ngIf="currentBitesizeSession">
    
    <a [routerLink]="['/bitesize', currentBitesizeSession.bitesize_session_id]">
      <span class="icon-wrap tracker-num">
        <span class="num" *ngIf="!bitesizeSessionViewService.checkViewed(bitesizeSessionViews, currentBitesizeSession.bitesize_session_id)">1</span>
        <span class="icon-wrap text-success"
        *ngIf="bitesizeSessionViewService.checkViewed(bitesizeSessionViews, currentBitesizeSession.bitesize_session_id)"
          ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
        ></span>
      </span>
      <span class="icon-wrap tracker-icon">
        <img
          src="/assets/img/placeholder-refresh.jpg"
          alt=""
          width="40"
          class="rounded"
        />
      </span>
      <span class="tracker-info">
        <span class="tracker-title">{{currentBitesizeSession.title}}</span>

        <span class="details">Bitesize Session ({{currentWeekNumber}} of {{getTotalBitesize()}})</span>
      </span>
      <span class="icon-wrap float-end icon-more"
        ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
      ></span>
    </a>
  </li>

  <li>
    <a [routerLink]="['/exercise', 'cardio']">
      <span class="icon-wrap tracker-num">
        <span class="num" *ngIf="cardioDaysHit<cardioData.weeklyTarget.days"><ng-container *ngIf="currentBitesizeSession">2</ng-container><ng-container *ngIf="!currentBitesizeSession">1</ng-container></span>
         <span class="icon-wrap text-success" *ngIf="cardioDaysHit>=cardioData.weeklyTarget.days"
          ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
        ></span>
      </span>
      <span class="icon-wrap tracker-icon">
        <img
          src="/assets/img/placeholder-cardio.jpg"
          alt=""
          width="40"
          class="rounded"
        />
      </span>
      <span class="tracker-info">
        <span class="tracker-title">Cardio Exercise Target</span>

        <div *ngIf="isLoading" class="text-center">
          <app-loading-spinner [inline]="true"></app-loading-spinner>
        </div>
        <span class="details">
          Workout for 10 minutes on 5 days this week.
        </span>
        <span class="details-sm"
          >{{ cardioDaysHit }} of {{ cardioWeeklyTarget.days }} days
          completed</span
        >
      </span>
      <span class="icon-wrap float-end icon-more"
        ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
      ></span>
    </a>
  </li>
  <li>
    <a [routerLink]="['/exercise', 'strength']">
      <span class="icon-wrap tracker-num">
        <span class="num" *ngIf="strengthDaysHit<strengthData.weeklyTarget.days"><ng-container *ngIf="currentBitesizeSession">3</ng-container><ng-container *ngIf="!currentBitesizeSession">2</ng-container></span>
         <span class="icon-wrap text-success" *ngIf="strengthDaysHit>=strengthData.weeklyTarget.days"
          ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
        ></span>
      </span>
      <span class="icon-wrap tracker-icon">
        <img
          src="/assets/img/placeholder-strength.jpg"
          alt=""
          width="40"
          class="rounded"
        />
      </span>
      <span class="tracker-info">
        <span class="tracker-title">Strength Exercise Target</span>

        <div *ngIf="isLoading" class="text-center">
          <app-loading-spinner [inline]="true"></app-loading-spinner>
        </div>

        <span class="details">
          Workout for {{ strengthWeeklyTarget.days }} days this week.
        </span>
        <span class="details-sm"
          >{{ strengthDaysHit }} of {{ strengthWeeklyTarget.days }} days
          completed</span
        >
      </span>
      <span class="icon-wrap float-end icon-more"
        ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
      ></span>
    </a>
  </li>
  <li>
    <a [routerLink]="['/trackers', 'health-status']">
      <span class="icon-wrap tracker-num">
        <span class="num" *ngIf="userSymptoms.length == 0"><ng-container *ngIf="currentBitesizeSession">4</ng-container><ng-container *ngIf="!currentBitesizeSession">3</ng-container></span>
        <span *ngIf="userSymptoms.length > 0" class="icon-wrap text-success"
          ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
        ></span>
      </span>
      <span class="icon-wrap tracker-icon">
        <img
          src="/assets/img/placeholder-symptoms.jpg"
          alt=""
          width="40"
          class="rounded"
        />
      </span>
      <span class="tracker-info">
        <span class="tracker-title">Update your Health Status</span>

        <span class="details"
          >Analyse whether your symptoms have changed from their usual
          state.</span
        >
      </span>
      <span class="icon-wrap float-end icon-more"
        ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
      ></span>
    </a>
  </li>

  <li>
    <a [routerLink]="['/reading-list']">
      <span class="icon-wrap tracker-num">
        <span class="num" *ngIf="!readingListReviewed"><ng-container *ngIf="currentBitesizeSession">5</ng-container><ng-container *ngIf="!currentBitesizeSession">4</ng-container></span>
        <span *ngIf="readingListReviewed" class="icon-wrap text-success"
          ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
        ></span>
      </span>
      <span class="icon-wrap tracker-icon">
        <img
          src="/assets/img/placeholder-symptoms.jpg"
          alt=""
          width="40"
          class="rounded"
        />
      </span>
      <span class="tracker-info">
        <span class="tracker-title">Review your Reading List</span>

        <span class="details"
          >Review your condition specific reading list</span
        >
      </span>
      <span class="icon-wrap float-end icon-more"
        ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
      ></span>
    </a>
  </li>
  <li>
    <a [routerLink]="['/goals']">
      <span class="icon-wrap tracker-num">
        <span class="num" *ngIf="!goalsReviewed"><ng-container *ngIf="currentBitesizeSession">6</ng-container><ng-container *ngIf="!currentBitesizeSession">5</ng-container></span>
        <span *ngIf="goalsReviewed" class="icon-wrap text-success"
          ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
        ></span>
      </span>
      <span class="icon-wrap tracker-icon">
        <img
          src="/assets/img/placeholder-goals.jpg"
          alt=""
          width="40"
          class="rounded"
        />
      </span>
      <span class="tracker-info">
        <span class="tracker-title">Review your goals</span>

        <span class="details"
          >Review your goals or add a new one.</span
        >
        <!--span class="details-sm"> {{goalsReviewed}} of {{ goalsToReview }} goals reviewed</span-->
      </span>
      <span class="icon-wrap float-end icon-more"
        ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
      ></span>
    </a>
  </li>
  <li *ngIf="currentWeekNumber==reviewWeekNo || (currentWeekNumber > reviewWeekNo && (!eq5dResponses || eq5dCompletedThisWeek))">
    <a [routerLink]="['/questionnaires', 'eq5d', 8]">
      <span class="icon-wrap tracker-num">
        <span class="num" *ngIf="!eq5dResponses"><ng-container *ngIf="currentBitesizeSession">7</ng-container><ng-container *ngIf="!currentBitesizeSession">6</ng-container></span>
        <span *ngIf="eq5dResponses" class="icon-wrap text-success"
          ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
        ></span>
      
      </span>
      <span class="icon-wrap tracker-icon">
        <img
          src="/assets/img/placeholder-cardio.jpg"
          alt=""
          width="40"
          class="rounded"
        />
      </span>
      <span class="tracker-info">
        <span class="tracker-title">Health Questionnaire</span>

        <div *ngIf="isLoading" class="text-center">
          <app-loading-spinner [inline]="true"></app-loading-spinner>
        </div>
        <span class="details">
          Take the EQ5D health questionnaire.
        </span>
      </span>
      <span class="icon-wrap float-end icon-more"
        ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
      ></span>
    </a>
  </li>
  <!--li *ngIf="currentWeekNumber==reviewWeekNoEarly">
    <a [routerLink]="['/questionnaires', 'eq5d', 4]">
      <span class="icon-wrap tracker-num">
        <span class="num" *ngIf="!eq5dResponses4"><ng-container *ngIf="currentBitesizeSession">7</ng-container><ng-container *ngIf="!currentBitesizeSession">6</ng-container></span>
        <span *ngIf="eq5dResponses4" class="icon-wrap text-success"
          ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
        ></span>
      
      </span>
      <span class="icon-wrap tracker-icon">
        <img
          src="/assets/img/placeholder-cardio.jpg"
          alt=""
          width="40"
          class="rounded"
        />
      </span>
      <span class="tracker-info">
        <span class="tracker-title">Health Questionnaire</span>

        <div *ngIf="isLoading" class="text-center">
          <app-loading-spinner [inline]="true"></app-loading-spinner>
        </div>
        <span class="details">
          Take the EQ5D health questionnaire.
        </span>
      </span>
      <span class="icon-wrap float-end icon-more"
        ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
      ></span>
    </a>
  </li-->
  <li *ngIf="currentWeekNumber==reviewWeekNo || (currentWeekNumber > reviewWeekNo && (!feedbackResponses || feedbackCompletedThisWeek))">
    <a [routerLink]="['/feedback']">
      <span class="icon-wrap tracker-num">
        <span class="num" *ngIf="!feedbackResponses"><ng-container *ngIf="currentBitesizeSession">8</ng-container><ng-container *ngIf="!currentBitesizeSession">7</ng-container></span>
        <span *ngIf="feedbackResponses" class="icon-wrap text-success"
          ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
        ></span>
      
      </span>
      <span class="icon-wrap tracker-icon">
        <img
          src="/assets/img/placeholder-cardio.jpg"
          alt=""
          width="40"
          class="rounded"
        />
      </span>
      <span class="tracker-info">
        <span class="tracker-title">Feedback</span>

        <div *ngIf="isLoading" class="text-center">
          <app-loading-spinner [inline]="true"></app-loading-spinner>
        </div>
        <span class="details">
          Take feedback questionnaire.
        </span>
      </span>
      <span class="icon-wrap float-end icon-more"
        ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
      ></span>
    </a>
  </li>
</ul>
</div>