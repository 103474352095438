<div [ngClass]="{'has-sidebar': hasSidebar}">
    <div class="container footer mt-3  d-none d-md-block">
      <div class="row mt-3 mb-3">
        <div class="col">
          © 2021 Hark 2. All rights reserved.
        </div>
        <div class="col text-end">
          <a [routerLink]="['/terms-and-conditions']">Terms &amp; Conditions</a> |
          <a [routerLink]="['/privacy-policy']">Privacy Policy</a>
        </div>
      </div>
    </div>
    </div>