export class Weight {
    public user_weight_id: any;
    public user_id: any;
    public weight: any;
    public created: any;
    public modified?: any;
  
    constructor(
        user_weight_id: number | undefined,
        user_id:  number | undefined,
        weight:  number | undefined,
        created: any,
        modified?: any
    ) {
      this.user_weight_id = user_weight_id;
      this.user_id = user_id;
      this.weight = weight;
      this.created = created;
      this.modified = modified;
    }
  }
