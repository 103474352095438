<div class="exercise-icon">
  <svg

*ngIf="variant=='outline'"
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 156.81 150.42"
  >
    <defs>
      <style></style>
    </defs>
    <g id="Layer_2-2">
      <g id="Layer_1-2">
        <path
          class="cls-1"
          d="M59.98,76.19l40.36,40.38c2.02,2.13,2.91,2.98,4.25,3.02l35.09-.68c-7.17-7.57-13.24-16.12-18.01-25.39-.99-2.59-2.24-5.97-3.62-9.77l-11.13,4.43c-2.07.75-4.36-.31-5.12-2.39-.72-1.99.23-4.19,2.17-5.03l11.37-4.52c-1.43-4-2.91-8.18-4.35-12.27l-15.34,6.11c-2.07.75-4.36-.31-5.12-2.39-.72-1.99.23-4.19,2.17-5.03l15.65-6.23c-2.43-6.91-4.51-12.93-5.64-16.2-.81-2.7-3.07-4.71-5.85-5.21-1.56-.21-3.12.34-4.21,1.47-1.87,1.87-3.56,3.44-4.88,4.6-2.54,2.25-5.8,3.51-9.19,3.55l-7.33.13c-2.86.03-5.66-.78-8.06-2.33l-3.22,33.74h.01Z"
        />
        <path
          class="cls-1"
          d="M104.75,127.56h-.22c-3.96-.3-7.6-2.3-9.96-5.5l-.69-.72L14.17,41.64l-3.48,3.48c-3.61,3.61-3.61,9.46,0,13.08l81.77,81.76c1.55,1.56,3.65,2.44,5.85,2.47h39.96c5.08.34,9.6-3.2,10.49-8.21.37-2.62-.28-5.28-1.81-7.44l-42.19.79h-.01Z"
        />
        <path
          class="cls-1"
          d="M52.66,68.87l3.96-42.22-.12-12.58c-.05-3.39-2.84-6.09-6.22-6.04-1.59.02-3.12.67-4.24,1.79h0l-26.23,26.19,32.86,32.87h0Z"
        />
        <path
          class="cls-2"
          d="M151.55,119.82c-.06-.06-.1-.13-.15-.19-8.97-8.51-16.54-18.38-22.43-29.24-4.69-12.28-15.54-43.48-18.75-52.75-1.82-5.58-6.63-9.65-12.43-10.51-3.95-.52-7.92.83-10.73,3.66-1.81,1.81-3.38,3.26-4.61,4.35-1.12.98-2.55,1.53-4.04,1.54l-7.33.13c-3.49.06-6.37-2.73-6.43-6.22v-.05l-.18-16.59C64.39,6.16,58-.08,50.21,0c-3.69.04-7.21,1.52-9.82,4.13L5.05,39.48c-6.73,6.72-6.73,17.63,0,24.35h0l78.6,78.6H4.18c-2.2,0-3.99,1.79-3.99,3.99s1.79,3.99,3.99,3.99h134.32c8.96.24,16.71-6.19,18.13-15.03.8-5.57-1-11.21-4.89-15.28-.07-.09-.12-.19-.2-.27h0ZM71.22,44.78l7.33-.13c3.39-.05,6.65-1.31,9.19-3.55,1.32-1.16,3.01-2.73,4.88-4.6,1.09-1.13,2.65-1.67,4.21-1.47,2.77.5,5.03,2.51,5.85,5.21,1.13,3.27,3.19,9.27,5.64,16.2l-15.65,6.23c-2.02.88-2.96,3.23-2.08,5.25.84,1.94,3.04,2.89,5.03,2.17l15.34-6.11c1.44,4.09,2.92,8.27,4.35,12.27l-11.37,4.52c-2.02.88-2.96,3.23-2.08,5.25.84,1.94,3.04,2.89,5.03,2.17l11.13-4.43c1.38,3.8,2.63,7.18,3.62,9.77,4.78,9.27,10.84,17.82,18.01,25.39l-35.09.68c-1.34-.04-2.23-.9-4.25-3.02l-40.36-40.38,3.19-33.74c2.41,1.56,5.22,2.37,8.09,2.33h0ZM46.03,9.77h0c2.4-2.39,6.28-2.39,8.68,0,1.13,1.13,1.77,2.65,1.79,4.24l.13,12.6-3.97,42.22L19.8,36l26.23-26.23ZM148.75,134.21c-.89,5.02-5.42,8.57-10.51,8.23h-39.96c-2.2-.03-4.3-.92-5.85-2.47L10.68,58.19c-3.61-3.61-3.61-9.46,0-13.08l3.48-3.48,79.71,79.71.69.72c2.36,3.19,6,5.2,9.96,5.5h.22l42.19-.81c1.54,2.16,2.18,4.84,1.81,7.46h.01Z"
        />
        <path
          class="cls-2"
          d="M4.18,131.07h40.16c2.2,0,3.99-1.79,3.99-3.99s-1.79-3.99-3.99-3.99H4.18c-2.2,0-3.99,1.79-3.99,3.99s1.79,3.99,3.99,3.99Z"
        />
      </g>
    </g>
  </svg>

<svg
*ngIf="variant=='solid'"
    id="Layer_2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 156.81 150.42"
  >
    <defs>
      <style>
        .cls-1 {
          fill: none;
        }
        .cls-2 {
          fill: #fff;
        }
      </style>
    </defs>
    <g id="Layer_2-2">
      <g id="Layer_1-2">
        <path
          class="cls-1"
          d="M59.98,76.19l40.36,40.38c2.02,2.13,2.91,2.98,4.25,3.02l35.09-.68c-7.17-7.57-13.24-16.12-18.01-25.39-.99-2.59-2.24-5.97-3.62-9.77l-11.13,4.43c-2.07.75-4.36-.31-5.12-2.39-.72-1.99.23-4.19,2.17-5.03l11.37-4.52c-1.43-4-2.91-8.18-4.35-12.27l-15.34,6.11c-2.07.75-4.36-.31-5.12-2.39-.72-1.99.23-4.19,2.17-5.03l15.65-6.23c-2.43-6.91-4.51-12.93-5.64-16.2-.81-2.7-3.07-4.71-5.85-5.21-1.56-.21-3.12.34-4.21,1.47-1.87,1.87-3.56,3.44-4.88,4.6-2.54,2.25-5.8,3.51-9.19,3.55l-7.33.13c-2.86.03-5.66-.78-8.06-2.33l-3.22,33.74h.01Z"
        />
        <path
          class="cls-1"
          d="M104.75,127.56h-.22c-3.96-.3-7.6-2.3-9.96-5.5l-.69-.72L14.17,41.64l-3.48,3.48c-3.61,3.61-3.61,9.46,0,13.08l81.77,81.76c1.55,1.56,3.65,2.44,5.85,2.47h39.96c5.08.34,9.6-3.2,10.49-8.21.37-2.62-.28-5.28-1.81-7.44l-42.19.79h-.01Z"
        />
        <path
          class="cls-1"
          d="M52.66,68.87l3.96-42.22-.12-12.58c-.05-3.39-2.84-6.09-6.22-6.04-1.59.02-3.12.67-4.24,1.79h0l-26.23,26.19,32.86,32.87h0Z"
        />
        <path
          class="cls-2"
          d="M151.74,120.08c-.07-.09-.12-.19-.2-.27-.06-.06-.1-.13-.15-.19-8.97-8.51-16.54-18.38-22.43-29.24-.97-2.53-2.2-5.87-3.57-9.65l.03.08-18.5,7.36c-2.07.75-4.36-.31-5.12-2.39-.72-1.99.23-4.19,2.17-5.03l18.75-7.45c-.04-.1-.08-.21-.11-.32-.14-.39-.28-.79-.42-1.18-.24-.68-.48-1.35-.72-2.03l-.04-.12-.08-.23c-.52-1.47-1.05-2.96-1.57-4.44-.02-.06-.04-.12-.06-.18-.17-.5-.35-.99-.52-1.48-.27-.76-.53-1.51-.8-2.26,0,0,0-.02-.01-.03l-22.73,9.05c-2.07.75-4.36-.31-5.12-2.39-.72-1.99.23-4.19,2.17-5.03l23.03-9.17.04.12c-2.39-6.84-4.44-12.74-5.56-15.98-1.82-5.58-6.63-9.65-12.43-10.51-3.95-.52-7.92.83-10.73,3.66-1.81,1.81-3.38,3.26-4.61,4.35-1.12.98-2.55,1.53-4.04,1.54l-7.33.13c-3.49.06-6.37-2.73-6.43-6.22v-.05l-.18-16.59C64.39,6.16,58-.08,50.21,0c-3.69.04-7.21,1.52-9.82,4.13L5.05,39.48c-6.73,6.72-6.73,17.63,0,24.35h0l78.6,78.6H4.18c-2.2,0-3.99,1.79-3.99,3.99s1.79,3.99,3.99,3.99h134.32c8.96.24,16.71-6.19,18.13-15.03.8-5.57-1-11.21-4.89-15.28v-.02ZM46.03,9.77c2.4-2.39,6.28-2.39,8.68,0,1.13,1.13,1.77,2.65,1.79,4.24l.13,12.6-3.97,42.22L19.8,36l26.23-26.23ZM148.75,134.21c-.89,5.02-5.42,8.57-10.51,8.23h-39.96c-2.2-.03-4.3-.92-5.85-2.47L10.68,58.19c-3.61-3.61-3.61-9.46,0-13.08l3.48-3.48,79.71,79.71.69.72c2.36,3.19,6,5.2,9.96,5.5h.22l42.19-.81c1.54,2.16,2.18,4.84,1.81,7.46h.01Z"
        />
        <path
          d="M121.53,69.97c-.09-.24-.17-.48-.26-.73l.07.19c.04.11.08.23.12.34l.07.19h0Z"
        />
        <path d="M117.19,57.66l.06.18.02.06c-.03-.08-.06-.17-.09-.25h.01Z" />
        <path
          class="cls-2"
          d="M4.18,131.07h40.16c2.2,0,3.99-1.79,3.99-3.99s-1.79-3.99-3.99-3.99H4.18c-2.2,0-3.99,1.79-3.99,3.99s1.79,3.99,3.99,3.99Z"
        />
      </g>
    </g>
  </svg>
</div>
