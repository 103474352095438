<img
  *ngIf="resource?.image_filename"
  src="{{ resource.image_filename | getThumbnail : 'wide' }}"
  alt="Placeholder image"
  class="img-fluid fade-in d-none d-sm-block"
/>
<img
  *ngIf="resource?.image_filename"
  src="{{ resource.image_filename | getThumbnail : 'medium-landscape' }}"
  alt="Placeholder image"
  class="img-fluid fade-in d-block d-sm-none"
/>
<app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
<div class="main-content-area sub-listing">
  <ul class="tracker-list">
    <li *ngFor="let page of pages">
      <a
        [routerLink]="[
          '/resources',
          page.content_section_id,
          page.content_page_id
        ]"
      >
        <span class="icon-wrap tracker-icon">
          <img
            src="{{ page.image_filename | getThumbnail : 'medium-landscape' }}"
            alt=""
            width="60"
            class="rounded"
          />
        </span>
        <span class="tracker-info">
          <span class="tracker-title">{{ page.title }}</span>
          <span class="details" *ngIf="page.content.content"
            >{{ resourcesService.getReadingTime(page.content.content) }} min
            read</span
          >
          <span class="details" *ngIf="!page.content.content"
            >{{ resourcesService.getReadingTime(page.content) }} min read</span
          >
        </span>
        <span class="icon-wrap float-end icon-more"
          ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
        ></span>
      </a>
    </li>
  </ul>
</div>