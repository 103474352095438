<div class="symptom-feedback">
  <span *ngIf="+previousScore > +currentScore">
    <p class="symptom-feedback-title">
      <span class="text-success">{{ +currentScore - +previousScore }}</span>
      decrease since your previous entry.
    </p>
  </span>

  <span *ngIf="+previousScore < +currentScore">
    <p class="symptom-feedback-title">
      <span class="text-danger">+{{ +currentScore - +previousScore }}</span>
      increase since your previous entry.
    </p>
  </span>
  <span *ngIf="+previousScore === +currentScore">
    <p class="symptom-feedback-title">
      This symptom has remained the same since the previous entry.
    </p>
  </span>

  <span class="symptom-feedback-info" *ngIf="+currentScore > 3">
    <ng-container [ngSwitch]="symptom">
      <ng-container *ngSwitchCase="'cough'">
        <p>
          You have recorded that your cough has worsened lately. Coughing
          excessively, especially over long periods of time, can cause damage to
          the airways and be tiring. You may find the section on
          <a [routerLink]="['/resources', '16']">Managing Your Cough</a> helpful
          as there are useful tips and advice on how to control your cough and
          coughing effectively.
        </p>
        <p>
          If there is anything you need to discuss, please contact your local
          Health Care Professional by telephone or via the
          <a [routerLink]="['/chat', 'healthcare-professional']"
            >Ask the Health Care Professional</a
          >
          link.
        </p>
        <p *ngIf="diagnosisMode != 'covid'">
          Please refer to your
          <a [routerLink]="['/action-plan']">Action Plan</a> to check if you are
          having a flare up (exacerbation) and what action may be needed.
        </p>
      </ng-container>
      <ng-container *ngSwitchCase="'ischaemic_pain'">
        <p>You have recorded that your chest pain is worse lately. Please refer to your Action Plan to check what action may be needed as you are having a change in symptoms.</p>
        <p class="mb-0">
          If there is anything you need to discuss, please contact your local
          Health Care Professional by telephone or via the
          <a [routerLink]="['/chat', 'healthcare-professional']"
            >Ask the Health Care Professional</a
          >
          link
        </p>
      </ng-container>
      <ng-container *ngSwitchCase="'phlegm'">
        <p>
          You have recorded that your phlegm has changed recently either in
          volume, colour or consistency. Changes in your phlegm can indicate
          that you may have an infection and need antibiotics. If you feel
          unwell, follow your
          <a [routerLink]="['/action-plan']">Action Plan</a>. You may find the
          section on
          <a [routerLink]="['/resources', '16']">Managing Your Cough</a>
          helpful as there are useful tips and advice on clearing your chest
          effectively.
        </p>
        <p>
          If there is anything you need to discuss, please contact your local
          Health Care Professional by telephone or via the
          <a [routerLink]="['/chat', 'healthcare-professional']"
            >Ask the Health Care Professional</a
          >
          link
        </p>
      </ng-container>
      <ng-container *ngSwitchCase="'chest_tightness'">
        <p>
          You have recorded that your chest feels tighter lately; you may feel
          you are more wheezy. If you feel unwell, follow your
          <a [routerLink]="['/action-plan']">Action Plan</a>.
          <ng-container
            *ngIf="
              diagnosisMode == 'respiratory' &&
              userData &&
              userData.primary_diagnosis &&
              exacerbationsResourceId[userData.primary_diagnosis]
            "
            >You may find the section on
            <a
              [routerLink]="[
                '/resources',
                exacerbationsResourceId[userData.primary_diagnosis]
              ]"
              >Managing Exacerbations</a
            >
            helpful as there are useful tips and advice on how to manage these
            flare ups.
          </ng-container>
        </p>
        <p>
          If there is anything you need to discuss, please contact your local
          Health Care Professional by telephone or via the
          <a [routerLink]="['/chat', 'healthcare-professional']"
            >Ask the Health Care Professional</a
          >
          link
        </p>
      </ng-container>
      <ng-container *ngSwitchCase="'sob'">
        <ng-container
          *ngIf="diagnosisMode == 'covid' || diagnosisMode == 'respiratory'"
        >
          <p>
            You have recorded that your breathlessness is worse lately.
            <ng-container *ngIf="diagnosisMode != 'covid'"
              >If you feel unwell, follow your
              <a [routerLink]="['/action-plan']">Action Plan</a>.</ng-container
            >
            You may find the section on
            <a [routerLink]="['/resources', '7']"> Managing Breathlessness</a>
            helpful as there are useful techniques on how to control your
            breathing, and advice on relaxation and positioning. If there is
            anything you need to discuss, please contact your local Health Care
            Professional by telephone or via the
            <a [routerLink]="['/chat', 'healthcare-professional']"
              >Ask the Health Care Professional</a
            >
            link
          </p>
        </ng-container>
        <ng-container *ngIf="diagnosisMode == 'cardiac'">
          <p>
            You have recorded that your breathlessness is worse lately. Please
            refer to your Action Plan to check what action may be needed as you
            are having a change in symptoms. You may find the section on
            <a [routerLink]="['/resources', '7']"> Managing Breathlessness</a>
            helpful as there are useful techniques on how to control your
            breathing, and advice on relaxation and positioning. If there is
            anything you need to discuss, please contact your local Health Care
            Professional by telephone or via the
            <a [routerLink]="['/chat', 'healthcare-professional']"
              >Ask the Health Care Professional</a
            >
            link
          </p>
        </ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'energy'">
        <!-- fatigue for covid -->

        <ng-container *ngIf="diagnosisMode == 'respiratory'">
          <p>
            You have recorded that your energy levels are low lately. You may
            find the sections on
            <a [routerLink]="['/resources', '19']">Managing my day</a>,
            <a [routerLink]="['/resources', '17']">Fear &amp; Anxiety</a>,
            <a [routerLink]="['/resources', '2']">Sleeping well</a> and
            <a [routerLink]="['/resources', '4']">Healthy Eating</a> helpful as
            there are useful advice on conserving energy, having a healthy
            balanced diet and sleeping well. There is also a bitesize on Stress
            & Anxiety.
          </p>
          <p>
            If there is anything you need to discuss, please contact your local
            Health Care Professional by telephone or via the
            <a [routerLink]="['/chat', 'healthcare-professional']"
              >Ask the Health Care Professional</a
            >
            link
          </p>
        </ng-container>

        <ng-container *ngIf="diagnosisMode == 'cardiac'">
          <p>
            You have recorded that your energy levels are low lately. Please
            refer to your Action Plan to check what action may be needed as you
            are having a change in symptoms. You may find the sections on
            <a [routerLink]="['/resources', '19']">Managing my day</a>,
            <a [routerLink]="['/resources', '17']">Fear &amp; Anxiety</a>,
            <a [routerLink]="['/resources', '2']">Sleeping well</a> and
            <a [routerLink]="['/resources', '4']">Healthy Eating</a> helpful as
            there are useful advice on conserving energy, having a healthy
            balanced diet and sleeping well.
          </p>

          <p>
            If there is anything you need to discuss, please contact your local
            Health Care Professional by telephone or via the
            <a [routerLink]="['/chat', 'healthcare-professional']"
              >Ask the Health Care Professional</a
            >
            link
          </p>
        </ng-container>
        <ng-container *ngIf="diagnosisMode == 'covid'">
          <p>
            You have recorded that your fatigue has worsened lately. You may
            find the section on
            <a [routerLink]="['/resources', '19']">Managing my day</a>,
            <a [routerLink]="['/resources', '17']">Fear &amp; Anxiety</a>,
            <a [routerLink]="['/resources', '2']">Sleeping well</a> and
            <a [routerLink]="['/resources', '4']">Healthy Eating</a> helpful as
            there are useful advice on conserving energy, having a healthy
            balanced diet and sleeping well.
          </p>
          <p *ngIf="diagnosisMode == 'covid'">
            If there is anything you need to discuss, please contact your local
            Health Care Professional by telephone or via the
            <a [routerLink]="['/chat', 'healthcare-professional']"
              >Ask the Health Care Professional</a
            >
            link.
          </p></ng-container
        >
      </ng-container>

      <ng-container *ngSwitchCase="'fatigue'">
        <p>
          You have recorded that your fatigue has worsened lately. You may find
          the section on
          <a [routerLink]="['/resources', '19']">Managing my day</a>,
          <a [routerLink]="['/resources', '17']">Fear &amp; Anxiety</a>,
          <a [routerLink]="['/resources', '2']">Sleeping well</a> and
          <a [routerLink]="['/resources', '4']">Healthy Eating</a> helpful as
          there are useful advice on conserving energy, having a healthy
          balanced diet and sleeping well.
        </p>
        <p>
          If there is anything you need to discuss, please contact your local
          Health Care Professional by telephone or via the
          <a [routerLink]="['/chat', 'healthcare-professional']"
            >Ask the Health Care Professional</a
          >
          link.
        </p>
      </ng-container>
      <ng-container *ngSwitchCase="'memory'">
        <p>
          You have recorded that your memory is worse at the moment. You may
          find the section on
          <a [routerLink]="['/resources', '22']">Memory and Thinking</a> helpful
          as there are useful tips and advice which may help you to remember
          things.
        </p>
        <p>
          If there is anything you need to discuss, please contact your local
          Health Care Professional by telephone or via the
          <a [routerLink]="['/chat', 'healthcare-professional']"
            >Ask the Health Care Professional</a
          >
          link
        </p>
      </ng-container>

      <ng-container *ngSwitchCase="'anxiety'">
        <p>
          You have recorded that you are more anxious lately. You may find the
          sections on
          <a [routerLink]="['/resources', '17']">Fear &amp; Anxiety</a> and
          <a [routerLink]="['/resources', '2']">Sleeping well</a> helpful as
          there are useful advice on how to deal with anxiety and worry.
        </p>
        <p>There is also a bitesize information on Stress and anxiety</p>
        <p>
          If there is anything you need to discuss, please contact your local
          Health Care Professional by telephone or via the
          <a [routerLink]="['/chat', 'healthcare-professional']"
            >Ask the Health Care Professional</a
          >
          link
        </p>
      </ng-container>

      <ng-container *ngSwitchCase="'activity'">
        <ng-container *ngIf="diagnosisMode != 'cardiac'">
          <p>
            You have recorded that you are finding it more difficult to do your
            activities of daily living. You may find the section on
            <a [routerLink]="['/resources', '19']">Managing my day</a> helpful
            as there is useful advice on conserving energy when carrying out
            tasks.
          </p>
          <p>
            If there is anything you need to discuss, please contact your local
            Health Care Professional by telephone or via the
            <a [routerLink]="['/chat', 'healthcare-professional']"
              >Ask the Health Care Professional</a
            >
            link
          </p>

          <p *ngIf="diagnosisMode != 'covid'">
            Please refer to your
            <a [routerLink]="['/action-plan']">Action Plan</a> to check if you
            are having a flare up (exacerbation) and what action may be needed.
          </p>
        </ng-container>

        <ng-container *ngIf="diagnosisMode == 'cardiac'">
          <p>
            You have recorded that you are finding it more difficult to do your
            activities of daily living. Please refer to your Action Plan to
            check what action may be needed as you are having a change in
            symptoms. You may find the section on
            <a [routerLink]="['/resources', '19']">Managing my day</a> helpful
            as there is useful advice on conserving energy when carrying out
            tasks.
          </p>
          <p>
            If there is anything you need to discuss, please contact your local
            Health Care Professional by telephone or via the
            <a [routerLink]="['/chat', 'healthcare-professional']"
              >Ask the Health Care Professional</a
            >
            link
          </p>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'sleep'">
        <p>
          You have recorded that you are not sleeping well lately. You may find
          the sections on
          <a [routerLink]="['/resources', '19']">Managing my day</a>,
          <a [routerLink]="['/resources', '17']">Managing Fear &amp; Anxiety</a>
          and <a [routerLink]="['/resources', '2']">Sleeping well</a> helpful as
          there are useful advice on conserving energy and sleeping well.
        </p>
        <p>
          If there is anything you need to discuss, please contact your local
          Health Care Professional by telephone or via the
          <a [routerLink]="['/chat', 'healthcare-professional']"
            >Ask the Health Care Professional</a
          >
          link
        </p>
      </ng-container>
      <ng-container *ngSwitchCase="'confidence'">
        <p>
          You have recorded that you are finding it difficult to cope with
          everyday life and activities. You may find the sections on
          <a [routerLink]="['/resources', '19']">Managing my day </a> &amp;
          <a [routerLink]="['/resources', '17']">Managing Fear &amp; Anxiety</a>
          helpful as well as the bitesize information on Stress and Anxiety.
        </p>
        <p>
          If there is anything you need to discuss, please contact your local
          Health Care Professional by telephone or via the
          <a [routerLink]="['/chat', 'healthcare-professional']"
            >Ask the Health Care Professional</a
          >
          link
        </p>
      </ng-container>
      <ng-container *ngSwitchCase="'mood'">
        <p>
          You have recorded that your mood is low lately. You may find the
          section on
          <a [routerLink]="['/resources', '17']">Fear &amp; Anxiety</a> helpful
          as there are useful tips and advice which may help you to lift your
          mood. There is also a bitesize on Low Mood.
        </p>
        <p>
          If there is anything you need to discuss, please contact your local
          Health Care Professional by telephone or via the
          <a [routerLink]="['/chat', 'healthcare-professional']"
            >Ask the Health Care Professional</a
          >
          link
        </p>
      </ng-container>
      <ng-container *ngSwitchCase="'appetite'">
        <p>
          You have recorded that your appetite is worse lately. You may find the
          section on
          <a [routerLink]="['/resources', '4']">Healthy Eating</a> helpful as
          there are useful tips and advice which may help you to regain your
          appetite.
        </p>
        <p>
          If there is anything you need to discuss, please contact your local
          Health Care Professional by telephone or via the
          <a [routerLink]="['/chat', 'healthcare-professional']"
            >Ask the Health Care Professional</a
          >
          link
        </p>
      </ng-container>
      <ng-container *ngSwitchCase="'ankle_swelling'">
        <p>
          You have recorded that the swelling in your ankles has got worse
          lately. Please refer to your Action Plan to check what action may be
          needed as you are having a change in symptoms.
        </p>
        <p>
          If there is anything you need to discuss, please contact your local
          Health Care Professional by telephone or via the
          <a [routerLink]="['/chat', 'healthcare-professional']"
            >Ask the Health Care Professional</a
          >
          link
        </p>
      </ng-container>
      <ng-container *ngSwitchCase="'surgery_pain'">
        <p>
          You have recorded that you are getting pain over the area where you
          had surgery is worse lately.
        </p>
        <p>
          If there is anything you need to discuss, please contact your local
          Health Care Professional by telephone or via the
          <a [routerLink]="['/chat', 'healthcare-professional']"
            >Ask the Health Care Professional</a
          >
          link
        </p>
      </ng-container>
    </ng-container>
  </span>
</div>
