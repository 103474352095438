import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {
  gettingStarted = false;
  currentYear: number = new Date().getFullYear();

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
    if(this.router.url == '/getting-started/terms'){
      this.gettingStarted = true;
    }
  }

}