<div class="bg-light">
  <p *ngIf="notifications.length == 0" class="mx-auto p-4 text-center">No notifications found</p>
  <ul class="tracker-list tracker-list-padded" *ngIf="notifications.length > 0">
    <li
      *ngFor="let notification of notifications"
      [ngClass]="{ new: notification.status == 0 }"
    >
      <a (click)="onPressNotification(notification)">
        <span class="icon-wrap tracker-icon">
          <fa-icon
            [icon]="['fal', 'medal']"
            *ngIf="notification.notification_type == 'achievement'"
          ></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-title">{{ notification.title }}</span>

          <span class="details">{{ notification.text }}</span>
        </span>
      </a>
    </li>
  </ul>
</div>
