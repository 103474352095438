import { Component, OnInit } from '@angular/core';
import { ResourcesService } from '../services/resources.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { UserTaskStatusService } from '../services/user-task-status.service';
import { UserService } from '../auth/service/user.service';
import { AchievementService } from '../services/achievement.service';

@Component({
  selector: 'app-reading-list',
  templateUrl: './reading-list.component.html',
  styleUrls: ['./reading-list.component.scss'],
})
export class ReadingListComponent implements OnInit {
  resources: any;
  isLoading = false;
  taskCompleted = false;
  weeklyTaskCompleted = false;
  userData: any;
  currentWeekNumber: any;
  constructor(
    private resourcesService: ResourcesService,
    private router: Router,
    private userTaskStatusService: UserTaskStatusService,
    private userService: UserService,
    private achievementService: AchievementService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
        this.currentWeekNumber = +userData.current_week;
      }
    });

    this.userTaskStatusService.fetchAll().subscribe((responseData) => {
      responseData.forEach((userTaskStatus) => {
        if (userTaskStatus.task_id == 'readingList') {
          this.taskCompleted = true;
        }
        if (userTaskStatus.task_id == 'readingList' + this.currentWeekNumber) {
          this.weeklyTaskCompleted = true;
        }
      });

      if (!this.taskCompleted) {
        this.userTaskStatusService
          .create('readingList', 1)
          .subscribe((responseData) => {});
      }
    });

    this.resourcesService.fetchReadingList().subscribe((responseData) => {
      this.isLoading = false;
      this.resources = responseData[0];
      // calculate the reading times
      for (let [key, value] of Object.entries(this.resources)) {
        this.resources[key].forEach((resource: any, index: number) => {
          this.resources[key][index].readingTime = 0;
          resource.pages.forEach((page: any) => {
            this.resources[key][index].readingTime +=
              this.resourcesService.getReadingTime(page.content);
          });
        });
      }
    });
  }

  getResources(key: any) {
    let resources: any = [];
    resources = this.resources[key];
    return resources;
  }

  ngOnDestroy() {
    if (!this.taskCompleted) {
   
    }

    if (!this.weeklyTaskCompleted) {
      this.userTaskStatusService
        .create('readingList' + this.currentWeekNumber, 1)
        .subscribe((responseData) => {});
    }
  }
}
