export class ActionPlan {
  constructor(
    public action_plan_id: number,
    public user_id: number,
    public cough: number,
    public cough_productive: number,
    public breathlessness_rest: number,
    public breathlessness: number,
    public wheeze: number,
    public sputum_colour: number,
    public sputum_clear_total: number,
    public sputum_thickness: number,
    public sputum_clear_difficulty: number,
    public nasal_discharge: number,
    public sore_throat: number,
    public swollen_ankles: number,
    public weight_change: number,
    public chest_pain: number,
    public palpitations: number,
    public dizziness: number,
    public nausea: number,
    public created: string,
    public modified: string
  ) {}
}
