<app-header-bar [title]="'Please select an option...'"></app-header-bar>
<div class="container  content-wrap">
  <ul class="tracker-list tracker-list-no-bt">
    <li>
      <a [routerLink]="['/chat', 'healthcare-professional']">
        <span class="icon-wrap tracker-icon">
          <fa-icon
            [icon]="['fal', 'user-md-chat']"
            [fixedWidth]="true"
          ></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link"
            >Ask a Healthcare Professional
            <span class="icon-wrap float-end icon-more"
            ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
          ></span></span>

          <span class="details"
            >Get advice from a healthcare professional.</span
          >
        </span>
      </a>
    </li>
    <!--li>
      <a [routerLink]="['/chat', 'forum']">
        <span class="icon-wrap tracker-icon">
          <fa-icon
            [icon]="['fal', 'comment-alt-lines']"
            [fixedWidth]="true"
          ></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link"
            >Forum
            <span class="icon-wrap float-end icon-more"
            ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
          ></span></span>

          <span class="details"
            >Chat with other people using the Impact programme.</span
          >
        </span>
      </a>
    </li-->
    <li>
      <a [routerLink]="['/chat', 'support']">
        <span class="icon-wrap tracker-icon">
          <fa-icon [icon]="['fal', 'headset']" [fixedWidth]="true"></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link"
            >Technical support
            <span class="icon-wrap float-end icon-more"
          ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
        ></span></span>

          <span class="details"
            >Contact us if you are having trouble using the programme.</span
          >
        </span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/more', 'app']">
        <span class="icon-wrap tracker-icon">
          <fa-icon [icon]="['fal', 'mobile']" [fixedWidth]="true"></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link"
            >Install Web App
            <span class="icon-wrap float-end icon-more"
              ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon></span
          ></span>

          <span class="details">How to as an app on your device.</span>
        </span>
      </a>
    </li>
  </ul>
</div>
