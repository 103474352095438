import { Component, OnInit } from '@angular/core';
import { UserService } from '../auth/service/user.service';
import { AuthService } from '../auth/service/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.authService.user.subscribe((user) => {
      if (user) {
        if (this.authService.redirect) {
          this.router.navigateByUrl(this.authService.redirect);
        } else {
          this.router.navigate(['/dashboard']);
        }
      }
    });
  }
}
