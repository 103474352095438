export class StrengthWorkout {
  constructor(
    public strength_workout_id: number,
    public user_id: number,
    public workout_date: string,
    public exercises: string,
    public created: string,
    public modified: string
  ) {}
}
