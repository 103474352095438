<div class="dashboard-masthead-container" *ngIf="show">
    <div class="dashboard-masthead dashboard-masthead-strength">
      <div class="dashboard-masthead-content">
        <div class="dashboard-masthead-content-inner">
          <h2>Hit your Strength Exercise Target</h2>
          <div class="dashboard-masthead-subtitle">{{ 5 - strengthDaysHit}} more days needed</div>
          <div class="week-bar">
            <div class="entry-days">
              <div
                class="entry-day"
                *ngFor="let day of currentWeekData"
                [ngClass]="{
                  complete: day.exercises
                }"
              >
                <div
                  class="entry-day-label"
                  *ngIf="!day.exercises"
                >
                  {{ day.date | date : "EE" | slice : 0 : 1 }}
                </div>
  
                <div
                  class=""
                  *ngIf="day.date <= today"
                  [ngClass]="{
                    complete: day.exercises
                  }"
                >
                  <span
                    class="icon-wrap"
                    *ngIf="day.exercises"
                  >
                    <fa-icon [icon]="['fas', 'check']"></fa-icon>
                  </span>
                  
                </div>
              </div>
            </div>
          </div>
  
          <div class="dashboard-masthead-subtext">
            Do 5 strength workouts this week.
          </div>
        </div>
      </div>
    </div>
  </div>
  