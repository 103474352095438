<div *ngIf="isLoading" style="text-align: center">
    <app-loading-spinner></app-loading-spinner>
  </div>
  <div *ngIf="!isLoading">
    <div class="container-fluid content-container fh login">
      <h2>Authenticator</h2>
  
      <div *ngIf="testSite">
        <p class="lead text-center">
          This is a test environment for training purposes.
        </p>
      </div>
      <div class="row">
        <div class="col-lg-4 offset-lg-4 col-sm-6 offset-sm-3">
          <div class="alert alert-danger" *ngIf="error">
            <p>{{ error }}</p>
          </div>
          <form
            #authForm="ngForm"
            (ngSubmit)="onSubmit(authForm)"
            *ngIf="!isLoading"
          >
          <p>Please enter the code from the authenticator app</p>
            <div class="form-group">
              <label for="email" class="sr-only">Code</label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text"
                    ><fa-icon
                      [icon]="['fal', 'user']"
                      [fixedWidth]="true"
                    ></fa-icon
                  ></span>
                </div>
                <input
                  type="number"
                  id="code"
                  class="form-control"
                  ngModel
                  name="code"
                  placeholder="000000"
                  required
                  email
                  autocomplete="off"
                />
              </div>
            </div>
            
  
            <button
              type="submit"
              [disabled]="!authForm.valid"
              class="btn btn-primary w-100 w-100 btn-lg"
            >
              Confirm
            </button>
            <hr />
            
          </form>
        </div>
      </div>
      <div class="container-fluid mt-5 inline-footer">
        <div class="row mt-3 mb-3">
          <div class="col-lg-4 offset-lg-4 col-sm-6 offset-sm-3">
            <div class="row">
              <div class="col">© 2022 Hark 2. <br />All rights reserved.</div>
              <div class="col text-end">
                <a [routerLink]="['/terms-and-conditions']"
                  >Terms &amp; Conditions</a
                >
                <br />
                <a [routerLink]="['/privacy-policy']">Privacy Policy</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  