import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-risk-factors',
  templateUrl: './risk-factors.component.html',
  styleUrls: ['./risk-factors.component.scss'],
})
export class RiskFactorsComponent implements OnInit {
  @ViewChild('form', { static: false }) rfForm?: NgForm;

  completed = false;
  score = 0;

  riskFactorsScores: any = {
    smoking: null,
    diabetes: null,
    obesity: null,
    bloodPressure: null,
    activity: null,
    depression: null,
    cholesterol: null,
  };
  constructor() {}

  ngOnInit(): void {}

  onSubmit() {
    // smoking
    if (this.rfForm?.value.smoking == 1) {
      this.riskFactorsScores.smoking = 'red';
    } else {
      this.riskFactorsScores.smoking = 'green';
    }

    // diabetes HbA1c: below 5.9% 	HbA1c: 5.9% to 6.5% 	HbA1c: over 6.5%
    if (+this.rfForm?.value.diabetes > 6.5) {
      this.riskFactorsScores.diabetes = 'red';
    } else if (+this.rfForm?.value.diabetes < 5.9) {
      this.riskFactorsScores.diabetes = 'green';
    } else {
      this.riskFactorsScores.diabetes = 'amber';
    }

    // BMI: 20 to 24.9 	BMI: 25 to 29.9 	BMI: over 30
    if (+this.rfForm?.value.bmi > 30) {
      this.riskFactorsScores.bmi = 'red';
    } else if (+this.rfForm?.value.bmi <= 30 && +this.rfForm?.value.bmi >= 25) {
      this.riskFactorsScores.bmi = 'amber';
    } else {
      this.riskFactorsScores.bmi = 'green';
    }

    // "Systolic: below 120mmhg Diastolic: below 80mmhg "	"Systolic: 120 to 139mmhg Diastolic: 80 to 89mmhg "	"Systolic: above 140mmhg Diastolic: above 90mmhg "
    if (
      +this.rfForm?.value.bloodPressureSys < 120 &&
      +this.rfForm?.value.bloodPressureDia < 80
    ) {
      this.riskFactorsScores.bloodPressure = 'green';
    } else if (
      +this.rfForm?.value.bloodPressureSys >= 120 &&
      +this.rfForm?.value.bloodPressureSys < 140 &&
      +this.rfForm?.value.bloodPressureDia >= 80 &&
      +this.rfForm?.value.bloodPressureDia < 90
    ) {
      this.riskFactorsScores.bloodPressure = 'amber';
    } else if (
      +this.rfForm?.value.bloodPressureSys > 140 &&
      +this.rfForm?.value.bloodPressureDia > 90
    ) {
      this.riskFactorsScores.bloodPressure = 'red';
    }

    //Very active 	Light to moderate activity 	Inactive
    if (+this.rfForm?.value.activity == 1) {
      this.riskFactorsScores.activity = 'red';
    } else if (+this.rfForm?.value.activity == 4) {
      this.riskFactorsScores.activity = 'green';
    } else {
      this.riskFactorsScores.activity = 'amber';
    }

    //Depression "Not clinically depressed "	Mildly depressed 	Clinically depressed
    if (+this.rfForm?.value.depression == 1) {
      this.riskFactorsScores.depression = 'green';
    } else if (+this.rfForm?.value.depression == 2) {
      this.riskFactorsScores.depression = 'amber';
    } else {
      this.riskFactorsScores.depression = 'red';
    }

    //"LDL: Below 2.6mmol/L /Cholesterol / HDL ratio: below 4//Triglycerides: below 1.1mmol/L "
    // "LDL: 2.6 to 3.3mmol/L Cholesterol / HDL ratio: 4 to 6 Triglycerides: 1.1 to 1.7 mmol/L "
    // "LDL: above  3.4 mmol/L Cholesterol / HDL ratio: above 6 Triglycerides: above 1.7 mmol/L "

    if (
      +this.rfForm?.value.ldl < 2.6 &&
      +this.rfForm?.value.ratio < 4 &&
      +this.rfForm?.value.trig < 1.1
    ) {
      this.riskFactorsScores.cholesterol = 'green';
    } else if (
      (+this.rfForm?.value.ldl >= 2.6 &&+this.rfForm?.value.ldl <= 3.3) ||
      (+this.rfForm?.value.ratio >= 4 && +this.rfForm?.value.ratio <= 6) ||
      +this.rfForm?.value.trig >= 1.1 && +this.rfForm?.value.trig <= 1.7
    ) {
      this.riskFactorsScores.cholesterol = 'amber';
    } else if (
      +this.rfForm?.value.ldl > 3.3 ||
      +this.rfForm?.value.ratio > 6  ||
      +this.rfForm?.value.trig > 1.7
    )  {
      this.riskFactorsScores.cholesterol = 'red';
    }
  }
}
