<div class="main-content-area sub-page pt-3">
  <div class="">
    <!--div class="row">
      <div class="col-md-6">
        <div class="card bg-light">
          <div class="card-body">
            <h2 class="card-title text-start">
              <span class="icon-wrap text-secondary-accent">
                <fa-icon [icon]="['fas', 'star']"></fa-icon>
              </span>
              Current Goals
            </h2>
            <div *ngIf="isLoading" class="text-center">
              <app-loading-spinner [inline]="true"></app-loading-spinner>
            </div>
            <div *ngIf="!isLoading">
             
              <div class="p-3">

              <swiper
                [config]="config"
                (swiper)="onSwiper($event)"
                (slideChange)="onSlideChange()"
                *ngIf="openGoals.length > 0"
                class="swiper"
              >
                <div *ngFor="let goal of openGoals" class="swiper-slide">
                  <div class="goal-content">
                    <a class="goal-checkbox" (click)="onCompleteGoal(goal)">
                      <span class="icon-wrap">
                        <fa-icon [icon]="['fas', 'check']"></fa-icon>
                      </span>
                    </a>
                    <div class="goal-text">
                      {{ goal.what }}
                    </div>
                  </div>
                </div>
              </swiper>
            </div>
              <div
                class="swiper-slide"
                *ngIf="openGoals.length === 0 && allGoals.length > 0"
              >
            No goals currently open.  Please add a new one.
              </div>
              <div class="swiper-slide" *ngIf="allGoals.length === 0">
                Please set a goal to begin
              </div>
            </div>
          </div>
        </div>
  
        <a
          [routerLink]="['/goals', 'add']"
          class="btn btn-primary  w-100 mt-3 mb-3"
          >
         Add a goal</a
        >
      </div>
  
      <div class="col-md-6">
        <div class="card  bg-light">
          <div class="card-body">
            <h2 class="card-title text-start">
              <span class="icon-wrap text-secondary-accent">
                <fa-icon [icon]="['fas', 'star']"></fa-icon>
              </span>
              Summary
            </h2>
            <div *ngIf="isLoading" class="text-center">
              <app-loading-spinner [inline]="true"></app-loading-spinner>
            </div>
            <div *ngIf="!isLoading">
              <div class="row" *ngIf="allGoals">
                <div class="col-6 mb-3">
                  <div class="status-badge">
                    <div class="status-badge-icon">
                      <span class="icon-wrap">
                        <fa-icon [icon]="['far', 'stars']"></fa-icon>
                      </span>
                    </div>
                    <div class="status-badge-text">
                      <div class="status-badge-title">
                        Goals Created</div>
                      <div class="status-badge-status">{{ allGoals.length }}</div>
                    </div>
                  </div>
                </div>
                <div class="col-6 mb-3">
                  <div class="status-badge">
                    <div class="status-badge-icon">
                      <span class="icon-wrap">
                        <fa-icon [icon]="['far', 'check']"></fa-icon>
                      </span>
                    </div>
                    <div class="status-badge-text">
                      <div class="status-badge-title">
                        Goals Achieved</div>
                      <div class="status-badge-status">
                        {{ completeGoals.length }}
                      </div>
                    </div>
                  </div>
                </div>
  
                <div class="col-6 mb-3" *ngIf="quickestGoal">
                  <div class="status-badge">
                    <div class="status-badge-icon">
                      <span class="icon-wrap">
                        <fa-icon [icon]="['far', 'calendar-alt']"></fa-icon>
                      </span>
                    </div>
                    <div class="status-badge-text">
                      <div class="status-badge-title">
                        Quickest Goal</div>
                      <div class="status-badge-status">
                        <div *ngIf="quickestGoal !== -1">
                          {{ quickestGoal
                          }}<span>
                           
                          Days</span
                          >
                        </div>
                        <div *ngIf="quickestGoal === -1">
                         -
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
  
                <div class="col-6 mb-3">
                  <div class="status-badge">
                    <div class="status-badge-icon">
                      <span class="icon-wrap">
                        <fa-icon [icon]="['far', 'arrows-h']"></fa-icon>
                      </span>
                    </div>
                    <div class="status-badge-text">
                      <div class="status-badge-title">
                        Longest Streak </div>
                      <div class="status-badge-status">
                        {{ goalStreak
                        }}<span>
                          Weeks
                          
                          </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div-->

    <div class="row">
      <div class="col">
        <div class="flex-tabs-container nav-tabs-dark-blue">
          <tabset #goalTabs [justified]="true">
            <tab>
              <ng-template tabHeading> All Goals</ng-template>

              <!--ul class="task-list task-list-goals mt-3">
                <li *ngFor="let goal of allGoals">
                  <a [routerLink]="['/goals', 'update', goal.goal_id]">
                    <span class="num">
                      <span class="icon-wrap" *ngIf="goal.status == 1">
                        <fa-icon [icon]="['far', 'circle']"></fa-icon>
                      </span>
                      <span
                        class="icon-wrap text-secondary"
                        *ngIf="goal.status == 2"
                      >
                        <fa-icon [icon]="['fas', 'check-circle']"></fa-icon>
                      </span>
                    </span>
                    <span class="task">
                      <span class="title">
                        {{ goal.what }}​<br />
                        <span class="subtitle"
                          >Review: {{ goal.start | date: "EEE d MMM" }} |
                          {{ goal.progress }}% complete</span
                        >
                      </span>
                      <span class="status">
                        <span class="icon-wrap float-end icon-more"
                          ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
                        ></span>
                      </span>
                    </span>
                  </a>
                </li>
              </ul-->
              <table class="table-workouts-flex mt-2 mb-4">
                <tbody>
                  <ng-container *ngFor="let goal of allGoals">
                    <tr>
                      <td class="position-relative">
                        <div class="text-start flex-goal-status">
                          <span class="icon-wrap text-warning" *ngIf="goal.status == 1 &&  goal.start >= now && goal.status">
                            <fa-icon [icon]="['fas', 'clock']"></fa-icon>
                          </span>
                          <span class="icon-wrap text-danger" *ngIf="goal.status == 1  && goal.start < now && goal.status">
                            <fa-icon [icon]="['fas', 'circle-exclamation']"></fa-icon>
                          </span>
                          <span
                            class="icon-wrap text-success"
                            *ngIf="goal.status == 2"
                          >
                            <fa-icon [icon]="['fas', 'check-circle']"></fa-icon>
                          </span>
                        </div>
                        <!--div class="flex-workout-date">
                          {{ goal.start | customDate :"ddd Do"}}
                        </div-->
                        <div class="text-start flex-workout-type">
                          <div>{{ goal.what }}</div>
                          <div
                            class="goal-review-date"
                            [ngClass]="{
                              'text-danger':
                                goal.start < now && goal.status == 1
                            }"
                          >
                            <ng-container *ngIf="goal.status==1"> Review on:  {{ goal.start | customDate : "ddd Do MMM" }}</ng-container> <ng-container *ngIf="goal.status==2"> Completed:  {{ goal.modified | customDate : "ddd Do MMM" }}</ng-container>
                          </div>
                        </div>

                        <a
                          class="flex-workout-link stretched-link"
                          [routerLink]="['/goals', 'update', goal.goal_id]"
                          ><span aria-label="Edit Workout">
                            <fa-icon
                              [icon]="['fal', 'arrow-right']"
                            ></fa-icon></span
                        ></a>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>

              <p *ngIf="allGoals.length < 1">No goals found</p>
            </tab>
            <tab>
              <ng-template tabHeading> Open </ng-template>

              <!--ul class="task-list task-list-goals mt-3">
                <li *ngFor="let goal of openGoals">
                  <a [routerLink]="['/goals', 'update', goal.goal_id]">
                    <span class="num">
                      <span class="icon-wrap" *ngIf="goal.status == 1">
                        <fa-icon [icon]="['far', 'circle']"></fa-icon>
                      </span>
                      <span
                        class="icon-wrap text-secondary"
                        *ngIf="goal.status == 2"
                      >
                        <fa-icon [icon]="['fas', 'check-circle']"></fa-icon>
                      </span>
                    </span>
                    <span class="task">
                      <span class="title">
                        {{ goal.what }}​<br />
                        <span class="subtitle"
                          >Review: {{ goal.start | date : "EEE d MMM" }} |
                          {{ goal.progress }}% complete</span
                        >
                      </span>
                      <span class="status">
                        <span class="icon-wrap float-end icon-more"
                          ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
                        ></span>
                      </span>
                    </span>
                  </a>
                </li>
              </ul-->
              <table class="table-workouts-flex mt-2 mb-4">
                <tbody>
                  <ng-container *ngFor="let goal of openGoals">
                    <tr>
                      <td class="position-relative">
                        <div class="text-start flex-goal-status">
                          <span class="icon-wrap text-warning" *ngIf="goal.status == 1 &&  goal.start >= now && goal.status">
                            <fa-icon [icon]="['fas', 'clock']"></fa-icon>
                          </span>
                          <span class="icon-wrap text-danger" *ngIf="goal.status == 1  && goal.start < now && goal.status">
                            <fa-icon [icon]="['fas', 'circle-exclamation']"></fa-icon>
                          </span>
                          <span
                            class="icon-wrap text-success"
                            *ngIf="goal.status == 2"
                          >
                            <fa-icon [icon]="['fas', 'check-circle']"></fa-icon>
                          </span>
                        </div>
                        <!--div class="flex-workout-date">
                          {{ goal.start | customDate :"ddd Do"}}
                        </div-->
                        <div class="text-start flex-workout-type">
                          <div>{{ goal.what }}</div>
                          <div
                            class="goal-review-date"
                            [ngClass]="{
                              'text-danger':
                                goal.start < now && goal.status == 1
                            }"
                          >
                            <ng-container *ngIf="goal.status==1"> Review on:  {{ goal.start | customDate : "ddd Do MMM" }}</ng-container> <ng-container *ngIf="goal.status==2"> Completed:  {{ goal.modified | customDate : "ddd Do MMM" }}</ng-container>
                          </div>
                        </div>

                        <a
                          class="flex-workout-link stretched-link"
                          [routerLink]="['/goals', 'update', goal.goal_id]"
                          ><span aria-label="Edit Workout">
                            <fa-icon
                              [icon]="['fal', 'arrow-right']"
                            ></fa-icon></span
                        ></a>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <p *ngIf="openGoals.length < 1">No open goals found</p>
            </tab>
            <tab>
              <ng-template tabHeading> Achieved </ng-template>
              <!--ul class="task-list task-list-goals mt-3">
                <li *ngFor="let goal of completeGoals">
                  <a [routerLink]="['/goals', 'update', goal.goal_id]">
                    <span class="num">
                      <span class="icon-wrap" *ngIf="goal.status == 1">
                        <fa-icon [icon]="['far', 'circle']"></fa-icon>
                      </span>
                      <span
                        class="icon-wrap text-success"
                        *ngIf="goal.status == 2"
                      >
                        <fa-icon [icon]="['fas', 'check-circle']"></fa-icon>
                      </span>
                    </span>
                    <span class="task">
                      <span class="title">
                        {{ goal.what }}​<br />
                        <span class="subtitle"
                          >Review: {{ goal.start | date : "EEE d MMM" }} |
                          {{ goal.progress }}% complete</span
                        >
                      </span>
                      <span class="status">
                        <span class="icon-wrap float-end icon-more"
                          ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
                        ></span>
                      </span>
                    </span>
                  </a>
                </li>
              </ul-->
              <table class="table-workouts-flex mt-2 mb-4">
                <tbody>
                  <ng-container *ngFor="let goal of completeGoals">
                    <tr>
                      <td class="position-relative">
                        <div class="text-start flex-goal-status">
                          <span class="icon-wrap text-warning" *ngIf="goal.status == 1 &&  goal.start >= now && goal.status">
                            <fa-icon [icon]="['fas', 'clock']"></fa-icon>
                          </span>
                          <span class="icon-wrap text-danger" *ngIf="goal.status == 1  && goal.start < now && goal.status">
                            <fa-icon [icon]="['fas', 'circle-exclamation']"></fa-icon>
                          </span>
                          <span
                            class="icon-wrap text-success"
                            *ngIf="goal.status == 2"
                          >
                            <fa-icon [icon]="['fas', 'check-circle']"></fa-icon>
                          </span>
                        </div>
                        <!--div class="flex-workout-date">
                          {{ goal.start | customDate :"ddd Do"}}
                        </div-->
                        <div class="text-start flex-workout-type">
                          <div>{{ goal.what }}</div>
                          <div
                            class="goal-review-date"
                            [ngClass]="{
                              'text-danger':
                                goal.start < now && goal.status == 1
                            }"
                          >
                            <ng-container *ngIf="goal.status==1"> Review on:  {{ goal.start | customDate : "ddd Do MMM" }}</ng-container> <ng-container *ngIf="goal.status==2"> Completed:  {{ goal.modified | customDate : "ddd Do MMM" }}</ng-container>
                          </div>
                        </div>

                        <a
                          class="flex-workout-link stretched-link"
                          [routerLink]="['/goals', 'update', goal.goal_id]"
                          ><span aria-label="Edit Workout">
                            <fa-icon
                              [icon]="['fal', 'arrow-right']"
                            ></fa-icon></span
                        ></a>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
              <p *ngIf="completeGoals.length < 1">No achieved goals found</p>
            </tab>
          </tabset>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container mt-3">
  <div class="row" *ngIf="allGoals">
    <div class="col-6 mb-3">
      <div class="status-badge">
        <div class="status-badge-icon"></div>
        <div class="status-badge-text">
          <div class="status-badge-title">Goals Created</div>
          <div class="status-badge-status">{{ allGoals.length }}</div>
        </div>
      </div>
    </div>
    <div class="col-6 mb-3">
      <div class="status-badge">
        <div class="status-badge-icon"></div>
        <div class="status-badge-text">
          <div class="status-badge-title">Goals Achieved</div>
          <div class="status-badge-status">{{ completeGoals.length }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <div class="more-resources p-0">
        <ul class="tracker-list ">
          <li>
            <a class="task" [routerLink]="['/goals', 'about']">
              <span class="tracker-info">
                <span class="tracker-title">Read about Goal Setting</span>
              </span>
              <span class="icon-wrap float-end icon-more"
                ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon>
              </span>
            </a>
          </li>
          <li>
            <a class="task" [routerLink]="['/goals', 'managing']">
              <span class="tracker-info">
                <span class="tracker-title">Read about how to manage expectations</span>
              </span>
              <span class="icon-wrap float-end icon-more"
                ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon>
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="row d-md-block d-none mt-2">
    <div class="col text-center">
      <button class="btn btn-primary" [routerLink]="['/goals', 'add']">
        <span class="icon-wrap"
          ><fa-icon [icon]="['fal', 'circle-plus']"></fa-icon
        ></span>
        Add a Goal
      </button>
    </div>
  </div>

      <button class="btn btn-primary d-md-none sticky-button" [routerLink]="['/goals', 'add']">
        <span class="icon-wrap"
          ><fa-icon [icon]="['fal', 'circle-plus']"></fa-icon
        ></span>
        Add a Goal
      </button>
</div>
