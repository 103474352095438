import { DatePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { UserService } from 'src/app/auth/service/user.service';
import { ActionPlanService } from 'src/app/services/action-plan.service';
import { UserTaskStatusService } from 'src/app/services/user-task-status.service';
import Swal from 'sweetalert2/src/sweetalert2.js';

@Component({
  selector: 'app-action-plan-setup',
  templateUrl: './action-plan-setup.component.html',
  styleUrls: ['./action-plan-setup.component.scss'],
})
export class ActionPlanSetupComponent implements OnInit {
  @ViewChild('form', { static: false }) entryForm!: NgForm;
  symptomData = {
    cough: '',
    cough_productive: '',
    breathlessness_rest: '',
    breathlessness: '',
    wheeze: '',
    sputum_colour: '',
    sputum_clear_total:'',
    sputum_thickness:'',
    sputum_clear_difficulty: '',
    nasal_discharge: '',
    sore_throat: '',
    swollen_ankles: '',
    weight_change: '',
    chest_pain: '',
    palpitations: '',
    dizziness: '',
    nausea: '',
  };
  public symptoms: any = [];
  isLoading = false;
  error = null;
  private errorSub!: Subscription;
  public userSymptomId = null;
  public symptom: any;
  submitted = false;
  taskComplete = false;
  gettingStarted = false;
  currentYear: number = new Date().getFullYear();

  diagnosisMode: any;
  userData: any;

  constructor(
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private el: ElementRef,
    private actionPlanService: ActionPlanService,
    private userTaskStatusService: UserTaskStatusService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    if(this.router.url == '/getting-started/action-plan'){
      this.gettingStarted = true;
    }
    // get diagnosis mode

    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
        if (this.userData.primary_diagnosis) {
          this.diagnosisMode = this.userService.getDiagnosisMode(this.userData.primary_diagnosis);
        }
      }
    });

    

    this.userTaskStatusService.fetchAll().subscribe((responseData) => {
      responseData.forEach((task) => {
        if (task.task_id == 'createActionPlan' && task.status == 1) {
          this.taskComplete = true;
        //  this.router.navigate(['/dashboard']);
        }
      });
    });
  }

  onSubmit() {
    this.submitted = true;
    if (!this.entryForm?.valid) {
      Swal.fire({
        icon: 'error',
        title: 'The form is invalid',
        text: 'Please check the form for errors',
        showConfirmButton: true,
        didClose: () => this.scrollToFirstInvalidControl(),
      });
    } else {
      // create entry
      const resourceIds = [];
      this.actionPlanService
        .create(
          this.entryForm?.value.cough,
          this.entryForm?.value.cough_productive,
          this.entryForm?.value.breathlessness_rest,
          this.entryForm?.value.breathlessness,
          this.entryForm?.value.wheeze,
          this.entryForm?.value.sputum_colour,
          this.entryForm?.value.sputum_clear_total,
          this.entryForm?.value.sputum_thickness,
          this.entryForm?.value.sputum_clear_difficulty,
          this.entryForm?.value.nasal_discharge,
          this.entryForm?.value.sore_throat,
          this.entryForm?.value.swollen_ankles,
          this.entryForm?.value.weight_change,
          this.entryForm?.value.chest_pain,
          this.entryForm?.value.palpitations,
          this.entryForm?.value.dizziness,
          this.entryForm?.value.nausea
        )
        .subscribe(
          (responseData) => {
            if (!this.taskComplete) {
              this.userTaskStatusService
                .create('createActionPlan', 1)
                .subscribe((responseData) => {
                  Swal.fire(
                    'Task Complete',
                    "You completed the 'Create Action Plan' task, well done! You can now start the programme.",
                    'success'
                  );
                  this.router.navigate(['/action-plan']);
                });
            } else {
              Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Action plan setup complete',
                showConfirmButton: false,
                timer: 2000,
                timerProgressBar: true,
                toast: true,
              });
              this.router.navigate(['/action-plan']);
              this.entryForm?.reset();
            }
          },
          (error) => {
            this.error = error.message;
          }
        );
    }
  }

  onSkip() {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you have an action plan already',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, I have an action plan',
    }).then((result) => {
      if (result.value) {
        this.userTaskStatusService
          .create('createActionPlan', 1)
          .subscribe((responseData) => {
            Swal.fire(
              'Task Complete',
              "You completed the 'Create Action Plan' task, well done! You can now start the programme.",
              'success'
            );
            this.router.navigate(['/action-plan']);
          });
      }
    });
  }

  public scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement =
      this.el.nativeElement.querySelector('form .ng-invalid');

    if (firstInvalidControl) {
      firstInvalidControl.focus();
      window.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  public getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 150;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<any> | any {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away

    if (this.submitted) {
      return true;
    } else if (
      !this.entryForm?.value.breathlessness_rest &&
      !this.entryForm?.value.breathlessness
    ) {
      return true;
    } else {
      return false;
    }
  }
}
