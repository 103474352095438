import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-fear-anxiety-symptom-checker',
  templateUrl: './fear-anxiety-symptom-checker.component.html',
  styleUrls: ['./fear-anxiety-symptom-checker.component.scss']
})
export class FearAnxietySymptomCheckerComponent implements OnInit {

  picked:any = [];
  completed = false;
  symptoms = [
    {value: 1, label: 'Worrying about physical symptoms'},
    {value: 2, label: 'Excessive worrying about anything and everything'},
    {value: 3, label: 'Suddenly experiencing strong feelings of fear and/or panic'},
    {value: 4, label: 'Distressing memories'},
    {value: 5, label: 'Spending a lot of time searching for information about symptoms and/or posting about symptoms on social media'},
    {value: 6, label: 'Frequently checking physical symptoms e.g. O2 levels.'},
    {value: 7, label: 'Frequent distressing dreams / nightmares '},
    {value: 8, label: 'Difficulty getting off to sleep or frequently waking in the night'}
  ];

  @ViewChild('form', { static: false }) checkerForm?: NgForm;
  constructor() { }

  ngOnInit(): void {
  }


  onPick(event:any, symptomId: number) {
    if (event.target.checked) {
      if (this.picked.indexOf(symptomId) < 0) {
        this.picked.push(symptomId);
      }
    } else {
      if (this.picked.indexOf(symptomId) > -1) {
        this.picked.splice(this.picked.indexOf(symptomId), 1);
      }
    }
  }

  onSubmit(){
    this.completed = true;
  }

}
