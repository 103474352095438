import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  error = new Subject<string>();

  constructor(private http: HttpClient) {}

  fetchResponse() {

    return this.http
      .get<any>(environment.apiUrl + '/feedback_responses', {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          if (responseData['_embedded'].feedback_responses[0]) {
            return responseData['_embedded'].feedback_responses[0];
          } else {
            return null;
          }
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
  saveResponse(
    q1:any,
    q2:any,
    q3:any,
    q4:any,
    q4_extra:any,
    q5:any,
    q6:any,
    q7:any,
    q8:any,
    q9:any,
  ) {
    const postData = {
      q1,
      q2,
      q3,
      q4,
      q4_extra,
      q5,
      q6,
      q7,
      q8,
      q9
    };
    return this.http.post<any>(
      environment.apiUrl + '/feedback_responses',
      postData,
      {
        observe: 'response',
      }
    );
  }
}
