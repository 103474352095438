import { Subscription } from 'rxjs';
import { UserTarget } from './../../../models/user-target.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { UserTargetService } from 'src/app/services/user-target.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-weight-target',
  templateUrl: './weight-target.component.html',
  styleUrls: ['./weight-target.component.scss']
})
export class WeightTargetComponent implements OnInit {
  public target?: UserTarget;
  isFetching = false;
  error = null;
  private errorSub?: Subscription;

  @ViewChild('form', { static: false }) weightForm?: NgForm;

  constructor(
    private targetService: UserTargetService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.targetService.fetchAll().subscribe((targets) => {
      this.target = this.targetService.getLatestTarget(targets, 'weight');
    });
  }

  onSubmit() {
    this.targetService
      .create('weight', this.weightForm?.value.weightInput, 'kg')
      .subscribe(
        (responseData) => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Target Set',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            toast: true,
          });
          this.router.navigate(['/trackers', 'weight']);
        },
        (error) => {
          this.error = error.message;
        }
      );
  }

}
