import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Weight } from 'src/app/models/weight.model';
import { WeightService } from 'src/app/services/weight.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-weight-update',
  templateUrl: './weight-update.component.html',
  styleUrls: ['./weight-update.component.scss']
})
export class WeightUpdateComponent implements OnInit {
  public weight?: Weight;
  isFetching = false;
  error = null;
  private errorSub?: Subscription;
  public weightId :any;

  @ViewChild('form', { static: false }) weightForm?: NgForm;

  constructor(
    private weightService: WeightService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isFetching = true;
    this.weightId = this.route.snapshot.params.id;
    this.weightService.fetch(this.weightId).subscribe(
      (weight) => {
        this.weight = weight;
        this.isFetching = false;
      },
      (error) => {
        this.isFetching = false;
        this.error = error.message;
      }
    );
  }

  onSubmit() {
    this.weightService
      .update(this.weightId, this.weightForm?.value.weightInput)
      .subscribe(
        (responseData) => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Weight updated',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            toast: true,
          });
          this.router.navigate(['/trackers', 'weight']);
        },
        (error) => {
          this.error = error.message;
        }
      );
  }

  onDelete() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to undo this",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it',
    }).then((result) => {
      if (result.value) {
      this.weightService.delete(this.weightId).subscribe(
        (responseData) => {
          Swal.fire('Deleted', 'Your record has been deleted.', 'success');
          this.router.navigate(['/trackers', 'weight']);
        },
        (error) => {
          this.isFetching = false;
          this.error = error.message;
          Swal.fire('Deleted!', 'There was an error.', 'error');
        });
      }
    });
  }
}
