import { Component, OnInit } from '@angular/core';
import { ResourcesService } from '../services/resources.service';
import { BitesizeSessionService } from '../services/bitesize-session.service';
import { BitesizeSessionViewService } from '../services/bitesize-session-view.service';
import { UserService } from '../auth/service/user.service';
import { forkJoin } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss'],
})
export class ResourcesComponent implements OnInit {
  resources: any;
  readingLists: any;
  isLoading = false;
  bitesizeSessions!: any;
  currentWeekNumber!: any;
  actualWeekNumber!: any;
  userData: any;
  bitesizeSessionViews: any;
  activeTab: any = null;
  constructor(
    private resourcesService: ResourcesService,
    private bitesizeSessionService: BitesizeSessionService,
    public bitesizeSessionViewService: BitesizeSessionViewService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    private _location: Location
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.route.data.subscribe((data) => {
      if (data.tab) {
        if (data.tab == 'reading-list') {
          this.activeTab = 1;
        }
        if (data.tab == 'bitesize') {
          this.activeTab = 2;
        }
      } else {
        this.activeTab = 0;
      }
    });

    this.resourcesService.fetchAll().subscribe((responseData) => {
      this.isLoading = false;
      this.resources = responseData;
      // calculate the reading times
      this.resources.forEach((resource: any, index: number) => {
        this.resources[index].readingTime = 0;
        resource.pages.forEach((page: any) => {
          this.resources[index].readingTime +=
            this.resourcesService.getReadingTime(page.content);
        });
      });

      this.resourcesService.fetchReadingList().subscribe((responseData) => {
        this.isLoading = false;
        this.readingLists = responseData[0];
        // calculate the reading times
        for (let [key, value] of Object.entries(this.readingLists)) {
          this.readingLists[key].forEach((resource: any, index: number) => {
            this.readingLists[key][index].readingTime = 0;
            resource.pages.forEach((page: any) => {
              this.readingLists[key][index].readingTime +=
                this.resourcesService.getReadingTime(page.content);
            });
          });
        }
      });

      this.userService.userData.subscribe((userData: any) => {
        if (userData != null) {
          this.userData = userData;

          this.currentWeekNumber = +userData.current_week;
          this.actualWeekNumber = +userData.current_week;

          /*
          this.bitesizeSessionService
            .fetchAll()
            .subscribe((bitesizeResponseData) => {
              this.bitesizeSessions = bitesizeResponseData;
              if (userData.smoker != 1) {
                // remove the smoking one
                let smokeIndex = -1;
                this.bitesizeSessions.forEach(
                  (_session: any, index: number) => {
                    if (_session.bitesize_session_id == 1) {
                      smokeIndex = index;
                    }
                  }
                );
                if (smokeIndex != -1) {
                  this.bitesizeSessions.splice(smokeIndex, 1);
                }
              }
            });
            */

          forkJoin({
            bitesizeSessions: this.bitesizeSessionService.fetchAll(),
            bitesizeSessionViews: this.bitesizeSessionViewService.fetchAll(),
            // targets: this.userTargetService.fetchAll(),
          }).subscribe((responseData) => {
            this.isLoading = false;

            // bitesize sessions
            this.bitesizeSessions = responseData.bitesizeSessions;
            if (this.userData.smoker != 1) {
              // remove the smoking one
              let smokeIndex = -1;
              this.bitesizeSessions.forEach((_session: any, index: number) => {
                if (_session.bitesize_session_id == 1) {
                  smokeIndex = index;
                }
              });
              if (smokeIndex != -1) {
                this.bitesizeSessions.splice(smokeIndex, 1);
              }
            }

            // console.log(this.bitesizeSessions);

            let bitesizeWeek = +this.currentWeekNumber;
            if (this.userData.smoker == 1) {
              bitesizeWeek++;
            }

            this.bitesizeSessionViews = responseData.bitesizeSessionViews;
          });
        }
      });
    });
  }

  getReadingLists(key: any) {
    let resources: any = [];
    resources = this.readingLists[key];
    return resources;
  }

  getTotalBitesize() {
    //if (this.userData?.smoker == 1) {
    return this.bitesizeSessions.length;
    // } else {
    //return this.bitesizeSessions.length - 1;
    // }
  }

  public changeRoute(item: string) {
    if (item == 'reading-list') {
      this._location.go('/resources/reading-list');
    }
    else if (item == 'general') {
      this._location.go('/resources');
    }
    else if (item == 'bitesize') {
      this._location.go('/resources/bitesize');
    }
  }
}
