<div class="bg-light fh">
  <p class="px-3 pt-3 text-center" *ngIf="userData">
    This reading list is based on your primary <ng-container *ngIf="userData.secondary_diagnosis">and secondary</ng-container> diagnosis
  </p>
  <div class="container  relative">
    <app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
    <div class="row mt-3">
      <div class="col-12"  *ngFor="let _resources of resources | keyvalue">
        <p class="px-3 pt-3 text-center">{{_resources.key}}
        </p>
        <ul
          class="tracker-list tracker-list-padded"
        >
          <li *ngFor="let resource of getResources(_resources.key)">
            <a
              class="resource-nav-item"
              [routerLink]="['/resources', resource.content_section_id]"
            >
              <span class="icon-wrap tracker-icon">
                <img
                  src="{{
                    resource.image_filename | getThumbnail : 'medium-landscape'
                  }}"
                  alt=""
                  width="60"
                  class="rounded"
                />
              </span>

              <span class="tracker-info text-start">
                <span class="tracker-title"> {{ resource.title }}</span>

                <span class="details"
                  >{{ resource.pages?.length }} article<ng-container
                    *ngIf="resource.pages?.length != 1"
                    >s</ng-container
                  >
                  | {{ resource.readingTime }} minute read</span
                >
              </span>
              <span class="icon-wrap float-end icon-more"
                ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
              ></span>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="text-center">
      <button class="btn btn-primary" [routerLink]="['/dashboard']">Back to Dashboard</button>
    </div>

  </div>
</div>
