import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  gettingStarted = false;
  currentYear: number = new Date().getFullYear();

  constructor(
    private router: Router,
  ) { }

  ngOnInit(): void {
    if(this.router.url == '/getting-started/privacy'){
      this.gettingStarted = true;
    }
  }

}
