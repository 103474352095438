import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import * as moment from 'moment';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Symptoms } from '../models/symptoms.model';

@Injectable({
  providedIn: 'root',
})
export class UserSymptomsService {
  error = new Subject<string>();
  slug = '/user_symptoms';
  resourceName = 'user_symptoms';
  allSymptomss?: Observable<Symptoms[]> | null;
  normalSymptomss?: Observable<Symptoms> | undefined | null;

  public symptomsArray = [
    {
      id: 'ischaemic_pain',
      title: 'Chest Pain',
      icon: <IconName>'face-tired',
      iconWeight: <IconPrefix>'far',
    },
    {
      id: 'cough',
      title: 'Cough',
      icon: <IconName>'head-side-cough',
      iconWeight: <IconPrefix>'fal',
    },
    {
      id: 'phlegm',
      title: 'Phlegm',
      icon: <IconName>'tint',
      iconWeight: <IconPrefix>'far',
    },
    {
      id: 'chest_tightness',
      title: 'Chest Tightness',
      icon: <IconName>'lungs',
      iconWeight: <IconPrefix>'far',
    },
    {
      id: 'sob',
      title: 'Shortness of Breath',
      icon: <IconName>'face-exhaling',
      iconWeight: <IconPrefix>'far',
    },
    {
      id: 'ankle_swelling',
      title: 'Ankle Swelling',
      icon: <IconName>'boot-heeled',
      iconWeight: <IconPrefix>'far',
    },
    {
      id: 'activity',
      title: 'Everyday Activity',
      icon: <IconName>'kitchen-set',
      iconWeight: <IconPrefix>'far',
    },
    {
      id: 'confidence',
      title: 'Confidence',
      icon: <IconName>'frown-open',
      iconWeight: <IconPrefix>'far',
    },
    {
      id: 'anxiety',
      title: 'Anxiety',
      icon: <IconName>'frown-open',
      iconWeight: <IconPrefix>'far',
    },
    {
      id: 'sleep',
      title: 'Sleep',
      icon: <IconName>'snooze',
      iconWeight: <IconPrefix>'fal',
    },
    {
      id: 'energy',
      title: 'Energy',
      icon: <IconName>'couch',
      iconWeight: <IconPrefix>'fal',
    },
    {
      id: 'mood',
      title: 'Mood',
      icon: <IconName>'meh',
      iconWeight: <IconPrefix>'far',
    },
    {
      id: 'appetite',
      title: 'Appetite',
      icon: <IconName>'utensils',
      iconWeight: <IconPrefix>'far',
    },
    {
      id: 'surgery_pain',
      title: 'Post Surgery Pain',
      icon: <IconName>'scalpel',
      iconWeight: <IconPrefix>'far',
    },
    {
      id: 'fatigue',
      title: 'Fatigue',
      icon: <IconName>'couch',
      iconWeight: <IconPrefix>'far',
    },
    {
      id: 'memory',
      title: 'Short Term Memory Loss',
      icon: <IconName>'face-tired',
      iconWeight: <IconPrefix>'far',
    },
  ];

  constructor(private http: HttpClient) {}

  fetchAll(): Observable<Symptoms[]> {
    if (!this.allSymptomss) {
      this.allSymptomss = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: Symptoms[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.allSymptomss;
  }

  getBewteen(
    userSymptoms: Symptoms[],
    startDate: string | Date,
    endDate: string | Date
  ) {
    let entries: Symptoms[] = [];
    userSymptoms.forEach((userSymptom) => {
      if (
        moment(userSymptom.created) >= moment(startDate) &&
        moment(userSymptom.created) <= moment(endDate)
      ) {
        entries.push(userSymptom);
      }
    });
    return entries;
  }

  clearCache() {
    this.allSymptomss = null;
    this.normalSymptomss = null;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const item = new Symptoms(
            responseData.user_symptom_id,
            responseData.user_id,
            responseData.cough,
            responseData.phlegm,
            responseData.chest_tightness,
            responseData.energy,
            responseData.sob,
            responseData.activity,
            responseData.sleep,
            responseData.confidence,
            responseData.mood,
            responseData.appetite,
            responseData.ischaemic_pain,
            responseData.ankle_swelling,
            responseData.surgery_pain,
            responseData.anxiety,
            responseData.fatigue,
            responseData.memory,
            responseData.total,
            responseData.baseline,
            responseData.created,
            responseData.modified
          );
          return item;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(
    cough: number,
    phlegm: number,
    chest_tightness: number,
    energy: number,
    sob: number,
    activity: number,
    sleep: number,
    confidence: number,
    mood: number,
    appetite: number,
    ischaemic_pain: number,
    ankle_swelling: number,
    surgery_pain: number,
    anxiety: number,
    fatigue: number,
    memory: number,
    baseline: number
  ) {
    this.clearCache();
    const payload = {
      cough,
      phlegm,
      chest_tightness,
      energy,
      sob,
      activity,
      sleep,
      confidence,
      mood,
      appetite,
      ischaemic_pain,
      ankle_swelling,
      surgery_pain,
      anxiety,
      fatigue,
      memory,
      baseline,
    };
    return this.http.post<Symptoms>(environment.apiUrl + this.slug, payload, {
      observe: 'response',
    });
  }

  update(
    id: number,
    cough: number,
    phlegm: number,
    chest_tightness: number,
    energy: number,
    sob: number,
    activity: number,
    sleep: number,
    confidence: number,
    mood: number,
    appetite: number,
    ischaemic_pain: number,
    ankle_swelling: number,
    surgery_pain: number,
    anxiety: number,
    fatigue: number,
    memory: number,
    baseline: number
  ) {
    this.clearCache();
    const payload = {
      cough,
      phlegm,
      chest_tightness,
      energy,
      sob,
      activity,
      sleep,
      confidence,
      mood,
      appetite,
      ischaemic_pain,
      ankle_swelling,
      surgery_pain,
      anxiety,
      fatigue,
      memory,
      baseline,
    };
    return this.http.patch<Symptoms>(
      environment.apiUrl + this.slug + '/' + id,
      payload,
      {
        observe: 'response',
      }
    );
  }

  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + '/' + id
    );
  }

  getSymptomScore(data: any, key: any) {
    if (data) {
      if (data[key] != undefined) {
        return data[key];
      } else {
        return;
      }
    } else {
      return;
    }
  }

  getLatestSymptomScore(symptoms: any, symptom: string) {
    let score: any = null;
    let scoreSet = false;
    symptoms.forEach((symptomItem: any) => {
      if (symptomItem[symptom] != undefined && symptomItem[symptom] != null &&!scoreSet) {
        score = symptomItem[symptom];
        scoreSet = true;
      }
    });
    return score;
  }

  checkSymptomForDiagnosis(symptomId: any, diagnosisMode: string) {
    let allowSymptom = true;

    if (
      diagnosisMode == 'cardiac' &&
      (symptomId == 'cough' ||
        symptomId == 'phlegm' ||
        symptomId == 'chest_tightness' ||
        symptomId == 'fatigue' ||
        symptomId == 'memory' ||
        symptomId == 'anxiety')
    ) {
      allowSymptom = false;
    }

    if (
      diagnosisMode == 'respiratory' &&
      (symptomId == 'ischaemic_pain' ||
        symptomId == 'ankle_swelling' ||
        symptomId == 'surgery_pain' ||
        symptomId == 'fatigue' ||
        symptomId == 'memory' ||
        symptomId == 'anxiety')
    ) {
      allowSymptom = false;
    }

    if (
      diagnosisMode == 'covid' &&
      (symptomId == 'ischaemic_pain' ||
        symptomId == 'ankle_swelling' ||
        symptomId == 'surgery_pain' ||
        symptomId == 'phlegm' ||
        symptomId == 'chest_tightness' ||
        symptomId == 'activity' ||
        symptomId == 'confidence' ||
        symptomId == 'energy')
    ) {
      allowSymptom = false;
    }

    return allowSymptom;
  }
}
