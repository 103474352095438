import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/auth/service/user.service';
import { Symptoms } from 'src/app/models/symptoms.model';
import { AchievementService } from 'src/app/services/achievement.service';
import { UserSymptomsService } from 'src/app/services/user-symptoms.service';
import { UserTaskStatusService } from 'src/app/services/user-task-status.service';
import Swal from 'sweetalert2/src/sweetalert2.js';

@Component({
  selector: 'app-health-status-setup',
  templateUrl: './health-status-setup.component.html',
  styleUrls: ['./health-status-setup.component.scss'],
})
export class HealthStatusSetupComponent implements OnInit {
  @ViewChild('form', { static: false }) entryForm!: NgForm;
  symptomData: any = {
    cough: '',
    fatigue: '',
    breathlessness: '',
    sleep: '',
    anxiety: '',
    mood: '',
    memory: '',
    appetite: '',
  };
  public symptoms: Symptoms[] = [];
  isLoading = false;
  error = null;
  private errorSub!: Subscription;
  public userSymptomId!: number;
  public symptom!: Symptoms;

  public diagnosisMode: any;
  public userData: any;
  public taskCompleted = false;
  gettingStarted = false;
  currentYear: number = new Date().getFullYear();

  constructor(
    private userSymptomsService: UserSymptomsService,
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private userTaskStatusService: UserTaskStatusService,
    private achievementService: AchievementService
  ) {}

  ngOnInit(): void {
    if(this.router.url == '/getting-started/health-status'){
      this.gettingStarted = true;
    }
    this.isLoading = true;
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;

        if (this.userData.primary_diagnosis) {
          this.diagnosisMode = this.userService.getDiagnosisMode(
            this.userData.primary_diagnosis
          );
        }
      }
    });

    this.userTaskStatusService.fetchAll().subscribe((responseData) => {
      responseData.forEach((userTaskStatus) => {
        if (userTaskStatus.task_id == 'healthStatusBaseline') {
          this.taskCompleted = true;
        }
      });
    });

    this.userSymptomsService.fetchAll().subscribe(
      (symptoms) => {
        this.isLoading = false;
        this.symptoms = symptoms;

        if (symptoms[0]) {
          this.userSymptomsService.symptomsArray.forEach((symptomItem) => {
            this.symptomData[symptomItem.id] =
              this.userSymptomsService.getSymptomScore(
                symptoms[0],
                symptomItem.id
              );
          });
        }
      },
      (error) => {
        this.isLoading = false;
        this.error = error.message;
      }
    );
  }

  onSubmit() {
    this.addSymptom();
  }

  private addSymptom() {
    this.userSymptomsService
      .create(
        this.entryForm.value.cough,
        this.entryForm.value.phlegm,
        this.entryForm.value.chest_tightness,
        this.entryForm.value.energy,
        this.entryForm.value.sob,
        this.entryForm.value.activity,
        this.entryForm.value.sleep,
        this.entryForm.value.confidence,
        this.entryForm.value.mood,
        this.entryForm.value.appetite,
        this.entryForm.value.ischaemic_pain,
        this.entryForm.value.ankle_swelling,
        this.entryForm.value.surgery_pain,
        this.entryForm.value.anxiety,
        this.entryForm.value.fatigue,
        this.entryForm.value.memory,
        1
      )
      .subscribe(
        (responseData) => {
          if (!this.taskCompleted) {
            this.userTaskStatusService
              .create('healthStatusBaseline', 1)
              .subscribe((responseData) => {
                Swal.fire(
                  'Task Complete',
                  "You completed the 'Set your Health Status' task, well done!",
                  'success'
                );

              this.router.navigate(['/getting-started']);
              });

            this.achievementService.refreshNotifications();
          } else {
            let message = '';
            let lastSymptom;
            if (this.userSymptomId) {
              lastSymptom = this.symptom;
            } else {
              lastSymptom = this.symptoms[0];
            }

            // save the task...

            if (responseData.body) {
              this.router.navigate(['/trackers', 'health-status']);
            } else {
              this.router.navigate(['/trackers', 'health-status']);
            }
          }
        },
        (error) => {
          this.error = error.message;
        }
      );
  }
}
