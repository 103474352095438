import { refCount } from 'rxjs/operators';
import { publishReplay } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserTarget } from './../models/user-target.model';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserTargetService {
  error = new Subject<string>();
  slug = "/user_targets";
  resourceName = "user_targets";
  allTargets?: Observable<UserTarget[]> | null = null;

  constructor(
    private http: HttpClient
  ) {}

  
  fetchAll(): Observable<UserTarget[]> {
    if (!this.allTargets) {
      this.allTargets = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: "json",
        })
        .pipe(
          map((responseData) => {
            const returnArray: UserTarget[] = [];
            responseData["_embedded"][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allTargets;
  }

  clearCache() {
    this.allTargets = null;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + "/" + id, {
        responseType: "json",
      })
      .pipe(
        map((responseData) => {
          const item = new UserTarget(
            +responseData.user_target_id,
            +responseData.user_id,
            responseData.target_type,
            +responseData.target,
            responseData.target_unit,
            responseData.created,
            responseData.modified
          );
          return item;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(
    target_type: string,
    target: number,
    target_unit: string,
  ) {
    this.clearCache();
    const payload = {
      target_type,
      target,
      target_unit,
    };
    return this.http.post<UserTarget>(
      environment.apiUrl + this.slug,
      payload,
      {
        observe: "response",
      }
    );
  }

  update(
    id: number,
    target_type: string,
    target: number,
    target_unit: string,
  ) {
    this.clearCache();
    const payload = {
      target_type,
      target,
      target_unit,
    };
    return this.http.patch<UserTarget>(
      environment.apiUrl + this.slug + "/" + id,
      payload,
      {
        observe: "response",
      }
    );
  }

  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + "/" + id
    );
  }
  
  getLatestTarget(userTargets:UserTarget[], targetType:string):UserTarget | undefined{
    let returnTarget!:UserTarget;
    userTargets.forEach((userTarget:UserTarget) => {
      if(userTarget.target_type === targetType && !returnTarget){
        returnTarget = userTarget;
      }
    });
    return returnTarget;
  }
}