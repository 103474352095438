

<div class="achievement-container-md" *ngIf="achievement.area == 'cardio' && achievement.type=='badge-workouts'">
  <div class="achievement-milestone achievement-milestone-exercise">
    <div class="achievement-header">
      <span class="icon-wrap achievement-icon">
        <fa-icon [icon]="['far', 'person-running']"></fa-icon>
      </span>
      <span class="achievement-icon-main-text">{{achievement.total}}</span>
    </div>
    <div class="achievement-badge-sub-text">
      Cardio<br/>Workouts
    </div>
  </div>
</div>


<div class="achievement-container-md" *ngIf="achievement.area == 'cardio' && achievement.type=='badge-workout-streak'">
  <div class="achievement-streak achievement-streak-exercise">
    <div class="achievement-header">
      <span class="icon-wrap achievement-icon">
        <fa-icon [icon]="['far', 'person-running']"></fa-icon>
      </span>
      <span class="achievement-icon-text">
        <span class="achievement-icon-main-text">{{achievement.total}}</span>
        <span class="achievement-icon-sub-text">DAY</span>
      </span>
    </div>
    <div class="achievement-badge-sub-text">
      Cardio<br/>Streak
    </div>
  </div>
</div>

<div class="achievement-container-md" *ngIf="achievement.area == 'strength' && achievement.type=='badge-workouts'">
  <div class="achievement-milestone achievement-milestone-exercise">
    <div class="achievement-header">
      <span class="icon-wrap achievement-icon">
        <img src="/assets/img/icon-strength-badge.svg" alt="Strength badge" />
      </span>
      <span class="achievement-icon-main-text">{{achievement.total}}</span>
    </div>
    <div class="achievement-badge-sub-text">
      Strength<br/>Workouts
    </div>
  </div>
</div>


<div class="achievement-container-md" *ngIf="achievement.area == 'strength' && achievement.type=='badge-workout-streak'">
  <div class="achievement-streak achievement-streak-exercise">
    <div class="achievement-header">
      <span class="icon-wrap achievement-icon">
        <img src="/assets/img/icon-strength-badge.svg" alt="Strength badge" />
      </span>
      <span class="achievement-icon-text">
        <span class="achievement-icon-main-text">{{achievement.total}}</span>
        <span class="achievement-icon-sub-text">DAY</span>
      </span>
    </div>
    <div class="achievement-badge-sub-text">
      Strength<br/>Streak
    </div>
  </div>
</div>

<div class="achievement-container-md" *ngIf="achievement.area == 'goals' && achievement.type=='badge-milestone'">
  <div class="achievement-milestone achievement-milestone-goals">
    <div class="achievement-header">
      <span class="icon-wrap achievement-icon">
        <fa-icon [icon]="['far', 'bullseye-pointer']"></fa-icon>
      </span>
      <span class="achievement-icon-main-text">{{achievement.total}}</span>
    </div>
    <div class="achievement-badge-sub-text">
      Goals
    </div>
  </div>
</div>


<div class="achievement-container-md" *ngIf="achievement.area == 'programme' && achievement.type=='bitesize-session'">
  <div class="achievement-milestone achievement-milestone-programme">
    <div class="achievement-header">
      <span class="icon-wrap achievement-icon">
        <fa-icon [icon]="['far', 'graduation-cap']"></fa-icon>
      </span>
      <span class="icon-wrap achievement-icon">
        <fa-icon [icon]="['fas', 'check']"></fa-icon>
      </span>
    </div>
    <div class="achievement-badge-sub-text">
      Bitesize<br/>Sessions
    </div>
  </div>
</div>

<div class="achievement-container-md" *ngIf="achievement.area == 'programme' && achievement.type=='getting-started-1'">
  <div class="achievement-milestone achievement-milestone-programme">
    <div class="achievement-header">
      <span class="icon-wrap achievement-icon">
        <fa-icon [icon]="['far', 'graduation-cap']"></fa-icon>
      </span>
      <span class="achievement-icon-main-text">1</span>
    </div>
    <div class="achievement-badge-sub-text">
      Question<br/>Session
    </div>
  </div>
</div>

<div class="achievement-container-md" *ngIf="achievement.area == 'programme' && achievement.type=='getting-started-2'">
  <div class="achievement-milestone achievement-milestone-programme">
    <div class="achievement-header">
      <span class="icon-wrap achievement-icon">
        <fa-icon [icon]="['far', 'graduation-cap']"></fa-icon>
      </span>
      <span class="achievement-icon-main-text">2</span>
    </div>
    <div class="achievement-badge-sub-text">
      Question<br/>Session
    </div>
  </div>
</div>


<div class="achievement-container-md" *ngIf="achievement.area == 'programme' && achievement.type=='safety-quiz'">
  <div class="achievement-milestone achievement-milestone-programme">
    <div class="achievement-header">
      <span class="icon-wrap achievement-icon">
        <fa-icon [icon]="['far', 'graduation-cap']"></fa-icon>
      </span>
      <span class="achievement-icon-main-text">3</span>
    </div>
    <div class="achievement-badge-sub-text">
      Question<br/>Session
    </div>
  </div>
</div>


<div class="achievement-container-md" *ngIf="achievement.area == 'programme' && achievement.type=='feedback-survey'">
  <div class="achievement-milestone achievement-milestone-programme">
    <div class="achievement-header">
      <span class="icon-wrap achievement-icon">
        <fa-icon [icon]="['far', 'comment-smile']"></fa-icon>
      </span>
    </div>
    <div class="achievement-badge-sub-text">
      Feedback<br/>Survey
    </div>
  </div>
</div>

<div class="achievement-container-md"  *ngIf="achievement.area == 'programme' && achievement.type=='week-streak'">
  <div class="achievement-streak achievement-streak-programme">
    <div class="achievement-badge-sub-text">
      WEEK
    </div>
    <div class="achievement-icon-main-text">
      {{achievement.total}}
    </div>
    <div class="icon-wrap achievement-icon">
      <img src="/assets/img/icon-logo-badge.svg" />
    </div>
  </div>
</div>

<!--div
  class="achievement"
  [ngClass]="{
    'rounded-circle':
      achievement.type === 'badge-workouts' ||
      achievement.type === 'badge-goals',
    'achievement-primary': achievement.type === 'week-streak' || achievement.area === 'programme'
  }"
>
  <div class="achievement-bg">
    <span class="icon-wrap">
      <fa-icon
        [icon]="['fas', 'heartbeat']"
        *ngIf="
          achievement.type !== 'badge-goals' &&
          achievement.type !== 'week-streak' &&
          achievement.area !== 'programme'
        "
      ></fa-icon>
      <fa-icon
        [icon]="['fas', 'star']"
        *ngIf="achievement.type === 'badge-goals'"
      ></fa-icon>
      <fa-icon
        [icon]="['fas', 'award']"
        *ngIf=" achievement.area === 'programme'"
      ></fa-icon>
    </span>
  </div>

  <div class="achievement-content" *ngIf="achievement.type === 'week-streak'">
    <div class="achievement-row">
      <div class="achievement-icon"></div>
      <div class="achievement-value">{{ achievement.total }}</div>
    </div>
    <div>
      <div class="achievement-label">
        <span>Week <br />Streak</span>
      </div>
    </div>
  </div>

  <div class="achievement-content" *ngIf="achievement.type !== 'week-streak'">
    <div *ngIf="achievement.type === 'badge-workout-streak'">
      <div class="achievement-row">
        <div class="achievement-icon"></div>
        <div class="achievement-value">{{ achievement.total }}</div>
      </div>
      <div>
        <div class="achievement-label">
          <span *ngIf="achievement.type === 'badge-workout-streak'"
            >{{ achievement.unit }} Streak</span
          >
        </div>
      </div>
    </div>
    <div *ngIf="achievement.type === 'badge-workouts'">
      <div class="achievement-value">{{ achievement.total }}</div>
      <div class="achievement-label">
        <span *ngIf="achievement.type === 'badge-workouts'">Workouts</span>
      </div>
      <div class="achievement-icon"></div>
      <div></div>
    </div>
    <div *ngIf="achievement.type === 'badge-goals'">
      <div class="achievement-row">
        <div class="achievement-icon"></div>
        <div class="achievement-value">{{ achievement.total }}</div>
      </div>
      <div>
        <div class="achievement-label">
          <span>Goals</span>
        </div>
      </div>
    </div>
  </div>
</div-->
