import { GoalsService } from './../goals.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { AuthService } from 'src/app/auth/service/auth.service';
import { Goal } from '../goal.model';
import { UserTaskStatusService } from 'src/app/services/user-task-status.service';

@Component({
  selector: 'app-goal-add',
  templateUrl: './goal-add.component.html',
  styleUrls: ['./goal-add.component.scss'],
})
export class GoalAddComponent implements OnInit {
  @ViewChild('form', { static: false }) goalCreateForm!: NgForm;
  submitted = false;
  error!: string;
  isLoading = false;
  goals!: Goal[];
  taskComplete = false;
  today = new Date();

  constructor(
    private goalsService: GoalsService,
    private router: Router,
    private authService: AuthService,
    private userTaskStatusService: UserTaskStatusService
  ) {}

  ngOnInit(): void {
    this.goalsService.fetchGoals().subscribe((responsData) => {
      this.goals = responsData;
    });
    this.userTaskStatusService.fetchAll().subscribe((responseData) => {
      responseData.forEach((task) => {
        if (task.task_id == 'setGoal' && task.status == 1) {
          this.taskComplete = true;
        }
      });
    });
  }

  onSubmit() {
    this.submitted = true;

    // create goal
    // multiple posts so let's fork join
    const resourceIds = [];
    this.goalsService
      .createGoal(
        null,
        this.goalCreateForm.value.goal.what,
        this.goalCreateForm.value.goal.why,
        this.goalCreateForm.value.goal.how,
        this.goalCreateForm.value.goal.barriers,
        this.goalCreateForm.value.goal.start,
        1,
        1,
        0
      )
      .subscribe(
        (responseData) => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Goal created',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            toast: true,
          });
          this.router.navigate(['/goals']);
        },
        (error) => {
          this.error = error.message;
        }
      );
  }
}
