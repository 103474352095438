<div class="container relative bg-light pt-3">
  <app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>

  <!-- test -->

  <div class="cards-container" *ngIf="symptoms[0]">
    <ng-container *ngFor="let symptomInf of userSymptomsService.symptomsArray">
      <div
        class="card card-symptom w-100"
        *ngIf="checkSymptomForDiagnosis(symptomInf.id)"
      >
        <div class="card-body">
          <span class="icon-wrap text-danger symptom-high-icon" *ngIf="
          getLatestSymptomScore(symptoms, symptomInf.id) >=3 &&
          symptomsBaseline && getLatestSymptomScore(symptoms, symptomInf.id) > symptomsBaseline[symptomInf.id]
          ">
            <fa-icon [icon]="['fas', 'triangle-exclamation']"></fa-icon
              >
          </span>
          <a [routerLink]="['/trackers', 'health-status', symptomInf.id]">
            <div class="card-symptom-title">{{ symptomInf.title }}</div>
            <div class="card-symptom-info">
              <span class="card-symptom-icon"><app-symptom-icon [symptomId]="symptomInf.id"></app-symptom-icon></span>
              <span class="card-symptom-score">
                {{ getLatestSymptomScore(symptoms, symptomInf.id) }}
              </span>
              <span class="icon-wrap card-symptom-arrow"
                ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
              ></span>
            </div>
            <span class="progress progress-symptom">
              <span
                class="progress-bar"
                [class]="
                  getLatestSymptomScore(symptoms, symptomInf.id) | symptomBg : 5
                "
                role="progressbar"
                style="width: {{
                   getLatestSymptomScore(symptoms, symptomInf.id) == 0 ? '100' :
                  (getLatestSymptomScore(symptoms, symptomInf.id) / 5) * 100
                }}%"
                [attr.aria-valuenow]="
                   getLatestSymptomScore(symptoms, symptomInf.id) == 0 ? '100' :
                  (getLatestSymptomScore(symptoms, symptomInf.id) / 5) * 100
                "
                aria-valuemin="0"
                aria-valuemax="100" 
                [attr.aria-label]=" getLatestSymptomScore(symptoms, symptomInf.id) == 0 ? '100' :
                (getLatestSymptomScore(symptoms, symptomInf.id) / 5) * 100"
              ></span>
              <span class="line"></span><span class="line"></span
              ><span class="line"></span><span class="line"></span>
            </span>
          </a>
        </div>
      </div>
    </ng-container>
  </div>

  <!-- end test -->

  <!--div class="card text-center card-light mt-3">
    <div class="card-body text-center pt-3">
      <div *ngIf="symptoms[0]">
        <h3 class="badge badge-xl" [class]="symptoms[0].total | symptomBg : 40">
          <span class="sr-only">Your most recent symptoms rating was</span>
          {{ symptoms[0].total }}
        </h3>

        <div class="progress-icons-ends mt-2 mb-3">
          <span class="icon-wrap icon-left">
            <fa-icon
              [icon]="['fal', 'thermometer-empty']"
              [fixedWidth]="true"
            ></fa-icon>
          </span>
          <div class="progress">
            <div
              class="progress-bar"
              [class]="symptoms[0].total | symptomBg : 40"
              role="progressbar"
              style="width: {{ (symptoms[0].total / 40) * 100 }}%"
              [attr.aria-valuenow]="(symptoms[0].total / 40) * 100"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
          <span class="icon-wrap icon-right">
            <fa-icon
              [icon]="['fal', 'thermometer-full']"
              [fixedWidth]="true"
            ></fa-icon>
          </span>
        </div>
        <div *ngIf="previousSymptom && symptom" class="mt-2">
          <p *ngIf="+symptom.total < +previousSymptom.total">
            Your overall score has decreased for the better by
            <span class="text-success"
              ><strong>{{
                +symptom.total - +previousSymptom.total
              }}</strong></span
            >
            since your previous entry.
          </p>
          <p *ngIf="+symptom.total > +previousSymptom.total">
            Your overall score has unfortunately increased by
            <span class="text-danger"
              ><strong
                >+{{ +symptom.total - +previousSymptom.total }}</strong
              ></span
            >
            since your previous entry.
          </p>
          <p *ngIf="+symptom.total === +previousSymptom.total">
            Your overall score has remained the same since your previous entry.
          </p>
        </div>
        <div *ngIf="!previousSymptom && symptom">
          <p>
            Your overall score is {{ symptom.total }} and you will be able to
            track your score changes after future entries.
          </p>
        </div>
        <span
          ><a
            [routerLink]="[
              '/trackers',
              'health-status',
              'view',
              symptom.user_symptom_id
            ]"
            >View your current entry and feedback</a
          ><br /><br
        /></span>

        <button
          class="btn btn-primary btn-lg btn-wide"
          [routerLink]="['/trackers', 'health-status', 'update']"
        >
          Update
          <span class="icon-wrap"
            ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
          ></span>
        </button>

        <hr />
        <span class="small"
          >Last updated: {{ symptoms[0].created | date : "EEE d MMM" }}</span
        >
      </div>
    </div>
  </div-->

  <!--div *ngIf="symptoms[0]">
    <h3 class="mt-5 mb-1 sm-title">Your current scores</h3>
    <ul class="tracker-list tracker-list-no-bt tracker-list-symptoms">
      <ng-container
        *ngFor="let symptomInf of userSymptomsService.symptomsArray"
      >
        <li *ngIf="checkSymptomForDiagnosis(symptomInf.id)">
          <a [routerLink]="['/trackers', 'health-status', symptomInf.id]">
            <span class="tracker-icon">
              {{ getSymptomScore(symptoms[0], symptomInf.id) }}
            </span>
            <span class="tracker-info">
              <span class="tracker-link"
                ><span class="icon-symptom"
                  ><fa-icon
                    [icon]="[symptomInf.iconWeight, symptomInf.icon]"
                  ></fa-icon
                ></span>
                {{ symptomInf.title }}
                <span class="icon-wrap float-end"
                  ><fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon></span
              ></span>
              <span class="progress progress-symptom">
                <span
                  class="progress-bar"
                  [class]="
                    getSymptomScore(symptoms[0], symptomInf.id) | symptomBg : 5
                  "
                  role="progressbar"
                  style="width: {{
                    (getSymptomScore(symptoms[0], symptomInf.id) / 5) * 100
                  }}%"
                  [attr.aria-valuenow]="
                    getSymptomScore(symptoms[0], symptomInf.id) * 100
                  "
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></span>
                <span class="line"></span><span class="line"></span
                ><span class="line"></span><span class="line"></span>
              </span>
            </span>
          </a>
        </li>
      </ng-container>
    </ul>

    <h3 class="mt-4 mb-1 sm-title">Symptoms History</h3>
  

    <table class="table table-sm table-symptoms" *ngIf="symptoms">
      <thead>
        <tr>
          <th></th>
          <ng-container
            *ngFor="let symptomInf of userSymptomsService.symptomsArray"
          >
            <th *ngIf="checkSymptomForDiagnosis(symptomInf.id)">
              <span class="icon-wrap"
                ><fa-icon
                  [icon]="[symptomInf.iconWeight, symptomInf.icon]"
                ></fa-icon
              ></span>
            </th>
          </ng-container>
          <th>
            <span class="icon-wrap"
              ><fa-icon [icon]="['fal', 'thermometer-half']"></fa-icon
            ></span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let symptom of symptoms">
          <th>
            <a
              [routerLink]="[
                '/trackers',
                'health-status',
                'view',
                symptom.user_symptom_id
              ]"
              >{{ symptom.created | date : "MMM dd" }}</a
            >
          </th>
          <ng-container
            *ngFor="let symptomInf of userSymptomsService.symptomsArray"
          >
            <td *ngIf="checkSymptomForDiagnosis(symptomInf.id)">
              {{ getSymptomScore(symptom, symptomInf.id) }}
            </td>
          </ng-container>

          <td [class]="symptom.total | symptomBg : 40">{{ symptom.total }}</td>
        </tr>
      </tbody>
    </table>
  </div-->
</div>
