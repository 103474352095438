<div class="relative">
  <app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>

  <div class="img-container">
    <img
      [src]="page?.image_filename | getThumbnail : 'wide'"
      alt="Image showing {{ page?.title }}"
      class="img-fluid stage-nav-img resource-masthead-img fade-in d-block d-sm-none"
      *ngIf="page?.image_filename"
    />
    <img
      [src]="page?.image_filename | getThumbnail : 'wide'"
      alt="Image showing {{ page?.title }}"
      class="img-fluid stage-nav-img resource-masthead-img fade-in d-none d-sm-block"
      *ngIf="page?.image_filename"
    />
    <div *ngIf="page?.page_type_id === '2'">
      <video
        width="100%"
        poster="{{ page.content.posterUrl }}"
        controls
        preload
        class="fade-in drop-shadow"
        controlsList="nodownload"
      >
        <source src="{{ page.content.videoUrl }}" type="video/mp4" />
        <track
          src="{{ page.content.captionsUrl }}"
          kind="subtitles"
          srclang="en"
          label="English"
          default
          *ngIf="page.content.captionsUrl"
        />
      </video>
    </div>
  </div>
  <div class="main-content-area sub-page container-resource-page">
    <!--
    <h3 class="sm-title mb-4 has-prev-next text-center">
      <a
        (click)="onPrev()"
        class="prev pointer"
        *ngIf="prevPage?.content_page_id"
        [routerLink]="['']"
      >
        <span class="sr-only">Previous Page</span>
        <span class="icon-wrap">
          <fa-icon
            [icon]="['far', 'arrow-alt-left']"
            [fixedWidth]="true"
          ></fa-icon>
        </span>
      </a>
      {{ page?.title }}
      <a
        (click)="onNext()"
        class="next pointer"
        *ngIf="nextPage?.content_page_id"
        [routerLink]="['']"
      >
        <span class="sr-only">Next Page</span>
        <span class="icon-wrap">
          <fa-icon
            [icon]="['far', 'arrow-alt-right']"
            [fixedWidth]="true"
          ></fa-icon>
        </span>
      </a>
    </h3>
    -->
    <div (click)="onContentClick($event)">
      <div
        [innerHTML]="page?.content | safeHtml"
        *ngIf="page?.page_type_id == '1'"
      ></div>
      <div *ngIf="page?.page_type_id == '2'">
        <div [innerHTML]="page?.content.content | safeHtml"></div>
      </div>
      <div *ngIf="page?.page_type_id == '7'">
        <div [innerHTML]="page?.content | safeHtml"></div>
        <app-sleep-score></app-sleep-score>
      </div>

      <div *ngIf="page?.page_type_id == '8'">
        <div [innerHTML]="page?.content | safeHtml"></div>
        <app-fear-anxiety-symptom-checker></app-fear-anxiety-symptom-checker>
      </div>

      <div *ngIf="page?.page_type_id == '9'">
        <div [innerHTML]="page?.content | safeHtml"></div>
        <app-prioritising-tool></app-prioritising-tool>
      </div>
      <!--div *ngIf="page?.page_type_id == '10'">
        <div [innerHTML]="page?.content[0] | safeHtml"></div>
        <app-must-self-screen></app-must-self-screen>
        <div [innerHTML]="page?.content[1] | safeHtml"></div>
      </div-->
      <div *ngIf="page?.page_type_id == '11'">
        <div [innerHTML]="page?.content | safeHtml"></div>
        <app-memory-thinking-checkup></app-memory-thinking-checkup>
      </div>
      <div *ngIf="page?.page_type_id == '12'">
        <div [innerHTML]="page?.content | safeHtml"></div>
        <app-quiz></app-quiz>
      </div>
      <div *ngIf="page?.page_type_id == '13'">
        <div [innerHTML]="page?.content | safeHtml"></div>
        <app-risk-factors></app-risk-factors>
      </div>
      
    </div>
    <div class="last-update-text" *ngIf="page?.modified">
      Last updated: {{ page?.modified | customDate : "Do MMM YYYY" }}
    </div>
    <div class="last-update-text" *ngIf="page?.created && !page?.modified">
      Last updated: {{ page?.created | customDate : "Do MMM YYYY" }}
    </div>
  </div>
  
</div>
<div class="bg-light more-resources" *ngIf="pages && pages.length > 1">
  <h2 class="card-title text-center">More from {{ resource?.title }}</h2>
  <ul class="tracker-list">
    <ng-container *ngFor="let page of pages"
      ><li *ngIf="+page.content_page_id !== +pageId">
        <a
          class="task"
          [routerLink]="[
            '/resources',
            page.content_section_id,
            page.content_page_id
          ]"
        >
        <span class="tracker-info">
            <span class="tracker-title">{{ page.title }}</span>
        </span>
        <span class="icon-wrap float-end icon-more"
            ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon>
        </span>
        </a>
      </li>
    </ng-container>
  </ul>
  <!--
  <div class="row prev-next">
    <div class="col text-start">
      <a
        (click)="onPrev()"
        class="prev float-left pointer"
        *ngIf="prevPage?.content_page_id"
        [routerLink]="['']"
      >
        <span class="icon-wrap">
          <fa-icon
            [icon]="['far', 'arrow-alt-left']"
            [fixedWidth]="true"
          ></fa-icon>
        </span>
        Previous
      </a>
    </div>
    <div class="col text-end">
      <a
        (click)="onNext()"
        class="prev float-right pointer"
        *ngIf="nextPage?.content_page_id"
        [routerLink]="['']"
      >
        Next
        <span class="icon-wrap">
          <fa-icon
            [icon]="['far', 'arrow-alt-right']"
            [fixedWidth]="true"
          ></fa-icon>
        </span>
      </a>
    </div>
  </div>
  -->
</div>
