import { SettingsService } from './../services/settings.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { interval } from 'rxjs';

@Component({
  selector: 'app-offline',
  templateUrl: './offline.component.html',
  styleUrls: ['./offline.component.scss'],
})
export class OfflineComponent implements OnInit {
  public maintenanceMessage = '';
  checkSiteStatusInterval: any;
  constructor(
    private settingsService: SettingsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.settingsService.fetchAll().subscribe((settings: any) => {
      this.maintenanceMessage = settings.maintenance_message;
      if (+settings.online === 1) {
        this.router.navigate(['/']);
      }
    });

    this.checkSiteStatusInterval = interval(60000).subscribe((count) => {
      this.checkSiteStatus();
    });
  }

  checkSiteStatus() {
    this.settingsService.fetchAll().subscribe(
      (responseData) => {
        if (+responseData.online === 1) {
          this.router.navigate(['/']);
        }
      },
      (error) => {
        // console.log(error);
      }
    );
  }
}
