import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/auth/service/user.service';
import { AchievementService } from 'src/app/services/achievement.service';
import { ActionPlanService } from 'src/app/services/action-plan.service';

@Component({
  selector: 'app-action-plan-index',
  templateUrl: './action-plan-index.component.html',
  styleUrls: ['./action-plan-index.component.scss']
})
export class ActionPlanIndexComponent implements OnInit, OnDestroy {

  actionPlanSetup = false;
  actionPlan:any;
  diagnosisMode:any;
  userData:any;

  constructor(
    private actionPlanService:ActionPlanService,
    private router:Router,
    private userService:UserService,
    private achievementService:AchievementService
  ) { }

  ngOnInit(): void {
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
        if (this.userData.primary_diagnosis) {

          if (
            this.userData.primary_diagnosis == 1 ||
            this.userData.primary_diagnosis == 2 ||
            this.userData.primary_diagnosis == 3 ||
            this.userData.primary_diagnosis == 4
          ) {
            this.diagnosisMode = 'respiratory';
          }
          if (
            this.userData.primary_diagnosis == 5 ||
            this.userData.primary_diagnosis == 6 ||
            this.userData.primary_diagnosis == 7 ||
            this.userData.primary_diagnosis == 8
          ) {
            this.diagnosisMode = 'cardiac';
          }
        }
      }
    });
    this.actionPlanService.fetchAll().subscribe((responseData) => {
      if(responseData.length>0){
        this.actionPlanSetup = true;
        this.actionPlan = responseData[0];
      }
      else{
        this.router.navigate(['/action-plan', 'setup']);
      }
    });
  }

  ngOnDestroy(): void {
    this.achievementService.refreshNotifications();
  }

}
