<div class="main-content-area more bg-light">
  <ul class="tracker-list tracker-list-padded tracker-list-no-bt">
    <li class="d-lg-none">
      <a [routerLink]="['/chat', 'healthcare-professional']">
        <span class="icon-wrap tracker-icon">
          <fa-icon
            [icon]="['fal', 'user-md-chat']"
            [fixedWidth]="true"
          ></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link"
            >Ask a Healthcare Professional
            <span class="icon-wrap float-end icon-more"
              ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon></span
          ></span>

          <span class="details"
            >Get advice from a healthcare professional.</span
          >
        </span>
      </a>
    </li>
    <li  class="d-lg-none"  *ngIf="diagnosisMode && diagnosisMode!='covid' && this.userData.primary_diagnosis!=7  && this.userData.primary_diagnosis!=8">
      <a [routerLink]="['/action-plan']">
        <span class="icon-wrap tracker-icon">
          <fa-icon [icon]="['fal', 'gauge']" [fixedWidth]="true"></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link"
            >Action Plan
            <span class="icon-wrap float-end icon-more"
              ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon></span
          ></span>

          <span class="details">Review your action plan.</span>
        </span>
      </a>
    </li>
    <li class="d-lg-none">
      <a [routerLink]="['/achievements']">
        <span class="icon-wrap tracker-icon">
          <fa-icon [icon]="['fal', 'medal']" [fixedWidth]="true"></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link"
            >Achievements
            <span class="icon-wrap float-end icon-more"
              ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon></span
          ></span>

          <span class="details">{{ achievements.length }} badges earned</span>
        </span>
      </a>
    </li>
    <li class="d-lg-none">
      <a [routerLink]="['/programme', 'task-list']">
        <span class="icon-wrap tracker-icon">
          <fa-icon [icon]="['fal', 'list-check']" [fixedWidth]="true"></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link"
            >Weekly To-Do List
            <span class="icon-wrap float-end icon-more"
              ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon></span
          ></span>

          <span class="details">A guide of what to do on the programme this week</span>
        </span>
      </a>
    </li>
    <!--<li>
      <a [routerLink]="['/notifications']">
        <span class="icon-wrap tracker-icon">
          <fa-icon [icon]="['fal', 'bell']" [fixedWidth]="true"></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link"
            >Notifications
            <span class="icon-wrap float-end icon-more"
              ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon></span
          ></span>

          <span class="details">{{ notifications.length }} unread.</span>
        </span>
      </a>
    </li>-->
    <!--li>
      <a [routerLink]="['/chat', 'forum']">
        <span class="icon-wrap tracker-icon">
          <fa-icon [icon]="['fal', 'comments']" [fixedWidth]="true"></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link"
            >Forum
            <span class="icon-wrap float-end icon-more"
              ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon></span
          ></span>

          <span class="details"
            >Chat with other people using the Impact programme.</span
          >
        </span>
      </a>
    </li-->
    
  </ul>

  <h3 class="tracker-list-heading d-lg-none">ACCOUNT</h3>

  <ul class="tracker-list tracker-list-padded tracker-list-no-bt d-lg-none">
    
    <li>
      <a [routerLink]="['/update-details']">
        <span class="icon-wrap tracker-icon">
          <fa-icon [icon]="['fal', 'user']" [fixedWidth]="true"></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link"
            >Account Details
            <span class="icon-wrap float-end icon-more"
              ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon></span
          ></span>

          <span class="details">Update your personal details</span>
        </span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/change-password']">
        <span class="icon-wrap tracker-icon">
          <fa-icon [icon]="['fal', 'lock']" [fixedWidth]="true"></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link"
            >Change Password
            <span class="icon-wrap float-end icon-more"
              ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon></span
          ></span>

          <span class="details">Update your password</span>
        </span>
      </a>
    </li>
  </ul>

  <h3 class="tracker-list-heading">HELP</h3>

  <ul class="tracker-list tracker-list-padded tracker-list-no-bt">
    <li class="d-lg-none">
      <a [routerLink]="['/chat', 'support']">
        <span class="icon-wrap tracker-icon">
          <fa-icon [icon]="['fal', 'headset']" [fixedWidth]="true"></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link"
            >Technical support
            <span class="icon-wrap float-end icon-more"
              ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon></span
          ></span>

          <span class="details"
            >Contact us if you are having trouble using the programme.</span
          >
        </span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/more', 'app']">
        <span class="icon-wrap tracker-icon">
          <fa-icon [icon]="['fal', 'mobile']" [fixedWidth]="true"></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link"
            >Install Web App
            <span class="icon-wrap float-end icon-more"
              ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon></span
          ></span>

          <span class="details">How to as an app on your device.</span>
        </span>
      </a>
    </li>
    <!--<li>
      <a [routerLink]="['/contact']">
        <span class="icon-wrap tracker-icon">
          <fa-icon [icon]="['fal', 'message']" [fixedWidth]="true"></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link"
            >Contact
            <span class="icon-wrap float-end icon-more"
              ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon></span
          ></span>
          <span class="details">For all other queries.</span>
        </span>
      </a>
    </li>-->
    <!--<li>
      <a [routerLink]="['/more', 'about']">
        <span class="icon-wrap tracker-icon">
          <fa-icon
            [icon]="['fal', 'info-circle']"
            [fixedWidth]="true"
          ></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link"
            >About Impact
            <span class="icon-wrap float-end icon-more"
              ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon></span
          ></span>
        </span>
      </a>
    </li>-->
  </ul>

  <h3 class="tracker-list-heading">LEGAL</h3>

  <ul class="tracker-list tracker-list-padded tracker-list-no-bt">
    <li>
      <a [routerLink]="['/terms']">
        <span class="icon-wrap tracker-icon">
          <fa-icon
            [icon]="['fal', 'balance-scale']"
            [fixedWidth]="true"
          ></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link"
            >Terms and Conditions
            <span class="icon-wrap float-end icon-more"
              ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon></span
          ></span>
        </span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/privacy']">
        <span class="icon-wrap tracker-icon">
          <fa-icon
            [icon]="['fal', 'fingerprint']"
            [fixedWidth]="true"
          ></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link"
            >Privacy Policy
            <span class="icon-wrap float-end icon-more"
              ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon></span
          ></span>
        </span>
      </a>
    </li>
    
  </ul>
<br />
  <ul class="tracker-list tracker-list-padded tracker-list-no-bt mt-2">
    <li>
      <a [routerLink]="['']" (click)="onLogout()">
        <span class="icon-wrap tracker-icon text-danger">
          <fa-icon [icon]="['fal', 'sign-out']" [fixedWidth]="true"></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link">Sign out </span>
        </span>
      </a>
    </li>
  </ul>
</div>
