import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BitesizeSessionService {

  cachedResource: any;
  cacheTime = new Date();
  cacheLifetime = 1 * 60 * 1000;

  constructor(private http: HttpClient) {}

  fetchAll() {
    return this.http
      .get<any>(environment.apiUrl + '/bitesize_sessions', {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const resourcesArray: any = [];
          responseData['_embedded'].bitesize_sessions.forEach((resource: any) => {
            resourcesArray.push(resource);
          });
          return resourcesArray;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  clearCache() {
    this.cachedResource = null;
  }
}
