import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'symptomBg'
})
export class SymptomBgPipe implements PipeTransform {

  transform(score: number, max: number): unknown {
    const percent = (score / max) * 100;
    let bgClass = '';
    if (percent <= 0) {
      bgClass = 'bg-symptom-0';
    } else if (percent > 0 && percent <= 20) {
      bgClass = 'bg-symptom-1';
    }
      else if (percent > 20 && percent <= 40) {
      bgClass = 'bg-symptom-2';
    } else if (percent > 40 && percent <= 60) {
      bgClass = 'bg-symptom-3';
    } else if (percent > 60 && percent <= 80) {
      bgClass = 'bg-symptom-4';
    } else {
      bgClass = 'bg-symptom-5';
    }
    return bgClass;
  }

}
