import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { UserService } from 'src/app/auth/service/user.service';
import { UserAchievement } from 'src/app/models/user-achievement.model';
import { AchievementService } from 'src/app/services/achievement.service';
import { UserTaskStatusService } from 'src/app/services/user-task-status.service';

@Component({
  selector: 'app-programme-index',
  templateUrl: './programme-index.component.html',
  styleUrls: ['./programme-index.component.scss']
})
export class ProgrammeIndexComponent implements OnInit {
  daysToSurgery: number | undefined;
  sugeryDateProgress: number | undefined;
  isLoading = false;
  currentWeekNumber!: number;
  userData: any;
  reportsRead: any = [];
  achievements:UserAchievement[] =[];
  tasksComplete = 0;
  tasksTotal = 6;
  programmeTasksComplete = 0;
  programmeTasksTotal = 4;
  recentAchievements = 0;

  constructor(
    private userService: UserService,
   // private weeklyReportService: WeeklyReportService,
    private userTaskStatusService: UserTaskStatusService,
    private achievementService:AchievementService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
        this.userData = userData;

        this.currentWeekNumber = +userData.current_week;
      }
    });

    forkJoin({
      allTaskStatuses: this.userTaskStatusService.fetchAll(),
      allAchievements: this.achievementService.fetchAll()
    }).subscribe((responseData) => {
      this.achievements = responseData.allAchievements;
     /* this.reportsRead = this.weeklyReportService.getReportsRead(
        responseData.allTaskStatuses
      );*/
      this.isLoading = false;
    });
  }

  onShowReport(week: number) {
    //this.weeklyReportService.showReport.next(true);
    //this.weeklyReportService.activeWeek.next(week);
  }

  countAchievements(type:string){
    let count = 0;
    this.achievements.forEach((achievement)=>{
      if(achievement.type == type){
        count++;
      }
    });
    return count;
  }
}
