export class CardioWorkout {
  constructor(
    public cardio_workout_id: number,
    public user_id: number,
    public minutes: number,
    public seconds: number,
    public exercise_id: number,
    public exercise: string,
    public exercise_other: string,
    public intensity: string,
    public workout_date: string,
    public difficulty: number,
    public created: string,
    public modified: string
  ) {}
}
