import { CanDeactivate } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import Swal from "sweetalert2/src/sweetalert2.js";

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard
  implements CanDeactivate<ComponentCanDeactivate>
{
  canDeactivate(
    component: ComponentCanDeactivate
  ): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first

    if (component.canDeactivate()) {
      return true;
    } else {
      return Observable.create((observer: any) => {
        Swal.fire({
          title: "Unsaved changed",
          text: "You have unsaved changes in your form.  If you wish to save them go back and press the save button.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, go back and save them",
          cancelButtonText: "Continue without saving",
        }).then((result) => {
          if (result.value == true) {
            observer.next(false);
          } else {
            observer.next(true);
          }
        });
      });
    }
  }
}
