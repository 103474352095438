import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { ReplaySubject } from 'rxjs';
import { environment } from './../../environments/environment';
declare const gtag: any;
export interface AnalyticsEvent {
  type: 'PAGEVIEW' | 'EVENT';
  category?: string;
  action?: string;
  label: string;
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  eventsQueue$ = new ReplaySubject<AnalyticsEvent>();
  environment = environment;
  tracking = false;

  constructor(private cookieService: CookieService, private router: Router) {}

  public loadGoogleAnalytics(): void {
    const gaScript = document.createElement('script');
    gaScript.setAttribute('async', 'true');
    gaScript.setAttribute(
      'src',
      `https://www.googletagmanager.com/gtag/js?id=` + environment.ga
    );

    const gaScript2 = document.createElement('script');
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());`;

    if(document.documentElement.firstChild){
      document.documentElement.firstChild.appendChild(gaScript);
      document.documentElement.firstChild.appendChild(gaScript2);
    }
  }

  public startTracking(): void {
    this.loadGoogleAnalytics();
    gtag('create', environment.ga, 'auto');
    this.tracking = true;
    this.subscribeToEvents();
  }
  public stopTracking() {
    if (typeof gtag === 'function') {
      gtag('remove');
    }
    // Remove the default cookies
    // _ga is used to distinguish users.
    this.cookieService.delete('_ga', '/', environment.cookieDomain);
    this.cookieService.delete('_ga_' + environment.ga, '/', environment.cookieDomain);
    this.cookieService.delete('_ga_' + environment.ga, '/', environment.cookieDomain);
    // _gid is used to distinguish users.
    this.cookieService.delete('_gid', '/', environment.cookieDomain);
    // _gat is used to throttle request rate.
    this.cookieService.delete('_gat', '/', environment.cookieDomain);

    this.tracking = false;
  }

  private subscribeToEvents(): void {
    this.eventsQueue$.subscribe((e: AnalyticsEvent) => {
      if (e.type === 'PAGEVIEW') {
        // gtag does this automatically
        
        gtag('event', 'page_view', {
          page_title: e.label,
          page_location: this.router.url,
          send_to: environment.ga,
        });
      } else if (e.type === 'EVENT') {
        gtag('send', {
          hitType: 'event',
          eventCategory: e.category,
          eventAction: e.action,
          eventLabel: e.label,
        });
      }
    });
  }

  public trackVirtualPageview(name: string): void {
    if (this.tracking) {
      this.eventsQueue$.next({ type: 'PAGEVIEW', label: name });
    }
  }

  public trackEvent(category: string, action: string, label: string) {
    if (this.tracking) {
      this.eventsQueue$.next({ type: 'EVENT', category, action, label });
    }
  }
}
