import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { UserService } from 'src/app/auth/service/user.service';
import { UserTarget } from 'src/app/models/user-target.model';
import { CardioWorkout } from 'src/app/models/cardio-workout.model';
import { DateHelperService } from 'src/app/services/date-helper.service';
import { UserTargetService } from 'src/app/services/user-target.service';
import { CardioWorkoutService } from 'src/app/services/cardio-workout.service';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { AchievementService } from 'src/app/services/achievement.service';
import * as moment from 'moment';

@Component({
  selector: 'app-cardio-index',
  templateUrl: './cardio-index.component.html',
  styleUrls: ['./cardio-index.component.scss'],
})
export class CardioIndexComponent implements OnInit {
  public isLoading = false;
  public cardioWorkouts!: CardioWorkout[];
  public daysHit = 0;
  public weeklyTarget = { days: 5, threshold: 10 };
  public errors: any = [];
  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public currentWeekData!: any[];
  public today = this.dateHelperService.getToday();

  public allTargets: UserTarget[] = [];
  public allWorkouts!: CardioWorkout[];
  public currentTarget?: UserTarget;
  public monthsExercised: any;
  public weeksExercised: any;

  public last4Weeks: Date[] = this.dateHelperService.getLast4tWeeks();
  public last4WeeksData!: any;
  public monthData!: any;
  public stats: any;
  public userData: any;
  public programmeWeeks: any;

  public levelHistory: any;
  public canProgress = false;
  currentWeekNumber: any;
  workoutsByActivity: any;

  monthSelected = moment().toDate();
  currentMonth = moment().toDate();

  public firstDayMonth: any;
  public lastDayMonth: any;

  constructor(
    private userService: UserService,
    private dateHelperService: DateHelperService,
    private userTargetService: UserTargetService,
    public cardioWorkoutService: CardioWorkoutService,
    private achievementService: AchievementService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    const startDate = this.currentWeek[0];
    const endDate = this.currentWeek[6];

    if (!this.userService.userData.getValue()) {
      this.userService.userData.subscribe((userData) => {
        if (userData) {
          this.userData = userData;
          this.currentWeekNumber = this.userData.current_week;
        }
      });
    } else {
      this.userData = this.userService.userData.getValue();
      this.currentWeekNumber = this.userData.current_week;
    }

    forkJoin({
      workouts: this.cardioWorkoutService.fetchBetween(startDate, endDate),
      allWorkouts: this.cardioWorkoutService.fetchAll(),
      targets: this.userTargetService.fetchAll(),
      cardioLevelHistory: this.cardioWorkoutService.fetchLevelHistory(),
    }).subscribe(
      (responseData) => {
        this.isLoading = false;

        this.allTargets = responseData.targets;
        this.currentTarget = this.userTargetService.getLatestTarget(
          this.allTargets,
          'cardio'
        );
        if (this.currentTarget) {
          this.cardioWorkoutService.weeklyTarget.threshold =
            +this.currentTarget.target;
        }

        this.cardioWorkouts = responseData.workouts;
        this.allWorkouts = responseData.allWorkouts;
        this.workoutsByActivity = this.getWorkoutsByActivity();

        this.currentWeekData = this.cardioWorkoutService.generateWeekArray(
          this.currentWeek,
          this.cardioWorkouts
        );

        this.daysHit = this.cardioWorkoutService.getDaysHit(
          this.currentWeekData
        );
        this.monthsExercised = this.cardioWorkoutService.getMonths(
          this.allWorkouts
        );

        // get programme weeks?
        this.programmeWeeks = this.dateHelperService.getProgrammeWeeks(
          this.userData.current_week
        );

        this.weeksExercised = this.cardioWorkoutService.getWeeksExercised(
          this.allWorkouts,
          this.programmeWeeks
        );

        this.levelHistory = responseData.cardioLevelHistory;
        this.canProgress = this.cardioWorkoutService.canProgress(
          this.userData.exercise_level,
          this.levelHistory,
          this.allWorkouts,
          this.weeksExercised
        );


        this.getMonthData();
      },
      (error) => {
        this.errors.push(error.message);
      }
    );
  }

  getTotalWorkoutDuration() {
    let totalMins = 0;
    let totalSecs = 0;
    this.allWorkouts.forEach((workout) => {
      totalMins += +workout.minutes;
      totalSecs += +workout.seconds;
    });
    totalSecs = totalSecs + totalMins * 60;

    let hours = Math.floor(totalSecs / 3600);
    totalSecs %= 3600;
    let minutes = Math.floor(totalSecs / 60);
    let seconds = totalSecs % 60;

    return hours + 'h ' + minutes + 'm';
  }

  getWorkoutsByIntensity(intensity: string) {
    let total = 0;
    this.allWorkouts.forEach((workout) => {
      if (workout.intensity == intensity) {
        total++;
      }
    });
    return total;
  }

  getWorkoutsByActivity() {
    let workoutsByActivity: any = {};
    let workoutsByActivityArray = [];
    this.allWorkouts.forEach((workout) => {
      if (!workoutsByActivity[workout.exercise]) {
        workoutsByActivity[workout.exercise] = 0;
      }
      workoutsByActivity[workout.exercise]++;
    });

    for (let key in workoutsByActivity) {
      workoutsByActivityArray.push({
        exercise: key,
        total: workoutsByActivity[key],
      });
    }
    return workoutsByActivityArray.sort((a, b) => b.total - a.total);
  }

  onProgress(level: number) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Would you ike to progress to the next level',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'No',
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.value) {
        this.cardioWorkoutService
          .updateLevel(level)
          .subscribe((responseData: any) => {
            this.userData.exercise_level = level;
            this.canProgress = false;

            this.achievementService.refreshNotifications();
          });
      }
    });
  }

  onPreviousWeek() {
    if (this.currentWeekNumber > 1) {
      this.currentWeek = this.dateHelperService.getWeekFor(
        moment(this.currentWeek[0]).subtract(1, 'week')
      );
      this.currentWeekNumber--;
      this.currentWeekData = this.cardioWorkoutService.generateWeekArray(
        this.currentWeek,
        this.cardioWorkouts
      );
    }
  }

  onNextWeek() {
    if (this.currentWeekNumber < this.userData.current_week) {
      this.currentWeek = this.dateHelperService.getWeekFor(
        moment(this.currentWeek[0]).add(1, 'week')
      );
      this.currentWeekNumber++;
      this.currentWeekData = this.cardioWorkoutService.generateWeekArray(
        this.currentWeek,
        this.cardioWorkouts
      );
    }
  }

  getMonthData() {
    // get the current weeks in the month...
    let currentDate = moment(this.monthSelected);
    let currentMonth: any = [];
    let start = currentDate.clone().startOf('month').startOf('isoWeek');
    let end = currentDate.clone().endOf('month').endOf('isoWeek');

    this.firstDayMonth = currentDate.clone().startOf('month').format("YYYY-MM-DD");
    this.lastDayMonth = currentDate.clone().endOf('month').format("YYYY-MM-DD");

    while (start.isSameOrBefore(end)) {
      currentMonth.push(JSON.parse(JSON.stringify(start)));
      start.add(1, 'days');
    }

    this.monthData = this.cardioWorkoutService.generateLastMonthWeeksArray(
      currentMonth,
      this.allWorkouts
    );

    let last4WeeksDataForStats: any = [];

    this.allWorkouts.forEach((workout) => {
      if (
        moment(workout.workout_date) >= moment(currentMonth[0]) &&
        moment(workout.workout_date) <=
          moment(currentMonth[currentMonth.length - 1])
      ) {
        last4WeeksDataForStats.push(workout);
      }
    });

    this.stats = this.cardioWorkoutService.getStats(
      last4WeeksDataForStats
      // this.allWeeklyTargets
    );
    if (this.monthData[1].daysHit >= 5) {
      this.stats.targetHit++;
    }
    if (this.monthData[2].daysHit >= 5) {
      this.stats.targetHit++;
    }
    if (this.monthData[3].daysHit >= 5) {
      this.stats.targetHit++;
    }
    if (this.monthData[4].daysHit >= 5) {
      this.stats.targetHit++;
    }

    if (this.monthData[5] && this.monthData[5].daysHit >= 5) {
      this.stats.targetHit++;
    }

    if (this.monthData[6] && this.monthData[6].daysHit >= 5) {
      this.stats.targetHit++;
    }
  }

  onPrevMonth() {
    this.monthSelected = moment(this.monthSelected)
      .subtract(1, 'month')
      .toDate();
    this.getMonthData();
  }

  onNextMonth() {
    this.monthSelected = moment(this.monthSelected).add(1, 'month').toDate();
    this.getMonthData();
  }
}
