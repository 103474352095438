<div class="home-bg">
  <div class="home-content">
    <div class="home-masthead">
      <img
        src="./assets/img/logo-impact-white-new.svg"
        alt="i-impact"
        width="230"
      />
      <h2>Helping you manage your health condition week-by-week</h2>
    </div>
    <div class="home-footer">
      <div class="home-buttons">
        <button
          class="btn btn-white btn-lg w-100 text-start btn-icon-end"
          [routerLink]="['/login']"
        >
          Sign in
          <span class="icon-wrap"
          ><fa-icon
            [icon]="['fal', 'arrow-right']"
            [fixedWidth]="true"
          ></fa-icon></span
      >
        </button>
        <button
          class="btn btn-outline-white btn-lg w-100 text-start btn-icon-end"
          [routerLink]="['/register']"
        >
          Create an Account
          <span class="icon-wrap"
          ><fa-icon
            [icon]="['fal', 'arrow-right']"
            [fixedWidth]="true"
          ></fa-icon></span
      >
        </button>
      </div>
      <div class="footer-strap">Incorporating the programmes</div>
      <div class="programme-logos">
        <img
          src="./assets/img/logo-ayh-white.svg"
          alt="Activate Your Heart Logo"
          width="93"
        />
        <img
          src="./assets/img/logo-space-white.svg"
          alt="SPACE for COPD"
          width="69"
        />
        <img
          src="./assets/img/logo-ycr-white.svg"
          alt="Your COVID Recovery"
          width="93"
        />
      </div>
    </div>
  </div>
</div>
