import { UserDataInterface } from './../auth/service/userData.interface';
import { AuthService } from './../auth/service/auth.service';
import { environment } from './../../environments/environment';
import { UserService } from './../auth/service/user.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-aside-menu',
  templateUrl: './aside-menu.component.html',
  styleUrls: ['./aside-menu.component.scss'],
})
export class AsideMenuComponent implements OnInit {
  userData: any;
  avatarBase: any;
  isLoading = false;
  @Output() closeMenu: EventEmitter<any> = new EventEmitter();
  constructor(
    private userService: UserService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.avatarBase = environment.apiUrl + environment.avatarPath;

    this.isLoading = false;
    // are we logged in?
    this.authService.user.subscribe((user) => {
      if (user) {
        this.isLoading = false;
        this.userService.userData.subscribe(
          (userData: UserDataInterface | null) => {
            this.isLoading = false;
            if (userData) {
              this.userData = userData;
            }
          }
        );
      }
    });
  }

  onCloseMenu(event: any) {
    this.closeMenu.emit(true);
  }
  onLogout(): void {
    this.authService.logout();
  }
}
