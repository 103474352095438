import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth/service/auth.service';
import { UserService } from 'src/app/auth/service/user.service';
import { BitesizeSessionViewService } from 'src/app/services/bitesize-session-view.service';
import { BitesizeSessionService } from 'src/app/services/bitesize-session.service';
import { DateHelperService } from 'src/app/services/date-helper.service';

@Component({
  selector: 'app-bitesize-index',
  templateUrl: './bitesize-index.component.html',
  styleUrls: ['./bitesize-index.component.scss'],
})
export class BitesizeIndexComponent implements OnInit {
  isLoading = false;
  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();

  public today = this.dateHelperService.getToday();

  public userData: any;
  currentWeekNumber!: number;
  actualWeekNumber!: number;

  public bitesizeSessions: any;
  public currentBitesizeSession: any;
  public bitesizeSessionViews: any;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private bitesizeSessionService: BitesizeSessionService,
    public bitesizeSessionViewService: BitesizeSessionViewService,
    private dateHelperService: DateHelperService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    const startDate = this.currentWeek[0];
    const endDate = this.currentWeek[6];

    if (!this.userService.userData.getValue()) {
      this.userService.userData.subscribe((userData) => {
        if (userData) {
          this.userData = userData;
          this.currentWeekNumber = +userData.current_week;
          this.actualWeekNumber = +userData.current_week;
        }
      });
    } else {
      this.userData = this.userService.userData.getValue();
      this.currentWeekNumber = this.userData.current_week;
      this.actualWeekNumber = this.userData.current_week;
    }

    forkJoin({
      bitesizeSessions: this.bitesizeSessionService.fetchAll(),
      bitesizeSessionViews: this.bitesizeSessionViewService.fetchAll(),
      // targets: this.userTargetService.fetchAll(),
    }).subscribe((responseData) => {
      this.isLoading = false;

      // bitesize sessions
      this.bitesizeSessions = responseData.bitesizeSessions;
      if (this.userData.smoker != 1) {
        // remove the smoking one
        let smokeIndex = -1;
        this.bitesizeSessions.forEach(
          (_session: any, index: number) => {
            if (_session.bitesize_session_id == 1) {
              smokeIndex = index;
            }
          }
        );
        if (smokeIndex != -1) {
          this.bitesizeSessions.splice(smokeIndex, 1);
        }
      }

      let bitesizeWeek = +this.currentWeekNumber;
      if (this.userData.smoker == 1) {
        bitesizeWeek++;
      }
      if (this.bitesizeSessions[bitesizeWeek]) {
        this.currentBitesizeSession = this.bitesizeSessions[bitesizeWeek];
      }

      this.bitesizeSessionViews = responseData.bitesizeSessionViews;
    });
  }


  getTotalBitesize(){
    if(this.userData?.smoker ==1){
      return this.bitesizeSessions.length;
    }
    else{
      return this.bitesizeSessions.length -1;
    }
  }
}
