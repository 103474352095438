import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { CardioWorkout } from 'src/app/models/cardio-workout.model';
import { StrengthWorkout } from 'src/app/models/strength-workout.model';
import { CardioWorkoutService } from 'src/app/services/cardio-workout.service';
import { DateHelperService } from 'src/app/services/date-helper.service';
import { StrengthWorkoutService } from 'src/app/services/strength-workout.service';

@Component({
  selector: 'app-exercise-index',
  templateUrl: './exercise-index.component.html',
  styleUrls: ['./exercise-index.component.scss'],
})
export class ExerciseIndexComponent implements OnInit {
  isLoading = false;
  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public today = this.dateHelperService.getToday();

  public cardioData: {
    currentWeekData: [];
    daysHit: number;
    weeklyTarget: { days: number; threshold: number };
    workouts: CardioWorkout[];
  } = {
    currentWeekData: [],
    daysHit: 0,
    weeklyTarget: { days: 5, threshold: 10 },
    workouts: [],
  };
  public CardioCurrentWeekData!: any[];
  public cardioDaysHit = 0;
  public cardioWeeklyTarget = { days: 5, threshold: 10 };

  public strengthData: {
    currentWeekData: [];
    daysHit: number;
    weeklyTarget: { days: number; threshold: number };
    workouts: StrengthWorkout[];
  } = {
    currentWeekData: [],
    daysHit: 0,
    weeklyTarget: this.strengthWorkoutService.weeklyTarget,
    workouts: [],
  };
  public StrengthCurrentWeekData!: any[];
  public strengthDaysHit = 0;
  public strengthWeeklyTarget = this.strengthWorkoutService.weeklyTarget;

  constructor(
    private cardioWorkoutService: CardioWorkoutService,
    private dateHelperService: DateHelperService,
    private strengthWorkoutService: StrengthWorkoutService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    const startDate = this.currentWeek[0];
    const endDate = this.currentWeek[6];
    forkJoin({
      cardioWorkouts: this.cardioWorkoutService.fetchBetween(
        startDate,
        endDate
      ),
      strengthWorkouts: this.strengthWorkoutService.fetchBetween(
        startDate,
        endDate
      ),
      // targets: this.userTargetService.fetchAll(),
    }).subscribe((responseData) => {
      this.isLoading = false;
      this.cardioData.workouts = responseData.cardioWorkouts;
      this.cardioData.currentWeekData =
        this.cardioWorkoutService.generateWeekArray(
          this.currentWeek,
          this.cardioData.workouts
        );
      this.cardioDaysHit = this.cardioWorkoutService.getDaysHit(
        this.cardioData.currentWeekData
      );

      this.strengthData.workouts = responseData.strengthWorkouts;
      this.strengthData.currentWeekData =
        this.strengthWorkoutService.generateWeekArray(
          this.currentWeek,
          this.strengthData.workouts
        );
      this.strengthDaysHit = this.strengthWorkoutService.getDaysHit(
        this.strengthData.currentWeekData
      );
    });
  }
}
