<span class="symptom-icon">
  <ng-container [ngSwitch]="symptomId">
    <ng-container *ngSwitchCase="'cough'">
      <img src="/assets/img/icon-cough.svg" alt="{{symptomId}} icon" />
    </ng-container>
    <ng-container *ngSwitchCase="'sob'">
      <img src="/assets/img/icon-sob.svg" alt="{{symptomId}} icon" />
    </ng-container>
    <ng-container *ngSwitchCase="'phlegm'">
      <img src="/assets/img/icon-phlegm.svg" alt="{{symptomId}} icon" />
    </ng-container>
    <ng-container *ngSwitchCase="'ischaemic_pain'">
      <img src="/assets/img/icon-chest.svg" alt="{{symptomId}} icon" />
    </ng-container>
    <ng-container *ngSwitchCase="'chest_tightness'">
      <img src="/assets/img/icon-chest.svg" alt="{{symptomId}} icon"/>
    </ng-container>
    <ng-container *ngSwitchCase="'ankle_swelling'">
      <img src="/assets/img/icon-ankles.svg" alt="{{symptomId}} icon" />
    </ng-container>
    <ng-container *ngSwitchCase="'activity'">
      <img src="/assets/img/icon-daily.svg" alt="{{symptomId}} icon" />
    </ng-container>
    <ng-container *ngSwitchCase="'confidence'">
      <img src="/assets/img/icon-confidence.svg" alt="{{symptomId}} icon" />
    </ng-container>
    <ng-container *ngSwitchCase="'anxiety'">
      <img src="/assets/img/icon-anxiety.svg" alt="{{symptomId}} icon" />
    </ng-container>
    <ng-container *ngSwitchCase="'sleep'">
      <img src="/assets/img/icon-sleep.svg" alt="{{symptomId}} icon" />
    </ng-container>
    <ng-container *ngSwitchCase="'energy'">
      <img src="/assets/img/icon-energy.svg" alt="{{symptomId}} icon" />
    </ng-container>
    <ng-container *ngSwitchCase="'mood'">
      <img src="/assets/img/icon-mood.svg" alt="{{symptomId}} icon" />
    </ng-container>
    <ng-container *ngSwitchCase="'appetite'">
      <img src="/assets/img/icon-appetite.svg" alt="{{symptomId}} icon"/>
    </ng-container>
    <ng-container *ngSwitchCase="'surgery_pain'">
      <img src="/assets/img/icon-surgery.svg" alt="{{symptomId}} icon" />
    </ng-container>
    <ng-container *ngSwitchCase="'fatigue'">
      <img src="/assets/img/icon-fatigue.svg" alt="{{symptomId}} icon"/>
    </ng-container>
    <ng-container *ngSwitchCase="'memory'">
      <img src="/assets/img/icon-memory.svg" alt="{{symptomId}} icon" />
    </ng-container>
  </ng-container>
</span>