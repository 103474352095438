import { Component, Input, OnInit } from '@angular/core';
import { DateHelperService } from 'src/app/services/date-helper.service';

@Component({
  selector: 'app-dashboard-masthead-cardio',
  templateUrl: './dashboard-masthead-cardio.component.html',
  styleUrls: ['./dashboard-masthead-cardio.component.scss'],
})
export class DashboardMastheadCardioComponent implements OnInit {
  show = true;
  @Input() cardioWeeklyTarget: any;
  @Input() currentWeekData: any;
  @Input() cardioDaysHit: any;

  public today = this.dateHelperService.getToday();

  constructor(private dateHelperService: DateHelperService) {}

  ngOnInit(): void {}
  onClose(event:any){
    this.show = false;
  }
}
