<div
  class="add-to-homescreen"
  *ngIf="showInstallMessage"
  [ngClass]="{
    iphone: device === 'iphone' || device === 'ipod',
    ipad: device === 'ipad',
    android: device === 'android'
  }"
  (click)="onSkip($event)"
>
  <div class="add-to-homescreen-wrap">
    <div class="card bg-white">
      <div class="card-body">
        <h1>
          Install i-IMPACT<br />
          on your mobile phone
        </h1>
        <div>
          <!-- Get a better experience on your
          <span *ngIf="device === 'iphone'">iPhone</span
          ><span *ngIf="device === 'ipod'">iPod</span
          ><span *ngIf="device === 'ipad'">iPad</span>
          <span *ngIf="device === 'android'">device</span> by running
          {{ environment.projectName }} as an app.
          <div class="strapline mt-3" *ngIf="device !== 'android'">
            No App Store required.
          </div>
          <div class="strapline mt-3" *ngIf="device === 'android'">
            No Google Play Store required.
          </div> -->

          <div class="add-to-homescreen-benefits text-start">
            <ul>
              <li>
                <span class="icon-wrap text-success"
                  ><fa-icon
                    [icon]="['fas', 'check']"
                    [fixedWidth]="true"
                  ></fa-icon
                ></span>
                <strong
                  >An app icon on your mobile phone's home screen will launch
                  i-IMPACT</strong
                >No more opening up your internet browser app and typing in the
                web address
              </li>
              <li>
                <span class="icon-wrap text-success"
                  ><fa-icon
                    [icon]="['fas', 'check']"
                    [fixedWidth]="true"
                  ></fa-icon
                ></span>
                <strong>Full screen i-IMPACT experience</strong>No more internet
                browser interface elements getting in the way
              </li>
            </ul>
          </div>

          <div class="card w-100 card-instructions">
            <img
              src="/assets/img/add-to-homescreen.png"
              class="card-img-top"
              alt="Device showing i-impact installed"
            />
            <div class="card-body bg-secondary text-white instructions-content">
              <h5 class="card-title">How to Install</h5>
              <div *ngIf="device === 'android'">
                <h6 class="card-sub-title">No Google Play Store required</h6>
                <div>
                  <div *ngIf="canInstallAndroid">
                    Simply click the button below and accept any prompts
                    afterwards
                    <button
                      (click)="onInstallAndroid($event)"
                      class="btn btn-white btn-wide mt-3 mb-3"
                    >
                      Install now
                    </button>
                  </div>
                  <div *ngIf="!canInstallAndroid">
                    <ol>
                      <li>
                        Tap the menu icon (3 vertical dots).
                      </li>
                      <li>
                        Scroll down and select <strong>Install App</strong> from the options.
                      </li>
                      <li>
                        Confirm the installation by tapping <strong>Install</strong>.
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
              <div *ngIf="device !== 'android'">
                <h6 class="card-sub-title">No App Store required</h6>
                <ol>
                  <li>
                    Tap the <strong>Share</strong> icon
                    <img
                      src="/assets/img/share-ios.svg"
                      alt="IOS share button"
                      width="19"
                      class="share-icon"
                    />
                  </li>
                  <li>
                    Scroll down and select
                    <strong>Add to Home Screen</strong> from the
                    options
                  </li>
                  <li>
                    Confirm the installation by tapping the
                    <strong>Add</strong> button
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
