import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SearchService } from '../services/search.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  query: any;
  results: any;
  avatarBase: any;
  currentLang = 'en';

  constructor(
    private route: ActivatedRoute,
    private searchService: SearchService,
  ) {}

  ngOnInit(): void {
    this.avatarBase = environment.apiUrl + "/assets/";
    this.route.queryParams.subscribe((params) => {
      this.query = params["q"];
      // get results
      if (this.query) {
        this.searchService.getResults(this.query).subscribe((responseData) => {
          this.results = responseData;
        });
      }
    });
  }
}
