<div class="dashboard-masthead-container" *ngIf="show">
    <div class="dashboard-masthead dashboard-masthead-bitesize">
      <div class="dashboard-masthead-content">
        <div class="dashboard-masthead-content-inner">
          <h2>New bitesize info available</h2>
          <div class="dashboard-masthead-subtitle">
            {{currentBitesizeSession?.title}}
          </div>
          <div class="dashboard-masthead-subtext">
            Bitesize {{currentBitesizeNumber}} of {{totalBitesize}}
          </div>
        </div>
      </div>
    </div>
  </div>
  