import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { Observable, Subject, throwError } from 'rxjs';

import { Message } from '../models/message.model';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  error = new Subject<string>();
  cachedMessages! : {
    [ate:string]: Observable<Message[]>,
  } | null;
  cacheTime = new Date();
  cacheLifetime = 1 * 60 * 1000;

  constructor(private http: HttpClient, private datePipe: DatePipe) {}

  fetchMessages(category: string): Observable<Message[]>  {
    if (!this.cachedMessages) {
      this.cachedMessages = {};
    }
    const now = new Date();

    let searchParams = new HttpParams();
    if (category) {
      searchParams = searchParams.append('category', category.toString());
    }
    if(!this.cachedMessages[category] ||
      Math.abs(now.getTime() - this.cacheTime.getTime()) > this.cacheLifetime){
    this.cachedMessages[category] = this.http
      .get<any>(environment.apiUrl + '/messages', {
        params: searchParams,
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const messagesArray: Message[] = [];
          responseData['_embedded'].messages.forEach((message:any) => {
            messagesArray.push(message);
          });
          return messagesArray;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        }),
        publishReplay(1),
        refCount()
      );
    }
    return  this.cachedMessages[category];
  }


  clearCache() {
    this.cachedMessages = null;
  }

  createMessage(
    message:any,
    category:any
  ) {

    this.clearCache();
    const messagesData = {
      message,
      category
    };
    return this.http.post<Message>(
      environment.apiUrl + '/messages',
      messagesData,
      {
        observe: 'response',
      }
    );
  }
}
