<div class="">
  <app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
    <div class="card text-center card-light mb-3 border-radius-bottom-only">
     <div class="card-body text-center">
            <h3 *ngIf="gettingStarted" class="mb-3 text-center">Setup your Health Trackers</h3> 
            <p>
              This programme is aimed to help you manage your long term condition or
              recover after being unwell.
            </p>
            <p>
                Any problems that are causing you concern or limiting you are important to
                identify.
            </p>
            <p>
                In the Trackers section there is a list of symptoms and other areas in your life that may be affected due to your condition. Some of these trackers may be more
                relevant to you than others.
            </p>
            <p>
                Please select a number between 0 and 5 for each of the trackers. The higher
                the number, the worse the current impact.
            </p>
            <p>
                If you scored 3 or more on a tracker, you may want to find the relevant
                resource on the website for help and advice. <em>For example</em>, if you
                scored 3 or more on cough, the resource on cough includes useful tips and
                advice on how to control your cough effectively.
            </p>
      </div>
    </div>
  

  <form class="ii-form symptom-thermometer-form" (ngSubmit)="onSubmit()" #form="ngForm">


    <div class="form-group" *ngIf="diagnosisMode == 'cardiac'">
      <fieldset>
        <legend> <span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'ischaemic_pain'"></app-symptom-icon></span> &nbsp;Chest pain</legend>
        <div class="radio-group">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.ischaemic_pain"
              class="form-check-input"
              type="radio"
              required
              name="ischaemic_pain"
              id="ischaemic_pain0"
              value="0"
            />
            <label class="form-check-label" for="ischaemic_pain0">0</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.ischaemic_pain"
              class="form-check-input"
              type="radio"
              required
              name="ischaemic_pain"
              id="ischaemic_pain1"
              value="1"
            />
            <label class="form-check-label" for="ischaemic_pain1">1</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.ischaemic_pain"
              class="form-check-input"
              type="radio"
              required
              name="ischaemic_pain"
              id="ischaemic_pain2"
              value="2"
            />
            <label class="form-check-label" for="ischaemic_pain2">2</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.ischaemic_pain"
              class="form-check-input"
              type="radio"
              required
              name="ischaemic_pain"
              id="ischaemic_pain3"
              value="3"
            />
            <label class="form-check-label" for="ischaemic_pain3">3</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.ischaemic_pain"
              class="form-check-input"
              type="radio"
              required
              name="ischaemic_pain"
              id="ischaemic_pain4"
              value="4"
            />
            <label class="form-check-label" for="ischaemic_pain4">4</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.ischaemic_pain"
              class="form-check-input"
              type="radio"
              required
              name="ischaemic_pain"
              id="ischaemic_pain5"
              value="5"
            />
            <label class="form-check-label" for="ischaemic_pain5">5</label>
          </div>
        </div>
      </fieldset>
      <div class="row form-instructions">
        <div class="col">
          <span class="arrow-left"></span>
          <span class="symptom-desc-low">No pain</span>
        </div>
        <div class="col text-end">
          <span class="arrow-right"></span>
          <span class="symptom-desc-high">Pain all the time</span>
        </div>
      </div>
    </div>

    


    <div class="form-group" *ngIf="diagnosisMode == 'respiratory' || diagnosisMode == 'covid'">
      <fieldset>
        <legend><span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'cough'"></app-symptom-icon></span> &nbsp;Cough</legend>
        <div class="radio-group">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.cough"
              class="form-check-input"
              type="radio"
              required
              name="cough"
              id="cough0"
              value="0"
            />
            <label class="form-check-label" for="cough0">0</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.cough"
              class="form-check-input"
              type="radio"
              required
              name="cough"
              id="cough1"
              value="1"
            />
            <label class="form-check-label" for="cough1">1</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.cough"
              class="form-check-input"
              type="radio"
              required
              name="cough"
              id="cough2"
              value="2"
            />
            <label class="form-check-label" for="cough2">2</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.cough"
              class="form-check-input"
              type="radio"
              required
              name="cough"
              id="cough3"
              value="3"
            />
            <label class="form-check-label" for="cough3">3</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.cough"
              class="form-check-input"
              type="radio"
              required
              name="cough"
              id="cough4"
              value="4"
            />
            <label class="form-check-label" for="cough4">4</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.cough"
              class="form-check-input"
              type="radio"
              required
              name="cough"
              id="cough5"
              value="5"
            />
            <label class="form-check-label" for="cough5">5</label>
          </div>
        </div>
      </fieldset>
      <div class="row form-instructions">
        <div class="col">
          <span class="arrow-left"></span>
          <span class="symptom-desc-low">I NEVER cough</span>
        </div>
        <div class="col text-end">
          <span class="arrow-right"></span>
          <span class="symptom-desc-high">I cough ALL the time</span>
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="diagnosisMode == 'respiratory'">
      <fieldset>
        <legend><span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'phlegm'"></app-symptom-icon></span> &nbsp;Phlegm</legend>
        <div class="radio-group">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.phlegm"
              class="form-check-input"
              type="radio"
              required
              name="phlegm"
              id="phlegm0"
              value="0"
            />
            <label class="form-check-label" for="phlegm0">0</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.phlegm"
              class="form-check-input"
              type="radio"
              required
              name="phlegm"
              id="phlegm1"
              value="1"
            />
            <label class="form-check-label" for="phlegm1">1</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.phlegm"
              class="form-check-input"
              type="radio"
              required
              name="phlegm"
              id="phlegm2"
              value="2"
            />
            <label class="form-check-label" for="phlegm2">2</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.phlegm"
              class="form-check-input"
              type="radio"
              required
              name="phlegm"
              id="phlegm3"
              value="3"
            />
            <label class="form-check-label" for="phlegm3">3</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.phlegm"
              class="form-check-input"
              type="radio"
              required
              name="phlegm"
              id="phlegm4"
              value="4"
            />
            <label class="form-check-label" for="phlegm4">4</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.phlegm"
              class="form-check-input"
              type="radio"
              required
              name="phlegm"
              id="phlegm5"
              value="5"
            />
            <label class="form-check-label" for="phlegm5">5</label>
          </div>
        </div>
      </fieldset>
      <div class="row form-instructions">
        <div class="col">
          <span class="arrow-left"></span>
          <span class="symptom-desc-low">I have no phlegm on my chest</span>
        </div>
        <div class="col text-end">
          <span class="arrow-right"></span>
          <span class="symptom-desc-high">My chest is full of phlegm</span>
        </div>
      </div>
    </div>


    <div class="form-group" *ngIf="diagnosisMode == 'respiratory'">
      <fieldset>
        <legend><span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'chest_tightness'"></app-symptom-icon></span> &nbsp;Chest Tightness</legend>
        <div class="radio-group">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.chest_tightness"
              class="form-check-input"
              type="radio"
              required
              name="chest_tightness"
              id="chest_tightness0"
              value="0"
            />
            <label class="form-check-label" for="chest_tightness0">0</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.chest_tightness"
              class="form-check-input"
              type="radio"
              required
              name="chest_tightness"
              id="chest_tightness1"
              value="1"
            />
            <label class="form-check-label" for="chest_tightness1">1</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.chest_tightness"
              class="form-check-input"
              type="radio"
              required
              name="chest_tightness"
              id="chest_tightness2"
              value="2"
            />
            <label class="form-check-label" for="chest_tightness2">2</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.chest_tightness"
              class="form-check-input"
              type="radio"
              required
              name="chest_tightness"
              id="chest_tightness3"
              value="3"
            />
            <label class="form-check-label" for="chest_tightness3">3</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.chest_tightness"
              class="form-check-input"
              type="radio"
              required
              name="chest_tightness"
              id="chest_tightness4"
              value="4"
            />
            <label class="form-check-label" for="chest_tightness4">4</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.chest_tightness"
              class="form-check-input"
              type="radio"
              required
              name="chest_tightness"
              id="chest_tightness5"
              value="5"
            />
            <label class="form-check-label" for="chest_tightness5">5</label>
          </div>
        </div>
      </fieldset>
      <div class="row form-instructions">
        <div class="col">
          <span class="arrow-left"></span>
          <span class="symptom-desc-low">No tightness</span>
        </div>
        <div class="col text-end">
          <span class="arrow-right"></span>
          <span class="symptom-desc-high">My chest is very tight</span>
        </div>
      </div>
    </div>


    <div class="form-group">
      <fieldset>
        <legend><span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'sob'"></app-symptom-icon></span> &nbsp;Shortness of Breath</legend>
        <div class="radio-group">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sob"
              class="form-check-input"
              type="radio"
              required
              name="sob"
              id="sob0"
              value="0"
            />
            <label class="form-check-label" for="sob0">0</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sob"
              class="form-check-input"
              type="radio"
              required
              name="sob"
              id="sob1"
              value="1"
            />
            <label class="form-check-label" for="sob1">1</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sob"
              class="form-check-input"
              type="radio"
              required
              name="sob"
              id="sob2"
              value="2"
            />
            <label class="form-check-label" for="sob2">2</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sob"
              class="form-check-input"
              type="radio"
              required
              name="sob"
              id="sob3"
              value="3"
            />
            <label class="form-check-label" for="sob3">3</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sob"
              class="form-check-input"
              type="radio"
              required
              name="sob"
              id="sob4"
              value="4"
            />
            <label class="form-check-label" for="sob4">4</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sob"
              class="form-check-input"
              type="radio"
              required
              name="sob"
              id="sob5"
              value="5"
            />
            <label class="form-check-label" for="sob5">5</label>
          </div>
        </div>
      </fieldset>
      <div class="row form-instructions">
        <div class="col">
          <span class="arrow-left"></span>
          <span class="symptom-desc-low">No breathlessness</span>
        </div>
        <div class="col text-end">
          <span class="arrow-right"></span>
          <span class="symptom-desc-high">I am very breathless</span>
        </div>
      </div>
    </div>


    <div class="form-group" *ngIf="diagnosisMode == 'cardiac'">
      <fieldset>
        <legend><span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'ankle_swelling'"></app-symptom-icon></span> &nbsp;Ankle swelling</legend>
        <div class="radio-group">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.ankle_swelling"
              class="form-check-input"
              type="radio"
              required
              name="ankle_swelling"
              id="ankle_swelling0"
              value="0"
            />
            <label class="form-check-label" for="ankle_swelling0">0</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.ankle_swelling"
              class="form-check-input"
              type="radio"
              required
              name="ankle_swelling"
              id="ankle_swelling1"
              value="1"
            />
            <label class="form-check-label" for="ankle_swelling1">1</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.ankle_swelling"
              class="form-check-input"
              type="radio"
              required
              name="ankle_swelling"
              id="ankle_swelling2"
              value="2"
            />
            <label class="form-check-label" for="ankle_swelling2">2</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.ankle_swelling"
              class="form-check-input"
              type="radio"
              required
              name="ankle_swelling"
              id="ankle_swelling3"
              value="3"
            />
            <label class="form-check-label" for="ankle_swelling3">3</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.ankle_swelling"
              class="form-check-input"
              type="radio"
              required
              name="ankle_swelling"
              id="ankle_swelling4"
              value="4"
            />
            <label class="form-check-label" for="ankle_swelling4">4</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.ankle_swelling"
              class="form-check-input"
              type="radio"
              required
              name="ankle_swelling"
              id="ankle_swelling5"
              value="5"
            />
            <label class="form-check-label" for="ankle_swelling5">5</label>
          </div>
        </div>
      </fieldset>
      <div class="row form-instructions">
        <div class="col">
          <span class="arrow-left"></span>
          <span class="symptom-desc-low">No swelling at all</span>
        </div>
        <div class="col text-end">
          <span class="arrow-right"></span>
          <span class="symptom-desc-high">Swollen all the time</span>
        </div>
      </div>
    </div>



    <div class="form-group" *ngIf="diagnosisMode == 'respiratory' || diagnosisMode == 'cardiac'">
      <fieldset>
        <legend><span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'activity'"></app-symptom-icon></span> &nbsp;Everyday Activity</legend>
        <div class="radio-group">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.activity"
              class="form-check-input"
              type="radio"
              required
              name="activity"
              id="activity0"
              value="0"
            />
            <label class="form-check-label" for="activity0">0</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.activity"
              class="form-check-input"
              type="radio"
              required
              name="activity"
              id="activity1"
              value="1"
            />
            <label class="form-check-label" for="activity1">1</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.activity"
              class="form-check-input"
              type="radio"
              required
              name="activity"
              id="activity2"
              value="2"
            />
            <label class="form-check-label" for="activity2">2</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.activity"
              class="form-check-input"
              type="radio"
              required
              name="activity"
              id="activity3"
              value="3"
            />
            <label class="form-check-label" for="activity3">3</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.activity"
              class="form-check-input"
              type="radio"
              required
              name="activity"
              id="activity4"
              value="4"
            />
            <label class="form-check-label" for="activity4">4</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.activity"
              class="form-check-input"
              type="radio"
              required
              name="activity"
              id="activity5"
              value="5"
            />
            <label class="form-check-label" for="activity5">5</label>
          </div>
        </div>
      </fieldset>
      <div class="row form-instructions">
        <div class="col">
          <span class="arrow-left"></span>
          <span class="symptom-desc-low">No problems</span>
        </div>
        <div class="col text-end">
          <span class="arrow-right"></span>
          <span class="symptom-desc-high">Very difficult</span>
        </div>
      </div>
    </div>

    


    <div class="form-group" *ngIf="diagnosisMode !='covid'">
      <fieldset>
        <legend><span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'confidence'"></app-symptom-icon></span> &nbsp;Confidence</legend>

        <div class="radio-group">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.confidence"
              class="form-check-input"
              type="radio"
              required
              name="confidence"
              id="confidence0"
              value="0"
            />
            <label class="form-check-label" for="confidence0">0</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.confidence"
              class="form-check-input"
              type="radio"
              required
              name="confidence"
              id="confidence1"
              value="1"
            />
            <label class="form-check-label" for="confidence1">1</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.confidence"
              class="form-check-input"
              type="radio"
              required
              name="confidence"
              id="confidence2"
              value="2"
            />
            <label class="form-check-label" for="confidence2">2</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.confidence"
              class="form-check-input"
              type="radio"
              required
              name="confidence"
              id="confidence3"
              value="3"
            />
            <label class="form-check-label" for="confidence3">3</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.confidence"
              class="form-check-input"
              type="radio"
              required
              name="confidence"
              id="confidence4"
              value="4"
            />
            <label class="form-check-label" for="confidence4">4</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.confidence"
              class="form-check-input"
              type="radio"
              required
              name="confidence"
              id="confidence5"
              value="5"
            />
            <label class="form-check-label" for="confidence5">5</label>
          </div>
        </div>
      </fieldset>
      <div class="row form-instructions">
        <div class="col">
          <span class="arrow-left"></span>
          <span class="symptom-desc-low">I am confident ALL the time</span>
        </div>
        <div class="col text-end">
          <span class="arrow-right"></span>
          <span class="symptom-desc-high">I am NOT confident at all</span>
        </div>
      </div>
    </div>


    <div class="form-group" *ngIf="diagnosisMode =='covid'">
      <fieldset>
        <legend><span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'anxiety'"></app-symptom-icon></span> &nbsp;Anxiety</legend>

        <div class="radio-group">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.anxiety"
              class="form-check-input"
              type="radio"
              required
              name="anxiety"
              id="anxiety0"
              value="0"
            />
            <label class="form-check-label" for="anxiety0">0</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.anxiety"
              class="form-check-input"
              type="radio"
              required
              name="anxiety"
              id="anxiety1"
              value="1"
            />
            <label class="form-check-label" for="anxiety1">1</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.anxiety"
              class="form-check-input"
              type="radio"
              required
              name="anxiety"
              id="anxiety2"
              value="2"
            />
            <label class="form-check-label" for="anxiety2">2</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.anxiety"
              class="form-check-input"
              type="radio"
              required
              name="anxiety"
              id="anxiety3"
              value="3"
            />
            <label class="form-check-label" for="anxiety3">3</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.anxiety"
              class="form-check-input"
              type="radio"
              required
              name="anxiety"
              id="anxiety4"
              value="4"
            />
            <label class="form-check-label" for="anxiety4">4</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.anxiety"
              class="form-check-input"
              type="radio"
              required
              name="anxiety"
              id="anxiety5"
              value="5"
            />
            <label class="form-check-label" for="anxiety5">5</label>
          </div>
        </div>
      </fieldset>
      <div class="row form-instructions">
        <div class="col">
          <span class="arrow-left"></span>
          <span class="symptom-desc-low">I am NOT anxious at all</span>
        </div>
        <div class="col text-end">
          <span class="arrow-right"></span>
          <span class="symptom-desc-high">I am anxious ALL the time</span>
        </div>
      </div>
    </div>

    
    

    <div class="form-group">
      <fieldset>
        <legend><span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'sleep'"></app-symptom-icon></span> &nbsp;Sleep</legend>
        <div class="radio-group">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sleep"
              class="form-check-input"
              type="radio"
              required
              name="sleep"
              id="sleep0"
              value="0"
            />
            <label class="form-check-label" for="sleep0">0</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sleep"
              class="form-check-input"
              type="radio"
              required
              name="sleep"
              id="sleep1"
              value="1"
            />
            <label class="form-check-label" for="sleep1">1</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sleep"
              class="form-check-input"
              type="radio"
              required
              name="sleep"
              id="sleep2"
              value="2"
            />
            <label class="form-check-label" for="sleep2">2</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sleep"
              class="form-check-input"
              type="radio"
              required
              name="sleep"
              id="sleep3"
              value="3"
            />
            <label class="form-check-label" for="sleep3">3</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sleep"
              class="form-check-input"
              type="radio"
              required
              name="sleep"
              id="sleep4"
              value="4"
            />
            <label class="form-check-label" for="sleep4">4</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.sleep"
              class="form-check-input"
              type="radio"
              required
              name="sleep"
              id="sleep5"
              value="5"
            />
            <label class="form-check-label" for="sleep5">5</label>
          </div>
        </div>
      </fieldset>
      <div class="row form-instructions">
        <div class="col">
          <span class="arrow-left"></span>
          <span class="symptom-desc-low">I sleep soundly</span>
        </div>
        <div class="col text-end">
          <span class="arrow-right"></span>
          <span class="symptom-desc-high">I DON'T sleep soundly because of my condition</span>
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="diagnosisMode != 'covid'">
      <fieldset>
        <legend><span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'energy'"></app-symptom-icon></span> &nbsp;Energy</legend>
        <div class="radio-group">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.energy"
              class="form-check-input"
              type="radio"
              required
              name="energy"
              id="energy0"
              value="0"
            />
            <label class="form-check-label" for="energy0">0</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.energy"
              class="form-check-input"
              type="radio"
              required
              name="energy"
              id="energy1"
              value="1"
            />
            <label class="form-check-label" for="energy1">1</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.energy"
              class="form-check-input"
              type="radio"
              required
              name="energy"
              id="energy2"
              value="2"
            />
            <label class="form-check-label" for="energy2">2</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.energy"
              class="form-check-input"
              type="radio"
              required
              name="energy"
              id="energy3"
              value="3"
            />
            <label class="form-check-label" for="energy3">3</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.energy"
              class="form-check-input"
              type="radio"
              required
              name="energy"
              id="energy4"
              value="4"
            />
            <label class="form-check-label" for="energy4">4</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.energy"
              class="form-check-input"
              type="radio"
              required
              name="energy"
              id="energy5"
              value="5"
            />
            <label class="form-check-label" for="energy5">5</label>
          </div>
        </div>
      </fieldset>
      <div class="row form-instructions">
        <div class="col">
          <span class="arrow-left"></span>
          <span class="symptom-desc-low">LOTS of energy</span>
        </div>
        <div class="col text-end">
          <span class="arrow-right"></span>
          <span class="symptom-desc-high">No energy at all</span>
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="diagnosisMode == 'covid'">
      <fieldset>
        <legend><span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'fatigue'"></app-symptom-icon></span> &nbsp;Fatigue</legend>
        <div class="radio-group">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.fatigue"
              class="form-check-input"
              type="radio"
              required
              name="fatigue"
              id="fatigue0"
              value="0"
            />
            <label class="form-check-label" for="fatigue0">0</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.fatigue"
              class="form-check-input"
              type="radio"
              required
              name="fatigue"
              id="fatigue1"
              value="1"
            />
            <label class="form-check-label" for="fatigue1">1</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.fatigue"
              class="form-check-input"
              type="radio"
              required
              name="fatigue"
              id="fatigue2"
              value="2"
            />
            <label class="form-check-label" for="fatigue2">2</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.fatigue"
              class="form-check-input"
              type="radio"
              required
              name="fatigue"
              id="fatigue3"
              value="3"
            />
            <label class="form-check-label" for="fatigue3">3</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.fatigue"
              class="form-check-input"
              type="radio"
              required
              name="fatigue"
              id="fatigue4"
              value="4"
            />
            <label class="form-check-label" for="fatigue4">4</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.fatigue"
              class="form-check-input"
              type="radio"
              required
              name="fatigue"
              id="fatigue5"
              value="5"
            />
            <label class="form-check-label" for="fatigue5">5</label>
          </div>
        </div>
      </fieldset>
      <div class="row form-instructions">
        <div class="col">
          <span class="arrow-left"></span>
          <span class="symptom-desc-low">No fatigue at all</span>
        </div>
        <div class="col text-end">
          <span class="arrow-right"></span>
          <span class="symptom-desc-high">LOTS of fatigue</span>
        </div>
      </div>
    </div>

    

    <div class="form-group">
      <fieldset>
        <legend><span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'mood'"></app-symptom-icon></span> &nbsp;Mood</legend>

        <div class="radio-group">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.mood"
              class="form-check-input"
              type="radio"
              required
              name="mood"
              id="mood0"
              value="0"
            />
            <label class="form-check-label" for="mood0">0</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.mood"
              class="form-check-input"
              type="radio"
              required
              name="mood"
              id="mood1"
              value="1"
            />
            <label class="form-check-label" for="mood1">1</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.mood"
              class="form-check-input"
              type="radio"
              required
              name="mood"
              id="mood2"
              value="2"
            />
            <label class="form-check-label" for="mood2">2</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.mood"
              class="form-check-input"
              type="radio"
              required
              name="mood"
              id="mood3"
              value="3"
            />
            <label class="form-check-label" for="mood3">3</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.mood"
              class="form-check-input"
              type="radio"
              required
              name="mood"
              id="mood4"
              value="4"
            />
            <label class="form-check-label" for="mood4">4</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.mood"
              class="form-check-input"
              type="radio"
              required
              name="mood"
              id="mood5"
              value="5"
            />
            <label class="form-check-label" for="mood5">5</label>
          </div>
        </div>
      </fieldset>
      <div class="row form-instructions">
        <div class="col">
          <span class="arrow-left"></span>
          <span class="symptom-desc-low">My mood is NOT affected</span>
        </div>
        <div class="col text-end">
          <span class="arrow-right"></span>
          <span class="symptom-desc-high">I am LOW in mood all the time</span>
        </div>
      </div>
    </div>



    <div class="form-group" *ngIf="diagnosisMode == 'covid'">
      <fieldset>
        <legend><span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'memory'"></app-symptom-icon></span> &nbsp; Short term memory loss</legend>
        <div class="radio-group">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.memory"
              class="form-check-input"
              type="radio"
              required
              name="memory"
              id="memory0"
              value="0"
            />
            <label class="form-check-label" for="memory0">0</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.memory"
              class="form-check-input"
              type="radio"
              required
              name="memory"
              id="memory1"
              value="1"
            />
            <label class="form-check-label" for="memory1">1</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.memory"
              class="form-check-input"
              type="radio"
              required
              name="memory"
              id="memory2"
              value="2"
            />
            <label class="form-check-label" for="memory2">2</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.memory"
              class="form-check-input"
              type="radio"
              required
              name="memory"
              id="memory3"
              value="3"
            />
            <label class="form-check-label" for="memory3">3</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.memory"
              class="form-check-input"
              type="radio"
              required
              name="memory"
              id="memory4"
              value="4"
            />
            <label class="form-check-label" for="memory4">4</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.memory"
              class="form-check-input"
              type="radio"
              required
              name="memory"
              id="memory5"
              value="5"
            />
            <label class="form-check-label" for="memory5">5</label>
          </div>
        </div>
      </fieldset>
      <div class="row form-instructions">
        <div class="col">
          <span class="arrow-left"></span>
          <span class="symptom-desc-low">I have no problem remembering things</span>
        </div>
        <div class="col text-end">
          <span class="arrow-right"></span>
          <span class="symptom-desc-high">I have trouble remembering anything</span>
        </div>
      </div>
    </div>


    <div class="form-group">
      <fieldset>
        <legend><span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'appetite'"></app-symptom-icon></span> &nbsp;Appetite</legend>

        <div class="radio-group">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.appetite"
              class="form-check-input"
              type="radio"
              required
              name="appetite"
              id="appetite0"
              value="0"
            />
            <label class="form-check-label" for="appetite0">0</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.appetite"
              class="form-check-input"
              type="radio"
              required
              name="appetite"
              id="appetite1"
              value="1"
            />
            <label class="form-check-label" for="appetite1">1</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.appetite"
              class="form-check-input"
              type="radio"
              required
              name="appetite"
              id="appetite2"
              value="2"
            />
            <label class="form-check-label" for="appetite2">2</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.appetite"
              class="form-check-input"
              type="radio"
              required
              name="appetite"
              id="appetite3"
              value="3"
            />
            <label class="form-check-label" for="appetite3">3</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.appetite"
              class="form-check-input"
              type="radio"
              required
              name="appetite"
              id="appetite4"
              value="4"
            />
            <label class="form-check-label" for="appetite4">4</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.appetite"
              class="form-check-input"
              type="radio"
              required
              name="appetite"
              id="appetite5"
              value="5"
            />
            <label class="form-check-label" for="appetite5">5</label>
          </div>
        </div>
      </fieldset>
      <div class="row form-instructions">
        <div class="col">
          <span class="arrow-left"></span>
          <span class="symptom-desc-low">I have an appetite</span>
        </div>
        <div class="col text-end">
          <span class="arrow-right"></span>
          <span class="symptom-desc-high">I have NO appetite at all​</span>
        </div>
      </div>
    </div>



    <div class="form-group" *ngIf="diagnosisMode == 'cardiac'">
      <fieldset>
        <legend><span class="icon wrap symptom-icon"><app-symptom-icon [symptomId]="'surgery_pain'"></app-symptom-icon></span> &nbsp;Post Surgery Pain</legend>

        <div class="radio-group">
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.surgery_pain"
              class="form-check-input"
              type="radio"
              required
              name="surgery_pain"
              id="surgery_pain0"
              value="0"
            />
            <label class="form-check-label" for="surgery_pain0">0</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.surgery_pain"
              class="form-check-input"
              type="radio"
              required
              name="surgery_pain"
              id="surgery_pain1"
              value="1"
            />
            <label class="form-check-label" for="surgery_pain1">1</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.surgery_pain"
              class="form-check-input"
              type="radio"
              required
              name="surgery_pain"
              id="surgery_pain2"
              value="2"
            />
            <label class="form-check-label" for="surgery_pain2">2</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.surgery_pain"
              class="form-check-input"
              type="radio"
              required
              name="surgery_pain"
              id="surgery_pain3"
              value="3"
            />
            <label class="form-check-label" for="surgery_pain3">3</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.surgery_pain"
              class="form-check-input"
              type="radio"
              required
              name="surgery_pain"
              id="surgery_pain4"
              value="4"
            />
            <label class="form-check-label" for="surgery_pain4">4</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [(ngModel)]="symptomData.surgery_pain"
              class="form-check-input"
              type="radio"
              required
              name="surgery_pain"
              id="surgery_pain5"
              value="5"
            />
            <label class="form-check-label" for="surgery_pain5">5</label>
          </div>
        </div>
      </fieldset>
      <div class="row form-instructions">
        <div class="col">
          <span class="arrow-left"></span>
          <span class="symptom-desc-low">I have no pain</span>
        </div>
        <div class="col text-end">
          <span class="arrow-right"></span>
          <span class="symptom-desc-high">I have  pain all the time / sharp / dull ache</span>
        </div>
      </div>
    </div>

    <div class="mt-3 mb-3 text-center">
      <button class="btn btn-primary btn-block w-100" [disabled]="!form.valid">Save Trackers Baseline</button>
      
    </div>
  </form>
</div>
<div *ngIf="gettingStarted">
  <div class="signin-details">
    <div class="signin-details-flex">
      <div class="signin-left">
        © {{ currentYear }} University Hospitals of Leicester
      </div>
      <div class="signin-right">
        <a [routerLink]="['/getting-started/terms']"
        >Terms &amp; Conditions</a
      >&nbsp;|&nbsp; 
      <a [routerLink]="['/getting-started/privacy']">Privacy Policy</a>
      </div>
    </div>
  </div>
  <div class="">
    <div class="col footer-logos">
      <div class="programme-logo-strap">Incorporating the programmes</div>
      <div class="programme-logos">
        <img
          src="./assets/img/logo-ayh-colour.svg"
          alt="Activate Your Heart Logo"
          width="93"
        />
        <img
          src="./assets/img/logo-space-colour.svg"
          alt="SPACE for COPD"
          width="69"
        />
        <img
          src="./assets/img/logo-ycr-colour.svg"
          alt="Your COVID Recovery"
          width="93"
        />
      </div>
    </div>
  </div>
</div> 

