import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Subscription, forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth/service/auth.service';
import { UserService } from 'src/app/auth/service/user.service';
import { GoalsService } from 'src/app/goals/goals.service';
import { Symptoms } from 'src/app/models/symptoms.model';
import { UserSymptomsService } from 'src/app/services/user-symptoms.service';

@Component({
  selector: 'app-trackers-index',
  templateUrl: './trackers-index.component.html',
  styleUrls: ['./trackers-index.component.scss'],
})
export class TrackersIndexComponent implements OnInit {
  public symptoms: Symptoms[] = [];
  isLoading = false;
  error = null;
  private errorSub!: Subscription;
  taskStatus = false;
  taskId: any;
  userStage: any;
  userData!: any;
  stageHistories: any;

  // for feedback
  symptom: any;
  previousSymptom: any;
  public diagnosisMode: any;

  goals: any;
  openGoals: any;
  goalsToReview: any;
  symptomsBaseline:any;

  constructor(
    //private symptomsTrackerService: SymptomTrackerService,
    private datePipe: DatePipe,
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    public userSymptomsService: UserSymptomsService,
    private goalService: GoalsService
  ) {}

  ngOnInit(): void {
    /*
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userStage = user.stage;
      }
    });
*/

    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;

        if (this.userData.primary_diagnosis) {
          this.diagnosisMode = this.userService.getDiagnosisMode(
            this.userData.primary_diagnosis
          );
        }
      }
    });
    /*
    this.errorSub = this.symptomsTrackerService.error.subscribe(
      (errorMessage) => {
        this.error = errorMessage;
      }
    );
*/
    // check if there is a stage task
    this.isLoading = true;

    forkJoin({
      symptoms: this.userSymptomsService.fetchAll(),
      goals: this.goalService.fetchGoals(),
    }).subscribe(
      (responseData) => {
        this.isLoading = false;
        this.symptoms = responseData.symptoms;
        // are there any symptoms?

        // this.setChartData(this.symptoms);
        if (this.symptoms[0]) {
          this.symptom = this.symptoms[0];
        }
        if (this.symptoms[1]) {
          this.previousSymptom = this.symptoms[1];
        }

        this.symptoms.forEach((symptoms: any) => {
          if (symptoms.baseline == 1 && !this.symptomsBaseline) {
            this.symptomsBaseline = symptoms;
          }
        });

        this.goals = responseData.goals;
        this.openGoals = 0;
        this.goalsToReview = 0;
        let reviewedGoals = false;

        this.goals.forEach((goal:any) => {
          if (moment(goal.start) <= moment() && goal.status != 2) {
            this.goalsToReview++;
          }
          if (goal.status != 2) {
            this.openGoals++;
          }
        });
      },
      (error) => {
        // console.log(error);
      }
    );
  }

  ngOnDestroy() {}

  getSymptomScore(data: any, key: any) {
    if (data[key]) {
      return data[key];
    } else {
      return;
    }
  }

  checkSymptomForDiagnosis(symptomId: any) {
    let allowSymptom = true;

    if (
      this.diagnosisMode == 'cardiac' &&
      (symptomId == 'cough' ||
        symptomId == 'phlegm' ||
        symptomId == 'chest_tightness' ||
        symptomId == 'fatigue' ||
        symptomId == 'memory' ||
        symptomId == 'anxiety')
    ) {
      allowSymptom = false;
    }

    if (
      this.diagnosisMode == 'respiratory' &&
      (symptomId == 'ischaemic_pain' ||
        symptomId == 'ankle_swelling' ||
        symptomId == 'surgery_pain' ||
        symptomId == 'fatigue' ||
        symptomId == 'memory' ||
        symptomId == 'anxiety')
    ) {
      allowSymptom = false;
    }

    if (
      this.diagnosisMode == 'covid' &&
      (symptomId == 'ischaemic_pain' ||
        symptomId == 'ankle_swelling' ||
        symptomId == 'surgery_pain' ||
        symptomId == 'phlegm' ||
        symptomId == 'chest_tightness' ||
        symptomId == 'activity' ||
        symptomId == 'confidence' ||
        symptomId == 'energy')
    ) {
      allowSymptom = false;
    }

    return allowSymptom;
  }

  getLatestSymptomScore(symptoms: any, symptom: string) {
    let score: any = null;
    symptoms.forEach((symptomItem: any) => {
      if (symptomItem[symptom] != undefined && !score && score != 0) {
        score = symptomItem[symptom];
      }
    });
    return score;
  }
}
