
<div class="container relative">
    <app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
    <div class="row">
      <div class="col">
        <h3 class="sm-title mt-3 text-center">Managing expectations and realistic goal setting</h3>
        <div class="container-resource-page">
        <div *ngIf="resource" [innerHTML]="resource?.pages[0].content | safeHtml" (click)="onContentClick($event)"></div>
  
        <div class="text-center">
          <button class="btn btn-primary" [routerLink]="['/dashboard']">Back to Dashboard</button>
        </div>
        
      </div>
      </div>
    </div>
  </div>
  