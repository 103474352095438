import { UserAchievement } from './../../models/user-achievement.model';
import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-achievement-badge",
  templateUrl: "./achievement-badge.component.html",
  styleUrls: ["./achievement-badge.component.scss"],
})
export class AchievementBadgeComponent implements OnInit {
  @Input() achievement!: UserAchievement;
  constructor() {}

  ngOnInit(): void {
  }
}
