import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { Message } from 'src/app/models/message.model';
import { MessagesService } from 'src/app/services/messages.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  @ViewChild('form', { static: false }) messageForm!: NgForm;
  @ViewChild('message', { read: ElementRef }) message!: ElementRef;

  public messages: Message[] = [];
  isLoading = false;
  error:any;
  private errorSub!: Subscription;
  submitted = false;
  initialHeight = 0;
  gettingStarted = false;
  currentYear: number = new Date().getFullYear();

  constructor(
    private messagesService: MessagesService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    if(this.router.url == '/getting-started/support'){
      this.gettingStarted = true;
    }

    this.errorSub = this.messagesService.error.subscribe(
      (errorMessage) => {
        this.error = errorMessage;
      }
    );

    this.isLoading = true;
    this.messagesService.fetchMessages('s').subscribe(
      (messages) => {
        this.isLoading = false;
        this.messages = messages;
        this.initialHeight = this.message.nativeElement.style.height;
      },
      (error) => {
        this.isLoading = false;
        this.error = error.message;
      }
    );
  }

  onSubmit() {
    this.submitted = true;
    this.messagesService
    .createMessage(
      this.messageForm.value.message,
      's'
    )
    .subscribe(
      (responseData) => {
        const message = new Message(
          responseData.body?.message_id,
          responseData.body?.sender_id,
          responseData.body?.recipient_id,
          responseData.body?.message,
          responseData.body?.category,
          responseData.body?.is_admin,
          responseData.body?.created,
          responseData.body?.modified
          );
        this.messages.push(message);
        this.messageForm.reset();
        this.message.nativeElement.style.height = this.initialHeight;
        this.submitted = false;
      },
      (error) => {
        this.error = error.message;
      }
    );
  }


  public autoGrow() {
    const textArea = this.message.nativeElement;
    textArea.style.overflow = 'hidden';
    textArea.style.height = '0px';
    textArea.style.height = textArea.scrollHeight + 'px';
   }

}
