import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { UserService } from 'src/app/auth/service/user.service';
import { UserDataInterface } from 'src/app/auth/service/userData.interface';
import { AchievementService } from 'src/app/services/achievement.service';
import { ResourcesService } from 'src/app/services/resources.service';
import { UserTaskStatusService } from 'src/app/services/user-task-status.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-goals-managing',
  templateUrl: './goals-managing.component.html',
  styleUrls: ['./goals-managing.component.scss']
})
export class GoalsManagingComponent implements OnInit, OnDestroy {
  isLoading = false;
  userData!: UserDataInterface;
  resource: any;
  taskCompleted = false;

  constructor(
    private userService: UserService,
    private resourcesService: ResourcesService,
    private router: Router,
    private userTaskStatusService: UserTaskStatusService,
    private achievementService: AchievementService
  ) {}

  ngOnInit(): void {
    /*
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userStage = user.stage;
      }
    });*/
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
      }
    });

    this.isLoading = true;
    forkJoin({
      resource: this.resourcesService.fetchResource(
        environment.contentIds.managingGoals
      ),
    }).subscribe(
      (responseData) => {
        this.isLoading = false;
        this.resource = responseData.resource;
      },
      (error) => {
        this.isLoading = false;
        console.log(error);
      }
    );
  }

  ngOnDestroy() {
  }

  onContentClick(e: MouseEvent) {
    if (
      e.target instanceof HTMLAnchorElement &&
      e.target.host === location.host
    ) {
      e.preventDefault();
      this.router.navigateByUrl(e.target.pathname);
    }
  }
}
