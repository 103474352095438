<div *ngIf="!completed">
    <div
      class="internal-app card card-light  mt-3 mb-4"
    >
      <div class="card-body">
        <h4>Sleep Score</h4>
        <form
          (ngSubmit)="onSubmit()"
          #form="ngForm"
          class="sleep-score-form eq5d-form"
        >
          <fieldset>
            <ol>
              <li>
            <div class="form-group radio-group">
              
              <label
                ><h5>Thinking about a typical night in the last month, how long does
                it take you to fall asleep?</h5></label
              >
              <div class="tool-options">
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q1"
                      id="q1a"
                      value="4"
                      ngModel
                      #q1="ngModel"
                    />
                    <label class="form-check-label" for="q1a">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      0-15 minutes
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q1"
                      id="q1b"
                      value="3"
                      ngModel
                      #q1="ngModel"
                    />
                    <label class="form-check-label" for="q1b">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      16-30 minutes
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q1"
                      id="q1c"
                      value="2"
                      ngModel
                      #q1="ngModel"
                    />
                    <label class="form-check-label" for="q1c">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      31-45 minutes
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q1"
                      id="q1d"
                      value="1"
                      ngModel
                      #q1="ngModel"
                    />
                    <label class="form-check-label" for="q1d">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      46-60 minutes
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q1"
                      id="q1e"
                      value="0"
                      ngModel
                      #q1="ngModel"
                    />
                    <label class="form-check-label" for="q1e">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      More than 61 minutes
                    </label>
                  </div>
                </div>
              </div>
            </div>
            </li>
            <li>
            <div class="form-group radio-group">
              <label
                ><h5>Thinking about a typical night in the last month, if you wake up, how long are you awake for in total?</h5></label
              >
              <div class="tool-options">
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q2"
                      id="q2a"
                      value="4"
                      ngModel
                      #q2="ngModel"
                    />
                    <label class="form-check-label" for="q2a">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      0-15 minutes
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q2"
                      id="q2b"
                      value="3"
                      ngModel
                      #q2="ngModel"
                    />
                    <label class="form-check-label" for="q2b">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      16-30 minutes
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q2"
                      id="q2c"
                      value="2"
                      ngModel
                      #q2="ngModel"
                    />
                    <label class="form-check-label" for="q2c">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      31-45 minutes
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q2"
                      id="q2d"
                      value="1"
                      ngModel
                      #q2="ngModel"
                    />
                    <label class="form-check-label" for="q2d">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span> </span
                      >46-60 minutes
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q2"
                      id="q2e"
                      value="0"
                      ngModel
                      #q2="ngModel"
                    />
                    <label class="form-check-label" for="q2e">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      More than 61 minutes
                    </label>
                  </div>
                </div>
              </div>
            </div>
            </li>
            <li>
            <div class="form-group radio-group">
              <label
                ><h5>Thinking about the last month, how many nights a week do you have
                a problem with your sleep?</h5></label
              >
              <div class="tool-options">
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q3"
                      id="q3a"
                      value="4"
                      ngModel
                      #q3="ngModel"
                    />
                    <label class="form-check-label" for="q3a">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      0-1 nights
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q3"
                      id="q3b"
                      value="3"
                      ngModel
                      #q3="ngModel"
                    />
                    <label class="form-check-label" for="q3b">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      2 nights
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q3"
                      id="q3c"
                      value="2"
                      ngModel
                      #q3="ngModel"
                    />
                    <label class="form-check-label" for="q3c">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      3 nights
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q3"
                      id="q3d"
                      value="1"
                      ngModel
                      #q3="ngModel"
                    />
                    <label class="form-check-label" for="q3d">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      4 nights
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q3"
                      id="q3e"
                      value="0"
                      ngModel
                      #q3="ngModel"
                    />
                    <label class="form-check-label" for="q3e">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      5-7 nights
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="form-group radio-group">
              <label
                ><h5>Thinking about a typical night in the last month, how would you
                rate your sleep quality?</h5></label
              >
              <div class="tool-options">
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q4"
                      id="q4a"
                      value="4"
                      ngModel
                      #q4="ngModel"
                    />
                    <label class="form-check-label" for="q4a">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Very good
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q4"
                      id="q4b"
                      value="3"
                      ngModel
                      #q4="ngModel"
                    />
                    <label class="form-check-label" for="q4b">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Good
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q4"
                      id="q4c"
                      value="2"
                      ngModel
                      #q4="ngModel"
                    />
                    <label class="form-check-label" for="q4c">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Average
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q4"
                      id="q4d"
                      value="1"
                      ngModel
                      #q4="ngModel"
                    />
                    <label class="form-check-label" for="q4d">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Poor
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q4"
                      id="q4e"
                      value="0"
                      ngModel
                      #q4="ngModel"
                    />
                    <label class="form-check-label" for="q4e">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Very poor
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="form-group radio-group">
              <label
                ><h5>Thinking about the past month, to what extent has poor sleep
                affected your mood, energy, or relationships?</h5></label
              >
              <div class="tool-options">
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q5"
                      id="q5a"
                      value="4"
                      ngModel
                      #q5="ngModel"
                    />
                    <label class="form-check-label" for="q5a">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Not at all
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q5"
                      id="q5b"
                      value="3"
                      ngModel
                      #q5="ngModel"
                    />
                    <label class="form-check-label" for="q5b">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      A little
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q5"
                      id="q5c"
                      value="2"
                      ngModel
                      #q5="ngModel"
                    />
                    <label class="form-check-label" for="q5c">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Somewhat
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q5"
                      id="q5d"
                      value="1"
                      ngModel
                      #q5="ngModel"
                    />
                    <label class="form-check-label" for="q5d">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Much
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q5"
                      id="q5e"
                      value="0"
                      ngModel
                      #q5="ngModel"
                    />
                    <label class="form-check-label" for="q5e">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Very much
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="form-group radio-group">
              <label
                ><h5>Thinking about the past month, to what extent has poor sleep
                affected your concentration, productivity, or ability to stay
                awake?</h5></label
              >
              <div class="tool-options">
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q6"
                      id="q6a"
                      value="4"
                      ngModel
                      #q6="ngModel"
                    />
                    <label class="form-check-label" for="q6a">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Not at all
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q6"
                      id="q6b"
                      value="3"
                      ngModel
                      #q6="ngModel"
                    />
                    <label class="form-check-label" for="q6b">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      A little
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q6"
                      id="q6c"
                      value="2"
                      ngModel
                      #q6="ngModel"
                    />
                    <label class="form-check-label" for="q6c">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span> </span
                      >Somewhat
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q6"
                      id="q6d"
                      value="1"
                      ngModel
                      #q6="ngModel"
                    />
                    <label class="form-check-label" for="q6d">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Much
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q6"
                      id="q6e"
                      value="0"
                      ngModel
                      #q6="ngModel"
                    />
                    <label class="form-check-label" for="q6e">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Very much
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="form-group radio-group">
              <label
                ><h5>Thinking about the past month, to what extent has poor sleep
                troubled you in general?</h5></label
              >
              <div class="tool-options">
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q7"
                      id="q7a"
                      value="4"
                      ngModel
                      #q7="ngModel"
                    />
                    <label class="form-check-label" for="q7a">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Not at all
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q7"
                      id="q7b"
                      value="3"
                      ngModel
                      #q7="ngModel"
                    />
                    <label class="form-check-label" for="q7b">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      A little
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q7"
                      id="q7c"
                      value="2"
                      ngModel
                      #q7="ngModel"
                    />
                    <label class="form-check-label" for="q7c">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Somewhat
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q7"
                      id="q7d"
                      value="1"
                      ngModel
                      #q7="ngModel"
                    />
                    <label class="form-check-label" for="q7d">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Much
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q7"
                      id="q7e"
                      value="0"
                      ngModel
                      #q7="ngModel"
                    />
                    <label class="form-check-label" for="q7e">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Very much
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="form-group radio-group">
              <label><h5>How long have you had a problem with your sleep?</h5></label>
              <div class="tool-options">
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q8"
                      id="q8a"
                      value="4"
                      ngModel
                      #q8="ngModel"
                    />
                    <label class="form-check-label" for="q8a">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      I don't have a problem (or I've had a problem for less than
                      one month)
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q8"
                      id="q8b"
                      value="3"
                      ngModel
                      #q8="ngModel"
                    />
                    <label class="form-check-label" for="q8b">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span> </span
                      >1-2 months
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q8"
                      id="q8c"
                      value="2"
                      ngModel
                      #q8="ngModel"
                    />
                    <label class="form-check-label" for="q8c">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      3-6 months
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q8"
                      id="q8d"
                      value="1"
                      ngModel
                      #q8="ngModel"
                    />
                    <label class="form-check-label" for="q8d">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      7-12 months
                    </label>
                  </div>
                </div>
                <div>
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="radio"
                      required
                      name="q8"
                      id="q8e"
                      value="0"
                      ngModel
                      #q8="ngModel"
                    />
                    <label class="form-check-label" for="q8e">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Longer than a year
                    </label>
                  </div>
                </div>
              </div>
            </div>
            </li>
            </ol>
          </fieldset>
          <button class="btn btn-primary w-100 mb-4" [disabled]="!form.valid">
            Submit
            <!--<span class="icon-wrap"
              ><fa-icon [icon]="['far', 'check']"></fa-icon
            ></span>-->
          </button>
        </form>
      </div>
    </div>
  </div>
  <div
    class="internal-app card card-light  mt-3 mb-4 sleep-score-results"
    *ngIf="completed"
  >
    <div class="card-body">
      <h4>Sleep Score</h4>
      <div class="text-center">
        <h3 class="badge badge-xl text-center badge-primary">
          <span class="sr-only">Your sleep score is</span> {{ score }}
        </h3>
      </div>
  
      <p *ngIf="score > 26">
        <strong>Your sleep score is Very Good</strong><br>It seems that you are not experiencing
        symptoms of insomnia. You may still find some of the content in this
        section helpful
      </p>
      <p *ngIf="score > 24 && score < 27">
        <strong>Your sleep score is Good</strong><br>It seems that you are not experiencing
        significant symptoms of insomnia. You may still find some of the content
        in this section helpful
      </p>
      <p *ngIf="score > 15 && score < 25">
        <strong>Your sleep score is OK</strong><br>Some of the answers you have given indicate
        that you're experiencing a few symptoms of insomnia. We hope that you will
        find the content of this section helpful.
      </p>
      <p *ngIf="score > 9 && score < 16">
        <strong>Your sleep score is poor</strong><br>The answers you have given indicate that
        you're experiencing a number of symptoms of insomnia. We hope that you
        will find the content of this section helpful.
      </p>
      <p *ngIf="score < 10">
        <strong>Your sleep score is very poor</strong><br>The answers you have given indicate that
        you're experiencing a number of symptoms of insomnia. We hope that you
        will find the content of this section helpful. If your symptoms don’t
        improve during the course of the programme, then see the information about
        seeking further help at the end of this section.
      </p>
    </div>
  </div>
  <p>
    © Sleepio Ltd. reproduced under a Creative Commons Attribution Non-Commercial
    4.0 international license.
  </p>
  