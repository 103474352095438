import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { UserService } from '../auth/service/user.service';
import { Goal } from '../goals/goal.model';
import { GoalsService } from '../goals/goals.service';
import { CardioWorkout } from '../models/cardio-workout.model';
import { StrengthWorkout } from '../models/strength-workout.model';
import { Symptoms } from '../models/symptoms.model';
import { CardioWorkoutService } from '../services/cardio-workout.service';
import { DateHelperService } from '../services/date-helper.service';
import { StrengthWorkoutService } from '../services/strength-workout.service';
import { UserSymptomsService } from '../services/user-symptoms.service';
import { BitesizeSessionViewService } from '../services/bitesize-session-view.service';
import { BitesizeSessionService } from '../services/bitesize-session.service';
import { UserTaskStatusService } from '../services/user-task-status.service';
import { Eq5dService } from '../services/eq5d.service';
import { FeedbackService } from '../feedback/feedback.service';

@Component({
  selector: 'app-todo',
  templateUrl: './todo.component.html',
  styleUrls: ['./todo.component.scss'],
})
export class TodoComponent implements OnInit {
  isLoading = false;
  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public cardioData: {
    currentWeekData: [];
    daysHit: number;
    weeklyTarget: { days: number; threshold: number };
    workouts: CardioWorkout[];
  } = {
    currentWeekData: [],
    daysHit: 0,
    weeklyTarget: { days: 5, threshold: 10 },
    workouts: [],
  };
  public CardioCurrentWeekData!: any[];
  public today = this.dateHelperService.getToday();
  public cardioDaysHit = 0;
  public cardioWeeklyTarget = { days: 5, threshold: 10 };
  public goals!: Goal[];
  public goalsToReview = 0;
  public goalsReviewed = 0;
  public userData: any;
  currentWeekNumber!: number;
  actualWeekNumber!: number;

  public userSymptoms: Symptoms[] = [];
  public symptomsHigh = 0;
  public symptomsUsual = 0;
  public symptomsTotal = 0;

  reviewWeekNo = 8;
  reviewWeekNoEarly = 4;

  public strengthData: {
    currentWeekData: [];
    daysHit: number;
    weeklyTarget: { days: number; threshold: number };
    workouts: StrengthWorkout[];
  } = {
    currentWeekData: [],
    daysHit: 0,
    weeklyTarget:  this.strengthWorkoutService.weeklyTarget,
    workouts: [],
  };
  public StrengthCurrentWeekData!: any[];
  public strengthDaysHit = 0;
  public strengthWeeklyTarget = this.strengthWorkoutService.weeklyTarget;

  public tasksTotal = 6;
  public tasksComplete = 0;
  public bitesizeSessions: any;
  public currentBitesizeSession: any;
  public bitesizeSessionViews: any;
  eq5dResponses: any;
  eq5dCompletedThisWeek: any = false;
  eq5dResponses4: any;
  feedbackResponses: any;
  feedbackCompletedThisWeek:any = false;
  readingListReviewed = false;

  constructor(
    private cardioWorkoutService: CardioWorkoutService,
    private dateHelperService: DateHelperService,
    private goalService: GoalsService,
    private userService: UserService,
    private strengthWorkoutService: StrengthWorkoutService,
    private userSymptomsService: UserSymptomsService,
    private bitesizeSessionService: BitesizeSessionService,
    public bitesizeSessionViewService: BitesizeSessionViewService,
    private userTaskStatusService: UserTaskStatusService,
    private eq5dService: Eq5dService,
    private feedbackService: FeedbackService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    const startDate = this.currentWeek[0];
    const endDate = this.currentWeek[6];
    if (!this.userService.userData.getValue()) {
      this.userService.userData.subscribe((userData) => {
        if (userData) {
          this.userData = userData;
          this.currentWeekNumber = +userData.current_week;
          this.actualWeekNumber = +userData.current_week;
        }
      });
    } else {
      this.userData = this.userService.userData.getValue();
      this.currentWeekNumber = this.userData.current_week;
      this.actualWeekNumber = this.userData.current_week;
    }

    forkJoin({
      cardioWorkouts: this.cardioWorkoutService.fetchBetween(
        startDate,
        endDate
      ),
      strengthWorkouts: this.strengthWorkoutService.fetchBetween(
        startDate,
        endDate
      ),
      goals: this.goalService.fetchGoals(),
      // targets: this.userTargetService.fetchAll(),

      userSymptoms: this.userSymptomsService.fetchAll(),
      bitesizeSessions: this.bitesizeSessionService.fetchAll(),
      bitesizeSessionViews: this.bitesizeSessionViewService.fetchAll(),
      userTaskStatuses: this.userTaskStatusService.fetchAll(),
      eq5dResponses: this.eq5dService.fetchResponse(8),
      eq5dResponses4: this.eq5dService.fetchResponse(4),
      feedbackResponses: this.feedbackService.fetchResponse(),
    }).subscribe((responseData) => {
      this.isLoading = false;
      this.cardioData.workouts = responseData.cardioWorkouts;
      this.cardioData.currentWeekData =
        this.cardioWorkoutService.generateWeekArray(
          this.currentWeek,
          this.cardioData.workouts
        );
      this.cardioDaysHit = this.cardioWorkoutService.getDaysHit(
        this.cardioData.currentWeekData
      );
      if (this.cardioDaysHit >= this.cardioWeeklyTarget.days) {
        this.tasksComplete++;
      }

      this.strengthData.workouts = responseData.strengthWorkouts;
      this.strengthData.currentWeekData =
        this.strengthWorkoutService.generateWeekArray(
          this.currentWeek,
          this.strengthData.workouts
        );
      this.strengthDaysHit = this.strengthWorkoutService.getDaysHit(
        this.strengthData.currentWeekData
      );

      if (this.strengthDaysHit >= this.strengthWeeklyTarget.days) {
        this.tasksComplete++;
      }

      /*
      this.goals = responseData.goals;
      this.goalsToReview = this.goals.reduce(
        (acc, cur) => (cur.status !== 1 ? ++acc : acc),
        0
      );

      this.goalsReviewed = this.goals.reduce(
        (acc, cur) =>
          cur.status !== 1 && moment(cur.modified) > moment(startDate)
            ? ++acc
            : acc,
        0
      );
      if (this.goalsReviewed == this.goalsToReview) {
        this.tasksComplete++;
      }
      */

      this.goals = responseData.goals;
      let openGoals = 0;

      this.goals.forEach((goal) => {
        if (
          moment(goal.modified) > moment(startDate) ||
          moment(goal.created) > moment(startDate)
        ) {
          this.goalsReviewed = 1;
        }
        if (goal.status !== 2) {
          openGoals++;
        }
      });

      if (this.goalsReviewed == 1) {
        this.tasksComplete++;
      }

      this.userSymptoms = this.userSymptomsService.getBewteen(
        responseData.userSymptoms,
        startDate,
        endDate
      );
      if (this.userSymptoms[0]) {
        this.tasksComplete++;
        this.userSymptomsService.symptomsArray.forEach((symptomInf) => {
          let symptomScore = null;
          symptomScore = this.userSymptomsService.getSymptomScore(
            this.userSymptoms[0],
            symptomInf.id
          );
          if (symptomScore) {
            this.symptomsTotal++;

            if (symptomScore >= 3) {
              this.symptomsHigh++;
            } else {
              this.symptomsUsual++;
            }
          }
        });
      }

      // bitesize sessions
      this.bitesizeSessions = responseData.bitesizeSessions;

      let bitesizeWeek = +this.currentWeekNumber - 1;
      if (this.userData.smoker == 1) {
        // bitesizeWeek++;
      }
      if (this.bitesizeSessions[bitesizeWeek]) {
        this.currentBitesizeSession = this.bitesizeSessions[bitesizeWeek];
      }

      this.bitesizeSessionViews = responseData.bitesizeSessionViews;

      // is this task complete?
      if (
        this.currentBitesizeSession &&
        this.bitesizeSessionViewService.checkViewed(
          this.bitesizeSessionViews,
          this.currentBitesizeSession.bitesize_session_id
        )
      ) {
        this.tasksComplete++;
      }

      this.eq5dResponses = responseData.eq5dResponses;
      this.eq5dResponses4 = responseData.eq5dResponses4;
      this.feedbackResponses = responseData.feedbackResponses;

      if (!this.currentBitesizeSession) {
        this.tasksTotal--;
      }

      // are we on week 8?
      if (this.currentWeekNumber == this.reviewWeekNo) {
        // add the tasks
        this.tasksTotal += 2;

        if (this.eq5dResponses) {
          this.tasksComplete++;
        }
        if (this.feedbackResponses) {
          this.tasksComplete++;
        }
      }
      if (this.currentWeekNumber > this.reviewWeekNo) {
        if (!this.eq5dResponses) {
          this.tasksTotal++;
        } else {
          if (this.eq5dResponses?.created) {
            if (
              moment(this.eq5dResponses.created).format('YYYY-MM-DD') >=
                moment(this.currentWeek[0]).format('YYYY-MM-DD') &&
              moment(this.eq5dResponses.created).format('YYYY-MM-DD') <=
                moment(this.currentWeek[6]).format('YYYY-MM-DD')
            ) {
              this.eq5dCompletedThisWeek = true;
              this.tasksTotal++;
              this.tasksComplete++;
            }
          }
        }
        if (!this.feedbackResponses) {
          this.tasksTotal++;
        } else {
          if (this.feedbackResponses?.created) {
            if (
              moment(this.feedbackResponses.created).format('YYYY-MM-DD') >=
                moment(this.currentWeek[0]).format('YYYY-MM-DD') &&
              moment(this.feedbackResponses.created).format('YYYY-MM-DD') <=
                moment(this.currentWeek[6]).format('YYYY-MM-DD')
            ) {
              this.feedbackCompletedThisWeek = true;
              this.tasksTotal++;
              this.tasksComplete++;
            }
          }
        }
      }

      // are we on week 4?
      /*if (this.currentWeekNumber == this.reviewWeekNoEarly) {
        // add the tasks
        this.tasksTotal += 1;

        if (this.eq5dResponses4) {
          this.tasksComplete++;
        }
      }*/

      // reading list
      responseData.userTaskStatuses.forEach((taskStatus) => {
        if (taskStatus.task_id == 'readingList' + this.currentWeekNumber) {
          this.readingListReviewed = true;
        }
      });

      if (this.readingListReviewed == true) {
        this.tasksComplete++;
      }
    });
  }

  getTotalBitesize() {
    // if (this.userData?.smoker == 1) {
    return this.bitesizeSessions.length;
    // } else {
    //   return this.bitesizeSessions.length;
    // }
  }
}
