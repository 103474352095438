import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { forkJoin } from 'rxjs';
import { UserService } from '../auth/service/user.service';
import { DateHelperService } from '../services/date-helper.service';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UserTaskStatusService } from '../services/user-task-status.service';
import { UserTaskStatus } from '../models/user-task-status.model';
import { UserResourceService } from '../services/user-resource.service';
import { AuthService } from '../auth/service/auth.service';






@Component({
  selector: 'app-getting-started',
  templateUrl: './getting-started.component.html',
  styleUrls: ['./getting-started.component.scss'],
})
export class GettingStartedComponent implements OnInit {
  isLoading = false;
  currentYear: number = new Date().getFullYear();
  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public today = this.dateHelperService.getToday();
  public showMessage = true;
  public userData: any;
  public modalRef!: BsModalRef | null;
  public userTaskStatuses!: UserTaskStatus[];
  public userTasksStatusById: any;
  public taskIds = [
    'welcomeVideo',
    'eq5dPre',
    'readGoals',
   // 'setGoal',
   'healthStatusBaseline',
   //'readingList',
   // 'createActionPlan',
    'readActivity',
    'exerciseSafetyQuiz',
  ];

  public tasksComplete = 0;
  public tasksCompleteTasks:any = [];

  constructor(
    private dateHelperService: DateHelperService,
    private authService: AuthService,
    private userService: UserService,
    private modalService: BsModalService,
    private userTaskStatusService: UserTaskStatusService
  ) {
    this.userTasksStatusById = {};
    this.taskIds.forEach((taskId) => {
      this.userTasksStatusById[taskId] = 0;
    });
  }

  ngOnInit(): void {
    this.isLoading = true;
    const startDate = this.currentWeek[0];
    const endDate = this.currentWeek[6];

    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
      
        if (this.userData.primary_diagnosis && this.taskIds.indexOf('createActionPlan') == -1) {
          let diagnosisMode = this.userService.getDiagnosisMode(this.userData.primary_diagnosis);
          if(diagnosisMode == 'respiratory' || 
          this.userData.primary_diagnosis == 6 //HF
          ){
            this.taskIds.push('createActionPlan');
          }
        }
      }
    });

    this.userTaskStatusService.clearCache();
    forkJoin({
      userTaskStatuses: this.userTaskStatusService.fetchAll(),
      // targets: this.userTargetService.fetchAll(),
    }).subscribe((responseData) => {
      this.isLoading = false;
      this.userTaskStatuses = responseData.userTaskStatuses;
      this.userTaskStatuses.forEach((userTaskStatus) => {
        this.userTasksStatusById[userTaskStatus.task_id] =
          userTaskStatus.status;
          if (this.taskIds.indexOf(userTaskStatus.task_id) != -1 && this.tasksCompleteTasks.indexOf(userTaskStatus.task_id) == -1) {
            this.tasksComplete++;
            this.tasksCompleteTasks.push(userTaskStatus.task_id);
          }
      });

      if (!this.userTasksStatusById['introMessage']) {
       
      this.onShowIntroMessage();
        // save this now
        this.userTaskStatusService
          .create('introMessage', 1)
          .subscribe((responseData) => {});
      }
    });
  }

  openModal(event: any, template: TemplateRef<any>) {
    event.preventDefault();
    this.modalRef = this.modalService.show(
      template,
      Object.assign({}, { class: 'modal-lg' })
    );
  }
  onCloseModal(event: any) {
    this.modalRef?.hide();
    // update the task statuses
    forkJoin({
      userTaskStatuses: this.userTaskStatusService.fetchAll(),
      // targets: this.userTargetService.fetchAll(),
    }).subscribe((responseData) => {
      this.isLoading = false;
      this.userTaskStatuses = responseData.userTaskStatuses;
      this.userTaskStatuses.forEach((userTaskStatus) => {
        this.userTasksStatusById[userTaskStatus.task_id] =
          userTaskStatus.status;
        if (this.taskIds.indexOf(userTaskStatus.task_id) != -1 && this.tasksCompleteTasks.indexOf(userTaskStatus.task_id) == -1) {
          this.tasksComplete++;
          this.tasksCompleteTasks.push(userTaskStatus.task_id);
        }
      });

      console.log('hi');
      console.log(this.tasksCompleteTasks);
    });
  }
  onShowIntroMessage(){
    Swal.fire({
      icon: 'info',
      title: "Let's get you up and running!",
      html: 'Complete the following tasks to get started on the programme.  If you need any help please <a href="/chat">get in touch</a>',
    });
  }
  onSideAction(){
    this.onShowIntroMessage();
  }
  onLogout(): void {
    this.authService.logout();
  }
}
