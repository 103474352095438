import { AuthService } from './../service/auth.service';
import { UserService } from './../service/user.service';

import Swal from 'sweetalert2/src/sweetalert2.js';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-upload-avatar',
  templateUrl: './upload-avatar.component.html',
  styleUrls: ['./upload-avatar.component.scss'],
})
export class UploadAvatarComponent implements OnInit {
  fileData!: File;
  previewUrl: any;
  fileUploadProgress?: string;
  uploadedFilePath?: string;
  constructor(
    private authService: AuthService,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit(): void {}

  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    this.preview();
  }

  preview() {
    // Show preview
    const mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(this.fileData);
    reader.onload = (_event) => {
      this.previewUrl = reader.result;
    };
  }

  onSubmit() {
    const formData = new FormData();
    formData.append('avatar', this.fileData);

    this.fileUploadProgress = '0%';
    this.authService.upload(formData).subscribe(
      (events:any) => {
        if (events.type === HttpEventType.UploadProgress) {
          this.fileUploadProgress =
            Math.round((events.loaded / events.total) * 100) + '%';
          console.log(this.fileUploadProgress);
        } else if (events.type === HttpEventType.Response) {
          this.fileUploadProgress = '';

          // update use data
          this.userService.reset();
          this.userService.get().subscribe((userData:any) => {
          });
          
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Avatar updated',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            toast: true,
          });
          this.router.navigate(['/dashboard']);
        }
      },
      (error:any) => {
        Swal.fire({
          position: 'top-end',
          icon: 'error',
          title: 'There was an error uploading your file',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          toast: true,
        });
      }
    );
  }
}
