<ng-container *ngIf="isLoading">
  <app-loading-spinner
  ></app-loading-spinner>
</ng-container>
<ng-container *ngIf="!isLoading">
<!-- masthead will go here -->
<div class="dashboard-masthead-container" *ngIf="showMasthead">
  <!--<button class="dashboard-masthead-close" (click)="onCloseMasthead($event)">
  <span class="sr-only">Close</span
  ><span class="icon-wrap"
    ><fa-icon [icon]="['fas', 'times']"></fa-icon
  ></span>
</button>-->
<swiper
  [config]="config"
  (swiper)="onSwiper($event)"
  (slideChange)="onSlideChange()"
>
  <ng-template swiperSlide *ngIf="strengthData && strengthData.currentWeekData">
    <app-dashboard-masthead-strength
      [currentWeekData]="strengthData.currentWeekData"
      [strengthWeeklyTarget]="strengthWeeklyTarget"
      [strengthDaysHit]="strengthDaysHit"
    ></app-dashboard-masthead-strength>
  </ng-template>
  <ng-template swiperSlide *ngIf="cardioData">
    <app-dashboard-masthead-cardio
      [currentWeekData]="cardioData.currentWeekData"
      [cardioWeeklyTarget]="cardioWeeklyTarget"
      [cardioDaysHit]="cardioDaysHit"
    ></app-dashboard-masthead-cardio>
  </ng-template>
  <ng-template swiperSlide *ngIf="latestSymptomScore">
    <app-dashboard-masthead-health
      [symptomsHigh]="symptomsHigh"
      [latestSymptomScore]="latestSymptomScore"
      [baselineScores]="baselineScores"
    ></app-dashboard-masthead-health>
  </ng-template>
  <ng-template swiperSlide *ngIf="currentBitesizeSession">
    <app-dashboard-masthead-bitesize
      [currentBitesizeSession]="currentBitesizeSession"
      [currentBitesizeNumber]="currentWeekNumber"
      [totalBitesize]="getTotalBitesize()"
    ></app-dashboard-masthead-bitesize>
  </ng-template>
  <ng-template
    swiperSlide
    *ngIf="goalsToReview > 0 || openGoals > 0 || goalsComplete > 0"
  >
    <app-dashboard-masthead-goals
      [openGoals]="openGoals"
      [goalsToReview]="goalsToReview"
      [goalsComplete]="goalsComplete"
    ></app-dashboard-masthead-goals>
  </ng-template>
</swiper>
  
</div>
<nav class="page-header bg-light">
  <div>
    <!--span class="icon-wrap" (click)="onPrevWeek()"
      ><fa-icon [icon]="['fal', 'chevron-left']"></fa-icon
    ></span-->
  </div>
  <div>
    <h2 *ngIf="currentWeekNumber">Week {{ currentWeekNumber }}</h2>
    <div class="subtitle">
      {{ currentWeek[0] | customDate : "ddd Do MMM" }} -
      {{ currentWeek[6] | customDate : "ddd Do MMM" }}

      <div *ngIf="currentWeekNumber != actualWeekNumber">
        <a (click)="onResetWeek()" class="text-primary cursor"
          >Go to current week</a
        >
      </div>
    </div>
  </div>
  <div>
    <!--span class="icon-wrap" (click)="onNextWeek()"
      ><fa-icon [icon]="['fal', 'chevron-right']"></fa-icon
    ></span-->
  </div>
</nav>
<div class="main-content-area bg-light">
  <ul class="dashboard-flex-layout tracker-list tracker-list-padded">
    <li>
      <a [routerLink]="['/programme', 'task-list']">
        <span class="icon-wrap tracker-icon">
          <img
            src="/assets/img/placeholder-todo.jpg"
            alt=""
            width="60"
            class="rounded"
          />
        </span>
        <span class="tracker-info">
          <span class="tracker-title">Weekly To-Do List </span>

          <span class="progress progress-dividers">
            <span
              *ngFor="let _ of [].constructor(tasksTotal); let index = index"
              class="progress-bar"
              [ngClass]="{
                'bg-success': index < tasksComplete,
                'bg-field': index >= tasksComplete
              }"
              role="progressbar"
              style="width: {{ 100 / tasksTotal }}%"
              aria-valuenow="25"
              [attr.aria-label]="100 / tasksTotal + '%'"
              aria-valuemin="0"
              aria-valuemax="100"
            ></span>
          </span>

          <span class="details"
            ><b>{{ tasksComplete }} of {{ tasksTotal }}</b> tasks
            completed</span
          >
        </span>
        <span class="icon-wrap float-end icon-more"
          ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
        ></span>
      </a>
    </li>
    <li *ngIf="currentBitesizeSession">
      <a
        [routerLink]="['/bitesize', currentBitesizeSession.bitesize_session_id]"
      >
        <span class="icon-wrap tracker-icon">
          <img
            src="/assets/img/placeholder-refresh.jpg"
            alt=""
            width="60"
            class="rounded"
          />
        </span>
        <span class="tracker-info">
          <span class="tracker-title">{{ currentBitesizeSession.title }}</span>

          <span class="details"
            >Bitesize Session ({{ currentWeekNumber }} of
            {{ getTotalBitesize() }})</span
          >
        </span>
        <span class="icon-wrap float-end icon-more"
          ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
        ></span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/exercise', 'cardio']">
        <span class="icon-wrap tracker-icon">
          <img
            src="/assets/img/placeholder-cardio.jpg"
            alt=""
            width="60"
            class="rounded"
          />
        </span>
        <span class="tracker-info">
          <span class="tracker-title">Cardio Exercise Target</span>

          <ng-container *ngIf="isLoading">
            <app-loading-spinner
              [inline]="true"
              [small]="true"
            ></app-loading-spinner>
          </ng-container>
          <span class="progress progress-dividers" *ngIf="!isLoading">
            <span
              *ngFor="
                let num of [].constructor(cardioWeeklyTarget.days);
                let index = index
              "
              class="progress-bar"
              [ngClass]="{
                'bg-success': index < cardioDaysHit,
                'bg-field': index >= cardioDaysHit
              }"
              role="progressbar"
              style="width: {{ 100 / cardioWeeklyTarget.days }}%"
              [attr.aria-valuenow]="100 / cardioWeeklyTarget.days"
              [attr.aria-label]="100 / cardioWeeklyTarget.days + '%'"
              aria-valuemin="0"
              aria-valuemax="100"
            ></span>
          </span>

          <span class="details" *ngIf="!isLoading"
            ><b>{{ cardioDaysHit }} of {{ cardioWeeklyTarget.days }}</b> days
            completed</span
          >
        </span>
        <span class="icon-wrap float-end icon-more"
          ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
        ></span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/exercise', 'strength']">
        <span class="icon-wrap tracker-icon">
          <img
            src="/assets/img/placeholder-strength.jpg"
            alt=""
            width="60"
            class="rounded"
          />
        </span>
        <span class="tracker-info">
          <span class="tracker-title">Strength Exercise Target</span>
          <div *ngIf="isLoading" class="text-center">
            <app-loading-spinner
              [inline]="true"
              [small]="true"
            ></app-loading-spinner>
          </div>
          <span class="progress progress-dividers" *ngIf="!isLoading">
            <span
              *ngFor="
                let num of [].constructor(strengthWeeklyTarget.days);
                let index = index
              "
              class="progress-bar"
              [ngClass]="{
                'bg-success': index < strengthDaysHit,
                'bg-field': index >= strengthDaysHit
              }"
              role="progressbar"
              style="width: {{ 100 / strengthWeeklyTarget.days }}%"
              [attr.aria-valuenow]="100 / strengthWeeklyTarget.days"
              [attr.aria-label]="100 / strengthWeeklyTarget.days + '%'"
              aria-valuemin="0"
              aria-valuemax="100"
            ></span>
          </span>

          <span class="details" *ngIf="!isLoading"
            ><b>{{ strengthDaysHit }} of {{ strengthWeeklyTarget.days }}</b>
            days completed</span
          >
        </span>
        <span class="icon-wrap float-end icon-more"
          ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
        ></span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/trackers']">
        <span class="icon-wrap tracker-icon">
          <img
            src="/assets/img/placeholder-symptoms.jpg"
            alt=""
            width="60"
            class="rounded"
          />
        </span>
        <span class="tracker-info">
          <span class="tracker-title">Health Trackers</span>

          <div *ngIf="userSymptoms.length == 0">
            <span class="details">Update your Trackers</span>
          </div>

          <div *ngIf="userSymptoms.length > 0">
            <span class="progress progress-dividers" *ngIf="symptomsHigh > 0">
              <!--span
                *ngFor="
                  let _ of [].constructor(symptomsTotal);
                  let index = index
                "
                class="progress-bar"
                [ngClass]="{
                  'bg-field': index < symptomsUsual,
                  'bg-danger': index >= symptomsUsual
                }"
                role="progressbar"
                style="width: 14.285714%"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              ></span-->
              <span
                *ngFor="
                  let latestScore of latestSymptomScore
                    | keyvalue : valueAscOrder;
                  let index = index
                "
                class="progress-bar {{
                  getSymptomClass(latestScore.key, latestScore.value)
                }}"
                role="progressbar"
                style="width: 14.285714%"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
                aria-label="symptom block"
              ></span>
            </span>

            <span class="progress progress-dividers" *ngIf="symptomsHigh == 0">
              <span
                *ngFor="
                  let latestScore of latestSymptomScore
                    | keyvalue : valueAscOrder;
                  let index = index
                "
                class="progress-bar bg-symptom-{{ latestScore.value }}"
                role="progressbar"
                style="width: 14.285714%"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
                aria-label="symptom block"
              ></span>
            </span>

            <span class="details">
              <ng-container
                *ngIf="
                  symptomsHigh > 0 &&
                  userSymptoms[0].created <
                    (currentWeek[0] | customDate : 'YYYY-MM-DD')
                "
              >
                <b>{{ symptomsHigh }}</b> tracker<span *ngIf="symptomsHigh != 1"
                  >s</span
                >
                were at a high level on
                {{ userSymptoms[0].created | customDate : "do MMM" }} for you.
                Please update.
              </ng-container>
              <ng-container
                *ngIf="
                  symptomsHigh > 0 &&
                  userSymptoms[0].created >=
                    (currentWeek[0] | customDate : 'YYYY-MM-DD')
                "
              >
                You have <b>{{ symptomsHigh }}</b> tracker<span
                  *ngIf="symptomsHigh != 1"
                  >s</span
                >
                at a high level for you
              </ng-container>

              <ng-container *ngIf="symptomsHigh < 1">
                Are your tracker levels still at a low range?
              </ng-container>
            </span>
          </div>
        </span>
        <span class="icon-wrap float-end icon-more"
          ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
        ></span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/goals']">
        <span class="icon-wrap tracker-icon">
          <img
            src="/assets/img/placeholder-goals.jpg"
            alt=""
            width="60"
            class="rounded"
          />
        </span>
        <span class="tracker-info">
          <span class="tracker-title">Current Goals</span>

          <span class="details" *ngIf="goalsToReview > 0">
            You have
            <b>{{ goalsToReview }}</b> goal<span *ngIf="goalsToReview != 1"
              >s</span
            >
            to review</span
          >
          <span
            class="details"
            *ngIf="
              goalsToReview == 0 && openGoals >= 0 && goals && goals.length > 0
            "
            >You have <b>{{ openGoals }}</b> goal<span *ngIf="openGoals != 1"
              >s</span
            >
            in progress. Add another?</span
          >
          <span
            class="details"
            *ngIf="
              goalsToReview == 0 && openGoals == 0 && goals && goals.length > 0
            "
            >You currently have no goals. Please add one.</span
          >
          <span class="details" *ngIf="goals?.length == 0"
            >You currently have no goals. Please add one.</span
          >
        </span>
        <span class="icon-wrap float-end icon-more"
          ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
        ></span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/achievements']">
        <span class="icon-wrap tracker-icon">
          <img
            src="/assets/img/placeholder-achievements.jpg"
            alt=""
            width="60"
            class="rounded"
          />
        </span>
        <span class="tracker-info">
          <span class="tracker-title">Recent Achievements</span>

          <span class="details"
            ><b>{{ recentAchievements }}</b> badges earned this week</span
          >
        </span>
        <span class="icon-wrap float-end icon-more"
          ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
        ></span>
      </a>
    </li>
  </ul>
</div>
</ng-container>