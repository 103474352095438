import { KeyValue } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { DateHelperService } from 'src/app/services/date-helper.service';

@Component({
  selector: 'app-dashboard-masthead-health',
  templateUrl: './dashboard-masthead-health.component.html',
  styleUrls: ['./dashboard-masthead-health.component.scss']
})
export class DashboardMastheadHealthComponent implements OnInit {
  show = true;
  @Input() symptomsHigh: any;
  @Input() latestSymptomScore: any;
  @Input() baselineScores:any

  public today = this.dateHelperService.getToday();

  constructor(private dateHelperService: DateHelperService) {}

  ngOnInit(): void {
    
  }
  onClose(event:any){
    this.show = false;
  }


  getSymptomClass(id: any, score: any) {
    let bgClass = 'bg-field';
    if (this.baselineScores) {
      if (this.baselineScores[id] != undefined) {
        if (score > this.baselineScores[id] && score >= 3) {
          bgClass = 'bg-symptom-' + score;
        }
      }
    }
    return bgClass;
  }

   // Preserve original property order
   originalOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return 0;
  };

  // Order by ascending property value
  valueAscOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    return a.value?.toString().localeCompare(b.value);
  };
}
