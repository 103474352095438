
<div class="relative">
  <app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>

  <div class="img-container">
    <img
      src="{{ page?.image_filename | getThumbnail: 'wide' }}"
      alt="Image showing {{ page?.title }}"
      class="img-fluid stage-nav-img fade-in d-block d-sm-none"
      *ngIf="page?.image_filename"
    />
    <img
      src="{{ page?.image_filename | getThumbnail: 'wide' }}"
      alt="Image showing {{ page?.title }}"
      class="img-fluid stage-nav-img fade-in d-none d-sm-block drop-shadow rounded"
      *ngIf="page?.image_filename"
    />
    <div *ngIf="page?.page_type_id == '2'">
      <video
        width="100%"
        poster="{{ page.content.posterUrl }}"
        controls
        preload
        class="fade-in rounded drop-shadow"
      >
        <source src="{{ page.content.videoUrl }}" type="video/mp4" />
        <track
          src="{{ page.content.captionsUrl }}"
          kind="subtitles"
          srclang="en"
          label="English"
          default
          *ngIf="page.content.captionsUrl"
        />
      </video>
    </div>
  </div>
  <div class="container mt-4 container-resource-page">
    <h3 class="sm-title mb-4 has-prev-next">
      {{ page?.title }}
    </h3>
    <div *ngIf="resourceId == 28">
      <app-terms-content></app-terms-content>
    </div>
    <div *ngIf="resourceId == 27">
      <app-privacy-content></app-privacy-content>
    </div>

    <div *ngIf="resourceId != 28 && resourceId != 27">
      <div
        [innerHTML]="page?.content | safeHtml"
        *ngIf="page?.page_type_id == '1'"
      ></div>
      <div *ngIf="page?.page_type_id == '2'">
        <div [innerHTML]="page?.content.content | safeHtml"></div>
      </div>
    </div>
  </div>
</div>
