import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-memory-thinking-checkup',
  templateUrl: './memory-thinking-checkup.component.html',
  styleUrls: ['./memory-thinking-checkup.component.scss']
})
export class MemoryThinkingCheckupComponent implements OnInit {

  picked:any = [];
  completed = false;
  symptoms:any = [
    {value: 1, label: 'Someone says something and a few minutes later, you can’t remember what they said'},
    {value: 2, label: 'I find it difficult to concentrate on something like reading or watching the television'},
    {value: 3, label: 'My brain seems to be working much slower than normal'},
    {value: 4, label: 'I find it difficult to make and follow a plan to do things'},
    {value: 5, label: 'I forget to do things such as attend appointments'},
    {value: 6, label: 'I often can’t find the name for something I am talking about in conversation'},
    {value: 7, label: 'There are times when my brain just seems to “short circuit” and I can’t think at all'},
  ];

  @ViewChild('form', { static: false }) checkerForm?: NgForm;
  constructor() { }

  ngOnInit(): void {
  }


  onPick(event:any, symptomId: number) {
    if (event.target.checked) {
      if (this.picked.indexOf(symptomId) < 0) {
        this.picked.push(symptomId);
      }
    } else {
      if (this.picked.indexOf(symptomId) > -1) {
        this.picked.splice(this.picked.indexOf(symptomId), 1);
      }
    }
  }

  onSubmit(){
    this.completed = true;
  }

}
