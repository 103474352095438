<div class="container relative">
  <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>

  <h3 class="mt-4 mb-1 sm-title text-center" *ngIf="symptom">
    {{ symptom.created | date : "EEEE d MMM yyyy" }}
  </h3>
  <div class="card text-center mt-2">
    <div class="card-body text-center pt-3">
      <div *ngIf="symptom">
       

        <span
          ><small
            >Please see our feedback below for resources that could help.</small
          ></span
        >
      </div>
    </div>

    <div *ngIf="symptom">
      <ul class="tracker-list tracker-list-symptoms mt-5">
        <ng-container
          *ngFor="let symptomInf of userSymptomsService.symptomsArray"
        >
          <ng-container *ngIf="currentSymptom == symptomInf.id">
            <li *ngIf="checkSymptomForDiagnosis(symptomInf.id)">
              <a
                class="symptom-list"
                [routerLink]="['/trackers', 'health-status', symptomInf.id]"
              >
                <div class="tracker-icon">
                  {{
                    userSymptomsService.getSymptomScore(symptom, symptomInf.id)
                  }}
                </div>
                <div class="tracker-info">
                  <span class="tracker-link"
                    ><span class="icon-wrap symptom-small-icon"
                      ><fa-icon
                        [icon]="[symptomInf.iconWeight, symptomInf.icon]"
                      ></fa-icon
                      >&nbsp;</span
                    >
                    {{ symptomInf.title }}
                    <span class="icon-wrap float-right"
                      ><fa-icon
                        [icon]="['far', 'arrow-alt-right']"
                      ></fa-icon></span
                  ></span>
                  
                  <span class="progress progress-symptom">
                    <span
                      class="progress-bar"
                      [class]="
                        userSymptomsService.getSymptomScore(
                          symptom,
                          symptomInf.id
                        ) | symptomBg : 5
                      "
                      role="progressbar"
                      style="width: {{
                        userSymptomsService.getSymptomScore(
                          symptom,
                          symptomInf.id
                        ) == 0 ? '100' :
                        (userSymptomsService.getSymptomScore(
                          symptom,
                          symptomInf.id
                        ) /
                          5) *
                          100
                      }}%"
                      [attr.aria-valuenow]="
                        userSymptomsService.getSymptomScore(
                          symptom,
                          symptomInf.id
                        ) == 0 ? '100' :
                        (userSymptomsService.getSymptomScore(
                          symptom,
                          symptomInf.id
                        ) /
                          5) *
                          100
                      "
                      aria-valuemin="0"
                      aria-valuemax="100"
                    ></span>
                    <span class="line"></span><span class="line"></span
                    ><span class="line"></span><span class="line"></span>
                  </span>
                  <app-health-status-feedback
                    [symptom]="symptomInf.id"
                    [currentScore]="
                      userSymptomsService.getSymptomScore(
                        symptom,
                        symptomInf.id
                      )
                    "
                    [previousScore]="
                      userSymptomsService.getSymptomScore(
                        previousSymptom,
                        symptomInf.id
                      )
                    "
                  ></app-health-status-feedback>
                </div>
              </a>
            </li>
          </ng-container>
        </ng-container>
      </ul>

      <div class="important-alert">
        <h4>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
            <path
              d="M248.747 204.705l6.588 112c.373 6.343 5.626 11.295 11.979 11.295h41.37a12 12 0 0 0 11.979-11.295l6.588-112c.405-6.893-5.075-12.705-11.979-12.705h-54.547c-6.903 0-12.383 5.812-11.978 12.705zM330 384c0 23.196-18.804 42-42 42s-42-18.804-42-42 18.804-42 42-42 42 18.804 42 42zm-.423-360.015c-18.433-31.951-64.687-32.009-83.154 0L6.477 440.013C-11.945 471.946 11.118 512 48.054 512H527.94c36.865 0 60.035-39.993 41.577-71.987L329.577 23.985zM53.191 455.002L282.803 57.008c2.309-4.002 8.085-4.002 10.394 0l229.612 397.993c2.308 4-.579 8.998-5.197 8.998H58.388c-4.617.001-7.504-4.997-5.197-8.997z"
            ></path>
          </svg>
          Important
        </h4>
        <p class="text-left">
          If there is anything you need to discuss, please go to
          <a [routerLink]="['/chat', 'healthcare-professional']"
            >Ask a Healthcare Professional</a
          >
          or call your local IMPACT team by telephone.
        </p>
      </div>
      <div class="text-center mt-5">
        <button
          class="btn-primary btn btn-block"
          [routerLink]="[
            '/trackers',
            'health-status',
            'update',
            symptom.user_symptom_id
          ]"
        >
          Edit Symptoms
          <span class="icon-wrap"
            ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
          ></span>
        </button>
      </div>

      <div class="text-center mt-5">
        <button class="btn btn-white btn-sm" (click)="onDelete()">
          Delete
          <span class="icon-wrap text-danger"
            ><fa-icon [icon]="['fal', 'trash']"></fa-icon
          ></span>
        </button>
      </div>
    </div>
  </div>
</div>
