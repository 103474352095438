<div class="internal-app card card-light mt-3 mb-4">
  <div class="card-body">
    <h4>Prioritising</h4>
    <form #form="ngForm" class="sleep-score-form eq5d-form">
      <div *ngIf="step == 1">
        <p>
          Think about an activity that you are finding difficult at the moment
          and ask yourself the following questions:
        </p>
        <ol>
          <li>
            <div class="form-group radio-group">
              <label for="q1">
                <h5>Do I need to do this activity?</h5>
              </label>
              <div class="tool-options">
                <div>
                  <div class="form-check">
                    <input
                      type="radio"
                      required
                      class="form-check-input"
                      name="q1"
                      value="1"
                      id="q1a"
                      ngModel
                      #q1="ngModel"
                    />
                    <label class="form-check-label" for="q1a">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Yes
                    </label>
                  </div>

                  <div class="form-check">
                    <input
                      type="radio"
                      required
                      class="form-check-input"
                      name="q1"
                      value="2"
                      id="q1b"
                      ngModel
                      #q5="ngModel"
                    />
                    <label class="form-check-label" for="q1b">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="form-group radio-group">
              <label for="q1"><h5>Do I enjoy doing this activity?</h5></label>
              <div class="tool-options">
                <div>
                  <div class="form-check">
                    <input
                      type="radio"
                      required
                      class="form-check-input"
                      name="q2"
                      value="1"
                      id="q2a"
                      ngModel
                      #q1="ngModel"
                    />
                    <label class="form-check-label" for="q2a">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Yes
                    </label>
                  </div>

                  <div class="form-check">
                    <input
                      type="radio"
                      required
                      class="form-check-input"
                      name="q2"
                      value="2"
                      id="q2b"
                      ngModel
                      #q5="ngModel"
                    />
                    <label class="form-check-label" for="q2b">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ol>
        <button
          class="btn btn-primary btn-block mb-4"
          type="button"
          (click)="onNextStep()"
        >
          Next
          <span class="icon-wrap"
            ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
          ></span>
        </button>
      </div>

      <div *ngIf="step == 2">
        <p>
          Sometimes we have to prioritise tasks that need to be done even if we
          don’t enjoy doing them, but it is also important to give yourself time
          for activities that you enjoy, such as hobbies and socialising, even
          if they are not necessary.
        </p>
        <p>
          Try to have a balance of activities that you need to do and want to do
          each day.
        </p>
        <p>
          If an activity isn’t necessary and isn’t enjoyable, could you stop
          doing it altogether? Or do it less often?
        </p>
        <div class="speech-bubble">
          “I mowed the lawn then was wiped out for 4 hours. I thought, why do I
          need to do it? I don’t want to spend my time doing it and missing out
          on time with my family.”
        </div>
        <button
          class="btn btn-primary btn-block mb-4"
          type="button"
          (click)="onNextStep()"
        >
          Next
          <span class="icon-wrap"
            ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
          ></span>
        </button>
      </div>

      <div *ngIf="step == 3">
        <ol>
          <li style="counter-increment: customlistcounter 3">
            <div class="form-group radio-group">
              <label for="q3"
                ><h5>Could I do this activity less often?</h5></label
              >
              <div class="tool-options">
                <div>
                  <div class="form-check">
                    <input
                      type="radio"
                      required
                      class="form-check-input"
                      name="q3"
                      value="1"
                      id="q3a"
                      ngModel
                      #q1="ngModel"
                    />
                    <label class="form-check-label" for="q3a">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Yes
                    </label>
                  </div>

                  <div class="form-check">
                    <input
                      type="radio"
                      required
                      class="form-check-input"
                      name="q3"
                      value="2"
                      id="q3b"
                      ngModel
                      #q5="ngModel"
                    />
                    <label class="form-check-label" for="q3b">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="form-group radio-group">
              <label><h5>Could I do it differently?</h5></label>
              <div class="tool-options">
                <div>
                  <div class="form-check">
                    <input
                      type="radio"
                      required
                      class="form-check-input"
                      name="q4"
                      value="1"
                      id="q4a"
                      ngModel
                      #q1="ngModel"
                    />
                    <label class="form-check-label" for="q4a">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Yes
                    </label>
                  </div>

                  <div class="form-check">
                    <input
                      type="radio"
                      required
                      class="form-check-input"
                      name="q4"
                      value="2"
                      id="q4b"
                      ngModel
                      #q5="ngModel"
                    />
                    <label class="form-check-label" for="q4b">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ol>
        <button
          class="btn btn-primary btn-block mb-4"
          type="button"
          (click)="onNextStep()"
        >
          Next
          <span class="icon-wrap"
            ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
          ></span>
        </button>
      </div>

      <div *ngIf="step == 4">
        <p>
          Sometimes we do things out of habit, even if we don’t really need or
          want to. There are often ways that we can change our routines to make
          them easier.
        </p>

        <p>
          Example – Sam has a shower and washes her hair every morning. She
          finds this really tiring and has to rest for an hour afterwards. Sam
          decides that instead of showering every day she will wash at the sink
          on alternate days, as this uses up less energy. She also buys a seat
          designed to be used in the shower, so that she can sit down safely
          while washing and showering. Sam now washes her hair twice a week and
          uses dry shampoo on other days.
        </p>

        <p>
          Even if you can’t do an activity differently or less often, you might
          be able to take more time doing it. Allow yourself to rest before,
          during and after.
        </p>

        <button
          class="btn btn-primary btn-block mb-4"
          type="button"
          (click)="onNextStep()"
        >
          Next
          <span class="icon-wrap"
            ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
          ></span>
        </button>
      </div>

      <div *ngIf="step == 5">
        <ol>
          <li style="counter-increment: customlistcounter 5">
            <div class="form-group radio-group">
              <label for="q5"
                ><h5>Could someone else help me with this activity?</h5></label
              >
              <div class="tool-options">
                <div>
                  <div class="form-check">
                    <input
                      type="radio"
                      required
                      class="form-check-input"
                      name="q5"
                      value="1"
                      id="q5a"
                      ngModel
                      #q1="ngModel"
                    />
                    <label class="form-check-label" for="q5a">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Yes
                    </label>
                  </div>

                  <div class="form-check">
                    <input
                      type="radio"
                      required
                      class="form-check-input"
                      name="q5"
                      value="2"
                      id="q5b"
                      ngModel
                      #q5="ngModel"
                    />
                    <label class="form-check-label" for="q5b">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="form-group radio-group">
              <label><h5>Could someone else do it for me?</h5></label>
              <div class="tool-options">
                <div>
                  <div class="form-check">
                    <input
                      type="radio"
                      required
                      class="form-check-input"
                      name="q6"
                      value="1"
                      id="q6a"
                      ngModel
                      #q1="ngModel"
                    />
                    <label class="form-check-label" for="q6a">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      Yes
                    </label>
                  </div>

                  <div class="form-check">
                    <input
                      type="radio"
                      required
                      class="form-check-input"
                      name="q6"
                      value="2"
                      id="q6b"
                      ngModel
                      #q5="ngModel"
                    />
                    <label class="form-check-label" for="q6b">
                      <span class="icon-wrap">
                        <span class="unselected">
                          <fa-icon
                            [icon]="['fal', 'square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                        <span class="selected">
                          <fa-icon
                            [icon]="['fas', 'check-square']"
                            [fixedWidth]="true"
                          ></fa-icon>
                        </span>
                      </span>
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ol>
        <button
          class="btn btn-primary btn-block mb-4"
          type="button"
          (click)="onNextStep()"
        >
          Next
          <span class="icon-wrap"
            ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
          ></span>
        </button>
      </div>

      <div *ngIf="step == 6">
        <p>
          It is important to ask for help if you need it. Friends and family
          might not realise that you are having difficulty, so talk to them
          about any problems you are having and let them know how they could
          help you.
        </p>
        <p>
          Sometimes friends and family want to give more help than you need, and
          stop you doing things altogether. Try to discourage this, as it is
          important for you to keep active and to set yourself manageable goals.
        </p>
        <p>
          If you are unable to ask friends or family for help, there may be
          organisations in your area who can support you. Speak to your health
          professional, GP surgery, or contact your local council to find out
          what is available where you live.
        </p>
        <div class="speech-bubble">
          “Learn to say what you need and express how you feel. I was cooking
          with my wife – preparing and passing the food to her to cook. I told
          her that I needed to stop and sit down. It helped me not get too
          tired, and my wife understood what I needed to do.”
        </div>
        <p>
          Use these questions to help you think of ways to prioritise your day
          to day activities.
        </p>
        <ol>
          <li>Do I <strong>need</strong> to do this?</li>
          <li>Do I <strong>want</strong> to do this?</li>
          <li>How could I do this less often?</li>
          <li>How could I do this differently?</li>
          <li>How could someone else help?</li>
        </ol>
        <p>
          As you become stronger or manage your condition better, you will be
          able to do more. Prioritising will become less important and you can
          gradually return to doing activities more often, and with less help
          from others.
        </p>
        <p>
          Use an
          <a
            href="{{ environment.staticUrl }}/66263d5e1b54e.pdf"
            target="_blank"
            >activity diary</a
          >
          to help you reflect on whether you are still feeling drained and need
          to do less, or if you are improving and could start to do more.
        </p>
        <div class="speech-bubble">
          “I’m enjoying more self-control and self-care, knowing that I’m
          recovering. This is my new normal and I’m doing the things I want to
          do and letting go of what’s not important.”
        </div>
      </div>
    </form>
  </div>
</div>
