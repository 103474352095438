<div class="main-content-area sub-page">
  <div class="row">
    <div class="col">
      <div class="page-header">

      <h2 class="text-center py-0 mb-2">Exercise</h2>
      </div>


      <div class="card bg-light achievements-card">
        <div class="card-body">
          <div class="badges-section">
            <h3>Cardio Milestones</h3>

            <div class="badges-container">
              <div class="achievement-container-sm" *ngFor="let milestone of milestones">
                <div class="achievement-milestone achievement-milestone-exercise" [ngClass]="{'faded':!hasAward('cardio', 'badge-workouts', milestone)}">
                  <div class="achievement-header">
                    <span class="icon-wrap achievement-icon">
                      <fa-icon [icon]="['far', 'person-running']"></fa-icon>
                    </span>
                    <span class="achievement-icon-main-text">{{milestone}}</span>
                  </div>
                  <div class="achievement-badge-sub-text">
                    Cardio<br/>Workouts
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="badges-section">
            <h3>Cardio Streaks</h3>

            <div class="badges-container">
              <div class="achievement-container-sm" *ngFor="let streak of streaks">
                <div class="achievement-streak achievement-streak-exercise"[ngClass]="{'faded':!hasAward('cardio', 'badge-workout-streak', streak)}">
                  <div class="achievement-header">
                    <span class="icon-wrap achievement-icon">
                      <fa-icon [icon]="['far', 'person-running']"></fa-icon>
                    </span>
                    <span class="achievement-icon-text">
                      <span class="achievement-icon-main-text">{{streak}}</span>
                      <span class="achievement-icon-sub-text">DAY</span>
                    </span>
                  </div>
                  <div class="achievement-badge-sub-text">
                    Cardio<br/>Streak
                  </div>
                </div>
              </div>
            </div>
          </div>



          <div class="badges-section">
            <h3>Strength Milestones</h3>

            <div class="badges-container">
              <div class="achievement-container-sm" *ngFor="let milestone of milestones">
                <div class="achievement-milestone achievement-milestone-exercise" [ngClass]="{'faded':!hasAward('strength', 'badge-workouts', milestone)}">
                  <div class="achievement-header">
                    <span class="icon-wrap achievement-icon">
                      <img src="/assets/img/icon-strength-badge.svg"  alt="Strength badge" />
                    </span>
                    <span class="achievement-icon-main-text">{{milestone}}</span>
                  </div>
                  <div class="achievement-badge-sub-text">
                    Strength<br/>Workouts
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="badges-section">
            <h3>Strength Streaks</h3>

            <div class="badges-container">
              <div class="achievement-container-sm" *ngFor="let streak of streaks">
                <div class="achievement-streak achievement-streak-exercise" [ngClass]="{'faded':!hasAward('strength', 'badge-workout-streak', streak)}">
                  <div class="achievement-header">
                    <span class="icon-wrap achievement-icon">
                      <img src="/assets/img/icon-strength-badge.svg"  alt="Strength badge" />
                    </span>
                    <span class="achievement-icon-text">
                      <span class="achievement-icon-main-text">{{streak}}</span>
                      <span class="achievement-icon-sub-text">DAY</span>
                    </span>
                  </div>
                  <div class="achievement-badge-sub-text">
                    Strength<br/>Streak
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="page-header">

      <h2 class="text-center py-0 mt-5 mb-2">Goals</h2>
      </div>


      <div class="card bg-light achievements-card">
        <div class="card-body">
          <div class="badges-section">
            <h3>Milestones</h3>

            <div class="badges-container">
              
              <div class="achievement-container-sm" *ngFor="let milestone of goalMilestones">
                <div class="achievement-milestone achievement-milestone-goals" [ngClass]="{'faded':!hasAward('goals', 'badge-milestone', milestone)}">
                  <div class="achievement-header">
                    <span class="icon-wrap achievement-icon">
                      <fa-icon [icon]="['far', 'bullseye-pointer']"></fa-icon>
                    </span>
                    <span class="achievement-icon-main-text">{{milestone}}</span>
                  </div>
                  <div class="achievement-badge-sub-text">
                    Goals
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="row">
    <div class="col">
      <div class="page-header">

      <h2 class="text-center py-0 mt-5 mb-2">Programme</h2>
      </div>


      <div class="card bg-light achievements-card">
        <div class="card-body">
          <div class="badges-section">
            <h3>Milestones</h3>

            <div class="badges-container">
              
              <div class="achievement-container-sm">
                <div class="achievement-milestone achievement-milestone-programme" [ngClass]="{'faded':!hasAward('programme', 'bitesize-session', 1)}">
                  <div class="achievement-header">
                    <span class="icon-wrap achievement-icon">
                      <fa-icon [icon]="['far', 'graduation-cap']"></fa-icon>
                    </span>
                    <span class="icon-wrap achievement-icon">
                      <fa-icon [icon]="['fas', 'check']"></fa-icon>
                    </span>
                  </div>
                  <div class="achievement-badge-sub-text">
                    Bitesize<br/>Sessions
                  </div>
                </div>
              </div>


              <div class="achievement-container-sm">
                <div class="achievement-milestone achievement-milestone-programme" [ngClass]="{'faded':!hasAward('programme', 'getting-started-1', null)}">
                  <div class="achievement-header">
                    <span class="icon-wrap achievement-icon">
                      <fa-icon [icon]="['far', 'comments-question']"></fa-icon>
                    </span>
                  </div>
                  <div class="achievement-badge-sub-text">
                    Question<br/>Session
                  </div>
                </div>
              </div>



              <div class="achievement-container-sm">
                <div class="achievement-milestone achievement-milestone-programme" [ngClass]="{'faded':!hasAward('programme', 'safety-quiz', null)}">
                  <div class="achievement-header">
                    <span class="icon-wrap achievement-icon">
                      <fa-icon [icon]="['far', 'comments-question']"></fa-icon>
                    </span>
                  </div>
                  <div class="achievement-badge-sub-text">
                    Exercise Safety
                  </div>
                </div>
              </div>

              <div class="achievement-container-sm">
                <div class="achievement-milestone achievement-milestone-programme" [ngClass]="{'faded':!hasAward('programme', 'feedback-survey', null)}">
                  <div class="achievement-header">
                    <span class="icon-wrap achievement-icon">
                      <fa-icon [icon]="['far', 'comment-smile']"></fa-icon>
                    </span>
                  </div>
                  <div class="achievement-badge-sub-text">
                    Feedback<br/>Survey
                  </div>
                </div>
              </div>
             
              
            </div>
          </div>

          <div class="badges-section">
            <h3>Weekly Participation</h3>

            <div class="badges-container">
              <div class="achievement-container-sm mb-2" *ngFor="let week of weeks">
                <div class="achievement-streak achievement-streak-programme" [ngClass]="{'faded':!hasAward('programme', 'week-streak', week)}">
                  <div class="achievement-badge-sub-text">
                    WEEK
                  </div>
                  <div class="achievement-icon-main-text">
                    {{week}}
                  </div>
                  <div class="icon-wrap achievement-icon">
                    <img src="/assets/img/icon-logo-badge.svg" alt="Badge" />
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  
</div>
