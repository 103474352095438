
<div class="main-content-area bg-light ">
  <app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>

  


  <div>
    <div class="card card-callto goals-callto bg-secondary">

      <a [routerLink]="['/goals']" class="stretched-link"></a>
      <div class="card-callto-icon">
        <fa-icon [icon]="['fal', 'bullseye-pointer']"></fa-icon
          >
      </div>
      <div class="card-callto-title">
        <div class="card-callto-title-title">Goals</div>
        <span class="card-callto-title-subtitle" *ngIf="goalsToReview > 0"
          >
          You have
          <b>{{ goalsToReview }}</b> goal<span *ngIf="goalsToReview!=1">s</span> to review</span
        >
        <span class="card-callto-title-subtitle" *ngIf="goalsToReview == 0 && openGoals>=0 && (goals && goals.length>0)">You have <b>{{openGoals}}</b> goal<span *ngIf="openGoals!=1">s</span> in progress. Add another?</span>
        <span class="card-callto-title-subtitle" *ngIf="goalsToReview == 0 && openGoals==0 &&  (goals && goals.length>0)">You currently have no goals. Please add one.</span>
        <span class="card-callto-title-subtitle" *ngIf="goals?.length==0">You currently have no goals. Please add one.</span>
      </div>
      <div class="card-callto-arrow">
        <span class="icon-wrap"
        ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
      ></span>
      </div>
    </div>
  </div>


  <div class="cards-container mt-3" *ngIf="symptoms[0]">
    <ng-container *ngFor="let symptomInf of userSymptomsService.symptomsArray">
      <div
        class="card card-symptom w-100"
        *ngIf="checkSymptomForDiagnosis(symptomInf.id)"
      >
        <div class="card-body">

          <span class="icon-wrap text-danger symptom-high-icon" *ngIf="
          getLatestSymptomScore(symptoms, symptomInf.id) >=3 &&
          symptomsBaseline && getLatestSymptomScore(symptoms, symptomInf.id) > symptomsBaseline[symptomInf.id]
          ">
            <fa-icon [icon]="['fas', 'triangle-exclamation']"></fa-icon
              >
          </span>

          <a class="stretched-link" [routerLink]="['/trackers', 'health-status', symptomInf.id]">
            <div class="card-symptom-title">{{ symptomInf.title }}</div>
            <div class="card-symptom-info">
              <span class="card-symptom-icon"><app-symptom-icon [symptomId]="symptomInf.id"></app-symptom-icon></span>
              <span class="card-symptom-score">
                {{ getLatestSymptomScore(symptoms, symptomInf.id) }}
              </span>
              <span class="icon-wrap card-symptom-arrow"
                ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
              ></span>
            </div>
            <span class="progress progress-symptom">
              <span
                class="progress-bar"
                [class]="
                  getLatestSymptomScore(symptoms, symptomInf.id) | symptomBg : 5
                "
                role="progressbar"
                style="width: {{

                  getLatestSymptomScore(symptoms, symptomInf.id) == 0 ? '100' :
                  (getLatestSymptomScore(symptoms, symptomInf.id) / 5) * 100
                }}%"
                [attr.aria-valuenow]="
                   getLatestSymptomScore(symptoms, symptomInf.id) == 0 ? '100' :
                  (getLatestSymptomScore(symptoms, symptomInf.id) / 5) * 100
                "
                aria-valuemin="0"
                aria-valuemax="100"
                [attr.aria-label]=" getLatestSymptomScore(symptoms, symptomInf.id) == 0 ? '100' :
                (getLatestSymptomScore(symptoms, symptomInf.id) / 5) * 100"
              ></span>
              <span class="line"></span><span class="line"></span
              ><span class="line"></span><span class="line"></span>
            </span>
          </a>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="tracker-key">
    <span class="icon-wrap text-danger">
      <fa-icon [icon]="['fas', 'triangle-exclamation']"></fa-icon>
    </span> = Tracker at a high level<br />(high level range set by your baseline assessment)
  </div>


<!--ul class="tracker-list tracker-list-padded">

  <li>
    <a [routerLink]="['/goals']">
      <span class="icon-wrap tracker-icon">
        <fa-icon
          [icon]="['fal', 'star']"
          [fixedWidth]="true"
        ></fa-icon>
      </span>
      <span class="tracker-info">
        <span class="tracker-link"
          >Goals
          <span class="icon-wrap float-end icon-more"
          ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
        ></span></span>
      </span>
    </a>
  </li>

    <li>
      <a [routerLink]="['/trackers', 'health-status']">
        <span class="icon-wrap tracker-icon">
          <fa-icon
            [icon]="['fal', 'thermometer-half']"
            [fixedWidth]="true"
          ></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link"
            >Health Status
            <span class="icon-wrap float-end icon-more"
            ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
          ></span></span>
        </span>
      </a>
    </li>

    
    <li>
      <a [routerLink]="['/trackers', 'weight']">
        <span class="icon-wrap tracker-icon">
          <fa-icon
            [icon]="['fal', 'weight-scale']"
            [fixedWidth]="true"
          ></fa-icon>
        </span>
        <span class="tracker-info">
          <span class="tracker-link"
            >Weight
            <span class="icon-wrap float-end icon-more"
            ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
          ></span></span>
        </span>
      </a>
    </li>
  </ul-->
</div>
