<div *ngIf="!completed">
    <div class="internal-app card card-light mt-3 mb-4">
      <div class="card-body">
        <form
          (ngSubmit)="onSubmit()"
          #form="ngForm"
          class="sleep-score-form eq5d-form"
        >
          <fieldset>
            <div class="form-group">
              <label class="internal-title" for="symptoms"
                ><h4>Which, if any, are currently issues for you?</h4></label
              >
              <div  class=" checkbox-container">
                <div
                  *ngFor="let symptom of symptoms"
                  class="checkbox"
                >
  
              
              <input
                type="checkbox"
                value="{{ symptom.value }}"
                name="picked"
                [checked]="
                  picked &&
                  -1 !== picked.indexOf(symptom.value)
                    ? 'checked'
                    : ''
                "
                (change)="onPick($event, symptom.value)"
                id="treatment-{{symptom.value}}"
              />
                  <label for="treatment-{{symptom.value}}">
                    <span class="icon-wrap">
                      <fa-icon
                      [icon]="['fal', 'circle']"
                      [fixedWidth]="true"
                      *ngIf="!(
                      picked &&
                      -1 !== picked.indexOf(symptom.value)
                        ? 'checked'
                        : '')
                    "
                    ></fa-icon>
                    <fa-icon
                      [icon]="['fas', 'check-circle']"
                      [fixedWidth]="true"
                      *ngIf="
                      picked &&
                      -1 !== picked.indexOf(symptom.value)
                        ? 'checked'
                        : ''
                    "
                    ></fa-icon>
                    </span>
                    {{ symptom.label }}
                  </label>
                </div>
              </div>
            </div>
  
          </fieldset>
          <button class="btn btn-primary w-100 mb-4" [disabled]="!form.valid">
            Submit
            <!--<span class="icon-wrap"
              ><fa-icon [icon]="['far', 'check']"></fa-icon
            ></span>-->
          </button>
        </form>
      </div>
    </div>
  </div>
  <div
    class="internal-app card card-light  mt-3 mb-4 sleep-score-results"
    *ngIf="completed"
  >
    <div class="card-body">
      <h4>Recommendations</h4>
      <p *ngIf="picked.length==0">
        <strong>
            You don’t seem to be experiencing any of the common effects of illness on memory &amp; thinking at the moment, you may still find some strategies that are helpful to you or you may need to speak to your health professional about ways to better understand the specific problems you are experiencing.
        </strong>
      </p>
      
      <div *ngIf="picked.length!=0">
      <p>
        <strong>
          We believe you will find the following section(s) relevant to you:
        </strong>
      </p>
      <p>
      <span *ngIf="picked.indexOf(1)!==-1 || picked.indexOf(5)!==-1 ">
        <strong> <a [routerLink]="['/resources', 22, 108]" class="btn btn-primary">Memory difficulties</a>&nbsp; </strong>
      </span>
      <span *ngIf="picked.indexOf(6)!==-1 ">
        <strong> <a [routerLink]="['/resources', 22, 110]" class="btn btn-primary">Word Finding difficulties</a>&nbsp;   </strong>
      </span>
      <span *ngIf="picked.indexOf(2)!==-1 || picked.indexOf(3)!==-1 || picked.indexOf(7)!==-1 ">
        <strong> <a [routerLink]="['/resources', 22, 107]" class="btn btn-primary">Attention &amp; concentration</a>&nbsp;  </strong>
      </span>
      <span *ngIf="picked.indexOf(4)!==-1">
        <strong> <a [routerLink]="['/resources', 22, 109]" class="btn btn-primary">Executive problems</a>&nbsp;  </strong>
      </span>
      <p style="text-align: left;">If you have ticked any of the above, ask you’re a close family member or friend which they think apply to you. They will probably have noticed changes as well, asking them might help in two ways.</p>
      <ul>
        <li>First, it might help you accept that there is a problem.</li>
        <li>Second, it will help you and your family talk together about ways of coping rather than trying to avoid the subject.</li>
      </ul>
        <p style="text-align: left;">This will almost certainly relieve pressure on both you and them; recovering is enough to cope with without the added pressure of trying to keep problems hidden.</p>
      <p style="text-align: left;">Discussing the problems with those around you, family, friends or colleagues, also allows you to ask them to help. For instance, you can ask family or friends to remind you to do a task on a particular day or time. If you are at work, you may be able to ask colleagues to do the same.</p>
    </div>
    </div>
  </div>
  
  
  