import { catchError, map } from 'rxjs/operators';
import { Subject, throwError } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  error = new Subject<string>();

  constructor(private http: HttpClient) {}

    getResults(query:string) {

      let params = new HttpParams();
      params = params.append('query', query.toString());

      return this.http
        .get<any>(environment.apiUrl + '/search', {
          responseType: 'json',
          params,
        })
        .pipe(
          map((responseData) => {
            const results = responseData['_embedded'].search[0];
            return results;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          })
        );
    }
}
