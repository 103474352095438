import { UserService } from './../auth/service/user.service';
import { UserDataInterface } from './../auth/service/userData.interface';
import { NavigationEnd, Router } from '@angular/router';
// import { UserDataInterface } from './../services/userData.interface';
import { AuthService } from './../auth/service/auth.service';
import { Component, OnInit, Input } from '@angular/core';
import { environment } from './../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { ResourcesService } from '../services/resources.service';
import { NotificationService } from '../services/notification.service';
import { interval } from 'rxjs';
import { Notification } from '../models/notification.model';
import { BitesizeSessionService } from '../services/bitesize-session.service';
import { BitesizeSessionViewService } from '../services/bitesize-session-view.service';
import { UserSymptomsService } from '../services/user-symptoms.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  viewHeight!: number;
  minBarHeight!: number;
  avatarBase!: any;
  userData!:any;
  resources!: any;
  bitesizeSessions!: any;

  notifications: Notification[] = [];
  notificationsInterval: any;
  currentWeekNumber!: any;
  actualWeekNumber!: any;

  @Input() section: any;
  diagnosisMode:any;
  currentUrl:any;

  now = new Date();
  
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private resourcesService: ResourcesService,
    private notificationService: NotificationService,
    private bitesizeSessionService: BitesizeSessionService,
    public bitesizeSessionViewService: BitesizeSessionViewService,
    public userSymptomsService: UserSymptomsService
  ) {}

  

  ngOnInit(): void {
    this.avatarBase = environment.apiUrl + environment.avatarPath;

    this.router.events.subscribe((val) => {
      // see also
      if(val instanceof NavigationEnd){
        this.currentUrl = val.url;
      }
    });
    // are we logged in?
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userService.userData.subscribe(
          (userData: any) => {
            if (userData != null) {
              this.userData = userData;

              this.diagnosisMode = this.userService.getDiagnosisMode(
                this.userData.primary_diagnosis
              );

              this.currentWeekNumber = +userData.current_week;
              this.actualWeekNumber = +userData.current_week;

              this.resourcesService
                .fetchAll()
                .subscribe((resourceResponseData) => {
                  this.resources = resourceResponseData;
                });
              this.bitesizeSessionService
                .fetchAll()
                .subscribe((bitesizeResponseData) => {
                  this.bitesizeSessions = bitesizeResponseData;
                  if (userData.smoker != 1) {
                    // remove the smoking one
                    let smokeIndex = -1;
                    this.bitesizeSessions.forEach(
                      (_session: any, index: number) => {
                        if (_session.bitesize_session_id == 1) {
                          smokeIndex = index;
                        }
                      }
                    );
                    if (smokeIndex != -1) {
                      this.bitesizeSessions.splice(smokeIndex, 1);
                    }
                  }
                });
            }
          },
          (error: HttpErrorResponse) => {
            // console.log(error);
          }
        );

        // notifications

        // check for notifications
        this.notificationsInterval = null;
        this.notificationsInterval = interval(60000).subscribe((count) => {
          this.checkNotifications();
        });
        this.notificationService.updateNotifications.subscribe((data) => {
          if (data) {
            this.checkNotifications();
          }
        });
        // end notifications
        this.checkNotifications();
      } else {
        this.notificationsInterval = null;
      }
    });
  }

  goToNotifications() {
    if (this.notifications.length > 0) {
      this.router.navigate(['/notifications']);
    }
  }

  checkNotifications() {
    this.notificationService.getNotifications().subscribe((responseData) => {
      this.notifications = responseData;
    });
  }

  onLogout(): void {
    this.authService.logout();
  }

  onSearch(query: any) {
    this.router.navigate(['/search'], { queryParams: { q: query.viewModel } });
  }



  checkSymptomForDiagnosis(symptomId: any) {
    let allowSymptom = true;
    /*
    ischaemic_pain
  cough
  phlegm
  chest_tightness
  sob
  ankle_swelling
  activity
  confidence
  sleep
  energy
  mood
  appetite
  surgery_pain
*/

    if (
      this.diagnosisMode == 'cardiac' &&
      (symptomId == 'cough' ||
        symptomId == 'phlegm' ||
        symptomId == 'chest_tightness' ||
        symptomId == 'fatigue' ||
        symptomId == 'memory' ||
        symptomId == 'anxiety')
    ) {
      allowSymptom = false;
    }

    if (
      this.diagnosisMode == 'respiratory' &&
      (symptomId == 'ischaemic_pain' ||
        symptomId == 'ankle_swelling' ||
        symptomId == 'surgery_pain' ||
        symptomId == 'fatigue' ||
        symptomId == 'memory' ||
        symptomId == 'anxiety')
    ) {
      allowSymptom = false;
    }

    if (
      this.diagnosisMode == 'covid' &&
      (symptomId == 'ischaemic_pain' ||
        symptomId == 'ankle_swelling' ||
        symptomId == 'surgery_pain' ||
        symptomId == 'phlegm' ||
        symptomId == 'chest_tightness' ||
        symptomId == 'activity' ||
        symptomId == 'confidence' ||
        symptomId == 'energy')
    ) {
      allowSymptom = false;
    }

    return allowSymptom;
  }

  checkUrl(){
    let navItem = '';
    if(this.router.url){
      if(this.router.url.includes('/bitesize')){
        navItem = 'resources';
      }

      else if(this.router.url.includes('/resources')){
        navItem = 'resources';
      }
      else if(this.router.url.includes('/achievements')){
        navItem = 'programme';
      }
      else if(this.router.url.includes('/programme')){
        navItem = 'programme';
      }
      else if(this.router.url.includes('/contact')){
        navItem = 'more';
      }
      else if(this.router.url.includes('/more')){
        navItem = 'more';
      }
     
    }
    return navItem;
  }
}
