import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/auth/service/user.service';
import { AchievementService } from 'src/app/services/achievement.service';
import { BitesizeSessionViewService } from 'src/app/services/bitesize-session-view.service';
import { BitesizeSessionService } from 'src/app/services/bitesize-session.service';
import { DateHelperService } from 'src/app/services/date-helper.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-bitesize-session',
  templateUrl: './bitesize-session.component.html',
  styleUrls: ['./bitesize-session.component.scss'],
})
export class BitesizeSessionComponent implements OnInit, OnDestroy {
  bitesizeId!: number;
  allBitesize: any;
  bitesize: any;
  isLoading = false;
  environment = environment;
  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();

  public today = this.dateHelperService.getToday();

  public userData: any;
  currentWeekNumber!: number;
  actualWeekNumber!: number;
  constructor(
    public bitesizeSessionService: BitesizeSessionService,
    private route: ActivatedRoute,
    private titleService: Title,
    private bitesizeSessionViewService: BitesizeSessionViewService,
    private achievementService: AchievementService,
    private dateHelperService: DateHelperService,
    private userService: UserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const startDate = this.currentWeek[0];
    const endDate = this.currentWeek[6];

    if (!this.userService.userData.getValue()) {
      this.userService.userData.subscribe((userData) => {
        if (userData) {
          this.userData = userData;
          this.currentWeekNumber = +userData.current_week;
          this.actualWeekNumber = +userData.current_week;
        }
      });
    } else {
      this.userData = this.userService.userData.getValue();
      this.currentWeekNumber = this.userData.current_week;
      this.actualWeekNumber = this.userData.current_week;
    }



    this.route.params.subscribe((params) => {
      this.bitesizeId = params.id;
      this.isLoading = true;

      this.bitesizeSessionService.fetchAll().subscribe((responseData) => {
        this.isLoading = false;
        this.allBitesize = responseData;
        let bitesizeNum = 0;
        let thisBitesizeNum = 0;
        this.allBitesize.forEach((bitesize: any) => {
          bitesizeNum++;
          if (bitesize.bitesize_session_id == this.bitesizeId) {
            thisBitesizeNum = bitesizeNum;
            this.bitesize = bitesize;
            this.titleService.setTitle(
              environment.title + ' | Bitesize Session | ' + this.bitesize.title
            );
          }
        });
        if ((+thisBitesizeNum > +this.currentWeekNumber && this.userData.smoker==1 ) || (+thisBitesizeNum > +this.currentWeekNumber+1 && this.userData.smoker!=1 ) ) {
          this.router.navigate(['/resources', 'bitesize']);
        } else {
          this.bitesizeSessionViewService
            .save(this.bitesizeId)
            .subscribe((responseData) => {
              // get the views?
            });
        }
      });
    });
  }

  ngOnDestroy(): void {
    this.achievementService.refreshNotifications();
  }
}
