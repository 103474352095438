<div class="d-flex strength-exercises-detailed">
  <ng-container *ngFor="let exercise of exercises; let index = index">
    <ng-container *ngIf="exerciseData && exerciseData['' + exercise.id]?.set1">
      <div class="strength-exercises-item">
        <div
          [ngClass]="{
            'strength-icon-active strength-icon-all': exerciseData['' + exercise.id]?.set1,
            'strength-icon-inactive .strength-icon-all': !exerciseData['' + exercise.id]?.set1
          }"
        >
          <app-strength-icon [exerciseId]="exercise.id"></app-strength-icon>
        </div>
        <div>
          <b>{{
            +exerciseData["" + exercise.id]?.set1 +
              +exerciseData["" + exercise.id]?.set2 +
              +exerciseData["" + exercise.id]?.set3
          }}</b>
          reps
          <br />

          <div class="d-flex">
            <app-difficulty-circle
              [difficulty]="+exerciseData[exercise.id].difficulty"
            ></app-difficulty-circle>
            <span
              class="difficulty-text"
              *ngIf="+exerciseData[exercise.id].difficulty == 1"
              >{{ exerciseData["" + exercise.id].weight }}{{ "kg" }}</span
            >
            <span
              class="difficulty-text"
              *ngIf="+exerciseData[exercise.id].difficulty == 2"
              >{{ exerciseData["" + exercise.id].weight }}{{ "kg" }}</span
            >
            <span
              class="difficulty-text"
              *ngIf="+exerciseData[exercise.id].difficulty == 3"
              >{{ exerciseData["" + exercise.id].weight }}{{ "kg" }}</span
            >
            <span
              class="difficulty-text"
              *ngIf="+exerciseData[exercise.id].difficulty == 4"
              >{{ exerciseData["" + exercise.id].weight }}{{ "kg" }}</span
            >
            <span
              class="difficulty-text"
              *ngIf="+exerciseData[exercise.id].difficulty == 5"
              >{{ exerciseData["" + exercise.id].weight }}{{ "kg" }}</span
            >
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
