import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpParams,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { take, exhaustMap, tap } from "rxjs/operators";

import { AuthService } from "../service/auth.service";
import { Router } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class AuthInterceptorService implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.authService.user.pipe(
      take(1),
      exhaustMap((user) => {
        if (!user) {
          return next.handle(req);
        }
        let modifiedReq = req.clone();
        if (req.url != environment.apiUrl + "/restore") {
           modifiedReq = req.clone({
            setHeaders: {
              Authorization: "Bearer " + user.token,
            },
            //headers: new HttpHeaders().set('Authorization', 'Bearer ' + user.token),
          });
        }
        return next.handle(modifiedReq).pipe(
          tap(
            () => {},
            (err: any) => {
              if (err instanceof HttpErrorResponse) {
                /* if (err.status !== 401) {
           return;
          }
          this.router.navigate(['login']);*/
              }
            }
          )
        );
      })
    );
  }
}
