import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ChartOptions } from 'chart.js';
import { Subscription } from 'rxjs';
import { Weight } from 'src/app/models/weight.model';
import { UserTargetService } from 'src/app/services/user-target.service';
import { WeightService } from 'src/app/services/weight.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-weight-index',
  templateUrl: './weight-index.component.html',
  styleUrls: ['./weight-index.component.scss'],
})
export class WeightIndexComponent implements OnInit {
 /* public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Date',
            fontColor: '#222529',
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: '#EBEBEB',
          },
          scaleLabel: {
            display: true,
            labelString: 'Weight (KG)',
            fontColor: '#222529',
          },
          ticks: {
            min: 0,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  public barChartData: ChartDataSets[] = [
    { data: [], label: 'weight', maxBarThickness: 50 },
  ];
  public barChartColors: Color[] = [
    { backgroundColor: environment.colours.darkBlue },
  ];*/
  public weights: Weight[] = [];
  isFetching = false;
  error = null;
  private errorSub?: Subscription;
  public target:any;

  @ViewChild('form', { static: false }) weightForm?: NgForm;

  constructor(
    private weightService: WeightService,
    private datePipe: DatePipe,
    private targetService: UserTargetService
  ) {}

  ngOnInit(): void {

    this.isFetching = true;
    this.weightService.fetchAll().subscribe(
      (weights) => {
        console.log(weights);
        this.isFetching = false;
        this.weights = weights;
        this.setChartData(weights);
      },
      (error) => {
        this.isFetching = false;
        this.error = error.message;
      }
    );
    this.targetService.fetchAll().subscribe((targets) => {
      this.target = this.targetService.getLatestTarget(targets, 'weight');
    });
  }

  onSubmit() {
    this.weightService
      .create(this.weightForm?.value.weightInput)
      .subscribe(
        (responseData) => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Weight updated',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            toast: true,
          });

          const newWeight = new Weight(
            responseData.body?.user_weight_id,
            responseData.body?.user_id,
            responseData.body?.weight,
            responseData.body?.created,
            responseData.body?.modified
          );

          this.weights.unshift(newWeight);

          // update the chart
          this.setChartData(this.weights);
        },
        (error) => {
          this.error = error.message;
        }
      );
  }

  setChartData(weights:any) {/*
    this.barChartData[0].data = [];
    this.barChartLabels = [];
    weights.forEach((weight) => {
      this.barChartData[0].data.push(weight.weight);
      this.barChartLabels.push(
        this.datePipe.transform(weight.created, 'MMM dd')
      );
    });

    // reverse them
    this.barChartData[0].data.reverse();
    this.barChartLabels.reverse();*/
  }
}
