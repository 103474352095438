<app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
<div class="main-content-area mt-4 container-resource-page" *ngIf="bitesize">
  <div [innerHTML]="bitesize?.overview | safeHtml"></div>

  <pdf-viewer
    *ngIf="bitesize.pdf_filename"
    [src]="'/assets/pdf/' + bitesize.pdf_filename"
    [render-text]="true"
    [original-size]="false"
    style="width: 100%; height: 90vh"
  ></pdf-viewer>

  <div class="text-center mt-4">
    <a
      class="btn btn-primary"
      href="./assets/pdf/{{ bitesize.pdf_filename }}"
      target="_blank"
    >
      <span class="icon-wrap"
        ><fa-icon [icon]="['far', 'file-download']"></fa-icon
      ></span>
      Download</a
    ><br><br><a
    class="btn btn-primary"
          [routerLink]="['/dashboard']"
  >
   
    Back to Dashboard</a
  ><br>
  </div>
</div>
