<div *ngIf="isLoading" class="text-center">
  <app-loading-spinner></app-loading-spinner>
</div>
<div *ngIf="!isLoading" class="login">
  <div class="container container-register">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">

        <div>
          <button
          class="btn btn-back btn-lg"
          [routerLink]="['/']"
        >
          <span class="icon-wrap"
          ><fa-icon
            [icon]="['fal', 'arrow-left']"
            [fixedWidth]="true"
          ></fa-icon>
          <span class="sr-only">Back</span></span
      >
        </button>
        </div>


        <div class="card rounded-3 text-black">
          <div class="card-body login-form">
            <div class="text-center">
              <img
                src="/assets/img/logo-iimpact-colour.svg"
                style="width: 185px"
                alt="logo"
              />
              <h4 class="login-title">Sign in</h4>
            </div>

            <div *ngIf="testSite">
              <p class="lead text-center">
                This is a test environment for training purposes.
              </p>
            </div>

            <div class="alert alert-danger" *ngIf="error">
              <p>{{ error }}</p>
            </div>
            <form
              #authForm="ngForm"
              (ngSubmit)="onSubmit(authForm)"
              *ngIf="!isLoading"
            >
              <div class="form-group mb-4 form-floating">
                <input
                  type="email"
                  id="email"
                  class="form-control"
                  ngModel
                  name="email"
                  placeholder="Email"
                  required
                  email
                  autocomplete="off"
                />
                <label for="email">Email Address</label>
              </div>
              <div class="form-group mb-4 form-floating">
                <input
                  type="password"
                  id="password"
                  class="form-control"
                  ngModel
                  name="password"
                  required
                  minlength="6"
                  placeholder="Password"
                  autocomplete="off"
                />
                <label for="password">Password</label>
                <!--label for="inputPassword">Password</label-->
              </div>

              <button
                type="submit"
                [disabled]="!authForm.valid"
                class="btn btn-primary w-100 w-100 btn-lg"
              >
                Sign in
              </button>
              <div class="row mb-2 mt-4 small">
                <div class="col-xl-12">
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-check">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="rememberMe"
                          name="rememberMe"
                          ngModel
                          value="1"
                        />
                        <label class="form-check-label" for="rememberMe"
                          >Remember me</label
                        >
                      </div>
                    </div>
                    <div class="col-sm-6 text-sm-end">
                      <a [routerLink]="['/forgotten-password']"
                        >Forgotten your Password?
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        
      </div>
    </div>
    <!--<div class="row signin-details">
      <div class="col-sm-6 signin-left">
        Website last reviewed April 2024<br>
        © {{ currentYear }} University Hospitals of Leicester
      </div>
      <div class="col-sm-6 signin-right">
        <a [routerLink]="['/terms-and-conditions']"
          >Terms &amp; Conditions</a
        >
        &nbsp;&nbsp;|&nbsp;&nbsp; 
        <a [routerLink]="['/privacy-policy']">Privacy Policy</a><br />
        Built by <a href="https://www.hark2.com">Hark 2</a>
      </div>
    </div>-->
    <div class="col-lg-8 offset-lg-2">
      <div class="signin-details">
        <div class="signin-details-flex">
          <div class="signin-left">
            Website last reviewed April 2024<br>
          </div>
          <div class="signin-right">
            <a [routerLink]="['/terms-and-conditions']"
              >Terms &amp; Conditions</a
            >&nbsp;|&nbsp; 
            <a [routerLink]="['/privacy-policy']">Privacy Policy</a>
          </div>
        </div>
        <div class="signin-details-flex">
          <div class="signin-left">
            © {{ currentYear }} University Hospitals of Leicester
          </div>
          <div class="signin-right">
            Built by <a href="https://www.hark2.com">Hark 2</a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col footer-logos">
          <div class="programme-logo-strap">Incorporating the programmes</div>
          <div class="programme-logos">
            <img
              src="./assets/img/logo-ayh-colour.svg"
              alt="Activate Your Heart Logo"
              width="93"
            />
            <img
              src="./assets/img/logo-space-colour.svg"
              alt="SPACE for COPD"
              width="69"
            />
            <img
              src="./assets/img/logo-ycr-colour.svg"
              alt="Your COVID Recovery"
              width="93"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--div *ngIf="!isLoading">
  <div class="container-fluid content-container fh login">
    <img src="/assets/img/logo-impact-white.svg" alt="IMPACT logo" class="logo" width="100">
    <h2>Login</h2>

    <div *ngIf="testSite">
      <p class="lead text-center">
        This is a test environment for training purposes.
      </p>
    </div>
    <div class="row">
      <div class="col-lg-4 offset-lg-4 col-sm-6 offset-sm-3">
      </div>
    </div>
    
  </div>
</div-->
