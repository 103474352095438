import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CatQuestionnaireService {
  error = new Subject<string>();

  constructor(private http: HttpClient) {}

  fetchResponse(stage: number) {
    let params = new HttpParams();
    params = params.append('stage', stage.toString());

    return this.http
      .get<any>(environment.apiUrl + '/cat_responses', {
        responseType: 'json',
        params,
      })
      .pipe(
        map((responseData) => {
          if (responseData['_embedded'].cat_responses[0]) {
            return responseData['_embedded'].cat_responses[0];
          } else {
            return null;
          }
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }
  saveResponse(
    stage:any,
    cough:any,
    phlegm:any,
    chest_tightness:any,
    breathlessness:any,
    activity:any,
    confidence:any,
    sleep:any,
    energy:any
  ) {
    const postData = {
      stage,
      cough,
      phlegm,
      chest_tightness,
      breathlessness,
      activity,
      confidence,
      sleep,
      energy,
    };
    return this.http.post<any>(
      environment.apiUrl + '/cat_responses',
      postData,
      {
        observe: 'response',
      }
    );
  }
}
