// import { UserWeeklyTarget } from "./../models/user-weekly-target.model";
import { CardioWorkout } from './../models/cardio-workout.model';
import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { DateHelperService } from './date-helper.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class CardioWorkoutService {
  error = new Subject<string>();
  slug = '/cardio_workouts';
  resourceName = 'cardio_workouts';
  weeklyTarget = {
    threshold: 10,
    days: 5,
  };

  levelTargets: any = {
    1: {
      simple: 'Workout 5 times this week for 10 minutes',
      long: '2 consecutive weeks, 5 out of 7 or 10 out of 14 days where increased by a 1 min/day (cumulative 10 mins or more)',
    },
    2: {
      simple: 'Workout 5 times this week for 10 continuous minutes',
      long: '2 consecutive weeks, 5 out of 7 or 10 out of 14 days where increased by a 1 min/day (minimum 10 mins continuous/ day or more)',
    },
    3: {
      simple: 'Workout 5 times this week for 20 continuous minutes',
      long: '2 consecutive weeks, 5 out of 7 or 10 out of 14 days where increased by a 1 min/day (minimum 20 mins continuous or more)',
    },
    4: {
      simple: 'Workout 5 times this week for 30 continuous minutes',
      long: '2 consecutive weeks, 5 out of 7 or 10 out of 14 days where increased by a 1 min/day (minimum 30 mins continuous or more)',
    },
    5: {
      simple: 'Workout 5 times this week for 40 continuous minutes',
      long: '2 consecutive weeks, 5 out of 7 or 10 out of 14 days of 40 mins+ continuous aiming for 30 mins 5x a week or 75 mins 2x a week or 50 mins 3x a week',
    },
  };

  allWorkouts?: Observable<CardioWorkout[]> | null = null;
  workoutsBetween?: any;
  allExercises?: any;
  allLevelHistory?: any;
  constructor(
    private http: HttpClient,
    private dateHelperService: DateHelperService
  ) {}

  fetchBetween(startDate: Date, endDate: Date): Observable<CardioWorkout[]> {
    if (!this.workoutsBetween) {
      this.workoutsBetween = {};
    }

    if (
      !this.workoutsBetween[
        this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD') +
          '_' +
          this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
      ]
    ) {
      let searchParams = new HttpParams();

      searchParams = searchParams.append(
        'startDate',
        this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD')
      );
      searchParams = searchParams.append(
        'endDate',
        this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
      );
      this.workoutsBetween[
        this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD') +
          '_' +
          this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
      ] = this.http
        .get<any>(environment.apiUrl + this.slug, {
          params: searchParams,
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: CardioWorkout[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.workoutsBetween[
      this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD') +
        '_' +
        this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
    ];
  }
  fetchAll(): Observable<CardioWorkout[]> {
    if (!this.allWorkouts) {
      this.allWorkouts = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: CardioWorkout[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                // parse the exercise data
                if (item.exercises) {
                  item.exercises = JSON.parse(item.exercises);
                }
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allWorkouts;
  }

  fetchExercises(): Observable<any[]> {
    if (!this.allExercises) {
      this.allExercises = this.http
        .get<any>(environment.apiUrl + '/exercises', {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            return responseData['_embedded']['exercises'];
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allExercises;
  }

  clearCache() {
    this.allWorkouts = null;
    this.workoutsBetween = null;
    this.allLevelHistory = null;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const item = new CardioWorkout(
            +responseData.cardio_workout_id,
            +responseData.user_id,
            +responseData.minutes,
            +responseData.seconds,
            +responseData.exercise_id,
            responseData.exercise,
            responseData.exercise_other,
            responseData.intensity,
            responseData.workout_date,
            +responseData.difficulty,
            responseData.created,
            responseData.modified
          );
          return item;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(
    workout_date: string,
    exercise_id: number,
    exercise_other: string,
    minutes: number,
    seconds: number,
    difficulty: number
  ) {
    this.clearCache();
    const payload = {
      workout_date: moment(workout_date).format('YYYY-MM-DD'),
      exercise_id,
      exercise_other,
      minutes,
      seconds,
      difficulty,
    };
    return this.http.post<CardioWorkout>(
      environment.apiUrl + this.slug,
      payload,
      {
        observe: 'response',
      }
    );
  }

  update(
    id: number,
    workout_date: string,
    exercise_id: number,
    exercise_other: string,
    minutes: number,
    seconds: number,
    // intensity: string,
    difficulty: number
  ) {
    this.clearCache();
    const payload = {
      workout_date: moment(workout_date).format('YYYY-MM-DD'),
      exercise_id,
      exercise_other,
      minutes,
      seconds,
      //  intensity,
      difficulty,
    };
    return this.http.patch<CardioWorkout>(
      environment.apiUrl + this.slug + '/' + id,
      payload,
      {
        observe: 'response',
      }
    );
  }

  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + '/' + id
    );
  }

  generateWeekArray(currentWeek: any, data: any) {
    const weekArray: any = [];
    currentWeek.forEach((day: any) => {
      let currentDay = {
        date: this.dateHelperService.formatDate(day, 'YYYY-MM-DD'),
        minutes: 0,
        seconds: 0,
        workouts: 0,
      };
      data.forEach((item: CardioWorkout) => {
        if (item.workout_date === currentDay.date) {
          currentDay.minutes += +item.minutes;
          currentDay.seconds += +item.seconds;
          currentDay.workouts++;
        }
      });
      weekArray.push(currentDay);
    });

    // combine the seconds into minutes
    weekArray.forEach((weekItem: any, index: number) => {
      let secondsToMins = Math.floor(weekItem.seconds / 60);
      weekItem.minutes += secondsToMins;
      weekItem.seconds = weekItem.seconds - secondsToMins * 60;
      weekArray[index] = weekItem;
    });

    return weekArray;
  }

  generateLast4WeeksArray(last4Weeks: any, data: any) {
    const weekArray: any = {
      1: { days: [], daysHit: 0 },
      2: { days: [], daysHit: 0 },
      3: { days: [], daysHit: 0 },
      4: { days: [], daysHit: 0 },
      total: { daysActive: 0 },
    };
    let weekcount = 1;
    let totalDaysActive = 0;
    last4Weeks.forEach((day: any, dayIndex: number) => {
      if (dayIndex > 6 && dayIndex < 14) {
        weekcount = 2;
      }
      if (dayIndex > 13 && dayIndex < 21) {
        weekcount = 3;
      }
      if (dayIndex > 20) {
        weekcount = 4;
      }
      let currentDay = {
        date: this.dateHelperService.formatDate(day, 'YYYY-MM-DD'),
        mins: 0,
        secs: 0,
      };
      data.forEach((item: CardioWorkout) => {
        if (item.workout_date === currentDay.date) {
          currentDay.mins += +item.minutes;
          currentDay.secs += +item.seconds;
        }
      });
      if (currentDay.mins != 0 || currentDay.secs != 0) {
        totalDaysActive++;
        weekArray[weekcount].daysHit++;
      }
      weekArray[weekcount].days.push(currentDay);
    });
    weekArray.total.daysActive = totalDaysActive;
    return weekArray;
  }

  generateLastMonthWeeksArray(month: any, data: any) {

    const weekArray: any = {
      1: { days: [], daysHit: 0 },
      2: { days: [], daysHit: 0 },
      3: { days: [], daysHit: 0 },
      4: { days: [], daysHit: 0 },
      total: { daysActive: 0 },
    };


    if(month.length > 28){
      weekArray[5] = {days:[], daysHit:0};
    }
    if(month.length > 35){
      weekArray[6] = {days:[], daysHit:0};
    }
    let weekcount = 1;
    let totalDaysActive = 0;
    month.forEach((day: any, dayIndex: number) => {
      if (dayIndex > 6 && dayIndex < 14) {
        weekcount = 2;
      }
      if (dayIndex > 13 && dayIndex < 21) {
        weekcount = 3;
      }
      if (dayIndex > 20 && dayIndex<28) {
        weekcount = 4;
      }
      if (dayIndex > 27 && dayIndex<35) {
        weekcount = 5;
      }
      if (dayIndex > 34) {
        weekcount = 6;
      }
      let currentDay = {
        date: this.dateHelperService.formatDate(day, 'YYYY-MM-DD'),
        mins: 0,
        secs: 0,
      };
      data.forEach((item: CardioWorkout) => {
        if (item.workout_date === currentDay.date) {
          currentDay.mins += +item.minutes;
          currentDay.secs += +item.seconds;
        }
      });
      if (currentDay.mins != 0 || currentDay.secs != 0) {
        totalDaysActive++;
        weekArray[weekcount].daysHit++;
      }
      weekArray[weekcount].days.push(currentDay);
    });
    weekArray.total.daysActive = totalDaysActive;
    return weekArray;
  }

  getDaysHit(weekArray: any) {
    let daysHit = 0;
    weekArray.forEach((day: any) => {
      if (+day.minutes >= this.weeklyTarget.threshold) {
        daysHit++;
      }
    });
    return daysHit;
  }

  getStats(workoutData: CardioWorkout[]) {
    let stats = {
      timeSpent: 0,
      timeSpentFormatted: { hours: 0, minutes: 0 },
      total: workoutData.length,
      activeDays: 0,
      longest: 0,
      targetHit: 0,
      streak: 0,
      lightWorkouts:0,
      moderateWorkouts:0,
      vigorousWorkouts:0,
      workoutsByActivity:{}
    };

    let activeDays: any = [];
    workoutData.forEach((walk) => {
      activeDays.push(walk.workout_date);
      stats.timeSpent += +walk.minutes;
      if (+walk.minutes > stats.longest) {
        stats.longest = +walk.minutes;
      }
      if(walk.intensity == 'l'){
        stats.lightWorkouts++;
      }
      if(walk.intensity == 'm'){
        stats.moderateWorkouts++;
      }
      if(walk.intensity == 'v'){
        stats.vigorousWorkouts++;
      }
    });
    let uniqueDays = activeDays.filter(this.onlyUnique);
    let uniqueDaysAsc = uniqueDays.reverse();

    uniqueDaysAsc.sort((a:string,b:string)=> {
      return a > b ? 1 : a < b ? -1 : 0;
    });
    
    let currentStreak = 0;
    uniqueDaysAsc.forEach((day: string, index: number) => {
      if (index > 0) {
        if (moment(day).diff(moment(uniqueDaysAsc[index - 1]), 'days') > 1) {
          currentStreak = 0;
        }
      }
      currentStreak++;
      if (currentStreak > stats.streak) {
        stats.streak++;
      }
    });

    stats.activeDays = uniqueDays.length;

    // time spent
    const hours = Math.floor(stats.timeSpent / 60);
    const minutes = stats.timeSpent % 60;
    stats.timeSpentFormatted.hours = hours;
    stats.timeSpentFormatted.minutes = minutes;

    /*if (weeklyTargets != null) {
      weeklyTargets.forEach((target: UserWeeklyTarget, index: number) => {
        if (+target.walking == 1) {
          stats.targetHit++;
        }
      });
    }*/

    stats.workoutsByActivity = this.getWorkoutsByActivity(workoutData);
    return stats;
  }



  getWorkoutsByActivity(workouts:any) {
    let workoutsByActivity: any = {};
    let workoutsByActivityArray = [];
    workouts.forEach((workout:any) => {
      if (!workoutsByActivity[workout.exercise]) {
        workoutsByActivity[workout.exercise] = 0;
      }
      workoutsByActivity[workout.exercise]++;
    });

    for (let key in workoutsByActivity) {
      workoutsByActivityArray.push({
        exercise: key,
        total: workoutsByActivity[key],
      });
    }
    return workoutsByActivityArray.sort((a, b) => b.total - a.total);
  }

  onlyUnique(value: any, index: number, self: any) {
    return self.indexOf(value) === index;
  }

  getMonths(workouts: CardioWorkout[]) {
    let months: any = [];
    workouts.forEach((workout) => {
      if (
        months.indexOf(moment(workout.workout_date).format('MMMMYY')) === -1
      ) {
        months.push(moment(workout.workout_date).format('MMMMYY'));
      }
    });
    return months;
  }

  getWeeksExercised(workouts: CardioWorkout[], programmeWeeks: any) {
    let weeks: any = [];
    programmeWeeks.forEach((week: any, weekIndex: number) => {
      programmeWeeks[weekIndex].data = [];
    });
    workouts.forEach((workout) => {
      programmeWeeks.forEach((week: any, weekIndex: number) => {
        if (
          moment(workout.workout_date).format('YYYY-MM-DD') >=
            moment(week.start).format('YYYY-MM-DD') &&
          moment(workout.workout_date).format('YYYY-MM-DD') <=
            moment(week.end).format('YYYY-MM-DD')
        ) {
          programmeWeeks[weekIndex].data.push(workout);
        }
      });
    });

    programmeWeeks.forEach((week: any, weekIndex: number) => {
      let weekHours = 0;
      let weekMins = 0;
      let weekSecs = 0;
      let days: any = [];

      week.data.forEach((workout: CardioWorkout) => {
        weekMins += +workout.minutes;
        weekSecs += +workout.seconds;
        if (days.indexOf(workout.workout_date) == -1) {
          days.push(workout.workout_date);
        }
      });

      let secondsToMins = Math.floor(weekSecs / 60);
      weekMins += secondsToMins;
      weekSecs = weekSecs - secondsToMins * 60;

      let minsToHours = Math.floor(weekMins / 60);
      weekHours = minsToHours;
      weekMins =  weekMins - (minsToHours*60);

      programmeWeeks[weekIndex].totalHours = weekHours;
      programmeWeeks[weekIndex].totalMinutes = weekMins;
      programmeWeeks[weekIndex].totalSeconds = weekSecs;
      programmeWeeks[weekIndex].days = days;
    });

    return programmeWeeks;
  }
  getCurrentStreak(walks: CardioWorkout[]) {
    let currentStreak = 0;
    let activeDays: any = [];
    walks.forEach((walk) => {
      activeDays.push(walk.workout_date);
    });
    let uniqueDays = activeDays.filter(this.onlyUnique);

    let streakBroken = false;

    if (
      moment().format('YYYY-MM-DD') ==
      moment(uniqueDays[0]).format('YYYY-MM-DD')
    ) {
      uniqueDays.forEach((day: string, index: number) => {
        if (index > 0) {
          if (moment(day).diff(moment(uniqueDays[index - 1]), 'days') != -1) {
            streakBroken = true;
          }
        }
        if (streakBroken == false) {
          currentStreak++;
        }
      });
    }
    return currentStreak;
  }

  filterExercises(exercises: any, intentisty: any) {
    return exercises.filter((item: any) => item.intensity === intentisty);
  }
  parseExercises(exercises: string) {
    /*    if(exercises){

      try {
        a = JSON.parse(response);
    } catch(e) {
        alert(e); // error in the above string (in this case, yes)!
    }
      let parsesExercises = JSON.parse(exercises);
    }*/
  }

  updateLevel(level: number) {
    this.clearCache();
    const payload = {
      level: level,
    };
    return this.http.post<any>(
      environment.apiUrl + '/user_exercise_levels',
      payload,
      {
        observe: 'response',
      }
    );
  }

  fetchLevelHistory(): Observable<any[]> {
    if (!this.allLevelHistory) {
      this.allLevelHistory = this.http
        .get<any>(environment.apiUrl + '/user_exercise_levels', {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            return responseData['_embedded']['user_exercise_levels'];
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allLevelHistory;
  }

  canProgress(
    currentLevel: number,
    cardioLevelHistory: any,
    workouts: any,
    programmeWeeks: any
  ) {
    let canProgress = false;
    let currentWeek = this.dateHelperService.getCurrentWeek();
    // console.log(programmeWeeks);

    // check they've been on the programme long enough
    if (programmeWeeks.length < 3) {
      canProgress = false;
      return canProgress;
    }

    // do they have a history for this week?
    let hasHistory = false;
    cardioLevelHistory.forEach((cardioLevel: any) => {
      if (
        moment(cardioLevel.created).format('YYYY-MM-DD') >=
        moment(currentWeek[0]).format('YYYY-MM-DD')
      ) {
        hasHistory = true;
      }
    });

    if (hasHistory) {
      canProgress = false;
      return canProgress;
    }

    // have they been on this level for two weeks?
    if (
      cardioLevelHistory.length == 0 ||
      (cardioLevelHistory.length > 0 &&
        programmeWeeks.length > 2 &&
        cardioLevelHistory[0].created <
          programmeWeeks[programmeWeeks.length - 3].start)
    ) {
      //  Have they done the exercise required for this level?

      let totalDays =
        programmeWeeks[programmeWeeks.length - 3].days.length +
        programmeWeeks[programmeWeeks.length - 2].days.length;


      let daysHit: any = [];
      let workoutDays: any = {};

      switch (currentLevel) {
        case 1:
          // 1: {simple:'Workout 5 times this week for 10 minutes', long: '2 consecutive weeks, 5 out of 7 or 10 out of 14 days where increased by a 1 min/day = cumulative 10 mins or more'},
          // check last two weeks..
          // have they done 10/14 days?
          programmeWeeks[programmeWeeks.length - 3].data.forEach(
            (workoutData: any) => {
              if (!workoutDays[workoutData.workout_date]) {
                workoutDays[workoutData.workout_date] = [];
              }
              workoutDays[workoutData.workout_date].push(workoutData);
            }
          );
          programmeWeeks[programmeWeeks.length - 2].data.forEach(
            (workoutData: any) => {
              if (!workoutDays[workoutData.workout_date]) {
                workoutDays[workoutData.workout_date] = [];
              }
              workoutDays[workoutData.workout_date].push(workoutData);
            }
          );

          for (let workoutDate in workoutDays) {
            if (workoutDays.hasOwnProperty(workoutDate)) {
              let dayMins = 0;
              let daySecs = 0;
              workoutDays[workoutDate].forEach((_workoutData: any) => {
                dayMins += +_workoutData.minutes;
                daySecs += +_workoutData.seconds;
              });

              // Now check it
              if (dayMins * 60 + daySecs > 10 * 60) {
                daysHit++;
              }
            }
          }
          console.log(daysHit);
          if (daysHit >= 10) {
            canProgress = true;
          }

          break;

        case 2:
          // 2: {simple:'Workout 5 times this week for 10 continuous minutes', long:'2 consecutive weeks, 5 out of 7 or 10 out of 14 days where increased by a 1 min/day = minimum 10 mins continuous/ day or more'},
          programmeWeeks[programmeWeeks.length - 3].data.forEach(
            (workoutData: any) => {
              if (
                +workoutData.minutes >= 10 &&
                daysHit.indexOf(workoutData.workout_date) == -1
              ) {
                daysHit.push(workoutData.workout_date);
              }
            }
          );
          programmeWeeks[programmeWeeks.length - 2].data.forEach(
            (workoutData: any) => {
              if (
                +workoutData.minutes >= 10 &&
                daysHit.indexOf(workoutData.workout_date) == -1
              ) {
                daysHit.push(workoutData.workout_date);
              }
            }
          );
          if (daysHit >= 10) {
            canProgress = true;
          }
          break;

        case 3:
          // 3: {simple:'Workout 5 times this week for 20 continuous minutes', long:'2 consecutive weeks, 5 out of 7 or 10 out of 14 days where increased by a 1 min/day = minimum 20 mins continuous or more'},
          programmeWeeks[programmeWeeks.length - 3].data.forEach(
            (workoutData: any) => {
              if (
                +workoutData.minutes >= 20 &&
                daysHit.indexOf(workoutData.workout_date) == -1
              ) {
                daysHit.push(workoutData.workout_date);
              }
            }
          );
          programmeWeeks[programmeWeeks.length - 2].data.forEach(
            (workoutData: any) => {
              if (
                +workoutData.minutes >= 20 &&
                daysHit.indexOf(workoutData.workout_date) == -1
              ) {
                daysHit.push(workoutData.workout_date);
              }
            }
          );
          if (daysHit >= 10) {
            canProgress = true;
          }
          break;

        case 4:
          // 4: {simple:'Workout 5 times this week for 30 continuous minutes', long:'2 consecutive weeks, 5 out of 7 or 10 out of 14 days where increased by a 1 min/day = minimum 30 mins continuous or more'},
          programmeWeeks[programmeWeeks.length - 3].data.forEach(
            (workoutData: any) => {
              if (
                +workoutData.minutes >= 30 &&
                daysHit.indexOf(workoutData.workout_date) == -1
              ) {
                daysHit.push(workoutData.workout_date);
              }
            }
          );
          programmeWeeks[programmeWeeks.length - 2].data.forEach(
            (workoutData: any) => {
              if (
                +workoutData.minutes >= 30 &&
                daysHit.indexOf(workoutData.workout_date) == -1
              ) {
                daysHit.push(workoutData.workout_date);
              }
            }
          );
          if (daysHit >= 10) {
            canProgress = true;
          }
          break;
        case 5:
          // 5: {simple:'Workout 5 times this week for 40 continuous minutes', long:'2 consecutive weeks, 5 out of 7 or 10 out of 14 days of 40 mins+ continuous aiming for 30 mins 5x a week or 75 mins 2x a week or 50 mins 3x a week'},

          break;
      }

      // no false
      // yes true
    } else {
      canProgress = false;
      return canProgress;
    }

    return canProgress;
  }
}
