import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { Weight } from '../models/weight.model';
import { environment } from 'src/environments/environment';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class WeightService {
  error = new Subject<string>();
  slug = '/user_weights';
  resourceName = 'user_weights';
  allActionPlans?: Observable<Weight[]> | null;
  normalActionPlans?: Observable<Weight> | undefined | null;

  constructor(private http: HttpClient) {}

  fetchAll(): Observable<Weight[]> {
    if (!this.allActionPlans) {
      this.allActionPlans = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: Weight[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.allActionPlans;
  }

  clearCache() {
    this.allActionPlans = null;
    this.normalActionPlans = null;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const item = new Weight(
            responseData.user_weight_id,
            responseData.user_id,
            responseData.weight,
            responseData.created,
            responseData.modified
          );
          return item;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(
    weight: number,
  ) {
    this.clearCache();
    const payload = {
      weight
    };
    return this.http.post<Weight>(environment.apiUrl + this.slug, payload, {
      observe: 'response',
    });
  }

  update(
    id: number,
    weight: number,
  ) {
    this.clearCache();
    const payload = {
      weight,
    };
    return this.http.patch<Weight>(
      environment.apiUrl + this.slug + '/' + id,
      payload,
      {
        observe: 'response',
      }
    );
  }

  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + '/' + id
    );
  }
}
