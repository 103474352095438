<div class="main-content-area sub-page">
  <div class="row" *ngIf="exerciseData">
    <div class="col">
      <nav class="page-header">
        <div>
          <button class="btn btn-left" *ngIf="prevWorkout"
            [routerLink]="['/exercise','strength', 'view-workout',prevWorkout.strength_workout_id]">
            <span class="icon-wrap"><fa-icon [icon]="['fal', 'chevron-left']"></fa-icon></span>
          </button>
        </div>
        <div>
          <h2 *ngIf="workout">
            {{ workout?.workout_date | customDate : "dddd Do MMMM YYYY" }}
          </h2>
        </div>
        <div>
          <button class="btn btn-right" *ngIf="nextWorkout"
            [routerLink]="['/exercise','strength', 'view-workout',nextWorkout.strength_workout_id]">
            <span class="icon-wrap"><fa-icon [icon]="['fal', 'chevron-right']"></fa-icon></span>
          </button>
        </div>
      </nav>

      <ng-container *ngFor="let exercise of exercises">
        <div *ngIf="exerciseData['' + exercise.id].set1" class="card bg-light mb-3">
          <div class="card-body">
            <div class="strength-view-title">
              <h3>{{ exercise.label }}</h3>
              <h4>{{ exerciseData["" + exercise.id].weight }}kg weights</h4>
            </div>
            <div class="strength-view-details">
              <div class="strength-view-icon">
                <app-strength-icon [exerciseId]="exercise.id"></app-strength-icon>
              </div>
              <div class="status-badge status-badge-sm">
                <div class="status-badge-text">
                  <div class="status-badge-title">Set 1</div>
                  <div class="status-badge-status">
                    {{ exerciseData["" + exercise.id].set1 }}<span> reps</span>
                  </div>
                </div>
              </div>
              <div class="status-badge status-badge-sm">
                <div class="status-badge-text">
                  <div class="status-badge-title">Set 2</div>
                  <div class="status-badge-status">
                    {{ exerciseData["" + exercise.id].set2 }}<span> reps</span>
                  </div>
                </div>
              </div>

              <div class="status-badge status-badge-sm">
                <div class="status-badge-text">
                  <div class="status-badge-title">Set 3</div>
                  <div class="status-badge-status">
                    {{ exerciseData["" + exercise.id].set3 }}<span> reps</span>
                  </div>
                </div>
              </div>

              <div class="status-badge status-badge-sm status-badge-dark">
                <div class="status-badge-text">
                  <div class="status-badge-title">Total</div>
                  <div class="status-badge-status">
                    {{
                    +exerciseData["" + exercise.id].set1 +
                    +exerciseData["" + exercise.id].set2 +
                    +exerciseData["" + exercise.id].set3
                    }}<span> reps</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="strength-view-difficulty">
              <h5>Difficulty</h5>
              <app-difficulty-circle class="difficulty-icon-alignment"
                [difficulty]="+exerciseData[exercise.id].difficulty"></app-difficulty-circle>
              <span class="difficulty-text" *ngIf="+exerciseData[exercise.id].difficulty == 1">Very easy</span>
              <span class="difficulty-text" *ngIf="+exerciseData[exercise.id].difficulty == 2">Easy</span>
              <span class="difficulty-text" *ngIf="+exerciseData[exercise.id].difficulty == 3">Average</span>
              <span class="difficulty-text" *ngIf="+exerciseData[exercise.id].difficulty == 4">Hard</span>
              <span class="difficulty-text" *ngIf="+exerciseData[exercise.id].difficulty == 5">Very hard</span>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="status-badge status-badge-dark mb-5">
        <div class="status-badge-text">
          <div class="status-badge-title">Workout Total</div>
          <div class="status-badge-status">{{totalReps}}<span> reps</span></div>
        </div>
      </div>
    </div>


    <div class="row d-md-block d-none mt-2">
      <div class="col text-center">
        <button class="btn btn-primary" [routerLink]="[
            '/exercise',
            'strength',
            'edit-workout',
            workout?.strength_workout_id
          ]">
          <span class="icon-wrap"><fa-icon [icon]="['fal', 'circle-plus']"></fa-icon></span>
          Edit workout {{workout?.strength_workout_id}}
        </button>
      </div>
    </div>
  </div>
</div>

<button class="btn btn-primary d-md-none sticky-button" [routerLink]="[
    '/exercise',
    'strength',
    'edit-workout',
    workout?.strength_workout_id
  ]">
  <span class="icon-wrap"><fa-icon [icon]="['fal', 'circle-plus']"></fa-icon></span>
  Edit workout
</button>