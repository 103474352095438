import { Injectable } from '@angular/core';
import { Subject, throwError } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuizService {
  error = new Subject<string>();

  constructor(private http: HttpClient) {}


  fetchQuiz(quizId: number) {
    return this.http
    .get<any>(environment.apiUrl + '/quizzes/' + quizId, {
      responseType: 'json',
    })
    .pipe(
      map((responseData) => {
        return responseData;
      }),
      catchError((errorRes) => {
        return throwError(errorRes);
      })
    );
  }
  fetchResults(quizId: number, stage: number){
    let params = new HttpParams();
    params = params.append('assessmentId', quizId.toString());
    params = params.append('stage', stage.toString());

    return this.http
    .get<any>(environment.apiUrl + '/quiz_results', {
      responseType: 'json',
      params,
    })
    .pipe(
      map((responseData) => {
        const ResultData  = {
          results : responseData['_embedded'].quiz_results[0].results,
          responses : responseData['_embedded'].quiz_results[0].responses
        };

        return ResultData;
      }),
      catchError((errorRes) => {
        return throwError(errorRes);
      })
    );
  }
  saveResponses(assessmentId:number, responses:any, mode:any){
    const postData = {
      assessmentId,
      responses,
      mode
    };
    return this.http.post<any>(
      environment.apiUrl + '/quiz_results',
      postData,
      {
        observe: 'response',
      }
    );
  }
}
