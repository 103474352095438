<p class="px-3 pt-3 text-center">
    To complete the programme you must complete the following tasks.
  </p>
<div class="todo-progress">
  <span class="progress progress-dividers">
    <span
      *ngFor="let _ of [].constructor(tasksTotal); let index = index"
      class="progress-bar"
      [ngClass]="{
        'bg-success': index < tasksComplete,
        'bg-white': index >= tasksComplete
      }"
      role="progressbar"
      style="width: {{ 100 / tasksTotal }}%"
      aria-valuenow="25"
      aria-valuemin="0"
      aria-valuemax="100"
    >
      <span *ngIf="index < tasksComplete" class="icon-wrap text-white"
        ><fa-icon [icon]="['far', 'check']"></fa-icon
      ></span>
    </span>
  </span>
  <div class="progress-strap">
    <strong>{{ tasksComplete }} of {{ tasksTotal }}</strong> tasks completed
  </div>
</div>
<ul class="tracker-list">
  <li>
    <a [routerLink]="['/bitesize']">
      <span class="icon-wrap tracker-num">
        <span class="num" *ngIf="bitesizeComplete < 8">1</span>
        <span *ngIf="bitesizeComplete > 7" class="icon-wrap text-success"
          ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
        ></span>
      </span>
      <span class="icon-wrap tracker-icon">
        <img
          src="/assets/img/placeholder-symptoms.jpg"
          alt=""
          width="40"
          class="rounded"
        />
      </span>
      <span class="tracker-info">
        <span class="tracker-title">Bitesize Sessions</span>

        <!--span class="details">Complete at least 8 bitesize reads</span-->
        <span class="progress progress-dividers">
            <span
              *ngFor="let _ of [].constructor(8); let index = index"
              class="progress-bar"
              [ngClass]="{
                'bg-success': index < bitesizeComplete,
                'bg-white': index >= bitesizeComplete
              }"
              role="progressbar"
              style="width: {{ 100 / 8 }}%"
              aria-valuenow="25"
              aria-valuemin="0"
              aria-valuemax="100"
            ></span>
          </span>

          <span class="details"
            >{{ bitesizeComplete }} of {{ 8 }} sessions completed</span
          >

      </span>
      <span class="icon-wrap float-end icon-more"
        ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
      ></span>
    </a>
  </li>

  <li>
    <a [routerLink]="['/exercise', 'cardio']">
      <span class="icon-wrap tracker-num">
        <span class="num" *ngIf="!cardioComplete">2</span>
        <span *ngIf="cardioComplete" class="icon-wrap text-success"
          ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
        ></span>
      </span>
      <span class="icon-wrap tracker-icon">
        <img
          src="/assets/img/placeholder-cardio.jpg"
          alt=""
          width="40"
          class="rounded"
        />
      </span>
      <span class="tracker-info">
        <span class="tracker-title">Cardio workouts</span>

        <span class="details"
          >Add 24 cardio exercises (minimum 3 workouts a week)</span
        >
      </span>
      <span class="icon-wrap float-end icon-more"
        ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
      ></span>
    </a>
  </li>

  <li>
    <a [routerLink]="['/exercise', 'strength']">
      <span class="icon-wrap tracker-num">
        <span class="num" *ngIf="!strengthComplete">3</span>
        <span *ngIf="strengthComplete" class="icon-wrap text-success"
          ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
        ></span>
      </span>
      <span class="icon-wrap tracker-icon">
        <img
          src="/assets/img/placeholder-strength.jpg"
          alt=""
          width="40"
          class="rounded"
        />
      </span>
      <span class="tracker-info">
        <span class="tracker-title">Strength workouts</span>

        <span class="details">Add 16 strength workouts</span>
      </span>
      <span class="icon-wrap float-end icon-more"
        ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
      ></span>
    </a>
  </li>

  <li>
    <a [routerLink]="['/goals']">
      <span class="icon-wrap tracker-num">
        <span class="num" *ngIf="!goalComplete">4</span>
        <span *ngIf="goalComplete" class="icon-wrap text-success"
          ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
        ></span>
      </span>
      <span class="icon-wrap tracker-icon">
        <img
          src="/assets/img/placeholder-goals.jpg"
          alt=""
          width="40"
          class="rounded"
        />
      </span>
      <span class="tracker-info">
        <span class="tracker-title">Goals</span>

        <span class="details">Complete a goal</span>
      </span>
      <span class="icon-wrap float-end icon-more"
        ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
      ></span>
    </a>
  </li>
</ul>
