<div class="container relative">
  <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>
  <h3 class="mt-3 mb-3 sm-title">Feedback survey</h3>
  <p>Please fill in the survey below based on your experience of I-Impact.</p>

  <div *ngIf="completed">
    <p>You have completed the survey, thank you for your feedback</p>
    <p>
      <button class="btn btn-primary" [routerLink]="['/dashboard']">
        Go to Programme
        <span class="icon-wrap"
          ><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon
        ></span>
      </button>
    </p>
  </div>

  <div *ngIf="!completed">
    <form
      class="goal-form goal-form-nums eq5d-form"
      (ngSubmit)="onSubmit()"
      #form="ngForm"
    >
      <fieldset>
        <div class="col-sm-6 input-difficulty">
          <label
            class="sub-label"
            style="padding-left: 2.25rem; position: relative"
            ><span class="num-alone">1</span> How satisfied are you with the
            programme overall?</label
          >

          <div
            class="radio-group"
            [ngClass]="{ chosen: satisfaction.dirty || satisfaction.touched }"
          >
            <div class="form-check form-check-inline">
              <input
                ngModel
                #satisfaction="ngModel"
                class="form-check-input"
                type="radio"
                required
                name="satisfaction"
                id="satisfaction1"
                value="5"
              />
              <label class="form-check-label" for="satisfaction1"
                ><span class="icon-wrap">
                  <fa-icon [icon]="['fas', 'grin-stars']"></fa-icon> </span
              ></label>
            </div>

            <div class="form-check form-check-inline">
              <input
                ngModel
                #satisfaction="ngModel"
                class="form-check-input"
                type="radio"
                required
                name="satisfaction"
                id="satisfaction2"
                value="4"
              />
              <label class="form-check-label" for="satisfaction2"
                ><span class="icon-wrap">
                  <fa-icon [icon]="['fas', 'smile']"></fa-icon> </span
              ></label>
            </div>

            <div class="form-check form-check-inline">
              <input
                ngModel
                #satisfaction="ngModel"
                class="form-check-input"
                type="radio"
                required
                name="satisfaction"
                id="satisfaction3"
                value="3"
              />
              <label class="form-check-label" for="satisfaction3"
                ><span class="icon-wrap">
                  <fa-icon [icon]="['fas', 'meh']"></fa-icon> </span
              ></label>
            </div>

            <div class="form-check form-check-inline">
              <input
                ngModel
                #satisfaction="ngModel"
                class="form-check-input"
                type="radio"
                required
                name="satisfaction"
                id="satisfaction4"
                value="2"
              />
              <label class="form-check-label" for="satisfaction4"
                ><span class="icon-wrap">
                  <fa-icon [icon]="['fas', 'frown']"></fa-icon> </span
              ></label>
            </div>

            <div class="form-check form-check-inline">
              <input
                ngModel
                #satisfaction="ngModel"
                class="form-check-input"
                type="radio"
                required
                name="satisfaction"
                id="satisfaction5"
                value="1"
              />
              <label class="form-check-label" for="satisfaction5"
                ><span class="icon-wrap">
                  <fa-icon [icon]="['fas', 'tired']"></fa-icon> </span
              ></label>
            </div>
          </div>

          <div
            class="alert alert-danger"
            *ngIf="!satisfaction.valid && submitted"
          >
            Please select a satisfaction above
          </div>
        </div>

        <div class="form-group radio-group">
          <label
            ><span class="num">2</span> I found the information in the site
            was:</label
          >
          <div class="form-check">
            <input
              type="radio"
              required
              class="form-check-input"
              name="q2"
              value="1"
              id="q2a"
              ngModel
              #q2="ngModel"
            />
            <label class="form-check-label" for="q2a">
              <span class="icon-wrap">
                <span class="unselected">
                  <fa-icon
                    [icon]="['fal', 'square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                <span class="selected">
                  <fa-icon
                    [icon]="['fas', 'check-square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              Too detailed
            </label>
          </div>

          <div class="form-check">
            <input
              type="radio"
              required
              class="form-check-input"
              name="q2"
              value="2"
              id="q2b"
              ngModel
              #q2="ngModel"
            />
            <label class="form-check-label" for="q2b">
              <span class="icon-wrap">
                <span class="unselected">
                  <fa-icon
                    [icon]="['fal', 'square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                <span class="selected">
                  <fa-icon
                    [icon]="['fas', 'check-square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              Sufficiently detailed
            </label>
          </div>

          <div class="form-check">
            <input
              type="radio"
              required
              class="form-check-input"
              name="q2"
              value="3"
              id="q2c"
              ngModel
              #q2="ngModel"
            />
            <label class="form-check-label" for="q2c">
              <span class="icon-wrap">
                <span class="unselected">
                  <fa-icon
                    [icon]="['fal', 'square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                <span class="selected">
                  <fa-icon
                    [icon]="['fas', 'check-square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              Insufficiently detailed
            </label>
          </div>
        </div>

        <div class="form-group radio-group">
          <label
            ><span class="num">3</span> Did you have any problems understanding
            any of the information?</label
          >
          <div class="form-check">
            <input
              type="radio"
              required
              class="form-check-input"
              name="q3"
              value="1"
              id="q3a"
              ngModel
              #q3="ngModel"
            />
            <label class="form-check-label" for="q3a">
              <span class="icon-wrap">
                <span class="unselected">
                  <fa-icon
                    [icon]="['fal', 'square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                <span class="selected">
                  <fa-icon
                    [icon]="['fas', 'check-square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              Always
            </label>
          </div>

          <div class="form-check">
            <input
              type="radio"
              required
              class="form-check-input"
              name="q3"
              value="2"
              id="q3b"
              ngModel
              #q3="ngModel"
            />
            <label class="form-check-label" for="q3b">
              <span class="icon-wrap">
                <span class="unselected">
                  <fa-icon
                    [icon]="['fal', 'square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                <span class="selected">
                  <fa-icon
                    [icon]="['fas', 'check-square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              Most of the time
            </label>
          </div>

          <div class="form-check">
            <input
              type="radio"
              required
              class="form-check-input"
              name="q3"
              value="3"
              id="q3c"
              ngModel
              #q3="ngModel"
            />
            <label class="form-check-label" for="q3c">
              <span class="icon-wrap">
                <span class="unselected">
                  <fa-icon
                    [icon]="['fal', 'square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                <span class="selected">
                  <fa-icon
                    [icon]="['fas', 'check-square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              Some of the time
            </label>
          </div>

          <div class="form-check">
            <input
              type="radio"
              required
              class="form-check-input"
              name="q3"
              value="4"
              id="q3d"
              ngModel
              #q3="ngModel"
            />
            <label class="form-check-label" for="q3d">
              <span class="icon-wrap">
                <span class="unselected">
                  <fa-icon
                    [icon]="['fal', 'square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                <span class="selected">
                  <fa-icon
                    [icon]="['fas', 'check-square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              Never
            </label>
          </div>
        </div>

        <div class="form-group radio-group">
          <label for="q4"
            ><span class="num">4</span> Were there any parts of the programme
            which were difficult to use or follow?</label
          >
          <div class="form-check">
            <input
              type="radio"
              required
              class="form-check-input"
              name="q4"
              value="1"
              id="q4a"
              ngModel
              #q4="ngModel"
            />
            <label class="form-check-label" for="q4a">
              <span class="icon-wrap">
                <span class="unselected">
                  <fa-icon
                    [icon]="['fal', 'square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                <span class="selected">
                  <fa-icon
                    [icon]="['fas', 'check-square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              Yes (please comment)
            </label>
            <div class="m-2"
            [hidden]="q4.value != '1'">
              <label>Please comment below</label>
               <textarea
              type="radio"
              class="form-control ms-4"
              name="q4_extra"
              id="q4_extra"
              placeholder="Enter your feedback"
              rows="3"
              ngModel
              #q4_extra="ngModel"
            ></textarea>
            </div>
           
          </div>

          <div class="form-check">
            <input
              type="radio"
              required
              class="form-check-input"
              name="q4"
              value="2"
              id="q4b"
              ngModel
              #q4="ngModel"
            />
            <label class="form-check-label" for="q4b">
              <span class="icon-wrap">
                <span class="unselected">
                  <fa-icon
                    [icon]="['fal', 'square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                <span class="selected">
                  <fa-icon
                    [icon]="['fas', 'check-square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              No
            </label>
          </div>
        </div>

        <div class="form-group radio-group">
          <label for="q5"
            ><span class="num">5</span> Do you feel that the instructions and
            prompts on the screen clearly indicated what to do</label
          >
          <div class="form-check">
            <input
              type="radio"
              required
              class="form-check-input"
              name="q5"
              value="1"
              id="q5a"
              ngModel
              #q5="ngModel"
            />
            <label class="form-check-label" for="q5a">
              <span class="icon-wrap">
                <span class="unselected">
                  <fa-icon
                    [icon]="['fal', 'square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                <span class="selected">
                  <fa-icon
                    [icon]="['fas', 'check-square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              Yes
            </label>
          </div>

          <div class="form-check">
            <input
              type="radio"
              required
              class="form-check-input"
              name="q5"
              value="2"
              id="q5b"
              ngModel
              #q5="ngModel"
            />
            <label class="form-check-label" for="q5b">
              <span class="icon-wrap">
                <span class="unselected">
                  <fa-icon
                    [icon]="['fal', 'square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                <span class="selected">
                  <fa-icon
                    [icon]="['fas', 'check-square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              No
            </label>
          </div>
        </div>

        <div class="form-group radio-group">
          <label for="q6"
            ><span class="num">6</span> Was the interactivity of the programme
            engaging and helped you to learn about your condition?</label
          >
          <div class="form-check">
            <input
              type="radio"
              required
              class="form-check-input"
              name="q6"
              value="1"
              id="q6a"
              ngModel
              #q6="ngModel"
            />
            <label class="form-check-label" for="q6a">
              <span class="icon-wrap">
                <span class="unselected">
                  <fa-icon
                    [icon]="['fal', 'square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                <span class="selected">
                  <fa-icon
                    [icon]="['fas', 'check-square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              Definitely
            </label>
          </div>

          <div class="form-check">
            <input
              type="radio"
              required
              class="form-check-input"
              name="q6"
              value="2"
              id="q6b"
              ngModel
              #q6="ngModel"
            />
            <label class="form-check-label" for="q6b">
              <span class="icon-wrap">
                <span class="unselected">
                  <fa-icon
                    [icon]="['fal', 'square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                <span class="selected">
                  <fa-icon
                    [icon]="['fas', 'check-square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              Most of the time
            </label>
          </div>

          <div class="form-check">
            <input
              type="radio"
              required
              class="form-check-input"
              name="q6"
              value="3"
              id="q6c"
              ngModel
              #q6="ngModel"
            />
            <label class="form-check-label" for="q6c">
              <span class="icon-wrap">
                <span class="unselected">
                  <fa-icon
                    [icon]="['fal', 'square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                <span class="selected">
                  <fa-icon
                    [icon]="['fas', 'check-square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              Generally
            </label>
          </div>

          <div class="form-check">
            <input
              type="radio"
              required
              class="form-check-input"
              name="q6"
              value="4"
              id="q6d"
              ngModel
              #q6="ngModel"
            />
            <label class="form-check-label" for="q6d">
              <span class="icon-wrap">
                <span class="unselected">
                  <fa-icon
                    [icon]="['fal', 'square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                <span class="selected">
                  <fa-icon
                    [icon]="['fas', 'check-square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              Not at all
            </label>
          </div>
        </div>

        <div class="form-group radio-group">
          <label for="q7"
            ><span class="num">7</span> Which section did you find the most
            valuable?</label
          >
          <div class="form-check">
            <input
              type="radio"
              required
              class="form-check-input"
              name="q7"
              value="1"
              id="q7a"
              ngModel
              #q7="ngModel"
            />
            <label class="form-check-label" for="q7a">
              <span class="icon-wrap">
                <span class="unselected">
                  <fa-icon
                    [icon]="['fal', 'square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                <span class="selected">
                  <fa-icon
                    [icon]="['fas', 'check-square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              Goal setting
            </label>
          </div>

          <div class="form-check">
            <input
              type="radio"
              required
              class="form-check-input"
              name="q7"
              value="2"
              id="q7b"
              ngModel
              #q7="ngModel"
            />
            <label class="form-check-label" for="q7b">
              <span class="icon-wrap">
                <span class="unselected">
                  <fa-icon
                    [icon]="['fal', 'square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                <span class="selected">
                  <fa-icon
                    [icon]="['fas', 'check-square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              Bitesize information
            </label>
          </div>

          <div class="form-check">
            <input
              type="radio"
              required
              class="form-check-input"
              name="q7"
              value="3"
              id="q7c"
              ngModel
              #q7="ngModel"
            />
            <label class="form-check-label" for="q7c">
              <span class="icon-wrap">
                <span class="unselected">
                  <fa-icon
                    [icon]="['fal', 'square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                <span class="selected">
                  <fa-icon
                    [icon]="['fas', 'check-square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              Condition specific reading list
            </label>
          </div>

          <div class="form-check">
            <input
              type="radio"
              required
              class="form-check-input"
              name="q7"
              value="4"
              id="q7d"
              ngModel
              #q7="ngModel"
            />
            <label class="form-check-label" for="q7d">
              <span class="icon-wrap">
                <span class="unselected">
                  <fa-icon
                    [icon]="['fal', 'square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                <span class="selected">
                  <fa-icon
                    [icon]="['fas', 'check-square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              Generic resources
            </label>
          </div>

          <div class="form-check">
            <input
              type="radio"
              required
              class="form-check-input"
              name="q7"
              value="5"
              id="q7e"
              ngModel
              #q7="ngModel"
            />
            <label class="form-check-label" for="q7e">
              <span class="icon-wrap">
                <span class="unselected">
                  <fa-icon
                    [icon]="['fal', 'square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                <span class="selected">
                  <fa-icon
                    [icon]="['fas', 'check-square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              Tracking your exercise
            </label>
          </div>

          <div class="form-check">
            <input
              type="radio"
              required
              class="form-check-input"
              name="q7"
              value="6"
              id="q7f"
              ngModel
              #q7="ngModel"
            />
            <label class="form-check-label" for="q7f">
              <span class="icon-wrap">
                <span class="unselected">
                  <fa-icon
                    [icon]="['fal', 'square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                <span class="selected">
                  <fa-icon
                    [icon]="['fas', 'check-square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              Tracking your strength exercises
            </label>
          </div>

          <div class="form-check">
            <input
              type="radio"
              required
              class="form-check-input"
              name="q7"
              value="7"
              id="q7g"
              ngModel
              #q7="ngModel"
            />
            <label class="form-check-label" for="q7g">
              <span class="icon-wrap">
                <span class="unselected">
                  <fa-icon
                    [icon]="['fal', 'square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                <span class="selected">
                  <fa-icon
                    [icon]="['fas', 'check-square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              Tracking your health status
            </label>
          </div>

          <div class="form-check">
            <input
              type="radio"
              required
              class="form-check-input"
              name="q7"
              value="8"
              id="q7h"
              ngModel
              #q7="ngModel"
            />
            <label class="form-check-label" for="q7h">
              <span class="icon-wrap">
                <span class="unselected">
                  <fa-icon
                    [icon]="['fal', 'square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                <span class="selected">
                  <fa-icon
                    [icon]="['fas', 'check-square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              Ask your healthcare professional
            </label>
          </div>
        </div>

        <div class="form-group radio-group">
          <label for="q8"
            ><span class="num">8</span> I enjoyed using the programme</label
          >
          <div class="form-check">
            <input
              type="radio"
              required
              class="form-check-input"
              name="q8"
              value="1"
              id="q8a"
              ngModel
              #q8="ngModel"
            />
            <label class="form-check-label" for="q8a">
              <span class="icon-wrap">
                <span class="unselected">
                  <fa-icon
                    [icon]="['fal', 'square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                <span class="selected">
                  <fa-icon
                    [icon]="['fas', 'check-square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              Yes
            </label>
          </div>

          <div class="form-check">
            <input
              type="radio"
              required
              class="form-check-input"
              name="q8"
              value="2"
              id="q8b"
              ngModel
              #q8="ngModel"
            />
            <label class="form-check-label" for="q8b">
              <span class="icon-wrap">
                <span class="unselected">
                  <fa-icon
                    [icon]="['fal', 'square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
                <span class="selected">
                  <fa-icon
                    [icon]="['fas', 'check-square']"
                    [fixedWidth]="true"
                  ></fa-icon>
                </span>
              </span>
              No
            </label>
          </div>
        </div>

        <div class="form-group">
          <label for="q9"
            ><span class="num">9</span> Please enter any other feedback</label
          >

          <textarea
            type="radio"
            required
            class="form-control"
            name="q9"
            value="1"
            id="q9"
            placeholder="Enter your feedback"
            rows="6"
            ngModel
            #q9="ngModel"
          ></textarea>
        </div>

        <button class="btn btn-primary btn-block mb-4" [disabled]="!form.valid">
          Submit
          <span class="icon-wrap"
            ><fa-icon [icon]="['far', 'check']"></fa-icon
          ></span>
        </button>
      </fieldset>
    </form>
  </div>
</div>
