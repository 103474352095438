import { Component, Input, OnInit } from '@angular/core';
import { StrengthWorkoutService } from 'src/app/services/strength-workout.service';

@Component({
  selector: 'app-format-strength-exercises-detailed',
  templateUrl: './format-strength-exercises-detailed.component.html',
  styleUrls: ['./format-strength-exercises-detailed.component.scss'],
})
export class FormatStrengthExercisesDetailedComponent implements OnInit {
  @Input() exerciseData: any;
  exercises: any;
  constructor(private strengthWorkoutService: StrengthWorkoutService) {
    this.exercises = strengthWorkoutService.exercises;
  }

  ngOnInit(): void {
    if (this.isJsonString(this.exerciseData)) {
      this.exerciseData = JSON.parse(this.exerciseData);
    }
  }

  isJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
