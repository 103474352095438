<div class="internal-app card card-light mt-3 mb-4 must-form" #target>
    <form class="sleep-score-form eq5d-form"
    (ngSubmit)="onSubmit(target)"
    #form="ngForm"
    id="quiz">
      <div
        class="form-group radio-group"
        *ngFor="let question of questions; let i = index"
      >
        <label for="q5"
          ><h5>{{ question.question }}</h5></label
        >
        <div class="tool-options">
          <div>
            <div class="form-check">
              <input
                type="radio"
                required
                class="form-check-input"
                name="q{{ i }}"
                value="1"
                id="q{{ i }}t"
                ngModel
                #q1="ngModel"
                [disabled]="completed"
              />
              <label class="form-check-label" for="q{{ i }}t">
                <span class="icon-wrap">
                  <span class="unselected">
                    <fa-icon
                      [icon]="['fal', 'square']"
                      [fixedWidth]="true"
                    ></fa-icon>
                  </span>
                  <span class="selected">
                    <fa-icon
                      [icon]="['fas', 'check-square']"
                      [fixedWidth]="true"
                    ></fa-icon>
                  </span>
                </span>
                True
              </label>
            </div>
  
            <div class="form-check">
              <input
                type="radio"
                required
                class="form-check-input"
                name="q{{ i }}"
                value="2"
                id="q{{ i }}f"
                ngModel
                #q5="ngModel"
                [disabled]="completed"
              />
              <label class="form-check-label" for="q{{ i }}f">
                <span class="icon-wrap">
                  <span class="unselected">
                    <fa-icon
                      [icon]="['fal', 'square']"
                      [fixedWidth]="true"
                    ></fa-icon>
                  </span>
                  <span class="selected">
                    <fa-icon
                      [icon]="['fas', 'check-square']"
                      [fixedWidth]="true"
                    ></fa-icon>
                  </span>
                </span>
                False
              </label>
            </div>
          </div>
        </div>
  
        <div *ngIf="completed" class="mt-3">
            <h5 *ngIf="question.correct"><span class="icon-wrap text-success"
              ><fa-icon [icon]="['far', 'check']"></fa-icon
            ></span> Correct</h5>
            <h5 *ngIf="!question.correct"><span class="icon-wrap text-danger"
              ><fa-icon [icon]="['far', 'times']"></fa-icon
            ></span> Incorrect</h5>
            {{question.feedback}}
        </div>
        <hr>
      </div>
      <button class="btn btn-primary btn-block mb-4" [disabled]="!form.valid">
          Submit
          <!--<span class="icon-wrap"
            ><fa-icon [icon]="['far', 'check']"></fa-icon
          ></span>-->
        </button>
    </form>
  </div>
  