import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-sleep-score',
  templateUrl: './sleep-score.component.html',
  styleUrls: ['./sleep-score.component.scss']
})
export class SleepScoreComponent implements OnInit {
  @ViewChild('form', { static: false }) sleepForm?: NgForm;

  completed = false;
  score = 0;
  constructor() { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.completed = true;
    window.scroll(0, 0);
    this.score += +this.sleepForm?.value.q1;
    this.score += +this.sleepForm?.value.q2;
    this.score += +this.sleepForm?.value.q3;
    this.score += +this.sleepForm?.value.q4;
    this.score += +this.sleepForm?.value.q5;
    this.score += +this.sleepForm?.value.q6;
    this.score += +this.sleepForm?.value.q7;
    this.score += +this.sleepForm?.value.q8;
  }

}
