import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ComponentCanDeactivate } from 'src/app/guards/pending-changes.guard';
import { CardioWorkoutService } from 'src/app/services/cardio-workout.service';

import Swal from 'sweetalert2/src/sweetalert2.js';
import * as moment from 'moment';
import { forkJoin, Observable } from 'rxjs';
import { CardioWorkout } from 'src/app/models/cardio-workout.model';
import { AchievementService } from 'src/app/services/achievement.service';

@Component({
  selector: 'app-edit-cardio-workout',
  templateUrl: './edit-cardio-workout.component.html',
  styleUrls: ['./edit-cardio-workout.component.scss'],
})
export class EditCardioWorkoutComponent
  implements OnInit, ComponentCanDeactivate
{
  @ViewChild('form', { static: false }) entryForm?: NgForm;
  submitted = false;
  error?: string;
  maxDate = new Date();
  bsDate = new Date();
  exercises: any;
  otherSelected = false;
  public entryId!: number;
  isLoading = false;
  workout!:CardioWorkout;
  workoutDate?: Date;
  errors:any = [];
  hasDeleted = false;

  constructor(
    private cardioWorkoutService: CardioWorkoutService,
    private router: Router,
    private achievementService: AchievementService,
    private route: ActivatedRoute,
    private el: ElementRef
  ) {}
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.entryId = params.id;
      this.isLoading = true;
      forkJoin({
        exercises: this.cardioWorkoutService.fetchExercises(),
        workout: this.cardioWorkoutService.fetch(this.entryId),
      }).subscribe(
        (responseData) => {
          this.isLoading = false;
          this.exercises = responseData.exercises;
          this.workout = responseData.workout;
          this.workoutDate = moment(this.workout.workout_date).toDate();
        },
        (error) => {
          this.errors.push(error.message);
        }
      );
    });
  }

  onSubmit() {
    this.submitted = true;
    if (!this.entryForm?.valid) {
      Swal.fire({
        icon: 'error',
        title: 'The form is invalid',
        text: 'Please check the form for errors',
        showConfirmButton: true,
        didClose: () => this.scrollToFirstInvalidControl(),
      });
    } else {
      // create entry
      const resourceIds = [];
      this.cardioWorkoutService
        .update(
          this.entryId,
          this.entryForm?.value.date,
          +this.entryForm?.value.exercise,
          this.entryForm?.value.exercise_other,
          +this.entryForm?.value.minutes,
          +this.entryForm?.value.seconds,
          //this.entryForm?.value.intensity,
          +this.entryForm?.value.difficulty
        )
        .subscribe(
          (responseData) => {
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Entry created',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              toast: true,
            });
            this.router.navigate(['/exercise', 'cardio']);
            this.entryForm?.reset();

            this.achievementService.refreshNotifications();
          },
          (error) => {
            this.error = error.message;
          }
        );
    }
  }


  onDeleteEntry() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to undo this",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it',
    }).then((result) => {
      if (result.value) {
        this.cardioWorkoutService.delete(this.entryId).subscribe(
          (responseData) => {

            this.hasDeleted = true;
            Swal.fire('Deleted', 'Your entry has been deleted.', 'success');
            this.router.navigate(['/exercise', 'cardio']);
          },
          (error) => {
            this.isLoading = false;
            this.errors.push(error.message);
            Swal.fire('Error!', 'There was an error.', 'error');
          }
        );
      }
    });
  }

  onCheckForOther(event: any) {
    let selectElementText =
      event.target['options'][event.target['options'].selectedIndex].text;
    if (selectElementText == 'Other') {
      this.otherSelected = true;
    } else {
      this.otherSelected = false;
    }
  }

  filterExercise(exercises: any, intensity: any) {
    return this.cardioWorkoutService.filterExercises(exercises, intensity);
  }

  public scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement =
      this.el.nativeElement.querySelector('form .ng-invalid');

    if (firstInvalidControl) {
      firstInvalidControl.focus();
      window.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
        behavior: 'smooth',
      });
    }
  }

  public getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 150;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<any> | any {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away

    if (this.submitted || this.hasDeleted) {
      return true;
    } else if (
      !this.entryForm?.value.exercise &&
      !this.entryForm?.value.minutes &&
      !this.entryForm?.value.seconds &&
      !this.entryForm?.value.difficulty
    ) {
      return true;
    } else {
      return false;
    }
  }
}
