
<div class="main-content-area">
  <div class="mt-n3">
    <div class="row">
      <div class="col">
        <form
          class="ii-form mt-3"
          (ngSubmit)="onSubmit()"
          #form="ngForm"
        >
          <fieldset ngModelGroup="goal" #goal="ngModelGroup">
            <div class="form-group"> <label for="what"
                ><span class="num">1.</span> Write out your goal</label
              >
              <textarea
                name="what"
                id="what"
                class="form-control"
                placeholder="Enter text..."
                rows="8"
                ngModel
                #what3="ngModel"
                required
                maxlength="65535"
                minlength=""
              ></textarea>
             
            </div>
            
            <div class="form-group"> <label for="start"
                ><span class="num">2.</span> Date to review goal
              </label>

              <div class="input-group">
                <span class="input-group-icon">
                  <fa-icon
                [icon]="['far', 'calendar-alt']"
                [fixedWidth]="true"
              ></fa-icon>
                </span>
                <input
                class="form-control form-control-date"
                name="start"
                #start="bsDatepicker"
                bsDatepicker
                placeholder="DD/MM/YYYY"
                autocomplete="off"
                placement="bottom"
                [bsConfig]="{
                  dateInputFormat: 'DD/MM/YYYY',
                  containerClass: 'theme-impact',
                  showWeekNumbers: false
                }"
                ngModel
                required
                [minDate]="today"
              >
              </div>

             
             
            </div>
          </fieldset>

          <button class="btn btn-primary w-100" [disabled]="!goal.valid">
           Submit
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
<br />
