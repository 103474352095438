
<p>
    <strong>Access agreement and disclaimer</strong>
</p>
<p>
    Access Agreement between users of the i-IMPACT website at
    https://<a href="http://www.i-impact.co.uk/">www.i-impact.co.uk</a>/ and
    related services and The University Hospitals of Leicester NHS Trust ("the
    Trust").
</p>
<p>
    In this Agreement, the "website" means the i-IMPACT website at
    https://<a href="http://www.i-impact.co.uk/">www.i-impact.co.uk</a>/ and
    "i-IMPACT" means the website and related services.
</p>
<p>
    In this Agreement, ‘Healthcare Provider’ means the healthcare team/clinician
    that has pre assessed you and registered you on to i-IMPACT. Your Healthcare
    Provider is responsible for the ongoing management of your care, through the
    i-IMPACT programme.
</p>
<p>
    In this Agreement, University Hospitals of Leicester NHS Trust (UHL) means
    the healthcare organisation that has developed the i-IMPACT for use by
    healthcare providers. UHL are both the Programme Developer and a Healthcare
    Provider (for internal patients that have been assessed and registered by
    the UHL Cardiac and Pulmonary rehabilitation teams).
</p>
<p>
    In this Agreement, the term Trust means University Hospitals of Leicester
    NHS Trust (UHL).
</p>
<p>
    i-IMPACT is offered as a free service to you, the user. Your use of i-IMPACT
    and/or your acceptance of these terms, conditions, and notices without
    modifying them, constitute Agreement by you to all of these terms,
    conditions, and notices. Only those who enter into this Agreement are
    authorised to use i-IMPACT.
</p>
<p>
    <strong>Operator details</strong>
</p>
<p>
    i-IMPACT is operated by the Trust. For enquiries about i-IMPACT, you can
    write to UHL Pulmonary &amp; Cardiac rehabilitation team, Centre for
    Exercise and rehabilitation Science, University Hospitals of Leicester,
    Glenfield Hospital, Groby Road, Leicester. LE3 9QP.
</p>
<p>
    In operating this website the Trust adheres to relevant statutory duties and
    best practice guidance listed below. This list is not exhaustive but
    provides the legal framework upon which the website is reviewed on an
    on-going basis:
</p>
<ul>
    <li>
        Data Protection Act 2018
    </li>
    <li>
        General Data Protection Regulations (GDPR) 2016
    </li>
    <li>
        Freedom of Information Act 2000
    </li>
    <li>
        Human Rights Act 1998
    </li>
    <li>
        NHS Information security management guidance
    </li>
    <li>
        Common law of confidence
    </li>
    <li>
        Caldicott Guardianship
    </li>
    <li>
        NHS Code of Confidentiality
    </li>
    <li>
        NHS Code of Practice: Information Security Management
    </li>
</ul>
<p>
    <strong>Copyright and trademark notices</strong>
</p>
<p>
    Product and company names mentioned on the website or other websites
    services accessed through the website are owned by third parties. All
    contents of the website including this access Agreement are the copyright of
    the Trust and are protected by other proprietary rights including database
    rights. <br/>
</p>
<p>
    No part of the i-IMPACT rehabilitation programme, including the website and
    any e-mail we send, may be reproduced in any form whether electronically or
    otherwise without the prior written consent of the Trust. <br/>
</p>
<p>
    You must retain all copyright or trademark notices contained on this website
    on any copy you make of it or part of it. You may not sell or modify what is
    on the i-IMPACT rehabilitation programme or reproduce, display, publicly
    perform, distribute, or otherwise use the content on it in any way for any
    public, academic commercial purpose. The use of such content on any other
    website or in a networked computer environment for any purpose is
    prohibited.
</p>
<p>
    <strong></strong> <br/>
</p>
<p>
    <strong>i-IMPACT rehabilitation programme: Consent &amp; Access</strong>
</p>
<p>
    As the Patient, signing up for the use of i-IMPACT, you are doing so on your
    own volition, consenting to the care provided by your Healthcare Provider
    through i-IMPACT rehabilitation programme, and to the processing of Personal
    Information by them and the University Hospitals of Leicester NHS Trust
    (UHL). Personal Information shall be processed fairly in accordance with the
    principles outlined in the Data Protection Legislation, Data Protection Act
    2018, Human Rights Act 1998, Common Law Duty of Confidence, Caldicott
    Principles and other relevant NHS mandates in relation to the processing of
    personal and sensitive personal data as part of these Terms &amp;
    Conditions.
</p>
<p>
    To access i-IMPACT rehabilitation programme, you will need to be provided
    with a unique code by your Healthcare Provider. Your use of the password
    protected areas of this website, are subject to the following terms:
</p>
<p>
    <strong>Privacy Statement</strong>
</p>
<p>
    Please read the i-IMPACT rehabilitation programme privacy statement below.
</p>
<p>
    <strong></strong> <br/>
</p>
<p>
    <strong>1. Purpose of our statement</strong>
</p>
<p>
    <strong></strong> <br/>
</p>
<p>
    1.1. This Privacy Statement deals with how we handle “personal information”
    as it is defined in the UK GDPR (2020).
</p>
<p>
    1.2. UHL (<strong>we</strong>, <strong>us</strong> or <strong>our</strong>)
    provides a suite of services, web-based management software and associated
    infrastructure (i.e i-IMPACT online website), designed to help health and
    care providers ( <strong>Healthcare</strong> <strong>Provider</strong>)
    offer online support for Patients who are self-managing long term
    conditions, through an online rehabilitation service, called the i-IMPACT
    rehabilitation programme.
</p>
<p>
    1.3. We have adopted this Privacy Statement to ensure that we have standards
    in place to protect the Personal Information that we collect about
    individuals that is necessary and incidental to:
</p>
<p>
    (a) providing the system and services that i-IMPACT rehabilitation programme
    offers;
</p>
<p>
    (b) the operation of our website
</p>
<p>
    1.4. We ensure that we comply with the Data Protection Act (2018) and the UK
    General Data Protection Regulation (GDPR) (2020) for the handling of
    Personal Information, to provide appropriate protection and care with
    respect to the treatment of your personal information in accordance with the
    UK GDPR (2020)
</p>
<p>
    <strong>2. Who and what this statement applies to</strong>
</p>
<p>
    2.1. We collect Personal Information on our own behalf and in respect of
    organisations that utilise the i-IMPACT rehabilitation programme.
</p>
<p>
    2.2. This Privacy Statement applies to all forms of information, physical
    and digital, whether collected or stored electronically or in hardcopy.
</p>
<p>
    2.3. If, at any time, an individual provides Personal Information or other
    information about someone other than himself or herself, the individual
    warrants that they have that person's consent to provide such information
    for the purpose specified.
</p>
<p>
    <strong>3. The information we collect</strong>
</p>
<p>
    <strong></strong> <br/>
</p>
<p>
    3.1. In the course of delivering the i-IMPACT rehabilitation programme, it
    is necessary for us to collect Personal Information. This information allows
    us to identify who an individual is for the purposes of our business, share
    Personal Information when asked of us, contact the individual in the
    ordinary course of business and transact with the individual. Without
    limitation, the type of information we may collect is:
</p>
<p>
    <strong>(a)</strong> <strong>Personal Information</strong>. To access
    i-IMPACT, we may collect your first name, last name, DOB, email address,
    ethnic origin and your phone number.
</p>
<p>
    <strong>(b)</strong> <strong>Contact Information</strong>. We may collect
    information such as an individual’s email address, telephone number,
    postcode, NHS trust.
</p>
<p>
    <strong>(c)</strong> <strong>Medical History Information</strong>. We may
    collect information about your medical history, Main Diagnosis, Treatment,
    Pre Existing Medical Conditions, Height, Weight, Smoking &amp; Alcohol
    Status.
</p>
<p>
    <strong>(d) Health Assessment Information.</strong>Where applicable, Anxiety
    and depression score (HADS),  PHQ9, GAD7, Incremental Shuttle walking test
    (m), Endurance Shuttle walking test (secs), 6 minute walking test
    (distance),  Sit to stand (5 or 1 min), QMVC &amp; health status tracker.
</p>
<p>
    <strong>Questionnaires for Respiratory patients:</strong> St. George’s, CRQ,
    KBILD, ACQ, CAT, WHODAS
</p>
<p>
    <strong>Questionnaires for Cardiac patients:</strong> CHQ, Chester step,
    WHODAS
</p>
<p>
    <strong>Questionnaires for Covid patients:</strong> MOCA, CAT, FACIT, WHODAS
</p>
<p>
    <strong>(e)</strong> <strong>Statistical Information</strong>. When you use
    the i-IMPACT website we may make a record and log some or all of the
    following information for statistical or maintenance purposes, as part of
    service evaluation and to ensure the quality of the i-IMPACT website: <br/>
    (i) duration of use
</p>
<p>
    (ii) number of log ins
</p>
<p>
    (iii) number of days since last log in
</p>
<p>
    (iv) feedback provided on completion of the Programme
</p>
<p>
    3.2. Other Personal information may be collected by your Healthcare Provider
    and will be subject to your Healthcare Provider’s own privacy statement and
    any legislation or regulation relevant to that Personal Information.
</p>
<p>
    3.3. We may collect other Personal Information about an individual, which we
    will maintain in accordance with this Privacy Statement.
</p>
<p>
    <strong>4. How information is collected</strong>
</p>
<p>
    <strong></strong> <br/>
</p>
<p>
    4.1. Most information will be collected in association with an individual’s
    use of the i-IMPACT Website and rehabilitation Programme. However we may
    also receive Personal Information from sources such as your Healthcare
    Provider. In particular, information is likely to be collected as follows:
</p>
<p>
    <strong>(a) Registrations</strong>. When an individual is registered for
    i-IMPACT online Programme, the HCP (Healthcare Professional) enters Personal
    Information details in order for the individual to receive access to the
    Programme.
</p>
<p>
    <strong>(b)</strong> <strong>Supply</strong>. When an individual supplies us
    with personal information on the website;
</p>
<p>
    <strong>(c)</strong> <strong>Contact</strong>. When an individual contacts
    us in any way; and/or
</p>
<p>
    <strong>(d)</strong> <strong>Access</strong>. When an individual accesses
    i-IMPACT through the internet we may collect information using cookies (if
    relevant – an individual can adjust their browser’s setting to accept or
    reject cookies), APIs or analytical services. In this respect please refer
    to our Cookie Policy set out below.
</p>
<p>
    4.2. As there are many circumstances in which we may collect information
    both electronically and physically, we will endeavor to ensure that an
    individual is always aware of when their Personal Information is being
    collected.
</p>
<p>
    <strong></strong> <br/>
</p>
<p>
    <strong>5. When Personal Information is used and disclosed</strong>
</p>
<p>
    <strong></strong> <br/>
</p>
<p>
    5.1. In general, the primary principle is that we will not use any Personal
    Information other than for the purpose for which it was collected other than
    with the individual’s permission. The purpose of collection is determined by
    the circumstances in which the information was collected and/or submitted.
</p>
<p>
    5.2. We will retain Personal Information for the period necessary to fulfil
    the purposes outlined in this Privacy Statement unless a longer retention
    period is required or permitted by law. In particular, we will retain
    personal information in line with;
</p>
<p>
    (a) in relation to any Personal Information processed in connection with our
    obligations under this Agreement, at the written direction of your
    Healthcare Provider, delete or return the Personal Data (and any copies of
    it) to your Healthcare Provider on termination of this Agreement, unless we
    are required by Law to retain the personal data;
</p>
<p>
    (b) the period of retention of your personal data will be determined by your
    Healthcare Provider;
</p>
<p>
    (c) we provide assurance that the deletion of Personal Information will
    adhere to our Trust’s data destruction policy that will securely delete
    Personal Data.
</p>
<p>
    5.3. We will not disclose or sell an individual’s Personal Information to
    unrelated third parties under any circumstances.
</p>
<p>
    5.4. Your Provider may obtain Personal Information from you during the
    course of your use of the i-IMPACT rehabilitation programme. To the extent
    that you disclose Personal Information to a Provider that Personal
    Information will be dealt with in accordance with that Provider’s Privacy
    Statement.
</p>
<p>
    5.6. Information is used to enable us to operate our service, especially as
    it relates to an individual. This may include:
</p>
<p>
    (a) the provision of access to i-IMPACT
</p>
<p>
    (b) verifying an individual’s identity.
</p>
<p>
    (c) communicating with an individual about: <br/>
    i. their relationship with us; <br/>
    ii. surveys and questionnaires;
</p>
<p>
    (d) for service evaluation of the i-IMPACT programme:
</p>
<p>
    (d) investigating any complaints about or made by an individual, or if we
    have reason to suspect that an individual is in breach of any of our terms
    and conditions or that an individual is or has been otherwise engaged in any
    unlawful activity; and/or
</p>
<p>
    (e) as required or permitted by any law (including UK GDPR 2020 &amp; the
    Data Protection Act 2018).
</p>
<p>
    5.7. Your Healthcare Provider will use your Personal Information, for the
    purposes of enabling the provision of care.
</p>
<p>
    5.7. When we use your Personal Information for any service evaluation, we
    will anonymise your information, meaning no patient confidential information
    is shared, and you will remain unidentified. The purpose of service
    evaluation will be to evaluate the effectiveness of the i-IMPACT
    rehabilitation programme.
</p>
<p>
    5.8. There are some circumstances in which we must disclose an individual’s
    information:
</p>
<p>
    (a) where we reasonably believe that an individual may be engaged in
    fraudulent, deceptive or unlawful activity that a governmental authority
    should be made aware of; and
</p>
<p>
    (b) as required by any law (including UK GDPR 2020 &amp; the Data Protection
    Act 2018).
</p>
<p>
    <strong>6. Transfer to other countries</strong>
</p>
<p>
    6.1. We shall not transfer Personal Data outside of the UK unless the prior
    written consent of your Provider has been obtained and the following
    conditions are fulfilled:
</p>
<p>
    (a) we have provided appropriate safeguards in relation to the transfer, the
    data Subject has enforceable rights and effective legal remedies
</p>
<p>
    (b) we comply with our obligations under the Data Protection Legislation by
    providing an adequate level of protection to any personal data that is
    transferred.
</p>
<p>
    <strong></strong> <br/>
</p>
<p>
    <strong>7. Choosing to opt out or withdrawing consent</strong>
</p>
<p>
    <strong></strong> <br/>
</p>
<p>
    7.1. An individual may opt to not have us collect their Personal
    Information. This may prevent us from offering them some or all of our
    services and may terminate their access to some or all of the services they
    access with or through us. They will be aware of this when:
</p>
<p>
    (a) <strong>Opt In</strong>: where relevant, the individual will have the
    right to choose to have information collected and/or receive information
    from us; or
</p>
<p>
    (b) <strong>Opt Out</strong>: where relevant, the individual will have the
    right to choose to exclude himself or herself from some or all collection of
    information and/or receiving information from us.
</p>
<p>
    7.2. If you wish to withdraw your consent, then your healthcare provider
    will stop processing your information from the date of the withdrawal.
</p>
<p>
    <strong></strong> <br/>
</p>
<p>
    <strong>8. Securing your Personal Information</strong>
</p>
<p>
    8.1. We may appoint a Privacy Officer to oversee the management of this
    Privacy Statement and compliance with UK GDPR (2020) &amp; the Data
    Protection Act (2018). This officer may have other duties within our
    organisation and also be assisted by internal and external professionals and
    advisors.
</p>
<p>
    8.2. We will take all reasonable precautions to protect an individual’s
    Personal Information from unauthorised access. This includes appropriately
    securing our physical facilities and electronic networks.
</p>
<p>
    8.3. i-IMPACT uses encryption to store and transfer Personal Information.
    Despite this, the security of online transactions and the security of
    communications sent by electronic means or by post cannot be guaranteed.
    Each individual that provides information to us via the internet or by post
    does so at their own risk. We cannot accept responsibility for misuse or
    loss of, or unauthorised access to, Personal Information where the security
    of information is not within our control.
</p>
<p>
    8.4. We are not responsible for the privacy or security practices of any
    third party. The collection and use of an individual’s information by such
    third parties may be subject to separate privacy and security policies.
</p>
<p>
    8.5. If an individual suspects any misuse or loss of, or unauthorised access
    to, their Personal Information, they should let us know immediately.
</p>
<p>
    8.6. We are not liable for any loss, damage or claim arising out of another
    person’s use of the Personal Information where we were authorised to provide
    that person with the Personal Information.
</p>
<p>
    <strong>9. How to access and or update information</strong>
</p>
<p>
    9.1. Subject to UK GDPR (2020) &amp; the Data Protection Act (2018), an
    individual has the right to request from us the Personal Information that we
    have about them, and we have an obligation to provide them with such
    information within 28 days of receiving their written request.
</p>
<p>
    9.2. If an individual cannot update their own information, we will correct
    any errors in the Personal Information we hold about an individual within 7
    days of receiving written notice from them about those errors.
</p>
<p>
    9.3. It is an individual’s responsibility to provide us with accurate and
    truthful Personal Information. We cannot be liable for any information that
    is provided to us that is incorrect.
</p>
<p>
    <strong></strong> <br/>
</p>
<p>
    <strong>10. Your right to be forgotten</strong>
</p>
<p>
    10.1. If you request, we will delete or anonymise your personal data so that
    it no longer identifies you, unless, we are legally allowed or required to
    maintain certain personal data, including situations such as the following:
</p>
<p>
    (a) to comply with a legal obligation;
</p>
<p>
    (b) for the performance of a task carried out in the public interest or in
    the exercise of official authority;
</p>
<p>
    (c) for archiving purposes in the public interest, scientific research
    historical research or statistical purposes where erasure is likely to
    render impossible or seriously impair the achievement of that processing; or
</p>
<p>
    (d) for the establishment, exercise or defence of legal claims.
</p>
<p>
    10.2. Please note that if you request that we delete your personal data, you
    may not be able to access i-IMPACT rehabilitation programme until such time
    as that personal data is provided to us again.
</p>
<p>
    <strong></strong> <br/>
</p>
<p>
    <strong>11. Complaints and disputes</strong>
</p>
<p>
    <strong></strong> <br/>
</p>
<p>
    11.1. If an individual has a complaint about our handling of their Personal
    Information, they should address their complaint in writing to the details
    below.
</p>
<p>
    11.2. If we have a dispute regarding an individual’s Personal Information,
    we both must first attempt to resolve the issue directly between us.
</p>
<p>
    11.3. If we become aware of any unauthorised access to an individual’s
    Personal Information
</p>
<p>
    we will inform them at the earliest practical opportunity once we have
    established what was accessed and how it was accessed.
</p>
<p>
    <strong>12. Contacting individuals</strong>
</p>
<p>
    12.1. From time to time, we may send an individual important notice, such as
    changes to our terms, conditions and policies. Because this information is
    important to the individual’s interaction with us, they may not opt out of
    receiving these communications.
</p>
<p>
    <strong>13. Cookie policy</strong>
</p>
<p>
    13.1. The password protected areas of this website incorporates the use of
    cookies. Cookies are small data text files that are sent from a server
    computer during a browsing session. Cookies are typically stored on your
    computer’s hard drive and are used by websites to simulate a continuous
    connection to that site. In order to make our site more responsive to your
    needs we use cookies to track and manage information relative to your
    specific requirements. In this way we can tailor our site to your needs,
    deliver a better and more personalised service and track the pages on our
    site that you visit.
</p>
<p>
    13.2. The use of cookies automatically identifies your browser to our
    computers whenever you interact with our website.
</p>
<p>
    13.3 Your browser options allow you to disable cookies. You should note that
    if you do disable cookies, you may not be able to access all the services on
    this website.
</p>
<p>
    <strong></strong> <br/>
</p>
<p>
    <strong>14. Contacting us</strong>
</p>
<p>
    <strong></strong> <br/>
</p>
<p>
    14.1. All correspondence with regards to privacy should be addressed to:
</p>
<p>
    Saiful Choudhury
</p>
<p>
    Head of Privacy
</p>
<p>
    UHL IM&amp;T
</p>
<p>
    County hall
</p>
<p>
    Glenfield
</p>
<p>
    LE3 8RA
</p>
<p>
    <a href="mailto:Saiful.Choudhury@uhl-tr.nhs.uk">
        Saiful.Choudhury@uhl-tr.nhs.uk
    </a>
</p>
<p>
    <strong></strong> <br/>
</p>
<p>
    <strong>15. Additions to this policy</strong>
</p>
<p>
    15.1. If we decide to change this Privacy Statement, we will post the
    changes on our webpage at https://
    <a href="http://www.i-impact.co.uk/">
        www.i-impact.co.uk
    </a>
    /.  Please refer back to this Privacy Statement to review any amendments.
</p>
<p>
    15.2. We may do things in addition to what is stated in this Privacy
    Statement to comply with the UK GDPR (2020) &amp; Data Protection Act
    (2018), and nothing in this Privacy Statement shall deem us to have not
    complied with UK GDPR (2020) &amp; the Data Protection Act (2018).
</p>
<p>
    <strong>16. Links to Third-Party Sites</strong> <br/>
    The website contains hyperlinks to websites operated by third parties. These
    hyperlinks are provided for reference only. The Trust does not control these
    websites and is not responsible for their content. The overall site content
    is reviewed on an annual basis with individual content updated and reviewed
    due to revised or new information and changes to guidelines. <br/>
</p>
<p>
    A reference on the website to any persons, products, websites or services
    does not constitute or imply their endorsement, recommendation or favouring
    by the Trust. <br/>
</p>
<p>
    The hyperlinks provided from the website have been authorised by the Trust.
    If you would like to add a hyperlink to the site, you require written
    consent from the Trust. <br/>
</p>
<p>
    The Trust reserves the right to remove any hyperlink at any time and without
    notice and shall not be obliged to give a reason for doing so. If you become
    concerned about the content of a site linked to or from the website (or
    anything on the website), please inform the Trust immediately via e-mail to
    communications@uhl-tr.nhs.uk. <br/>
</p>
<p>
    If your complaint is about unsuitable content you may also consider
    reporting the matter to the Internet Watch Foundation who can be contacted
    via their website at www.iwf.org.uk.
</p>
<p>
    <strong></strong> <br/>
</p>
<p>
    <strong>17. Messages to and from the Trust</strong> <br/>
    There are a number of potential risks when using e-mail of which users
    should be aware. This is a non-technical (and very simplified) explanation
    of some of the confidentiality risks which exist.
</p>
<p>
    Please note that all messages are sent to the i-IMPACT rehabilitation
    service that registered you for this programme. Local health care
    professionals from your healthcare provider, will respond to your email
    within 2-3 working days. For any urgent enquiries please ring 111 or 999.
    <br/>
</p>
<p>
    <strong>Issues with e-mail</strong> <br/>
    When you send an e-mail message, the message is sent in clear text, so
    anyone can read the content. <br/>
</p>
<p>
    It is unlikely that the message you send will go straight to the computer
    from where it will be read. The message will be sent to a computer nearer to
    its final destination like a sequence of post boxes.
</p>
<p>
    Each time the e-mail goes through a post box, a copy remains in the post
    box. Because of the way the internet works, it is impossible to know how
    many post boxes the e-mail will go through on its journey <br/>
</p>
<p>
    There are other risks including:
</p>
<ul>
    <li>
        E-mail may be sent to the wrong address by any sender or receiver;
    </li>
    <li>
        E-mail is easier to forge than handwritten or signed papers;
    </li>
    <li>
        E-mail can be intercepted, altered, forwarded, or used without detection
        or authorisation;
    </li>
    <li>
        E-mail delivery is not guaranteed
    </li>
</ul>
<p>
    These are potential problems of which you should be aware, but e-mail is
    convenient and used by millions of people every day. <br/>
    Our advice
</p>
<ul>
    <li>
        E-mail has been likened to sending a post card. Our advice is do not put
        anything in an e-mail if you are concerned about someone else seeing it.
    </li>
    <li>
        There is no guarantee of delivery or delivery times, when using e-mail,
        so do not use it in cases of emergency.
    </li>
    <li>
        It is your responsibility to follow up with the Trust if you have not
        received a response to your e-mail within a reasonable period of time.
    </li>
    <li>
        If you have asked the Trust to communicate with you via e-mail it is
        your responsibility to advise the Trust of any change of e-mail address.
        You may withdraw your consent for the Trust to communicate with you via
        e-mail at any time.
    </li>
    <li>
        You should be aware that if you share your computer it may be possible
        for other people to be able to see emails you have sent or sites you
        have visited on the Internet, as your computer keeps a record of these.
    </li>
    <li>
        To protect against viruses and SPAM we ask that you do not include
        attachments with your email. If we suspect your email contains these, we
        may delete it without opening to prevent any damage to the Trust systems
        and services.
    </li>
</ul>
<p>
    If the message you want to send us contains information which will identify
    other people or relates to someone else's medical condition or treatment,
    please write to us or ring us instead of emailing us. <br/>
</p>
<p>
    Any e-mail or other form of communication between representatives of the
    Trust and other parties will be deemed covered by these Terms and
    Conditions.
</p>
<p>
    <strong>18. No Unlawful or Prohibited Use</strong> <br/>
    As a condition of your use of the website, you warrant to the Trust that you
    will not use the website for any purpose that is unlawful or prohibited by
    these Terms and Conditions, and notices.
</p>
<p>
    <strong>19. Other General Terms <br/>
    </strong>This Agreement is governed by the laws of England. You consent to
    the exclusive jurisdiction of the English courts in all disputes arising out
    of or relating to the use of the Web site. Use of the website outside the UK
    is unauthorised. <br/>
</p>
<p>
    You agree that no joint venture, partnership, employment, or agency
    relationship exists between you and the Trust as a result of this Agreement
    or use of the website. Each and every provision of this Agreement is
    separate and severable. If any part of this Agreement is determined to be
    invalid or unenforceable pursuant to applicable law including, but not
    limited to, the warranty disclaimers and liability limitations set out
    above, then the validity and enforceability of the remainder of the Terms
    and Conditions shall not be affected. <br/>
</p>
<p>
    These Terms and Conditions constitutes the entire Agreement between you and
    the Trust with respect to the website and it supersedes all prior or
    contemporaneous communications and proposals, whether electronic, oral, or
    written, between you and the Trust with respect to the website. A printed
    version of this Agreement and of any notice given in electronic form shall
    be admissible in judicial or administrative proceedings based upon or
    relating to this Agreement to the same extent and subject to the same
    conditions as other business documents and records originally generated and
    maintained in printed form. Any rights expressly granted herein are
    reserved. You will be responsible for all charges, fees, duties, taxes, and
    assessments arising out of your use of the website.
</p>
<p>
    <strong>20. Variation of these Terms and Conditions</strong> <br/>
    The Trust reserves the right to change the Terms and Conditions and notices
    under which the website is offered. We will do this by altering the Terms
    and Conditions on the website and this alteration will then be of immediate
    effect. For this reason, you should regularly visit the Terms and Conditions
    on the website as the current version will be binding on you. Please note
    any unauthorised reproduction of this website or its content is strictly
    prohibited.
</p>
<p>
    <strong>Limitation on types of use</strong>
</p>
<p>
    You may not modify, copy, distribute, disable, transmit, display, perform,
    reproduce, publish, license, create derivative works from, transfer, or sell
    any information or services obtained from i-IMPACT.
</p>
<p>
    You may not use i-IMPACT for anything other than the purpose outlined in
    this access Agreement and you may not use spiders or other robotic software
    tools to gather information from the website or use any applications which
    could lead to harm to the website.
</p>
<p>
    You may not use the i-IMPACT rehabilitation programme to indicate any
    association with i-IMPACT or the Trust without the Trust's prior written
    consent. If you would like information about obtaining the Trust's
    permission to use any content of i-IMPACT rehabilitation programme or if you
    would like to link your website to i-IMPACT, please write to: UHL Pulmonary
    &amp; Cardiac Rehabilitation Team, Centre for Exercise and rehabilitation
    Science, University Hospitals of Leicester, Glenfield Hospital, Groby Road,
    Leicester. LE3 9QP.
</p>
<p>
    <strong></strong> <br/>
</p>
<p>
    <strong>Liability disclaimer</strong>
</p>
<p>
    <strong>Please Read Carefully!</strong> <br/>
    <br/>
    The main purpose of i-IMPACT rehabilitation programme is to provide
    information to users about treatments and information for long term
    conditions. All information provided via i-IMPACT rehabilitation programme
    is general information which can never be treated as being a substitute for
    professional heath care. Do not rely upon any of the general information
    provided via the website for medical diagnosis or treatment. You must always
    consult with your doctor about personal health concerns.
</p>
<p>
    Messages sent to the website, will be received by your Healthcare Provider,
    who will respond as and when time permits.
</p>
<p>
    UHL will not respond to messages sent to the website, as the management of
    your care is the responsibility of your Healthcare Provider. An exception to
    this is if you are a UHL internal patient i.e. you have been assessed for
    and registered to i-IMPACT programme through the Cardiac or Pulmonary
    Rehabilitation team at UHL.
</p>
<p>
    The Trust shall not in any event be liable for any damages (whether
    indirect, incidental, special, consequential or otherwise) arising out of,
    or in any way connected with the use of the website, or with any delayed
    access to, or inability to use the website, or otherwise arising out of the
    use of the website, whether based on contract, tort, strict liability or
    otherwise. This does exclude or limit liability for death or person injury
    caused by negligence or for fraudulent misrepresentation.
</p>
<p>
    If you have any immediate concerns in relation to your heart or the health
    of someone else you should not delay in contacting your doctor or the
    emergency services directly on 999.
</p>
<p>
    Whilst the Trust does make every effort to check the accuracy of information
    published on i-IMPACT website  it cannot give any warranty that information
    will be accurate or up-to-date and the user acknowledges that the
    information and services offered on i-IMPACT may include inaccuracies, out
    of date information or typographical errors. In particular, and without
    limitation to the rest of this Agreement, the Trust is not able to guarantee
    that the i-IMPACT rehabilitation service will continue or be accurate during
    time of server failure.
</p>
<p>
    Information contained in or accessible via i-IMPACT website changes on a
    regular basis. The Trust may make improvements or alterations to i-IMPACT at
    any time without notice.
</p>
<p>
    The Trust is not responsible in any way whatsoever for the content of any
    site accessed using the website, nor can it warrant that use of i-IMPACT
    rehabilitation website will be free from virus contamination and you should
    take adequate steps to ensure that you virus check regularly when using
    i-IMPACT website on your computer, PDA or phone.
</p>
<p>
    The Trust is not in a position to know for what purpose you will use
    information or data provided by i-IMPACT website and so cannot insure
    against loss.
</p>
<p>
    The Trust makes no representations about the suitability of the information,
    and services contained on, or accessed via, i-IMPACT website for any
    purpose. All warranties, terms and conditions with regard to this
    information, and services, including all warranties, terms and conditions,
    implied by statute, collaterally or otherwise, of satisfactory quality,
    fitness for a particular purpose, title, and non-infringement are excluded
    to the fullest extent permitted by law.
</p>