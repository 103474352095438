<div class="main-content-area sub-listing">
<p class="px-3 pt-3 text-center">Each week a new bitesize session will be available to you.</p>
<ul class="tracker-list" *ngIf="bitesizeSessions">
  <ng-container
    *ngFor="let bitesizeSession of bitesizeSessions; let index = index"
  >
    <li 
    [ngClass]="{'item-locked':index>= currentWeekNumber}">
      <a
        [routerLink]="index < currentWeekNumber ? ['/bitesize', bitesizeSession.bitesize_session_id] : []"
        [ngClass]="{'disabled':index>= currentWeekNumber}"
      >
        <span class="icon-wrap tracker-num">
          <span
            class="num"
            *ngIf="
              !bitesizeSessionViewService.checkViewed(
                bitesizeSessionViews,
                bitesizeSession.bitesize_session_id
              )
            "
            >{{index+1}}</span
          >
          <span
            class="icon-wrap text-success"
            *ngIf="
              bitesizeSessionViewService.checkViewed(
                bitesizeSessionViews,
                bitesizeSession.bitesize_session_id
              )
            "
            ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
          ></span>
        </span>
        <span class="icon-wrap tracker-icon">
          <img
            src="/assets/img/placeholder-refresh.jpg"
            alt=""
            width="40"
            class="rounded"
          />
        </span>
        <span class="tracker-info">
          <span class="tracker-title">{{ bitesizeSession.title }}</span>

          <span class="details"
            >Bitesize Session ({{ index+1 }} of
            {{ getTotalBitesize() }})</span
          >
        </span>
        <span class="icon-wrap float-end icon-more"
          >
          <fa-icon [icon]="['fal', 'arrow-right']" *ngIf="index<currentWeekNumber"></fa-icon>
          <fa-icon [icon]="['fal', 'lock']" *ngIf="index>= currentWeekNumber"></fa-icon>
      </span>
      </a>
    </li>
  </ng-container>
</ul>
</div>
