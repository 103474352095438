<div *ngIf="!completed">
    <div class="internal-app card card-light mt-3 mb-4">
      <div class="card-body">
        <form
          (ngSubmit)="onSubmit()"
          #form="ngForm"
          class="sleep-score-form eq5d-form"
        >
          <fieldset>
            <div class="form-group">
              <label class="internal-title" for="symptoms"
                ><h4>Which, if any, are currently issues for you?</h4></label
              >
              <div  class=" checkbox-container">
                <div
                  *ngFor="let symptom of symptoms"
                  class="checkbox"
                >
  
              
              <input
                type="checkbox"
                value="{{ symptom.value }}"
                name="picked"
                [checked]="
                  picked &&
                  -1 !== picked.indexOf(symptom.value)
                    ? 'checked'
                    : ''
                "
                (change)="onPick($event, symptom.value)"
                id="treatment-{{symptom.value}}"
              />
                  <label for="treatment-{{symptom.value}}">
                    <span class="icon-wrap">
                      <fa-icon
                      [icon]="['fal', 'circle']"
                      [fixedWidth]="true"
                      *ngIf="!(
                      picked &&
                      -1 !== picked.indexOf(symptom.value)
                        ? 'checked'
                        : '')
                    "
                    ></fa-icon>
                    <fa-icon
                      [icon]="['fas', 'check-circle']"
                      [fixedWidth]="true"
                      *ngIf="
                      picked &&
                      -1 !== picked.indexOf(symptom.value)
                        ? 'checked'
                        : ''
                    "
                    ></fa-icon>
                    </span>
                    {{ symptom.label }}
                  </label>
                </div>
              </div>
            </div>
  
  
  
  
            
          </fieldset>
          <button class="btn btn-primary btn-block mb-4" [disabled]="!form.valid">
            Submit
            <!--<span class="icon-wrap"
              ><fa-icon [icon]="['far', 'check']"></fa-icon
            ></span>-->
          </button>
        </form>
      </div>
    </div>
  </div>
  <div
    class="internal-app card card-light drop-shadow mt-3 mb-4 sleep-score-results"
    *ngIf="completed"
  >
    <div class="card-body">
      <h4>Recommendations</h4>
      <p *ngIf="picked.length==0">
        <strong>
          It seems from your responses that you are not currently
          experiencing any of the symptoms of anxiety and fear that are commonly
          experience following illness. That’s great, we hope you may still find some of
          the content of this section helpful
        </strong>
      </p>
      
      <div *ngIf="picked.length!=0">
        <span *ngIf="!(picked.indexOf(2)!==-1)">
      <p>
        <strong>
          We believe you will find the following section(s) relevant to you:
        </strong>
      </p>
      <span *ngIf="picked.indexOf(1)!==-1 || picked.indexOf(5)!==-1 || picked.indexOf(6)!==-1">
        <strong> <a [routerLink]="['/resources', 17, 30]" class="btn btn-primary">Worry about symptoms</a>&nbsp; </strong>
      </span>
      <span *ngIf="picked.indexOf(3)!==-1 ">
        <strong> <a [routerLink]="['/resources', 17, 28]" class="btn btn-primary">Panic</a>&nbsp;   </strong>
      </span>
      <span *ngIf="picked.indexOf(4)!==-1 || picked.indexOf(7)!==-1 ">
        <strong> <a [routerLink]="['/resources', 17, 31]" class="btn btn-primary">Nightmares and distressing memories</a>&nbsp;  </strong>
      </span>
      <span *ngIf="picked.indexOf(8)!==-1">
        <strong> <a [routerLink]="['/resources', 17, 31]" class="btn btn-primary">Sleep</a>&nbsp;  </strong>
      </span>
    </span>
  
      <strong *ngIf="picked.indexOf(2)!==-1">
        We believe you may benefit from reading all of the sections in this resource. Please click 'next' to find out more
      </strong>
    </div>
    </div>
  </div>
  
  
  