<div *ngIf="isLoading" style="text-align: center">
    <app-loading-spinner></app-loading-spinner>
  </div>

  <div class="container container-register">
    <div class="row">
      <div class="col-lg-8 offset-lg-2">
        <div>
          <button class="btn btn-back btn-lg" [routerLink]="['/']">
            <span class="icon-wrap"
              ><fa-icon
                [icon]="['fal', 'arrow-left']"
                [fixedWidth]="true"
              ></fa-icon
            ><span class="sr-only">Back</span></span>
          </button>
        </div>

        <div class="card rounded-3 text-black">
          <div class="card-body login-form">
            <div class="text-center">
              <img
                src="/assets/img/logo-iimpact-colour.svg"
                style="width: 185px"
                alt="logo"
              />
              <h4 class="login-title">Create Account</h4>
            </div>

            <div [hidden]="step != 0">
              <p>
                If you have been referred on to the i-IMPACT programme you will
                have been provided with a unique code which is assigned to your
                email address.
              </p>
              <p>
                <a [routerLink]="['/login']"
                  >Already have an account? Sign in</a
                >
              </p>

              <p>Please enter you email address and registration code below.</p>
              <div class="alert alert-danger" *ngIf="error">
                <p>{{ error }}</p>
              </div>

              <form
                #authForm="ngForm"
                class="goal-form"
                (ngSubmit)="onSubmit(authForm)"
              >
                <div class="form-group form-floating">
                  <input
                    type="email"
                    id="email"
                    class="form-control"
                    ngModel
                    name="email"
                    placeholder="Please enter your email address"
                    required
                    email
                    #email="ngModel"
                    [ngClass]="{ 'is-invalid': email.touched && !email.valid }"
                    autocomplete="off"
                  />
                  <label for="email"
                    >Email Address: <span class="required">*</span></label
                  >
                  <div
                    *ngIf="email.touched && !email.valid"
                    class="text-danger mt-1"
                  >
                    Please enter a valid email address
                  </div>
                </div>
                <div class="form-group form-floating">
                  <input
                    type="text"
                    id="code"
                    class="form-control"
                    ngModel
                    name="code"
                    placeholder="Please enter your Registration Code"
                    required
                    autocomplete="off"
                    #code="ngModel"
                    [ngClass]="{ 'is-invalid': code.touched && !code.valid }"
                  />
                  <label for="code"
                    >Registration Code: <span class="required">*</span></label
                  >
                  <div
                    *ngIf="code.touched && !code.valid"
                    class="text-danger mt-1"
                  >
                    Please enter a valid code
                  </div>
                </div>

                <div class="required-info">
                  <span class="required">*</span> Required field
                </div>

                <button
                  type="submit"
                  [disabled]="!authForm.valid || isLoading"
                  class="btn btn-primary btn-lg w-100 mb-3"
                >
                  Next
                </button>
              </form>
            </div>

            <!-- step 2 -->
            <form
              #detailsForm="ngForm"
              class="goal-form"
              (ngSubmit)="onSubmitRegistration(detailsForm)"
            >
              <div class="row" [hidden]="step != 1">
                <fieldset
                  ngModelGroup="accountSetup"
                  #accountSetup="ngModelGroup"
                >
                  <h3 class="text-center">Set Password</h3>
                  <div class="alert alert-danger" *ngIf="error">
                    <p>{{ error }}</p>
                  </div>

                  <div class="text-center">
                    <div>{{ user?.email }}</div>
                    <div>Registration Code: {{ user?.code }}</div>
                  </div>
                  <div
                    ngModelGroup="passwords"
                    #passwords="ngModelGroup"
                    appCheckPassword
                  >
                    <div class="form-group">
                      <div class="form-floating mb-2">
                        <input
                          type="password"
                          id="password"
                          class="form-control"
                          name="password"
                          placeholder="Please enter your password"
                          required
                          pattern="(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{9,}"
                          [ngClass]="{
                            'is-invalid': password.dirty && !password.valid
                          }"
                          #password="ngModel"
                          [(ngModel)]="password1"
                        />
                        <label for="password"
                          >Set Password: <span class="required">*</span></label
                        >
                      </div>

                      <div class="form-floating mb-2">
                        <input
                          type="password"
                          id="passwordConfirm"
                          class="form-control"
                          name="passwordConfirm"
                          placeholder="Please confirm your password"
                          required
                          [ngClass]="{
                            'is-invalid':
                              passwordConfirm.dirty && !passwordConfirm.valid
                          }"
                          #passwordConfirm="ngModel"
                          [(ngModel)]="password2"
                        />

                        <label for="passwordConfirm"
                          >Confirm Password:
                          <span class="required">*</span></label
                        >
                      </div>

                      <div
                        *ngIf="
                          passwords.errors?.passwordCheck &&
                          (passwordConfirm.dirty || passwordConfirm.touched)
                        "
                        class="alert alert-danger"
                      >
                        Passwords do not match.
                      </div>

                      <p>Your password must contain the following</p>
                      <ul>
                        <li
                          [ngClass]="{
                            'text-danger':
                              password.dirty && password.viewModel.length < 10,
                            'text-success':
                              password.dirty && password.viewModel.length > 9
                          }"
                        >
                          At least 10 characters
                          <span
                            class="icon-wrap"
                            *ngIf="password.viewModel.length > 9"
                            ><fa-icon
                              [icon]="['far', 'check']"
                              [fixedWidth]="true"
                            ></fa-icon></span
                          ><span
                            class="icon-wrap"
                            *ngIf="
                              password.dirty && password.viewModel.length < 10
                            "
                            ><fa-icon
                              [icon]="['far', 'times']"
                              [fixedWidth]="true"
                            ></fa-icon
                          ></span>
                        </li>
                        <li
                          [ngClass]="{
                            'text-danger':
                              password.dirty &&
                              !checkPassword(password.viewModel, 'upper'),
                            'text-success':
                              password.dirty &&
                              checkPassword(password.viewModel, 'upper')
                          }"
                        >
                          An uppercase character (A-Z)
                          <span
                            class="icon-wrap"
                            *ngIf="checkPassword(password.viewModel, 'upper')"
                            ><fa-icon
                              [icon]="['far', 'check']"
                              [fixedWidth]="true"
                            ></fa-icon></span
                          ><span
                            class="icon-wrap"
                            *ngIf="
                              password.dirty &&
                              !checkPassword(password.viewModel, 'upper')
                            "
                            ><fa-icon
                              [icon]="['far', 'times']"
                              [fixedWidth]="true"
                            ></fa-icon
                          ></span>
                        </li>
                        <li
                          [ngClass]="{
                            'text-danger':
                              password.dirty &&
                              !checkPassword(password.viewModel, 'lower'),
                            'text-success':
                              password.dirty &&
                              checkPassword(password.viewModel, 'lower')
                          }"
                        >
                          A lowercase character (a-z)
                          <span
                            class="icon-wrap"
                            *ngIf="
                              password.dirty &&
                              checkPassword(password.viewModel, 'lower')
                            "
                            ><fa-icon
                              [icon]="['far', 'check']"
                              [fixedWidth]="true"
                            ></fa-icon></span
                          ><span
                            class="icon-wrap"
                            *ngIf="
                              password.dirty &&
                              !checkPassword(password.viewModel, 'lower')
                            "
                            ><fa-icon
                              [icon]="['far', 'times']"
                              [fixedWidth]="true"
                            ></fa-icon
                          ></span>
                        </li>
                        <li
                          [ngClass]="{
                            'text-danger':
                              password.dirty &&
                              !checkPassword(password.viewModel, 'num'),
                            'text-success':
                              password.dirty &&
                              checkPassword(password.viewModel, 'num')
                          }"
                        >
                          A digit (0-9)<span
                            class="icon-wrap"
                            *ngIf="
                              password.dirty &&
                              checkPassword(password.viewModel, 'num')
                            "
                            ><fa-icon
                              [icon]="['far', 'check']"
                              [fixedWidth]="true"
                            ></fa-icon></span
                          ><span
                            class="icon-wrap"
                            *ngIf="
                              password.dirty &&
                              !checkPassword(password.viewModel, 'num')
                            "
                            ><fa-icon
                              [icon]="['far', 'times']"
                              [fixedWidth]="true"
                            ></fa-icon
                          ></span>
                        </li>
                        <li
                          [ngClass]="{
                            'text-danger':
                              password.dirty &&
                              !checkPassword(password.viewModel, 'special'),
                            'text-success':
                              password.dirty &&
                              checkPassword(password.viewModel, 'special')
                          }"
                        >
                          A special character ($@!%*?)<span
                            class="icon-wrap"
                            *ngIf="
                              password.dirty &&
                              checkPassword(password.viewModel, 'special')
                            "
                            ><fa-icon
                              [icon]="['far', 'check']"
                              [fixedWidth]="true"
                            ></fa-icon></span
                          ><span
                            class="icon-wrap"
                            *ngIf="
                              password.dirty &&
                              !checkPassword(password.viewModel, 'special')
                            "
                            ><fa-icon
                              [icon]="['far', 'times']"
                              [fixedWidth]="true"
                            ></fa-icon
                          ></span>
                        </li>
                      </ul>
                      <div
                        class="alert alert-danger"
                        *ngIf="!password.valid && password.touched"
                      >
                        Your password is invalid
                      </div>
                    </div>
                  </div>

                  <div class="required-info">
                    <span class="required">*</span> Required field
                  </div>

                  <div class="col text-end">
                    <button
                      type="submit"
                      [disabled]="!accountSetup.valid || isLoading"
                      class="btn btn-primary btn-lg w-100 mb-4"
                      (click)="onNext($event)"
                    >
                      Next
                    </button>
                  </div>
                </fieldset>
              </div>

              <div [hidden]="step != 2">
                <fieldset
                  ngModelGroup="accountTerms"
                  #accountTerms="ngModelGroup"
                >

                <h3 class="text-center">Terms & Conditions</h3>
                  <div class="alert alert-danger" *ngIf="error">
                    <p>{{ error }}</p>
                  </div>

                  <div class="form-group">
                    <p class="p-3">
                      Please note that all messages are sent to the IMPACT
                      service that registered you for this programme. Local
                      health care professionals will respond to your email
                      within 2-3 working days. For any urgent medical enquiries
                      please ring 111 or 999.
                    </p>

                    <div class="terms-box">
                      <app-terms-content></app-terms-content>
                    </div>

                    <label for="agreeTerms">
                      Agree to the Terms and Conditions
                      <span class="required">*</span>
                    </label>
                    <div class="form-check">
                      <input
                        type="checkbox"
                        name="isAccept"
                        ngModel
                        id="agreeTerms"
                        required
                        class="form-check-input"
                      />
                      Please review and accept Terms &amp; Conditions
                    </div>
                  </div>

                  <div class="required-info">
                    <span class="required">*</span> Required field
                  </div>

                  <div class="col text-end">
                    <button
                      type="submit"
                      [disabled]="!accountSetup.valid || isLoading"
                      class="btn btn-primary w-100 btn-lg mb-4"
                      (click)="onNext($event)"
                    >
                      Next
                      <span class="icon-wrap">
                        <fa-icon
                          [icon]="['far', 'arrow-circle-right']"
                          [fixedWidth]="true"
                        ></fa-icon>
                      </span>
                    </button>
                  </div>
                </fieldset>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-8 offset-lg-2">
      <div class="signin-details">
        <div class="signin-details-flex">
          <div class="signin-left">
            Website last reviewed April 2024<br>
          </div>
          <div class="signin-right">
            <a [routerLink]="['/terms-and-conditions']"
              >Terms &amp; Conditions</a
            >&nbsp;|&nbsp; 
            <a [routerLink]="['/privacy-policy']">Privacy Policy</a>
          </div>
        </div>
        <div class="signin-details-flex">
          <div class="signin-left">
            © {{ currentYear }} University Hospitals of Leicester
          </div>
          <div class="signin-right">
            Built by <a href="https://www.hark2.com">Hark 2</a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col footer-logos">
          <div class="programme-logo-strap">Incorporating the programmes</div>
          <div class="programme-logos">
            <img
              src="./assets/img/logo-ayh-colour.svg"
              alt="Activate Your Heart Logo"
              width="93"
            />
            <img
              src="./assets/img/logo-space-colour.svg"
              alt="SPACE for COPD"
              width="69"
            />
            <img
              src="./assets/img/logo-ycr-colour.svg"
              alt="Your COVID Recovery"
              width="93"
            />
          </div>
        </div>
      </div>
    </div> 
  </div>
