
<!--app-navbar
  (sideAction)="onSideAction()"
  [navbarOptions]="{
    sideAction: 'showHelp',
    sideActionIcon: 'info-circle',
    style: 'light'
  }"
  [pageTitle]="'Getting Started'"
  [home]="false"
></app-navbar-->
<div *ngIf="isLoading">
  <app-loading-spinner [inline]="true"></app-loading-spinner>
</div>
<div *ngIf="!isLoading">
  <div class="main-content-area">
  

  <div class="card text-center card-light mb-3" *ngIf="tasksComplete >= taskIds.length">
    <div class="card-body text-center">
      <span class="icon-wrap text-success" style="font-size: 4rem;"><fa-icon [icon]="['fas', 'check-circle']"></fa-icon></span>
    <p style="font-size: 1.25rem;">
      Well done on completing this section.</p>
      <p>You're now ready to start <strong>Week 1</strong> of the i-IMPACT programme.
    </p>
    <button [routerLink]="['/dashboard']" class="btn btn-primary w-100">Start the Programme</button>
    </div>
  </div>
  <div *ngIf="tasksComplete < taskIds.length">
    <div class="card text-center card-secondary mb-3">
        <div class="card-body text-center">
          <img
        src="./assets/img/logo-impact-white-new.svg"
        alt="i-impact"
        width="115"
        class="mb-2"
      />
          <p>Before you start <strong>Week 1</strong> we would like you to complete the quick tasks below to make sure you are able to get the most out of programme.</p>
          <div class="todo-progress">
            <span class="progress progress-dividers">
              <span
                *ngFor="let task of taskIds; let index = index"
                class="progress-bar"
                [ngClass]="{
                  'bg-success': index < tasksComplete,
                  'bg-white': index >= tasksComplete
                }"
                role="progressbar"
                style="width: 14.285714%"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <span class="icon-wrap text-white" *ngIf="index < tasksComplete"
                  ><fa-icon [icon]="['fas', 'check']"></fa-icon
                ></span>
              </span>
            </span>
            <div class="progress-strap">
              <strong>{{ tasksComplete }} of {{ taskIds.length }}</strong> tasks
              completed
            </div>
          </div>
        </div>
    </div>
  <ul class="dashboard-flex-layout tracker-list tracker-list-padded">
    <li>
      <a (click)="openModal($event, template)" class="pointer">
        <span class="icon-wrap tracker-num">
          <span class="num" *ngIf="!userTasksStatusById['welcomeVideo']"
            >1</span
          >
          <span
            *ngIf="userTasksStatusById['welcomeVideo']"
            class="icon-wrap text-success"
            ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
          ></span>
        </span>
        <span class="icon-wrap tracker-icon">
          <img
            src="/assets/img/placeholder-refresh.jpg"
            alt=""
            width="40"
            class="rounded"
          />
        </span>
        <span class="tracker-info">
          <span class="tracker-title">Welcome Video </span>
          <span class="details">Watch the welcome video</span>
        </span>
        <span class="icon-wrap float-end icon-more"
          ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
        ></span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/getting-started', 'eq5d']">
        <span class="icon-wrap tracker-num">
          <span
            class="num"
            *ngIf="
              !userTasksStatusById['eq5dPre']
            "
            >2</span
          >
          <span
            *ngIf="
              userTasksStatusById['eq5dPre']
            "
            class="icon-wrap text-success"
            ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
          ></span>
        </span>
        <span class="icon-wrap tracker-icon">
          <img
            src="/assets/img/placeholder-cardio.jpg"
            alt=""
            width="40"
            class="rounded"
          />
        </span>
        <span class="tracker-info">
          <span class="tracker-title">Health Questionnaire</span>

          <div *ngIf="isLoading" class="text-center">
            <app-loading-spinner [inline]="true"></app-loading-spinner>
          </div>
          <span class="details">
            Six multiple-choice questions.
          </span>
        </span>
        <span class="icon-wrap float-end icon-more"
          ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
        ></span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/getting-started', 'exercise']">
        <span class="icon-wrap tracker-num">
          <span class="num" *ngIf="!userTasksStatusById['readActivity']"
            >3</span
          >
          <span
            *ngIf="userTasksStatusById['readActivity']"
            class="icon-wrap text-success"
            ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
          ></span>
        </span>
        <span class="icon-wrap tracker-icon">
          <img
            src="/assets/img/placeholder-goals.jpg"
            alt=""
            width="40"
            class="rounded"
          />
        </span>
        <span class="tracker-info">
          <span class="tracker-title">The Exercise Programme</span>

          <span class="details">Improve your cardio and strength</span>
        </span>
        <span class="icon-wrap float-end icon-more"
          ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
        ></span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/getting-started', 'goals']">
        <span class="icon-wrap tracker-num">
          <span class="num" *ngIf="!userTasksStatusById['readGoals']">4</span>
          <span
            *ngIf="userTasksStatusById['readGoals']"
            class="icon-wrap text-success"
            ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
          ></span>
        </span>
        <span class="icon-wrap tracker-icon">
          <img
            src="/assets/img/placeholder-strength.jpg"
            alt=""
            width="40"
            class="rounded"
          />
        </span>
        <span class="tracker-info">
          <span class="tracker-title">Get started with Goals</span>

          <div *ngIf="isLoading" class="text-center">
            <app-loading-spinner [inline]="true"></app-loading-spinner>
          </div>

          <span class="details"> Read about setting goals.</span>
        </span>
        <span class="icon-wrap float-end icon-more"
          ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
        ></span>
      </a>
    </li>
    <li>
      <a [routerLink]="['/getting-started', 'health-status']">
        <span class="icon-wrap tracker-num">
          <span class="num" *ngIf="!userTasksStatusById['healthStatusBaseline']">5</span>
          <span
            *ngIf="userTasksStatusById['healthStatusBaseline']"
            class="icon-wrap text-success"
            ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
          ></span>
        </span>
        <span class="icon-wrap tracker-icon">
          <img
          src="/assets/img/placeholder-symptoms.jpg"
            alt=""
            width="40"
            class="rounded"
          />
        </span>
        <span class="tracker-info">
          <span class="tracker-title">Setup your Health Trackers</span>

          <div *ngIf="isLoading" class="text-center">
            <app-loading-spinner [inline]="true"></app-loading-spinner>
          </div>

          <span class="details">Set your baselines.</span>
        </span>
        <span class="icon-wrap float-end icon-more"
          ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
        ></span>
      </a>
    </li>
    <!--<li>
      <a [routerLink]="['/getting-started','reading-list']">
        <span class="icon-wrap tracker-num">
          <span class="num" *ngIf="!userTasksStatusById['readingList']">6</span>
          <span
            *ngIf="userTasksStatusById['readingList']"
            class="icon-wrap text-success"
            ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
          ></span>
        </span>
        <span class="icon-wrap tracker-icon">
          <img
            src="/assets/img/placeholder-todo.jpg"
            alt=""
            width="40"
            class="rounded"
          />
        </span>
        <span class="tracker-info">
          <span class="tracker-title">Check out your Reading List</span>

          <div *ngIf="isLoading" class="text-center">
            <app-loading-spinner [inline]="true"></app-loading-spinner>
          </div>

          <span class="details">This condition-specific list is available in the Resources section.</span>
        </span>
        <span class="icon-wrap float-end icon-more"
          ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
        ></span>
      </a>
    </li>-->
    <li>
      <a [routerLink]="['/getting-started', 'exercise-quiz']">
        <span class="icon-wrap tracker-num">
          <span class="num" *ngIf="!userTasksStatusById['exerciseSafetyQuiz']"
            >6</span
          >
          <span
            *ngIf="userTasksStatusById['exerciseSafetyQuiz']"
            class="icon-wrap text-success"
            ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
          ></span>
        </span>
        <span class="icon-wrap tracker-icon">
          <img
            src="/assets/img/placeholder-goals.jpg"
            alt=""
            width="40"
            class="rounded"
          />
        </span>
        <span class="tracker-info">
          <span class="tracker-title">Exercising Safely</span>

          <span class="details">Take the quiz.</span>
        </span>
        <span class="icon-wrap float-end icon-more"
          ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
        ></span>
      </a>
    </li>
    <li *ngIf="taskIds.indexOf('createActionPlan')!==-1">
      <a [routerLink]="['/getting-started', 'action-plan']">
        <span class="icon-wrap tracker-num">
          <span class="num" *ngIf="!userTasksStatusById['createActionPlan']"
            >7</span
          >
          <span
            *ngIf="userTasksStatusById['createActionPlan']"
            class="icon-wrap text-success"
            ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
          ></span>
        </span>
        <span class="icon-wrap tracker-icon">
          <img
            src="/assets/img/placeholder-symptoms.jpg"
            alt=""
            width="40"
            class="rounded"
          />
        </span>
        <span class="tracker-info">
          <span class="tracker-title">Create your Action Plan</span>

          <span class="details"
            >Set your usual symptoms.</span
          >
        </span>
        <span class="icon-wrap float-end icon-more"
          ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
        ></span>
      </a>
    </li>
  </ul>

  <div class="bg-light more-resources">
    <h2 class="card-title text-center">More options</h2>
    <ul class="tracker-list">
      <li>
          <a class="task" [routerLink]="['/getting-started','ask-hcp']">
          <span class="tracker-info">
              <span class="tracker-title">Ask a Healthcare Professional</span>
          </span>
          <span class="icon-wrap float-end icon-more"
              ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon>
          </span>
          </a>
        </li>
        <li>
          <a class="task" [routerLink]="['/getting-started','support']">
          <span class="tracker-info">
              <span class="tracker-title">Technical Support</span>
          </span>
          <span class="icon-wrap float-end icon-more"
              ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon>
          </span>
          </a>
        </li>
        <li>
          <a class="task"  [routerLink]="['']" (click)="onLogout()">
          <span class="tracker-info">
              <span class="tracker-title">Sign out</span>
          </span>
          <span class="icon-wrap float-end icon-more" style="color: #E25050;"
              ><fa-icon [icon]="['fal', 'sign-out']"></fa-icon>
          </span>
          </a>
        </li>
    </ul>
  </div>
</div>
  <ng-template #template>
    <div class="modal-header">
      <h5 class="modal-title">Welcome Video</h5>
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="modal"
        aria-label="Close"
        (click)="modalRef?.hide()"
      ></button>
    </div>
    <div class="modal-body">
      <app-welcome-video
        (closeModal)="onCloseModal($event)"
      ></app-welcome-video>
    </div>
  </ng-template>
</div>
<div>
  <div class="signin-details">
    <div class="signin-details-flex">
      <div class="signin-left">
        © {{ currentYear }} University Hospitals of Leicester
      </div>
      <div class="signin-right">
        <a [routerLink]="['/getting-started', 'terms']"
        >Terms &amp; Conditions</a
      >&nbsp;|&nbsp; 
      <a [routerLink]="['/getting-started', 'privacy']">Privacy Policy</a>
      </div>
    </div>
  </div>
  <div class="">
    <div class="col footer-logos">
      <div class="programme-logo-strap">Incorporating the programmes</div>
      <div class="programme-logos">
        <img
          src="./assets/img/logo-ayh-colour.svg"
          alt="Activate Your Heart Logo"
          width="93"
        />
        <img
          src="./assets/img/logo-space-colour.svg"
          alt="SPACE for COPD"
          width="69"
        />
        <img
          src="./assets/img/logo-ycr-colour.svg"
          alt="Your COVID Recovery"
          width="93"
        />
      </div>
    </div>
  </div>
</div> 
</div>

