import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterOutlet,
} from '@angular/router';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { AuthService } from '../auth/service/auth.service';
import { Location } from '@angular/common';
import { ResourcesService } from '../services/resources.service';
import { UserService } from '../auth/service/user.service';
import { NotificationService } from '../services/notification.service';
import { Notification } from '../models/notification.model';
import { BehaviorSubject, interval } from 'rxjs';
import { NavbarService } from '../services/navbar.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Input() pageTitle: any;
  @Input() pageIcon: any;
  @Input() tallNav: any;
  @Input() home: any;
  @Input() navbarOptions: any;
  @Input() resourceId: any;
  @Input() routerOutlet!: RouterOutlet;
  @Output() sideAction: EventEmitter<any> = new EventEmitter();
  isDashboard = false;
  showAside = false;
  public userData: any;
  orginalTitle:any;

  notifications: Notification[] = [];
  notificationsInterval: any;

  customAddLink:any;

  constructor(
    private authService: AuthService,
    private location: Location,
    public router: Router,
    public route: ActivatedRoute,
    private resourceService: ResourcesService,
    private userService: UserService,
    private notificationService: NotificationService,
    private navbarService:NavbarService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.orginalTitle = ''+this.pageTitle;
    // are we logged in?
    this.authService.user.subscribe((user) => {
      if (user) {
        // check for notifications
        this.notificationsInterval = null;
        this.notificationsInterval = interval(60000).subscribe((count) => {
          this.checkNotifications();
        });
        this.notificationService.updateNotifications.subscribe((data) => {
          if (data) {
            this.checkNotifications();
          }
        });
        // end notifications
        this.checkNotifications();
      } else {
        this.notificationsInterval = null;
      }
    });

    this.router.events.subscribe((event) => {

      if (!(event instanceof NavigationEnd)) {
        return;
      } else {
        this.customAddLink = null;
        // parse any links
        if(this.route?.root?.firstChild?.snapshot.data?.navbarOptions?.addLink && this.route?.root?.firstChild?.snapshot.params){
          this.customAddLink = this.parseLink(this.route.root.firstChild.snapshot.data.navbarOptions.addLink, this.route.root.firstChild.snapshot.params);
        }
        
        if (this.route.snapshot.routeConfig?.path == 'resources/:id') {
          this.pageTitle = '';
          this.resourceId = this.route.snapshot.params.id;
          this.resourceService
            .fetchResource(this.resourceId)
            .subscribe((responseData) => {
              this.pageTitle = responseData.resource.title;
            });
        }
        if (
          this.route.snapshot.routeConfig?.path ==
          'resources/:resourceId/:pageId'
        ) {
          this.pageTitle = '';
          this.resourceService
            .fetchResource(this.route.snapshot.params.resourceId)
            .subscribe((responseData) => {
              responseData.pages.forEach((page: any, pIndex: any) => {
                if (
                  +page.content_page_id === +this.route.snapshot.params.pageId
                ) {
                  this.pageTitle = page.title;
                }
              });
            });
        }
        if (this.router.url == '/todo') {
          if (!this.userService.userData.getValue()) {
            this.userService.userData.subscribe((userData) => {
              if (userData) {
                this.userData = userData;
                this.pageTitle =
                  'To-Do List: Week ' + this.userData.current_week;
              }
            });
          } else {
            this.userData = this.userService.userData.getValue();
            this.pageTitle = 'To-Do List: Week ' + this.userData.current_week;
          }
        }
        this.showAside = false;
      }
    });
    this.navbarService.customTitle.subscribe((customTitle)=>{
      if(customTitle){
        this.pageTitle = customTitle;
        this.cdr.detectChanges();
      }
      else{
        this.pageTitle = this.orginalTitle;
      }
    });
    
  }

  logout(): void {
    this.authService.logout();
  }
  onBack() {
    let navigated = false;

    // trackers
    if (this.router.url.includes('/trackers/health-status/')) {
      this.router.navigate(['/trackers']);
      navigated = true;
    }
    if (this.router.url.includes('/trackers/health-status/update')) {
      this.location.back();
      navigated = true;
    }
    if (this.router.url.includes('/trackers/weight/')) {
      this.router.navigate(['/trackers', 'weight']);
      navigated = true;
    }

    // trackers catch all
    if (!navigated && this.router.url.includes('/trackers')) {
      this.router.navigate(['/dashboard']);
      navigated = true;
    }

    // goals
    if (this.router.url.includes('/goals')) {
      this.location.back();
      navigated = true;
    }
    if (this.router.url.includes('/goals/')) {
      this.router.navigate(['/goals']);
      navigated = true;
    }

    // achievements
    if (this.router.url.includes('/achievements')) {
      this.location.back();
      navigated = true;
    }

    // task list
    if (this.router.url.includes('/task-list')) {
      this.location.back();
      navigated = true;
    }

    // resources
   /* if (this.router.url.includes('/resources')) {
      this.router.navigate(['/dashboard']);
      navigated = true;
    }*/

    if (this.router.url.includes('/resources/')) {
      this.location.back();
      navigated = true;
    }

    // support
    if (this.router.url.includes('/chat')) {
      this.router.navigate(['/dashboard']);
      navigated = true;
    }

    if (this.router.url.includes('/chat/')) {
      this.location.back();
      navigated = true;
    }

   


    // more
    if (this.router.url.includes('/more')) {
      this.router.navigate(['/dashboard']);
      navigated = true;
    }
    if (this.router.url.includes('/more/')) {
      this.location.back();
      navigated = true;
    }

    // exercise
    if (this.router.url.includes('/exercise/cardio')) {
      this.router.navigate(['/exercise']);
      navigated = true;
    }
    if (this.router.url.includes('/exercise/cardio/')) {
      this.router.navigate(['/exercise', 'cardio']);
      navigated = true;
    }
    if (this.router.url.includes('/exercise/strength')) {
      this.router.navigate(['/exercise']);
      navigated = true;
    }
    if (this.router.url.includes('/exercise/strength/')) {
      this.router.navigate(['/exercise', 'strength']);
      navigated = true;
    }

    // exercise catch all
    if (!navigated && this.router.url.includes('/exercise/')) {
      this.router.navigate(['/exercise']);
      navigated = true;
    }
    if (!navigated && this.router.url.includes('/exercise')) {
      this.router.navigate(['/dashboard']);
      navigated = true;
    }

    // social
    if (this.router.url.includes('/social/forum/category/')) {
      this.router.navigate(['/social', 'forum']);
      navigated = true;
    }
    if (
      !navigated &&
      this.router.url.includes('/social/forum') &&
      !this.router.url.includes('/social/forum/topic')
    ) {
      this.router.navigate(['/social']);
      navigated = true;
    }
    if (
      !navigated &&
      this.router.url.includes('/social/') &&
      !this.router.url.includes('/social/forum/topic')
    ) {
      this.router.navigate(['/social']);
      navigated = true;
    }

    if (!navigated) {
      this.location.back();
    }
  }
  onToggleAsideMenu() {
    this.showAside = !this.showAside;
  }
  onCloseMenu(event: any) {
    this.showAside = false;
  }

  onSideAction(event: any) {
    this.sideAction.emit(true);
  }

  checkNotifications() {
    this.notificationService.getNotifications().subscribe((responseData) => {
      this.notifications = responseData;
    });
  }

  parseLink(link:any, params:any){
    link = JSON.parse(JSON.stringify(link));
    link.forEach((l:any, i:number)=>{
      if(l.charAt(0) == ':'){
        let key = l.substring(1); 
        if(params[key]){
          link[i]= params[key];
        }
      }
      
    });
    return link;
  }
  
}
