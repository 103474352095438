import { Goal } from './../goal.model';
import { GoalsService } from './../goals.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { NgForm } from '@angular/forms';
import * as moment from 'moment';
import { Options } from '@angular-slider/ngx-slider';

@Component({
  selector: 'app-goal-update',
  templateUrl: './goal-update.component.html',
  styleUrls: ['./goal-update.component.scss'],
})
export class GoalUpdateComponent implements OnInit {
  constructor(
    private goalsService: GoalsService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  @ViewChild('form', { static: false }) goalUpdateForm!: NgForm;

  public goal!: Goal;
  isLoading = false;
  error:any;
  goalId:any;
  bsValue:any;
  private errorSub!: Subscription;
  options: Options = {
    floor: 0,
    ceil: 100,
    showSelectionBar: true,
  };
  today = new Date();

  ngOnInit(): void {
    this.goalId = this.route.snapshot.params.id;
    this.errorSub = this.goalsService.error.subscribe((errorMessage) => {
      this.error = errorMessage;
    });

    this.isLoading = true;
    this.goalsService.fetch(this.goalId).subscribe(
      (goal) => {
        this.isLoading = false;
        this.goal = goal;
        // this.bsValue = new Date(goal.start);
        this.bsValue = moment(goal.start).toDate();
      },
      (error) => {
        this.isLoading = false;
        this.error = error.message;
      }
    );
  }

  onSubmit() {

    if(moment(this.bsValue) < moment()){
      
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Invalid review date',
        text: 'Your review date is in the past',
        showConfirmButton: true,
      });
      return;
    }

    if (this.goal.progress == 100) {
      this.goal.status = 2;
    } else {
      this.goal.status = 1;
    }
    this.goalsService
      .updateGoal(
        this.goalId,
        this.goal.goal_category_id,
        this.goalUpdateForm.value.goalInputs.what,
        this.goalUpdateForm.value.goalInputs.why,
        this.goalUpdateForm.value.goalInputs.how,
        this.goalUpdateForm.value.goalInputs.barriers,
        moment(this.bsValue).format('YYYY-MM-DD'),
        this.goal.custom,
        this.goal.status,
        this.goal.progress
      )
      .subscribe(
        (responseData) => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Progress updated',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            toast: true,
          });
          this.router.navigate(['/goals']);
        },
        (error) => {
          console.log(error);
          this.error = error.message;
        }
      );
  }
}
