<div class="bg-light fh">
  <div class="main-content-area relative">
    <tabset [justified]="true" class="mt-2">
      <tab [active]="activeTab == 0" (selectTab)="changeRoute('general')">
        <ng-template tabHeading> General </ng-template>

        <p class="text-center mt-3">
          Please select a section from the list below.
        </p>
        <app-loading-spinner
          *ngIf="isLoading"
          [inline]="1"
        ></app-loading-spinner>
        <div class="row mt-3">
          <div class="col">
            <ul class="dashboard-flex-layout tracker-list tracker-list-padded">
              <li *ngFor="let resource of resources">
                <a
                  class="resource-nav-item"
                  [routerLink]="['/resources', resource.content_section_id]"
                >
                  <span class="icon-wrap tracker-icon">
                    <img
                      src="{{
                        resource.image_filename
                          | getThumbnail : 'medium-landscape'
                      }}"
                      alt=""
                      width="60"
                      class="rounded"
                    />
                  </span>

                  <span class="tracker-info text-start">
                    <span class="tracker-title"> {{ resource.title }}</span>

                    <span class="details"
                      >{{ resource.pages.length }} article<ng-container
                        *ngIf="resource.pages.length != 1"
                        >s</ng-container
                      >
                      | {{ resource.readingTime }} minute read</span
                    >
                  </span>
                  <span class="icon-wrap float-end icon-more"
                    ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
                  ></span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </tab>

      <tab [active]="activeTab == 1" (selectTab)="changeRoute('reading-list')">
        <ng-template tabHeading>Reading List </ng-template>

        <p class="px-3 pt-3 text-center" *ngIf="userData">
          This reading list is based on your primary
          <ng-container *ngIf="userData.secondary_diagnosis"
            >and secondary</ng-container
          >
          diagnosis
        </p>
        <div class="row mt-3">
          <div
            class="col-12"
            *ngFor="let _readingLists of readingLists | keyvalue"
          >
            <p class="px-3 pt-3 text-center">{{ _readingLists.key }}</p>
            <ul class="dashboard-flex-layout tracker-list tracker-list-padded">
              <li *ngFor="let resource of getReadingLists(_readingLists.key)">
                <a
                  class="resource-nav-item"
                  [routerLink]="['/resources', resource.content_section_id]"
                >
                  <span class="icon-wrap tracker-icon">
                    <img
                      src="{{
                        resource.image_filename
                          | getThumbnail : 'medium-landscape'
                      }}"
                      alt=""
                      width="60"
                      class="rounded"
                    />
                  </span>

                  <span class="tracker-info text-start">
                    <span class="tracker-title"> {{ resource.title }}</span>

                    <span class="details"
                      >{{ resource.pages?.length }} article<ng-container
                        *ngIf="resource.pages?.length != 1"
                        >s</ng-container
                      >
                      | {{ resource.readingTime }} minute read</span
                    >
                  </span>
                  <span class="icon-wrap float-end icon-more"
                    ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
                  ></span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </tab>
      <tab [active]="activeTab == 2" (selectTab)="changeRoute('bitesize')">
        <ng-template tabHeading> Bitesize</ng-template>
        <ul class="dashboard-flex-layout tracker-list tracker-list-padded mt-3">
          <ng-container
            *ngFor="let bitesizeSession of bitesizeSessions; let index = index"
          >
            <li [ngClass]="{ 'item-locked': index >= currentWeekNumber }">
              <a
                [routerLink]="
                  index < currentWeekNumber
                    ? ['/bitesize', bitesizeSession.bitesize_session_id]
                    : []
                "
                [ngClass]="{ disabled: index >= currentWeekNumber }"
              >
                <span class="icon-wrap tracker-num">
                  <span
                    class="num"
                    *ngIf="
                      !bitesizeSessionViewService.checkViewed(
                        bitesizeSessionViews,
                        bitesizeSession.bitesize_session_id
                      )
                    "
                    >{{ index + 1 }}</span
                  >
                  <span
                    class="icon-wrap text-success"
                    *ngIf="
                      bitesizeSessionViewService.checkViewed(
                        bitesizeSessionViews,
                        bitesizeSession.bitesize_session_id
                      )
                    "
                    ><fa-icon [icon]="['fas', 'check-circle']"></fa-icon
                  ></span>
                </span>
                <span class="icon-wrap tracker-icon">
                  <img
                    src="/assets/img/placeholder-refresh.jpg"
                    alt=""
                    width="40"
                    class="rounded"
                  />
                </span>
                <span class="tracker-info">
                  <span class="tracker-title">{{ bitesizeSession.title }}</span>

                  <span class="details"
                    >Bitesize Session ({{ index + 1 }} of
                    {{ getTotalBitesize() }})</span
                  >
                </span>
                <span class="icon-wrap float-end icon-more">
                  <fa-icon
                    [icon]="['fal', 'arrow-right']"
                    *ngIf="index < currentWeekNumber"
                  ></fa-icon>
                  <fa-icon
                    [icon]="['fal', 'lock']"
                    *ngIf="index >= currentWeekNumber"
                  ></fa-icon>
                </span>
              </a>
            </li>
          </ng-container>
        </ul>
      </tab>
    </tabset>
  </div>
</div>
