import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { AuthService } from 'src/app/auth/service/auth.service';
import { UserService } from 'src/app/auth/service/user.service';
import { UserDataInterface } from 'src/app/auth/service/userData.interface';
import { ResourcesService } from 'src/app/services/resources.service';
import { UserTaskStatusService } from 'src/app/services/user-task-status.service';
import Swal from 'sweetalert2/src/sweetalert2.js';

@Component({
  selector: 'app-exercise-about',
  templateUrl: './exercise-about.component.html',
  styleUrls: ['./exercise-about.component.scss']
})
export class ExerciseAboutComponent implements OnInit {
  taskStatus = false;
  taskId:any;
  isLoading = false;
  userStage:any;
  userData!: UserDataInterface;
  resource:any;
  taskCompleted = false;
  gettingStarted = false;
  currentYear: number = new Date().getFullYear();

  constructor(
    private userService: UserService,
    private resourcesService: ResourcesService,
    private router: Router,
    private userTaskStatusService:UserTaskStatusService
  ) {}

  ngOnInit(): void {
    if(this.router.url == '/getting-started/exercise'){
      this.gettingStarted = true;
    }
    this.isLoading = true;
    forkJoin({
      taskStatus: this.userTaskStatusService.fetchAll(),
      resource: this.resourcesService.fetchResource(21)
    }).subscribe(
      (responseData) => {
        this.isLoading = false;
        this.resource = responseData.resource;
        responseData.taskStatus.forEach((userTaskStatus) => {
          if (userTaskStatus.task_id == 'readActivity') {
            this.taskCompleted = true;
          }
        });

        if(!this.taskCompleted){
          this.userTaskStatusService
          .create('readActivity', 1)
          .subscribe((responseData) => {});
        }
      },
      (error) => {
        // console.log(error);
      }
    );
  }

  ngOnDestroy() {
    if (!this.taskCompleted) {
          Swal.fire(
            'Task Complete',
            "You completed the 'Read about Activity' task, well done!",
            'success'
          );
        this.router.navigate(['/getting-started']);
    }
  }

  onContentClick(e: MouseEvent) {
    if (e.target instanceof HTMLAnchorElement
        && e.target.host === location.host) {
      e.preventDefault();
      this.router.navigateByUrl(e.target.pathname);
    }
  }
}
