import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { BitesizeSessionViewService } from 'src/app/services/bitesize-session-view.service';
import { BitesizeSessionService } from 'src/app/services/bitesize-session.service';
import { CardioWorkoutService } from 'src/app/services/cardio-workout.service';
import { StrengthWorkoutService } from 'src/app/services/strength-workout.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-programme-progress',
  templateUrl: './programme-progress.component.html',
  styleUrls: ['./programme-progress.component.scss'],
})
export class ProgrammeProgressComponent implements OnInit {
  tasksTotal = 4;
  tasksComplete = 0;
  bitesizeComplete = 0;
  bitesizeViewIds: any = [];
  cardioComplete = false;
  strengthComplete = false;
  goalComplete = false;

  isLoading = false;
  environment = environment;
  constructor(
    private bitesizeSessionViewService: BitesizeSessionViewService,
    private cardioWorkoutService: CardioWorkoutService,
    private strengthWorkoutService:StrengthWorkoutService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;

    forkJoin({
      bitesizeViews: this.bitesizeSessionViewService.fetchAll(),
      cardioWorkouts: this.cardioWorkoutService.fetchAll(),
      strengthWorkouts: this.strengthWorkoutService.fetchAll(),

    }).subscribe((responseData) => {
      responseData.bitesizeViews.forEach((bitesizeView: any) => {
        this.bitesizeViewIds.push(bitesizeView.bitesize_session_id);
      });
      let uniqueViews = [...new Set(this.bitesizeViewIds)];
      this.bitesizeComplete = uniqueViews.length;
    });
  }
}
