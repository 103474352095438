<div class="container-narrow">
<div class="">
  <app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
  <div *ngIf="completed">
    <div class="card text-center card-light mb-3 border-radius-bottom-only">
      <div class="card-body text-center">
        <h3 class="sm-title text-center">Health Questionnaire</h3>
        <p style="text-align: center;">Thank you for completing this EQ-5D questionnaire.  Please press the button below to continue.</p>
        <div class="text-center">
          <button class="btn btn-primary" *ngIf="stage == 1" [routerLink]="['/getting-started']">Continue <span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span></button>
          <button class="btn btn-primary" *ngIf="stage !== 1" [routerLink]="['/dashboard']">Continue <span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span></button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!completed">
    <div class="card text-center card-light mb-3 border-radius-bottom-only">
      <div class="card-body text-center">
        <h3 class="sm-title text-center">Health Questionnaire</h3>
        <p  class="mt-3" style="text-align: center;">Please complete all the questions in this EQ-5D questionnaire.</p>
      </div>
    </div>
    <div class="internal-app card card-light  mt-2 mb-3">
      <div class="card-body">
  <form
    class="eq5d-form"
    (ngSubmit)="onSubmit()"
    #form="ngForm"
    name="eq5d-form"
  >
 
    <div class="form-group radio-group radio-group-nf" style="border-top: 0px;" [hidden]="step != 1">
      <label class="internal-title"><h4>Mobility (1/6)</h4></label>
    <p>Please select the <strong>ONE</strong> box that best describes your health <strong>TODAY</strong>.</p>
      <div class="tool-options">
        <div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          
          name="mobility"
          value="1"
          [(ngModel)]="responses.mobility"
          id="mobility1"
        />
        <label class="form-check-label" for="mobility1">
          <span class="icon-wrap">
            <span class="unselected">
              <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
            </span>
            <span class="selected">
              <fa-icon
                [icon]="['fas', 'check-square']"
                [fixedWidth]="true"
              ></fa-icon>
            </span>
          </span>
          I have no problems in walking about
        </label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          
          name="mobility"
          value="2"
          [(ngModel)]="responses.mobility"
          id="mobility2"
        />
        <label class="form-check-label" for="mobility2">
          <span class="icon-wrap">
            <span class="unselected">
              <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
            </span>
            <span class="selected">
              <fa-icon
                [icon]="['fas', 'check-square']"
                [fixedWidth]="true"
              ></fa-icon>
            </span>
          </span>
          I have slight problems in walking about
        </label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          
          name="mobility"
          value="3"
          [(ngModel)]="responses.mobility"
          id="mobility3"
        />
        <label class="form-check-label" for="mobility3">
          <span class="icon-wrap">
            <span class="unselected">
              <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
            </span>
            <span class="selected">
              <fa-icon
                [icon]="['fas', 'check-square']"
                [fixedWidth]="true"
              ></fa-icon>
            </span>
          </span>
          I have moderate problems in walking about
        </label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          
          name="mobility"
          value="4"
          [(ngModel)]="responses.mobility"
          id="mobility4"
        />
        <label class="form-check-label" for="mobility4">
          <span class="icon-wrap">
            <span class="unselected">
              <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
            </span>
            <span class="selected">
              <fa-icon
                [icon]="['fas', 'check-square']"
                [fixedWidth]="true"
              ></fa-icon>
            </span>
          </span>
          I have severe problems in walking about
        </label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          
          name="mobility"
          value="5"
          [(ngModel)]="responses.mobility"
          id="mobility5"
        />
        <label class="form-check-label" for="mobility5">
          <span class="icon-wrap">
            <span class="unselected">
              <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
            </span>
            <span class="selected">
              <fa-icon
                [icon]="['fas', 'check-square']"
                [fixedWidth]="true"
              ></fa-icon>
            </span>
          </span>
          I am unable to walk about
        </label>
      </div>
      </div>
      </div>
    </div>

    <div class="form-group radio-group" style="border-top: 0px;" [hidden]="step != 2">
      <label class="internal-title"><h4>Self-Care (2/6)</h4></label>
      <p>Please select the <strong>ONE</strong> box that best describes your health <strong>TODAY</strong>.</p>
      <div class="tool-options">
        <div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          
          name="selfcare"
          value="1"
          [(ngModel)]="responses.selfcare"
          id="selfcare1"
        />
        <label class="form-check-label" for="selfcare1">
          <span class="icon-wrap">
            <span class="unselected">
              <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
            </span>
            <span class="selected">
              <fa-icon
                [icon]="['fas', 'check-square']"
                [fixedWidth]="true"
              ></fa-icon>
            </span>
          </span>
          I have no problems washing or dressing myself
        </label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          
          name="selfcare"
          value="2"
          [(ngModel)]="responses.selfcare"
          id="selfcare2"
        />
        <label class="form-check-label" for="selfcare2">
          <span class="icon-wrap">
            <span class="unselected">
              <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
            </span>
            <span class="selected">
              <fa-icon
                [icon]="['fas', 'check-square']"
                [fixedWidth]="true"
              ></fa-icon>
            </span>
          </span>
          I have slight problems washing or dressing myself
        </label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          
          name="selfcare"
          value="3"
          [(ngModel)]="responses.selfcare"
          id="selfcare3"
        />
        <label class="form-check-label" for="selfcare3">
          <span class="icon-wrap">
            <span class="unselected">
              <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
            </span>
            <span class="selected">
              <fa-icon
                [icon]="['fas', 'check-square']"
                [fixedWidth]="true"
              ></fa-icon>
            </span>
          </span>
          I have moderate problems washing or dressing myself
        </label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          
          name="selfcare"
          value="4"
          [(ngModel)]="responses.selfcare"
          id="selfcare4"
        />
        <label class="form-check-label" for="selfcare4">
          <span class="icon-wrap">
            <span class="unselected">
              <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
            </span>
            <span class="selected">
              <fa-icon
                [icon]="['fas', 'check-square']"
                [fixedWidth]="true"
              ></fa-icon>
            </span>
          </span>
          I have severe problems washing or dressing myself
        </label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          
          name="selfcare"
          value="5"
          [(ngModel)]="responses.selfcare"
          id="selfcare5"
        />
        <label class="form-check-label" for="selfcare5">
          <span class="icon-wrap">
            <span class="unselected">
              <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
            </span>
            <span class="selected">
              <fa-icon
                [icon]="['fas', 'check-square']"
                [fixedWidth]="true"
              ></fa-icon>
            </span>
          </span>
          I am unable to wash or dress myself
        </label>
      </div>
    </div>
    </div>
  </div>
    <div class="form-group radio-group" style="border-top: 0px;" [hidden]="step != 3">
      <label class="internal-title"><h4>Usual Activities (3/6)</h4></label>
      <p>(e.g. work, study, housework, family or leisure
        activities)</p>
      <p>Please select the <strong>ONE</strong> box that best describes your health <strong>TODAY</strong>.</p>
      <div class="tool-options">
        <div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          
          name="activities"
          value="1"
          [(ngModel)]="responses.activities"
          id="activities1"
        />
        <label class="form-check-label" for="activities1">
          <span class="icon-wrap">
            <span class="unselected">
              <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
            </span>
            <span class="selected">
              <fa-icon
                [icon]="['fas', 'check-square']"
                [fixedWidth]="true"
              ></fa-icon>
            </span>
          </span>
          I have no problems doing my usual activities
        </label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          
          name="activities"
          value="2"
          [(ngModel)]="responses.activities"
          id="activities2"
        />
        <label class="form-check-label" for="activities2">
          <span class="icon-wrap">
            <span class="unselected">
              <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
            </span>
            <span class="selected">
              <fa-icon
                [icon]="['fas', 'check-square']"
                [fixedWidth]="true"
              ></fa-icon>
            </span>
          </span>
          I have slight problems doing my usual activities
        </label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          
          name="activities"
          value="3"
          [(ngModel)]="responses.activities"
          id="activities3"
        />
        <label class="form-check-label" for="activities3">
          <span class="icon-wrap">
            <span class="unselected">
              <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
            </span>
            <span class="selected">
              <fa-icon
                [icon]="['fas', 'check-square']"
                [fixedWidth]="true"
              ></fa-icon>
            </span>
          </span>
          I have moderate problems doing my usual activities
        </label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          
          name="activities"
          value="4"
          [(ngModel)]="responses.activities"
          id="activities4"
        />
        <label class="form-check-label" for="activities4">
          <span class="icon-wrap">
            <span class="unselected">
              <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
            </span>
            <span class="selected">
              <fa-icon
                [icon]="['fas', 'check-square']"
                [fixedWidth]="true"
              ></fa-icon>
            </span>
          </span>
          I have severe problems doing my usual activities
        </label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          
          name="activities"
          value="5"
          [(ngModel)]="responses.activities"
          id="activities5"
        />
        <label class="form-check-label" for="activities5">
          <span class="icon-wrap">
            <span class="unselected">
              <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
            </span>
            <span class="selected">
              <fa-icon
                [icon]="['fas', 'check-square']"
                [fixedWidth]="true"
              ></fa-icon>
            </span>
          </span>
          I am unable to do my usual activities
        </label>
      </div>
    </div>
    </div>
  </div>
    <div class="form-group radio-group" style="border-top: 0px;" [hidden]="step != 4">
    <label class="internal-title"><h4>Pain / Discomfort (4/6)</h4></label>
      <p>Please select the <strong>ONE</strong> box that best describes your health <strong>TODAY</strong>.</p>
      <div class="tool-options">
        <div>

      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          
          name="pain"
          value="1"
          [(ngModel)]="responses.pain"
          id="pain1"
        />
        <label class="form-check-label" for="pain1">
          <span class="icon-wrap">
            <span class="unselected">
              <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
            </span>
            <span class="selected">
              <fa-icon
                [icon]="['fas', 'check-square']"
                [fixedWidth]="true"
              ></fa-icon>
            </span>
          </span>
          I have no pain or discomfort
        </label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          
          name="pain"
          value="2"
          [(ngModel)]="responses.pain"
          id="pain2"
        />
        <label class="form-check-label" for="pain2">
          <span class="icon-wrap">
            <span class="unselected">
              <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
            </span>
            <span class="selected">
              <fa-icon
                [icon]="['fas', 'check-square']"
                [fixedWidth]="true"
              ></fa-icon>
            </span>
          </span>
          I have slight pain or discomfort
        </label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          
          name="pain"
          value="3"
          [(ngModel)]="responses.pain"
          id="pain3"
        />
        <label class="form-check-label" for="pain3">
          <span class="icon-wrap">
            <span class="unselected">
              <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
            </span>
            <span class="selected">
              <fa-icon
                [icon]="['fas', 'check-square']"
                [fixedWidth]="true"
              ></fa-icon>
            </span>
          </span>
          I have moderate pain or discomfort
        </label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          
          name="pain"
          value="4"
          [(ngModel)]="responses.pain"
          id="pain4"
        />
        <label class="form-check-label" for="pain4">
          <span class="icon-wrap">
            <span class="unselected">
              <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
            </span>
            <span class="selected">
              <fa-icon
                [icon]="['fas', 'check-square']"
                [fixedWidth]="true"
              ></fa-icon>
            </span>
          </span>
          I have severe pain or discomfort
        </label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          
          name="pain"
          value="5"
          [(ngModel)]="responses.pain"
          id="pain5"
        />
        <label class="form-check-label" for="pain5">
          <span class="icon-wrap">
            <span class="unselected">
              <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
            </span>
            <span class="selected">
              <fa-icon
                [icon]="['fas', 'check-square']"
                [fixedWidth]="true"
              ></fa-icon>
            </span>
          </span>
          I have extreme pain or discomfort
        </label>
      </div>
    </div>
</div>
</div>
    <div class="form-group radio-group" style="border-top: 0px;" [hidden]="step != 5">
      <label class="internal-title"><h4>Anxiety / Depression (5/6)</h4></label>
      <p>Please select the <strong>ONE</strong> box that best describes your health <strong>TODAY</strong>.</p>
      <div class="tool-options">
        <div>

      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          
          name="anxiety"
          value="1"
          [(ngModel)]="responses.anxiety"
          id="anxiety1"
        />
        <label class="form-check-label" for="anxiety1">
          <span class="icon-wrap">
            <span class="unselected">
              <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
            </span>
            <span class="selected">
              <fa-icon
                [icon]="['fas', 'check-square']"
                [fixedWidth]="true"
              ></fa-icon>
            </span>
          </span>
          I am not anxious or depressed
        </label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          
          name="anxiety"
          value="2"
          [(ngModel)]="responses.anxiety"
          id="anxiety2"
        />
        <label class="form-check-label" for="anxiety2">
          <span class="icon-wrap">
            <span class="unselected">
              <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
            </span>
            <span class="selected">
              <fa-icon
                [icon]="['fas', 'check-square']"
                [fixedWidth]="true"
              ></fa-icon>
            </span>
          </span>
          I am slightly anxious or depressed
        </label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          
          name="anxiety"
          value="3"
          [(ngModel)]="responses.anxiety"
          id="anxiety3"
        />
        <label class="form-check-label" for="anxiety3">
          <span class="icon-wrap">
            <span class="unselected">
              <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
            </span>
            <span class="selected">
              <fa-icon
                [icon]="['fas', 'check-square']"
                [fixedWidth]="true"
              ></fa-icon>
            </span>
          </span>
          I am moderately anxious or depressed
        </label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          
          name="anxiety"
          value="4"
          [(ngModel)]="responses.anxiety"
          id="anxiety4"
        />
        <label class="form-check-label" for="anxiety4">
          <span class="icon-wrap">
            <span class="unselected">
              <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
            </span>
            <span class="selected">
              <fa-icon
                [icon]="['fas', 'check-square']"
                [fixedWidth]="true"
              ></fa-icon>
            </span>
          </span>
          I am severely anxious or depressed
        </label>
      </div>
      <div class="form-check">
        <input
          type="radio"
          class="form-check-input"
          
          name="anxiety"
          value="5"
          [(ngModel)]="responses.anxiety"
          id="anxiety5"
        />
        <label class="form-check-label" for="anxiety5">
          <span class="icon-wrap">
            <span class="unselected">
              <fa-icon [icon]="['fal', 'square']" [fixedWidth]="true"></fa-icon>
            </span>
            <span class="selected">
              <fa-icon
                [icon]="['fas', 'check-square']"
                [fixedWidth]="true"
              ></fa-icon>
            </span>
          </span>
          I am extremely anxious or depressed
        </label>
      </div>
    </div>
    </div>
  </div>
    <div class="form-group" [hidden]="step != 6">
        <div class="row">
            <div class="col-md-12">
 
              <label class="internal-title"><h4>We would like to know how good or bad your health is TODAY (6/6)</h4></label>
            <p style="text-align: center;">This scale is numbered from 0 to 100. 0 means the worst health you can imagine.</p>
        <p style="text-align: center;">Please click on the scale to indicate how your health is TODAY.</p>
    </div>
  </div>
  <div class="row">
    <div class="col-6">
      <div class="health-box">
          Your Health Today
          <div class="value">{{value}}</div>
        </div>
            </div>
            <div class="col-6">
                <div class="healthrating-container">
                    <div class="healthrating-label">The best health you can imagine</div>

                <div class="healthrating-scale eq5d-slider">
                        <ngx-slider [(value)]="value" [options]="options"></ngx-slider>
                </div>
                        <div class="healthrating-label">The worst health you can imagine</div>
                    </div>
            </div>
        </div>
     

      
    </div>

    <div class="form-group" [hidden]="step != 7">
      <p>You have now completed this questionnaire.</p>
      <p>
        If you would like to change any of your answers, you may do so by
        pressing the "Previous" button prior to finalizing the questionnaire.
      </p>
      <p>
        Please finalise the questionnaire by pressing the ”Finalise" button.
        Once you press ”Finalise", you will not be able to go back to review or
        change your answers.
      </p>
    </div>
    <div class="mt-3 mb-3 row">
      <div class="col-6">
        <button
          class="btn btn-menu-bg btn-block"
          (click)="onPrevious()"
          [disabled]="step == 1"
          type="button"
        >
          Previous
        </button>
      </div>
      <div class="col-6 text-end">
        <button
          class="btn btn-primary btn-block"
          (click)="onNext()"
          *ngIf="step < 7"
          type="button"
          [disabled]="
            (step == 1 && !responses.mobility) ||
            (step == 2 && !responses.selfcare) ||
            (step == 3 && !responses.activities) ||
            (step == 4 && !responses.pain) ||
            (step == 5 && !responses.anxiety) ||
            (step == 6 && !value)
          "
        >
          Next
        </button>
        <button
          class="btn btn-primary btn-block"
          [disabled]="!form.valid"
          *ngIf="step > 6"
        >
          Finalise
          <span class="icon-wrap"
            ><fa-icon [icon]="['far', 'check']"></fa-icon
          ></span>
        </button>
      </div>
    </div>
    <hr>
    <p>© EuroQol Research Foundation. EQ-5D™ is a trade mark of the EuroQol Research Foundation. UK (English) v2.1</p>
  </form>
  </div>
  </div>
  </div>
</div>
</div>
<div *ngIf="gettingStarted">
  <div class="signin-details">
    <div class="signin-details-flex">
      <div class="signin-left">
        © {{ currentYear }} University Hospitals of Leicester
      </div>
      <div class="signin-right">
        <a [routerLink]="['/getting-started/terms']"
        >Terms &amp; Conditions</a
      >&nbsp;|&nbsp; 
      <a [routerLink]="['/getting-started/privacy']">Privacy Policy</a>
      </div>
    </div>
  </div>
  <div class="">
    <div class="col footer-logos">
      <div class="programme-logo-strap">Incorporating the programmes</div>
      <div class="programme-logos">
        <img
          src="./assets/img/logo-ayh-colour.svg"
          alt="Activate Your Heart Logo"
          width="93"
        />
        <img
          src="./assets/img/logo-space-colour.svg"
          alt="SPACE for COPD"
          width="69"
        />
        <img
          src="./assets/img/logo-ycr-colour.svg"
          alt="Your COVID Recovery"
          width="93"
        />
      </div>
    </div>
  </div>
</div> 