<nav
      class="navbar fixed-bottom navbar-expand navbar-light navbar-footer"
      aria-label="Main footer navigation"
    >
      <div class="d-flex justify-content-center" style="margin: 0 auto;">
        <ul class="navbar-nav">
          <li class="nav-item" routerLinkActive="active" [ngClass]="{'active':checkUrl() == 'dashboard'}">
            <a class="nav-link" [routerLink]="['/dashboard']">
              <span class="icon-wrap">
                <span class="light"><fa-icon [icon]="['fal', 'house-blank']"></fa-icon></span>
                <span class="solid"><fa-icon [icon]="['fas', 'house-blank']"></fa-icon></span>
              </span>
              <span class="nav-label">Home</span>
            </a>
          </li>
          <li class="nav-item" routerLinkActive="active" [ngClass]="{'active':checkUrl() == 'exercise'}">
            <a class="nav-link" [routerLink]="['/exercise']">
              <span class="icon-wrap"> 
                <span class="light">
                  <img src="./assets/icons/icon-exercise-outline-dark.svg"
                  height="24"
                  >
                  <!--<fa-icon [icon]="['fal', 'heart-pulse']"></fa-icon>-->
                  </span>
                <span class="solid">
                  <img src="./assets/icons/icon-exercise-solid-blue.svg"
                  height="24"
                  >
                  <!--<fa-icon [icon]="['fas', 'heart-pulse']"></fa-icon></fa-icon>-->
                </span>
              </span>
              <span class="nav-label">Exercise</span>
            </a>
          </li>
          <li class="nav-item" routerLinkActive="active" [ngClass]="{'active':checkUrl() == 'trackers'}">
            <a class="nav-link" [routerLink]="['/trackers']">
              <span class="icon-wrap"> 
                <span class="light"><fa-icon [icon]="['fal', 'bars-progress']"></fa-icon></span>
                <span class="solid"><fa-icon [icon]="['fas', 'bars-progress']"></fa-icon></span>
              </span>
              <span class="nav-label">Trackers</span>
            </a>
          </li>
          <li class="nav-item" routerLinkActive="active" [ngClass]="{'active':checkUrl() == 'resources'}">
            <a class="nav-link" [routerLink]="['/resources']">
              <span class="icon-wrap"> 
                <span class="light"><fa-icon [icon]="['fal', 'books']"></fa-icon></span>
                <span class="solid"><fa-icon [icon]="['fas', 'books']"></fa-icon></span>
              </span>
              <span class="nav-label">Resources</span>
            </a>
          </li>
          <li class="nav-item" routerLinkActive="active" [ngClass]="{'active':checkUrl() == 'more'}">
            <a class="nav-link" [routerLink]="['/more']">
              <span class="icon-wrap"> 
                <span class="light"><fa-icon [icon]="['fal', 'bars']"></fa-icon></span>
                <span class="solid"><fa-icon [icon]="['fas', 'bars']"></fa-icon></span>
              </span>
              <span class="nav-label">More</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
