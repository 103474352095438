<div class="container-narrow">
  <div class="container relative">
    <app-loading-spinner *ngIf="isLoading" [inline]="1"></app-loading-spinner>
    <h3 class="mt-3 mb-3 sm-title text-center">
      How is your recovery?
    </h3>
  
    <div
    class="card text-center card-light mt-3 mb-4" *ngIf="completed"
  >
    <div class="card-body text-center">
        <h3 class="badge badge-xl" *ngIf="currentScores.total"  [class]="currentScores.total | symptomBg: 40"><span class="sr-only">Your most recent rating was</span> {{currentScores.total}}</h3>
  
        <p>Thank you for completing the questionnaire.  Please press the button below to continue.</p>
        <button class="btn btn-primary" [routerLink]="['/questionnaires/eq5d']">Continue <span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span></button>
        
    </div>
  </div>
  <div *ngIf="!completed">
    <p>This questionnaire will help in understanding what effect your condition is having on your wellbeing and daily life.</p>
    <p>Your answers, and test score, can help guide the management and treatment of Impact for the greatest benefit.</p>
    <p>For each item below, place a mark number that best describes you now. Only select one response for each question.</p>   
  </div>
  
  
    <form class="symptom-thermometer-form" (ngSubmit)="onSubmit()" #form="ngForm" name="cat-form" [hidden]="completed">
      <div class="form-group">
        <fieldset>
          <legend><span class="icon-wrap"><fa-icon [icon]="['fal', 'head-side-cough']"></fa-icon></span> Cough</legend>
          <div class="radio-group">
            <div class="form-check form-check-inline">
              <input
                [ngModel]="currentScores.cough"
                class="form-check-input"
                type="radio"
                required
                name="cough"
                id="cough0"
                value="0"
              />
              <label class="form-check-label" for="cough0">0</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.cough"
                class="form-check-input"
                type="radio"
                required
                name="cough"
                id="cough1"
                value="1"
              />
              <label class="form-check-label" for="cough1">1</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.cough"
                class="form-check-input"
                type="radio"
                required
                name="cough"
                id="cough2"
                value="2"
              />
              <label class="form-check-label" for="cough2">2</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.cough"
                class="form-check-input"
                type="radio"
                required
                name="cough"
                id="cough3"
                value="3"
              />
              <label class="form-check-label" for="cough3">3</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.cough"
                class="form-check-input"
                type="radio"
                required
                name="cough"
                id="cough4"
                value="4"
              />
              <label class="form-check-label" for="cough4">4</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.cough"
                class="form-check-input"
                type="radio"
                required
                name="cough"
                id="cough5"
                value="5"
              />
              <label class="form-check-label" for="cough5">5</label>
            </div>
          </div>
        </fieldset>
        <div class="row form-instructions">
          <div class="col">
            <span class="arrow-left"></span>
            I NEVER cough
          </div>
          <div class="col text-right">
            <span class="arrow-right"></span>
            I cough ALL the time
          </div>
        </div>
      </div>
  
      <div class="form-group">
        <fieldset>
          <legend><span class="icon-wrap"><fa-icon [icon]="['fal', 'box-tissue']"></fa-icon></span> Phlegm</legend>
          <div class="radio-group">
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.phlegm"
                class="form-check-input"
                type="radio"
                required
                name="phlegm"
                id="phlegm0"
                value="0"
              />
              <label class="form-check-label" for="phlegm0">0</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.phlegm"
                class="form-check-input"
                type="radio"
                required
                name="phlegm"
                id="phlegm1"
                value="1"
              />
              <label class="form-check-label" for="phlegm1">1</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.phlegm"
                class="form-check-input"
                type="radio"
                required
                name="phlegm"
                id="phlegm2"
                value="2"
              />
              <label class="form-check-label" for="phlegm2">2</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.phlegm"
                class="form-check-input"
                type="radio"
                required
                name="phlegm"
                id="phlegm3"
                value="3"
              />
              <label class="form-check-label" for="phlegm3">3</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.phlegm"
                class="form-check-input"
                type="radio"
                required
                name="phlegm"
                id="phlegm4"
                value="4"
              />
              <label class="form-check-label" for="phlegm4">4</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.phlegm"
                class="form-check-input"
                type="radio"
                required
                name="phlegm"
                id="phlegm5"
                value="5"
              />
              <label class="form-check-label" for="phlegm5">5</label>
            </div>
          </div>
        </fieldset>
        <div class="row form-instructions">
          <div class="col">
            <span class="arrow-left"></span>
            I have no phlegm (mucus) in my chest at all
          </div>
          <div class="col text-right">
            <span class="arrow-right"></span>
            My chest is completely full of phlegm (mucus)
          </div>
        </div>
      </div>
  
      <div class="form-group">
        <fieldset>
          <legend><span class="icon-wrap"><fa-icon [icon]="['fal', 'heart']"></fa-icon></span> Chest Tightness</legend>
          <div class="radio-group">
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.chest_tightness"
                class="form-check-input"
                type="radio"
                required
                name="chestTightness"
                id="chestTightness0"
                value="0"
              />
              <label class="form-check-label" for="chestTightness0">0</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.chest_tightness"
                class="form-check-input"
                type="radio"
                required
                name="chestTightness"
                id="chestTightness1"
                value="1"
              />
              <label class="form-check-label" for="chestTightness1">1</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.chest_tightness"
                class="form-check-input"
                type="radio"
                required
                name="chestTightness"
                id="chestTightness2"
                value="2"
              />
              <label class="form-check-label" for="chestTightness2">2</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.chest_tightness"
                class="form-check-input"
                type="radio"
                required
                name="chestTightness"
                id="chestTightness3"
                value="3"
              />
              <label class="form-check-label" for="chestTightness3">3</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.chest_tightness"
                class="form-check-input"
                type="radio"
                required
                name="chestTightness"
                id="chestTightness4"
                value="4"
              />
              <label class="form-check-label" for="chestTightness4">4</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.chest_tightness"
                class="form-check-input"
                type="radio"
                required
                name="chestTightness"
                id="chestTightness5"
                value="5"
              />
              <label class="form-check-label" for="chestTightness5">5</label>
            </div>
          </div>
        </fieldset>
        <div class="row form-instructions">
          <div class="col">
            <span class="arrow-left"></span>
            My chest does not feel tight at all
          </div>
          <div class="col text-right">
            <span class="arrow-right"></span>
            My chest feels very tight
          </div>
        </div>
      </div>
  
  
      <div class="form-group">
        <fieldset>
          <legend><span class="icon-wrap"><fa-icon [icon]="['fal', 'lungs']"></fa-icon></span> Breathlessness</legend>
          <div class="radio-group">
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.breathlessness"
                class="form-check-input"
                type="radio"
                required
                name="breathlessness"
                id="breathlessness0"
                value="0"
              />
              <label class="form-check-label" for="breathlessness0">0</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.breathlessness"
                class="form-check-input"
                type="radio"
                required
                name="breathlessness"
                id="breathlessness1"
                value="1"
              />
              <label class="form-check-label" for="breathlessness1">1</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.breathlessness"
                class="form-check-input"
                type="radio"
                required
                name="breathlessness"
                id="breathlessness2"
                value="2"
              />
              <label class="form-check-label" for="breathlessness2">2</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.breathlessness"
                class="form-check-input"
                type="radio"
                required
                name="breathlessness"
                id="breathlessness3"
                value="3"
              />
              <label class="form-check-label" for="breathlessness3">3</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.breathlessness"
                class="form-check-input"
                type="radio"
                required
                name="breathlessness"
                id="breathlessness4"
                value="4"
              />
              <label class="form-check-label" for="breathlessness4">4</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.breathlessness"
                class="form-check-input"
                type="radio"
                required
                name="breathlessness"
                id="breathlessness5"
                value="5"
              />
              <label class="form-check-label" for="breathlessness5">5</label>
            </div>
          </div>
        </fieldset>
        <div class="row form-instructions">
          <div class="col">
            <span class="arrow-left"></span>
            When I walk up a hill or one flight of stairs I am not breathless
          </div>
          <div class="col text-right">
            <span class="arrow-right"></span>
            When I walk up a hill or one flight of stairs I am very breathless
          </div>
        </div>
      </div>
  
      <div class="form-group">
        <fieldset>
          <legend><span class="icon-wrap"><fa-icon [icon]="['far', 'walking']"></fa-icon></span> Activity</legend>
          <div class="radio-group">
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.activity"
                class="form-check-input"
                type="radio"
                required
                name="activity"
                id="activity0"
                value="0"
              />
              <label class="form-check-label" for="activity0">0</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.activity"
                class="form-check-input"
                type="radio"
                required
                name="activity"
                id="activity1"
                value="1"
              />
              <label class="form-check-label" for="activity1">1</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.activity"
                class="form-check-input"
                type="radio"
                required
                name="activity"
                id="activity2"
                value="2"
              />
              <label class="form-check-label" for="activity2">2</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.activity"
                class="form-check-input"
                type="radio"
                required
                name="activity"
                id="activity3"
                value="3"
              />
              <label class="form-check-label" for="activity3">3</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.activity"
                class="form-check-input"
                type="radio"
                required
                name="activity"
                id="activity4"
                value="4"
              />
              <label class="form-check-label" for="activity4">4</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.activity"
                class="form-check-input"
                type="radio"
                required
                name="activity"
                id="activity5"
                value="5"
              />
              <label class="form-check-label" for="activity5">5</label>
            </div>
          </div>
        </fieldset>
        <div class="row form-instructions">
          <div class="col">
            <span class="arrow-left"></span>
            I am not limited doing any activities at home
          </div>
          <div class="col text-right">
            <span class="arrow-right"></span>
            I am very limited doing activities at home
          </div>
        </div>
      </div>
  
  
  
      <div class="form-group">
        <fieldset>
          <legend><span class="icon-wrap"><fa-icon [icon]="['fal', 'smile']"></fa-icon></span> Confidence</legend>
          <div class="radio-group">
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.confidence"
                class="form-check-input"
                type="radio"
                required
                name="confidence"
                id="confidence0"
                value="0"
              />
              <label class="form-check-label" for="confidence0">0</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.confidence"
                class="form-check-input"
                type="radio"
                required
                name="confidence"
                id="confidence1"
                value="1"
              />
              <label class="form-check-label" for="confidence1">1</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.confidence"
                class="form-check-input"
                type="radio"
                required
                name="confidence"
                id="confidence2"
                value="2"
              />
              <label class="form-check-label" for="confidence2">2</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.confidence"
                class="form-check-input"
                type="radio"
                required
                name="confidence"
                id="confidence3"
                value="3"
              />
              <label class="form-check-label" for="confidence3">3</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.confidence"
                class="form-check-input"
                type="radio"
                required
                name="confidence"
                id="confidence4"
                value="4"
              />
              <label class="form-check-label" for="confidence4">4</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.confidence"
                class="form-check-input"
                type="radio"
                required
                name="confidence"
                id="confidence5"
                value="5"
              />
              <label class="form-check-label" for="confidence5">5</label>
            </div>
          </div>
        </fieldset>
        <div class="row form-instructions">
          <div class="col">
            <span class="arrow-left"></span>
            I am confident leaving my home despite my  condition
          </div>
          <div class="col text-right">
            <span class="arrow-right"></span>
            I am not at all confident leaving my home because of my condition
          </div>
        </div>
      </div>
  
      <div class="form-group">
        <fieldset>
          <legend><span class="icon-wrap"><fa-icon [icon]="['fal', 'snooze']"></fa-icon></span> Sleep</legend>
          <div class="radio-group">
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.sleep"
                class="form-check-input"
                type="radio"
                required
                name="sleep"
                id="sleep0"
                value="0"
              />
              <label class="form-check-label" for="sleep0">0</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.sleep"
                class="form-check-input"
                type="radio"
                required
                name="sleep"
                id="sleep1"
                value="1"
              />
              <label class="form-check-label" for="sleep1">1</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.sleep"
                class="form-check-input"
                type="radio"
                required
                name="sleep"
                id="sleep2"
                value="2"
              />
              <label class="form-check-label" for="sleep2">2</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.sleep"
                class="form-check-input"
                type="radio"
                required
                name="sleep"
                id="sleep3"
                value="3"
              />
              <label class="form-check-label" for="sleep3">3</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.sleep"
                class="form-check-input"
                type="radio"
                required
                name="sleep"
                id="sleep4"
                value="4"
              />
              <label class="form-check-label" for="sleep4">4</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.sleep"
                class="form-check-input"
                type="radio"
                required
                name="sleep"
                id="sleep5"
                value="5"
              />
              <label class="form-check-label" for="sleep5">5</label>
            </div>
          </div>
        </fieldset>
        <div class="row form-instructions">
          <div class="col">
            <span class="arrow-left"></span>
            I sleep soundly
          </div>
          <div class="col text-right">
            <span class="arrow-right"></span>
            I don't sleep soundly because of my condition
          </div>
        </div>
      </div>
  
      <div class="form-group">
        <fieldset>
          <legend><span class="icon-wrap"><fa-icon [icon]="['fal', 'bolt']"></fa-icon></span> Energy</legend>
          <div class="radio-group">
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.energy"
                class="form-check-input"
                type="radio"
                required
                name="energy"
                id="energy0"
                value="0"
              />
              <label class="form-check-label" for="energy0">0</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.energy"
                class="form-check-input"
                type="radio"
                required
                name="energy"
                id="energy1"
                value="1"
              />
              <label class="form-check-label" for="energy1">1</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.energy"
                class="form-check-input"
                type="radio"
                required
                name="energy"
                id="energy2"
                value="2"
              />
              <label class="form-check-label" for="energy2">2</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.energy"
                class="form-check-input"
                type="radio"
                required
                name="energy"
                id="energy3"
                value="3"
              />
              <label class="form-check-label" for="energy3">3</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.energy"
                class="form-check-input"
                type="radio"
                required
                name="energy"
                id="energy4"
                value="4"
              />
              <label class="form-check-label" for="energy4">4</label>
            </div>
            <div class="form-check form-check-inline">
              <input
              [ngModel]="currentScores.energy"
                class="form-check-input"
                type="radio"
                required
                name="energy"
                id="energy5"
                value="5"
              />
              <label class="form-check-label" for="energy5">5</label>
            </div>
          </div>
        </fieldset>
        <div class="row form-instructions">
          <div class="col">
            <span class="arrow-left"></span>
            I have lots of energy
          </div>
          <div class="col text-right">
            <span class="arrow-right"></span>
            I have no energy at all
          </div>
        </div>
      </div>
      
  
      <div class="mt-3 mb-3 text-center" *ngIf="!completed">
        <button class="btn btn-primary btn-block" [disabled]="!form.valid">Save Responses <span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span></button>
        
      </div>
    </form>
  </div>
  </div>