import { MfaComponent } from './auth/mfa/mfa.component';
import { UploadAvatarComponent } from './auth/upload-avatar/upload-avatar.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { UpdateDetailsComponent } from './auth/update-details/update-details.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MoreComponent } from './more/more.component';
import { RegisterComponent } from './auth/register/register.component';
import { LoginComponent } from './auth/login/login.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { AuthGuard } from './auth/guard/auth.guard';
import { ForgottenPasswordComponent } from './auth/forgotten-password/forgotten-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { OfflineComponent } from './offline/offline.component';
import { ExerciseIndexComponent } from './exercise/exercise-index/exercise-index.component';
import { CardioIndexComponent } from './exercise/cardio/cardio-index/cardio-index.component';
import { AddCardioWorkoutComponent } from './exercise/cardio/add-cardio-workout/add-cardio-workout.component';
import { PendingChangesGuard } from './guards/pending-changes.guard';
import { EditCardioWorkoutComponent } from './exercise/cardio/edit-cardio-workout/edit-cardio-workout.component';
import { ResourcesComponent } from './resources/resources.component';
import { ChatComponent } from './chat/chat.component';
import { ExpertComponent } from './chat/expert/expert.component';
import { SupportComponent } from './chat/support/support.component';
import { ForumComponent } from './chat/forum/forum.component';
import { ForumCategoryComponent } from './chat/forum/forum-category/forum-category.component';
import { ForumTopicComponent } from './chat/forum/forum-topic/forum-topic.component';
import { ForumTopicAddComponent } from './chat/forum/forum-topic-add/forum-topic-add.component';
import { GoalsComponent } from './goals/goals.component';
import { GoalBuilderComponent } from './goals/goal-builder/goal-builder.component';
import { GoalViewComponent } from './goals/goal-view/goal-view.component';
import { GoalUpdateComponent } from './goals/goal-update/goal-update.component';
import { GoalAddComponent } from './goals/goal-add/goal-add.component';
import { GoalsAboutComponent } from './goals/goals-about/goals-about.component';
import { StrengthIndexComponent } from './exercise/strength/strength-index/strength-index.component';
import { AddStrengthWorkoutComponent } from './exercise/strength/add-strength-workout/add-strength-workout.component';
import { EditStrengthWorkoutComponent } from './exercise/strength/edit-strength-workout/edit-strength-workout.component';
import { ResourceIndexComponent } from './resources/resource-index/resource-index.component';
import { ResourcePageComponent } from './resources/resource-page/resource-page.component';
import { TodoComponent } from './todo/todo.component';
import { GettingStartedComponent } from './getting-started/getting-started.component';
import { Eq5dComponent } from './questionnaires/eq5d/eq5d.component';
import { CatComponent } from './questionnaires/cat/cat.component';
import { ActionPlanSetupComponent } from './action-plan/action-plan-setup/action-plan-setup.component';
import { ActionPlanIndexComponent } from './action-plan/action-plan-index/action-plan-index.component';
import { ExerciseAboutComponent } from './exercise/exercise-about/exercise-about.component';
import { ExerciseSafetyQuizComponent } from './exercise/exercise-safety-quiz/exercise-safety-quiz.component';
import { HealthStatusIndexComponent } from './trackers/health-status/health-status-index/health-status-index.component';
import { HealthStatusUpdateComponent } from './trackers/health-status/health-status-update/health-status-update.component';
import { HealthStatusViewComponent } from './trackers/health-status/health-status-view/health-status-view.component';
import { TrackersIndexComponent } from './trackers/trackers-index/trackers-index.component';
import { HealthStatusSymptomComponent } from './trackers/health-status/health-status-symptom/health-status-symptom.component';
import { AchievementsIndexComponent } from './achievements/achievements-index/achievements-index.component';
import { MorePageComponent } from './more/more-page/more-page.component';
import { ContactComponent } from './contact/contact.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { BitesizeSessionComponent } from './bitesize/bitesize-session/bitesize-session.component';
import { BitesizeIndexComponent } from './bitesize/bitesize-index/bitesize-index.component';
import { ReadingListComponent } from './reading-list/reading-list.component';
import { ProgrammeIndexComponent } from './programme/programme-index/programme-index.component';
import { ProgrammeProgressComponent } from './programme/programme-progress/programme-progress.component';
import { WeightIndexComponent } from './trackers/weight/weight-index/weight-index.component';
import { WeightUpdateComponent } from './trackers/weight/weight-update/weight-update.component';
import { WeightTargetComponent } from './trackers/weight/weight-target/weight-target.component';
import { HealthStatusSetupComponent } from './trackers/health-status/health-status-setup/health-status-setup.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { GoalsManagingComponent } from './goals/goals-managing/goals-managing.component';
import { ViewStrengthWorkoutComponent } from './exercise/strength/view-strength-workout/view-strength-workout.component';
import { SearchComponent } from './search/search.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
    data: { hideSiebar: false },
  },
  {
    path: 'home',
    component: HomeComponent,
    data: {
      animation: 'Home',
      hideSiebar: true,
      pageTitle: 'Home',
      home: true,
      hideTopNav: true,
      fluidContainer: true,
      hideFooterNav: true,
    },
  },
  {
    path: 'getting-started',
    component: GettingStartedComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'GettingStarted',
      hideSiebar: true,
      pageTitle: 'Getting Started',
      hideTopNav: false,
      home: false,
      gettingStarted:true,
      hideFooterNav: true,

    },
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Dashboard',
      hideSiebar: false,
      pageTitle: 'Home',
      home: true,
    },
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      pageTitle: 'Login',
      hideNavbar: true,
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      fluidContainer: true,
    },
  },
  {
    path: 'mfa',
    component: MfaComponent,
    data: {
      pageTitle: 'Authenticator',
      hideNavbar: true,
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      bgDark: true,
      fluidContainer: true,
    },
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      subTitle: 'Register',
      hideNavbar: true,
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      fluidContainer: true,
    },
  },
  {
    path: 'forgotten-password',
    component: ForgottenPasswordComponent,
    data: {
      pageTitle: 'Forgotten Password',
      hideNavbar: true,
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      bgDark: false,
      fluidContainer: true,
    },
  },
  {
    path: 'reset-password/:id/:hash',
    component: ResetPasswordComponent,
    data: {
      hideNavbar: true,
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      bgDark: false,
      fluidContainer: true,
      pageTitle: 'Reset Password',
    },
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      noContainer: true,
      bgDark: false,
      pageTitle: 'Change Password',
    },
  },
  {
    path: 'update-details',
    component: UpdateDetailsComponent,
    canActivate: [AuthGuard],
    data: { hideSiebar: false, pageTitle: 'Account' },
  },
  {
    path: 'upload-avatar',
    component: UploadAvatarComponent,
    canActivate: [AuthGuard],
    data: { hideSiebar: false, pageTitle: 'Account' },
  },
  {
    path: 'more',
    component: MoreComponent,
    canActivate: [AuthGuard],
    data: { hideSiebar: false, animation: 'More', pageTitle: 'More' },
  },

  {
    path: 'exercise',
    component: ExerciseIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ExerciseIndex',
      pageTitle: 'Exercise',
      pageIcon: { variant: 'fas', icon: 'heart-pulse' },
    },
  },
  {
    path: 'exercise/about',
    component: ExerciseAboutComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ExerciseIndex',
      pageTitle: 'About Exercise',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'getting-started/exercise',
    component: ExerciseAboutComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: true,
      hideFooterNav: true,
      animation: 'ExerciseIndex',
      pageTitle: 'Getting Started',
      gettingStarted:true,
    },
  },

  {
    path: 'exercise/quiz',
    component: ExerciseSafetyQuizComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ExerciseSafetyQuiz',
      pageTitle: 'Exercise Safety Quiz',
      navbarOptions: { style: 'light' },
    },
  },


  {
    path: 'getting-started/exercise-quiz',
    component: ExerciseSafetyQuizComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: true,
      hideFooterNav: true,
      animation: 'ExerciseSafetyQuiz',
      pageTitle: 'Getting Started',
      gettingStarted:true,
    },
  },

  {
    path: 'exercise/cardio',
    component: CardioIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'CardioIndex',
      pageTitle: 'Cardio',
      navbarOptions: {
        style: 'light',
        addLink: ['/exercise', 'cardio', 'add-workout'],
      },
    },
  },
  {
    path: 'exercise/cardio/add-workout',
    component: AddCardioWorkoutComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
    data: {
      hideSiebar: false,
      animation: 'CardioAdd',
      pageTitle: 'Add Cardio Workout',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'exercise/cardio/edit-workout/:id',
    component: EditCardioWorkoutComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
    data: {
      hideSiebar: false,
      animation: 'CardioEdit',
      pageTitle: 'Edit Cardio Workout',
      navbarOptions: { style: 'light' },
    },
  },

  {
    path: 'exercise/strength',
    component: StrengthIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'StrengthIndex',
      pageTitle: 'Strength',
      navbarOptions: {
        style: 'light',
        addLink: ['/exercise', 'strength', 'add-workout'],
      },
    },
  },
  {
    path: 'exercise/strength/add-workout',
    component: AddStrengthWorkoutComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
    data: {
      hideSiebar: false,
      animation: 'StrengthAdd',
      pageTitle: 'Add Strength Workout',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'exercise/strength/edit-workout/:id',
    component: EditStrengthWorkoutComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
    data: {
      hideSiebar: false,
      animation: 'StrengthEdit',
      pageTitle: 'Edit Strength Workout',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'exercise/strength/view-workout/:id',
    component: ViewStrengthWorkoutComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'StrengthView',
      pageTitle: 'View Strength Workout',
      navbarOptions: { style: 'light' },
    },
  },

  // chat routes

  {
    path: 'chat',
    component: ChatComponent,
    canActivate: [AuthGuard],
    data: { hideSiebar: false, animation: 'Chat', pageTitle: 'Support' },
  },
  {
    path: 'chat/healthcare-professional',
    component: ExpertComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ChatHCP',
      pageTitle: 'Ask a Healthcare Professional',
      subTitle: '',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'chat/support',
    component: SupportComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ChatSupport',
      pageTitle: 'Technical Support',
      subTitle: 'Chat ',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'getting-started/ask-hcp',
    component: ExpertComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: true,
      animation: 'ChatHCP',
      pageTitle: 'Ask a Healthcare Professional',
      hideFooterNav: true,
      gettingStarted:true,
    },
  },
  {
    path: 'getting-started/support',
    component: SupportComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: true,
      animation: 'ChatSupport',
      pageTitle: 'Technical Support',
      hideFooterNav: true,
      gettingStarted:true,
    },
  },
  /*
  {
    path: 'chat/forum',
    component: ForumComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ChatForum',
      pageTitle: 'Forum',
      subTitle: '',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'chat/forum/category/:id',
    component: ForumCategoryComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ChatForumCategory',
      pageTitle: 'Forum',
      subTitle: '',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'chat/forum/topic/:id',
    component: ForumTopicComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ChatForumTopic',
      pageTitle: 'Forum',
      subTitle: '',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'chat/forum/topic-add/:id',
    component: ForumTopicAddComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ChatForumTopicAdd',
      pageTitle: 'Forum',
      subTitle: '',
      navbarOptions: { style: 'light' },
    },
  },*/
  {
    path: 'goals',
    component: GoalsComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'Goals',
      pageTitle: 'Goals',
      navbarOptions: {
        addLink: ['/goals', 'add'],
      },
    },
  },
  /*
  {
    path: 'goals/what-is-important-to-you',
    component: GoalBuilderComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'GoalBuilder',
      pageTitle: 'Goals',
      subTitle: 'What is important to you?',
    },
  },*/
  {
    path: 'goals/view/:id',
    component: GoalViewComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'GoalView',
      pageTitle: 'Goals',
      subTitle: 'View Goal',
      navbarOptions: { style: 'light', addLink: ['/goals', 'add'] },
    },
  },
  {
    path: 'goals/update/:id',
    component: GoalUpdateComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'GoalUpdate',
      pageTitle: 'Update Goal',
      subTitle: 'Update',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'goals/add',
    component: GoalAddComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'GoalsAdd',
      pageTitle: 'Add a Goal',
      subTitle: 'Add',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'goals/about',
    component: GoalsAboutComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'GoalsAbout',
      pageTitle: 'About Goal Setting',
      subTitle: 'About',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'goals/managing',
    component: GoalsManagingComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'GoalsAbout',
      pageTitle: 'Managing Expectations',
      subTitle: 'Managing Expectations',
      navbarOptions: { style: 'light' },
    },
  },


  {
    path: 'getting-started/goals',
    component: GoalsAboutComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: true,
      hideFooterNav: true,
      animation: 'GoalsAbout',
      pageTitle: 'Getting Started',
      subTitle: 'About',
      gettingStarted:true,
    },
  },

  {
    path: 'resources',
    component: ResourcesComponent,
    canActivate: [AuthGuard],
    data: { hideSiebar: false, animation: 'Resources', pageTitle: 'Resources' },
  },
  {
    path: 'resources/reading-list',
    component: ResourcesComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ResourcesIndex',
      pageTitle: 'Resources',
      navbarOptions: { style: 'light' },
      tab:'reading-list'
    },
  },
  {
    path: 'resources/bitesize',
    component: ResourcesComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ResourcesIndex',
      pageTitle: 'Resources',
      navbarOptions: { style: 'light' },
      tab:'bitesize'
    },
  },
  {
    path: 'resources/:id',
    component: ResourceIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ResourcesIndex',
      pageTitle: 'Resources',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'resources/:resourceId/:pageId',
    component: ResourcePageComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ResourcesPage',
      pageTitle: 'Resources',
      navbarOptions: { style: 'light' },
    },
  },

  {
    path: 'reading-list',
    component: ReadingListComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ReadingList',
      pageTitle: 'Reading List',
      navbarOptions: { style: 'light' },
    },
  },

  {
    path: 'getting-started/reading-list',
    component: ReadingListComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: true,
      hideFooterNav: true,
      animation: 'ReadingList',
      pageTitle: 'Getting Started',
      navbarOptions: { style: 'light' },
      gettingStarted:true,
    },
  },

  {
    path: 'programme',
    component: ProgrammeIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ProgrammeIndex',
      pageTitle: 'Programme',
    },
  },

  {
    path: 'programme/task-list',
    component: TodoComponent,
    canActivate: [AuthGuard],
    data: { hideSiebar: false, animation: 'TaskList', pageTitle: 'Weekly To-Do List',
    navbarOptions: { style: 'light' },
    },
    
  },

  {
    path: 'programme/progress',
    component: ProgrammeProgressComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ProgrammeProgress',
      pageTitle: 'Programme Progress',
      navbarOptions: { style: 'light' },
    },
  },
  /*
  {
    path: 'programme/weekly-reports',
    component: ProgrammeWeeklyReportsComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ProgrammeWeeklyReports',
      pageTitle: 'Programme',
    },
  },

  {
    path: 'programme/achievements',
    component: AchievementsIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ProgrammeProgress',
      pageTitle: 'Programme',
    },
  },
*/
  {
    path: 'questionnaires/cat',
    component: CatComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'CatQuestionnaire',
      hideSiebar: true,
      pageTitle: 'Questionnaires',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'questionnaires/eq5d',
    component: Eq5dComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Eq5dQuestionnaire',
      hideSiebar: true,
      pageTitle: 'Health Questionnaire',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'getting-started/eq5d',
    component: Eq5dComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Eq5dQuestionnaire',
      hideSiebar: true,
      hideFooterNav: true,
      pageTitle: 'Getting Started',
      gettingStarted:true,
    },
  },
  {
    path: 'questionnaires/eq5d/:stage',
    component: Eq5dComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'Eq5dQuestionnaire',
      hideSiebar: true,
      pageTitle: 'Questionnaires',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'feedback',
    component: FeedbackComponent,
    canActivate: [AuthGuard],
    data: { hideSiebar: false, animation: 'Feedback', pageTitle: 'Programme' },
  },
  {
    path: 'action-plan/setup',
    component: ActionPlanSetupComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
    data: {
      animation: 'ActionPlanSetup',
      pageTitle: 'Create Action Plan',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'getting-started/action-plan',
    component: ActionPlanSetupComponent,
    canActivate: [AuthGuard],
    canDeactivate: [PendingChangesGuard],
    data: {hideSiebar: true, hideFooterNav: true,
      animation: 'ActionPlanSetup',
      pageTitle: 'Getting Started',
      gettingStarted:true,
    },
  },
  {
    path: 'action-plan',
    component: ActionPlanIndexComponent,
    canActivate: [AuthGuard],
    data: {
      animation: 'ActionPlanIndex',
      pageTitle: 'Action Plan',
      navbarOptions: { style: 'light' },
    },
  },

  {
    path: 'trackers',
    component: TrackersIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'TrackersIndex',
      pageTitle: 'Trackers',
      pageIcon: { variant: 'fas', icon: 'bars-progress' },
    },
  },

  {
    path: 'trackers/health-status',
    component: HealthStatusIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'TrackersHealthStatus',
      pageTitle: 'Health Status',
      subTitle: 'Health Status',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'trackers/health-status/setup',
    component: HealthStatusSetupComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'TrackersHealthStatus',
      pageTitle: 'Set your Trackers Baseline',
      subTitle: 'Set your Trackers Baseline',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'getting-started/health-status',
    component: HealthStatusSetupComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: true,
      hideFooterNav: true,
      animation: 'TrackersHealthStatus',
      pageTitle: 'Getting Started',
      gettingStarted:true,
    },
  },

  {
    path: 'trackers/weight',
    component: WeightIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'WeightIndex',
      pageTitle: 'Weight',
      subTitle: 'Weight',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'trackers/weight/update/:id',
    component: WeightUpdateComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'TrackersWeightUpdate',
      pageTitle: 'Trackers',
      subTitle: 'Update Weight',
    },
  },
  {
    path: 'trackers/weight/target',
    component: WeightTargetComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'TrackersWeightTarget',
      pageTitle: 'Trackers',
      subTitle: 'Weight Target',
    },
  },
  {
    path: 'trackers/health-status/update',
    component: HealthStatusUpdateComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'TrackersSymptonThermometerUpdate',
      pageTitle: 'Update Health Status',
      subTitle: 'Update Health Status',
      navbarOptions: { style: 'light' },
    },
  },


  {
    path: 'trackers/health-status/update/:symptom',
    component: HealthStatusUpdateComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'TrackersSymptonThermometerUpdate',
      pageTitle: 'Update Health Status',
      subTitle: 'Update Symptom Thermometer',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'trackers/health-status/update/:symptom/:id',
    component: HealthStatusUpdateComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'TrackersSymptonThermometerUpdate',
      pageTitle: 'Update Health Status',
      subTitle: 'Update Symptom Thermometer',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'trackers/health-status/view/:symptom/:id',
    component: HealthStatusViewComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'TrackersHealthStatusView',
      pageTitle: 'Health Status',
      subTitle: 'View Symptom Thermometer record',
      navbarOptions: { style: 'light', addLink: ['/trackers', 'health-status', 'update', ':symptom'] },
    },
  },

  {
    path: 'trackers/health-status/:symptom',
    component: HealthStatusSymptomComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'TrackersHealthStatusSymptom',
      pageTitle: 'Health Status Symptom',
      subTitle: 'View Symptom Thermometer symptom',
      navbarOptions: { style: 'light', addLink: ['/trackers', 'health-status', 'update', ':symptom'] },
    },
  },

  {
    path: 'achievements',
    component: AchievementsIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'AchievementsIndex',
      pageTitle: 'Achievements',
      navbarOptions: { style: 'light' },
    },
  },


  {
    path: 'search',
    component: SearchComponent,
    canActivate: [AuthGuard],
    data: { hideSiebar: false, animation: 'Search', pageTitle: 'Search' },
  },

  /* {
    path: 'resources/:id',
    component: ResourceIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ResourcesIndex',
      pageTitle: 'Resources',
    },
  },
  {
    path: 'resources/:resourceId/:pageId',
    component: ResourcePageComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'ResourcesPage',
      pageTitle: 'Resources',
    },
  },*/

  /*

  {
    path: 'more/app',
    component: MorePageComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'MorePage',
      pageTitle: 'Install Web App',
      navbarOptions: { style: 'light' },
    },
  },

  {
    path: 'more/terms',
    component: MorePageComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'MorePage',
      pageTitle: 'Terms and Conditions',
      navbarOptions: { style: 'light' },
    },
  },

  {
    path: 'more/privacy',
    component: MorePageComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'MorePage',
      pageTitle: 'Privacy Policy',
      navbarOptions: { style: 'light' },
    },
  },
*/

  {
    path: 'more/:area',
    component: MorePageComponent,
    canActivate: [AuthGuard],
    data: { hideSiebar: false, animation: 'MorePage', pageTitle: 'More' },
  },

  {
    path: 'notifications',
    component: NotificationsComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'notifications',
      pageTitle: 'Notifications',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'contact',
    component: ContactComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'Contact',
      pageTitle: 'Contact',
      navbarOptions: { style: 'light' },
    },
  },

  {
    path: 'bitesize',
    component: BitesizeIndexComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'BitesizeIndex',
      pageTitle: 'Bitesize Sessions',
    },
  },

  {
    path: 'bitesize/:id',
    component: BitesizeSessionComponent,
    canActivate: [AuthGuard],
    data: {
      hideSiebar: false,
      animation: 'BitesizeSession',
      pageTitle: 'Bitesize Session',
      navbarOptions: { style: 'light' },
    },
  },

  {
    path: 'terms',
    component: TermsComponent,
    data: {
      hideSiebar: false,
      animation: 'MorePage',
      pageTitle: 'Terms and Conditions',
      navbarOptions: { style: 'light' },
    },
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
    data: {
      hideSiebar: false,
      animation: 'MorePage',
      pageTitle: 'Privacy Policy',
      navbarOptions: { style: 'light' },
    },
  },


  {
    path: 'terms-and-conditions',
    component: TermsComponent,
    data: {
      pageTitle: 'Terms & Conditions',
      hideNavbar: false,
      hideFooterNav: true,
      hideTopNav: false,
      hideSiebar: true,
      fluidContainer: false,
    },
  },
  {
    path: 'privacy-policy',
    component: PrivacyComponent,
    data: {
      pageTitle: 'Privacy Policy',
      hideNavbar: false,
      hideFooterNav: true,
      hideTopNav: false,
      hideSiebar: true,
      fluidContainer: false,
      
    },
  },

  {
    path: 'getting-started/terms',
    component: TermsComponent,
    canActivate: [AuthGuard],
    data: {
      pageTitle: 'Terms & Conditions',
      hideNavbar: false,
      hideFooterNav: true,
      hideTopNav: false,
      hideSiebar: true,
      fluidContainer: false,
      gettingStarted:true,
    },
  },
  {
    path: 'getting-started/privacy',
    component: PrivacyComponent,
    canActivate: [AuthGuard],
    data: {
      pageTitle: 'Privacy Policy',
      hideNavbar: false,
      hideFooterNav: true,
      hideTopNav: false,
      hideSiebar: true,
      fluidContainer: false,
      gettingStarted:true,
    },
  },

  {
    path: 'offline',
    component: OfflineComponent,
    data: {
      pageTitle: 'Site Offline',
      hideNavbar: true,
      hideFooterNav: true,
      hideTopNav: true,
      hideSiebar: true,
      bgDark: true,
      fluidContainer: true,
    },
  },
  { path: 'page-not-found', component: PageNotFoundComponent },
  { path: '**', redirectTo: '/page-not-found' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
