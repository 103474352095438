import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map, publishReplay, refCount } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ActionPlan } from '../models/action-plan.model';

@Injectable({
  providedIn: 'root',
})
export class ActionPlanService {
  error = new Subject<string>();
  slug = '/action_plans';
  resourceName = 'action_plans';
  allActionPlans?: Observable<ActionPlan[]> | null;
  normalActionPlans?: Observable<ActionPlan> | undefined | null;

  constructor(private http: HttpClient) {}

  fetchAll(): Observable<ActionPlan[]> {
    if (!this.allActionPlans) {
      this.allActionPlans = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: ActionPlan[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.allActionPlans;
  }

  clearCache() {
    this.allActionPlans = null;
    this.normalActionPlans = null;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const item = new ActionPlan(
            responseData.action_plan_id,
            responseData.user_id,
            responseData.cough,
            responseData.cough_productive,
            responseData.breathlessness_rest,
            responseData.breathlessness,
            responseData.wheeze,
            responseData.sputum_colour,
            responseData.sputum_clear_total,
            responseData.sputum_thickness,
            responseData.sputum_clear_difficulty,
            responseData.nasal_discharge,
            responseData.sore_throat,
            responseData.swollen_ankles,
            responseData.weight_change,
            responseData.chest_pain,
            responseData.palpitations,
            responseData.dizziness,
            responseData.nausea,
            responseData.created,
            responseData.modified
          );
          return item;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(
    cough: number,
    cough_productive: number,
    breathlessness_rest: number,
    breathlessness: number,
    wheeze: number,
    sputum_colour: number,
    sputum_clear_total: number,
    sputum_thickness: number,
    sputum_clear_difficulty: number,
    nasal_discharge: number,
    sore_throat: number,
    swollen_ankles: number,
    weight_change: number,
    chest_pain: number,
    palpitations: number,
    dizziness: number,
    nausea: number
  ) {
    this.clearCache();
    const payload = {
      cough,
      cough_productive,
      breathlessness_rest,
      breathlessness,
      wheeze,
      sputum_colour,
      sputum_clear_total,
      sputum_thickness,
      sputum_clear_difficulty,
      nasal_discharge,
      sore_throat,
      swollen_ankles,
      weight_change,
      chest_pain,
      palpitations,
      dizziness,
      nausea
    };
    return this.http.post<ActionPlan>(environment.apiUrl + this.slug, payload, {
      observe: 'response',
    });
  }

  update(
    id: number,
    cough: number,
    cough_productive: number,
    breathlessness_rest: number,
    breathlessness: number,
    wheeze: number,
    sputum_colour: number,
    sputum_clear_total: number,
    sputum_thickness: number,
    sputum_clear_difficulty: number,
    nasal_discharge: number,
    sore_throat: number,
    swollen_ankles: number,
    weight_change: number,
    chest_pain: number,
    palpitations: number,
    dizziness: number,
    nausea: number
  ) {
    this.clearCache();
    const payload = {
      cough,
      cough_productive,
      breathlessness_rest,
      breathlessness,
      wheeze,
      sputum_colour,
      sputum_clear_total,
      sputum_thickness,
      sputum_clear_difficulty,
      nasal_discharge,
      sore_throat,
      swollen_ankles,
      weight_change,
      chest_pain,
      palpitations,
      dizziness,
      nausea
    };
    return this.http.patch<ActionPlan>(
      environment.apiUrl + this.slug + '/' + id,
      payload,
      {
        observe: 'response',
      }
    );
  }

  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + '/' + id
    );
  }
}
