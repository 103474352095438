<div class="container relative">
  <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>
  <div class="card text-center mt-3">
    <div class="card-body text-center">
      <div *ngIf="weights[0]">
        <h3 class="mt-1 mb-3 sm-title">Update Weight</h3>
        <form class="weight-form  text-center" (ngSubmit)="onSubmit()" #form="ngForm">
          <div class="text-center">
          <div class="form-group mb-3">
            <input
              type="text"
              class="form-control from-control-xl-micro"
              name="weightInput"
              id="weightInput"
              placeholder="00"
              rows=""
              [ngModel]="weights[0].weight"
              #weightInput="ngModel"
              required
              maxlength="65535"
              minlength=""
            /> <span class="xl-micro-addon">Kg</span>
          </div> 
        </div>
          <button class="btn btn-primary">Update <span class="icon-wrap"><fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon></span></button>
        </form>
        <hr>
        <div *ngIf="target">
            <div>Target Weight : {{target.target  | number}}{{target.target_unit}}</div>
        </div>

        <div><a [routerLink]="['/trackers', 'weight', 'target']">Set new target</a></div>

        <hr>
        <span class="small">Last updated: {{ weights[0].created | date: "EEE d MMM" }}</span>
      </div>

      <div *ngIf="!weights[0]">
        <h3>Set Weight (kgs)</h3>

        <form class="weight-form" (ngSubmit)="onSubmit()" #form="ngForm">
          <div class="form-group text-center mb-3">
            <input
              type="text"
              class="form-control from-control-xl-micro"
              name="weightInput"
              id="weightInput"
              placeholder="00"
              rows=""
              ngModel
              #weightInput="ngModel"
              required
            />
            <span class="xl-micro-addon">Kg</span>
          </div>

          <button class="btn btn-primary" [disabled]="!form.valid">Save</button>
        </form>
      </div>
    </div>
  </div>

  <ul class="tracker-list" *ngIf="weights[0]">
    <li
      *ngFor="let weight of weights; let i = index"
    >
    <a [routerLink]="['/trackers', 'weight', 'update', weight.user_weight_id]">
      <span class="tracker-label">
        {{ weight.weight }}<span class="small">Kg</span>
      </span>
      <span class="tracker-info">
        <span class="tracker-link">
          {{ weight.created | date : "d MMMM yyyy" }}
          <span class="icon-wrap float-right"
            ><fa-icon [icon]="['far', 'arrow-alt-right']"></fa-icon></span
        ></span>
        <span class="details-sm">
            <span *ngIf="weights[i+1]">
            <span *ngIf="weight.weight - weights[i+1].weight > 0">Up</span>
            <span *ngIf="weight.weight - weights[i+1].weight < 0">Down</span
            > {{ weight.weight - weights[i+1].weight | number : '1.2-2' }}kg</span> 
         <span *ngIf="target"> | {{target.target - weight.weight | number : '1.2-2'}}{{target.target_unit}} needed for target</span>
        </span>
      </span>
    </a>
    </li>
  </ul>

  <!--canvas
  class="mt-3 mb-3"
    baseChart
    [datasets]="barChartData"
    [labels]="barChartLabels"
    [options]="barChartOptions"
    [legend]="barChartLegend"
    [chartType]="barChartType"
    [colors]="barChartColors"
  >
  </canvas-->
</div>
