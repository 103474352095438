<div class="main-content-area">
  <div class="bg-light">
    <div class="row" *ngIf="exerciseData">
      <div class="col">

        <!-- START OF FORM -->
        <form class="ii-form" (ngSubmit)="onSubmit()" #form="ngForm">

          <!-- START OF DATE -->
          <div class="form-group">
            <!-- Title: Date -->
            <label for="date">
              Date of workout
            </label>
            <!-- Field: Date -->
            <div class="input-group">
              <span class="input-group-icon">
                <fa-icon
                  [icon]="['far', 'calendar-alt']"
                  [fixedWidth]="true">
                </fa-icon>
              </span>
              <input type="text" 
                id="workout_date" 
                class="form-control form-control-date" 
                [ngModel]="workoutDate" 
                #workout_date="ngModel" 
                name="workout_date" 
                placeholder="DD/MM/YYYY" 
                required 
                date 
                bsDatepicker 
                autocomplete="off" 
                [maxDate]="maxDate" 
                [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-impact', showWeekNumbers: false }"
              />
          </div>
            <!-- Alert for date -->
            <div class="alert alert-danger" *ngIf="!workout_date.valid && (submitted || submittedStep[step])">
              Please enter a date above
            </div>
          </div>
          <!-- END OF DATE -->

          <!-- START OF EXERCISE CHOICE -->
          <div *ngIf="!exercisesSelected">
            <div class="form-group">
              <!-- Title: Exercise choice -->
              <label for="exercise-selection">
                What exercises did you do?
              </label>
              <!-- Exercise selection list -->
              <ul class="pick-list strength-picks">
                <!-- Each exercise -->
                <li class="strength-pick" *ngFor="let exercise of exercises; let index = index"
                [ngClass]="{ active: exercisesPicked.indexOf(exercise) !== -1 }"
                (click)="onPickExercise(exercise)">
                  <span class="pick-icon">
                    <span class="icon-wrap">
                      <fa-icon [icon]="['far', 'circle']" *ngIf="exercisesPicked.indexOf(exercise) == -1"></fa-icon>
                      <fa-icon [icon]="['fas', 'check-circle']" *ngIf="exercisesPicked.indexOf(exercise) !== -1"></fa-icon>
                    </span>
                  </span>
                  <span class="pick-item">{{ exercise.label }}</span>
                  <app-strength-icon [exerciseId]="exercise.id"></app-strength-icon>
                </li>
                <!-- Select all exercises -->
                <li class="w-100 text-center" [ngClass]="{ active: exercisesPicked.length == exercisesPicked.length }" (click)="onPickAll()">
                  <span class="pick-icon">
                    <span class="icon-wrap">
                      <fa-icon [icon]="['far', 'circle']" *ngIf="exercisesPicked.length != exercisesPicked.length"></fa-icon>
                      <fa-icon [icon]="['fas', 'check-circle']"
                        *ngIf="exercisesPicked.length == exercisesPicked.length"></fa-icon>
                    </span>
                  </span>
                  <span class="pick-item">All of the above</span>
                </li>
              </ul>
            </div>
            <!-- Confirm Button -->
            <button class="mt-3 btn btn-primary w-100" (click)="onConfirmExercises()"
              [disabled]="exercisesPicked.length == 0">
               Next
            </button>
            <hr />
            <!-- Delete Button -->
            <button class="btn btn-danger w-100 mt-4" (click)="onDeleteEntry()" type="button">
              Delete Entry
            </button>
          </div>
          <!-- END OF EXERCISE CHOICE -->

          <div *ngIf="exercisesSelected">

            <!-- START OF STRENGTH EXERCISE DETAILS -->
            <div *ngFor="let exercise of exercisesPicked; let index = index" [hidden]="step != index">
              
              <h2 class="form-subheading">
                <!-- Exercise Icon -->
                <app-strength-icon [exerciseId]="exercise.id"></app-strength-icon>
                <!-- Heading: Icon and Exercise -->
                 {{ exercise.label }}<br />
                 <!-- Sub-heading: Amount of exercises -->
                 <span class="form-subheading-aside">Exercise ({{ step + 1 }} of {{ exercisesPicked.length }})</span>
              </h2>

              

              <!-- START OF WEIGHT & REPS  -->
              <div class="form-group">
                <div class="row g-2">

                  <!-- WEIGHT -->
                  <div class="col mb-2">
                    <!-- Title: Weight -->
                    <label for="weight_{{ exercise.id }}">Weight</label>
                    <!-- Field: Weight -->
                    <div class="input-group">
                      <input
                        type="number" 
                        id="weight_{{ exercise.id }}"
                        class="form-control"
                        [(ngModel)]="exerciseData[exercise.id].weight" 
                        name="weight_{{ exercise.id }}" 
                        placeholder=""
                        required 
                        step=".5" 
                        min="0"
                      />
                      <span class="input-group-text">KG</span>
                    </div>
                    
                  </div>

                  <!-- SET 1 -->
                  <div class="col mb-2">
                    <!-- Title: Set 1 -->
                    <label class="sub-label" for="set1_reps_{{ exercise.id }}">Set 1</label>
                    <!-- Field: Set 1 -->
                    <div class="input-group">
                      <input
                        [(ngModel)]="exerciseData[exercise.id].set1" 
                        #set1_reps="ngModel" 
                        class="form-control"
                        type="text" 
                        required 
                        name="set1_reps_{{ exercise.id }}"
                        id="set1_reps_{{ exercise.id }}" 
                        value="0"
                      />
                      <span class="input-group-text">REPS</span>
                    </div>
                  </div>

                  <!-- SET 2 -->
                  <div class="col mb-2">
                    <!-- Title: Set 2 -->
                    <label class="sub-label" for="set2_reps_{{ exercise.id }}">Set 2</label>
                    <!-- Field: Set 2 -->
                    <div class="input-group">
                      <input 
                        [(ngModel)]="exerciseData[exercise.id].set2" 
                        #set2_reps="ngModel" 
                        class="form-control"
                        type="text" 
                        required 
                        name="set2_reps_{{ exercise.id }}"
                        id="set2_reps_{{ exercise.id }}" 
                        value="0"
                      />
                      <span class="input-group-text">REPS</span>
                    </div>
                    
                  </div>

                  <!-- SET 3 -->
                  <div class="col mb-2">
                    <!-- Title: Set 3 -->
                    <label class="sub-label" for="set3_reps_{{ exercise.id }}">Set 3</label>
                    <!-- Field: Set 3 -->
                    <div class="input-group">
                      <input 
                        [(ngModel)]="exerciseData[exercise.id].set3" 
                        #set3_reps="ngModel" 
                        class="form-control"
                        type="text" 
                        required 
                        name="set3_reps_{{ exercise.id }}"
                        id="set3_reps_{{ exercise.id }}" 
                        value="0"
                      />
                      <span class="input-group-text">REPS</span>
                    </div>
                    
                  </div>
                </div>
                <!-- Description of form elements -->
                <div class="form-desc">
                  A <b>Set</b> is made up of repetitions (reps).<br />
                  <b>Reps</b> are how many times the exercise is performed.
                </div>
                <!-- Alert for weight -->
                <div class="alert alert-danger" *ngIf="!exerciseData[exercise.id].weight && (submitted || submittedStep[step])">
                  Please enter a weight above
                </div>
                <!-- Alert for Set 1 reps -->
                <div class="alert alert-danger" *ngIf="!set1_reps.valid && (submitted || submittedStep[step])">
                  Please select a value above for Set 1
                </div>
                <!-- Alert for Set 2 reps -->
                <div class="alert alert-danger" *ngIf="!set2_reps.valid && (submitted || submittedStep[step])">
                  Please select a value above for Set 2
                </div>
                <!-- Alert for Set 3 reps -->
                <div class="alert alert-danger" *ngIf="!set3_reps.valid && (submitted || submittedStep[step])">
                  Please select a value above for Set 3
                </div>
              </div>
              <!-- END OF WEIGHT & REPS -->

              <!-- START OF DIFFICULTY -->
              <div class="form-group">
                <div class="col-sm-6 input-difficulty">
                  <!-- Title: Difficulty -->
                  <label class="sub-label">Difficulty</label>
                  <div class="radio-group" [ngClass]="{ chosen: difficulty.dirty || difficulty.touched }">

                    <!-- Difficulty level - 1 Very Easy -->
                    <div class="form-check form-check-inline">
                      <input 
                        [(ngModel)]="exerciseData[exercise.id].difficulty" 
                        #difficulty="ngModel"
                        class="form-check-input" 
                        type="radio" 
                        required 
                        name="difficulty_{{ exercise.id }}"
                        id="difficulty1_{{ exercise.id }}" 
                        value="1"
                      />
                      <span class="sr-only">1</span>
                      <label class="form-check-label" for="difficulty1_{{ exercise.id }}"><span class="icon-wrap">
                          <fa-icon [icon]="['fas', 'grin-stars']"></fa-icon> </span>
                      </label>
                    </div>

                    <!-- Difficulty level - 2 Easy -->
                    <div class="form-check form-check-inline">
                      <input 
                        [(ngModel)]="exerciseData[exercise.id].difficulty" 
                        #difficulty="ngModel"
                        class="form-check-input" 
                        type="radio" 
                        required 
                        name="difficulty_{{ exercise.id }}"
                        id="difficulty2_{{ exercise.id }}" 
                        value="2"
                      />
                      <label class="form-check-label" for="difficulty2_{{ exercise.id }}">
                        <span class="sr-only">2</span>
                        <span class="icon-wrap">
                          <fa-icon [icon]="['fas', 'smile']"></fa-icon>
                        </span>
                      </label>
                    </div>

                    <!-- Difficulty level - 3 Average -->
                    <div class="form-check form-check-inline">
                      <input 
                        [(ngModel)]="exerciseData[exercise.id].difficulty" 
                        #difficulty="ngModel"
                        class="form-check-input" 
                        type="radio" 
                        required 
                        name="difficulty_{{ exercise.id }}"
                        id="difficulty3_{{ exercise.id }}" 
                        value="3"
                      />
                      <label class="form-check-label" for="difficulty3_{{ exercise.id }}">
                        <span class="sr-only">3</span>
                        <span class="icon-wrap">
                          <fa-icon [icon]="['fas', 'meh']"></fa-icon>
                        </span>
                      </label>
                    </div>

                    <!-- Difficulty level - 4 Hard -->
                    <div class="form-check form-check-inline">
                      <input 
                        [(ngModel)]="exerciseData[exercise.id].difficulty" 
                        #difficulty="ngModel"
                        class="form-check-input" 
                        type="radio" 
                        required 
                        name="difficulty_{{ exercise.id }}"
                        id="difficulty4_{{ exercise.id }}" 
                        value="4"
                      />
                      <label class="form-check-label" for="difficulty4_{{ exercise.id }}">
                        <span class="sr-only">4</span>
                        <span class="icon-wrap">
                          <fa-icon [icon]="['fas', 'frown']"></fa-icon>
                        </span>
                      </label>
                    </div>

                    <!-- Difficulty level - 5 Very Hard -->
                    <div class="form-check form-check-inline">
                      <input 
                        [(ngModel)]="exerciseData[exercise.id].difficulty" 
                        #difficulty="ngModel"
                        class="form-check-input" 
                        type="radio" 
                        required 
                        name="difficulty_{{ exercise.id }}"
                        id="difficulty5_{{ exercise.id }}" 
                        value="5"
                      />
                      <label class="form-check-label" for="difficulty5_{{ exercise.id }}">
                        <span class="sr-only">5</span>
                        <span class="icon-wrap">
                          <fa-icon [icon]="['fas', 'tired']"></fa-icon>
                        </span>
                      </label>
                    </div>

                  </div>
                </div>
                <!-- Alert for Difficulty -->
                <div class="alert alert-danger" *ngIf="!difficulty.valid && (submitted || submittedStep[step])">
                  Please select a value above
                </div>
              </div>
              <!-- END OF DIFFICULTY -->

            </div>
            <!-- END OF STRENGTH EXERCISE DETAILS -->

            <!-- START OF STRENGTH SUMMARY -->
            <div *ngIf="step === exercisesPicked.length">
              <h2 class="form-subheading">Summary</h2>
              <ng-container *ngFor="let exercise of exercises">
                <div *ngIf="exerciseData['' + exercise.id].set1" class="strength-summary card mb-3">
                  <div class="card-body">
                    <div class="strength-view-title">
                      <h3>{{ exercise.label }}</h3>
                    </div>
                    <div class="strength-view-details">
                      <div class="strength-view-icon">
                        <app-strength-icon [exerciseId]="exercise.id"></app-strength-icon>
                      </div>
                      <div class="status-badge status-badge-sm">
                        <div class="status-badge-text">
                          <div class="status-badge-status">
                            <b>Set 1:</b> {{ exerciseData["" + exercise.id].set1 }}<span> reps</span>
                          </div>
                        </div>
                      </div>
                      <div class="status-badge status-badge-sm">
                        <div class="status-badge-text">
                          <div class="status-badge-status">
                            <b>Set 2:</b> {{ exerciseData["" + exercise.id].set2 }}<span> reps</span>
                          </div>
                        </div>
                      </div>
        
                      <div class="status-badge status-badge-sm">
                        <div class="status-badge-text">
                          <div class="status-badge-status">
                            <b>Set 3:</b> {{ exerciseData["" + exercise.id].set3 }}<span> reps</span>
                          </div>
                        </div>
                      </div>
        
                    </div>

                    <div class="strength-view-difficulty">
                      <app-difficulty-circle class="difficulty-icon-alignment"
                        [difficulty]="+exerciseData[exercise.id].difficulty"></app-difficulty-circle>
                      <span class="difficulty-text" *ngIf="+exerciseData[exercise.id].difficulty == 1">Very easy</span>
                      <span class="difficulty-text" *ngIf="+exerciseData[exercise.id].difficulty == 2">Easy</span>
                      <span class="difficulty-text" *ngIf="+exerciseData[exercise.id].difficulty == 3">Average</span>
                      <span class="difficulty-text" *ngIf="+exerciseData[exercise.id].difficulty == 4">Hard</span>
                      <span class="difficulty-text" *ngIf="+exerciseData[exercise.id].difficulty == 5">Very hard</span>
                       &nbsp;&nbsp;|&nbsp;&nbsp;{{ exerciseData["" + exercise.id].weight }}kg weights
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
            <!-- END OF STRENGTH SUMMARY -->

          </div>
          <!-- END OF STRENGTH EXERCISE DETAILS -->

          <!-- START OF PAGINATION NAVIGATION & SAVE -->
          <div *ngIf="exercisesSelected">
            <div class="text-center">
              <!-- Circle indicators -->
              <span *ngFor="let exercise of exercisesPicked; let index = index">
                <span class="icon-wrap mx-1" [ngClass]="{
                    'text-primary': index == step,
                    'text-secondary-accent': index != step
                  }">
                  <fa-icon [icon]="['fas', 'circle']"></fa-icon>
                </span>
              </span>
              <span class="icon-wrap mx-1" [ngClass]="{
                  'text-primary': exercisesPicked.length == step,
                  'text-secondary-accent': exercisesPicked.length != step
                }">
                <fa-icon [icon]="['fas', 'circle']"></fa-icon>
              </span>
              &nbsp;
            </div>

            <!-- Previous & Next Buttons -->
            <div class="row mt-4" *ngIf="step < exercisesPicked.length">
              <div class="col">
                <!-- Previous Button -->
                <button class="btn btn-secondary w-100" *ngIf="step > 0" type="button" (click)="onPrev()">
                  Previous
                </button>
                <button class="btn btn-secondary w-100" *ngIf="step < 1" type="button" (click)="exercisesSelected = false">
                  Previous
                </button>
              </div>
              <div class="col">
                <!-- Next Button -->
                <button class="btn btn-primary w-100" type="button" (click)="onNext()">
                  Next
                </button>
              </div>
            </div>
            <!-- Previous & Save Button -->
            <div class="row mt-4" *ngIf="step === exercisesPicked.length">
              <div class="col">
                <!-- Previous Button -->
                <button class="btn btn-secondary w-100" *ngIf="step > 0" type="button" (click)="onPrev()">
                  Previous
                </button>
              </div>
              <div class="col">
                <!-- Save Button -->
                <button class="btn btn-primary w-100">
                  Save Workout
                </button>
              </div>
            </div>
          </div>
          <!-- END OF PAGINATION NAVIGATION & SAVE -->

        </form>
        <!-- END OF FORM -->
      </div>
    </div>
  </div>
</div>