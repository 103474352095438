import { StrengthWorkout } from './../models/strength-workout.model';
import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { DateHelperService } from './date-helper.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class StrengthWorkoutService {
  error = new Subject<string>();
  slug = '/strength_workouts';
  resourceName = 'strength_workouts';
  weeklyTarget = {
    threshold: 1,
    days: 3,
  };
  allWorkouts?: Observable<StrengthWorkout[]> | null = null;
  workoutsBetween?: any;
  exercises = [
    { id: 'bicepCurl', label: 'Bicep Curls' },
    { id: 'pullUps', label: 'Pull ups' },
    { id: 'sitToStand', label: 'Sit to Stand' },
    { id: 'stepUps', label: 'Step ups' },
  ];

  constructor(
    private http: HttpClient,
    private dateHelperService: DateHelperService
  ) {}

  fetchBetween(startDate: Date, endDate: Date): Observable<StrengthWorkout[]> {
    if (!this.workoutsBetween) {
      this.workoutsBetween = {};
    }

    if (
      !this.workoutsBetween[
        this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD') +
          '_' +
          this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
      ]
    ) {
      let searchParams = new HttpParams();

      searchParams = searchParams.append(
        'startDate',
        this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD')
      );
      searchParams = searchParams.append(
        'endDate',
        this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
      );
      this.workoutsBetween[
        this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD') +
          '_' +
          this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
      ] = this.http
        .get<any>(environment.apiUrl + this.slug, {
          params: searchParams,
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: StrengthWorkout[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.workoutsBetween[
      this.dateHelperService.formatDate(startDate, 'YYYY-MM-DD') +
        '_' +
        this.dateHelperService.formatDate(endDate, 'YYYY-MM-DD')
    ];
  }
  fetchAll(): Observable<StrengthWorkout[]> {
    if (!this.allWorkouts) {
      this.allWorkouts = this.http
        .get<any>(environment.apiUrl + this.slug, {
          responseType: 'json',
        })
        .pipe(
          map((responseData) => {
            const returnArray: StrengthWorkout[] = [];
            responseData['_embedded'][this.resourceName].forEach(
              (item: any) => {
                // parse the exercise data
                if (item.exercises) {
                  item.exercises = JSON.parse(item.exercises);
                }
                returnArray.push(item);
              }
            );
            return returnArray;
          }),
          catchError((errorRes) => {
            return throwError(errorRes);
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.allWorkouts;
  }

  clearCache() {
    this.allWorkouts = null;
    this.workoutsBetween = null;
  }

  fetch(id: number) {
    return this.http
      .get<any>(environment.apiUrl + this.slug + '/' + id, {
        responseType: 'json',
      })
      .pipe(
        map((responseData) => {
          const item = new StrengthWorkout(
            +responseData.strength_workout_id,
            +responseData.user_id,
            responseData.workout_date,
            responseData.exercises,
            responseData.created,
            responseData.modified
          );
          return item;
        }),
        catchError((errorRes) => {
          return throwError(errorRes);
        })
      );
  }

  create(workout_date: string, exercises?: any) {
    this.clearCache();
    const payload = {
      workout_date: moment(workout_date).format('YYYY-MM-DD'),
      exercises,
    };
    return this.http.post<StrengthWorkout>(
      environment.apiUrl + this.slug,
      payload,
      {
        observe: 'response',
      }
    );
  }

  update(id: number, workout_date: string, exercises?: any) {
    this.clearCache();
    const payload = {
      workout_date: moment(workout_date).format('YYYY-MM-DD'),
      exercises,
    };
    return this.http.patch<StrengthWorkout>(
      environment.apiUrl + this.slug + '/' + id,
      payload,
      {
        observe: 'response',
      }
    );
  }

  delete(id: number) {
    this.clearCache();
    return this.http.delete<{ name: string }>(
      environment.apiUrl + this.slug + '/' + id
    );
  }

  generateWeekArray(currentWeek: any, data: any) {
    const weekArray: any = [];
    currentWeek.forEach((day: any) => {
      let currentDay = {
        date: this.dateHelperService.formatDate(day, 'YYYY-MM-DD'),
        exercises: '',
        reps: 0,
      };

      let currentDayExerciseObj: any = {};
      data.forEach((item: StrengthWorkout) => {
        if (item.workout_date == currentDay.date) {
          try {
            let exerciseObj: any;
            if (typeof item.exercises === 'string') {
              exerciseObj = JSON.parse(item.exercises);
            } else {
              exerciseObj = item.exercises;
            }

            this.exercises.forEach((__ex) => {
              if (exerciseObj[__ex.id].set1) {
                currentDay.reps += +exerciseObj[__ex.id].set1;
              }
              if (exerciseObj[__ex.id].set2) {
                currentDay.reps += +exerciseObj[__ex.id].set2;
              }
              if (exerciseObj[__ex.id].set3) {
                currentDay.reps += +exerciseObj[__ex.id].set3;
              }
            });

            this.exercises.forEach((__ex) => {
              if (!currentDayExerciseObj[__ex.id]) {
                currentDayExerciseObj[__ex.id] = {};
              }
              if (!currentDayExerciseObj[__ex.id].weight) {
                currentDayExerciseObj[__ex.id].weight = 0;
              }
              if (!currentDayExerciseObj[__ex.id].difficulty) {
                currentDayExerciseObj[__ex.id].difficulty = 0;
              }
              if (!currentDayExerciseObj[__ex.id].set1) {
                currentDayExerciseObj[__ex.id].set1 = 0;
              }
              if (!currentDayExerciseObj[__ex.id].set2) {
                currentDayExerciseObj[__ex.id].set2 = 0;
              }
              if (!currentDayExerciseObj[__ex.id].set3) {
                currentDayExerciseObj[__ex.id].set3 = 0;
              }
              currentDayExerciseObj[__ex.id].set1 += +exerciseObj[__ex.id].set1;
              currentDayExerciseObj[__ex.id].set2 += +exerciseObj[__ex.id].set2;
              currentDayExerciseObj[__ex.id].set3 += +exerciseObj[__ex.id].set3;
              currentDayExerciseObj[__ex.id].weight =
                +exerciseObj[__ex.id].weight;
              currentDayExerciseObj[__ex.id].difficulty =
                +exerciseObj[__ex.id].difficulty;
            });
          } catch {}

          currentDay.exercises = JSON.stringify(currentDayExerciseObj);
        }
      });
      weekArray.push(currentDay);
    });
    return weekArray;
  }

  generateLastMonthWeeksArray(month: any, data: any) {
    const weekArray: any = {
      1: { days: [], daysHit: 0 },
      2: { days: [], daysHit: 0 },
      3: { days: [], daysHit: 0 },
      4: { days: [], daysHit: 0 },
      total: { daysActive: 0 },
    };

    if (month.length > 28) {
      weekArray[5] = { days: [], daysHit: 0 };
    }
    if (month.length > 35) {
      weekArray[6] = { days: [], daysHit: 0 };
    }
    let weekcount = 1;
    let totalDaysActive = 0;
    month.forEach((day: any, dayIndex: number) => {
      if (dayIndex > 6 && dayIndex < 14) {
        weekcount = 2;
      }
      if (dayIndex > 13 && dayIndex < 21) {
        weekcount = 3;
      }
      if (dayIndex > 20 && dayIndex < 28) {
        weekcount = 4;
      }
      if (dayIndex > 27 && dayIndex < 35) {
        weekcount = 5;
      }
      if (dayIndex > 34) {
        weekcount = 6;
      }
      let currentDay = {
        date: this.dateHelperService.formatDate(day, 'YYYY-MM-DD'),
        exercises: '',
      };
      data.forEach((item: StrengthWorkout) => {
        if (item.workout_date === currentDay.date) {
          currentDay.exercises = item.exercises;
        }
      });
      if (currentDay.exercises != '') {
        totalDaysActive++;
        weekArray[weekcount].daysHit++;
      }
      weekArray[weekcount].days.push(currentDay);
    });
    weekArray.total.daysActive = totalDaysActive;
    return weekArray;
  }

  generateLast4WeeksArray(last4Weeks: any, data: any) {
    const weekArray: any = {
      1: { days: [], daysHit: 0 },
      2: { days: [], daysHit: 0 },
      3: { days: [], daysHit: 0 },
      4: { days: [], daysHit: 0 },
      total: { daysActive: 0 },
    };
    let weekcount = 1;
    let totalDaysActive = 0;
    last4Weeks.forEach((day: any, dayIndex: number) => {
      if (dayIndex > 6 && dayIndex < 14) {
        weekcount = 2;
      }
      if (dayIndex > 13 && dayIndex < 21) {
        weekcount = 3;
      }
      if (dayIndex > 20) {
        weekcount = 4;
      }
      let currentDay = {
        date: this.dateHelperService.formatDate(day, 'YYYY-MM-DD'),
        exercises: '',
      };
      data.forEach((item: StrengthWorkout) => {
        if (item.workout_date === currentDay.date) {
          currentDay.exercises = item.exercises;
        }
      });
      if (currentDay.exercises != '') {
        totalDaysActive++;
        weekArray[weekcount].daysHit++;
      }
      weekArray[weekcount].days.push(currentDay);
    });
    weekArray.total.daysActive = totalDaysActive;
    return weekArray;
  }

  getDaysHit(weekArray: any) {
    let daysHit = 0;
    weekArray.forEach((day: any) => {
      if (day.exercises) {
        daysHit++;
      }
    });
    return daysHit;
  }

  getStats(workoutData: StrengthWorkout[]) {
    let stats = {
      total: workoutData.length,
      totalReps: 0,
      activeDays: 0,
      longest: '',
      targetHit: 0,
      streak: 0,
    };

    let activeDays: any = [];
    workoutData.forEach((workout) => {
      activeDays.push(workout.workout_date);

      let exerciseObj: any = workout.exercises;

      this.exercises.forEach((exercise) => {
        stats.totalReps +=
          +exerciseObj[exercise.id].set1 +
          +exerciseObj[exercise.id].set2 +
          +exerciseObj[exercise.id].set3;
      });
    });
    let uniqueDays = activeDays.filter(this.onlyUnique);

    stats.activeDays = uniqueDays.length;

    let uniqueDaysAsc = uniqueDays.reverse();
    let currentStreak = 0;
    uniqueDaysAsc.forEach((day: string, index: number) => {
      if (index > 0) {
        if (moment(day).diff(moment(uniqueDaysAsc[index - 1]), 'days') != 1) {
          currentStreak = 0;
        }
      }
      currentStreak++;
      if (currentStreak > stats.streak) {
        stats.streak++;
      }
    });

    return stats;
  }

  onlyUnique(value: any, index: number, self: any) {
    return self.indexOf(value) === index;
  }

  getMonths(workouts: StrengthWorkout[]) {
    let months: any = [];
    workouts.forEach((workout) => {
      if (
        months.indexOf(moment(workout.workout_date).format('MMMMYY')) === -1
      ) {
        months.push(moment(workout.workout_date).format('MMMMYY'));
      }
    });
    return months;
  }

  getCurrentStreak(walks: StrengthWorkout[]) {
    let currentStreak = 0;
    let activeDays: any = [];
    walks.forEach((walk) => {
      activeDays.push(walk.workout_date);
    });
    let uniqueDays = activeDays.filter(this.onlyUnique);

    let streakBroken = false;

    if (
      moment().format('YYYY-MM-DD') ==
      moment(uniqueDays[0]).format('YYYY-MM-DD')
    ) {
      uniqueDays.forEach((day: string, index: number) => {
        if (index > 0) {
          if (moment(day).diff(moment(uniqueDays[index - 1]), 'days') != -1) {
            streakBroken = true;
          }
        }
        if (streakBroken == false) {
          currentStreak++;
        }
      });
    }
    return currentStreak;
  }

  parseExercises(exercises: string) {
    /*    if(exercises){

      try {
        a = JSON.parse(response);
    } catch(e) {
        alert(e); // error in the above string (in this case, yes)!
    }
      let parsesExercises = JSON.parse(exercises);
    }*/
  }

  getWeeksExercised(workouts: StrengthWorkout[], programmeWeeks: any) {
    let weeks: any = [];
    programmeWeeks.forEach((week: any, weekIndex: number) => {
      programmeWeeks[weekIndex].data = [];
    });
    workouts.forEach((workout) => {
      programmeWeeks.forEach((week: any, weekIndex: number) => {
        if (
          moment(workout.workout_date).format('YYYY-MM-DD') >=
            moment(week.start).format('YYYY-MM-DD') &&
          moment(workout.workout_date).format('YYYY-MM-DD') <=
            moment(week.end).format('YYYY-MM-DD')
        ) {
          programmeWeeks[weekIndex].data.push(workout);
        }
      });
    });

    programmeWeeks.forEach((week: any, weekIndex: number) => {
      week.data.forEach((workout: StrengthWorkout) => {});
    });

    return programmeWeeks;
  }

  getWeekReport(allWorkouts: StrengthWorkout[], week: any, exercise: any) {
    const repDex = {
      '0': 0,
      '1-7': 1,
      '8-12': 2,
      '13+': 3,
    };
    const weekArray: any = [];
    let globalWorkouts = 0;
    week.forEach((day: any) => {
      let currentDay = {
        date: this.dateHelperService.formatDate(day, 'YYYY-MM-DD'),
        weight: 0,
        weightAvg: 0,
        set1: 0,
        set2: 0,
        set3: 0,
        set1Avg: 0,
        set2Avg: 0,
        set3Avg: 0,
        repsAvg: 0,
        difficulty: 0,
        difficultyAvg: 0,
        workouts: 0,
      };
      allWorkouts.forEach((item: StrengthWorkout) => {
        if (item.workout_date === currentDay.date) {
          globalWorkouts++;
        }

        // did they do this exercise?
        let exercises: any;
        if (item.exercises) {
          exercises = item.exercises;
          if (
            item.workout_date === currentDay.date &&
            exercises[exercise].weight
          ) {
            // process exercises here..
            currentDay.workouts++;
            currentDay.weight += exercises[exercise].weight;
            currentDay.difficulty += exercises[exercise].difficulty;
            if (exercises[exercise].set1 == '0') {
              currentDay.set1 += 0;
            }
            if (exercises[exercise].set1 == '1-7') {
              currentDay.set1 += 1;
            }
            if (exercises[exercise].set1 == '8-12') {
              currentDay.set1 += 2;
            }
            if (exercises[exercise].set1 == '13+') {
              currentDay.set1 += 3;
            }
            if (exercises[exercise].set2 == '0') {
              currentDay.set2 += 0;
            }
            if (exercises[exercise].set2 == '1-7') {
              currentDay.set2 += 1;
            }
            if (exercises[exercise].set2 == '8-12') {
              currentDay.set2 += 2;
            }
            if (exercises[exercise].set2 == '13+') {
              currentDay.set2 += 3;
            }
            if (exercises[exercise].set3 == '0') {
              currentDay.set3 += 0;
            }
            if (exercises[exercise].set3 == '1-7') {
              currentDay.set3 += 1;
            }
            if (exercises[exercise].set3 == '8-12') {
              currentDay.set3 += 2;
            }
            if (exercises[exercise].set3 == '13+') {
              currentDay.set3 += 3;
            }
          }
        }
      });
      weekArray.push(currentDay);
    });

    // combine the seconds into minutes
    weekArray.forEach((weekItem: any, index: number) => {
      // aggregate data here
      if (weekItem.workouts > 0) {
        weekItem.difficultyAvg = weekItem.difficulty / weekItem.workouts;
        weekItem.set1Avg = weekItem.set1 / weekItem.workouts;
        weekItem.set2Avg = weekItem.set2 / weekItem.workouts;
        weekItem.set3Avg = weekItem.set3 / weekItem.workouts;
        weekItem.weightAvg = weekItem.weight / weekItem.workouts;
      }
    });

    // check for green week
    let greenDays = 0;
    let redDays = 0;
    let redRun = 0;
    let greenRun = 0;
    let redRunMax = 0;
    let greenRunMax = 0;
    let hitTarget = 0;
    let progress = false;
    let reduce = false;
    let totalWorkouts = 0;
    let daysExercised = 0;
    for (let i = 0; i < 7; i++) {
      totalWorkouts += weekArray[i].workouts;
      if (weekArray[i].workouts > 0) {
        daysExercised++;
      }
      if (weekArray[i].workouts > 0 && weekArray[i].difficultyAvg <= 2) {
        greenDays++;
        greenRun++;
        redRun = 0;
      }
      if (weekArray[i].workouts > 0 && weekArray[i].difficultyAvg >= 4) {
        redDays++;
        greenRun = 0;
        redRun++;
      }
      if (redRun > redRunMax) {
        redRunMax = redRun;
      }
      if (greenRun > greenRunMax) {
        greenRunMax = greenRun;
      }

      if (
        weekArray[i].set1Avg >= 2 &&
        weekArray[i].set2Avg >= 2 &&
        weekArray[i].set3Avg >= 2
      ) {
        hitTarget++;
      }
    }

    let feedbackText = '';
    if (hitTarget == 5 && redDays == 3 && greenDays == 4) {
      feedbackText =
        'Well done! You may consider doing strength exercises for the recommended counts of repetitions for 3 days in the coming week using same weight resistance!';
    } else if (daysExercised == 6 && greenDays == 6) {
      feedbackText =
        'Great effort! You can consider increasing the number of repetitions to complete recommended counts.';
    } else if (
      daysExercised == 3 &&
      greenDays == 1 &&
      redDays == 0 &&
      hitTarget == 3
    ) {
      feedbackText =
        'Keep up the effort! You can continue with the same weight resistance and count of repetitions for the upcoming week.';
    } else if (daysExercised == 7 && greenDays == 7 && hitTarget == 7) {
      feedbackText =
        'Excellent work! You may consider increasing the resistance weight by up to 0.5kg for the next week.  You can continue same count of repetitions.';
    } else if (daysExercised == 7 && greenDays == 3 && hitTarget == 7) {
      feedbackText =
        'Good work! Keep performing the recommended count of repetitions. You may continue using the same weight resistance for the upcoming week.';
    } else if (daysExercised == 7 && greenDays == 3 && hitTarget == 7) {
      feedbackText =
        'Good work! Keep performing the recommended count of repetitions. You may continue using the same weight resistance for the upcoming week.';
    } else if (daysExercised == 3 && greenDays == 0 && hitTarget == 3) {
      feedbackText =
        'Keep up the good work! You may want to remain consistent with the count of repetitions and resistance weight for the next week.';
    } else if (daysExercised == 3 && greenDays == 0 && hitTarget == 0) {
      feedbackText =
        'Great effort! Keep trying to perform recommended count of repetitions. You may want to consider reducing the weight resistance by up to 0.5kg for the next week. If you are experiencing any worsening in health condition do consult your physician and study team.';
    } else if (daysExercised == 4 && greenDays == 2 && hitTarget == 0) {
      feedbackText =
        'Nice effort! Keep performing the recommended count of repetitions for 3 days / week for the next week.';
    } else if (daysExercised == 4 && greenDays == 2 && hitTarget == 1) {
      feedbackText =
        'Great effort! You may want to stick to the count of recommended repetitions for 3 days / week in the upcoming week.';
    }

    if (hitTarget >= 3 && greenDays >= 3 && redDays == 0) {
      progress = true;
    }
    if (hitTarget == 0 && redDays >= 2 && greenDays == 0) {
      reduce = true;
    }

    return {
      feedback: feedbackText,
      progress: progress,
      reduce: reduce,
      totalWorkouts: totalWorkouts,
      daysExercised: daysExercised,
      weekArray: weekArray,
      globalWorkouts: globalWorkouts,
    };
  }
  isJson(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
}
