<div class="pt-3">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <p>
          Please fill in the form below to send a general enquiry to the IMPACT
          team. Remember you can use Ask The Healthcare Professional or
          Technical Support for specific enquiries.
        </p>
        <form
          #authForm="ngForm"
          name="contactForm"
          (ngSubmit)="onSubmit(authForm)"
          *ngIf="!isLoading"
        >
          <div class="form-group mb-4 form-floating">
            <input
              type="text"
              id="name"
              class="form-control"
              ngModel
              name="name"
              placeholder="name"
              required
              #name
              autocomplete="off"
            />
            <label for="name">Name</label>
          </div>
          <div class="form-group mb-4 form-floating">
            <input
              type="email"
              id="email"
              class="form-control"
              ngModel
              name="email"
              placeholder="Email"
              required
              #email
              autocomplete="off"
            />
            <label for="email">Email Address</label>
          </div>
          <div class="form-group mb-4 form-floating">
            <textarea
              id="message"
              class="form-control"
              ngModel
              name="message"
              placeholder="message"
              required
              #message
              autocomplete="off"
              rows="10"
              style="height: 200px"
            ></textarea>
            <label for="message">Your Message</label>
          </div>

          <button
            type="submit"
            [disabled]="!authForm.valid"
            class="btn btn-primary w-100 w-100 btn-lg"
          >
            Send Message
          </button>
        </form>
      </div>
    </div>
  </div>
</div>
