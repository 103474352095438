import { FeedbackService } from './feedback.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { forkJoin } from 'rxjs';
import Swal from 'sweetalert2';
import { UserService } from '../auth/service/user.service';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
  @ViewChild('form', { static: false }) feedbackForm?: NgForm;
  userData: any;
  taskId: any;
  userStage: any;
  isFetching = false;
  taskStatus = false;
  submittedResponses: any;
  completed = false;
  submitted = false;

  constructor(
    private feedbackService: FeedbackService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.userService.userData.subscribe((userData: any) => {
      if (userData) {
        this.userData = userData;
        this.userStage = this.userData.stage;

        this.isFetching = true;
        forkJoin({
          responses: this.feedbackService.fetchResponse(),
        }).subscribe(
          (responseData) => {
            this.isFetching = false;
            this.submittedResponses = responseData.responses;
            // have they answered it?
            if (this.submittedResponses) {
              this.completed = true;
            }
          },
          (error) => {
            // console.log(error);
          }
        );
      }
    });
  }

  onSubmit() {
    this.submitted = true;
    this.feedbackService
      .saveResponse(
        this.feedbackForm?.value.satisfaction,
        this.feedbackForm?.value.q2,
        this.feedbackForm?.value.q3,
        this.feedbackForm?.value.q4,
        this.feedbackForm?.value.q4_extra,
        this.feedbackForm?.value.q5,
        this.feedbackForm?.value.q6,
        this.feedbackForm?.value.q7,
        this.feedbackForm?.value.q8,
        this.feedbackForm?.value.q9
      )
      .subscribe((responseData) => {
        this.completed = true;
      });
  }
}
