<div class="main-content-area container-inner">
  <div class="row">
    <div class="col-12 col-md-12">
      <ul class="tracker-list tracker-list-padded">
        <!--li>
          <a [routerLink]="['/programme', 'progress']">
            <span class="icon-wrap tracker-icon">
              <img
                src="/assets/img/placeholder-todo.jpg"
                alt=""
                width="60"
                class="rounded"
              />
            </span>
            <span class="tracker-info">
              <span class="tracker-title">Programme Progress</span>

              <span class="progress progress-dividers">
                <span
                  *ngFor="
                    let _ of [].constructor(programmeTasksTotal);
                    let index = index
                  "
                  class="progress-bar"
                  [ngClass]="{
                    'bg-success': index < programmeTasksComplete,
                    'bg-white': index >= programmeTasksComplete
                  }"
                  role="progressbar"
                  style="width: {{ 100 / tasksTotal }}%"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></span>
              </span>

              <span class="details"
                >{{ programmeTasksComplete }} of {{ programmeTasksTotal }} tasks completed</span
            
            </span>
            <span class="icon-wrap float-end icon-more"
              ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
            ></span>
          </a>
        </li-->
        <li>
          <a [routerLink]="['/programme', 'task-list']">
            <span class="icon-wrap tracker-icon">
              <img
                src="/assets/img/placeholder-todo.jpg"
                alt=""
                width="60"
                class="rounded"
              />
            </span>
            <span class="tracker-info">
              <span class="tracker-title">Weekly To-Do List </span>

              <!--span class="progress progress-dividers">
                <span
                  *ngFor="
                    let _ of [].constructor(tasksTotal);
                    let index = index
                  "
                  class="progress-bar"
                  [ngClass]="{
                    'bg-success': index < tasksComplete,
                    'bg-white': index >= tasksComplete
                  }"
                  role="progressbar"
                  style="width: {{ 100 / tasksTotal }}%"
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></span>
              </span>

              <span class="details"
                >{{ tasksComplete }} of {{ tasksTotal }} tasks completed</span
            -->
            </span>
            <span class="icon-wrap float-end icon-more"
              ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
            ></span>
          </a>
        </li>
        <li>
          <a [routerLink]="['/achievements']">
            <span class="icon-wrap tracker-icon">
              <img
                src="/assets/img/placeholder-achievements.jpg"
                alt=""
                width="60"
                class="rounded"
              />
            </span>
            <span class="tracker-info">
              <span class="tracker-title">Achievements</span>

              <span class="details"
                >{{ recentAchievements }} badges earned this week</span
              >
            </span>
            <span class="icon-wrap float-end icon-more"
              ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
            ></span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
