import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { UserService } from 'src/app/auth/service/user.service';
import { UserDataInterface } from 'src/app/auth/service/userData.interface';
import { AchievementService } from 'src/app/services/achievement.service';
import { QuizService } from 'src/app/services/quiz.service';
import { UserTaskStatusService } from 'src/app/services/user-task-status.service';
import Swal from 'sweetalert2/src/sweetalert2.js';

@Component({
  selector: 'app-exercise-safety-quiz',
  templateUrl: './exercise-safety-quiz.component.html',
  styleUrls: ['./exercise-safety-quiz.component.scss'],
})
export class ExerciseSafetyQuizComponent implements OnInit, OnDestroy{
  isLoading = false;
  taskStatus = false;
  quiz: any;
  quizId = 1;
  answersPicked: any = [];
  completed = false;
  score = 0;
  results: any;
  responses: any;
  userData!: UserDataInterface;
  stage = 1;
  taskCompleted = false;
  gettingStarted = false;
  currentYear: number = new Date().getFullYear();

  constructor(
    private quizService: QuizService,
    private userTaskStatusService: UserTaskStatusService,
    private router: Router,
    private achievementService: AchievementService
  ) {}

  ngOnInit(): void {
    if(this.router.url == '/getting-started/exercise-quiz'){
      this.gettingStarted = true;
    }
    /*
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userStage = user.stage;
      }
    });
*/

    this.isLoading = true;
    forkJoin({
      quiz: this.quizService.fetchQuiz(this.quizId),
      quizResults: this.quizService.fetchResults(this.quizId, 1),
      userTaskStatus: this.userTaskStatusService.fetchAll(),
    }).subscribe(
      (responseData) => {
        this.isLoading = false;
        this.quiz = responseData.quiz;

        responseData.userTaskStatus.forEach((userTaskStatus) => {
          if (userTaskStatus.task_id == 'exerciseSafetyQuiz') {
            this.taskCompleted = true;
          }
        });

        this.results = responseData.quizResults.results;

        this.responses = responseData.quizResults.responses;
        if (this.results[0]) {
          this.completed = true;
          this.score =
            (this.results[0].score / this.results[0].total_questions) * 100;
          const responsesByAid: any = [];
          this.responses.forEach((response: any) => {
            responsesByAid.push(response.assessment_answer_id);
          });

          // set the picked answers..
          this.quiz.questions.forEach((question: any) => {
            this.quiz.answers[question.assessment_question_id].forEach(
              (answer: any) => {
                if (responsesByAid.indexOf(answer.assessment_answer_id) != -1) {
                  this.answersPicked.push(answer);
                }
              }
            );
          });
        }
      },
      (error) => {
        // console.log(error);
      }
    );
  }

  onRetake() {
    // reset it
    this.completed = false;
    this.answersPicked = [];
  }

  onPickAnswer(answer: any) {
    if (!this.completed) {
      if (this.answersPicked.indexOf(answer) !== -1) {
        this.answersPicked.splice(this.answersPicked.indexOf(answer), 1);
      } else {
        this.answersPicked.push(answer);
      }
    }
  }
  onSubmitAnswers() {
    this.completed = true;
    // work out the score..
    let points = 0;
    let totalAnswers = 0;
    const responses: any = [];
    this.quiz.questions.forEach((question: any) => {
      this.quiz.answers[question.assessment_question_id].forEach(
        (answer: any) => {
          totalAnswers++;
          if (this.answersPicked.indexOf(answer) !== -1) {
            responses.push({
              question_id: question.assessment_question_id,
              answer_id: answer.assessment_answer_id,
              correct: answer.correct,
            });
          }

          if (
            this.answersPicked.indexOf(answer) !== -1 &&
            answer.correct == 1
          ) {
            points++;
          } else if (
            this.answersPicked.indexOf(answer) === -1 &&
            answer.correct != 1
          ) {
            points++;
          }
        }
      );
    });

    this.score = Math.round((points / totalAnswers) * 100);

    // save results
    this.quizService.saveResponses(1, responses, 'groupTF').subscribe(
      (taskResponseData) => {},
      (error) => {
        // console.log(error);
      }
    );

    window.scrollTo(0, 0);
  }

  ngOnDestroy() {
    // have they passed
    if (this.score >= 80 && !this.taskCompleted) {
      // test
      this.userTaskStatusService
        .create('exerciseSafetyQuiz', 1)
        .subscribe((responseData) => {
          Swal.fire(
            'Task Complete',
            "You completed the 'Exercise Safety Quiz' task, well done!",
            'success'
          );
          this.router.navigate(['/getting-started']);
        });
      this.achievementService.refreshNotifications();
    }

    
  }

  countCorrectPicked(questionId:any){
    let total = 0;
    this.quiz.answers[questionId].forEach((answer:any)=>{
      if(answer.correct==1){
        if(this.answersPicked.indexOf(answer)!==-1){
          total++;
        }
      }
    });
    return total;
  }

  countCorrectAnswers(questionId:any){
    let total = 0;
    this.quiz.answers[questionId].forEach((answer:any)=>{
      if(answer.correct==1){
        total++;
      }
    });
    return total;
  }
}
