import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Notification } from '../models/notification.model';
import { UserAchievement } from '../models/user-achievement.model';
import { AchievementService } from '../services/achievement.service';
import { NotificationService } from '../services/notification.service';
import { AuthService } from '../auth/service/auth.service';
import { UserService } from '../auth/service/user.service';

@Component({
  selector: 'app-more',
  templateUrl: './more.component.html',
  styleUrls: ['./more.component.scss'],
})
export class MoreComponent implements OnInit {
  achievements: UserAchievement[] = [];
  notifications: Notification[] = [];
  diagnosisMode: any;
  userData: any;
  constructor(
    private achievementService: AchievementService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userService.userData.subscribe((userData: any) => {
          if (userData != null) {
            this.userData = userData;

            this.diagnosisMode = this.userService.getDiagnosisMode(
              this.userData.primary_diagnosis
            );
          }
        });
      }
    });

    forkJoin({
      achievements: this.achievementService.fetchAll(),
      notifications: this.notificationService.getNotifications(),
    }).subscribe((responseData) => {
      this.achievements = responseData.achievements;
      this.notifications = responseData.notifications;
    });
  }

  onLogout(): void {
    this.authService.logout();
  }
}
