import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-difficulty-circle',
  templateUrl: './difficulty-circle.component.html',
  styleUrls: ['./difficulty-circle.component.scss']
})
export class DifficultyCircleComponent implements OnInit {
  @Input() difficulty:number = 0;
  constructor() { }

  ngOnInit(): void {
  }


}
