import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { ChartOptions } from 'chart.js';
import * as moment from 'moment';
import { forkJoin, Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/service/auth.service';
import { UserService } from 'src/app/auth/service/user.service';
import { UserDataInterface } from 'src/app/auth/service/userData.interface';
import { Symptoms } from 'src/app/models/symptoms.model';
import { UserSymptomsService } from 'src/app/services/user-symptoms.service';

@Component({
  selector: 'app-health-status-index',
  templateUrl: './health-status-index.component.html',
  styleUrls: ['./health-status-index.component.scss'],
})
export class HealthStatusIndexComponent implements OnInit {
  /*
  public barChartOptions: ChartOptions = {
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      xAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: 'Date',
            fontColor: '#222529', // y axe label color (can be hexadecimal too)
          },
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            color: '#EBEBEB', // grid line color (can be removed or changed)
          },
          scaleLabel: {
            display: true,
            labelString: 'Symptom Score',
            fontColor: '#222529', // y axe label color (can be hexadecimal too)
          },
          ticks: {
            min: 0,
            max: 40,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      },
    },
  };
  public barChartLabels: Label[] = [];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  public barChartData: ChartDataSets[] = [
    { data: [], label: 'Symptoms', maxBarThickness: 50 },
  ];
  public barChartColors: any = [];

  colors: any = [];
*/
  public symptoms: Symptoms[] = [];
  isLoading = false;
  error = null;
  private errorSub!: Subscription;
  taskStatus = false;
  taskId: any;
  userStage: any;
  userData!: any;
  stageHistories: any;

  // for feedback
  symptom: any;
  previousSymptom: any;
  public diagnosisMode: any;
  symptomsBaseline:any;

  constructor(
    //private symptomsTrackerService: SymptomTrackerService,
    private datePipe: DatePipe,
    private authService: AuthService,
    private router: Router,
    private userService: UserService,
    public userSymptomsService: UserSymptomsService
  ) {}

  ngOnInit(): void {
    /*
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userStage = user.stage;
      }
    });
*/

    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;

        if (this.userData.primary_diagnosis) {
          this.diagnosisMode = this.userService.getDiagnosisMode(
            this.userData.primary_diagnosis
          );
        }
      }
    });
    /*
    this.errorSub = this.symptomsTrackerService.error.subscribe(
      (errorMessage) => {
        this.error = errorMessage;
      }
    );
*/
    // check if there is a stage task
    this.isLoading = true;

    forkJoin({
      symptoms: this.userSymptomsService.fetchAll(),
    }).subscribe(
      (responseData) => {
        this.isLoading = false;
        this.symptoms = responseData.symptoms;


        this.symptoms.forEach((symptoms: any) => {
          if (symptoms.baseline == 1 && !this.symptomsBaseline) {
            this.symptomsBaseline = symptoms;
          }
        });

        // are there any symptoms?
        if (this.symptoms.length < 1) {
          this.router.navigate(['/trackers', 'health-status', 'update']);
        }
        // this.setChartData(this.symptoms);
        if (this.symptoms[0]) {
          this.symptom = this.symptoms[0];
        }
        if (this.symptoms[1]) {
          this.previousSymptom = this.symptoms[1];
        }
      },
      (error) => {
        // console.log(error);
      }
    );
  }
  /*
  setChartData(symptoms) {
    this.barChartData[0].data = [];
    this.barChartData[0].backgroundColor = [];
    symptoms.forEach((symptom, index) => {
      this.barChartData[0].data.push(symptom.total);
      this.barChartLabels.push(
        this.datePipe.transform(symptom.created.replace(/-/g, '/'), 'MMM dd')
      );

      const percent = (symptom.total / 40) * 100;
      let bgColour = '';
      if (percent <= 20) {
        bgColour = '#F4E132';
      } else if (percent > 20 && percent <= 40) {
        bgColour = '#EBB92A';
      } else if (percent > 40 && percent <= 60) {
        bgColour = '#FB9626';
      } else if (percent > 60 && percent <= 80) {
        bgColour = '#FB6620';
      } else {
        bgColour = '#EB1218';
      }
      this.colors.push(bgColour);
      this.barChartData[0].backgroundColor[index] = bgColour;
    });

    // reverse them
    this.barChartData[0].data.reverse();
    this.barChartData[0].backgroundColor.reverse();
    this.colors.reverse();
    this.barChartLabels.reverse();
  }
*/
  ngOnDestroy() {}

  getSymptomScore(data: any, key: any) {
    if (data[key]) {
      return data[key];
    } else {
      return;
    }
  }

  checkSymptomForDiagnosis(symptomId: any) {
    let allowSymptom = true;
    /*
    ischaemic_pain
  cough
  phlegm
  chest_tightness
  sob
  ankle_swelling
  activity
  confidence
  sleep
  energy
  mood
  appetite
  surgery_pain
*/

    if (
      this.diagnosisMode == 'cardiac' &&
      (symptomId == 'cough' ||
        symptomId == 'phlegm' ||
        symptomId == 'chest_tightness' ||
        symptomId == 'fatigue' ||
        symptomId == 'memory' ||
        symptomId == 'anxiety')
    ) {
      allowSymptom = false;
    }

    if (
      this.diagnosisMode == 'respiratory' &&
      (symptomId == 'ischaemic_pain' ||
        symptomId == 'ankle_swelling' ||
        symptomId == 'surgery_pain' ||
        symptomId == 'fatigue' ||
        symptomId == 'memory' ||
        symptomId == 'anxiety')
    ) {
      allowSymptom = false;
    }

    if (
      this.diagnosisMode == 'covid' &&
      (symptomId == 'ischaemic_pain' ||
        symptomId == 'ankle_swelling' ||
        symptomId == 'surgery_pain' ||
        symptomId == 'phlegm' ||
        symptomId == 'chest_tightness' ||
        symptomId == 'activity' ||
        symptomId == 'confidence' ||
        symptomId == 'energy')
    ) {
      allowSymptom = false;
    }

    return allowSymptom;
  }

  getLatestSymptomScore(symptoms: any, symptom: string) {
    let score: any = null;
    symptoms.forEach((symptomItem: any) => {
      if (symptomItem[symptom] != undefined && !score && score!=0) {
        score = symptomItem[symptom];
      }
    });

    return score;
  }
}
