import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2/src/sweetalert2.js';
import { forkJoin } from 'rxjs';
import { UserDataInterface } from 'src/app/auth/service/userData.interface';
import { AuthService } from 'src/app/auth/service/auth.service';
import { UserService } from 'src/app/auth/service/user.service';
import { ResourcesService } from 'src/app/services/resources.service';
import { environment } from 'src/environments/environment';
import { UserTaskStatusService } from 'src/app/services/user-task-status.service';
import { AchievementService } from 'src/app/services/achievement.service';

@Component({
  selector: 'app-goals-about',
  templateUrl: './goals-about.component.html',
  styleUrls: ['./goals-about.component.scss'],
})
export class GoalsAboutComponent implements OnInit, OnDestroy {
  isLoading = false;
  userData!: UserDataInterface;
  resource: any;
  taskCompleted = false;
  gettingStarted = false;
  currentYear: number = new Date().getFullYear();

  constructor(
    private userService: UserService,
    private resourcesService: ResourcesService,
    private router: Router,
    private userTaskStatusService: UserTaskStatusService,
    private achievementService: AchievementService
  ) {}

  ngOnInit(): void {
    /*
    this.authService.user.subscribe((user) => {
      if (user) {
        this.userStage = user.stage;
      }
    });*/
    if(this.router.url == '/getting-started/goals'){
      this.gettingStarted = true;
    }

    this.userTaskStatusService.fetchAll().subscribe((responseData) => {
      responseData.forEach((userTaskStatus) => {
        if (userTaskStatus.task_id == 'readGoals') {
          this.taskCompleted = true;
        }
      });
      if (!this.taskCompleted) {
        this.userTaskStatusService
          .create('readGoals', 1)
          .subscribe((responseData) => {});
      }
    });
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;
      }
    });

    this.isLoading = true;
    forkJoin({
      resource: this.resourcesService.fetchResource(
        environment.contentIds.aboutGoals
      ),
    }).subscribe(
      (responseData) => {
        this.isLoading = false;
        this.resource = responseData.resource;
      },
      (error) => {
        this.isLoading = false;
        console.log(error);
      }
    );
  }

  ngOnDestroy() {
    if (!this.taskCompleted) {
      Swal.fire(
        'Task Complete',
        "You completed the 'Read about Goals' task, well done!",
        'success'
      );

      this.router.navigate(['/getting-started']);
      this.achievementService.refreshNotifications();
    }
  }

  onContentClick(e: MouseEvent) {
    if (
      e.target instanceof HTMLAnchorElement &&
      e.target.host === location.host
    ) {
      e.preventDefault();
      this.router.navigateByUrl(e.target.pathname);
    }
  }
}
