// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  cookieDomain: 'iimpact-app.hark2dev.com',
  ga: '',
  title: 'i-IMPACT',
  apiUrl: 'https://iimpact-api.hark2dev.com',
  staticUrl: 'https://iimpact-static.hark2dev.com',
  avatarPath: '/assets/avatar/',
  testSite: false,
  projectName: 'i-IMPACT',
  pwaName: 'i-IMPACT',
  languages: [
    {
      id: 1,
      language: 'English',
      languageNative: 'English',
      translationFile: 'en',
    },
  ],
  contentIds:{
    aboutGoals:20,
    managingGoals: 78
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
