import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {
  @ViewChild('form', { static: false }) quizform?: NgForm;

  questions:any = [
    {
      question: 'Climbing the stairs is a form of physical activity',
      answer: 't',
      feedback:
        'unless we climbed stairs for a protracted period of time, its activity',
      correct: null,
    },
    {
      question:
        'You only need to complete either physical activity or exercise',
      answer: 'f',
      feedback: 'It’s important for good health we are active and do exercise',
      correct: null,
    },
    {
      question:
        'Exercise should be carried out at a steady pace (you should be able to carry on a conversation comfortably while exercising)',
      answer: 't',
      feedback:
        'It is important to exercise comfortably to improve your fitness without over doing it',
      correct: null,
    },
    {
      question:
        'Benefits of regular exercise include; improving blood pressure and heart rate, lowering cholesterol levels, helps to reduce weight, reduce stress',
      answer: 't',
      feedback:
        'There is strong evidence to indicate that exercise is a great medicine',
      correct: null,
    },
    {
      question: 'Walking is the simplest form of aerobic activity',
      answer: 't',
      feedback: 'Walking is great exercise, its free and easy to do from home',
      correct: null,
    },
  ];
  completed = false;
  constructor() {}

  ngOnInit(): void {}

  onSubmit(el: HTMLElement) {
    this.completed = true;
    el.scrollIntoView();
    this.questions.forEach((question:any, index:any) => {
      this.questions[index].correct = false;
      if (question.answer === 't' && this.quizform?.value['q' + index] === '1') {
        this.questions[index].correct = true;
      } else if (
        question.answer === 'f' &&
        this.quizform?.value['q' + index] === '2'
      ) {
        this.questions[index].correct = true;
      }
    });
  }
}
