import { DateHelperService } from 'src/app/services/date-helper.service';
import { CacheManagerService } from './../../services/cache-manager.service';
import { UserService } from './user.service';
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, tap, take, exhaustMap, map, last } from 'rxjs/operators';
import { throwError, BehaviorSubject } from 'rxjs';

import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../../environments/environment';
import { User } from '../model/user.model';
import { TranslateService } from '@ngx-translate/core';

export interface AuthResponseData {
  kind: string;
  user: {
    email: string;
    first_name: string;
    last_name: string;
    id: string;
    app_token: string;
    user: {};
    api_token: { access_token: string; expires_in: number };
    avatar: string;
    language_id: string;
    week_start: string;
    current_week:string;
    username: string;
  };
  id: string;
}

@Injectable({ providedIn: 'root' })
export class AuthService {
  user = new BehaviorSubject<any>(null);
  private tokenExpirationTimer: any;
  public redirect!: string;
  public fetching = new BehaviorSubject<any>(false);

  constructor(
    private http: HttpClient,
    private router: Router,
    private cookieService: CookieService,
    private userService: UserService,
    private cacheManagerService: CacheManagerService,
    private dateHelperService: DateHelperService,
    private translate: TranslateService
  ) {}

  login(email: string, password: string, rememberMe: any) {
    return this.http
      .post<AuthResponseData>(environment.apiUrl + '/auth', {
        email,
        password,
        rememberMe,
      })
      .pipe(
        catchError(this.handleError),
        tap((resData) => {
          this.handleAuthentication(
            resData.user.email,
            resData.user.id,
            resData.user.app_token,
            resData.user,
            resData.user.api_token.access_token,
            resData.user.api_token.expires_in
          );

          // are we remebering it?
          if (rememberMe) {
            // this.autoLogout(expiresIn * 1000);
            const now = new Date();
            now.setMonth(now.getMonth() + 1);
            const exp = new Date(now);

            // store in cookies
            this.cookieService.set(
              'appToken',
              resData.user.app_token,
              exp,
              '/'
            );
            this.cookieService.set('appEmail', resData.user.email, exp, '/');
          }
        })
      );
  }

  forgottenPassword(email: string) {
    return this.http
      .post<any>(environment.apiUrl + '/forgotten-password', { email })
      .pipe(catchError(this.handleError));
  }

  checkForgottenPasswordHash(id: number, hash: string) {
    const params = new HttpParams().set('user_id', id).set('hash', hash);
    return this.http
      .get<any>(environment.apiUrl + '/reset-password', {
        params,
        responseType: 'json',
      })
      .pipe(
        tap((resData) => {
          return resData;
        })
      );
  }

  resetPassword(user_id: number, token: string, password: string) {
    return this.http
      .post<any>(environment.apiUrl + '/reset-password', {
        user_id,
        token,
        password,
      })
      .pipe(catchError(this.handleError));
  }

  changePassword(currentPassword: string, newPassword: string) {
    return this.http
      .post<any>(environment.apiUrl + '/change-password', {
        current_password: currentPassword,
        new_password: newPassword,
      })
      .pipe(catchError(this.handleError));
  }

  checkCode(code: string, email: string) {
    return this.http
      .post<AuthResponseData>(environment.apiUrl + '/check_code', {
        email,
        code,
      })
      .pipe(
        catchError(this.handleError),
        tap((resData: any) => {
          /*
          if(resData[0]){
            return resData[0];
          }*/
        })
      );
  }
  register(
    code: string,
    email: string,
    password: string,
    
  ) {
    return this.http
      .post<AuthResponseData>(environment.apiUrl + '/register', {
        code,
        email,
        password,
      })
      .pipe(
        catchError(this.handleError),
        tap((resData) => {
          this.handleAuthentication(
            resData.user.email,
            resData.user.id,
            resData.user.app_token,
            resData.user,
            resData.user.api_token.access_token,
            resData.user.api_token.expires_in
          );
        })
      );
  }

  updateDetails(
    email: string,
    firstName: string,
    lastName: string,
    language_id: string,
    week_start: string
  ) {
    return this.http
      .post<any>(environment.apiUrl + '/user-data', {
        email,
        first_name: firstName,
        last_name: lastName,
        language_id,
        week_start
      })
      .pipe(
        catchError(this.handleError),
        tap((resData) => {
          this.dateHelperService.setStartOfWeek(week_start);

          environment.languages.forEach((lang) => {
            if (lang.id == +language_id) {
              this.translate.use(lang.translationFile);
            }
          });

          this.cacheManagerService.clearAllCache();
          this.userService.reset();
          this.userService.get().subscribe((userdata) => {
            return userdata;
          });
        })
      );
  }

  upload(formData: any) {
    return this.http.post(environment.apiUrl + '/avatar', formData, {
      reportProgress: true,
      observe: 'events',
    });
  }

  checkScreenName(screenName: string) {
    // use params as posting to form
    const params = new HttpParams().set('screenName', '' + screenName);

    return this.http
      .get<any>(environment.apiUrl + '/check-screen-name', {
        params: params,
        responseType: 'json',
      })
      .pipe(
        tap((resData) => {
          return resData;
        })
      );
  }

  restore() {
    return this.http
      .get<any>(environment.apiUrl + '/restore', {
        responseType: 'json',
      })
      .pipe(
        catchError(this.handleError),
        tap(
          (resData) => {
            const userData = resData._embedded.restore[0];
            this.handleAuthentication(
              userData.user.email,
              userData.user.id,
              userData.user.app_token,
              userData.user,
              userData.user.api_token.access_token,
              userData.user.api_token.expires_in
            );
            this.fetching.next(false);

            this.userService.reset();
            this.userService.get().subscribe((userdata) => {});
          },
          (error) => {
            this.fetching.next(false);
            return error;
          }
        )
      );
  }

  autoLogin() {
    this.fetching.next(true);
    const appEmail = this.cookieService.get('appEmail');
    const appToken = this.cookieService.get('appToken');

    if (!appToken || !appEmail) {
      this.fetching.next(false);
      return;
    }

    return this.http
      .post<any>(environment.apiUrl + '/restore', {
        email: appEmail,
        token: appToken,
      })
      .pipe(
        catchError(this.handleError),
        tap(
          (resData) => {
            this.handleAuthentication(
              resData.user.email,
              resData.user.id,
              appToken,
              resData.user,
              resData.user.api_token.access_token,
              resData.user.api_token.expires_in
            );
            this.fetching.next(false);
            this.userService.get().subscribe((userdata) => {});
          },
          (error) => {
            this.fetching.next(false);
          }
        )
      );
  }

  getAccessToken() {
    this.user.pipe(
      take(1),
      exhaustMap((user) => {
        if (user) {
          return user.token;
        }
      })
    );
    return null;
  }

  refreshAccessToken() {
    return this.http.get<any>(environment.apiUrl + '/restore').pipe(
      catchError(this.handleError),
      tap((resData) => {
        let restoreData = resData._embedded.restore[0];
        this.handleAuthentication(
          restoreData.user.email,
          restoreData.user.id,
          restoreData.user.app_token,
          restoreData.user,
          restoreData.user.api_token.access_token,
          restoreData.user.api_token.expires_in
        );
        // this.userService.get().subscribe((userdata) => {});
      })
    );
  }
  handleRefresh401(errorRes: HttpErrorResponse) {
    const errorMessage = 'An unknown error occurred!';
    return throwError(errorMessage);
  }

  logout(): any {
    this.cookieService.delete('appToken');
    this.cookieService.delete('appEmail');
    // this.userService.userData.next(null);
    // this.userService.reset();
    this.cacheManagerService.clearAllCache();
    this.http
      .get<any>(environment.apiUrl + '/logout', {
        responseType: 'json',
      })
      .subscribe(
        (response) => {
          this.router.navigate(['/']);
          return false;
        },
        (error) => {
          return error;
        }
      );

    this.user.next(null);
    this.userService.reset();
  }

  autoLogout(expirationDuration: number) {
    this.tokenExpirationTimer = setTimeout(() => {
      this.logout();
    }, expirationDuration);
  }

  private handleAuthentication(
    email: string,
    userId: string,
    token: string,
    userData: {
      first_name: any;
      last_name: any;
      avatar: any;
      language_id: any;
      week_start: any;
      username: any;
      current_week:any;
    },
    api_token: string,
    expiresIn: number
  ) {
    this.dateHelperService.setStartOfWeek(userData.week_start);

    environment.languages.forEach((lang) => {
      if (lang.id == userData.language_id) {
        this.translate.use(lang.translationFile);
      }
    });

    const expirationDate = new Date(new Date().getTime() + expiresIn * 1000);
    const user = new User(
      email,
      userId,
      userData.first_name,
      userData.last_name,
      token,
      api_token,
      expirationDate,
      userData.avatar,
      userData.language_id,
      userData.week_start,
      userData.current_week,
      userData.username
    );
    this.user.next(user);
    this.userService.get().subscribe((userdata) => {});
  }

  private handleError(errorRes: HttpErrorResponse) {
    // console.log(errorRes);
    let errorMessage =
      'An unknown error occurred.  If the problem persists please contact us.';
    if (!errorRes.statusText) {
      return throwError(errorMessage);
    }

    switch (errorRes.statusText) {
      case 'EMAIL_EXISTS':
        errorMessage = 'This email exists already';
        break;
      case 'EMAIL_NOT_FOUND':
        errorMessage = 'This email does not exist.';
        break;
      case 'INVALID_PASSWORD':
        errorMessage = 'This password is not correct.';
        break;
      case 'INVALID_CREDENTIALS':
        errorMessage = 'The email or password is incorrect.';
        break;
      case 'INVALID_REGISTRATION_DATA':
        errorMessage = 'Invalid registration data provided.';
        errorMessage += errorRes.error;
        break;
      case 'INVALID_DATA':
        errorMessage = errorRes.error;
        break;
      case 'ACTION_NOT_ALLOWED':
        errorMessage = errorRes.error;
        break;
      case 'ACCOUNT_EXPIRED':
        errorMessage = 'Your account has expired and no longer has access.';
        break;
      case 'Unprocessable Entity':
        errorMessage = 'The data could not be processed.';
        break;
    }
    return throwError(errorMessage);
  }

  checkPassword(password: string, check: string) {
    switch (check) {
      case 'upper':
        return /[A-Z]/.test(password);
        break;
      case 'lower':
        return /[a-z]/.test(password);
        break;
      case 'num':
        return /[0-9]/.test(password);
        break;
      case 'special':
        return /[$@$!%*?&]/.test(password);
        break;
    }
    return false;
  }
}
